import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../../service/http.service';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {CompanyIntegrationModel} from '../../../../model/company-integration.model';
import {QueueModel} from '../../../../model/queue.model';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {FlashMessageService} from '../../../../service/flash-message.service';

@Component({
  selector: 'app-adisyo',
  templateUrl: './adisyo.component.html',
  styleUrls: ['./adisyo.component.scss']
})
export class AdisyoComponent implements OnInit {
  loading: boolean;
  isConnected: boolean;
  contactsLoading: boolean;
  productsLoading: boolean;
  locationLoading: boolean;
  productLoading: boolean;
  categoryLoading: boolean;
  tSalesLoading: boolean;
  ySalesLoading: boolean;
  l7dSalesLoading: boolean;
  mSalesLoading: boolean;
  lmSalesLoading: boolean;
  dateRangeSalesLoading: boolean;
  dateSalesLoading: boolean;
  submitted: boolean;
  attributes = {
    apisecret: '',
    apikey: '',
    apiconsumer: '',
    location: null
  };
  parameters = {
    date: '',
  };
  rangeDates: any;
  startDate: string;
  startDateValue: string;
  endDate: string;
  errorMessageStatus: boolean;
  errorMessage: '';
  monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  queueDisplay = false;
  minuteDisplay = false;
  saleDisplay = false;
  countdownDuration: number = 180000; // 3 minutes in milliseconds
  timeoutId: any;
  constructor(private http: HttpService, private config: DynamicDialogConfig, private flashMessageService: FlashMessageService) { }

  ngOnInit(): void {
    this.isConnected = this.config.data.isConnected;
    this.attributes.location = '/api/locations/' + this.config.data.location;
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'PRODUCT_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
    });
    this.attributes.apiconsumer = this.config.data.name + '-' + this.config.data.location;
    this.checkRemainingTime();

  }

  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'PRODUCT_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 10000);
    }
  }

  async save(): Promise<void> {
    this.submitted = true;

    await this.http.post(`${CompanyIntegrationModel.IRI}/adisyo-connect`, this.attributes).then(response => {
      this.isConnected = true;
    });

    this.submitted = false;
  }

  syncLocation(): void {
    this.errorMessageStatus = false;
    this.locationLoading = true;

    this.http.get('/api/company_integrations/adisyo/location/all?location=' + this.config.data.location).subscribe(
      response => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.locationLoading = false;
      },
      error => {
        if (error.status === 500) {
          this.errorMessageStatus = true;
          this.errorMessage = error.error.detail;
          this.locationLoading = false;
        }
      }
    );

  }
  syncCategories(): void {
    this.errorMessageStatus = false;
    this.categoryLoading = true;
    this.productLoading = true;
    this.minuteDisplay = true;  // Show the message
    this.http.get('/api/company_integrations/adisyo/category/' + this.config.data.location).subscribe(
      response => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      },
      error => {
        if (error.status === 500) {
          this.errorMessageStatus = true;
          this.errorMessage = error.error.detail;
        }
      }
    );

    const now = new Date().getTime();
    localStorage.setItem('categorySyncStartTime', now.toString());
    this.startCountdown();
  }
  syncProducts(): void {
    this.productLoading = true;
    this.errorMessageStatus = false;
    this.http.get('/api/company_integrations/adisyo/product/' + this.config.data.location).subscribe(
      response => {
        this.queueDisplay = true;
        this.controlImportStatus();
        this.productLoading = false;
      },
      error => {
        if (error.status === 500) {
          this.errorMessageStatus = true;
          this.errorMessage = error.error.detail;
          this.productLoading = false;
        }
      }
    );
    const now = new Date().getTime();
    localStorage.setItem('categorySyncStartTime', now.toString());
    this.startCountdown();
  }

  syncSales(option): void {
    this.saleDisplay = true;
    if (option ===  'T'){ this.tSalesLoading = true; }
    if (option ===  'SD'){ 
      this.dateSalesLoading = true; 
      option = '?start_date=' + this.startDate;

    }
    /*
    if (option ===  'Y'){ this.ySalesLoading = true; }
    if (option ===  'L7D'){ this.l7dSalesLoading = true; }
    if (option ===  'M'){ this.mSalesLoading = true; }
    if (option ===  'LM'){ this.lmSalesLoading = true; }
    if (option ===  'DR'){
      this.dateRangeSalesLoading = true;
      option = 'DR&fromDate=' + this.startDate + '&toDate=' + this.endDate;
    }

     */

    this.http.get('/api/company_integrations/adisyo/sales/' + this.config.data.location + option).subscribe(response => {

    });
    setTimeout(() => {
      this.tSalesLoading = false;
      this.ySalesLoading = false;
      this.l7dSalesLoading = false;
      this.mSalesLoading = false;
      this.lmSalesLoading = false;
      this.dateRangeSalesLoading = false;
      this.dateSalesLoading = false;
      this.saleDisplay = false;

    }, 40000); 
  }
  onSelectDate(event): void {
    const start = new Date(this.startDateValue);
    const day = start.getDate() < 10 ? `0${start.getDate()}` : start.getDate();
    const month = (start.getMonth() + 1) < 10 ? `0${start.getMonth() + 1}` : (start.getMonth() + 1); // +1 eklendi
    this.startDate = `${start.getFullYear()}-${month}-${day} 00:00:00`;
  }

  onSelectRangeDate(): void {
    let parameters = {...this.parameters};
    if (this.parameters.date) {
      const start = new Date(this.parameters.date[0]);
      const end = new Date(this.parameters.date[1]);
      parameters = {...parameters, ...{
          'startDate[strictly_after]': `${start.getDate()}-` + this.monthNames[`${start.getMonth()}`] + `-${start.getFullYear()}`,
          'endDate[strictly_before]': `${end.getDate()}-` + this.monthNames[`${end.getMonth()}`] + `-${end.getFullYear()}`,
        }
      };
    }
    this.parameters = parameters;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.startDate = this.parameters['startDate[strictly_after]'];
    this.endDate = this.parameters['endDate[strictly_before]'];
  }
  startCountdown() {
    this.productLoading = true;
    this.categoryLoading = true;
    this.minuteDisplay = true;

    // Calculate the remaining time based on start time
    const now = new Date().getTime();
    const startTime = parseInt(localStorage.getItem('categorySyncStartTime') || '0', 10);
    const elapsedTime = now - startTime;
    const remainingTime = this.countdownDuration - elapsedTime;

    // If there's still time remaining, set the timeout
    if (remainingTime > 0) {
      this.timeoutId = setTimeout(() => {
        this.productLoading = false;
        this.categoryLoading = false;
        this.minuteDisplay = false;
        localStorage.removeItem('categorySyncStartTime'); // Clear stored time
      }, remainingTime);
    } else {
      // If the time is already past, immediately reset the state
      this.productLoading = false;
      this.categoryLoading = false;
      this.minuteDisplay = false;
      localStorage.removeItem('categorySyncStartTime'); // Clear stored time
    }
  }

  checkRemainingTime() {
    const startTime = localStorage.getItem('categorySyncStartTime');
    if (startTime) {
      // If a start time exists, start the countdown
      this.startCountdown();
    }
  }

  ngOnDestroy() {
    // Clear the timeout if the component is destroyed
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
