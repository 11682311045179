import { Component, OnInit } from '@angular/core';
import {TaxRateModel} from '../../../../model/tax-rate.model';
import {TaxService} from '../../../../service/tax.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';

@Component({
  selector: 'app-create-tax',
  templateUrl: './create-tax.component.html',
  styleUrls: ['./create-tax.component.scss']
})
export class CreateTaxComponent implements OnInit {

  item: TaxRateModel = new TaxRateModel();
  submitted: boolean;
  errors = [];

  constructor(private taxService: TaxService, private flashMessageService: FlashMessageService,
              private ref: DynamicDialogRef, private validationService: ValidationService,
              private config: DynamicDialogConfig) { }

  ngOnInit(): void {

    if (this.config.data?.item) {
      this.item = this.config.data.item;
    }

    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;

    if (this.item.id) {
      await this.taxService.update(this.item).then(response => {
        if (response !== undefined) {
          this.taxService.getTaxesAction({isActive: true});
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    } else {
      await this.taxService.save(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }

    this.submitted = false;
  }

  close(): void {
    this.ref.close();
  }
}
