import {Component, OnInit, ViewChild} from '@angular/core';
import {
  InvoiceDeliveryStatus,
  InvoiceModel,
  InvoiceProductionStatus,
  InvoiceStatus,
  InvoiceStockStatus,
  InvoiceType
} from '../../../model/invoice.model';
import {HttpService} from '../../../service/http.service';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {InvoiceReceiveComponent} from '../invoice-receive/invoice-receive.component';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../service/utility.service';
import {WorkOrderModel} from '../../../model/work-order.model';
import {CurrencyService} from '../../../service/currency.service';
import {DeliveryActions} from '../../../model/invoice-item.model';
import {UserModel} from '../../../model/user.model';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {ContactModel} from '../../../model/contact.model';
import {StockModel} from '../../../model/stock.model';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  providers: [DialogService, DynamicDialogConfig]
})
export class InvoiceListComponent implements OnInit {

  @ViewChild('createdAt') createdAt;
  @ViewChild('expectedDate') expectedDate;

  loading: boolean;
  workOrdersLoading: boolean;
  total: number;
  items: InvoiceModel[] = [];
  item: InvoiceModel = new InvoiceModel();
  workOrders: WorkOrderModel[] = [];
  currency: string;
  changeStatusItems: MenuItem[] = [];
  detailed: boolean;
  entities = [];
  totalPrice = 0;

  user: UserModel = new UserModel();
  settings = [];

  invoiceType = InvoiceType;
  invoiceStatus = InvoiceStatus;
  invoiceDeliveryStatus = InvoiceDeliveryStatus;
  deliveryActions = DeliveryActions;
  suppliers: any[];

  totals = {
    total: 0,
    tax: 0,
    subtotal: 0
  };

  parameters = {
    code: '',
    'contact.name': '',
    tags: '',
    total: '',
    expectedDate: [],
    createdAt: [],
    status: '',
    materialStatus: '',
    itemStatus: '',
    isReceived: '',
    contactId: '',
    productionStatus: '',
    type: InvoiceType.Purchase,
    page: 1,
    itemsPerPage: 20,
    'currency.code': '',
    deliveryStatus: [
      InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING,
      InvoiceDeliveryStatus.DELIVERY_STATUS_READY,
      InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED,
      InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED
    ].join(',')
  };

  stockStatuses = [];
  productionStatuses = [];
  deliveryStatuses = [];
  display: boolean;

  constructor(private http: HttpService, private dialogService: DialogService, private route: ActivatedRoute,
              private translate: TranslateService, private utilityService: UtilityService, private currencyService: CurrencyService,
              private dataTableSettingService: DataTableSettingService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.entities.push('invoice');
    this.currency = this.currencyService.getCurrency().code;
    setTimeout(() => {
      this.suppliers = [
        { name: this.translate.instant('ALL'), value: ''}
      ];
    }, 500);
    this.loadSuppliers();
  }

  load(event: LazyLoadEvent = null): void {

    setTimeout(() => {
      this.stockStatuses = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceStockStatus.StatusAvailable.toString()), value: InvoiceStockStatus.StatusAvailable},
        {name: this.translate.instant(InvoiceStockStatus.StatusNotAvailable.toString()), value: InvoiceStockStatus.StatusNotAvailable},
        {name: this.translate.instant(InvoiceStockStatus.StatusExpected.toString()), value: InvoiceStockStatus.StatusExpected},
      ];


      this.productionStatuses = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Not_Started), value: InvoiceProductionStatus.Status_Not_Started},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Started), value: InvoiceProductionStatus.Status_Started},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Paused), value: InvoiceProductionStatus.Status_Paused},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Finished), value: InvoiceProductionStatus.Status_Finished},
      ];

      this.deliveryStatuses = [
        {name: this.translate.instant('ALL'), value: [
            InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING,
            InvoiceDeliveryStatus.DELIVERY_STATUS_READY,
            InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED,
            InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED
          ].join(',')},
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_READY), value: InvoiceDeliveryStatus.DELIVERY_STATUS_READY},
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING), value: InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING},
        // tslint:disable-next-line:max-line-length
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED), value: InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED},
        // tslint:disable-next-line:max-line-length
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED), value: InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED}
      ];
    }, 500);

    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    this.route.data.subscribe(data => {this.parameters.type =  data.type; });

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));

    let parameters = {...this.parameters};

    if (this.parameters.expectedDate) {
      const start = new Date(this.parameters.expectedDate[0]);
      const end = new Date(this.parameters.expectedDate[1]);

      parameters = {...parameters, ...{
        'expectedDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
        'expectedDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
      }};

      delete parameters.expectedDate;
    }
    if (this.parameters.createdAt) {
      const start = new Date(this.parameters.createdAt[0]);
      const end = new Date(this.parameters.createdAt[1]);

      parameters = {...parameters, ...{
        'createdAt[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
        'createdAt[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
      }};

      delete parameters.expectedDate;
    }

    this.loading = true;
    this.http.get(InvoiceModel.IRI, parameters).subscribe((response => {
      this.totalPrice = 0;
      this.total = response['hydra:totalItems'];
      this.items = response['hydra:member'];

      this.items.map(((item: InvoiceModel) => {
        this.totalPrice += item.normalizedTotal;
      }));

      this.loading = false;
    }));

    this.http.get(`/api/invoices/totals`, parameters).subscribe((response: any) => {
      this.totals = response;
    });
  }
  loadSuppliers(): void {
    this.http.get(ContactModel.IRI, {type: 'supplier'}).subscribe((response: ContactModel) => {
      response['hydra:member'].map((items: any) => {
        this.suppliers.push({ name: items.name, value: items['@id'] });
      });
    });
  }
  receive(id: number): void {

    const ref = this.dialogService.open(InvoiceReceiveComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('PURCHASE_RECEIVE'),
    });

    ref.onClose.subscribe(() => {
      this.load();
    });
  }

  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;

    this.http.download('/api/data/exports/invoices', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'invoices.xlsx'); });
  }

  loadWorkOrders(id: number): void {
    this.workOrdersLoading = true;
    this.workOrders = [];
    this.http.get(WorkOrderModel.IRI, {'invoice.id': id}).subscribe((response: WorkOrderModel[]) => {
      this.workOrders = response['hydra:member'];
      this.workOrdersLoading = false;
    });
  }

  openChangeStatusPanel(panel, event, id: number): void {
    this.changeStatusItems = [
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED), state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED}},
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED), state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED  }},
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED), state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED}},
    ];

    panel.toggle(event);
  }

  changeStatus(id: number, status: string): any {
    this.loading = true;
    this.http.put(`${InvoiceModel.IRI}/${id}`, {deliveryStatus: status}).then(response => {
      this.load();
    });
  }

  closeCalendar(field: string): void {
    let isClose = true;

    if (field === 'expectedDate' ) {
      this.parameters.expectedDate.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    } else {
      this.parameters.createdAt.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    }

    if (isClose) {
      this.createdAt.overlayVisible = false;
      this.createdAt.datepickerClick = true;
      this.expectedDate.overlayVisible = false;
      this.expectedDate.datepickerClick = true;
    }
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      this.parameters.type,
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  onReorder(event): void {
    this.http.post('/api/reorder', {drag: this.items[event.dragIndex].id, drop: this.items[event.dropIndex].id});
  }
  openForm(): void {
    this.item = new InvoiceModel();
    this.display = true;
  }
  reload(): void {
    this.load();
    this.display = false;
  }
}
