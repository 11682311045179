import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FlashMessageModel} from '../model/flash-message.model';

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {

  flashMessages = new BehaviorSubject<FlashMessageModel>(null);

  flashMessagesData = this.flashMessages.asObservable();

  constructor() { }

  updateMessages(message: FlashMessageModel): void {
    this.flashMessages.next(message);
  }
}
