import {Component, ViewChild, ElementRef, AfterViewInit, OnInit, ChangeDetectorRef} from '@angular/core';
import {WorkOrderModel} from '../../../model/work-order.model';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService, LazyLoadEvent, TreeNode} from 'primeng/api';
import {Table, TableService} from 'primeng/table';
import {InvoiceProductionStatus, InvoiceStockStatus} from '../../../model/invoice.model';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../../model/user.model';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {SidebarService} from '../../../service/sidebar.service';
import {PackOptionModel} from '../../../model/pack-option.model';

@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.scss'],
  providers: [TableService, Table]
})
export class WorkOrderListComponent implements AfterViewInit {

  @ViewChild('calendar') calendar;
  @ViewChild('printable') printableDiv: ElementRef | undefined;

  items: WorkOrderModel[] = [];
  total: number;
  loading: boolean;
  user: UserModel = new UserModel();
  settings = [];

  parameters = {
    page: 1,
    barcode: '',
    code: '',
    plannedQuantity: '',
    plannedTime: '',
    status: '',
    materialStatus: '',
    deadline: [],
    itemsPerPage: 20,
    planning: false,
    'invoice.contact.name': '',
    finalQuantity: ''
  };

  stockStatuses = [];
  productionStatuses = [];
  moreLoading: boolean;
  newWorkOrder: boolean;
  display: boolean;
  workOrderId: number;
  detailDisplay: boolean;
  workOrderForDetail: any;
  tableLoading: boolean;
  finishedDisplay: boolean;
  selectedWorkOrder: WorkOrderModel;
  packStocks: PackOptionModel[] = [];
  packOption: boolean;
  finalQuantity: number;
  isPackSavilable: boolean;
  showCode = false;

  constructor(private http: HttpService, private translate: TranslateService,
              private dataTableSettingService: DataTableSettingService,
              public sidebarService: SidebarService, private cdr: ChangeDetectorRef,
              private confirmationService: ConfirmationService) {
  }

  ngAfterViewInit(): void {
    this.sidebarService.getDisplay().subscribe((display) => {
      this.newWorkOrder = display;
      this.load();
    });
    this.newWorkOrder = false;
    this.cdr.detectChanges();
  }

  load(event: LazyLoadEvent = null, loading = true): void {

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    if (loading === true) {
      this.loading = true;
    }


    setTimeout(() => {
      this.stockStatuses = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceStockStatus.StatusAvailable.toString()), value: InvoiceStockStatus.StatusAvailable},
        {name: this.translate.instant(InvoiceStockStatus.StatusNotAvailable.toString()), value: InvoiceStockStatus.StatusNotAvailable},
        {name: this.translate.instant(InvoiceStockStatus.StatusExpected.toString()), value: InvoiceStockStatus.StatusExpected},
      ];

      this.productionStatuses = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Not_Started), value: InvoiceProductionStatus.Status_Not_Started},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Started), value: InvoiceProductionStatus.Status_Started},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Paused), value: InvoiceProductionStatus.Status_Paused},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Finished), value: InvoiceProductionStatus.Status_Finished},
      ];
    }, 500);

    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.parameters['exists[parent]'] = false;
    this.parameters.planning = false;

    let parameters = {...this.parameters};

    if (this.parameters.deadline) {

      const start = new Date(this.parameters.deadline[0]);
      const end = new Date(this.parameters.deadline[1]);

      parameters = {
        ...parameters, ...{
          'deadline[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'deadline[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
          'order[id]': `DESC`,
        }
      };

      delete parameters.deadline;
    }

    this.http.get(WorkOrderModel.IRI, parameters).subscribe((response: WorkOrderModel[]) => {
      // @ts-ignore
      this.total = response['hydra:totalItems'];
      this.items = response['hydra:member'];
      this.loading = false;
      this.tableLoading = false;
    });
  }

  closeCalendar(): void {
    let isClose = true;
    this.parameters.deadline.map(item => {
      if (!item) {
        isClose = false;
      }
    });

    if (isClose) {
      this.calendar.overlayVisible = false;
      this.calendar.datepickerClick = true;
    }
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      'workOrders',
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  changeStatus(id, status, workOrder): void {
    if (status === 'FINISHED') {
      this.finishedDisplay = true;
      this.finalQuantity = workOrder.plannedQuantity;
      this.selectedWorkOrder = workOrder;
      this.http.get(PackOptionModel.IRI, {
        // @ts-ignore
        stock: this.selectedWorkOrder.stock.id
      }).subscribe((response) => {
        this.packStocks = response['hydra:member'];
      });
    } else {
      this.tableLoading = true;
      this.http.put(`${WorkOrderModel.IRI}/${id}/change_status`,
        {
          status: status
        }
      ).then((response) => {
        this.load(null, false);
      });
    }
  }
  workOrderFinishSave(): void {
    this.confirmationService.confirm({
      message: this.translate.instant('ALERT_COMPLETE_OPERATION'),
      accept: () => {
        // @ts-ignore
        this.http.put(`${WorkOrderModel.IRI}/${this.selectedWorkOrder.id}/change_status`,
          {
            status: 'FINISHED',
            packStocks : this.packStocks,
            finalQuantity : this.finalQuantity,
          }
        ).then((response) => {
          this.load(null, false);
          this.finishedDisplay = false;
        });
      }
    });
  }

  createNewWorkOrder(): void {
    this.newWorkOrder = true;
  }

  editWorkOrder(row): void {
    this.workOrderId = row.id;
    this.newWorkOrder = true;
  }

  showDetail(row): void {
    this.workOrderForDetail = row;
    this.detailDisplay = true;
  }

  print(): void {
    this.detailDisplay = false;
    const printContents = this.printableDiv.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  close(): void {
    this.finishedDisplay = false;
    this.load();
  }

  onRecipeOrPackOption(): void {
    if (this.packOption === true){
      this.packOption = false;
    }else {
      this.packOption = true;
    }
  }

  produceControl(): void{
    console.log(this.items)
    let total = 0;
    this.isPackSavilable = false;
    this.packStocks.map(item => {
      total = total + (item.quantity * item.finalQuantity);
      if ( total > this.finalQuantity) {
        this.isPackSavilable = true;
      }
    });
  }

  setFinalQuantity(event): void {
    this.finalQuantity = parseFloat(event.target.value)
  }

}
