import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-stock-list-menu',
  templateUrl: './stock-list-menu.component.html',
  styleUrls: ['./stock-list-menu.component.scss']
})
export class StockListMenuComponent implements OnInit {

  menuItems: MenuItem[];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuItems = [
        {label: this.translate.instant('STOCKS'), icon: 'pi pi-table', routerLink: '/stocks'},
        {label: this.translate.instant('STOCK_TRANSFERS'), icon: 'pi pi-send', routerLink: '/stock-transfers'},
        {label: this.translate.instant('STOCK_ADJUSTMENTS'), icon: 'pi pi-pencil', routerLink: '/stock-adjustments'},
        {label: this.translate.instant('STOCK_REQUESTS'), icon: 'pi pi-envelope', routerLink: '/stock-requests'},
        {label: this.translate.instant('STOCK_MOVEMENTS'), icon: 'pi pi-sort-alt', routerLink: '/stock-movements'},
        {label: this.translate.instant('INVENTORY'), icon: 'pi pi-sort-alt', routerLink: '/inventory'},
      ];
    }, 500);
  }

}
