import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntegrationModel} from '../../../../model/integration.model';
import {CompanyIntegrationModel} from '../../../../model/company-integration.model';
import {LucaComponent} from '../../../settings/integrations/luca/luca.component';
import {ParasutComponent} from '../../../settings/integrations/parasut/parasut.component';
import {LogoComponent} from '../../../settings/integrations/logo/logo.component';
import {EfaturaComponent} from '../../../settings/integrations/efatura/efatura.component';
import {environment} from '../../../../../environments/environment';
import {KolaybiComponent} from '../../../settings/integrations/kolaybi/kolaybi.component';
import {HttpService} from '../../../../service/http.service';
import {DialogService} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {

  loading: boolean;
  items: MenuItem[];
  integrations: IntegrationModel[] = [];
  kolayBiConfiguration = {
    clientId: null,
    clientSecret: null,
    redirectUri: null,
    apiUrl: null,
    channel: null
  };
  partner: string = null;
  code: string = null;
  activeIntegrations = [];
  activeIndex = 2;
  constructor(private translate: TranslateService,
              private router: Router, private http: HttpService,
              private activatedRoute: ActivatedRoute, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.items = [
      {label: this.translate.instant('POS_INTEGRATION_STEP'), routerLink: 'POS_INTEGRATION'},
      {label: this.translate.instant('ACCOUNT_INTEGRATION_STEP'), routerLink: 'ACCOUNT_INTEGRATION'},
      {label: this.translate.instant('E_INVOICE_INTEGRATION_STEP'), routerLink: 'E_INVOICE_INTEGRATION_STEP'},
      {label: this.translate.instant('STOCK_IMPORT_STEP'), routerLink: 'STOCK_IMPORT'},
    ];
    this.kolayBiConfiguration = environment.kolayBi;
    this.partner = this.activatedRoute.snapshot.queryParamMap.get('partner');
    this.code = this.activatedRoute.snapshot.queryParamMap.get('code');

    if (this.partner && this.partner === 'kolaybi' && this.code) {
      this.http.get(`${CompanyIntegrationModel.IRI}/kolaybi-connect`, {code: this.code}).subscribe(response => {
        if (response) {
          this.activeIntegrations[response.integration['@id']] = response.id;

          this.dialogService.open(KolaybiComponent, {
            width: '50vw',
            header: '',
          });
        }
      });
    }
    this.load();
  }
  load(): void {
    this.loading = true;
    this.http.get(IntegrationModel.IRI).subscribe((response: IntegrationModel[]) => {
      this.integrations = response['hydra:member'];
      this.loading = false;
    });

    this.loadIntegrations();
  }

  loadIntegrations(): void {
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.activeIntegrations = [];
      response['hydra:member'].map((item: CompanyIntegrationModel) => {
        if (item.isActive) {
          // @ts-ignore
          this.activeIntegrations[item.integration['@id']] = item.id;
        }
      });
    });
  }
  openModal(id: number): void {
    const ref = this.dialogService.open(LucaComponent, {
      width: '50vw',
      data: {id},
      header: 'LUCA',
    });

    ref.onClose.subscribe((data) => {
      if (data && data.integration !== undefined && data.integration) {
        this.loadIntegrations();
      }
    });
  }

  connectKolayBi(): void {
    window.location.href = `${this.kolayBiConfiguration.apiUrl}/oauth/authorize?client_id=${this.kolayBiConfiguration.clientId}&redirect_uri=${this.kolayBiConfiguration.redirectUri}&client_secret=${this.kolayBiConfiguration.clientSecret}&response_type=code`;
  }

  connectParasut(isConnected: boolean): void
  {
    const ref = this.dialogService.open(ParasutComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Paraşüt',
    });
  }
  connectLogo(isConnected: boolean): void
  {
    const ref = this.dialogService.open(LogoComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Logo',
    });
  }

  connectEfatura(isConnected: boolean): void
  {
    const ref = this.dialogService.open(EfaturaComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'E-Fatura',
    });
  }
  async disconnect(id: number): Promise<void> {
    await this.http.put(`${CompanyIntegrationModel.IRI}/${id}`, {isActive: false}).then(response => {
      if (response) {
        this.loadIntegrations();
      }
    });
  }
  nextPage(): void {
    this.router.navigate(['initial-setup/select-einvoice']).then(r => {
    });
  }

  previousPage(): void {
    this.router.navigate(['initial-setup/select-pos']).then(r => {
    });
  }

}
