<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div style="
width: 60%;position: absolute;top:0px;bottom: 0px;
background-image: url('assets/tax.jpeg');
background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: -1;
    opacity: 0.1;
"></div>
<div class="p-grid">
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-6 p-lg-offset-0 p-sm-offset-0 p-xl-offset-3 p-md-offset-0">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'ADD_TAX_PAGE_TITLE' | translate}}
          <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}"> {{ 'ADD_TAX_PAGE_DESCRIPTION' | translate}}</p>
        </h1>
      </div>
      <div class="p-col-12" style="background: #ffffff9e;
    padding: 40px 8px;
    border-radius: 10px;">
        <p-table [loading]="loading" [lazy]="true" [value]="items">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '80%'}">{{'TAX' | translate}} <app-tooltip description="TAX_RATE_NAME_TOOLTIP"></app-tooltip> </th>
              <th [style]="{width: '20%'}">{{'RATE' | translate}} <app-tooltip description="TAX_RATE_TOOLTIP"></app-tooltip></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>{{ row.name }}</td>
              <td [style]="{textAlign: 'center'}">{{ row.rate / 100|percent }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td colspan="2">
                <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}" icon="pi pi-plus"
                        (click)="openForm()"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>
      <div class="p-p-3 p-text-left">
        <p-button label="İleri" (onClick)="nextPage()" [style]="{float: 'right', marginTop:'10px'}"
                  icon="pi pi-angle-right" iconPos="right">
        </p-button>
        <p-button label="Geri" (onClick)="previousPage()" [style]="{float: 'right', marginTop:'10px',marginRight:'10px'}"
                  icon="pi pi-angle-left" iconPos="left">
        </p-button>

      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

