import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {LocationModel} from '../../../model/location.model';
import { TranslateService} from '@ngx-translate/core';
import {LocationFunctionModel} from '../../../model/location-function.model';
import {AddressComponent} from '../../address/address.component';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../service/validation.service';
import {Subscription} from 'rxjs';
import {TreeNode} from 'primeng/api';
import { LocationService } from '../../../service/location.service';
import {ActivatedRoute} from '@angular/router';
import {CurrencyModel} from '../../../model/currency.model';
import {IntegrationModel} from '../../../model/integration.model';
import {environment} from '../../../../environments/environment';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {KolaybiComponent} from '../integrations/kolaybi/kolaybi.component';
import {LucaComponent} from '../integrations/luca/luca.component';
import {ParasutComponent} from '../integrations/parasut/parasut.component';
import {LingaComponent} from '../integrations/linga/linga.component';
import {LogoComponent} from '../integrations/logo/logo.component';
import {EfaturaComponent} from '../integrations/efatura/efatura.component';
import {CategoryModel} from '../../../model/category.model';
import {CategoryService} from '../../../service/category.service';
import {StockAccountingCodeModel} from '../../../model/stock-accounting-code.model';
import {CompanyModel} from '../../../model/company.model';
import {ContactModel} from '../../../model/contact.model';
import {ContactService} from '../../../service/contact.service';
import {TikoHesapComponent} from '../integrations/tiko-hesap/tiko-hesap.component';
import {BizimHesapComponent} from '../integrations/bizim-hesap/bizim-hesap.component';
import {PoscanaComponent} from '../integrations/poscana/poscana.component';
import {SquareComponent} from '../integrations/square/square.component';
import {AdisyoComponent} from '../integrations/adisyo/adisyo.component';
import {PaycellComponent} from '../integrations/paycell/paycell.component';
import { SimpraComponent } from '../integrations/simpra/simpra.component';
import {IsBasiComponent} from '../integrations/is-basi/is-basi.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { MenuluxComponent } from '../integrations/menulux/menulux.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [DialogService]
})
export class LocationComponent implements OnInit, OnDestroy {
  items: LocationModel[] = [];
  item: LocationModel = new LocationModel();
  functions: LocationFunctionModel[] = [];
  selectedFunctions: string[] = [];
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean;
  errors = [];
  files: TreeNode[];
  data: TreeNode[];
  validationSubscriber: Subscription;
  codeLoading: boolean;
  parameters = {
    name: '',
    page: 0,
    itemsPerPage: 10
  };
  moreLoading: boolean;
  total: 0;
  inventoryCountTypes: any [];
  currencies: CurrencyModel[] = [];
  showAccounting: boolean;
  showEInvoice: boolean;
  tabMenuItems: MenuItem[];
  activeItem: MenuItem;
  integrations: IntegrationModel[] = [];
  activeIntegrations = [];
  partner: string = null;
  code: string = null;
  kolayBiConfiguration = {
    clientId: null,
    clientSecret: null,
    redirectUri: null,
    apiUrl: null,
    channel: null
  };

  isBasiConfiguration = {
    ApiKey: null,
    returnUrl: null,
    apiUrl: null,
  };
  activeLocation: string;
  categories: CategoryModel[] = [];
  accountingCodes: StockAccountingCodeModel[] = [];
  values: any[] = [];
  contacts: ContactModel[] = [];
  activeLocationName: string;
  constructor(private http: HttpService, private confirmationService: ConfirmationService,
              private translate: TranslateService, private flashMessageService: FlashMessageService,
              private dialog: DialogService, private validationService: ValidationService,
              private locationService: LocationService, private activatedRoute: ActivatedRoute,
              private dialogService: DialogService, private categoryService: CategoryService,
              private contactService: ContactService, private httpClient: HttpClient
              ) { }

  ngOnInit(): void {
    this.kolayBiConfiguration = environment.kolayBi;
    this.isBasiConfiguration = environment.isBasi;
    this.partner = this.activatedRoute.snapshot.queryParamMap.get('partner');
    this.code = this.activatedRoute.snapshot.queryParamMap.get('code');

    this.categoryService.getCategoriesAction({categoryType: 'stock'});
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
    });
    this.contactService.getContactsAction({type: 'supplier'});
    this.contacts = this.contactService.getSuppliers();
    this.http.get(IntegrationModel.IRI, { pagination: false, itemsPerPage: 200}).subscribe((response: IntegrationModel[]) => {
      this.integrations = response['hydra:member'];
    });

    if (this.partner && this.partner === 'kolaybi' && this.code) {
      this.http.get(`${CompanyIntegrationModel.IRI}/kolaybi-connect`, {code: this.code}).subscribe(response => {
        if (response) {
          this.activeIntegrations[response.integration['@id']] = response.id;
          this.dialogService.open(KolaybiComponent, {
            width: '50vw',
            header: '',
          });
        }
      });
    }
    if (this.partner && this.partner === 'logoisbasi' && this.code) {
      this.http.get(`${CompanyIntegrationModel.IRI}/logoIsBasi-connect`,
        {code: this.code, location: localStorage.getItem('activeLocation')}).subscribe(response => {
        if (response) {
          this.activeIntegrations[response.integration['@id']] = response.id;
          this.dialogService.open(IsBasiComponent, {
            width: '50vw',
            header: '',
          });
        }
      });
    }
    this.load();
    this.loadFunctions();
    this.getFiles(null);
    this.inventoryCountTypes = [
      { name: this.translate.instant('CONSUME_UNSOLD_STOCK'), value: 'CONSUME_UNSOLD_STOCK' },
      { name: this.translate.instant('TRANSFER_UNSOLD_STOCK'), value: 'TRANSFER_UNSOLD_STOCK' }
    ];

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
    });

    this.tabMenuItems = [
      {label: this.translate.instant('POS'), command: () => this.activeItem =  this.tabMenuItems[0]},
      {label: this.translate.instant('ACCOUNTING'), command: () => this.activeItem =  this.tabMenuItems[1]},
      {label: this.translate.instant('INTEGRATOR'), command: () => this.activeItem =  this.tabMenuItems[2]},
      {label: this.translate.instant('ACCOUNTING_GROUP_CODES'), command: () => this.activeItem = this.tabMenuItems[3]}
    ];
    this.activeItem = this.tabMenuItems[0];
  }

  load(type = null): void {
    this.loading = true;
    this.loadIntegrations();

    if (type !== 'search'){
      this.parameters.page = this.parameters.page + 1;
    }else {
      this.parameters.page = 1;
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    const parameters = {...this.parameters};
    this.loading = true;
    this.http.get(LocationModel.IRI, parameters).subscribe((response: LocationModel[]) => {
      this.items = response['hydra:member'];
      // @ts-ignore
      this.total = response['hydra:totalItems'];
      if (this.total >= this.parameters.page * this.parameters.itemsPerPage){
        this.moreLoading = true;
      }else{
        this.moreLoading = false;
      }
    });

    this.loadFunctions();
    this.getFiles(null);
  }

  async getFiles(id: number| null): Promise<void> {
    this.loading = true;
    await this.locationService.getFilesystem().then(files => this.files = files);
    this.data = this.loadtree(this.files);
    this.loading = false;
  }

  loadtree(treeData): any {
    const data = [];
    treeData.forEach((key, i) => {
      let d;
      const rg = d ? (d.children || (d.children = [])) : data;
      d = data.find(e => e.data.children === null) || (data[data.length] =
          { data: {
                  name: key.name,
                  isActive: key.isActive,
                  isDefault: key.isDefault,
                  id: key.id,
                  address: key.address,
                  code: key.code,
                  functions: key.functions,
                  parentId: key.parent,
                  inventoryCountType: key.inventoryCountType,
                  storeId: key.storeId,
                  currency: key.currency,
                  contact: key.contact,
                  accountingCode: key.accountingCode
                },
              children: this.loadtree(key.children)
        });
    });
    return data;
  }

  loadFunctions(): void {
    this.http.get(LocationFunctionModel.IRI).subscribe((response: LocationFunctionModel[]) => {
      this.functions = response['hydra:member'];
    });
  }

  openForm(): void {
    this.http.get(`${LocationModel.IRI}/location-number`).subscribe((response: LocationModel) => {
      this.item.code = response.code;
      this.showForm = true;
    });
  }

  edit(row): void {
    this.item = JSON.parse(JSON.stringify(row));
    if ( this.item.currency){
      this.item.currency = this.item.currency['@id'];
    }
    if ( this.item.contact){
      this.item.contact = this.item.contact['@id'];
    }
    if (this.item.parent) {
      this.item.parent = this.item.parent['@id'];
    }else{
      this.item.parent = this.item.parentId;
    }
    this.showForm = true;
    this.item.functions.map((f, i) => {
      this.item.functions[i] = f['@id'];
    });
  }

  changeStatus(row): void {
    this.http.put(`${LocationModel.IRI}/${row.id}`, {isActive: row.isActive}).then((response) => {
      row.isActive = response.isActive;
    });
  }

  hideForm(): void {
    this.errors = [];
    this.showForm = false;
    this.item = new LocationModel();
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    if (this.item.id){
      await this.http.put(`${LocationModel.IRI}/${this.item.id}`, this.item).then((response: LocationModel) => {
        if (response){
          this.hideForm();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          this.load();
        }
      });
    }else{
      await this.http.post(LocationModel.IRI, this.item).then((response: LocationModel) => {
        if (response){
          this.items.push(response);
          this.hideForm();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_UPDATED'));
        }
      });
    }
    setTimeout(() => {
      // window.location.reload();
    }, 2000);
    this.submitted = false;
  }

  openAddressDialog(id: number, address: string): void {
    this.loading = true;
    const ref = this.dialog.open(AddressComponent, {
      data: {id: address},
      width: '50vw',
      header: this.translate.instant('ADDRESS'),
    });
    this.loading = false;
    ref.onClose.subscribe(response => {
      if (response && response.saved) {
        this.http.put(`${LocationModel.IRI}/${id}`, {address: response.id}).then((locationModel: LocationModel) => {
          if (locationModel) {
            this.load();
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_UPDATED'));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      }
    });
  }

  getParentNumber = (event) => {
    this.codeLoading = true;
    this.http.get(`${LocationModel.IRI}/location-number?location=${event.value}`).subscribe((response: LocationModel) => {
      this.item.code = response.code;
      this.codeLoading = false;
    });
  }

  addAccounting(row): void {
    this.activeLocation = row.id;
    localStorage.setItem('activeLocation', '/api/locations/' + row.id);
    this.activeLocationName = row.name;
    this.showAccounting = true;
    this.http.get(`${StockAccountingCodeModel.IRI}/location-code?location=${row.id}`).subscribe((response) => {
      this.accountingCodes = response['hydra:member'];
      this.codeLoading = false;
      this.accountingCodes.map((item) => {
        this.values[item.category.id] = item.code;
      });
    });
  }
  saveAccountingCode(id, event, activeLocation): void {
    this.http.post(`${StockAccountingCodeModel.IRI}/post-location-code`, {
      location: '/api/locations/' + activeLocation,
      category: id,
      code: event.target.value
    }).then(response => {
      if (response) {
      }
    });
}

  loadIntegrations(): void {
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.activeIntegrations = [];
      response['hydra:member'].map((item: CompanyIntegrationModel, ) => {
        if (item.isActive && item.location) {
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          this.activeIntegrations[item.location.id + '/' + item.integration.id] = {integrationId: item.integration.id, id: item.id};
        }
      });
    });
  }

  openModal(id: number): void {
    const ref = this.dialogService.open(LucaComponent, {
      width: '50vw',
      data: {id},
      header: 'LUCA',
    });

    ref.onClose.subscribe((data) => {
      if (data && data.integration !== undefined && data.integration) {
        this.loadIntegrations();
      }
    });
  }

  connectKolayBi(): void {
    window.location.href = `${this.kolayBiConfiguration.apiUrl}/oauth/authorize?client_id=${this.kolayBiConfiguration.clientId}&redirect_uri=${this.kolayBiConfiguration.redirectUri}&client_secret=${this.kolayBiConfiguration.clientSecret}&response_type=code`;
  }

  header(): HttpHeaders {
    const ApiKey = this.isBasiConfiguration.ApiKey;
    return new HttpHeaders({
        ApiKey
      });

  }

  connectIsBasi(isConnected: boolean, location: string): void {
     const ref = this.dialogService.open(IsBasiComponent, {
       width: '50vw',
       data: {isConnected, location},
       header: 'İş Başı',
     });

  }

  connectParasut(isConnected: boolean,  location: string): void
  {
    const ref = this.dialogService.open(ParasutComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Paraşüt',
    });
  }

  connectLinga(isConnected: boolean, location: string): void
  {
    console.log(this.integrations);
    const ref = this.dialogService.open(LingaComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Linga',
    });
  }

  connectLogo(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(LogoComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Logo',
    });

  }

  connectEfatura(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(EfaturaComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'E-Fatura',
    });
  }

  connectTikoHesap(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(TikoHesapComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Tiko Hesap',
    });
  }
  connectBizimHesap(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(BizimHesapComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Bizim Hesap',
    });
  }
  connectPoscana(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(PoscanaComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Poscana',
    });
  }
  connectSquare(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(SquareComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Square',
    });
  }
  connectAdisyo(isConnected: boolean, location: string, name: string): void
  {
    const ref = this.dialogService.open(AdisyoComponent, {
      width: '50vw',
      data: {isConnected, location, name},
      header: 'Adisyo',
    });
  }
  connectPaycell(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(PaycellComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Paycell',
    });
  }
  connectSimpra(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(SimpraComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Simpra',
    });
  }
  connectMenulux(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(MenuluxComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Menulux',
    });
  }

  async disconnect(id: number): Promise<void> {
    await this.http.put(`${CompanyIntegrationModel.IRI}/${id}`, {isActive: false}).then(response => {
      if (response) {
        this.loadIntegrations();
      }
    });
  }
  addContact(event): void {
    this.item.contact = event.value;
  }

  ngOnDestroy(): void {
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
    this.errors = [];
  }
}
