import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scanned-invoices',
  templateUrl: './scanned-invoices.component.html',
  styleUrls: ['./scanned-invoices.component.scss']
})
export class ScannedInvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
