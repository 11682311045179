import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpService} from '../../service/http.service';
import {CompanyIntegrationModel} from "../../model/company-integration.model";

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent implements OnInit {

  @Input() entities = [];
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpService) { }

  syncLoading = false;
  show = false;
  company: any;

  ngOnInit(): void {
    this.company = JSON.parse(localStorage.getItem('company'));

    if (this.company) {
      this.http.get(CompanyIntegrationModel.IRI, {'company.id': this.company.id}).subscribe(response => {
        if (response['hydra:totalItems'] > 0) {
          this.show = true;
        }
      });
    }
  }

  sync(): void {
    this.syncLoading = true;
    let queryString = '';
    this.entities.map(entity => {
      queryString += `&entities[]=${entity}`;
    });

    queryString = queryString.replace('&', '?');

    this.http.get(`/api/integration/sync/Luca${queryString}`).subscribe(() => {
      this.syncLoading = false;
      this.callback.emit();
    });
  }
}
