import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';

export class StockAdjustmentItemModel extends ApiResourceModel {
  stock: StockModel|string;
  stockVariant: StockVariantModel;
  quantity: number;
  inStock: number;
  unitPrice: number;
  totalPrice: number;
  normalizedUnitPrice: number;
  normalizedTotalPrice: number;
  barcode: string;
  expirationDate: Date = null;
  trackType: string;
}
