import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { IntegrationLog } from '../model/integration-log.model';
import { HttpService } from './http.service';
import { IntegrationModel } from '../model/integration.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private data = new BehaviorSubject([]);
  sharedData = this.data.asObservable();

  constructor(private translate: TranslateService, private http: HttpService ) {
  }
  startTimer(): void {
    setInterval(() => {
      const newData = [{ name: 'STOCK_REMAINING',
        title: this.translate.instant('STOCK_IMPORT_REMAINING'),
        url: 'aaaaaaa'}];
      this.setData(newData);
    }, 2000);
  }

  setData(data): void {
    this.data.next(data);
  }
  getIntegrationLogs() : any{
    const company = JSON.parse(window.localStorage.getItem('company') || '{}');
    const companyId = company.id;
    this.http.get(IntegrationLog.IRI, {isShow: false, company: companyId}).subscribe((response)=>{
      this.setData(response['hydra:member']);
    });
  }
}
