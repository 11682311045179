<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading" id="pdf-content">

  <div class="p-grid" style="margin: -1.5rem 0;">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'DAILY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'daily-income'"></app-page-info>
      <p-button icon="pi pi-file-excel" (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid" id="button">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start"  selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>

    <app-report-tabs pageName="daily-income"></app-report-tabs>
<div >
  <div class="p-grid">
    <div class="p-col-4" style="overflow-x: auto;">
      <div class="p-col div-sums" [style]="{padding: '0px !important'}">
        <div>
          <div class="inside-div-no-background">
            <table class="table-section">
              <tr>
                <td colspan="2" [style]="{width: '50%',fontSize:'18px'}"><b
                  class="fw-500">{{'INCOMES' | translate}}</b></td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'TOTAL' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b>{{sale?.subTotal | currency: info.currency}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'DISCOUNT' | translate}} + {{'UNPAID' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b> {{sale?.discount | currency: info.currency}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'TOTAL_GROSS_INCOME' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b>{{sale?.total | currency: info.currency}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'TAX' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b> {{sale?.tax | currency: info.currency}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'TOTAL_NET_INCOME' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b>   {{sale?.netTotal | currency: info.currency}}</td>

              </tr>
            </table>
            <hr>
            <table class="table-section">
              <tr>
                <td colspan="3" [style]="{width: '50%',fontSize:'18px'}">
                  <b class="fw-500">{{'CZECH_AND_COVER_AVERAGE' | translate}}</b></td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'TOTAL_CZECH' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b>{{sale?.saleCount}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'AVERAGE_CZECH_INCOME' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b> {{sale?.averageSale | currency: info.currency}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'TOTAL_COVER' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b> {{sale?.cuver}}</td>
              </tr>
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{'AVERAGE_COVER_INCOME' | translate}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                  class="fw-500"></b> {{sale?.averageCuver | currency: info.currency}}</td>
              </tr>
            </table>
            <hr>
            <table class="table-section">
              <thead>
              <tr>
                <th [style]="{fontSize:'18px',float: 'left'}"><b class="fw-500">{{'TOTAL_INCOME' | translate}}</b></th>
                <th [style]="{width: '50%',textAlign:'right',fontSize:'22px'}">
                    {{sale?.netTotal | currency: info.currency}}
                </th>
              </tr>
              </thead>
              <tbody *ngFor=" let category of categories.categories">
              <tr [style]="{borderBottom: '1px solid #ffffff'}">
                <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                  class="fw-500">{{ category.name}}</b></td>
                <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}">
                  {{ (category.sumSales?.total ? category.sumSales?.total : 0)  | currency: info.currency}}</td>
              </tr>
              </tbody>
            </table>
            <hr>
            <p-chart type="pie" [data]="dataIncoming" [style]="{'width': '40%'}"></p-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-8">
      <div class="p-col div-sums" style="padding: 0px !important">
        <div>
          <div class="inside-div-no-background" style="overflow: auto;max-height: 600px; padding: 3px !important">
            <p-table [value]="saleDates" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width: '13%'}">{{'DATE' | translate}}</th>
                  <th [style]="{width: '12%'}">{{'TOTAL' | translate}}</th>
                  <th [style]="{width: '10%'}">{{'DISCOUNT' | translate}}</th>
                  <th [style]="{width: '10%'}">{{'GROSS_INCOME' | translate}}</th>
                  <th [style]="{width: '12%'}">{{'TAX' | translate}}</th>
                  <th [style]="{width: '12%'}">{{'NET_INCOME' | translate}}</th>
                  <th [style]="{width: '5%'}">{{'CZECH' | translate}}</th>
                  <th [style]="{width: '5%'}">{{'COVER' | translate}}</th>
                  <th [style]="{width: '10%'}">{{'AVERAGE_CZECH' | translate}}</th>
                  <th [style]="{width: '10%'}">{{'AVERAGE_COVER' | translate}}</th>
                  <th [style]="{width: '5%'}"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-dates>
                <tr >
                  <td [style]="{cursor: 'pointer', color:'#0edcdc'}" [routerLink]="'/reports/sale-reports/product-group'"
                      [queryParams]="{location: parameters.location, date: dates.saleDate}">{{dates.saleDate}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.subTotal) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.discount | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.total) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.tax | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.netTotal) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.saleCount}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.cuver}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.averageSale) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.averageCuver) | currency: info.currency}}</td>
                  <td><p-button  icon="{{isRefresh ? 'pi pi-spinner' : 'pi pi-refresh'}}" [style]="{cursor: 'pointer'}" (click)="refreshDailyReport(dates.saleDate)"></p-button></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr >
                  <td >{{'TOTAL' | translate}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.subTotal) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.discount | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.total) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.tax | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.netTotal) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.saleCount}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.cuver}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.averageSale) | currency: info.currency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.averageCuver) | currency: info.currency}}</td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" *ngIf="chartOpen">
      <p-chart type="bar" [data]="data" [options]="chartOptions" height="400px"></p-chart>
    </div>
  </div>
</div>
</p-card>

