import {ApiResourceModel} from './api-resource.model';


export class StockTransactionModel extends ApiResourceModel{
  public static IRI = '/api/stock_transactions';
  public code: string;
  public quantity: string;
  public relatedType: string;
  public date: string;
  public comment: any;
  public relatedById: any;
  public relatedItemById: any;
  public averagePrice: number;
}
