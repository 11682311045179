<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card [style]="{marginLeft:'3px'}">
  <div class="p-grid">
    <p-table [value]="logs" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" [style]="{ width:'10%' }">{{'NAME' | translate}}</th>
          <th scope="col" [style]="{width:'10%'}">{{'STATUS' | translate}}</th>
          <th scope="col" [style]="{width:'70%'}">{{'URL' | translate}}</th>
          <th scope="col" [style]="{width:'10%'}">{{'RESPONSE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.integration?.name }}</td>
          <td>{{item.success ? 'Başarılı' : 'Başarısız' }}</td>
          <td>{{item.url }}</td>
          <td><i class="pi pi-pencil" (click)="onClick(item)"></i></td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</p-card>
<p-dialog header="Title" [(visible)]="display">
  {{ response }}
</p-dialog>
