import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkOrderModel} from '../../../model/work-order.model';
import {HttpService} from '../../../service/http.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {WorkOrderMaterialModel} from '../../../model/work-order-material.model';
import {WorkOrderOperationModel} from '../../../model/work-order-operation.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {LocationModel} from '../../../model/location.model';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';

@Component({
  selector: 'app-quick-work-order-create',
  templateUrl: './quick-work-order-create.component.html',
  styleUrls: ['./quick-work-order-create.component.scss']
})
export class QuickWorkOrderCreateComponent implements OnInit, OnDestroy {

  workOrder = new WorkOrderModel();
  submitted = false;
  loading = false;
  locations: LocationModel[] = [];
  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private config: DynamicDialogConfig,
              private flashMessageService: FlashMessageService, private ref: DynamicDialogRef,
              private validationService: ValidationService) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(`${WorkOrderModel.IRI}/work_order_number`).subscribe((response: WorkOrderModel) => {

      this.workOrder = {
        ...this.workOrder,
        ...{code: response.code, series: response.series, number: response.number, deadline: response.deadline},
        ...{deadline: new Date(response.deadline)}
      };

      this.loading = false;
    });

    this.loadVariant();
    this.loadLocations();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  loadLocations(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe(response => {
      this.locations = response;

      if (this.locations.length > 0) {
        this.workOrder.source = this.locations[0]['@id'];
        this.workOrder.target = this.locations[0]['@id'];
      }
    });
  }

  loadVariant(): void {
    this.loading = true;
    this.http.get(`${StockVariantModel.IRI}/${this.config.data.id}`).subscribe((response: StockVariantModel) => {
      this.workOrder.stock = response.stock['@id'];
      this.workOrder.variant = response['@id'];
    });

    this.http.get(`${StockVariantModel.IRI}/${this.config.data.id}/recipes`).subscribe((response) => {
      response['hydra:member'].map((item, i) => {
        const material = new WorkOrderMaterialModel();
        material.plannedQuantity = item.amountWithWastage;
        material.cost = item.amountWithWastage * item.stock.price;
        material.totalQuantity =  item.amountWithWastage * this.workOrder.plannedQuantity;
        material.notes = item.note;
        material.variant = item.stock['@id'];
        material.status = '';
        material.createWorkOrder = item.stock.stock.isMaking;
        // @ts-ignore
        this.workOrder.materials.push(material);
        this.workOrder.subtotal += material.cost;
      });
    });
    this.http.get(`${StockVariantModel.IRI}/${this.config.data.id}/operations`).subscribe((response) => {
      response['hydra:member'].map((item, i) => {
        const operation = new WorkOrderOperationModel();
        operation.plannedTime = item.time;
        operation.cost = item.cost;
        operation.resource = item.resource['@id'];
        operation.operation = item.operation['@id'];
        operation.totalCost = item.totalCost;
        // @ts-ignore
        this.workOrder.operations.push(operation);
        this.workOrder.subtotal += item.totalCost;
      });
    });
  }

  async save(): Promise<void> {
    this.submitted = true;

    if (this.workOrder.id === 0) {
      delete this.workOrder.id;
      delete this.workOrder['@id'];
    }

    await this.http.post(WorkOrderModel.IRI, this.workOrder).then((response: WorkOrderModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.successCreated'));
        this.ref.close({saved: true});
      }
    }).catch(error => {
    });

    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

  getStockStatus(): void {
    let targetId = 0;

    this.locations.map(location => {
      if (location['@id'] === this.workOrder.source) {
        targetId = location.id;
      }
    });

    if (this.workOrder.plannedQuantity !== undefined || this.workOrder.plannedQuantity !== 0) {
      // tslint:disable-next-line:max-line-length
     this.http.get(`${this.workOrder.variant}/recipe/status?qty=${this.workOrder.plannedQuantity}&location=${targetId}`).subscribe((response) => {
        // @ts-ignore
        this.workOrder.materials.map((item: WorkOrderMaterialModel, i) => {
          // @ts-ignore
          this.workOrder.materials[i].status =  response['hydra:member'][i].stock.status;
          // @ts-ignore
          this.workOrder.materials[i].expectedDate =  response['hydra:member'][i].stock.expectedDate;
        });
      });
    }
  }
}
