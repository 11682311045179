import {Component, OnInit} from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../service/location.service';
import {StockModel} from '../../../../model/stock.model';
import {StockVariantModel} from '../../../../model/stock-variant.model';

@Component({
  selector: 'app-scatter-chart',
  templateUrl: './menu-analysis.component.html',
  styleUrls: ['./menu-analysis.component.scss']
})

export class MenuAnalysisComponent implements OnInit {
  events: any[] = [];
  options: any;
  info: any;
  saleDates: any;
  sale = {
    subTotal: 0,
    total: 0,
    discount: 0,
    tax: 0,
    netTotal: 0,
    cuver: 0,
    saleCount: 0,
    averageCuver: 0,
    averageSale: 0,
    averageCost: 0,
    averageCuverCost: 0,
    cost: 0
  };
  categories: any;
  menuAnalys: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  location: LocationModel[] = [];
  parameters = {
    date: [],
    startDate: '',
    lastDate: '',
    location: '',
    stock: null,
    saleDate: ''
  };
  points: any[] = [];
  sumMenuCost = 0;
  sumMenuRevenue = 0;
  sumMenuCM = 0;
  menuFootCost = 0;
  itemCm = 0;
  itemCmAverage = 0;
  percentageMenuMix = 0;
  maxQuantity = 0;
  maxProfit = 0;
  dateRange: Date[] = [];
  basicData: any;
  basicOptions: any;
  variants: StockVariantModel[] = [];
  a = 0;
  b = 0;
  c = 0;
  d = 0;

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.startDate;
        // @ts-ignore
        this.parameters.lastDate = params.lastDate;
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
        this.parameters.location = params.location ? params.location : '';

        const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));

        const selectedDate = dateRange.map(date => this.getFormattedDate(date));

        this.parameters.saleDate = selectedDate.join(',');
        this.parameters = {...this.parameters};
      }
    });
    this.load().then();
  }

  async load(): Promise<void> {
    this.loading = true;
    this.sale = {
      subTotal: 0,
      total: 0,
      discount: 0,
      tax: 0,
      netTotal: 0,
      cuver: 0,
      saleCount: 0,
      averageCuver: 0,
      averageSale: 0,
      averageCost: 0,
      averageCuverCost: 0,
      cost: 0
    };
    this.points = [];
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/daily_reports', this.parameters).subscribe(response => {
      this.saleDates = response['hydra:member'];
      this.saleDates.map(item => {
        this.sale.subTotal += item.subTotal;
        this.sale.total += item.total;
        this.sale.netTotal += item.netTotal;
        this.sale.tax += item.tax;
        this.sale.discount += item.discount;
        this.sale.cuver += item.cuver;
        this.sale.saleCount += item.saleCount;
        this.sale.cost += item.totalCost;
      });
      this.sale.averageCuver = this.sale.netTotal / this.sale.cuver;
      this.sale.averageSale = this.sale.netTotal / this.sale.saleCount;
      this.sale.averageCost = this.sale.cost / this.sale.saleCount;
      this.sale.averageCuverCost = this.sale.cost / this.sale.cuver;
    });
    await this.http.get('/api/reports/menu_analys_reports', this.parameters).subscribe(response => {
      this.sumMenuCost = 0;
      this.sumMenuRevenue = 0;
      this.sumMenuCM = 0;
      this.itemCm = 0;
      this.menuFootCost = 0;
      this.itemCmAverage = 0;
      this.percentageMenuMix = 0;
      this.menuAnalys = response;
      this.menuAnalys.results.map((item, i) => {
        if (parseFloat(item.quantity) > this.maxQuantity) {
          this.maxQuantity = parseFloat(item.quantity);
        }

        if (parseFloat(item.profit) > this.maxProfit) {
          this.maxProfit = parseFloat(item.profit);
        }
        // this.sumMenuCost = this.sumMenuCost + (item.quantity * item.total_cost);
        this.sumMenuCost = this.sumMenuCost + (item.sale_price - item.total_cost);
        // this.sumMenuRevenue = this.sumMenuRevenue + (item.quantity * item.sale_price);
        this.sumMenuRevenue = this.sumMenuRevenue + (item.quantity * item.total_cost);
        // this.sumMenuCM = this.sumMenuCM + (item.quantity * (item.sale_price - item.total_cost));
        this.sumMenuCM = this.sumMenuCM + (item.quantity * item.sale_price);
        this.itemCm = this.itemCm + ((item.sale_price - item.total_cost));
        this.menuFootCost = (this.sumMenuRevenue / this.sumMenuCM) * 100;
        this.itemCmAverage = this.sumMenuCost / response.results.length;
        this.percentageMenuMix = (1 / this.menuAnalys.results.length * 7);
      });
      setTimeout(() => {
        response.results.map((item, i) => {
          if (item.profit > 0 && item.total_cost > 0) {
            if (this.a < 10 && this.getBaseLog(10, parseFloat(item.profit)) > (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) > (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info ? this.info.currency : '₺'
                }
              );
              this.a = this.a + 1;
            } else if (this.b < 10 && this.getBaseLog(10, parseFloat(item.profit)) > (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) < (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info.currency
                }
              );
              this.b = this.b + 1;
            } else if (this.c < 10 && this.getBaseLog(10, parseFloat(item.profit)) < (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) > (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info.currency

                }
              );
              this.c = this.c + 1;
            } else if (this.d < 10 && this.getBaseLog(10, parseFloat(item.profit)) < (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) < (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info.currency
                }
              );
              this.d = this.d + 1;
            }
          }
        });
      }, 100);
      this.a = 0;
      this.b = 0;
      this.c = 0;
      this.d = 0;
      this.http.get('/api/reports/categories_reports', this.parameters).subscribe(res => {
        this.categories = res;
        this.chartOption();
        this.loading = false;
        this.parameters.stock = null;
      });
    });
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
  }

  // @ts-ignore
  // @ts-ignore
  chartOption(): void {
    const catName = this.categories.categories.map(category => category.name);
    const totalValues = this.categories.categories.map(category => category.sumSales.total);
    const costValues = this.categories.categories.map(category => category.sumSales.totalCOST);
    this.chartOptions = {
      zoomEnabled: true,
      zoomType: 'xy',
      theme: 'light2',
      animationEnabled: true,
      /*
      title: {
        text: 'Waste Generation and Urbanization by Region'
      },
      */
      axisX: {
        title: this.translate.instant('AVERAGE_PROFIT_ITEM'),
        labelFontSize: 0,
        titleFontSize: 12,
        suffix: '',
        interval: 1,
        gridThickness: 0.2,
        minimum: 0 - 0.1,
        maximum: this.getBaseLog(10, this.maxProfit) + 0.1,
        stripLines: [
          {
            value: (this.getBaseLog(10, this.maxProfit) / 2).toFixed(2),
            color: '#002bff',
          }
        ],
        lineColor: 'black', // Sınır rengi
        lineThickness: 1 // Sınır kalınlığı
      },
      axisY: {
        title: this.translate.instant('SOLD_ITEMS'),
        labelFontSize: 0,
        titleFontSize: 12,
        includeZero: true,
        gridThickness: 0.2,
        maximum: this.getBaseLog(10, this.maxQuantity) + 0.1,
        minimum: 0 - 0.1,
        stripLines: [
          {
            value: (this.getBaseLog(10, this.maxQuantity) / 2).toFixed(2),
            color: '#18dde3',
          }
        ],
        lineColor: 'black', // Sınır rengi
        lineThickness: 1 // Sınır kalınlığı
      },
      data: [{
        type: 'scatter',
        markerType: 'circle',  // "circle", "square", "cross", "none"
        markerSize: 10,
        markerMargin: 1,
        indexLabel: '{name}',
        fontSize: 70,
        color: '#18dde3',
        // toolTipContent: '{name}<br>Satış Adedi: {q}<br>Birim Maliyeti: {salePrice}<br>
        // Toplam Maliyeti: {sale}<br>COST: {cost}<br>Toplam COST: {totalCost}',
        toolTipContent:
          '<div style=\'"\'height: 75px; \'"\'>{name} <div style=\'"\'width: 400px;\'"\'> ' +
          '<table style=\'"\'width: 50%; float: left; border-right: 3px solid #18dde3; \'"\'>' +
          '<tr><td style=\'"\'width: 70%;\'"\'>Satış Adedi: {q}</td></tr>' +
          '<tr><td>Satış Fiyatı: {salePrice}{currency}</td></tr>' +
          '<tr><td>Birim Maliyeti: {cost}{currency}</td></tr> ' +
          '</table>' +
          '<table style=\'"\'width: 50%; float: left;\'"\'>' +
          '<tr><td>Toplam Gelir: {sale}{currency}</td></tr>' +
          '<tr><td>Toplam Gider: {totalCost}{currency}</td></tr>' +
          '<tr><td style=\'"\'color: red;\'"\'>Cost: %{costRate} </td></tr> ' +
          '</table>' +
          '</div></div>',
        dataPoints: this.points
      }]
    };
    this.basicData = {
      labels: [...catName],
      datasets: [
        {
          label: 'Gelir',
          backgroundColor: 'green',
          data: [...totalValues]
        },
        {
          label: 'Gider',
          backgroundColor: 'red',
          data: [...costValues]
        }
      ]
    };
    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#ebedef'
          }
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            color: '#ebedef',

          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        }],
        yAxes: [{
          ticks: {
            color: '#ebedef',
            stepSize: 400000, // Bu değeri isteğinize göre ayarlayabilirsiniz
            beginAtZero: true // Y eksenini 0'dan başlatır
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        }]
      }
    };
  }

  getBaseLog(x, y): number {
    return Math.log(y) / Math.log(x);
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];

    this.parameters.startDate = this.getFormattedDate(event[0]);
    this.parameters.lastDate = this.getFormattedDate(event[1]);
    const dateRange = this.getDateRange(start, end);


    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    this.parameters.saleDate = selectedDate.join(',');

    this.parameters = {...this.parameters};

  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  cmCategory(item): string {
    console.log(this.itemCmAverage);
    //if (this.getBaseLog(10, parseFloat(item)) > (this.getBaseLog(10, this.maxQuantity) / 2)) {
    if ((item.sale_price - item.total_cost) > this.itemCmAverage) {
      return 'HIGH';
    } else {
      return 'LOW';
    }
  }

  mmCategory(item): string {
    if (this.getBaseLog(10, parseFloat(item)) > (this.getBaseLog(10, this.maxProfit) / 2)) {
      return 'HIGH';
    } else {
      return 'LOW';
    }
  }

  menuItemClass(cm, mm): string {
    if (cm === 'HIGH' && mm === 'HIGH') {
      return 'STAR';
    }
    if (cm === 'HIGH' && mm === 'LOW') {
      return 'PUZZLE';
    }
    if (cm === 'LOW' && mm === 'HIGH') {
      return 'PLOWHORSE';
    }
    if (cm === 'LOW' && mm === 'LOW') {
      return 'DOG';
    }
  }

  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  };

  async onVariantSelect(event: StockModel): Promise<void> {
    this.parameters.stock = event.id;
  }

  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

}
