import { Component, OnInit } from '@angular/core';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {HttpService} from '../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {UserModel} from '../../../model/user.model';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {CurrencyModel} from '../../../model/currency.model';
import {LocationModel} from '../../../model/location.model';
import {StockModel} from '../../../model/stock.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CategoryService} from '../../../service/category.service';
import {ContactService} from '../../../service/contact.service';
import {FlashMessageService} from '../../../service/flash-message.service';

@Component({
  selector: 'app-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.scss']
})
export class AllergensComponent implements OnInit {
  products: StockVariantModel[] = [];
  item: StockModel = new StockModel();
  loading: boolean;
  total: number;
  user: UserModel = new UserModel();
  settings = [];
  suppliers: ContactModel[] = [];
  taxes: TaxRateModel[] = [];
  categories: CategoryModel[] = [];
  searchSuppliers: any [];
  searchCategories: any [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  id: number;
  type: string;
  tabMenuItems: MenuItem[];
  activeTabMenuItem: MenuItem;
  activeTab: number;
  allergens = [];
  allergenDialog: boolean;
  parameters = {
    id: '',
    name: '',
    code: '',
    'stock.category.name': '',
    'stock.currency.code': '',
    'stock.type': 'material',
    'stock.category.id': '',
    'stock.supplier.id': '',
    'stock.locations.id': '',
    page: 1,
    itemsPerPage: 20
  };
  moreLoading: boolean;
  submitted: boolean;
  constructor(private http: HttpService, private translate: TranslateService,
              private categoryService: CategoryService, private flashMessageService: FlashMessageService,
              private contactService: ContactService) { }

  ngOnInit(): void {
    this.loading = true;
    this.tabMenuLoad();
    this.load();
    this.loadSearchData().then();
    this.allergens = [
      {
        name: this.translate.instant('CEREALS_CONTAINING_GLUTEN'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('CRUSTACEAN_SHELLFISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('EGGS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('FISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('MILK'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('NUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('PEANUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('SOYBEANS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      }
    ];
  }

  load(event: LazyLoadEvent = null): void {
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }
    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe(response => {
      this.products = response['hydra:member'];
      this.total = response['hydra:totalItems'];
    });
  }

  async loadSearchData(): Promise<void> {
    this.loading = true;
    await this.categoryService.getCategoriesAction({isActive: true});
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
      this.searchCategories = this.categoryService.getSearchCategories();
    });
    await this.contactService.getContactsAction({pagination: false, type: 'supplier'});
    this.contactService.getContacts().subscribe(response => {
      this.suppliers = response;
      this.searchSuppliers = this.contactService.getSearchContacts();
    });

    await this.http.get(LocationModel.IRI + '/subscriptionControl', { pagination: false}).subscribe(response => {
      this.locations = response;
      this.loading = false;
    });
  }

  tabMenuLoad(): void {
    this.tabMenuItems = [
      { label: this.translate.instant('INVENTORY_ITEMS'),
        command: (event) => {
          this.parameters['stock.type'] = 'material';
          this.load();
        }
      },
      { label: this.translate.instant('SUB_RECIPES'),
        command: (event) => {
          this.parameters['stock.type'] = 'semi_product';
          this.load();
        }
      },
      { label: this.translate.instant('MENU_ITEMS'),
        command: (event) => {
          this.parameters['stock.type'] = 'product';
          this.load();
        }
      }
    ];
    this.activeTabMenuItem = this.tabMenuItems[0];
  }

  showAllergen(event, i): void {
    this.item = null;
    this.allergens = [
      {
        name: this.translate.instant('CEREALS_CONTAINING_GLUTEN'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('CRUSTACEAN_SHELLFISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('EGGS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('FISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('MILK'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('NUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('PEANUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('SOYBEANS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      }
    ];
    this.allergenDialog = true;
    this.item =  event;
    if (event.allergen.length === 0) {
      this.item.allergen = this.allergens;
    }

  }

  saveAllergens(): void{
    this.submitted = true;
    this.http.put(`${StockModel.IRI}/${this.item.id}`, {
      allergen: this.item.allergen
    }).then((response: StockModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_UPDATED'));
      this.allergenDialog = false;
      this.submitted = false;
      this.load();
    });
  }

  change(event, i, noInfo, contains, mayContain, doesNotContain): void {

    // @ts-ignore
    this.item.allergen[i].noInfo = noInfo;
    // @ts-ignore
    this.item.allergen[i].contains = contains;
    // @ts-ignore
    this.item.allergen[i].mayContain = mayContain;
    // @ts-ignore
    this.item.allergen[i].doesNotContain = doesNotContain;

  }

  changeParameters(event, type): void {
    if (type === 'category'){
      this.parameters['stock.category.id'] = event.value;
    }else if (type === 'supplier'){
      this.parameters['stock.supplier.id'] = event.value;
    } else if (type === 'locations'){
      this.parameters['stock.locations.id'] = event.value;
    }else{
      this.parameters['stock.category.id'] = null;
      this.parameters['stock.supplier.id'] = null;
      this.parameters['stock.locations.id'] = null;
    }
    this.load();
  }


}
