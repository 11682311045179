<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card class="card" *ngIf="!loading" [style]="{overflow:'hidden',minHeight:'600px'}">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600', marginTop: '8px' }">{{ 'INVENTORY_REPORTS'|translate }}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'INVENTORY_CATEGORY'"></app-page-info>
      <p-button icon="pi pi-file-excel" [disabled]="parameters.location === '' || parameters.inventory === '' "
                (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf"
                (click)="downloadPdf()"
                [disabled]="parameters.location === '' || parameters.inventory === '' "
                [style]="{ marginLeft: '5px', float: 'right' }"></p-button>

    </div>
  </div>
  <div class="p-col-6" *ngIf="noSelected" [style]="{marginTop:'10px'}">
    <p-message severity="error" text="{{ 'REQUIRED_FIELDS' | translate}}"><br></p-message>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-multiSelect [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4', marginTop:'8px'} "
                     [options]="inventories" [(ngModel)]="selectedInventories"
                     placeholder="{{ 'SELECT_INVENTORY'| translate}} *({{'REQUIRED' | translate}})"
                     (onChange)="changeInventory($event)" optionLabel="name" optionValue="id"></p-multiSelect>

    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4', marginTop:'7px'}"
                  [options]="searchCategories" [(ngModel)]="selectedCategories"
                  placeholder="{{ 'SELECT_CATEGORY'| translate}}"
                  (onChange)="changeCategory($event)" optionLabel="name" optionValue="id" [filter]="true"
                  filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-multiSelect [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4', marginTop:'8px'}"
                     [options]="locations" [(ngModel)]="selectedLocations"
                     placeholder="{{ 'SELECT_LOCATION'| translate}} *({{'REQUIRED' | translate}})"
                     (onChange)="changeLocation($event)" optionLabel="name" optionValue="id">
      </p-multiSelect>
    </div>
    <div class="p-col-6 p-md-3 p-lg-2" [style]="{maxWidth:'135px'}">

      <p-button label="{{'SEND_TRANSFER' | translate}}" [icon]="tableLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="!isSelected" (click)="loadInventoryReportData(null)"></p-button>

    </div>

  </div>
  <div id="pdf-content">
    <div class="card" *ngIf="reportValues.length>0" [style]="{overflow: 'auto', maxWidth:'100%'}">
      <p-table [value]="reportValues[0].totals" [loading]="tableLoading">
        <ng-template pTemplate="header">
          <tr style="line-height: 14px">
            <th [style]="{width: '20%'}">{{'CATEGORY' | translate}}</th>
            <th [style]="{width: '10%'}">{{'TOTAL_SALES' | translate}}</th>
            <th [style]="{width: '10%'}">{{'TOTAL_CONSUMETION' | translate}}</th>
            <th [style]="{width: '10%'}">{{'COST %' | translate}}</th>
            <th [style]="{width: '10%'}">{{'TOTAL_PROFIT' | translate}}</th>
            <th [style]="{width: '10%'}">{{'COGS' | translate}}</th>
            <th [style]="{width: '10%'}">{{'COST %' | translate}}</th>
            <th [style]="{width: '10%'}">{{'DIFFERENCE_VALUE' | translate}}</th>
            <th [style]="{width: '10%'}">{{'DIFFERENCE_RATIO' | translate}} %</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-value>
          <tr>
            <td [style]="{'textAlign': 'left'}">{{value.name}}</td>
            <td [style]="{'textAlign': 'right'}">{{value.catSales | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{value.lastQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}"
              [ngStyle]="{'color': ((value.lastQuantityValue / value.catSales)*100) > 45 ? 'red' : 'black'}">{{(value.catSales > 0) ? ((value.lastQuantityValue / value.catSales) * 100).toFixed(2) + '%' : '' }}</td>
            <td [style]="{'textAlign': 'right'}">{{value.catSales - value.lastQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{value.saleQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}"
              [ngStyle]="{'color': ((value.saleQuantityValue / value.catSales)*100) > 45 ? 'red' : 'black'}">    {{ (value.catSales > 0) ? ((value.saleQuantityValue / value.catSales) * 100).toFixed(2) + '%' : '' }}
            </td>
            <td [style]="{'textAlign': 'right'}"
              [ngStyle]="{'color': ((value.lastQuantityValue / value.saleQuantityValue)*100) > 5 ? 'red' : 'black'}">{{value.differenceQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}"
              [ngStyle]="{'color': ((value.lastQuantityValue / value.saleQuantityValue)*100) > 5 ? 'red' : 'black'}">{{(value.saleQuantityValue > 0) ? ((value.lastQuantityValue / value.saleQuantityValue) * 100).toFixed(2) + '%' : '' }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr class="inventory-table">
            <td>{{'TOTAL' | translate}}</td>
            <td [style]="{'textAlign': 'right'}">{{ (reportValues[0].locations[0].totalSales[0].total - reportValues[0].locations[0].totalSales[0]?.tax) | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{reportValues[0].locations[0].lastQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{(reportValues[0].locations[0].totalSales[0].total > 0) ? ((reportValues[0].locations[0].lastQuantityValue / reportValues[0].locations[0].totalSales[0].total) * 100).toFixed(2) + '%' : ''}}</td>
            <td [style]="{'textAlign': 'right'}">{{reportValues[0].locations[0].totalSales[0].netTotal -
            reportValues[0].locations[0].lastQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{reportValues[0].locations[0].saleQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{(reportValues[0].locations[0].totalSales[0].total > 0) ? (reportValues[0].locations[0].saleQuantityValue / reportValues[0].locations[0].totalSales[0].total).toFixed(2) + '%' : '' }}</td>
            <td [style]="{'textAlign': 'right'}">{{reportValues[0].locations[0].differenceQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{'textAlign': 'right'}">{{(reportValues[0].locations[0].saleQuantityValue > 0) ? ((reportValues[0].locations[0].lastQuantityValue / reportValues[0].locations[0].saleQuantityValue) * 100).toFixed(2) + '%' : ''}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div *ngIf="reportValues.length>0 && reportValues[0].locations[0].totalSales[0].total > 0" class="p-grid">

      <div class="p-col-12 p-lg-5">
        <p-chart type="bar" [data]="data" [options]="chartOptions"></p-chart>
      </div>

      <div class="p-col-6 p-lg-3" [style]="{marginTop:'50px'}">
        <h3 [style]="{ fontWeight: '600', marginTop: '8px' }">{{ 'GRAND_TOTALS'|translate }}</h3>
        <table [style]="{width: '100%'}">
          <tr>
            <td [style]="{width: '30%'}">{{'TOTAL_SALES' | translate}}</td>
            <td [style]="{width: '100%', position: 'relative', display: 'inline-block'}">
              <p-progressBar [showValue]="false" value="100" unit=""></p-progressBar>
              <div class="progress-text">{{reportValues[0].locations[0].totalSales[0].netTotal | number: '1.1-2'}}</div>
            </td>
          </tr>
          <tr>
            <td [style]="{width: '30%'}">{{'TOTAL_CONSUMETION' | translate}}</td>
            <td [style]="{width: '100%', position: 'relative', display: 'inline-block'}">
              <p-progressBar [showValue]="false" value="{{valueConsum}}" unit=""></p-progressBar>
              <div class="progress-text">{{reportValues[0].locations[0].lastQuantityValue | number: '1.1-2'}}</div>
            </td>
          </tr>
          <tr>
            <td [style]="{width: '30%'}">{{'TOTAL_PROFIT' | translate}}</td>
            <td [style]="{width: '100%', position: 'relative', display: 'inline-block'}">
              <p-progressBar [showValue]="false" value="{{valueProf}}" unit=""></p-progressBar>
              <div
                class="progress-text">{{(reportValues[0].locations[0].totalSales[0].netTotal - reportValues[0].locations[0].lastQuantityValue) | number: '1.1-2'}}</div>
            </td>
          </tr>
        </table>
      </div>

      <div class="p-col-6 p-lg-4" [style]="{marginTop:'20px'}">
        <canvasjs-chart [options]="chartOptions2" [styles]="{width: '80%', height:'100%'}"></canvasjs-chart>
      </div>

    </div>

    <div class="p-grid" id="button" *ngIf="reportValues.length>0">
      <div class="p-col-3 p-md-1 p-lg-2">
        <p-button [disabled]="parameters.search === ''"
                  (click)="resetTheSearch()" class="toggle-button">{{ 'Aramayı Sıfırla' }}
        </p-button>
      </div>

      <div class="p-col-3 p-md-3 p-lg-1" [style]="{textAlign: 'center'}">
        <p style="margin: auto; margin-bottom:10px">{{'DETAILED_LIST' | translate}}</p>
        <p-inputSwitch [(ngModel)]="isDetails" (onChange)="isStocks = false"></p-inputSwitch>
      </div>

      <div class="p-col-3 p-md-3 p-lg-1" [style]="{textAlign: 'center'}">
        <p style="margin: auto; margin-bottom:10px">{{'STOCKS' | translate}}</p>
        <p-inputSwitch [(ngModel)]="isStocks" (onChange)="isDetails = false"></p-inputSwitch>
      </div>

    </div>

    <div class="card" *ngIf="reportValues.length>0 && !isStocks && isDetails">
      <p-table [value]="reportValues[0]['totals']" [scrollable]="true" scrollHeight="800px"
               styleClass="p-datatable-lg ui-table ui-table-sticky" [loading]="tableLoading"
               dataKey="mCategoryId"
               [lazy]="true" (onLazyLoad)="loadInventoryReportData($event)">
        <ng-template pTemplate="header">
          <tr style="line-height: 14px">
            <th class=" width-240">
              <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                              appendTo="body" autofocus [suggestions]="variants" name="stock"
                              placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                              (completeMethod)="searchStock($event)"
                              (onSelect)="onVariantSelect($event)"
                              field="name"></p-autoComplete>
            </th>
            <th class="width-110" pSortableColumn="firstQuantity">
              <p-sortIcon field="firstQuantity"></p-sortIcon>
              {{'OPENING_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="firstQuantityValue">
              <p-sortIcon field="firstQuantityValue"></p-sortIcon>
              {{'OPENING_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="purchaseQuantity">
              <p-sortIcon field="purchaseQuantity"></p-sortIcon>
              {{'PURCHASE_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="purchaseQuantityValue">
              <p-sortIcon field="purchaseQuantityValue"></p-sortIcon>
              {{'PURCHASE_VALUE' | translate}}</th>
            <th class="width-110" pSortabclass="width-110" leColumn="addedTransfer">
              <p-sortIcon field="addedTransfer"></p-sortIcon>
              {{'TRANSFER_+_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="addedTransferValue">
              <p-sortIcon field="addedTransferValue"></p-sortIcon>
              {{'TRANSFER_+_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="missingTransfer">
              <p-sortIcon field="missingTransfer"></p-sortIcon>
              {{'TRANSFER_-_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="missingTransferValue">
              <p-sortIcon field="missingTransferValue"></p-sortIcon>
              {{'TRANSFER_-_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="wasteEvent">
              <p-sortIcon field="wasteEvent"></p-sortIcon>
              {{'USAGE_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="wasteEventValue">
              <p-sortIcon field="wasteEventValue"></p-sortIcon>
              {{'USAGE_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="quantity">
              <p-sortIcon field="quantity"></p-sortIcon>
              {{'STOCK_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="quantityValue">
              <p-sortIcon field="quantityValue"></p-sortIcon>
              {{'STOCK_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="countQuantity">
              <p-sortIcon field="countQuantity"></p-sortIcon>
              {{'COUNT_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="countQuantityValue">
              <p-sortIcon field="countQuantityValue"></p-sortIcon>
              {{'COUNT_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="lastQuantity">
              <p-sortIcon field="lastQuantity"></p-sortIcon>
              {{'CONSUMPTION_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="lastQuantityValue">
              <p-sortIcon field="lastQuantityValue"></p-sortIcon>
              {{'CONSUMPTION_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="saleQuantity">
              <p-sortIcon field="saleQuantity"></p-sortIcon>
              {{'COGS_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="saleQuantityValue">
              <p-sortIcon field="saleQuantityValue"></p-sortIcon>
              {{'COGS_VALUE' | translate}}</th>
            <th class="width-110" pSortableColumn="differenceQuantity">
              <p-sortIcon field="differenceQuantity"></p-sortIcon>
              {{'DIFFERENCE_QUANTITY' | translate}}</th>
            <th class="width-110" pSortableColumn="differenceQuantityValue">
              <p-sortIcon field="differenceQuantityValue"></p-sortIcon>
              {{'DIFFERENCE_VALUE' | translate}}</th>
            <th class=" width-240" [style]="{textAlign: 'center'}">{{'DESCRIPTION' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mainC let-expanded="expanded">
          <tr class="inventory-table">
            <td class="mainCat width-20" [style]="{padding: '0px'}">
              <button type="button" pButton pRipple [pRowToggler]="mainC" class="p-button-text p-button-rounded
              p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td class="mainCat width-220"><span></span> {{mainC.name}}</td>
            <td class=" width-110 mainCat">{{ mainC.firstQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.firstQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.purchaseQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.purchaseQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.addedTransfer | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.addedTransferValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.missingTransfer | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.missingTransferValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.wasteEvent | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.wasteEventValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.quantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.quantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.countQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.countQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.lastQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.lastQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.saleQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.saleQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat">{{ mainC.differenceQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat">{{ mainC.differenceQuantityValue | number: '1.1-2' }}</td>
            <td class=" mainCat width-240"></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr class="inventory-table">
            <td class="mainCat width-20" [style]="{padding: '0px'}"></td>
            <td class="mainCat width-220">{{'TOTAL' | translate}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].firstQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].firstQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].purchaseQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].purchaseQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].addedTransfer | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].addedTransferValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].missingTransfer | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].missingTransferValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].wasteEvent | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].wasteEventValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].quantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].quantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].countQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].countQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].lastQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].lastQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].saleQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].saleQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].differenceQuantity | number: '1.1-2'}}</td>
            <td class=" width-110 mainCat"
                style="font-size: small">{{ reportValues[0].locations[0].differenceQuantityValue | number: '1.1-2' }}</td>
            <td class=" mainCat width-240"></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-row>
          <tr class="inventory-table">
            <td colspan="23" class="pad">
              <p-table [loading]="tableLoading" responsiveLayout="stack"
                       [value]="row['subCategory']" [scrollable]="true"
                       styleClass="p-datatable-lg" dataKey="categoryId">
                <ng-template pTemplate="body" let-cat let-expanded="expanded">
                  <tr *ngIf="cat['stocks'].length>0" [style]="{width:'100%'}">
                    <td class="width-20 pad">
                      <button type="button" pButton pRipple [pRowToggler]="cat"
                              class="p-button-text p-button-rounded p-button-plain"
                              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td class="width-220">{{cat.name}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.firstQuantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.firstQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.purchaseQuantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.purchaseQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.addedTransfer | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.addedTransferValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.missingTransfer | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.missingTransferValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.wasteEvent | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.wasteEventValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.quantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.quantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.countQuantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.countQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.lastQuantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.lastQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.saleQuantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray ">{{ cat.saleQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.differenceQuantity | number: '1.1-2'}}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.differenceQuantityValue | number: '1.1-2' }}</td>
                    <td class="width-240"></td>
                  </tr>

                </ng-template>
                <ng-template pTemplate="rowexpansion" let-row let-i="rowIndex">
                  <tr>
                    <td colspan="23" class="pad">
                      <p-table [loading]="tableLoading" responsiveLayout="stack"
                               [value]="row['stocks']" [scrollable]="true"
                               styleClass="p-datatable-lg">
                        <ng-template pTemplate="body" let-stock>
                          <tr class="inventory-table">
                            <td class="width-220 info">{{stock.name | slice:0:14 }}... <span
                              [style]="{float:'right'}"></span> <span class="tooltip">{{ stock.name }}</span></td>
                            <td class="width-20 pad">{{ stock.unit }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.firstQuantity | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.firstQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.purchaseQuantity | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.purchaseQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.addedTransfer | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.addedTransferValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.missingTransfer | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.missingTransferValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.wasteEvent | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.wasteEventValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.quantity | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.quantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.countQuantity | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.countQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.lastQuantity | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.lastQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.saleQuantity | number: '1.1-2'}}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray ">{{ stock.saleQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.differenceQuantity | number: '1.1-2'}}</td>
                            <td
                              [style]="{textAlign: 'right'}">{{ stock.differenceQuantityValue | number: '1.1-2' }}</td>
                            <td class="width-240 info">{{stock.description | slice:0:14 }}... <span
                              class="tooltip">{{ stock.description }}</span></td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="card stockTable" [style]="{overflow: 'auto', maxWidth:'100%'}"
         *ngIf="reportValues.length>0 && !isStocks && !isDetails">
      <p-table [value]="reportValues[0]['totals']" [scrollable]="true" scrollHeight="8000px"
               styleClass="p-datatable-lg ui-table ui-table-sticky" [loading]="tableLoading"
               dataKey="mCategoryId"
               columnResizeMode="expand" [lazy]="true" (onLazyLoad)="loadInventoryReportData($event)">
        <ng-template pTemplate="header">
          <tr style="line-height: 14px">
            <th class=" width-240">
              <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                              appendTo="body" autofocus [suggestions]="variants" name="stock"
                              placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                              (completeMethod)="searchStock($event)"
                              (onSelect)="onVariantSelect($event)"
                              field="name"></p-autoComplete>
            </th>
            <th pSortableColumn="firstQuantityValue">
              <p-sortIcon field="firstQuantityValue"></p-sortIcon>
              {{'OPENING_VALUE' | translate}}</th>
            <th pSortableColumn="purchaseQuantityValue">
              <p-sortIcon field="purchaseQuantityValue"></p-sortIcon>
              {{'PURCHASE_VALUE' | translate}}</th>
            <th pSortableColumn="addedTransferValue">
              <p-sortIcon field="addedTransferValue"></p-sortIcon>
              {{'TRANSFER_+_VALUE' | translate}}</th>
            <th pSortableColumn="missingTransferValue">
              <p-sortIcon field="missingTransferValue"></p-sortIcon>
              {{'TRANSFER_-_VALUE' | translate}}</th>
            <th pSortableColumn="wasteEventValue">
              <p-sortIcon field="wasteEventValue"></p-sortIcon>
              {{'USAGE_VALUE' | translate}}</th>
            <th pSortableColumn="quantityValue">
              <p-sortIcon field="quantityValue"></p-sortIcon>
              {{'STOCK_VALUE' | translate}}</th>
            <th pSortableColumn="countQuantityValue">
              <p-sortIcon field="countQuantityValue"></p-sortIcon>
              {{'COUNT_VALUE' | translate}}</th>
            <th pSortableColumn="lastQuantityValue">
              <p-sortIcon field="lastQuantityValue"></p-sortIcon>
              {{'CONSUMPTION_VALUE' | translate}}</th>
            <th pSortableColumn="saleQuantityValue">
              <p-sortIcon field="saleQuantityValue"></p-sortIcon>
              {{'COGS_VALUE' | translate}}</th>
            <th pSortableColumn="differenceQuantityValue">
              <p-sortIcon field="differenceQuantityValue"></p-sortIcon>
              {{'DIFFERENCE_VALUE' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mainC let-expanded="expanded">
          <tr class="inventory-table">
            <td class="mainCat width-20" [style]="{padding: '0px'}">
              <button type="button" pButton pRipple [pRowToggler]="mainC" class="p-button-text p-button-rounded
            p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td class="mainCat width-220">{{mainC.name}}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.firstQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.purchaseQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.addedTransferValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.missingTransferValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.wasteEventValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.quantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.countQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.lastQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" class=" mainCat">{{ mainC.saleQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}"
                class=" mainCat">{{ mainC.differenceQuantityValue | number: '1.1-2' }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr class="inventory-table">
            <td class="mainCat width-20" [style]="{padding: '0px'}"></td>
            <td class="mainCat width-220"> {{'TOTAL' | translate}}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].firstQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].purchaseQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].addedTransferValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].missingTransferValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].wasteEventValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].quantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].countQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].lastQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].saleQuantityValue | number: '1.1-2' }}</td>
            <td [style]="{textAlign: 'right'}" style="font-size: small"
                class=" mainCat">{{ reportValues[0].locations[0].differenceQuantityValue | number: '1.1-2' }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-row>
          <tr class="inventory-table">
            <td colspan="12" class="pad">
              <p-table [loading]="tableLoading" responsiveLayout="stack"
                       [value]="row['subCategory']"
                       styleClass="p-datatable-lg" dataKey="categoryId">
                <ng-template pTemplate="body" let-cat let-expanded="expanded">
                  <tr *ngIf="cat['stocks'].length>0" [style]="{width:'100%'}">
                    <td class="width-20" [style]="{padding: '0px'}">
                      <button type="button" pButton pRipple [pRowToggler]="cat"
                              class="p-button-text p-button-rounded p-button-plain"
                              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td class="width-220">{{cat.name}}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray">{{ cat.firstQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{cat.purchaseQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray">{{ cat.addedTransferValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.missingTransferValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray">{{ cat.wasteEventValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.quantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray">{{ cat.countQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{cat.lastQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}"
                        class=" backgroundGray">{{ cat.saleQuantityValue | number: '1.1-2' }}</td>
                    <td [style]="{textAlign: 'right'}">{{ cat.differenceQuantityValue | number: '1.1-2' }}</td>
                  </tr>

                </ng-template>
                <ng-template pTemplate="rowexpansion" let-row let-i="rowIndex">
                  <tr class="inventory-table">
                    <td colspan="12" class="pad">
                      <p-table [loading]="tableLoading" responsiveLayout="stack"
                               [value]="row['stocks']"
                               styleClass="p-datatable-lg">
                        <ng-template pTemplate="body" let-stock>
                          <tr>
                            <td class="width-240 info">{{stock.name | slice:0:14 }}... <span
                              [style]="{float:'right'}">{{ stock.unit }}</span> <span
                              class="tooltip">{{ stock.name }}</span></td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray">{{ stock.firstQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.purchaseQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray">{{ stock.addedTransferValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.missingTransferValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray">{{ stock.wasteEventValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.quantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray">{{ stock.countQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}">{{ stock.lastQuantityValue | number: '1.1-2' }}</td>
                            <td [style]="{textAlign: 'right'}"
                                class=" backgroundGray">{{ stock.saleQuantityValue | number: '1.1-2' }}</td>
                            <td
                              [style]="{textAlign: 'right'}">{{ stock.differenceQuantityValue | number: '1.1-2' }}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="card stockTable" [style]="{overflow: 'auto', maxWidth:'100%'}"
         *ngIf="reportValues.length>0 && isStocks">
      <p-table #dt [value]="reportValues[0]['stock']" styleClass="p-datatable-lg ui-table ui-table-sticky"
               [loading]="tableLoading" dataKey="mCategoryId" columnResizeMode="expand" [first]="first"
               [rows]="parameters.itemsPerPage" [showCurrentPageReport]="true" (onPage)="handlePageChange($event)"
               [paginator]="true" [lazy]="false" (onLazyLoad)="loadInventoryReportData($event)">
        <ng-template pTemplate="header">
          <tr style="line-height: 14px">
            <th class=" width-240">
              <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                              appendTo="body" autofocus [suggestions]="variants" name="stock"
                              placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                              (completeMethod)="searchStock($event)"
                              (onSelect)="onVariantSelect($event)"
                              field="name"></p-autoComplete>
            </th>
            <th class=" width-110" pSortableColumn="firstQuantity">
              <p-sortIcon field="firstQuantity"></p-sortIcon>
              {{'OPENING_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="firstQuantityValue">
              <p-sortIcon field="firstQuantityValue"></p-sortIcon>
              {{'OPENING_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="purchaseQuantity">
              <p-sortIcon field="purchaseQuantity"></p-sortIcon>
              {{'PURCHASE_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="purchaseQuantityValue">
              <p-sortIcon field="purchaseQuantityValue"></p-sortIcon>
              {{'PURCHASE_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="addedTransfer">
              <p-sortIcon field="addedTransfer"></p-sortIcon>
              {{'TRANSFER_+_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="addedTransferValue">
              <p-sortIcon field="addedTransferValue"></p-sortIcon>
              {{'TRANSFER_+_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="missingTransfer">
              <p-sortIcon field="missingTransfer"></p-sortIcon>
              {{'TRANSFER_-_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="missingTransferValue">
              <p-sortIcon field="missingTransferValue"></p-sortIcon>
              {{'TRANSFER_-_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="wasteEvent">
              <p-sortIcon field="wasteEvent"></p-sortIcon>
              {{'USAGE_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="wasteEventValue">
              <p-sortIcon field="wasteEventValue"></p-sortIcon>
              {{'USAGE_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="quantity">
              <p-sortIcon field="quantity"></p-sortIcon>
              {{'STOCK_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="quantityValue">
              <p-sortIcon field="quantityValue"></p-sortIcon>
              {{'STOCK_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="countQuantity">
              <p-sortIcon field="countQuantity"></p-sortIcon>
              {{'COUNT_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="countQuantityValue">
              <p-sortIcon field="countQuantityValue"></p-sortIcon>
              {{'COUNT_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="lastQuantity">
              <p-sortIcon field="lastQuantity"></p-sortIcon>
              {{'CONSUMPTION_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="lastQuantityValue">
              <p-sortIcon field="lastQuantityValue"></p-sortIcon>
              {{'CONSUMPTION_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="saleQuantity">
              <p-sortIcon field="saleQuantity"></p-sortIcon>
              {{'COGS_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="saleQuantityValue">
              <p-sortIcon field="saleQuantityValue"></p-sortIcon>
              {{'COGS_VALUE' | translate}}</th>
            <th class=" width-110" pSortableColumn="differenceQuantity">
              <p-sortIcon field="differenceQuantity"></p-sortIcon>
              {{'DIFFERENCE_QUANTITY' | translate}}</th>
            <th class=" width-110" pSortableColumn="differenceQuantityValue">
              <p-sortIcon field="differenceQuantityValue"></p-sortIcon>
              {{'DIFFERENCE_VALUE' | translate}}</th>
            <th class=" width-240" [style]="{textAlign: 'center'}">{{'DESCRIPTION' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-stock>
          <tr class="inventory-table">
            <td class="width-240 info">{{stock.name | slice:0:14 }}... <span
              [style]="{float:'right'}">{{ stock.unit }}</span> <span class="tooltip">{{ stock.name }}</span></td>
            <td class=" backgroundGray width-110">{{ stock.firstQuantity | number: '1.1-2'}}</td>
            <td class=" backgroundGray width-110">{{ stock.firstQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110">{{ stock.purchaseQuantity | number: '1.1-2'}}</td>
            <td class=" width-110">{{ stock.purchaseQuantityValue | number: '1.1-2' }}</td>
            <td class=" backgroundGray width-110">{{ stock.addedTransfer | number: '1.1-2'}}</td>
            <td class=" backgroundGray width-110">{{ stock.addedTransferValue | number: '1.1-2' }}</td>
            <td class=" width-110">{{ stock.missingTransfer | number: '1.1-2'}}</td>
            <td class=" width-110">{{ stock.missingTransferValue | number: '1.1-2' }}</td>
            <td class=" backgroundGray width-110">{{ stock.wasteEvent | number: '1.1-2'}}</td>
            <td class=" backgroundGray width-110">{{ stock.wasteEventValue | number: '1.1-2' }}</td>
            <td class=" width-110">{{ stock.quantity | number: '1.1-2'}}</td>
            <td class=" width-110">{{ stock.quantityValue | number: '1.1-2' }}</td>
            <td class=" backgroundGray width-110">{{ stock.countQuantity | number: '1.1-2'}}</td>
            <td class=" backgroundGray width-110">{{ stock.countQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110">{{ stock.lastQuantity | number: '1.1-2'}}</td>
            <td class=" width-110">{{ stock.lastQuantityValue | number: '1.1-2' }}</td>
            <td class=" backgroundGray width-110">{{ stock.saleQuantity | number: '1.1-2'}}</td>
            <td class=" backgroundGray width-110">{{ stock.saleQuantityValue | number: '1.1-2' }}</td>
            <td class=" width-110">{{ stock.differenceQuantity | number: '1.1-2'}}</td>
            <td class=" width-110">{{ stock.differenceQuantityValue | number: '1.1-2' }}</td>
            <td class="width-240 info">{{stock.description | slice:0:14 }}... <span
              class="tooltip">{{ stock.description }}</span></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr class="inventory-table">
            <td class="mainCat width-240"><span></span> {{'TOTAL' | translate}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].firstQuantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].firstQuantityValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].purchaseQuantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].purchaseQuantityValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].addedTransfer | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].addedTransferValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].missingTransfer | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].missingTransferValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].wasteEvent | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].wasteEventValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].quantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].quantityValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].countQuantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].countQuantityValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].lastQuantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].lastQuantityValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].saleQuantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].saleQuantityValue | number: '1.1-2' }}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].differenceQuantity | number: '1.1-2'}}</td>
            <td style="font-size: small"
                class=" mainCat width-110">{{ reportValues[0].locations[0].differenceQuantityValue | number: '1.1-2' }}</td>
            <td class=" mainCat width-240" *ngIf="description"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>
