<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'STOCK_REQUESTS'|translate }}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">
        {{ 'STOCK_REQUEST_DESCRIPTION'|translate }}
      </p>
    </ng-template>
    <ng-template pTemplate>
      <p-button *ngIf="isDeleteAble === true" styleClass="p-button-danger p-mt-3 p-mb-3" [style]="{float: 'right', marginRight: '13px'}" label="{{ 'DELETE' | translate}}" [icon]="'pi pi-trash'" (click)="delete( stockRequest.id )"></p-button>
    </ng-template>
  </p-splitter>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label for="code">{{ 'REQUEST_NUMBER'|translate}}</label>
        <input id="code"  type="text" pInputText [(ngModel)]="stockRequest.code" >
        <small class="p-error">{{ errors['code'] }}</small>
      </div>
      <div class="p-field p-col-6">
        <label for="adjustmentDate">{{ 'ADJUSTMENT_DATE'|translate}}</label>
        <p-calendar id="adjustmentDate" [(ngModel)]="stockRequest.requestDate"></p-calendar>
        <small class="p-error">{{ errors['adjustmentDate'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-6">
        <label for="location">{{ 'SELECT_ORIGIN' | translate}}</label>
        <p-dropdown id="location" [options]="locations" [(ngModel)]="stockRequest.location" optionLabel="name"  optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['location'] }}</small>
      </div>
      <div class="p-field p-col-6">
        <label for="destination">{{ 'SELECT_DESTINATION' | translate}}</label>
        <p-dropdown id="destination" [options]="destinations" [(ngModel)]="stockRequest.destination" optionLabel="name"  optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['location'] }}</small>
      </div>
    </div>

    <p-table [value]="stockRequest.items" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'STOCK' | translate}} <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip></th>
          <th>{{ 'QUANTITY' | translate}} <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip></th>
          <th>{{ 'UNIT_PRICE' | translate}} <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip></th>
          <th>{{ 'DESCRIPTION' | translate}} <app-tooltip [description]="'TOOLTIP_DESCRIPTION'"></app-tooltip></th>
          <th>{{ 'TOTAL_PRICE' | translate}} <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip></th>
          <th width="10%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1" class="{{ hasErrors('variant', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete autofocus [(ngModel)]="autoCompleteValues[i]"  (onSelect)="onVariantSelect($event, i);" field="name" [suggestions]="variants" (completeMethod)="searchVariant($event)"></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ autoCompleteValues[i] ? autoCompleteValues[i].name : '' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity" (ngModelChange)="calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.quantity}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.unitPrice" (ngModelChange)="calculateTotalPrice(i)" mode="decimal" minFractionDigits="2" maxFractionDigits="2" ></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{item.unitPrice|currency: currencyLabel: ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" pInputText [(ngModel)]="item.description" placeholder (keydown)="onKeydown($event)">
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.description }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{item.totalPrice|currency: currencyLabel: ''}}
          </td>
          <td>
            <button  pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="p-fluid  p-pt-3">

      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addItem()" iconPos="left"  ></button>
    </div>

    <div class="clearfix"></div>
    <p-button styleClass="p-button-secondary p-mt-3 p-mb-3" label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></p-button>
  </div>
</p-card>
