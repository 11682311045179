import {ApiResourceModel} from "./api-resource.model";
import {StockModel} from "./stock.model";
import {StockVariantOptionModel} from "./stock-variant-option.model";
import {StockVariantModel} from "./stock-variant.model";
import {StockVariantOptionValueModel} from "./stock-variant-option-value.model";

export class StockVariantOptionValuesModel extends ApiResourceModel{
  public static IRI = '/api/stock_variant_option_values';
  public variant: StockVariantModel;
  public variantOption: StockVariantOptionModel;
  public variantOptionValue: StockVariantOptionValueModel;
}
