import { Component, OnInit } from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../../service/http.service';
import {SaleStatisticModel} from '../../../model/sale-statistic.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-product-statistic',
  templateUrl: './product-statistic.component.html',
  styleUrls: ['./product-statistic.component.scss']
})
export class ProductStatisticComponent implements OnInit {
  stock: StockModel = new StockModel();
  stats: SaleStatisticModel[] = [];
  totalStats: SaleStatisticModel[] = [];
  id: number;
  variantId: number;
  loading: boolean;
  basicData: any;
  totalData: any;

  constructor(private activatedRoute: ActivatedRoute, private http: HttpService, private translate: TranslateService) { }

  ngOnInit(): void {

    // tslint:disable-next-line:radix
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    // tslint:disable-next-line:radix
    this.variantId = parseInt(this.activatedRoute.snapshot.paramMap.get('variantId'));

    this.loading = true;
    this.http.get(`${StockModel.IRI}/${this.id}`).toPromise().then((response: StockModel) => {
      this.stock = response;
      this.loading = false;
    });

    this.http.get(SaleStatisticModel.IRI, {'variant.id': this.variantId}).subscribe(response => {
      this.stats = response['hydra:member'];

      const labels = [];
      const data = [];
      const prices = [];
      this.stats.map(stat => {
        labels.push(stat.month);
        data.push(stat.total);
        prices.push(stat.price);
      });

      this.basicData = {
        labels,
        datasets: [
          {
            label: this.translate.instant('TOTAL_ORDER'),
            data,
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
          },
          {
            label: this.translate.instant('UNIT_PRICE'),
            data: prices,
            fill: false,
            borderColor: '#FFA726',
            tension: .4
          },
        ]
      };
    });

    this.http.get(SaleStatisticModel.IRI, {'exists[variant]': false}).subscribe(response => {
      this.totalStats = response['hydra:member'];

      const totalLabels = [];
      const totalData = [];

      this.totalStats.map(stat => {
        totalLabels.push(stat.month);
        totalData.push(stat.total);
      });

      this.totalData = {
        labels: totalLabels,
        datasets: [
          {
            label: this.translate.instant('TOTAL'),
            data: totalData,
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
          },
        ]
      };
    });
  }

}
