import {ApiResourceModel} from './api-resource.model';
import {OperationModel} from './operation.model';
import {ResourceModel} from './resource.model';
import {WorkOrderModel} from './work-order.model';
import {WorkOrderOperationBatchModel} from "./work-order-operation-batch.model";

export class WorkOrderOperationModel extends ApiResourceModel{
  public static IRI = '/api/work_order_operations';
  public id: number;
  public operation: OperationModel|string;
  public resource: ResourceModel|string;
  public dependencies: string[] = [];
  public plannedTime: number;
  public plannedQuantity: number;
  public cost: number;
  public totalCost: number;
  public status: string;
  public workOrder: WorkOrderModel;
  public isActive = true;
  public notes: string;
  public isLastOperation: string;
}
