import {Component, OnInit} from '@angular/core';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {UserModel} from '../../../model/user.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {UnitModel} from '../../../model/unit.model';
import {CurrencyModel} from '../../../model/currency.model';
import {LocationModel} from '../../../model/location.model';
import {CategoryModel} from '../../../model/category.model';
import {ContactModel} from '../../../model/contact.model';
import {StockModel} from '../../../model/stock.model';
import {PrintTemplateModel} from '../../../model/print-template.model';
import {ProductRecipeModel} from '../../../model/product-recipe.model';
import {HttpService} from '../../../service/http.service';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrencyService} from '../../../service/currency.service';
import {UtilityService} from '../../../service/utility.service';
import {NotificationService} from '../../../service/notification.service';
import {CategoryService} from '../../../service/category.service';
import {ContactService} from '../../../service/contact.service';
import {LocationService} from '../../../service/location.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {QueueModel} from '../../../model/queue.model';
import {LazyLoadEvent} from 'primeng/api';
import {InventoryStockModel} from '../../../model/inventory-stock.model';
import {SidebarService} from '../../../service/sidebar.service';
import {PurchaseOptionModel} from '../../../model/purchase-option.model';

@Component({
  selector: 'app-inventory-items',
  templateUrl: './inventory-items.component.html',
  styleUrls: ['./inventory-items.component.scss']
})
export class InventoryItemsComponent implements OnInit {
  products: StockVariantModel[] = [];
  loading = true;
  total: number;
  user: UserModel = new UserModel();
  settings = [];
  currency: string;

  taxes: TaxRateModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  categories: CategoryModel[] = [];
  suppliers: ContactModel[] = [];
  searchCategories = [];
  searchSuppliers = [];
  searchLocations = [];

  display: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  type: string;
  moreLoading: boolean;
  showQuantitiesDialog: boolean;
  quantities: any [];
  printing: boolean;
  printTemplate: PrintTemplateModel = new PrintTemplateModel();
  apiUrl: string;
  queueDisplay = false;
  recipe: ProductRecipeModel[] = [];
  recipeDialog: boolean;
  recipeId: number;
  tableLoading: boolean;
  selectedVariant: any [];
  sumInStock: number;
  sumValueInStock: number;

  parameters = {
    id: '',
    'stock.name': '',
    code: '',
    'stock.category.name': '',
    'stock.category.id': '',
    'stock.currency.code': '',
    'stock.supplier.id': '',
    'stock.type': 'material',
    'stock.isActive': 1,
    page: 1,
    itemsPerPage: 20,
    location: 0,
  };
  selectLocationShow: boolean;
  selectLocationShowCount = 0;
  totalLocation = 0;
  actions: any;
  displayAction: boolean;
  stockIsActive: any;

  constructor(private http: HttpService, private dataTableSettingService: DataTableSettingService,
              private router: Router, private activatedRoute: ActivatedRoute, private currencyService: CurrencyService,
              private utilityService: UtilityService, private sharedDataService: NotificationService,
              private categoryService: CategoryService, private contactService: ContactService,
              private locationService: LocationService, private translate: TranslateService, private route: ActivatedRoute,
              private sidebarService: SidebarService) {
  }


  ngOnInit(): void {
    this.stockIsActive = true;
    this.route.queryParams.subscribe(params => {
      const searchTerm = params.searchTerm;
      if (searchTerm) {
       this.parameters['stock.name'] = searchTerm;
      }
    });
    this.sidebarService.getDisplay().subscribe((display) => {
      this.display = display === true ? false : false;
      this.displayCreate = display === true ? false : false;
      this.recipeDialog = display === true ? false : false;
    });
    this.display = false;
    this.displayCreate = false;
    this.recipeDialog = false;
    this.currency = this.currencyService.getCurrency().code;

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.apiUrl = environment.apiUrl;
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
      this.controlImportStatus();
    });
    this.loadSearchData().then();
  }

  load(event: LazyLoadEvent = null): void {
    window.localStorage.setItem( 'locationID' , String(this.parameters.location) );
    this.products = [];
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.parameters['stock.isActive'] = this.stockIsActive;
    if (this.stockIsActive === false){
      this.parameters['stock.isActive'] = 0;
    }
    if (this.stockIsActive === true){
      this.parameters['stock.isActive'] = 1;
    }
    if (this.parameters.location) {
      this.tableLoading = true;
      this.http.get(StockVariantModel.IRI, this.parameters).subscribe(response => {
        this.products = response['hydra:member'];
        this.total = response['hydra:totalItems'];
        this.tableLoading = false;
      });
      this.http.get(`${StockVariantModel.IRI}/location_sum/` + this.parameters.location, this.parameters).subscribe(res => {
        this.sumValueInStock = res['hydra:member'][1];
        this.sumInStock = res['hydra:member'][0];
      });
    }
  }

  async loadSearchData(): Promise<void> {
    this.loading = true;

    this.http.get(`${CategoryModel.IRI}/category-type/stock?search=yes`).subscribe(category => {
      category['hydra:member'].unshift({name: this.translate.instant('ALL_CATEGORIES'), id: null});
      this.categories = category['hydra:member'];
    });
    this.http.get(`${ContactModel.IRI}/get-contact-by-type/supplier`).subscribe(category => {
      this.searchSuppliers = category['hydra:member'];
    });
    await this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;

      if (this.locations.length === 1){
        this.selectLocationShowCount = 1;
        this.parameters['stock.locations.id'] = this.locations[0].id;
        this.parameters.location = this.locations[0].id;
      }else{
        if ( this.selectLocationShowCount === 0){
          this.selectLocationShowCount = 1;
          this.selectLocationShow = true;
        }
      }
      this.loading = false;
      this.load();
    });
  }
  async loadSearchDataFilter(): Promise<void> {

    this.http.get(`${CategoryModel.IRI}/category-type/stock`).subscribe(category => {
      category['hydra:member'].unshift({name: this.translate.instant('ALL_CATEGORIES'), id: null});
      this.categories = category['hydra:member'];
    });
    this.searchSuppliers = this.contactService.getSearchContacts({pagination: false, type: 'supplier'});
    await this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });
    this.load();
  }

  cost(variant: StockVariantModel): number {
    if (variant.stock.type !== 'material') {
      return variant.totalCost;
    } else {
      return variant.averageCost;
    }
  }

  createExcel(): void {
    const parameters = {...this.parameters};
    delete parameters.page;
    delete parameters.id;
    delete parameters.itemsPerPage;


    this.http.download('/api/data/exports/inventory_items', parameters)
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'inventoryItems.xlsx');
      });
  }

  createPdf(id: number, templateId: number): void {
    this.printing = true;
    this.http.get(`${StockVariantModel.IRI}/pdf`).subscribe(response => {
      this.printing = false;
      window.open(response.response, '_blank');
    });
  }

  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 30000);
    }
  }

  onLoadRecipes(id): void {
    this.recipeId = id;
    this.http.get(ProductRecipeModel.IRI, {'items.stock.id': id}).subscribe(res => {
      this.recipe = res['hydra:member'];
      this.loading = false;
      this.recipeDialog = true;
    });
  }

  getRecipeTotal(recipe): number {
    if (recipe) {
      let grandTotal = 0;
      recipe.map(item => {
        grandTotal = grandTotal + item.cost;
      });
      return grandTotal;
    }
    return 0;
  }

  openForm(): void {
    if ( this.checkActionPermission('CAN_ADD_NEW_STOCK')) {
    this.item = new StockModel();
    this.item.variants.push(new StockVariantModel());
    this.item.variants[0].alertLevel = 1;
    this.item.variants[0].minOrder = 1;
    this.display = true;
    this.displayCreate = true;
    this.displayUpdate = false;
    }else {
      this.displayAction = true;
    }
  }

  changeParameters(event, type): void {
    if (type === 'category') {
      this.parameters['category.id'] = event.value;
    }
    if (type === 'locations') {
      this.parameters['stock.location.id'] = event;
      this.parameters.location = event.value;
      this.selectLocationShow = false;
    }
    this.load(null);
  }

  async edit(event): Promise<void> {
    if ( this.checkActionPermission('CAN_EDIT_STOCK')) {
    this.displayCreate = true;
    await this.http.get(`${StockModel.IRI}/${event}`).subscribe((response) => {
      this.item = response;
      this.item.category = this.item.category ? this.item.category['@id'] : null;
      this.item.locations = this.item.locations ? this.item.locations : null;
      this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
      this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
      this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
      if (this.item.unit instanceof UnitModel) {
        this.item.unitName = this.item.unit ? this.item.unit.name : null;
      }
      // @ts-ignore
      this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
      this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
      this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
      this.item.purchaseOptions.map((data, i) => {
        this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
          this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
          this.item.purchaseOptions[i].supplier = data.supplier['@id'];
          this.item.purchaseOptions[i].unit = data.unit['@id'];
          if (data.packName === '' && data.packPerCase === null) {
            this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
          } else {
            this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
          }

        });
      });
      this.item.locations.map((location, i) => {
        this.item.locations[i] = location['@id'];
      });
      this.displayCreate = true;
      this.display = true;
    });
    }else {
      this.displayAction = true;
    }

  }

  reload(): void {
    this.load();
    this.display = false;
  }

  getAveragePrice(row): number {
    // tslint:disable-next-line:max-line-length
    const findIndex = row.stock.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters['stock.locations.id']);

    if (findIndex !== -1) {
      row.price = row.stock.stockQuantities[findIndex].lastPurchasePrice;
      row.alertLevel = row.stock.stockQuantities[findIndex].alertLevel;
      row.amount = row.stock.stockQuantities[findIndex].amount;
      row.valueInStock = row.stock.stockQuantities[findIndex].valueInStock;
      row.lastCount = row.stock.stockQuantities[findIndex].lastCount;
      return row.stock.stockQuantities[findIndex].averagePrice;
    } else {
      return 0;
    }
  }

  showLocationQuantity(row): void {
    this.quantities = row.stock;
    this.showQuantitiesDialog = true;
  }

  getLocationName(id): string {
    const findIndex = this.locations.findIndex(obj => obj['@id'] === id);
    if (findIndex === -1) {
      return '';
    } else {
      return this.locations[findIndex].name;
    }
  }
  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].inventory.findIndex(obj => obj.name === text);
      return this.actions[0].inventory[findIndex].value ? this.actions[0].inventory[findIndex].value : false;
    }
  }

}

