import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../../../../service/http.service';
import {DialogService} from 'primeng/dynamicdialog';
import {CategoryModel} from '../../../../model/category.model';
import {CompanyModel} from '../../../../model/company.model';
import {ConfirmationService, MenuItem, TreeNode} from 'primeng/api';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ValidationService} from '../../../../service/validation.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {CategoryService} from '../../../../service/category.service';
import {CreateCategoryComponent} from '../../../settings/category/create-category/create-category.component';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
  providers: [DialogService]
})
export class AddCategoryComponent implements OnInit, OnDestroy {
  items: CategoryModel[] = [];
  activeItems: CategoryModel[] = [];
  item: CategoryModel = new CategoryModel();
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean;
  errors = [];
  files: TreeNode[];
  data: TreeNode[];
  isOpen: boolean;
  parameters = {
    categoryType: 'stock'
  };
  tabMenuItems: MenuItem[] = [];
  activeTabMenuItems: MenuItem;
  validationSubscriber: Subscription;
  type: string;
  moreLoading: boolean;

  constructor(private router: Router, private http: HttpService,
              private confirmationService: ConfirmationService, private translate: TranslateService,
              private validationService: ValidationService, private flashMessageService: FlashMessageService,
              private categoryService: CategoryService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.loadCategory(null).then();
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }
  async loadCategory(id: number| null): Promise<void> {
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.categoryService.getFilesystem(this.parameters).then(files => this.files = files);
    this.data = this.loadTree(this.files);
    this.loading = false;
  }
  loadTree(treeData): any {
    const data = [];
    treeData.forEach((key, i) => {
      let d;
      const rg = d ? (d.children || (d.children = [])) : data;
      d = data.find(e => e.data.children === null) || (data[data.length] =
        { data: {
            code: key.code,
            name: key.name,
            isActive: key.isActive,
            id: key.id,
            parentId: key.parent,
            tax: key.tax ? key.tax : null,
            categoryType: key.categoryType,
            accountingCode: key.accountingCode,
            location: key.location?.name,
          },
          children: this.loadTree(key.children)
        });
    });
    return data;
  }
  openForm(item: CategoryModel = null): void{
    this.errors = [];
    const ref = this.dialogService.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: {item}
    });

    ref.onClose.subscribe(() => {
      this.loadCategory(null).then();
    });
    this.isOpen = true;
  }
  edit(row): void {
    this.item = JSON.parse(JSON.stringify(row));
    if (this.item.parent) {
      this.item.parent = this.item.parent['@id'];
    }else{
      this.item.parent = this.item.parentId;
    }
    if ( this.item.tax) {
      this.item.tax = this.item.tax['@id'];
    }
    this.openForm(this.item);
  }
  nextPage(): void {
    this.router.navigate(['initial-setup/okay-and-continue']).then(r => {
    });
  }

  previousPage(): void {
    this.router.navigate(['initial-setup/add-tax']).then(r => {
    });
  }
  ngOnDestroy(): void {
    this.validationService.updateErrors(null);

    if (this.validationSubscriber) {
      this.validationSubscriber.unsubscribe();
    }

    this.categoryService.categories.next([]);
  }

}
