import {Component, OnInit} from '@angular/core';
import {InvoiceModel} from '../../../model/invoice.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {CreditModel} from '../../../model/credit.model';
import {Subscription} from 'rxjs';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {InventoryModel} from '../../../model/inventory.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {ShipmentItemModel} from '../../../model/shipment-item.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {DatePipe} from '@angular/common';
import {StockModel} from '../../../model/stock.model';

@Component({
  selector: 'app-order-receive',
  templateUrl: './order-receive.component.html',
  styleUrls: ['./order-receive.component.scss']
})
export class OrderReceiveComponent implements OnInit {
  showInvoiceDialog: boolean;
  showWaybillDialog: boolean;
  invoice: InvoiceModel = new InvoiceModel();
  invoiceItem: InvoiceModel = new InvoiceModel();
  shipment: ShipmentModel = new ShipmentModel();
  credit: CreditModel = new CreditModel();
  submitted: boolean;
  loading: boolean;
  id: any;
  uploadedFiles: any[] = [];
  documentType: any[] = [];
  currencyLabel: string;
  shipments: ShipmentModel [] = [];
  errors = [];
  totalError = [];
  validationSubscriber: Subscription;
  creditRemember: boolean;
  editAction: boolean;
  discountRateChecked = false;
  discountValueChecked = false;
  priceChange: boolean;
  creditValues: string[] = [];
  priceCreditValues: string[] = [];
  priceCreditValuesOrder: number[] = [];
  priceChangeValues: string[] = [];
  disabledValues: string[] = [];
  changePriceVariants: StockPriceChanges [] = [];
  tableLoading: boolean;
  discountRate: number;
  firstDiscountValues: number[] = [];
  firstDiscountRateValues: number[] = [];
  secondDiscountValues: number[] = [];
  total: number;
  subTotal: number;
  tax: number;
  itemUnitPriceValues: number[] = [];
  itemSubTotalValues: number[] = [];
  itemTaxValues: number[] = [];
  discountValue: number;
  discountRateValue: number;
  variants: StockVariantModel[] = [];
  firstSubTotal: number;
  isDiscount: boolean;
  discountRateValueTotal: number;
  today: Date = new Date();
  minDate: Date;
  activeInventory: InventoryModel;
  isCanBeSend = true;
  isCanBeSelectDate: Date | string;
  agencyNumber = false;
  agencyNumberNotNull = false;
  actionPrice: boolean;
  actionCredit: boolean;
  documentNumberIsValid: boolean;
  isIncomingInvoice: boolean;
  notSave: boolean;
  lotTrackingView: boolean;

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private validationService: ValidationService,
              private flashMessageService: FlashMessageService,
              private translate: TranslateService,
              private confirmationService: ConfirmationService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.isIncomingInvoice = false;
    this.isDiscount = false;
    this.loading = true;
    this.shipment.documentType = this.route.snapshot.paramMap.get('type');
    this.creditRemember = false;
    this.editAction = false;
    this.documentType = [
      {name: this.translate.instant('INVOICE'), value: 'Invoice'},
      {name: this.translate.instant('DELIVERY_NOTE'), value: 'DeliveryNote'},
    ];
    this.loadInvoice().then();
    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });
    this.getActiveInventory().then();
  }

  async getActiveInventory(): Promise<any> {
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      this.activeInventory = response;

      const inventoryDate = new Date(this.activeInventory.endDate);

      const docDate = new Date(this.shipment.documentDate);

      if (this.shipment.documentDate) {
        if (inventoryDate.getTime() > docDate.getTime()) {
          this.notSave = true;
        } else {
          this.notSave = false;
        }
      }
    });
  }

  async loadInvoice(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');
    await this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
      this.invoice = response;
      this.total = this.invoice.total;
      this.subTotal = this.invoice.subtotal;
      this.firstSubTotal = this.invoice.subtotal;
      this.tax = this.invoice.tax;
      // Shipment
      this.shipment.invoice = this.invoice;
      if (this.invoice.incomingInvoices.length > 0){
        this.isIncomingInvoice = true;
        this.shipment.documentDate = new Date(this.invoice.incomingInvoices[0].invoiceDate);
        this.shipment.documentNumber = this.invoice.incomingInvoices[0].invoiceNumber;
      }
      // Shipment end
      this.currencyLabel = this.invoice.currency?.symbol;
      this.credit.invoice = this.invoice['@id'];
      this.credit.location = this.invoice.location['@id'];
      this.credit.currency = this.invoice.currency['@id'];
      this.credit.contact = this.invoice.contact['@id'];
      this.lotTrackingView = false;
      this.invoice.items.map((item, i) => {
        // @ts-ignore
        if (item.stock?.isTrackByLot && item.stock.isTrackByLot === true){
          this.lotTrackingView = true;
        }
        // @ts-ignore
        item.stock = item.variant.stock;
        // @ts-ignore
        this.invoice.items[i].barcode = item.variant.barcode + '' + this.invoice.number;
        this.invoice.items[i].firstUnitPrice = item.unitPrice;
        this.invoice.items[i].discount = this.invoice.items[i].discount ? this.invoice.items[i].discount : 0;
        this.invoice.items[i].discountRate = this.invoice.items[i].discountRate ? this.invoice.items[i].discountRate : 0;
        this.itemUnitPriceValues.push(item.unitPrice);
        this.itemSubTotalValues.push(item.subtotal);
        this.itemTaxValues.push(item.tax);
        item.documentQuantity = item.quantity;
        if (item.incomingQuantity === 0) {
          item.incomingQuantity = item.remainingQuantity;
        } else {
          item.incomingQuantity = item.incomingQuantity;
        }
        const shipmentItem = new ShipmentItemModel();
        shipmentItem.invoiceItem = item['@id'];
        shipmentItem.quantity = item.remainingQuantity;
        this.shipment.items.push(shipmentItem);
        this.creditValues.push(item.stock['@id']);
        this.priceCreditValues.push(item.stock['@id']);
      });
      this.http.get(CreditModel.IRI, {'invoice.id': this.id}).subscribe(res => {
        this.loading = false;
        if (res['hydra:totalItems'] > 0) {
          this.priceCreditValues = [];
          this.credit = res['hydra:member'][0];
          this.creditRemember = true;
          this.credit.items.map(item => {
            if (item.type === 'CHANGE_QUANTITY') {
              // @ts-ignore
              this.creditValues.push(item.stock['@id']);
            } else if (item.type === 'CHANGE_PRICE') {
              // @ts-ignore
              this.priceCreditValues.push(item.stock['@id']);
              const findObject = this.invoice.items.findIndex(obj => obj.stock['@id'] === item.stock['@id']);
              if (findObject !== -1) {
                this.priceCreditValuesOrder.push(findObject);
              }
              this.priceChange = true;
            }
          });
        }
      });

    });
    await this.http.get(ShipmentModel.IRI, {invoice: this.id}).subscribe(data => {
      if (data['hydra:totalItems'] > 0) {
        this.shipments = data['hydra:member'];
        this.shipments.map(item => {
          this.shipment.documentType = item.documentType;
          this.shipment.documentDate =  item.documentDate;
          this.shipment.documentNumber = item.documentNumber;
          this.shipment.id = item.id;
          this.shipment.total = item.total;
          this.shipment.taxTotal = item.taxTotal;
          if (this.shipments[0].paymentDate) {
            this.shipment.paymentDate = new Date(item.paymentDate);
          }
        });
      } else {
        this.editAction = true;
      }
      this.tableLoading = false;

    });
  }

  async save(): Promise<void> {
    // @ts-ignore
    this.invoice.total = parseFloat(this.invoice.total.toFixed(2));
    this.submitted = true;
    this.errors = [];
    if (this.shipment.documentNumber === undefined ) {
      this.totalError = [];
      this.totalError.push({documentNumber: 'Döküman Numarası Giriniz'});
      this.submitted = false;
      // tslint:disable-next-line:max-line-length
    }else if (this.shipment.documentDate === undefined ) {
      this.totalError = [];
      this.totalError.push({documentDate: 'Döküman Tarihi Seçiniz'});
      this.submitted = false;
      // tslint:disable-next-line:max-line-length
    }else if (this.shipment.total === undefined || this.invoice.total !== this.shipment.total || this.shipment.documentType === undefined) {
      this.totalError = [];
      this.totalError.push({shipmentTotal: 'Fatura Tutarını Giriniz'});
      this.submitted = false;
    } else {
      this.totalError = [];
      this.invoiceSave().then(async () => {
        this.shipment.invoice = this.invoice['@id'];
        const date = new Date(this.shipment.documentDate);
        const datePipe = new DatePipe('en-US');
        this.shipment.documentDate = datePipe.transform(date, 'yyyy-MM-dd');
        if (this.shipment.id) {
          await this.http.put(`${ShipmentModel.IRI}/${this.shipment.id}`, this.shipment).then( response => {
           if ( response.invoice?.isReceived === true){
             this.router.navigate([`/orders/receive-order`]);
             this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
           }else {
             this.submitted = false;
             this.loading = false;
           }
          });
        }else {
          await this.http.post(ShipmentModel.IRI, this.shipment).then((response: ShipmentModel) => {
            this.router.navigate([`/orders/receive-order`]);
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          }).catch(error => {
            this.router.navigate([`/orders/receive-order`]);
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          });
        }
        this.credit.items.map(async (item, i) => {
          this.credit.items[i].stock = item.stock['@id'];
          this.credit.items[i].variant = item.variant['@id'];
        });
        // tslint:disable-next-line:no-unused-expression
        this.changePriceVariants.map(async changePrice => {
          await this.http.post(StockPriceChanges.IRI, changePrice).subscribe(res => {
          }, 2000);
        });
        if (this.credit.id) {
          this.credit.contact = this.credit.contact['@id'];
          this.credit.location = this.credit.location['@id'];
          this.credit.currency = this.credit.currency['@id'];
          this.credit.invoice = this.credit.invoice['@id'];
          await this.http.put(`${CreditModel.IRI}/${this.credit.id}`, this.credit).subscribe(res => {
          });
        } else {
          await this.http.post(CreditModel.IRI, this.credit).subscribe(res => {
          });
        }
        this.submitted = false;
      });
    }
  }

  async invoiceSave(): Promise<void> {
    this.loading = true;
    this.invoice.contact = this.invoice.contact['@id'];
    this.invoice.currency = this.invoice.currency['@id'];
    this.invoice.location = this.invoice.location['@id'];
    this.invoice.credits = this.invoice.credits['@id'];
    this.invoice.discountRate = parseFloat(String(this.invoice.discountRate));
    this.invoice.items.map((item, key) => {
      this.invoice.items[key].currency = this.invoice.currency;
      this.invoice.items[key].stock = this.invoice.items[key].stock['@id'];
      this.invoice.items[key].variant = this.invoice.items[key].variant['@id'];
      this.invoice.items[key].taxRate = this.invoice.items[key].taxRate['@id'];
      if (this.invoice.items[key].purchaseOption) {
        this.invoice.items[key].purchaseOption = this.invoice.items[key].purchaseOption['@id'];
      }
    });
    this.invoice.incomingInvoices.map((inv, key) => {
      this.invoice.incomingInvoices[key] = this.invoice.incomingInvoices[key]['@id'];
    });
    this.invoice.shipments.map((shipmentItem, key) => {
      this.invoice.shipments[key] = this.invoice.shipments[key]['@id'];
    });
    await this.http.put(`${InvoiceModel.IRI}/${this.invoice.id}`, this.invoice).then();
  }

  onChangeDiscountRate(value, i): void {
    this.invoice.items[i].priceChangeAction = 'update';
    this.invoice.items[i].discountRate = parseFloat(value.target.value);
    this.invoice.items[i].discount = this.invoice.items[i].firstUnitPrice * (parseFloat(value.target.value) / 100);
    // tslint:disable-next-line:max-line-length
    this.invoice.items[i].unitPrice = this.invoice.items[i].firstUnitPrice - (this.invoice.items[i].firstUnitPrice * (parseFloat(value.target.value) / 100));
    this.invoicePriceControl();
  }

  changeInInvoiceItemQuantity(row, i, event = null): void {
    this.credit.items = [];
    this.invoice.items[i].quantity = parseFloat(event.target.value);
    this.invoice.items[i].incomingQuantity = parseFloat(event.target.value);
    this.invoice.items[i].remainingQuantity = parseFloat(event.target.value);
    this.invoice.items[i].credit = true;
    this.invoicePriceControl();
  }

  invoicePriceControl(): void {
    this.credit.items = [];
    let newSubtotal = 0;
    let newTotal = 0;
    let newTaxTotal = 0;
    let actionPriceCount = 0;
    let actionCreditCount = 0;
    let itemsDiscountTotal = 0;
    this.invoice.items.map((item, i) => {
      if (item.firstUnitPrice !== item.unitPrice) {
        actionPriceCount = actionPriceCount + 1;
        if (item.priceChangeAction === 'credit') {
        // Price Change
        const creditItem = {
          quantity: this.invoice.items[i].incomingQuantity,
          variant: this.invoice.items[i].variant,
          stock: this.invoice.items[i].stock,
          reason: '',
          type: 'PRICE_CHANGE',
          price: parseFloat(String(this.invoice.items[i].unitPrice - item.firstUnitPrice)),
          subtotal: this.invoice.items[i].unitPrice * this.invoice.items[i].incomingQuantity,
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          tax: (this.invoice.items[i].incomingQuantity) * (this.invoice.items[i].unitPrice - item.firstUnitPrice) * (item.taxRate?.rate / 100),
          discountRate: item.discountRate,
          // tslint:disable-next-line:max-line-length
          discount: (this.invoice.items[i].incomingQuantity) * (this.invoice.items[i].unitPrice - item.firstUnitPrice) * (item.discountRate / 100),
          total: (this.invoice.items[i].unitPrice - item.firstUnitPrice) * this.invoice.items[i].incomingQuantity

        };

        // tslint:disable-next-line:max-line-length
        const findObjIndex = this.credit.items.findIndex((obj => obj.variant['@id'] === this.invoice.items[i].variant['@id'] && obj.type === 'PRICE_CHANGE'));
        if (findObjIndex === -1) {
          // @ts-ignore
          this.credit.items.push(creditItem);
        } else {
          this.credit.items[findObjIndex].quantity = this.invoice.items[i].incomingQuantity;
        }
        }
        if (actionPriceCount > 0) {
          this.actionPrice = true;
          this.creditRemember = true;
        } else {
          this.actionPrice = false;
          this.creditRemember = true;
        }
      }
      if (item.incomingQuantity < item.quantity) {
        // Incoming Quantity Change
        actionCreditCount = actionCreditCount + 1;
        // @ts-ignore
        const creditItem = {
          quantity: this.invoice.items[i].quantity - this.invoice.items[i].incomingQuantity,
          variant: this.invoice.items[i].variant,
          stock: this.invoice.items[i].stock,
          reason: '',
          type: 'CHANGE_QUANTITY',
          price: parseFloat(String(this.invoice.items[i].unitPrice)),
          subtotal: this.invoice.items[i].unitPrice * this.invoice.items[i].incomingQuantity,
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          tax: (this.invoice.items[i].quantity - this.invoice.items[i].incomingQuantity) * this.invoice.items[i].unitPrice * (item.taxRate?.rate / 100),
          discountRate: item.discountRate,
          // tslint:disable-next-line:max-line-length
          discount: (this.invoice.items[i].quantity - this.invoice.items[i].incomingQuantity) * this.invoice.items[i].unitPrice * (item.discountRate / 100),
          total: this.invoice.items[i].unitPrice * this.invoice.items[i].incomingQuantity
        };
        if (item.credit === true) {
          // tslint:disable-next-line:max-line-length
          const findObjIndex = this.credit.items.findIndex((obj => obj.variant['@id'] === this.invoice.items[i].variant['@id'] && obj.type === 'CHANGE_QUANTITY'));
          if (findObjIndex === -1) {
            // @ts-ignore
            this.credit.items.push(creditItem);
          } else {
            this.credit.items[findObjIndex].quantity = this.invoice.items[i].incomingQuantity;
          }
        }
        if (actionCreditCount > 0) {
          this.actionCredit = true;
          this.creditRemember = true;
        } else {
          this.actionCredit = false;
          this.creditRemember = false;
        }
      }
      this.invoice.items[i].discount = (item.unitPrice * item.quantity) * (item.discountRate / 100);
      itemsDiscountTotal = itemsDiscountTotal + item.discount;
      this.invoice.items[i].subtotal = item.firstUnitPrice * item.quantity;
      // @ts-ignore
      this.invoice.items[i].tax = (item.unitPrice * item.quantity) * (item.taxRate?.rate / 100);
      newSubtotal += item.firstUnitPrice * item.quantity;
      newTotal += item.unitPrice * item.quantity;
      // @ts-ignore
      newTaxTotal += (item.unitPrice * item.quantity) * (item.taxRate?.rate / 100);
    });
    if (this.credit.items.length === 0){
      this.creditRemember = false;
    }
    this.invoice.tax = newTaxTotal;
    this.invoice.subtotal = newSubtotal;
    this.invoice.discount = itemsDiscountTotal;
    this.invoice.total = newTaxTotal + newSubtotal - itemsDiscountTotal;
  }


  deleteItem(i): void {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_YOU_SURE_THAT_YOU_WANT_TO_PERFORM_THIS_ACTION'),
      accept: () => {
        this.invoice.items.splice(i, 1);
        this.invoicePriceControl();
        this.invoiceSave().then();
        this.loadInvoice().then();
      }
    });
  }

  changeInInvoiceItemIncomingQuantity(row, i, event = null): void {
    this.credit.items = [];
    if (parseFloat(event.target.value) > this.invoice.items[i].quantity) {
      this.invoice.items[i].incomingQuantity = this.invoice.items[i].quantity;
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'INCOMING_QUANTITY_NOT_GREATER_THEN_DOCUMENT_QUANTITY'));
    } else {
      this.invoice.items[i].incomingQuantity = parseFloat(event.target.value);
    }
    this.invoicePriceControl();
  }

  creditItemControl(row, i): void {
    this.invoicePriceControl();
  }

  documentNumberControl(event): void {
    this.documentNumberIsValid = false;
    this.http.get(ShipmentModel.IRI, {documentNumber: event.target.value}).subscribe(data => {
      if (data['hydra:totalItems'] > 0) {
        this.documentNumberIsValid = true;
      }
    });
  }
  shipmentDateControl(event): void {
    this.getActiveInventory().then(() => {
      if (this.activeInventory.startDate) {
        const selectedDate = new Date(event);
        const InventoryStartDate = new Date(this.activeInventory.startDate);
        if (selectedDate.getTime() < InventoryStartDate.getTime()) {
          this.shipment.documentDate = this.today;
          this.isCanBeSend = false;
          this.isCanBeSelectDate = this.activeInventory.startDate;
        } else {
          this.minDate = InventoryStartDate;
          this.isCanBeSend = true;
        }
      } else {
        this.minDate = this.today;
      }
    });
  }
  edit(): void {
    this.editAction = true;
  }
}
