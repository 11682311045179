<app-loading-indicator [loading]="loading"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-12">
      <label for="name">{{'COMPANY_NAME' | translate}}</label>
      <input id="name" name="name" type="text" pInputText [(ngModel)]="item.name">
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="phoneNumber">{{'PHONE_NUMBER' | translate}}</label>
      <input id="phoneNumber" name="phoneNumber" type="text" pInputText [(ngModel)]="item.phoneNumber">
      <small class="p-error">{{ errors['phoneNumber'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="email2">{{'EMAIL' | translate}}</label>
      <input id="email2" type="text" name="email" pInputText [(ngModel)]="item.email">
      <small class="p-error">{{ errors['email'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="contactNumber">{{'CONTACT_NUMBER' | translate}}</label>
      <input id="contactNumber" name="contactNumber" type="text" pInputText [(ngModel)]="item.contactNumber">
      <small class="p-error">{{ errors['contactNumber'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="code">{{'CODE' | translate}}</label>
      <input id="code" name="code" type="text" pInputText [(ngModel)]="item.code">
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="taxAgency">{{'TAX_AGENCY' | translate}}</label>
      <input id="taxAgency" name="taxAgency" type="text" pInputText [(ngModel)]="item.taxAgency">
      <small class="p-error">{{ errors['taxAgency'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="taxAgencyNumber">{{'TAX_AGENCY_NUMBER' | translate}}</label>
      <input id="taxAgencyNumber" name="taxAgencyNumber" type="text" pInputText [(ngModel)]="item.taxAgencyNumber">
      <small class="p-error">{{ errors['taxAgencyNumber'] }}</small>
    </div>
    <div class="p-col-6">
      <label for="type">{{'CONTACT_TYPE' | translate}}</label>
      <p-dropdown
        [style]="{width: '100% !important'}"
        [options]="contactTypes" appendTo="body" (onChange)="this.item = $event.value"
        [ngModel]="item.type"  placeholder="select"
        optionLabel="name" optionValue="value">
      </p-dropdown>
      <small class="p-error">{{ errors['type'] }}</small>
    </div>
  </div>


<!---
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
      <label for="type">{{'CONTACT_TYPE' | translate}}
        <app-tooltip description="CONTACTS_EMAIL_TOOLTIP"></app-tooltip>
      </label>
      <p-dropdown id="type" appendTo="body"  [options]="contactTypes" [(ngModel)]="item.type" optionLabel="name" optionValue="value"></p-dropdown>
      <small class="p-error">{{ errors['contactType'] }}</small>
    </div>
  </div>

  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
    </div>
    <div class="p-field p-col">
      <label for="category">{{'CATEGORY' | translate}}
        <i (click)="addCategory()" class="pi pi-plus" style="margin-left: 10px;font-weight: 600;color: #00b3d1;cursor: pointer"></i>
      </label>
      <p-multiSelect id="category" [options]="categories" [(ngModel)]="item.category" placeholder="Kategori Seç" optionLabel="name" optionValue="@id"></p-multiSelect>
      <small class="p-error">{{ errors['category'] }}</small>
    </div>
  </div>


  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
      <h3>{{'ORDER_INFORMATION'| translate}}</h3>
    </div>
    <div class="p-field p-col p-offset-2">
      <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <p-button (click)="activeIndex = 0"  styleClass="p-button-secondary" label="{{'FOR_ORDER'|translate}}"></p-button>
      </div>
      <div class="p-field p-col">
        <p-button (click)="activeIndex = 1"  styleClass="p-button-text" label="{{'NOT_FOR_ORDER'|translate}}" [style]="{width: '50%'}"></p-button>
      </div>
         </div>
         </div>
  </div>
  <hr>
  <div class="p-fluid">
    <p-tabView [(activeIndex)]="activeIndex">
      <p-tabPanel>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col">

          </div>
          <div class="p-field p-col">
            <label for="orderTextNumber">{{'ORDER_TEXT_NUMBER' | translate}}</label>
            <input id="orderTextNumber" name="orderTextNumber" type="text" pInputText [(ngModel)]="item.orderTextNumber">
            <small class="p-error">{{ errors['orderTextNumber'] }}</small>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col">
          </div>
          <div class="p-field p-col">
            <label for="minimumOrder">{{'MIN_ORDER' | translate}}</label>
            <input id="minimumOrder" type="number" pInputText [(ngModel)]="item.minimumOrder">
            <small class="p-error">{{ errors['minimumOrder'] }}</small>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col" >
            <label for="ccMail">{{'CC_MAIL_ADDRESS' | translate}}</label>
            <input id="ccMail" name="ccMail" type="text" pInputText [(ngModel)]="item.ccMail">
            <small class="p-error">{{ errors['ccMail'] }}</small>
          </div>
          <div class="p-field p-col">
            <div class="field-checkbox">
              <p-checkbox value="true" [(ngModel)]="item.deliveryDay"></p-checkbox>
              <label> Hergün Teslimat</label>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel>
        Content 2
      </p-tabPanel>
    </p-tabView>
  </div>

  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
    </div>
    <div class="p-field p-col">
    </div>
  </div>

  <h3>{{'PAYMENT_INFORMATION' | translate}}</h3>
  <hr>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="paymentTerms">{{'PAYMENT_TERMS' | translate}}</label>
        <p-dropdown id="paymentTerms" [options]="paymentTerms" [(ngModel)]="item.paymentTerm" (onChange)="onChangePaymentTerms($event)"
                    placeholder="Seç" optionLabel="name" optionValue="value"></p-dropdown>
        <small class="p-error">{{ errors['paymentTerm'] }}</small>
      </div>
      <div class="p-field p-col">
        <label for="days">.</label>
        <input *ngIf="daysCheckBox == true" id="days" name="days" type="number" min="1" pInputText [(ngModel)]="item.monthDay">
        <small class="p-error">{{ errors['monthDay'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col" >
        <label for="discountRate">{{'DISCOUNT_RATE' | translate}}</label>
        <p-inputNumber mode="decimal" id="discountRate" [(ngModel)]="item.discountRate" prefix="%"></p-inputNumber>
        <small class="p-error">{{ errors['discountRate'] }}</small>
      </div>
      <div class="p-field p-col"></div>
    </div>
  <h3>{{'ACCOUNTING_INFORMATION' | translate}}</h3>
  <br>

  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">

    </div>
    <div class="p-field p-col">
      <label for="taxes">{{'TAX_RATE' | translate}}
        <i (click)="addTax()" class="pi pi-plus" style="margin-left: 10px;font-weight: 600;color: #00b3d1;cursor: pointer"></i>
      </label>
      <p-dropdown id="taxes" appendTo="body"  [options]="taxes" [(ngModel)]="item.taxRate" placeholder="Seç" optionLabel="name" optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['taxRate'] }}</small>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col" >
    </div>

    <div class="p-field p-col">
    </div>
  </div>
  <h3 class="h5-title">{{ 'GENERAL' | translate }}</h3>
  <br>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
      <label for="comment">{{'COMMENT' | translate}}</label>
      <input id="comment" pInputText [ngStyle]="{ width: '100%'}" name="comment" [(ngModel)]="item.comment" type="text"/>
    </div>
    <div class="p-field p-col-12">
      <label for="purchaseGoal">{{'PURCHASE_GOAL' | translate}}</label>
      <input id="purchaseGoal" name="purchaseGoal" type="text" pInputText [(ngModel)]="item.purchaseGoal">
      <small class="p-error">{{ errors['purchaseGoal'] }}</small>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
      <div class="field-checkbox">
        <p-checkbox value="true" [(ngModel)]="item.costCalculation"></p-checkbox>
        <label>{{'ADD_SUPPLIER_TO_FOOD_COST_CALCULATION'| translate}}</label>
      </div>
    </div>
    <div class="p-field p-col-12">
      <div class="field-checkbox">
        <p-checkbox  value="true" [(ngModel)]="item.privateContact"></p-checkbox>
        <label>{{'PRIVATE_SUPPLIER_NOT_VIEWABLE_TO_OTHERS'| translate}}</label>
      </div>
    </div>
    <div class="p-field p-col-12">
      <div class="field-checkbox">
        <p-checkbox  value="true" [(ngModel)]="item.createRefundNote"></p-checkbox>
        <label>{{'CREATE_REFUND_NOTE_FOR_PRICE_DIFFERENCES'| translate}}</label>
      </div>
    </div>
    <div class="p-field p-col-12">
      <div class="field-checkbox">
        <p-checkbox  value="true" [(ngModel)]="item.autoUpdatePrice"></p-checkbox>
        <label>{{'AUTOMATICALLY_UPDATE_PRICES_FROM_INVOICES'| translate}}</label>
      </div>
    </div>
  </div>
-->
  <div class="p-fluid">
    <div class="p-field p-col-3 p-offset-4">
      <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
    </div>
  </div>
