import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationModel} from '../../../../../model/location.model';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../../service/location.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {UtilityService} from '../../../../../service/utility.service';

@Component({
  selector: 'app-by-daily',
  templateUrl: './by-daily.component.html',
  styleUrls: ['./by-daily.component.scss']
})
export class ByDailyComponent implements OnInit {
  events: any[] = [];
  options: any;
  info: any;
  sale = {
    subTotal: 0,
    total: 0,
    discount: 0,
    tax: 0,
    netTotal: 0,
    cuver: 0,
    saleCount: 0,
    averageCuver: 0,
    averageSale: 0
  };
  saleDates: any;
  categories: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  location: LocationModel[] = [];
  date: Date[] = [];
  parameters = {
    date: [],
    startDate: '',
    lastDate: '',
    location: '',
    saleDate: ''
  };

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService, private utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.startDate;
        // @ts-ignore
        this.parameters.lastDate = params.lastDate;
        this.date = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
        this.parameters.location = params.location ? params.location : '';
        const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));
        console.log(dateRange);

        const selectedDate = dateRange.map(date => this.getFormattedDate(date));

        this.parameters.saleDate = selectedDate.join(',');
      }
      else {
        this.getThisWeekDates();
      }
    });
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/reports/daily_income_reports', this.parameters).subscribe(response => {
      this.saleDates = response;
      this.saleDates.map(item => {
        console.log(item.netTotal);
        console.log(item.total - item.tax);
        this.sale.subTotal += parseFloat(item.subTotal);
        this.sale.total += parseFloat(item.total);
        this.sale.netTotal += parseFloat(item.netTotal);
        this.sale.tax += parseFloat(item.tax);
        this.sale.discount += parseFloat(item.discount);
        this.sale.cuver += parseFloat(item.cuver);
        this.sale.saleCount += parseFloat(item.saleCount);
      });
      this.sale.averageCuver = this.sale.netTotal / this.sale.cuver;
      this.sale.averageSale = this.sale.netTotal / this.sale.saleCount;
      this.loading = false;

    });
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
      this.date = [new Date(this.info.startDate), new Date(this.info.lastDate)];
    });
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    this.parameters.startDate = this.getFormattedDate(start);
    this.parameters.lastDate = this.getFormattedDate(end);
    const dateRange = this.getDateRange(start, end);

    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    this.parameters.saleDate = selectedDate.join(',');

    this.parameters = { ...this.parameters };


  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'block';
      }
      // @ts-ignore
      pdf.save(`unpaid_report_byDaily.pdf`); // PDF'i indir
    });
  }
  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/unpaidByDaily', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'unpaid_report_byDaily.xlsx'); });
  }
  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange;
  }
  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  getThisWeekDates(): void {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Haftanın ilk gününü al
    const thisWeekDates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      thisWeekDates.push(this.getFormattedDate(date));
    }
    this.parameters.startDate = this.getFormattedDate(firstDayOfWeek);
    this.parameters.lastDate = thisWeekDates[thisWeekDates.length-1];

    this.parameters.saleDate = thisWeekDates.join(',');

  }
}
