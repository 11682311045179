import { Component, OnInit } from '@angular/core';
import {SectorModel} from '../../../../model/sector.model';
import {SectorService} from '../../../../service/sector.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {CookBookCategoryModel} from '../../../../model/cook-book-category.model';
import {CookBookCategoryService} from '../../../../service/cook-book-category.service';
import {CookBookStockModel} from '../../../../model/cook-book-stock.model';
import {CookBookStockService} from '../../../../service/cook-book-stock.service';
import {CreateCookBookCategoryComponent} from '../create-cook-book-category/create-cook-book-category.component';

@Component({
  selector: 'app-create-cook-book-stock',
  templateUrl: './create-cook-book-stock.component.html',
  styleUrls: ['./create-cook-book-stock.component.scss']
})
export class CreateCookBookStockComponent implements OnInit {



  item: CookBookStockModel = new CookBookStockModel();
  submitted: boolean;
  errors = [];
  categories: CookBookCategoryModel[] = [];

  constructor(private categoryService: CookBookCategoryService,
              private stockService: CookBookStockService,
              private flashMessageService: FlashMessageService,
              private ref: DynamicDialogRef,
              private validationService: ValidationService,
              private config: DynamicDialogConfig,
              private translate: TranslateService, private dialogService: DialogService) { }

  ngOnInit(): void {
    if (this.config.data?.item) {
      this.item = this.config.data.item;
    }

    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    this.categoryService.getItemsAction({type: 'stock', pagination: false, itemsPerPage: 300});
    this.categoryService.getItems().subscribe(response => {
      this.categories = response;
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;

    if (this.item.id) {
      await this.stockService.update(this.item).then(response => {
        if (response !== undefined) {
          this.stockService.getItemsAction({isActive: true});
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    } else {
      await this.stockService.save(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }

    this.submitted = false;
  }

  close(): void {
    this.ref.close();
  }

  onSetCategory(event): void {
    this.item.category = event;
  }

  addCategory(type): void {
    this.dialogService.open(CreateCookBookCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: type,
    });
  }


}
