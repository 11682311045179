import {CookBookCategoryModel} from './cook-book-category.model';

export class CookBookStockModel {
  public static IRI = '/api/cook_book_stocks';
  public id: number | null;
  public name: string;
  public code: string;
  public unit: string;
  public price: number;
  public cost: number;
  public category: CookBookCategoryModel[];
}
