import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api';
import {environment} from '../../../../environments/environment';
import {StockModel} from '../../../model/stock.model';
import {CookBookModel} from '../../../model/cook-book.model';
import {CookBookCategoryModel} from '../../../model/cook-book-category.model';
import {CookBookStockModel} from '../../../model/cook-book-stock.model';
import {CookBookSectorModel} from '../../../model/cook-book-sector.model';
import {CookBookItemModel} from '../../../model/cook-book-item.model';
import {DialogService} from 'primeng/dynamicdialog';
import {SectorService} from '../../../service/sector.service';
import {CreateSectorComponent} from './create-sector/create-sector.component';
import {CreateCookBookCategoryComponent} from './create-cook-book-category/create-cook-book-category.component';
import {CreateCookBookStockComponent} from './create-cook-book-stock/create-cook-book-stock.component';
import {CookBookCategoryService} from '../../../service/cook-book-category.service';
import {ImageCroppedEvent, ImageTransform} from 'ngx-image-cropper';
import {UploadModel} from '../../../model/upload.model';


@Component({
  selector: 'app-cook-book',
  templateUrl: './cook-book.component.html',
  styleUrls: ['./cook-book.component.scss']
})
export class CookBookComponent implements OnInit {
  loading: boolean;
  addNewCook: boolean;
  info: any;
  categories: CookBookCategoryModel[] = [];
  sectors: CookBookSectorModel[] = [];
  variants: CookBookModel[] = [];
  stocks: StockModel[] = [];
  cookBooks: CookBookModel[] = [];
  cookBook: CookBookModel;
  categoryStock = [];
  recipeAmount: any[] = [];
  item = {
    prepTime: 0,
    cookTime: 0,
    totalCost: 0,
    description: ''
  };
  listView = true;
  parameters = {
    id: '',
    name: '',
    code: '',
    pagination: true,
    category: '',
    sector: '',
    itemsPerPage: 20,
    page: 1,
  };
  total: number;
  visibleSidebar: boolean;
  moreLoading: boolean;
  apiUrl: string;

  imageChangedEvent: any;
  croppedImage: any ;
  displayBasic: boolean;
  scale = 1;
  transform: ImageTransform = {};
  imageSrc: string | ArrayBuffer | null = null;
  errorMessage: string | null = null;

  constructor(private http: HttpService,
              private categoryService: CookBookCategoryService,
              private sectorService: SectorService,
              private translate: TranslateService, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.loadSearchData();
    this.load();
  }

  load(event: LazyLoadEvent = null): void {
    this.loading = true;
    this.apiUrl = environment.apiUrl;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(CookBookModel.IRI, this.parameters).subscribe(response => {
      this.cookBooks = response['hydra:member'];
      this.total = response['hydra:totalItems'];
      this.loading = false;
      this.parameters.id = '';
    });
  }

  loadSearchData(): void {
    this.loading = true;
    // await this.categoryService.getCategoriesAction({isActive: true});
    this.http.get(CookBookCategoryModel.IRI, {type: 'cookBook', pagination: false, itemsPerPage: 300}).subscribe(response => {
      this.categories = response['hydra:member'];
    });
    this.categoryService.getItemsAction({type: 'cookbook' , pagination: false, itemsPerPage: 300});
    this.categoryService.getItems().subscribe(response => {
      this.categories = response;
    });

    this.sectorService.getSectorsAction({  pagination: false, itemsPerPage: 300});
    this.sectorService.getSectors().subscribe(response => {
      this.sectors = response;
    });
  }

  isThereProduct(id): number {
    const count = 0;

    return count;
  }

  onChangeCategory(id): void {
    this.parameters['stock.category.id'] = id;
    this.load();
  }

  openSideBar(item): void {
    this.loading = true;
    this.item = item;
    this.loading = false;
  }

  onSelectCategory(event): void {
    this.parameters.category = event.value ? event.value : '';
  }

  onSelectSector(event): void {
    this.parameters.sector = event.value ? event.value : '';
  }

  onSelectName(event): void {
    this.parameters.id = event.id ? event.id : '';
    this.load();
  }

  searchStock = (event) => {
    this.http.get(CookBookModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.stocks = response['hydra:member'];
    });
  }

  onOpenAdd(): void {
    this.addNewCook = true;
    this.cookBook = new CookBookModel();
    this.cookBook.items = [];
  }

  onOpenEdit(row): void {
    this.cookBook = row;
    this.cookBook.category = this.cookBook.category['@id'];
    if (this.cookBook.sector) {
      this.cookBook.sector = this.cookBook.sector['@id'];
    }
    this.onCalculateTotalCost();
    this.addNewCook = true;

  }

  searchVariant = (event) => {
    this.http.get(CookBookStockModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  }

  onVariantSelect(event, i): void {
    this.cookBook.items[i].cookBookStock = event;
  }

  onSetCategory(event): void {
    console.log(event);
    this.cookBook.category = event;
  }

  onSetSector(event): void {

    this.cookBook.sector = event;
  }

  onInputRecipe(event, i): void {
    this.cookBook.items[i].amount = (event);

    this.onCalculateTotalCost();
  }

  addItem(modifier = null, extra = null): void {
    const item = {
      cookBookStock: null,
      amount: 0
    };
    // @ts-ignore
    this.cookBook.items.push(item);
  }

  onSetTime = (event) => {
    return event;
  }
  onSetName = (event) => {
    return event;
  }
  onSetDescription = (event) => {
    return event;
  }

  onConsole(): void {
    if (this.cookBook.id) {
      this.cookBook.items.map((item, i) => {
        this.cookBook.items[i].cookBookStock = item.cookBookStock['@id'];
      });
      this.http.put(`${CookBookModel.IRI}/${this.cookBook.id}`, this.cookBook).then(response => {
        this.addNewCook = false;
        this.load();
      });
    } else {
      this.cookBook.items.map((item, i) => {
        this.cookBook.items[i].cookBookStock = item.cookBookStock['@id'];
      });
      this.http.post(CookBookModel.IRI, this.cookBook).then(response => {
        this.addNewCook = false;
        this.load();
      });
    }
  }

  onCalculateTotalCost(): void {
    let grandTotal = 0;
    this.cookBook.items.map((item, i) => {
      // @ts-ignore
      grandTotal += item.cookBookStock.price * item.amount;
    });
    this.cookBook.totalCost = grandTotal;
  }

  removeItem(i): void {
    if (this.cookBook.items[i].id) {
      this.http.delete(`${CookBookItemModel.IRI}/${this.cookBook.items[i].id}`).then(() => {
        this.http.put(`${CookBookModel.IRI}/${this.cookBook.id}`, this.cookBook).then(response => {
        });
      });
    }
    this.cookBook.items.splice(i, 1);
    this.onCalculateTotalCost();
  }

  paginate(event): void {

    this.load(event);
  }

  addSector(): void {
    this.dialogService.open(CreateSectorComponent, {
      width: '50vw',
      header: this.translate.instant('SECTOR_CREATE')
    });
  }

  addCategory(type): void {
    this.dialogService.open(CreateCookBookCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: type,
    });
  }
  addStock(): void {
    this.dialogService.open(CreateCookBookStockComponent, {
      width: '50vw',
      header: this.translate.instant('STOCK_CREATE')
    });
  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
      this.imageChangedEvent = event;
    } else {
      console.error('No file selected or file is not valid.');
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    console.log('Cropped Image:', this.croppedImage);
  }

  imageLoaded(): void {
    // Show cropper
  }

  onloadImage(): void {
    // Post the cropped image
    this.http.post(UploadModel.IRI, { file: this.croppedImage }).toPromise().then(response => {
      this.cookBook.image = response.file;
      this.displayBasic = false;
    }).catch(error => {
      console.error('Error uploading image:', error);
    });
  }

  cropperReady(): void {
    // Cropper ready
  }

  loadImageFailed(): void {
    // Show message
  }

  zoomOut(): void {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn(): void {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  showBasicDialog(): void {
    this.displayBasic = true;
  }

}
