<div class="p-grid" [style]="{marginLeft: '4px'}">
  <div class="p-col-4">
    <p-card> <h3>Kullanıcılar</h3><br>
      <div class="p-grid" *ngFor="let user of users">
          <div class="p-col-12">
            <div class="p-col-2">
              <img src="{{baseUrl + '/images/chat-avatar.jpg'}}" alt="Image" width="250"/>
            </div>
            <div class="p-col-8"><b>{{user.firstName + ' ' + user.lastName}}</b></div>
            <div class="p-col-2"></div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col-8">Chat Area</div>
</div>

