<p-card>
<app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'CATEGORIES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'CATEGORIES'"></app-page-info>
      <p-button [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}" icon="pi pi-plus" (click)="openForm()"></p-button>
    </div>
  </div>

  <div class="p-grid" style="margin-top: 20px">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTabMenuItems"></p-tabMenu>
      <br>
      <p-treeTable [loading]="loading" [lazy]="true" [value]="data" *ngIf="!loading" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'5%'}"></th>
            <th [style]="{width:'15%',textAlign:'left'}">{{'CODE' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'40%',textAlign:'left'}">{{'NAME' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'15%',textAlign:'left'}">{{'CATEGORY_TYPE' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'15%',textAlign:'left'}">{{'ACCOUNT_CODE' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'15%',textAlign:'left'}" *ngIf="parameters.categoryType != 'product'">{{'CATEGORY_MATCHING' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode">
            <td class="p-text-left">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            </td>
            <td>{{rowData.code}}</td>
            <td><a href="javascript:void(0)" [style]="{color:'#0edcdc'}" (click)="edit(rowData)">{{rowData.name}}</a></td>
            <td>{{rowData.categoryType | translate }}</td>
            <td>{{rowData.accountingNumber }}</td>
            <td *ngIf="parameters.categoryType != 'product'">
              <button pButton pRipple type="button" label="{{'CATEGORY_MATCHING' | translate}}" class="p-button-secondary" (click)="matching(rowData)"></button>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="loadCategory(null)"></button>
        </div>
      </div>

    </div>
  </div>
  <div class="p-grid" style="margin-top: 20px">
    <div class="p-col-4">
      <label for="name" class="p-d-block">{{'SELECT_DEFAULT_CATEGORY' | translate}}</label>
      <p-dropdown id="name" appendTo="body" [options]="activeItems" [showClear]="true"
                  [(ngModel)]="company.defaultCategory" [placeholder]="'SELECT'|translate"
                  optionLabel="name" id="category" (onChange)="setIsDefault($event)"
                  optionValue="@id">
      </p-dropdown>

    </div>
  </div>
</p-card>

<p-confirmPopup></p-confirmPopup>
