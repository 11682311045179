import {CookBookItemModel} from "./cook-book-item.model";

export class CookBookModel {
  public static IRI = '/api/cook_books';
  id: number;
  name: string;
  prepTime: number;
  cookTime: number;
  image: string;
  cookingInstruction: string;
  items: CookBookItemModel[];
  totalCost: number;
  category: number;
  sector: number;
}
