import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {CategoryModel} from '../../../model/category.model';
import {ConfirmationService, MenuItem, TreeNode} from 'primeng/api';
import { TranslateService} from '@ngx-translate/core';
import {ValidationService} from '../../../service/validation.service';
import {Subscription} from 'rxjs';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CompanyModel} from '../../../model/company.model';
import {CategoryService} from '../../../service/category.service';
import {DialogService} from 'primeng/dynamicdialog';
import {CreateCategoryComponent} from './create-category/create-category.component';



@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [DialogService]
})
export class CategoryComponent implements OnInit, OnDestroy {
  items: CategoryModel[] = [];
  activeItems: CategoryModel[] = [];
  item: CategoryModel = new CategoryModel();
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean;
  errors = [];
  files: TreeNode[];
  data: TreeNode[];
  isOpen: boolean;
  parameters = {
    categoryType: 'product'
  };
  tabMenuItems: MenuItem[] = [];
  activeTabMenuItems: MenuItem;
  validationSubscriber: Subscription;
  type: string;
  moreLoading: boolean;
  matchesDialog: boolean;
  constructor(private http: HttpService, private confirmationService: ConfirmationService, private translate: TranslateService,
              private validationService: ValidationService, private flashMessageService: FlashMessageService,
              private categoryService: CategoryService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.tabMenuItems = [
      {
        label: this.translate.instant('MENU_ITEM_CATEGORIES'),
        title: 'MENU_ITEM_CATEGORIES',
        command: (event) => {
          this.activeTabMenuItems = this.tabMenuItems[0];
          this.parameters.categoryType = 'product';
          this.loadCategory(null).then();
        }
        },
      {label: this.translate.instant('STOCK_CATEGORIES'),
        title: 'STOCK_CATEGORIES',
        command: (event) => {
          this.activeTabMenuItems = this.tabMenuItems[1];
          this.parameters.categoryType = 'stock';
          this.loadCategory(null).then();
        }},
    ];
    this.activeTabMenuItems = this.tabMenuItems[0];
    this.loadCategory(null).then();
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }
  async loadCategory(id: number| null): Promise<void> {
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.categoryService.getFilesystem(this.parameters).then(files => this.files = files);
    this.data = this.loadTree(this.files);
    this.loading = false;
  }
  loadTree(treeData): any {
    const data = [];
    treeData.forEach((key, i) => {
      let d;
      const rg = d ? (d.children || (d.children = [])) : data;
      d = data.find(e => e.data.children === null) || (data[data.length] =
        { data: {
            code: key.code,
            name: key.name,
            isActive: key.isActive,
            id: key.id,
            parent: key.parent,
            categoryType: key.categoryType,
            accountingCode: key.accountingCode,
            categoryMatches: key.categoryMatches,
          },
          children: this.loadTree(key.children)
        });
    });
    return data;
  }

  openForm(item: CategoryModel = null): void{
    const type = this.parameters.categoryType;
    this.errors = [];
    const ref = this.dialogService.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: {item, type}
    });

    ref.onClose.subscribe(() => {
      this.loadCategory(null).then();
    });
    this.isOpen = true;
  }

  edit(row): void {
    console.log(row)
    this.item = JSON.parse(JSON.stringify(row));
    console.log(this.item)
    this.openForm(this.item);
  }

  changeStatus(row): void {
    this.http.put(`${CategoryModel.IRI}/${row.id}`, {isActive: row.isActive}).then((response) => {
      row.isActive = response.isActive;
    });
  }
  setIsDefault(row): void {
    this.http.put(`${CompanyModel.IRI}/${this.company.id}`, {defaultCategory: row.value}).then();
  }
  ngOnDestroy(): void {
    this.validationService.updateErrors(null);

    if (this.validationSubscriber) {
      this.validationSubscriber.unsubscribe();
    }

    this.categoryService.categories.next([]);
  }
}
