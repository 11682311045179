<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PURCHASE_REPORT' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <p-button icon="pi pi-question" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-arrow-down" [disabled]="parameters.location === '' " (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <p-tabMenu [model]="items" [activeItem]="activeItem" [style]="{overflowX: 'unset', marginBottom: '30px'}"></p-tabMenu>
  <div class="p-col-6" *ngIf="noSelected" [style]="{marginTop:'10px'}">
    <p-message severity="error" text="{{ 'REQUIRED_FIELDS' | translate}}"><br></p-message>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="(activeItem === items[1])">
      <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                      appendTo="body" autofocus [suggestions]="stocks" name="stock" placeholder="{{ 'SEARCH_ITEM'| translate }}"
                      (completeMethod)="searchStock($event)" (onSelect)="onVariantSelect($event);"
                      field="name"></p-autoComplete>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="activeItem === items[5]">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
               type="text" pInputText placeholder="{{'SEARCH_ITEM' | translate}}"
               [(ngModel)]="parameters.name" (keyDown.enter)="search();load()"  />
      </span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="activeItem === items[2]">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
               type="text" pInputText placeholder="{{'INVOICE_NUMBER' | translate}}"
               [(ngModel)]="parameters.invoiceId" (input)="changeInvoiceInput($event)" />
      </span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="searchContacts" [(ngModel)]="parameters.contact"
                  (onChange)="changeContact($event)"
                  placeholder="{{ 'SELECT_SUPPLIER' | translate}}"
                  optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="(activeItem === items[1] || activeItem === items[5]) && !detail">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="locations" [(ngModel)]="parameters.location" (onChange)="changeLocation($event)"
                  placeholder="{{ 'SELECT_LOCATION' | translate}} *({{'REQUIRED' | translate}})"
                  optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="(activeItem !== items[1] && activeItem !== items[5]) || detail">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="locations" [(ngModel)]="parameters.location" (onChange)="changeLocation($event)"
                  placeholder="{{ 'SELECT_LOCATION' | translate}}"
                  optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-calendar id="start" name="start"  selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onStartChange($event)"
                  placeholder="{{'SELECT_TRANSFER_DATE'| translate}} *({{'REQUIRED' | translate}})">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" [style]="{display: 'flex'}">
      <p-button [style]="{marginTop: '4px'}" [disabled]="!select" label="{{'SEARCH' | translate}}" type="submit" [icon]="tableLoading ? 'pi pi-spin pi-spinner' : 'pi pi-search'" (click)="search(); load()"></p-button>
    </div>
    <div class="p-col-3 p-md-3 p-lg-1" [style]="{textAlign: 'center'}" *ngIf="activeItem === items[1]">
      <p style="margin: auto; margin-bottom:1px">{{'DETAILED_LIST' | translate}}</p>
      <p-inputSwitch [(ngModel)]="detail" (onChange)="parameters.location ? select=true :select = false"></p-inputSwitch>
    </div>
  </div>
  <div class="card" *ngIf="activeItem === items[0] && searched" [style]="{overflow: 'auto', maxWidth:'100%'}">
    <p-table styleClass="p-datatable-striped" [loading]="loading" [value]="data[0]?.contact"
             dataKey="contactId" (onLazyLoad)="load($event)" [lazy]="true"
             columnResizeMode="expand" [style]="{width:'100%'}">
      <ng-template pTemplate="header">
        <tr style="line-height: 0px">
          <th [style]="{width:'5%'}"></th>
          <th [style]="{width:'40%'}" pSortableColumn="name"><p-sortIcon field="name"></p-sortIcon >{{'NAME' | translate}}
          </th>
          <th  [style]="{width:'15%', textAlign: 'center'}" pSortableColumn="discount"><p-sortIcon field="discount"></p-sortIcon >{{'DISCOUNT' | translate}}
          </th>
          <th  [style]="{width:'15%', textAlign: 'center'}" pSortableColumn="subTotal"><p-sortIcon field="subTotal"></p-sortIcon >{{'SUBTOTAL' | translate}}
          </th>
          <th  [style]="{width:'15%', textAlign: 'center'}" pSortableColumn="totalKDV"><p-sortIcon field="totalKDV"></p-sortIcon >{{'TOTAL' | translate}}
          </th>
          <th  [style]="{width:'10%', textAlign: 'center'}" pSortableColumn="totalKDV"><p-sortIcon field="totalKDV"></p-sortIcon >%{{'RATE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-expanded="expanded">
        <tr>
          <td class="mInvoice" ><button type="button" pButton pRipple [pRowToggler]="row" class="p-button-text p-button-rounded
            p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
          <td class="mInvoice" ><b>{{ row.name }}</b></td>
          <td class="mInvoice" [style]="{textAlign: 'right'}" >{{ row.discount | number: '1.1-2' }}{{row.currency}}</td>
          <td class="mInvoice" [style]="{textAlign: 'right'}" >{{ row.subTotal | number: '1.1-2' }}{{row.currency}}</td>
          <td class="mInvoice" [style]="{textAlign: 'right'}" >{{ row.totalKDV | number: '1.1-2' }}{{row.currency}}</td>
          <td class="mInvoice" [style]="{textAlign: 'right'}" >%{{(row.totalKDV / data[0]?.total[0].totalKDV) * 100 | number: '1.1-2'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td class="mInvoice" colspan="4"></td>
          <td colspan="1" [style]="{padding: '0 !important'}">{{'SUBTOTAL' | translate}}</td>
          <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].subTotal | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
        </tr>
        <tr>
          <td class="mInvoice" colspan="4"></td>
          <td colspan="1" [style]="{padding: '0 !important'}">{{'TAX' | translate}}</td>
          <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].tax | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
        </tr>
        <tr>
          <td class="mInvoice" colspan="4"></td>
          <td colspan="1" [style]="{padding: '0 !important'}">{{'DISCOUNT' | translate}}</td>
          <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].discount | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
        </tr>
        <tr>
          <td class="mInvoice" colspan="4"></td>
          <td colspan="1" [style]="{padding: '0 !important'}">{{'TOTAL' | translate}}</td>
          <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].totalKDV | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-row >
        <tr class="inventory-table">
          <td colspan="6" style="padding: 0">
          <p-table [loading]="tableLoading" responsiveLayout="stack"
                   [value]="row['subInvoice']"
                   styleClass="p-datatable-lg" dataKey="invoiceId">
            <ng-template pTemplate="header">
              <tr>
                <th>{{'CODE' | translate}}</th>
                <th>{{'DOCUMENT_NUMBER' | translate}}</th>
                <th>{{'DATE' | translate}}</th>
                <th>{{'SUBTOTAL' | translate}}</th>
                <th>{{'TAX_VALUE' | translate}}</th>
                <th>{{'DISCOUNT' | translate}}</th>
                <th>{{'TOTAL' | translate}}</th>
                <th>{{'LOCATION' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row >
              <tr (click)="showPopupDetails(row)" class="icon">
                <td >{{row.code}}</td>
                <td >{{row.documentNumber}}</td>
                <td >{{row.createdAt | date:'MM/dd/yyy'}}</td>
                <td [style]="{textAlign: 'right'}">{{row.subTotal | number: '1.1-2'}}{{row.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{row.tax | number: '1.1-2'}}{{row.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{row.discount | number: '1.1-2'}}{{row.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{row.total | number: '1.1-2'}}{{row.currency}}</td>
                <td >{{row.location}}</td>
              </tr>
            </ng-template>
          </p-table>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="card" *ngIf="activeItem === items[1]  && searched">
    <div style="overflow-x: auto;" *ngIf="detail">
      <p-table [loading]="tableLoading" responsiveLayout="stack"
               [value]="data[0]?.stocksInvoiceDetail" styleClass="p-datatable-lg"
               [first]="first" [rows]="parameters.itemsPerPage"
               [showCurrentPageReport]="true" (onPage)="handlePageChange($event)"
               [paginator]="true" [globalFilterFields]="['categoryName']">
        <ng-template pTemplate="header">
          <tr >
            <th class=" pad" [style]="{width: '6%'}" >{{'CODE' | translate}}</th>
            <th class=" pad" [style]="{width: '11%'}" >{{'INVOICE_NUMBER' | translate}}</th>
            <th class=" pad" [style]="{width: '8%'}" pSortableColumn="name"><p-sortIcon field="name"></p-sortIcon  >{{'NAME' | translate}}</th>
            <th class=" pad" [style]="{width: '6%'}" pSortableColumn="documentDate"><p-sortIcon field="documentDate"></p-sortIcon >{{'DATE' | translate}}</th>
            <th class=" pad" [style]="{width: '8%'}" pSortableColumn="contactName"><p-sortIcon field="contactName"></p-sortIcon >{{'CONTACT' | translate}}</th>
            <th class=" pad" [style]="{width: '8%'}" pSortableColumn="location"><p-sortIcon field="location"></p-sortIcon >{{'LOCATION' | translate}}</th>
            <th class=" pad" [style]="{width: '5%'}" pSortableColumn="mCatName"><p-sortIcon field="mCatName"></p-sortIcon >{{'MAIN_CATEGORY' | translate}}</th>
            <th class=" pad" [style]="{width: '7%'}" pSortableColumn="categoryName"><p-sortIcon field="categoryName"></p-sortIcon >{{'SUBCATEGORY' | translate}}</th>
            <th class=" pad" [style]="{width: '5%', textAlign: 'center'}" >{{'QUANTITY' | translate}} / {{'UNIT' | translate}}</th>
            <th class=" pad" [style]="{width: '7%', textAlign: 'center'}" >{{'UNIT_PRICE' | translate}}</th>
            <th class=" pad" [style]="{width: '3%', textAlign: 'center'}" >{{'TAX' | translate}}</th>
            <th class=" pad" [style]="{width: '7%', textAlign: 'center'}" pSortableColumn="subTotal"><p-sortIcon field="subTotal"></p-sortIcon >{{'SUBTOTAL' | translate}}</th>
            <th class=" pad" [style]="{width: '6%', textAlign: 'center'}" >{{'TAX_VALUE' | translate}}</th>
            <th class=" pad" [style]="{width: '6%', textAlign: 'center'}" pSortableColumn="discount"><p-sortIcon field="discount"></p-sortIcon >{{'DISCOUNT' | translate}}</th>
            <th class=" pad" [style]="{width: '7%', textAlign: 'center'}" pSortableColumn="total"><p-sortIcon field="total"></p-sortIcon >{{'TOTAL' | translate}}</th>
          </tr>
          <tr>
            <th colspan="2" [style]="{background: 'white !important', padding: '0'}"></th>
            <th [style]="{background: 'white !important', padding: '0'}">
              <p-columnFilter field="name" matchMode="equals" [showMenu]="false" [style]="{width: '100%'}">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="name"
                            (onChange)="$event.value === 'Tüm Ürünler' ? filter() : filter($event.value); extractContact($event.value);
                            extractDate($event.value);" placeholder="{{'ALL_ITEMS' | translate}}">
                  <ng-template let-option pTemplate="item">
                    <span>{{option}}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            </th>
            <th [style]="{background: 'white !important', padding: '0'}">
              <p-columnFilter field="documentDate" matchMode="equals" [showMenu]="false" [style]="{width: '100%'}">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value" [options]="date"
                              (onChange)="$event.value === 'Tüm Tarihler' ? filter() : filter($event.value);
                              extractNameDate($event.value)" placeholder="{{'ALL_DATES' | translate}}">
                    <ng-template let-option pTemplate="item">
                      <span>{{option}}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
            <th [style]="{background: 'white !important', padding: '0'}">
              <p-columnFilter field="contactName" matchMode="equals" [showMenu]="false" [style]="{width: '100%'}">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="fContactName"
                            (onChange)="$event.value === 'Tüm Tedarikçiler' ? filter() : filter($event.value);
                            extractName($event.value)" placeholder="{{'ALL_CONTACT' | translate}}">
                  <ng-template let-option pTemplate="item">
                    <span>{{option}}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            </th>
            <th [style]="{background: 'white !important', padding: '0'}">
              <p-columnFilter field="location" matchMode="equals" [showMenu]="false" [style]="{width: '100%'}">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value" [options]="locationName" (onChange)="filter($event.value)" placeholder="" [showClear]="true">
                    <ng-template let-option pTemplate="item">
                      <span>{{option}}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
            <th [style]="{background: 'white !important', padding: '0'}">
              <p-columnFilter field="mCatName" matchMode="equals" [showMenu]="false" [style]="{width: '100%'}">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value" [options]="mCategoryName"
                              (onChange)="$event.value === 'Tüm Kategoriler' ? filter() : filter($event.value);
                              extractCat($event.value)" placeholder="{{'ALL_CATEGORIES' | translate}}" >
                    <ng-template let-option pTemplate="item">
                      <span>{{option}}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
            <th [style]="{background: 'white !important', padding: '0'}">
              <p-columnFilter field="categoryName" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value" [options]="subCategoryName"
                              (onChange)="$event.value === 'Tüm Kategoriler' ? filter() : filter($event.value);
                              extractmCat($event.value)" placeholder="{{'ALL_CATEGORIES' | translate}}" >
                    <ng-template let-option pTemplate="item">
                      <span>{{option}}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
            <th colspan="8" [style]="{background: 'white !important', padding: '0'}"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr [style]="{lineHeight: '14px'}">
            <td class=" pad">{{row.code}}</td>
            <td class=" pad">{{row.documentNumber}}</td>
            <td class=" pad">{{row.name}}</td>
            <td class=" pad">{{row.documentDate | date:'MM/dd/yyy'}}</td>
            <td class=" pad info">{{row.contactName | slice:0:14 }}... <span class="tooltip">{{ row.contactName }}</span></td>
            <td class=" pad">{{row.location}}</td>
            <td class=" pad">{{row.mCatName}}</td>
            <td class=" pad">{{row.categoryName}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.quantity}} {{row.unit}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.price | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">%{{row.taxRate}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.subTotal | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.tax | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.discount | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.total | number: '1.1-2'}}{{row.currency}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td class="mInvoice" colspan="11"></td>
            <td colspan="2" [style]="{padding: '0 !important'}">{{'SUBTOTAL' | translate}}</td>
            <td colspan="2" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].subTotal | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="11"></td>
            <td colspan="2" [style]="{padding: '0 !important'}">{{'TAX' | translate}}</td>
            <td colspan="2" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].tax | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="11"></td>
            <td colspan="2" [style]="{padding: '0 !important'}">{{'DISCOUNT' | translate}}</td>
            <td colspan="2" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].discount | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="11"></td>
            <td colspan="2" [style]="{padding: '0 !important'}">{{'TOTAL' | translate}}</td>
            <td colspan="2" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].totalKDV | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div style="overflow-x: auto; width: 100%" *ngIf="!detail">
      <p-table [loading]="tableLoading" responsiveLayout="stack"
               [value]="data[0]?.stocksInvoice" styleClass="p-datatable-lg"
               [first]="first" [rows]="parameters.itemsPerPage" (onLazyLoad)="load($event)" [lazy]="true"
               [showCurrentPageReport]="true" (onPage)="handlePageChange($event)"
               [paginator]="true">
        <ng-template pTemplate="header">
          <tr >
            <th class=" pad" [style]="{width: '8%', textAlign: 'center'}" >{{'CODE' | translate}}</th>
            <th class=" pad" [style]="{width: '30%', textAlign: 'center'}" pSortableColumn="name"><p-sortIcon field="name"></p-sortIcon  >{{'NAME' | translate}}</th>
            <th class=" pad" [style]="{width: '14%', textAlign: 'center'}" >{{'QUANTITY' | translate}} / {{'UNIT' | translate}}</th>
            <th class=" pad" [style]="{width: '12%', textAlign: 'center'}" pSortableColumn="price"><p-sortIcon field="price"></p-sortIcon >{{'UNIT_PRICE' | translate}}</th>
            <th class=" pad" [style]="{width: '12%', textAlign: 'center'}" pSortableColumn="subTotal"><p-sortIcon field="subTotal"></p-sortIcon >{{'SUBTOTAL' | translate}}</th>
            <th class=" pad" [style]="{width: '12%', textAlign: 'center'}" pSortableColumn="discount"><p-sortIcon field="discount"></p-sortIcon >{{'DISCOUNT' | translate}}</th>
            <th class=" pad" [style]="{width: '12%', textAlign: 'center'}" pSortableColumn="total"><p-sortIcon field="total"></p-sortIcon >{{'TOTAL' | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr [style]="{lineHeight: '14px'}">
            <td class=" pad">{{row.code}}</td>
            <td class=" pad">{{row.name}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.quantity | number: '1.1'}} {{row.unit}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{(row.subTotal)/(row.quantity) | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.subTotal | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.discount | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.total | number: '1.1-2'}}{{row.currency}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td class="mInvoice" colspan="5"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'SUBTOTAL' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].subTotal | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="5"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'TAX' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].tax | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="5"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'DISCOUNT' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].discount | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="5"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'TOTAL' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].totalKDV | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="card" *ngIf="activeItem === items[2] && searched">
      <p-table [loading]="tableLoading" responsiveLayout="stack"
               [value]="data[0]?.invoice" styleClass="p-datatable-lg"
               [first]="first" [rows]="parameters.itemsPerPage" (onLazyLoad)="load($event)" [lazy]="true"
               [showCurrentPageReport]="true" (onPage)="handlePageChange($event)"
               [paginator]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="pad" [style]="{width: '8%'}" >{{'DOCUMENT_NUMBER' | translate}}</th>
            <th class=" pad" [style]="{width: '4%'}" >{{'CODE' | translate}}</th>
            <th class=" pad" [style]="{width: '5%'}" pSortableColumn="documentDate"><p-sortIcon field="documentDate"></p-sortIcon>{{'DATE' | translate}}</th>
            <th class=" pad" [style]="{width: '10%'}" pSortableColumn="contactName"><p-sortIcon field="contactName"></p-sortIcon >{{'CONTACT' | translate}}</th>
            <th class=" pad" [style]="{width: '8%'}" pSortableColumn="location"><p-sortIcon field="location"></p-sortIcon >{{'LOCATION' | translate}}</th>
            <th class=" pad" [style]="{width: '8%', textAlign: 'center'}" pSortableColumn="subTotal"><p-sortIcon field="subTotal"></p-sortIcon>{{'SUBTOTAL' | translate}}</th>
            <th class=" pad" [style]="{width: '8%', textAlign: 'center'}" pSortableColumn="tax"><p-sortIcon field="tax"></p-sortIcon>{{'TAX_VALUE' | translate}}</th>
            <th class=" pad" [style]="{width: '8%', textAlign: 'center'}" pSortableColumn="discount"><p-sortIcon field="discount"></p-sortIcon>{{'DISCOUNT' | translate}}</th>
            <th class=" pad" [style]="{width: '8%', textAlign: 'center'}" pSortableColumn="total"><p-sortIcon field="total"></p-sortIcon>{{'TOTAL' | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr (click)="showPopupDetails(row)" class="icon">
            <td class=" pad">{{row.documentNumber}}</td>
            <td class=" pad">{{row.code}}</td>
            <td class=" pad">{{row.documentDate | date:'MM/dd/yyy'}}</td>
            <td class=" info pad">{{row.contactName | slice:0:20 }}... <span class="tooltip">{{ row.contactName }}</span></td>
            <td class=" pad">{{row.location}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.subTotal | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.tax | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.discount | number: '1.1-2'}}{{row.currency}}</td>
            <td class=" pad" [style]="{textAlign: 'right'}">{{row.total | number: '1.1-2'}}{{row.currency}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td class="mInvoice" colspan="7"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'SUBTOTAL' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].subTotal | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="7"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'TAX' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].tax | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="7"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'DISCOUNT' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].discount | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
          <tr>
            <td class="mInvoice" colspan="7"></td>
            <td colspan="1" [style]="{padding: '0 !important'}">{{'TOTAL' | translate}}</td>
            <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.total[0].totalKDV | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  <div class="card" *ngIf="activeItem === items[5]  && searched">
      <div style="overflow-x: auto;">
        <p-table [loading]="tableLoading" responsiveLayout="stack"
                 [value]="data[0]?.abcAnalysis.stocks" styleClass="p-datatable-lg"
                 [first]="first" [rows]="30"
                 [showCurrentPageReport]="true" (onPage)="handlePageChange($event)"
                 [paginator]="true" [globalFilterFields]="['categoryName']">
          <ng-template pTemplate="header">
            <tr >
              <th class=" pad" [style]="{width: '20%'}" >{{'NAME' | translate}}</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >{{'QUANTITY' | translate}}</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >{{'UNIT' | translate}}</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >{{'SATINALMA' | translate}}</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >{{'SATINALMA' | translate}} (+)</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >%</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >% (+)</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >{{'UNIT_PRICE' | translate}}</th>
              <th class=" pad" [style]="{width: '10%', textAlign: 'center'}" >{{'Ort.Gün.S.Alım' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-i="index">
            <tr [style]="{lineHeight: '14px'}">
              <td class=" pad">{{row.name}}</td>
              <td class=" pad" [style]="{textAlign: 'left'}">{{row.quantity | number: '1.1-2'}}</td>
              <td class=" pad" [style]="{textAlign: 'left'}">{{row.unit}}</td>
              <td class=" pad" [style]="{textAlign: 'left'}">{{row.total | number: '1.1-2'}}{{row.currency}}</td>
              <td class=" pad" [style]="{textAlign: 'left'}">
                {{ getTotalSum(data[0]?.abcAnalysis.stocks, row.index) | number: '1.1-2' }}{{row.currency}}
              </td>
              <td class="pad" [style]="{ textAlign: 'left' }">
                {{row.rate | number: '1.1-2'}}
              </td>
              <td class=" pad" [style]="{textAlign: 'left'}">
                {{ getRateSum(data[0]?.abcAnalysis.stocks, row.index) | number: '1.1-2' }}
              </td>
              <td class=" pad" [style]="{textAlign: 'left'}">{{row.price | number: '1.1-2'}}{{row.currency}}</td>
              <td class=" pad" [style]="{textAlign: 'left'}">{{row.daysRate | number: '1.1-2'}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td class="mInvoice" colspan="7"></td>
              <td colspan="1" [style]="{padding: '0 !important'}">{{'TOTAL' | translate}}</td>
              <td colspan="1" class="mInvoice" [style]="{textAlign: 'right'}">{{ data[0]?.abcAnalysis.total.total | number: '1.1-2' }}{{data[0]?.total[0].currency}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </div>
</p-card>

<p-dialog [(visible)]="displayPopupDetails" [modal]="true" [style]="{ width: '80%'}">
  <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>

  <div id="pdf-content" [style]="{padding: '5% 5% 0 5%'}">

    <table style="width: 100%; padding: 5px">
      <tr>
        <td colspan="3"><b style="font-size: 20px;float: left">{{popupDetails?.name}}</b></td>
      </tr>
      <tr>
        <td style="width: 33%"></td>
        <td style="width: 33%"></td>
        <td style="width: 33%">
          <table>
            <tr>
              <td > <b>{{ 'DOCUMENT_DATE'| translate}}</b></td>
              <td style="width:1px">:</td>
              <td>{{popupDetails?.documentDate}}</td>
            </tr>
            <tr>
              <td ><b>{{ 'DOCUMENT_NUMBER'| translate}}</b> </td>
              <td style="width:1px">:</td>
              <td>{{popupDetails?.documentNumber}}</td>
            </tr>
            <tr>
              <td ><b> {{ 'INVOICE_NUMBER'| translate}}</b></td>
              <td style="width:1px">:</td>
              <td>{{popupDetails?.code}}</td>
            </tr>
            <tr>
              <td ><b> {{ 'LOCATION'| translate}}</b></td>
              <td style="width:1px">:</td>
              <td>{{popupDetails?.location}}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;display: flex">
          <p>{{popupDetails?.name}}</p>
        </td>
      </tr>      <tr>
        <td style="text-align: left;display: flex; line-height: 0">
          <p>{{popupDetails?.email}}</p>
        </td>
      </tr>      <tr>
        <td style="text-align: left;display: flex; line-height: 0">
          <p>{{popupDetails?.phone}}</p>
        </td>
      </tr>
    </table>

    <table style="margin-top: 35px">
      <thead>
        <tr >
          <th style="width: 8%; text-align: center">{{'CODE' | translate}}</th>
          <th style="width: 43%; text-align: center">{{'NAME' | translate}}</th>
          <th style="width: 2%; text-align: center">{{'UNIT' | translate}}</th>
          <th style="width: 2%; text-align: center">{{'QUANTITY' | translate}}</th>
          <th style="width: 5%; text-align: center">{{'UNIT_PRICE' | translate}}</th>
          <th style="width: 5%; text-align: center">{{'TAX' | translate}}</th>
          <th style="width: 9%; text-align: center">{{'SUBTOTAL' | translate}}</th>
          <th style="width: 7%; text-align: center">{{'TAX_VALUE' | translate}}</th>
          <th style="width: 7%; text-align: center">{{'DISCOUNT' | translate}}</th>
          <th style="width: 8%; text-align: center">{{'TOTAL' | translate}}</th>
        </tr>
      </thead>
      <tbody *ngFor="let row of popupDetails?.invoiceDetails" style="margin-bottom: 35px">
        <tr>
          <td style="font-size: 0.9rem" >{{row.code}}</td>
          <td style="font-size: 0.9rem">{{row.name}}</td>
          <td style="text-align: center;font-size: 0.9rem">{{row.unit}}</td>
          <td style="text-align: center;font-size: 0.9rem">{{row.quantity}}</td>
          <td style="text-align: right;font-size: 0.9rem">{{row.price | number: '1.1-2'}}{{row.currency}}</td>
          <td style="text-align: right;font-size: 0.9rem">%{{row.taxRate}}</td>
          <td style="text-align: right;font-size: 0.9rem">{{row.subTotal | number: '1.1-2'}}{{row.currency}}</td>
          <td style="text-align: right;font-size: 0.9rem">{{row.tax | number: '1.1-2'}}{{row.currency}}</td>
          <td style="text-align: right;font-size: 0.9rem">{{row.discount | number: '1.1-2'}}{{row.currency}}</td>
          <td style="text-align: right;font-size: 0.9rem">{{row.total | number: '1.1-2'}}{{row.currency}}</td>
        </tr>
      </tbody>
      <tfoot style="margin-top: 30px">
        <tr>
          <td colspan="7"></td>
          <td style="height:2rem"></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>{{'SUBTOTAL' | translate}}</td>
          <td style="text-align: right">{{popupDetails?.subTotal | number: '1.1-2'}}{{popupDetails?.currency}}</td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>{{'DISCOUNT' | translate}}</td>
          <td style="text-align: right">{{popupDetails?.discount | number: '1.1-2'}}{{popupDetails?.currency}}</td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>{{'TAX_VALUE' | translate}}</td>
          <td style="text-align: right">{{popupDetails?.tax | number: '1.1-2'}}{{popupDetails?.currency}}</td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>{{'TOTAL' | translate}}</td>
          <td style="text-align: right">{{popupDetails?.total | number: '1.1-2'}}{{popupDetails?.currency}}</td>
        </tr>

      </tfoot>
    </table>

  </div>
</p-dialog>


