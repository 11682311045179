<p-card>
  <app-loading-indicator [loading]="false"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'SALES_SUMMARY' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'SALES_SUMMARY'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">


    </div>
  </div>
</p-card>

