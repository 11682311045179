import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';

export class PackOptionModel {
  public static IRI = '/api/pack_options';
  public id: number;
  public stock: StockModel| string;
  public packVariant: StockVariantModel| string;
  public packStock: StockModel| string;
  public quantity: number;
  public finalQuantity: number;
}
