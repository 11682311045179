import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CategoryService} from '../../../service/category.service';
import {InventoryStockModel} from '../../../model/inventory-stock.model';
import {CurrencyService} from '../../../service/currency.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {LazyLoadEvent} from 'primeng/api';
import {StockModel} from '../../../model/stock.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {InventoryModel} from '../../../model/inventory.model';
import {InventoryItemModel} from '../../../model/inventory-item.model';


@Component({
  selector: 'app-inventory-stock',
  templateUrl: './inventory-stock.component.html',
  styleUrls: ['./inventory-stock.component.scss']
})
export class InventoryStockComponent implements OnInit {
  loading: boolean;
  category: any;
  location: any;
  items: InventoryStockModel[] = [];
  parameters = {
    location: '',
    category: '',
    'stockVariant.code': '',
    'stockVariant.name': '',
    stock: '',
    price: '',
    valueInStock: '',
    inventoryItem: '',
    itemsPerPage: 100000,
    pagination: false,
  };
  categoryId: any;
  currencyLabel: string;
  showDescription = false;
  showConsumptionValue = true;
  showConsumptionQuantity = true;
  showRatio = true;
  showPrice = true;
  showStockQuantity = true;
  showStockValue = true;
  showCode = false;
  showOrderItem2 = false;
  showOrderItem3 = false;
  stocks: StockModel[] = [];
  tableLoading: boolean;
  results: any [];
  sums: any [];
  actions: any;

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private categoryService: CategoryService,
              private currencyService: CurrencyService,
              private flashMessageService: FlashMessageService
  ) {
  }

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.paramMap.get('inventoryItem');
    this.http.get(`${InventoryModel.IRI}/inventorystocks/${this.route.snapshot.paramMap.get('category')}/${this.route.snapshot.paramMap.get('location')}/${this.route.snapshot.paramMap.get('inventoryItem')}`).subscribe((response: InventoryItemModel) => {
      if (response) {
        this.results = response.results;
        this.sums = response.sums;
        this.loading = false;

      }
    });
  }

  async load(event: LazyLoadEvent = null): Promise<void> {
    this.tableLoading = true;
    // @ts-ignore
    if (this.route.snapshot.paramMap.get('category') !== 0 ){
      this.parameters.category = this.route.snapshot.paramMap.get('category');
    }
    this.parameters.location = this.route.snapshot.paramMap.get('location');
    this.parameters.inventoryItem = this.route.snapshot.paramMap.get('inventoryItem');
    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get(InventoryStockModel.IRI, this.parameters).subscribe(response => {
      this.items = response['hydra:member'];
      this.items.map((item, i) => {
        this.items[i].countQuantity = item.countQuantity;
        this.items[i].unit1 = item.unit1;
        this.items[i].unit2 = item.unit2;
        this.items[i].unit2BaseQuantity = item.stock.purchaseOptions[0] ? item.stock.purchaseOptions[0].baseUnitQuantity : 0;
        this.items[i].unit3 = item.unit3;
        this.items[i].unit3BaseQuantity = item.stock.purchaseOptions[1] ? item.stock.purchaseOptions[1].baseUnitQuantity : 0;
        this.items[i].countValueInStock = item.countValueInStock ;
        this.items[i].consumptionQuantity = (item.quantity - item.countQuantity);
        this.items[i].consumptionValue = item.price * (item.quantity - item.countQuantity);
      });
      this.tableLoading = false;
    });
  }

  setQuantity(event, i, baseUnitQuantity = null): void {
    if (event !== null || event !== '') {
      const calculatedQuantity = (this.items[i].unit1) +
        (this.items[i].unit2 === undefined ? 0 : this.items[i].unit2 * this.items[i].unit2BaseQuantity) +
        (this.items[i].unit3 === undefined ? 0 : this.items[i].unit3 * this.items[i].unit3BaseQuantity);
      this.items[i].countQuantity =  calculatedQuantity;
      this.items[i].afterCountQuantity = event;
      this.items[i].countValueInStock = this.items[i].price * calculatedQuantity;
      this.items[i].consumptionQuantity = this.items[i].quantity - calculatedQuantity;
      this.items[i].consumptionValue = this.items[i].price * (this.items[i].quantity - calculatedQuantity);
      this.http.put(`${InventoryStockModel.IRI}/${this.items[i].id}`, {
        id: this.items[i].id,
        unit1: this.items[i].unit1,
        unit2: this.items[i].unit2,
        unit3: this.items[i].unit3,
        countQuantity: this.items[i].countQuantity,
        countValueInStock: this.items[i].price * this.items[i].countQuantity,
        description: null
      }).then(response => {
        console.log(event);
        this.sumValues();
      });
    }
  }

  getRatio(first: number, second: number): any {
    if (first) {
      const ratio = (first / second) * 100;
      return ratio.toFixed(2);
    } else {
      return 0;
    }
  }
  sumValues(type= null): number{
    let handleStock = 0;
    let realStock = 0;
    let consumption = 0;
    this.items.map(item => {

      handleStock += item.valueInStock;
      realStock += item.countValueInStock;
      consumption += item.consumptionValue;
    });
    if (type === 'handleStock'){
      return handleStock;
    }else if (type === 'realStock'){
      return realStock;
    }else if (type === 'consumption'){
      return consumption;
    }else if (type === 'ratio'){
      return Number(((consumption / handleStock) * 100).toFixed(2));
    }else{
      return 0;
    }
  }
  onChangeDescription(event, i, row): void{
    this.http.put(`${InventoryStockModel.IRI}/${this.items[i].id}`, {
      id: this.items[i].id,
      description: event.target.value
    }).then(response => {
    });
  }
  searchVariant = event => {
    this.http.get(StockModel.IRI, {name: event.query, type: 'material'}).subscribe((response: StockModel) => {
      this.stocks = response['hydra:member'];
    });
  }

  onVariantSelect(event): void {
    this.parameters.stock = event.id;
    this.load().then();
  }
  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].inventory.findIndex(obj => obj.name === text);
      return this.actions[0].inventory[findIndex].value ? this.actions[0].inventory[findIndex].value : false;
    }
  }
}
