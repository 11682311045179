<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form action="" *ngIf="!loading && !isConnected">
  <div class="p-fluid">
    <div class="p-field">
      <label for="x-api-key">{{ 'API-KEY'|translate }}</label>
      <input id="x-api-key" type="text" [name]="'api-key'" pInputText [(ngModel)]="attributes.apikey" autofocus>
    </div>
    <div class="p-field">
      <label for="x-api-secret">{{ 'API-SECRET'|translate }}</label>
      <input id="x-api-secret" type="text" [name]="'api-secret'" pInputText [(ngModel)]="attributes.apisecret" autofocus>
    </div>
    <div class="p-field">
      <label for="x-api-consumer">{{ 'MERCHANT-ID'|translate }}</label>
      <input id="x-api-consumer" type="text" [name]="'merchant_id'" pInputText [(ngModel)]="attributes.apiconsumer" autofocus>
    </div>
  </div>
  <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]="this.submitted" (click)="save()"></button>
</form>
<div *ngIf="!loading && isConnected">
  <img src="https://f4wbrznqapjb.merlincdn.net/wp-content/themes/paycell/assets/img/paycell_yeni_logo_v1-01.png" width="250px" alt="">
  <p-divider></p-divider>

  <h2 class="p-d-inline-block"><i class="pi pi-list" style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_CATEGORY'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="categoryLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="categoryLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="syncCategories()"></p-button>
  <p-divider></p-divider>

  <h2 class="p-d-inline-block"><i class="pi pi-list" style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_PRODUCT'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="categoryLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="categoryLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="syncProducts()"></p-button>
  <p-message severity="warn" *ngIf="queueDisplay"  class="p-d-block" [style]="{padding: '10px', marginBottom:'20px'}" text="{{'PRODUCT_IMPORT_REMAINING'| translate }}">
  </p-message>
  <p-divider></p-divider>

  <h2 class="p-d-inline-block"><i class="pi pi-shopping-cart"
                                  style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_SALES'|translate }}</h2>
  <div>
    <h3 class="p-d-inline-block"> {{ 'TODAY_SALES'|translate }}</h3>
    <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
              [disabled]="tSalesLoading" [style]="{'float': 'right' }"
              label="{{'UPDATE' | translate}}" [icon]="tSalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
              (click)="syncSales('T')"></p-button>
  </div>
  <div>
    <table [style]="{width:'100%'}">
      <tr>
        <td> <p-calendar  selectionMode="range" [readonlyInput]="true" inputId="range"
                          appendTo="body" placeholder="{{'SELECT_DATE_RANGE' | translate}}" dateFormat="dd-mm-yy"
                          (onClose)="onSelectRangeDate()" [(ngModel)]="parameters.date"
        ></p-calendar></td>
        <td><p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
                      [disabled]="dateRangeSalesLoading" [style]="{'float': 'right' }"
                      label="{{'UPDATE' | translate}}" [icon]="dateRangeSalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
                      (click)="syncSales('DR')"></p-button></td>
      </tr>
    </table>


  </div>
  <!--


  <div>
    <h3 class="p-d-inline-block"> {{ 'YESTERDAY_SALES'|translate }}</h3>
    <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
              [disabled]="ySalesLoading" [style]="{'float': 'right' }"
              label="{{'UPDATE' | translate}}" [icon]="ySalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
              (click)="syncSales('Y')"></p-button>
  </div>
  <div>
    <h3 class="p-d-inline-block"> {{ 'LAST_SEVEN_DAY'|translate }}</h3>
    <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
              [disabled]="l7dSalesLoading" [style]="{'float': 'right' }"
              label="{{'UPDATE' | translate}}" [icon]="l7dSalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
              (click)="syncSales('L7D')"></p-button>
  </div>
  <div>
    <h3 class="p-d-inline-block"> {{ 'THIS_MOUNT'|translate }}</h3>
    <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
              [disabled]="mSalesLoading" [style]="{'float': 'right' }"
              label="{{'UPDATE' | translate}}" [icon]="mSalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
              (click)="syncSales('M')"></p-button>
  </div>
  <div>
    <h3 class="p-d-inline-block"> {{ 'LAST_MOUNT'|translate }}</h3>
    <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
              [disabled]="lmSalesLoading" [style]="{'float': 'right' }"
              label="{{'UPDATE' | translate}}" [icon]="lmSalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
              (click)="syncSales('LM')"></p-button>
  </div>

  <div>
    <table [style]="{width:'100%'}">
      <tr>
        <td> <p-calendar [readonlyInput]="true" inputId="sdate"
                         appendTo="body" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd-mm-yy"
                         (onClose)="onSelectDate($event)" [(ngModel)]="startDateValue"
        ></p-calendar></td>
        <td>
          <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
                    [disabled]="dateSalesLoading" [style]="{'float': 'right' }"
                    label="{{'UPDATE' | translate}}" [icon]="dateSalesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
                    (click)="syncSales('SD')"></p-button></td>
      </tr>
    </table>


  </div>

  <p-divider></p-divider>
  -->
</div>
