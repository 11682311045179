import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {ModifierItemModel} from './modifier-item-model.model';

export class ModifierModel extends ApiResourceModel{
  public static IRI = '/api/modifiers';
  public name: string;
  public originalId: string;
  public location: LocationModel |string;
  public items: ModifierItemModel |string;
}
