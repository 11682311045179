<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div *ngIf="loading === false">
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-sm-6">
      <input
             [style]="{width: '200px',height:'45px',border:'none',borderBottom:'2px solid #00b4d2'}"
             id="name" type="text" placeholder="{{ 'NAME' | translate }}"
             pInputText [(ngModel)]="item.name" autofocus>
<!--
      <input *ngIf="nameEditable === true"
             [style]="{width: '200px',height:'45px',border:'none',borderBottom:'2px solid #00b4d2'}"
             id="name" type="text" placeholder="{{ 'NAME' | translate }}"
             pInputText [(ngModel)]="item.name" (blur)="!item.id ? saveStock() : null" autofocus>
      --->
      <small *ngIf="nameEditable === true" id="username1-help" [style]="{color:'#b9b9b9',marginTop:'5px'}"
             class="block">{{'SAMPLE_TEXT_INVENTORY_ITEM_NAME'| translate}}</small>
      <br>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-col-12 p-md-6 p-sm-6 ">
      <!---
      <button pButton [style]="{marginLeft: '5px', float:'right',background:'#ff2525'}"  class="p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" (click)="deleteItem(item)"></button>
              --->
      <app-page-info [pageName]="'ORDER_DETAIL'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'SAVE' | translate}}" class="p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" (click)="saveStock()"></button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
    </div>
  </div>
  <div class="p-col-12">
    <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTabMenuItem"></p-tabMenu>
  </div>
  <div *ngFor="let variant of item.variants" >
    <div class="p-grid" *ngIf="activeTabMenuItem.title === 'PURCHASING_INVENTORY' "  [style]="{width: '100%',background:'#f6f6f6',marginLeft:'-23px',marginRight:'-9px',padding:'1px 0 1px 20px'}">
      <div class="p-col-12">
        <h4><b>{{'BASIC_ITEM_DEFINITION' |translate}}</b></h4>
        <p *ngIf="infoTextShowDisplay">{{'BASIC_ITEM_DEFINITION_SUB' |translate}}</p>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4">
        <span>{{ 'CATEGORY' | translate}}</span>
        <div class="p-inputgroup">
          <span class="p-float-label">
            <p-dropdown [filter]="true" [placeholder]="'SELECT_CATEGORY'|translate"
                        class="p-inputtext-sm" appendTo="body"
                        (onChange)="onChangeCategory($event)" [options]="categories" [(ngModel)]="item.category"
                        optionLabel="name" id="category" optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['category'] }}</small>
          </span>
          <span class="p-inputgroup-addon" (click)="addCategory()"><i class="pi pi-plus"></i></span>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4">
        <span>{{ 'UNIT' | translate}}</span>
        <div class="p-inputgroup">
                  <span class="p-float-label">
                    <p-dropdown [style]="{ width: '100%'}" [filter]="true" [placeholder]="'SELECT_UNIT'|translate"
                                [options]="units" [(ngModel)]="item.unit" optionLabel="name"
                                class="p-inputtext-sm" appendTo="body"
                                optionValue="@id"></p-dropdown>
                    <small class="p-error">{{ errors['unit'] }}</small>
                  </span>
          <span class="p-inputgroup-addon" (click)="addUnit()"><i class="pi pi-plus"></i></span>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4">
        <span>{{ 'TAX' | translate}}</span>
        <div class="p-inputgroup">
                  <span class="p-float-label">
                    <p-dropdown [style]="{ width: '100%'}" [filter]="true" [placeholder]="'SELECT_TAX'|translate"
                                [options]="taxes" [(ngModel)]="item.tax" optionLabel="name"
                                class="p-inputtext-sm" appendTo="body"
                                optionValue="@id"></p-dropdown>
                    <small class="p-error">{{ errors['tax'] }}</small>
                  </span>
          <span class="p-inputgroup-addon" (click)="addTax()"><i class="pi pi-plus"></i></span>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-lg-4">
        <span>{{ 'CURRENCY' | translate}}</span>
        <p-dropdown [options]="currencies" [(ngModel)]="item.currency" (onChange)="changeCurrency($event)"
                    optionLabel="code" class="p-inputtext-sm" appendTo="body"
                    optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['currency'] }}</small>
      </div>

      <div class="p-col-12 p-md-6 p-lg-4">
        <span>{{ 'LOCATIONS' | translate}}</span>
        <p-multiSelect [style]="{width: '100%'}" [options]="locations" [(ngModel)]="item.locations"
                       appendTo="body" optionLabel="name" id="location" optionValue="@id">
        </p-multiSelect>
        <small class="p-error">{{ errors['locations'] }}</small>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4">
        <span>{{ 'SKU' | translate}}</span>
        <input type="text" [style]="{width: '100%',height:'36px'}" class="p-inputtext-sm" pInputText
               [(ngModel)]="variant.code">
        <small class="p-error">{{ errors['code'] }}</small>
      </div>
    </div>
  </div>
  <div class="p-grid" [style]="{width: '100%',background:'#f6f6f6',marginLeft:'-23px',marginRight:'-9px',padding:'1px 0 1px 20px'}">
    <div class="p-col-12">
      <!--- Purchasing Inventory --->
      <div *ngIf="activeTabMenuItem.title === 'PURCHASING_INVENTORY' ">
        <h4><b>{{'PURCHASE_OPTIONS'| translate}}</b></h4>
        <p *ngIf="infoTextShowDisplay">{{'PURCHASE_OPTIONS_SUB'| translate}}</p>
        <div class="p-grid" *ngIf="errors['purchaseOptions'] || errors['supplier']">
          <div class="p-col-12 alert-div" style="padding: 20px">
            <b>{{ 'ALERT' | translate }}!</b> <b>{{ errors['purchaseOptions'] }}</b> <b *ngIf="item.purchaseOptions.length > 0">{{ errors['supplier'] }}</b>
          </div>
        </div>
        <br>
        <div class="p-grid" [style]="{textAling:'center'}">
            <p-table [value]="item.purchaseOptions" dataKey="code">
              <ng-template pTemplate="header">
              <tr>
                <th [style]="{width:'5%'}" scope="row"></th>
                <th [style]="{width:'10%'}" scope="row"> {{'CODE' |translate}}</th>
                <th [style]="{width:'10%'}" scope="row"> {{'NAME' |translate}}</th>
                <th [style]="{width:'40%'}" scope="row"> {{'SUPPLIER' |translate}}</th>
                <th [style]="{width:'10%'}" scope="row"> {{'UNIT' |translate}}</th>
                <th [style]="{width: '10%'}" scope="row"> {{'PRICE' |translate}}</th>
                <th [style]="{width:'10%'}" scope="row"> {{'ACTIVE' |translate}}</th>
                <th [style]="{width:'10%'}" scope="row"> {{'MAIN_SUPPLIER' |translate}}</th>
                <th [style]="{width:'5%'}" scope="row"></th>
              </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row let-expanded="expanded" let-i="rowIndex">
                <tr>
                  <td>
                    <button type="button" pButton pRipple [pRowToggler]="row" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="row.code">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{row.code}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="row.name">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{row.name}}
                        <small class="p-error">{{ errors['name.' + i] }}</small>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div style="display: flex;flex-direction: row;">
                          <p-dropdown style="flex-grow: 8" [filter]="true"
                                      [placeholder]="'CONTACT_PLACEHOLDER'|translate"
                                      [options]="suppliers" [(ngModel)]="row.supplier" optionLabel="name"
                                      id="optionSupplier1" (onChange)="changeOptionContact($event, row.isMainContact)"
                                      optionValue="@id">
                          </p-dropdown>
                          <span class="p-inputgroup-addon" style="flex-grow: 1" (click)="addContact()"><i class="pi pi-plus"></i></span>
                        </div>

                      </ng-template>
                      <ng-template pTemplate="output">
                        <div *ngFor="let supplier of suppliers">
                          <span *ngIf="supplier['@id'] === row.supplier">{{supplier.name}}</span>
                        </div>
                        <small class="p-error">{{ errors['supplier.' + i] }}</small>

                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown [filter]="true" [placeholder]="'SELECT'|translate"
                                    (onChange)="changeUnit(row, i, 'unit', $event)" [options]="units" [(ngModel)]="row.unit"
                                    optionLabel="name" id="optionunit1" optionValue="@id">
                        </p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div *ngFor="let unit of units">
                          <span *ngIf="unit['@id'] === row.unit">{{unit.name}}</span>
                          <small class="p-error">{{ errors['purchaseOptions[' + i + '].unit'] }}</small>
                        </div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn [style]="{textAlign:'right'}">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText [(ngModel)]="row.price" type="number" min="0">
                      </ng-template>
                      <ng-template pTemplate="output" >
                        {{row.price | number: '1.2-2'}}
                        <br>
                        <small class="p-error">{{ errors['price.' + i] }}</small>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [style]="{textAlign:'center'}"><i class="pi pi-check" *ngIf="row.isActive" (click)="setActive(i)"
                         [style]="{color:'#55c416',fontSize:'16px',fontWeight:'bold', cursor:'pointer'}"></i>
                    <i class="pi pi-times" *ngIf="!row.isActive" (click)="setActive(i)"
                       [style]="{color:'#d10700',fontSize:'16px',fontWeight:'bold', cursor:'pointer'}"></i></td>
                  <td [style]="{textAlign:'center'}">
                    <i class="pi pi-check" *ngIf="row.isMainContact" (click)="setMainContact(i)"
                       [style]="{color:'#55c416',fontSize:'16px',fontWeight:'bold', cursor:'pointer'}"></i>
                    <i class="pi pi-times" *ngIf="!row.isMainContact" (click)="setMainContact(i)"
                       [style]="{color:'#d10700',fontSize:'16px',fontWeight:'bold' , cursor:'pointer'}"></i>
                  </td>
                  <td><button *ngIf="!row.id" pButton label="" icon="pi pi-trash"
                              class="p-button-outlined p-button-danger" (click)="removeItem(i, row.id)" iconPos="left"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-row let-i>
                <tr>
                  <td colspan="9">
                    <div class="p-grid" style="padding: 10px;">
                      <div class="p-col-12">
                        <h3><b>{{'TRANSFORMATION_BY_UNIT' | translate}}</b></h3>
                        <p *ngIf="infoTextShowDisplay">{{'TRANSFORMATION_BY_UNIT_DESCRIPTION' | translate}}</p>
                      </div>
                      <div class="p-col-12 success-div" [style]="{display:'flex'}" *ngIf="infoTextShowDisplay">
                       <b>{{'TRANSFORMATION_BY_UNIT_SAMPLE' | translate}}</b>
                      </div>
                      <div class="p-col-3">
                      <span>{{'BASE_UNIT_QUANTITY' | translate}}</span>
                      <input [style]="{ width: '100%',height:'40px'}" id="baseUnitQuantity1" type="number"
                             min="0.001" max="1" (change)="changeUnit(row, i, null)"
                             pInputText [(ngModel)]="row.baseUnitQuantity">
                      <small class="p-error">{{ errors['baseUnitQuantity'] }}</small>
                    </div>
                    <div class="p-col-2">
                      <span>{{'PACK_NICK_NAME' | translate}}</span>
                      <input [style]="{ width: '100%',height:'40px'}" id="caseName1" (change)="changeUnit(row, i, null)"
                             type="text" pInputText [(ngModel)]="row.packName">
                      <small class="p-error">{{ errors['caseName'] }}</small>
                    </div>
                    <div class="p-col-2">
                      <span>{{'PACK_PER_CASE' | translate}}</span>
                      <input [style]="{ width: '100%',height:'40px'}" id="caseUnit1"
                             (change)="changePackPerCase(row, i, null)"
                             type="number" pInputText [(ngModel)]="row.packPerCase">
                      <small class="p-error">{{ errors['casePerUnit'] }}</small>
                    </div>
                    <div class="p-col-12" [style]="{display:'flex'}" *ngIf="row.packName">
                      1  {{ row.unitName }} "<b>{{ row.packName }}</b>" içerisinde :
                      {{ row.packPerCase === null ? row.baseUnitQuantity : '' }} {{ row.packPerCase !== null ? row.packPerCase : '' }}
                      <div *ngFor="let un of units"> {{ un['@id'] === item.unit ? un.name : '' }} </div>
                    </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        <div class="col-md-12 p-mt-4" *ngIf="item.variants[0].code !== undefined">
          <p-button label="{{ 'ADD_PURCHASE_OPTION' | translate }}" icon="pi pi-plus"
                    [style]="{background: 'none',border: 'none', color: '#01a4bf', cursor: 'pointer'}"
                    (click)="addItem()"></p-button>
        </div>
        <hr>
        <div class="p-grid" *ngIf="item.type === 'material'">
          <div class="p-col-4">
            <h4><b>{{'COUNTING_AND_INVENTORY_MANAGEMENT' |translate}}</b></h4>

            <div class="p-field-8">
              <div class="p-field-12">
                <div *ngFor="let unit of units">
                  <p-checkbox *ngIf="unit['@id'] === item.unit" name="orderItem" value="{{ item.orderItem }}"
                              [binary]="true" label="{{ unit.name }}" [(ngModel)]="item.orderItem"></p-checkbox>
                </div>
              </div>
              <div *ngFor="let countsItem of item.purchaseOptions">
                <div class="p-field-12" *ngIf="countsItem.isCount === true">
                  <p-checkbox name="orderItem" value="{{ countsItem.isCount }}" [binary]="true"
                              [(ngModel)]="countsItem.isCount">
                    {{
                    countsItem.packName ? countsItem.packName : findUnitName(countsItem.unit)
                    }}
                  </p-checkbox>
                </div>
              </div>
            </div>
            <div class="p-field-12" *ngIf="infoTextShowDisplay">
              <p><b>{{ 'COUNTING_AND_INVENTORY_MANAGEMENT'| translate}}</b>: {{ 'COUNTING_AND_INVENTORY_MANAGEMENT_DESCRIPTION'| translate}}</p>
            </div>
          </div>
          <div class="p-col-4">
            <h4><b>{{'STATUS_ITEM_DEFINITION' |translate}}</b></h4>
            <div class="p-field-6">
              <label>{{ 'IS_BUYING'| translate}}</label><br>
              <p-inputSwitch [ngModel]="item.isBuying" (onChange)="isBuyingFunction($event)"></p-inputSwitch>
            </div>

            <div class="p-field-6">
              <label>{{ 'ACTIVE'| translate}} / {{ 'PASSIVE'| translate}}</label><br>
              <p-inputSwitch [(ngModel)]="item.isActive" (onChange)="item.isActive ? 0: 1 ; changeStatus(item.id, $event)"></p-inputSwitch>
            </div>
            <div class="p-field-12" *ngIf="infoTextShowDisplay">
              <p><b>{{ 'IS_BUYING'| translate}}:</b> {{ 'IS_BUYING_DESCRIPTION'| translate}}</p>
            </div>
            <div class="p-field-12" *ngIf="infoTextShowDisplay">
              <p><b>{{ 'ACTIVE'| translate}}</b>: {{ 'ACTIVE_STOCK_DESCRIPTION'| translate}}</p>
            </div>

          </div>
          <div class="p-col-4">
            <h4><b>{{'ADVANCED_ITEM_DEFINITION' |translate}}</b></h4>
            <div class="p-field-6">
              <label>{{ 'MIN_ORDER'| translate}}</label>
              <p-inputNumber name="orderItem" [(ngModel)]="item.variants[0].minOrder"
                             [minFractionDigits]="2" [maxFractionDigits]="5"
              ></p-inputNumber>
            </div>
            <div class="p-field-6">
              <label>{{ 'ALERT_LEVEL'| translate}}</label>
              <p-inputNumber name="orderItem" [(ngModel)]="item.variants[0].alertLevel" [minFractionDigits]="2" [maxFractionDigits]="5"></p-inputNumber>
            </div>
            <div class="p-field-12" *ngIf="infoTextShowDisplay">
              <p><b>{{ 'MIN_ORDER'| translate}}:</b> {{ 'MIN_ORDER_DESCRIPTION'| translate}}</p>
            </div>
            <div class="p-field-12" *ngIf="infoTextShowDisplay">
              <p><b>{{ 'ALERT_LEVEL'| translate}}</b>: {{ 'ALERT_LEVEL_DESCRIPTION'| translate}}</p>
            </div>
          </div>
        </div>
        <div class="p-grid" *ngIf="isProducer">
          <div class="p-col-12">
            <h4><b>{{'PRODUCE_ITEM_DEFINITION' |translate}}</b></h4>
            <div class="p-field-2">
              <label>{{ 'IS_MAKING'| translate}}</label><br>
              <p-inputSwitch [ngModel]="item.isMaking" (onChange)="isMakingFunction($event)"></p-inputSwitch>
            </div>
            <div class="p-field-2">
              <label>{{ 'TRACK_BY_LOT'| translate}}</label><br>
              <p-inputSwitch [ngModel]="item.isTrackByLot" (onChange)="isTrackLotFunction($event)"></p-inputSwitch>
            </div>
            <div class="p-field-4" *ngIf="item.isMaking">
              <label>{{ 'SALE_PRICE'| translate}}</label>
              <p-inputNumber name="orderItem" [(ngModel)]="item.variants[0].salePrice"></p-inputNumber>
            </div>

          </div>
        </div>
        <div>
        </div>
      </div>

      <!--- in recipes -->
      <div *ngIf="activeTabMenuItem.title === 'IN_RECIPES' && item.id">

        <div class="p-field-12">
          <h4><b>{{'IN_RECIPES' |translate}}</b></h4>
        </div>
        <p-table [value]="recipe" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width:'3%'}"></th>
              <th scope="col">{{'NAME'| translate}}</th>
              <th scope="col" [style]="{width:'15%'}">{{'COST'| translate}}</th>
              <th scope="col" [style]="{width:'15%'}">{{'QUANTITY'| translate}}</th>
              <th scope="col" [style]="{width:'10%'}"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-i="rowIndex">
            <tr>
              <td>{{i + 1}}</td>
              <td>{{ row.name }}/{{row.vrName}}</td>
              <td [style]="{textAlign:'right'}">{{ row.cost | number : '1.2-4' }}{{row.syhshah}} </td>
              <td [style]="{textAlign:'right'}">{{ row.amountWithWastage | number : '1.1-3'}} {{row.unit}} </td>
              <td [style]="{textAlign:'center', display: 'none'}"><p-button label="{{'DELETE' | translate}}" icon="pi pi-delete" (click)="deleteRecipe(row.recipeId)"></p-button></td>

            </tr>
          </ng-template>
        </p-table>
        <p-button [style]="{marginTop: '10px', float:'right', display: 'none'}" label="{{'CHANGE_CONTENT' | translate}}" (click)="openRecipeChange()"></p-button>
      </div>
      <!--Allergens -->
      <div *ngIf="activeTabMenuItem.title === 'ALLERGENS' ">
        <h4><b>{{'ALLERGENS' |translate}}</b></h4>
        <p-table [value]="item.allergen" class="p-datatable-striped" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width: '40%'}">{{ 'NAME'| translate}}</th>
              <th scope="col">{{'NO_INFO' | translate}}</th>
              <th scope="col">{{'CONTAINS' | translate}}</th>
              <th scope="col">{{'MAY_CONTAIN' | translate}}</th>
              <th scope="col">{{'DOES_NOT_CONTAIN' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-i="rowIndex">
            <tr>
              <td>{{ row.name | translate }} </td>
              <td>
                <p-checkbox [(ngModel)]="row.noInfo"
                            (onChange)="change($event, i, noInfo = true,contains= false,mayContain=false,doesNotContain= false)"
                            binary="true"></p-checkbox>
              </td>
              <td>
                <p-checkbox [(ngModel)]="row.contains"
                            (onChange)="change($event, i, noInfo = false,contains= true,mayContain=false,doesNotContain= false)"
                            binary="true"></p-checkbox>
              </td>
              <td>
                <p-checkbox [(ngModel)]="row.mayContain"
                            (onChange)="change($event, i, noInfo = false,contains= false,mayContain=true,doesNotContain= false)"
                            binary="true"></p-checkbox>
              </td>
              <td>
                <p-checkbox [(ngModel)]="row.doesNotContain"
                            (onChange)="change($event, i, noInfo = false,contains= false,mayContain=false,doesNotContain= true)"
                            binary="true"></p-checkbox>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- Stock Movements  -->
      <div *ngIf="activeTabMenuItem.title === 'STOCK_MOVEMENTS' ">
        <div class="p-grid p-mt-3 p-mb-3" [style]="{background:'#fff'}">
          <div class="p-col-12">
          <h4><b>{{'STOCK_MOVEMENTS' |translate}}</b></h4>
          </div>
          <div class="p-col-12 p-md-6 p-lg-3">
            <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
              <p-calendar (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}"
                          appendTo="body"
                          (onClose)="load(null, 'search')" selectionMode="range" [(ngModel)]="parameters.createdAt"
                          name="createdAt" dateFormat="yy-mm-dd">
              </p-calendar>
              <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
            </div>
          </div>
          <div class="p-col-12 p-md-6 p-lg-3">
            <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                        inputId="dropdown" placeholder="{{ 'SELECT_LOCATION' | translate}}"
                        (onChange)="stockMovingParameters.location = $event.value; loadStockChanges()"
                        appendTo="body"
                        [options]="locations" name="movementLocation" [(ngModel)]="stockMovingParameters.location"
                        optionLabel="name"
                        optionValue="id"></p-dropdown>

          </div>
          <div class="p-col-12 p-md-6 p-lg-3">
            <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                        inputId="dropdown"
                        (onChange)="stockMovingParameters.relatedType = $event.value; loadStockChanges()"
                        appendTo="body" [options]="stockMovementTypes" name="materialStatus"
                        [(ngModel)]="stockMovingParameters.relatedType" optionLabel="name"
                        optionValue="value"></p-dropdown>

          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <p-button label="{{'SEARCH' | translate}}"
                      [icon]="stockTransactionLoad ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                      (click)="loadStockChanges()"></p-button>
          </div>
        </div>
        <div class="tableDiv-content">
          {{ stockMovementTotal}}
          <p-table [value]="stockTransactions" [tableStyle]="{'min-width': '50rem'}" [loading]="stockTransactionLoad"
                   [paginator]="true" [rows]="20" [totalRecords]="stockMovementTotal" [lazy]="true" (onLazyLoad)="loadStockChanges($event)"
          >
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" [style]="{width:'15%'}"> {{'DATE' | translate}}</th>
                <th scope="col" [style]="{width:'8%'}"> {{'CODE' | translate}}</th>
                <th scope="col" [style]="{width:'15%'}"> {{'ACTION'| translate}}</th>
                <th scope="col" [style]="{width:'10%'}"> {{'QUANTITY' | translate}}</th>
                <th scope="col" [style]="{width:'10%'}"> {{'PRICE' | translate}}</th>
                <th scope="col" [style]="{width:'10%'}"> {{'VALUE' | translate}}</th>
                <th scope="col" [style]="{width:'10%'}"> {{'STOCK_QUANTITY' | translate}}</th>
                <th scope="col" [style]="{width:'10%'}"> {{'VALUE' | translate}}</th>
                <th scope="col" [style]="{width:'10%'}"> {{'AVERAGE_PRICE_DESCRIPTION' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr (click)="openTransactionDetail(item, item.code)">
                <td> {{ item.createdAt | date: 'Y-M-dd H:M:s' }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.type | translate }}</td>
                <td [style]="{textAlign:'right'}"><span *ngIf="item.type !== 'PURCHASE'
                && item.type !== 'ADD_STOCK_TRANSFER'
                && item.type !== 'INVENTORY_STOCK_TRANSFER'
                && item.type !== 'WORKORDER'
                && item.type !== 'WORKORDER_PACKED_POSITIVE'
                ">-</span>
                  {{ item.quantity | number: '1.2-2'}}</td>
                <td [style]="{textAlign:'right'}"> {{ item.unitPrice | number: '1.2-2'}}</td>
                <td [style]="{textAlign:'right'}">
                  <span *ngIf="item.type !== 'PURCHASE'
                  && item.type !== 'ADD_STOCK_TRANSFER'
                  && item.type !== 'INVENTORY_STOCK_TRANSFER'
                  && item.type !== 'WORKORDER'
                  && item.type !== 'WORKORDER_PACKED_POSITIVE'
                  ">-</span>
                  {{ item.valueInStock | number: '1.2-2'}}</td>
                <td [style]="{textAlign:'right'}">{{ item.quantityAfter | number: '1.2-2'}}</td>
                <td [style]="{textAlign:'right'}">{{ item.quantityAfter * item.unitPrice | number: '1.2-2'}}</td>
                <td [style]="{textAlign:'right'}"> {{ item.averageCost | number: '1.2-2'}}</td>
              </tr>
            </ng-template>
          </p-table>

        </div>
      </div>

      <!--- Pack Option--->
      <div *ngIf="activeTabMenuItem.title === 'PACK_OPTIONS' ">
        <h4><b>{{'PACK_OPTIONS' |translate}}</b></h4>
      </div>
      <!--- Pack Option End--->
    </div>
  </div>
</div>


<p-sidebar [(visible)]="transactionDetailShow" position="right" [style]="{width: '50vw'}">
  <h3>{{ 'INFORMATION' | translate}}</h3>
  <div class="p-grip p-mt-3" *ngIf="shipment.length > 0 ">
    <div *ngFor="let item of shipment">
      <div *ngIf="item.invoice.code === selectedCode">
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'CODE' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.code }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'TYPE' | translate }} </b></div>
          <div class="p-col-9">: {{ 'PURCHASE' | translate }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'DATE' | translate }} </b></div>
          <div class="p-col-9">: {{ item.documentDate | date: 'Y-M-dd H:M:s' }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'LOCATION' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.location.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'SUPPLIER' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.contact.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'CREATED_USER' | translate }} </b></div>
          <div class="p-col-9">: {{ item.createdBy.firstName}} {{ item.createdBy.lastName}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'DESCRIPTION' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.description}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grip p-mt-3" *ngIf="transfer.length > 0 ">
    <div *ngFor="let item of transfer">
      <div *ngIf="item.code === selectedCode">
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CODE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.code }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TYPE' | translate }} </b></div>
          <div class="p-col-10">: {{ 'STOCK_TRANSFERS' | translate }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'DATE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.transferDate | date: 'Y-M-dd H:M:s' }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TRANSFER_ORIGIN' | translate }} </b></div>
          <div class="p-col-10">: {{ item.origin.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TRANSFER_DESTINATION' | translate }} </b></div>
          <div class="p-col-10">: {{ item.destination.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CREATED_USER' | translate }} </b></div>
          <div class="p-col-10">: {{ item.createdBy.firstName}} {{ item.createdBy.lastName}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grip p-mt-3" *ngIf="waste.length > 0 ">
    <div *ngFor="let item of waste">
      <div *ngIf="item.code === selectedCode">
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CODE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.code }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TYPE' | translate }} </b></div>
          <div class="p-col-10">: {{ 'WASTE_EVENTS' | translate }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'DATE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.date | date: 'Y-M-dd H:M:s'}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'DESCRIPTION' | translate }} </b></div>
          <div class="p-col-10">: {{ item.description | translate}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'LOCATION' | translate }} </b></div>
          <div class="p-col-10">: {{ item.location.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CREATED_USER' | translate }} </b></div>
          <div class="p-col-10">: {{ item.createdBy.firstName}} {{ item.createdBy.lastName}}</div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-dialog [(visible)]="staticsDisplay">
  <h4>{{ stockName }}</h4>
  <p-dropdown [options]="locations" appendTo="body" placeholder="{{ 'SELECT_LOCATION' | translate}}" optionLabel="name"
              optionValue="id" (onChange)="getChartOptions($event)">
  </p-dropdown>
  <br>
  <p-chart type="bar" [data]="data" [options]="chartOptions" width="700px" height="400px"></p-chart>
</p-dialog>
<p-confirmDialog #cd [style]="{width: '50vw'}">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO' | translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

<p-dialog [(visible)]="statusChangeDisplay" *ngIf="statusChangeDisplay" [style]="{width: '50vw'}">
  <div class="p-grid">
    <div class="p-col-12 alert-div" >
      <b>{{ 'ALERT' | translate }}!</b>  {{ 'NOT_CHANGE_STATUS' | translate}}
    </div>
    <div class="p-col-12">
      <p>1-{{ 'USE_IN_RECIPE' | translate }}</p>
      <ul class="recipe" *ngFor="let recipeItem of recipe">
        <li>
          <span class="badge">{{ recipeItem.name }}/{{recipeItem.vrName}}</span>
        </li>
      </ul>

    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="recipeChangeDialog" *ngIf="recipeChangeDialog" [style]="{width: '25vw'}">

      <div class="p-inputgroup">
          <span class="p-float-label">
            <p-dropdown [filter]="true" [placeholder]="'SELECT_INVENTORY'|translate"
                        class="p-inputtext-sm" appendTo="body"
                        (onChange)="onChangeRecipeItem($event)" [options]="products"
                        optionLabel="stock.name" optionValue="id"></p-dropdown>
          </span>
      </div>
      <p-button [style]="{marginTop: '10px', float: 'right'}" [label]="'CHANGE_CONTENT'|translate" (click)="onChangeRecipe()"></p-button>

</p-dialog>
