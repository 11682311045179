import {Component, Input, OnInit} from '@angular/core';
import {ContactModel, ContactType} from '../../../model/contact.model';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ContactService} from '../../../service/contact.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CategoryModel} from '../../../model/category.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {HttpService} from '../../../service/http.service';
import {CategoryService} from '../../../service/category.service';
import {DialogService} from 'primeng/dynamicdialog';
import {CreateCategoryComponent} from '../../settings/category/create-category/create-category.component';
import {TranslateService} from '@ngx-translate/core';
import {CreateTaxComponent} from '../../settings/tax/create-tax/create-tax.component';
import {TaxService} from '../../../service/tax.service';
import 'primeflex/primeflex.css';

@Component({
  selector: 'app-contact-create-dialog',
  templateUrl: './contact-create-dialog.component.html',
  styleUrls: ['./contact-create-dialog.component.scss'],
  providers: [DialogService]
})
export class ContactCreateDialogComponent implements OnInit {

  item: ContactModel = new ContactModel();
  @Input() isDialog = true;
  submitted: boolean;
  errors = [];
  contactType: ContactType;
  contactTypes: [{ name: any; value: any }, { name: any, value: any }];
  categories: CategoryModel[] = [];
  taxes: TaxRateModel[] = [];
  daysCheckBox = false;
  companyIntegration: CompanyIntegrationModel[] = [];
  loading: boolean;
  activeIndex = 0;


  constructor(private validationService: ValidationService,
              private contactService: ContactService,
              private flashMessageService: FlashMessageService,
              private http: HttpService,
              private translate: TranslateService,
              private dialog: DialogService,
              private categoryService: CategoryService,
              private taxService: TaxService,
              private config: DynamicDialogConfig, private ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.contactTypes = [
      {name: 'Tedarikçi', value: 'supplier'},
      {name: 'Müşteri', value: 'customer'},
    ];

    if (this.config.data?.item) {
      this.item = this.config.data?.item;
    }
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    if(!this.item.id){
      this.item.type = this.contactTypes[0].value;
    }
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.companyIntegration = response['hydra:member'];
      this.loading = false;
    });

    this.loading = false;
  }

  async save(): Promise<void> {
    this.submitted = true;

    if (this.item.address) {
      this.item.address = this.item.address['@id'];
    }

    if (this.item.id) {
      await this.contactService.update(this.item).then(response => {
        if (response !== undefined) {
          if (this.isDialog) {
            this.ref.close();
          }
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          this.contactService.getContactsAction();
        }
      });
    } else {
      await this.contactService.save(this.item).then(response => {
        if (response !== undefined) {
          if (this.isDialog) {
            this.ref.close();
          }
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          this.contactService.getContactsAction();
        }
      });
    }

    this.submitted = false;
  }

  onChangePaymentTerms(event): void {
    if (event.value !== 'cash') {
      this.daysCheckBox = true;
    } else {
      this.daysCheckBox = false;
    }
  }
}
