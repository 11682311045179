<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'DAILY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'UNPAID_REPORT'"></app-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id"
        [filter]="true" filterBy="name" placeholder="{{ 'SELECT_LOCATION' | translate }}">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar dateFormat="yy-mm-dd" [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="date" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <app-report-tabs pageName="unpaid-report"></app-report-tabs>
  <app-unpaid-tab page=""></app-unpaid-tab>
</p-card>

