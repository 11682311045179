import {ApiResourceModel} from './api-resource.model';
import {IntegrationModel} from './integration.model';

export class IntegrationLog  extends ApiResourceModel{
  public static IRI = '/api/integration_logs';
  public integration: IntegrationModel [];
  public status: boolean;
  public response: string;
  public url: string;
  public isShow: boolean;
}
