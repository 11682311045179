import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookBookCategoryModel} from '../../../model/cook-book-category.model';
import {CookBookStockModel} from '../../../model/cook-book-stock.model';
import {LazyLoadEvent} from 'primeng/api';
import {environment} from '../../../../environments/environment';
import {CookBookModel} from '../../../model/cook-book.model';
import {HttpService} from '../../../service/http.service';
import {FileUpload} from 'primeng/fileupload';
import {StockMediaObjectModel} from '../../../model/stock-media-object.model';

@Component({
  selector: 'app-cook-book-stocks',
  templateUrl: './cook-book-stocks.component.html',
  styleUrls: ['./cook-book-stocks.component.scss']
})
export class CookBookStocksComponent implements OnInit {
  loading: boolean;
  cookBookStocks: CookBookStockModel[] = [];
  parameters = {
    id: '',
    name: '',
    code: '',
    pagination: true,
    category: '',
    sector: '',
    itemsPerPage: 20,
    page: 1,
  };
  total: number;
  response: any;
  entity: string;

  constructor(private translate: TranslateService, private http: HttpService) { }

  ngOnInit(): void {
  }
  load(event: LazyLoadEvent = null): void {
    this.loading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(CookBookStockModel.IRI, this.parameters).subscribe(response => {
      this.cookBookStocks = response['hydra:member'];
      this.total = response['hydra:totalItems'];
      this.loading = false;
    });
  }

  changePrice(row, event): void {
    console.log(row, event);
  }

  async upload(event, entity: string, fileUpload: FileUpload): Promise<void> {
    this.response = {};

    this.entity = entity;
    this.loading = true;

    for (const file of event.files) {
      const fd = new FormData();
      fd.append('file', file);

      await this.http.post(`/api/data/import/${entity}`, fd).then((response: StockMediaObjectModel) => {
        this.response = response;
      }).catch(error => {
      });
    }

    fileUpload.clear();

    this.loading = false;
  }

}
