import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {HttpService} from '../../../service/http.service';

@Component({
  selector: 'app-initial-setup',
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss']
})
export class InitialSetupComponent implements OnInit {
  items: MenuItem[];
  constructor(private translate: TranslateService, private router: Router, private http: HttpService) { }

  ngOnInit(): void {
  }
  nextPage(): void {

      this.router.navigate(['initial-setup/company-information']).then(r => {
      });

  }
  installDemoData(): void {
    this.http.get('/api/registration/import-sample-data').subscribe(response => {
      if (response.status === true){
        window.location.href = '/';
      }
    });
  }

}
