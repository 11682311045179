import {Component, OnInit, AfterViewInit} from '@angular/core';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {StockModel} from '../../../model/stock.model';
import {CurrencyModel} from '../../../model/currency.model';
import {UserModel} from '../../../model/user.model';
import {HttpService} from '../../../service/http.service';
import {LazyLoadEvent} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../service/utility.service';
import {CurrencyService} from '../../../service/currency.service';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {CategoryService} from '../../../service/category.service';
import {ContactService} from '../../../service/contact.service';
import {LocationModel} from '../../../model/location.model';
import {ActivatedRoute, Router, Route} from '@angular/router';
import {QueueModel} from '../../../model/queue.model';
import {ProductRecipeModel} from '../../../model/product-recipe.model';
import {PrintTemplateModel} from '../../../model/print-template.model';
import {NotificationService} from '../../../service/notification.service';
import {LocationService} from '../../../service/location.service';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-preparation-items',
  templateUrl: './preparation-items.component.html',
  styleUrls: ['./preparation-items.component.scss'],
  providers: [DialogService]
})
export class PreparationItemsComponent implements OnInit, AfterViewInit {

  products: StockVariantModel[] = [];
  loading = true;
  total: number;
  user: UserModel = new UserModel();
  settings = [];
  currency: string;

  taxes: TaxRateModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  categories: CategoryModel[] = [];
  suppliers: ContactModel[] = [];
  searchCategories = [];
  searchSuppliers = [];
  searchLocations = [];

  display: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  type: string;
  moreLoading: boolean;
  showQuantitiesDialog: boolean;
  quantities: any [];
  printing: boolean;
  printTemplate: PrintTemplateModel = new PrintTemplateModel();
  apiUrl: string;
  queueDisplay = false;
  recipe: ProductRecipeModel[] = [];
  recipeDialog: boolean;
  recipeId: number;
  tableLoading: boolean;
  selectedVariant: any [];
  nonRecipesCount: number;

  parameters = {
    id: '',
    'stock.name': '',
    code: '',
    'stock.category.name': '',
    'stock.currency.code': '',
    'stock.type': 'semi_product',
    'stock.locations.id': '',
    page: 1,
    itemsPerPage: 20,
    'stock.isArchive': false,
    location: 0
  };
  selectLocationShow: boolean;
  selectLocationShowCount = 0;
  private actions: any;
  displayAction: boolean;

  constructor(private http: HttpService, private dataTableSettingService: DataTableSettingService, private dialog: DialogService,
              private router: Router, private activatedRoute: ActivatedRoute, private currencyService: CurrencyService,
              private utilityService: UtilityService, private sharedDataService: NotificationService,
              private categoryService: CategoryService, private contactService: ContactService,
              private locationService: LocationService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.currency = this.currencyService.getCurrency().code;

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.apiUrl = environment.apiUrl;
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'PRODUCT_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
      this.controlImportStatus();
    });
    this.loadSearchData().then();
    this.nonRecipesCount = 0;
  }

  ngAfterViewInit(): void {
    this.nonRecipesCount = 0;
  }

  load(event: LazyLoadEvent = null): void {
    this.nonRecipesCount = 0;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));

    this.parameters['stock.isArchive'] = false;
    this.tableLoading = true;
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe(response => {
      this.products = response['hydra:member'];
      this.total = response['hydra:totalItems'];

      this.tableLoading = false;
    });
  }

  async loadSearchData(): Promise<void> {
    this.loading = true;
    await this.http.get(`${CategoryModel.IRI}/category-type/product?search=yes`).subscribe(category => {
      this.searchCategories = category['hydra:member'];
    });
    await this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
      if (this.locations.length === 1) {
        this.selectLocationShowCount = 1;
        this.parameters.location = this.locations[0].id;
        this.load();
      } else {
        this.selectLocationShowCount = 1;
        this.selectLocationShow = true;
      }
      this.loading = false;
    });
  }

  cost(variant: StockVariantModel): number {
    if (variant.stock.type !== 'material') {
      return variant.totalCost;
    } else {
      return variant.averageCost;
    }
  }

  createExcel(): void {
    const parameters = {...this.parameters};
    delete parameters.page;
    delete parameters.id;

    this.http.download('/api/data/exports/stocks')
      .subscribe(r => {
        this.utilityService.downloadFile(r, this.type + '.xlsx');
      });
  }

  createPdf(id: number, templateId: number): void {
    this.printing = true;
    this.http.get(`${StockVariantModel.IRI}/pdf`).subscribe(response => {
      this.printing = false;
      window.open(response.response, '_blank');
    });
  }

  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'PRODUCT_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 30000);
    }
  }

  async onLoadRecipes(id): Promise<void> {
    if (this.checkActionPermission('CAN_EDIT_SUB_RECIPES_RECIPE')) {
      this.loading = true;
      this.recipeId = id;
      await this.http.get(ProductRecipeModel.IRI, {'items.stock.id': id}).subscribe(res => {
        this.recipe = res['hydra:member'];
        this.loading = false;
        this.recipeDialog = true;

      });
    } else {
      this.displayAction = true;
    }
  }

  getRecipeTotal(recipe): number {
    if (recipe) {
      let grandTotal = 0;
      recipe.map(item => {
        grandTotal = grandTotal + item.cost;
      });
      return grandTotal;
    }
    return 0;
  }

  openForm(): void {
    if (this.checkActionPermission('CAN_ADD_SUB_RECIPES')) {
      this.item = new StockModel();
      this.display = true;
      this.displayCreate = true;
      this.displayUpdate = false;
    } else {
      this.displayAction = true;
    }
  }

  changeParameters(event, type): void {
    if (type === 'category') {
      this.parameters['category.id'] = event.value;
    }
    if (type === 'locations') {
      this.parameters['stock.location.id'] = event;
      this.selectLocationShow = false;
    }
    this.load(null);
  }

  async edit(event): Promise<void> {
    if (this.checkActionPermission('CAN_EDIT_SUB_RECIPES')) {
      this.loading = true;
      await this.http.get(`${StockModel.IRI}/${event}`).subscribe((response) => {
        this.item = response;
        this.item.category = this.item.category ? this.item.category['@id'] : null;
        this.item.locations = this.item.locations ? this.item.locations : null;
        this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
        this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
        this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
        // @ts-ignore
        this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
        this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
        this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
        this.item.purchaseOptions.map((data, i) => {
          this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
            this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
            this.item.purchaseOptions[i].supplier = data.supplier['@id'];
            this.item.purchaseOptions[i].unit = data.unit['@id'];
            if (data.packName === '' && data.packPerCase === null) {
              this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
            } else {
              this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
            }

          });
        });
        this.item.stockQuantities.map((stockQuantity, i) => {
          this.item.stockQuantities[i] = stockQuantity['@id'];
        });
        this.item.locations.map((location, i) => {
          this.item.locations[i] = location['@id'];
        });
        this.displayCreate = true;
        this.display = true;
        this.loading = false;
      });
    } else {
      this.displayAction = true;
    }

  }

  reload(): void {
    this.load();
  }

  totalCostControl(totalCost): void {
    if (totalCost === 0) {
      this.nonRecipesCount = this.nonRecipesCount + 1;
    }
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].inventory.findIndex(obj => obj.name === text);
      return this.actions[0].inventory[findIndex].value ? this.actions[0].inventory[findIndex].value : false;
    }
  }


}


