import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input} from '@angular/core';
import {LocationModel} from '../../../model/location.model';
import { HttpService} from '../../../service/http.service';
import {StockModel} from '../../../model/stock.model';
import {StockTransfersModel} from '../../../model/stock-transfers.model';
import {StockTransferItemModel } from '../../../model/stock-transfer-item.model';
import {ActivatedRoute, Router} from '@angular/router';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CurrencyService} from '../../../service/currency.service';
import {StockQuantityModel} from '../../../model/stock-quantity.model';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {InventoryService} from '../../../service/inventory.service';
import {InventoryModel} from '../../../model/inventory.model';
import {UserPageInformationModel} from '../../../model/user-page-information.model';
import {UserModel} from '../../../model/user.model';
import {UserPageInformationService} from '../../../service/user-page-information.service';



@Component({
  selector: 'app-stock-transfers-items',
  templateUrl: './stock-transfers-items.component.html',
  styleUrls: ['./stock-transfers-items.component.scss']
})

export class StockTransfersItemsComponent implements OnInit, AfterViewInit {
  @ViewChild('stock') stockElement!: ElementRef<HTMLInputElement>;
  @Input() item: StockTransfersModel = new StockTransfersModel();
  transfer: StockTransfersModel = new StockTransfersModel();
  locations: LocationModel[] = [];
  destinationLocations: any[] = [];
  quantities: StockQuantityModel[] = [];
  stocks: StockModel[] = [];
  total: 0;
  items: StockTransferItemModel[] = [];
  loading: boolean;
  transferDate: Date;
  defaultDate: Date;
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  id: any;
  isReceived: boolean;
  errors = [];
  isOriginSelected: boolean;
  isDestinationSelected: boolean;
  submitted: boolean;
  originStockAmount = [];
  destinationAmount = [];
  totals = [];
  isDeleteAble: boolean;
  validationSubscriber: Subscription;
  itemQuantities = [];
  currencyLabel: string;
  isQuantityLoaded: boolean;
  successMessage: boolean;
  findStockValues = [];
  findStockAlert: boolean;
  noSelectedItem: boolean;
  noSameLocation: boolean;
  itemQuantityValues = [];
  today: Date = new Date();
  activeInventory: InventoryModel;
  isCanBeSend = true;
  isCanBeSelectDate: Date | string;
  isAddStockAvailable: boolean;
  maxQuantityError: boolean;
  minDate: Date;
  maxDate: Date;

  infoTextShowDisplay = false;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;

  constructor(private http: HttpService, private route: ActivatedRoute, private flashMessageService: FlashMessageService,
              private router: Router, private currencyService: CurrencyService, private translate: TranslateService,
              private validationService: ValidationService, private userPageInformationService: UserPageInformationService) { }

  async ngOnInit(): Promise<void> {
    this.getUserInformation();
    this.noSameLocation = false;
    this.loading = true;
    if (this.item.id){
      this.id = this.item.id;
    }
    this.getActiveInventory().then();
    this.loadLocations();
    this.load().then();
    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });
    }
  async load(): Promise<void> {
    if (this.id) {
      this.isDeleteAble = false;
      // tslint:disable-next-line:max-line-length
      this.http.get(`${StockTransfersModel.IRI}/${this.id}`).subscribe((response: StockTransfersModel) => {
        this.transfer = response;
        this.transfer.origin = response.origin['@id'];
        this.transfer.destination = response.destination['@id'];
        this.transfer.items.map((item, key) => {
          this.autoCompleteValues.push(item.variant);
          this.itemQuantities.push(item.quantity);
          this.transfer.items[key].variant = this.transfer.items[key].variant['@id'];
          this.transfer.items[key].originStockFirst = this.transfer.items[key].originStock;
          this.transfer.items[key].originStockQuantity = item.originStockQuantity['@id'];
          this.transfer.items[key].destinationStockFirst = this.transfer.items[key].destinationStock;
          this.transfer.items[key].destinationStockQuantity = item.destinationStockQuantity['@id'];
          this.totals[key] = item.total;
          this.transfer.amount = this.totals.reduce((x, y) => x + y);
        });
        this.loading = false;
        this.isDeleteAble = true;
      });
    } else {
      this.http.get(`${StockTransfersModel.IRI}/stock-transfer-number`).subscribe((response: StockTransfersModel) => {
        this.loading = false;
        this.transfer = response;
        this.transfer.id = null;
        this.transfer.items = [];
        this.transfer.items.map(((item: StockTransferItemModel, key) => {
          this.totals.push(key, item.total);
        }));

        this.transfer.transferDate = this.activeInventory ? new Date(this.activeInventory.startDate) : this.today;
      });
    }
  }
  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }
  loadLocations(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });
    this.http.get(LocationModel.IRI, {pagination: false}).subscribe(response => {
      this.destinationLocations = response['hydra:member'];
    });
  }
  originStockQuantity = (event) => {
    this.transfer.origin = event.value;
  }
  destinationStockQuantity = (event) => {
    this.transfer.destination = (event.value);
  }
  searchStock = (event, i) => {
    if ( this.transfer.origin === null || this.transfer.destination === null ) {
      this.isOriginSelected = true;
      this.isDestinationSelected = true;
    }else if ( this.transfer.destination === null ) {
      this.isDestinationSelected = true;
    }else{
      this.http.get(StockModel.IRI, {isActive: 1, type: 'material', name: event.query}).subscribe((response: StockModel) => {
        if ( response['hydra:totalItems'] === 0){
// @ts-ignore
          this.variants = [{name: this.translate.instant('NO_RECORDS'), id: null}];
        }else {
          this.variants = response['hydra:member'];
        }

      });
    }
  }
  onOriginChange(): void {
    // @ts-ignore
    const originId = this.transfer.origin.split('/');
    // @ts-ignore
    const destinationId = this.transfer.destination?.split('/');
    if (this.isOriginSelected === true || this.transfer.destination === null) {
      this.isOriginSelected = false;
    }else if (originId[3] === destinationId[3]){
      this.noSameLocation = true;
    }else {
      if (this.transfer.items.length !== 0) {
        this.isQuantityLoaded = true;
        this.transfer.items.map((item, key) => {
          // tslint:disable-next-line:max-line-length
          this.http.get(StockQuantityModel.IRI, {
            location: this.transfer.origin,
            stock: item.stock,
            stockVariant: item.variant
          }).subscribe((response: StockQuantityModel) => {
            this.originStockAmount = response['hydra:member'];
            if (response['hydra:totalItems'] !== 0) {
              this.transfer.items[key].originStock = this.originStockAmount[0].amount - item.quantity;
              this.transfer.items[key].originStockFirst = this.originStockAmount[0].amount;
            } else {
              this.transfer.items[key].originStock = -item.quantity;
              this.transfer.items[key].originStockFirst = 0;
            }
            this.transfer.items[key].originStockQuantity = this.originStockAmount[0]['@id'];
            this.isQuantityLoaded = false;
          });
        });
      }
    }
  }
  onDestinationChange(): void {
    // @ts-ignore
    const originId = this.transfer.origin.split('/');
    // @ts-ignore
    const destinationId = this.transfer.destination.split('/');
    if (this.isDestinationSelected === true) {
      this.isDestinationSelected = false;
    }else if (originId[3] === destinationId[3]){
      this.noSameLocation = true;
    }else {
      this.noSameLocation = false;
      if (this.transfer.items.length !== 0) {
        this.isQuantityLoaded = true;
        this.transfer.items.map((item, key) => {
          // tslint:disable-next-line:max-line-length
          this.http.get(StockQuantityModel.IRI, {
            location: this.transfer.destination,
            stock: item.stock,
            stockVariant: item.variant
          }).subscribe((response: StockQuantityModel) => {
            this.destinationAmount = response['hydra:member'];
            if (response['hydra:totalItems'] !== 0) {
              this.transfer.items[key].destinationStock = this.destinationAmount[0].amount + item.quantity;
              this.transfer.items[key].destinationStockFirst = this.destinationAmount[0].amount;
            } else {
              this.transfer.items[key].destinationStock = 0 + item.quantity;
              this.transfer.items[key].destinationStockFirst = 0;
            }
            this.transfer.items[key].destinationStockQuantity = this.destinationAmount[0]['@id'];
            this.isQuantityLoaded = false;
          });
        });
      }
    }
  }
  async save(): Promise<void> {
    this.submitted = true;
    if ( this.transfer.origin === null || this.transfer.destination === null ) {
      this.isOriginSelected = true;
      this.isDestinationSelected = true;
    }else if (this.transfer.items.length === 0){
      this.noSelectedItem = true;
    }else{
      if (this.transfer.items.length === null ){
        this.noSelectedItem = true;
      }else{
        this.itemQuantityValues = [];
        this.transfer.items.map((item, i) => {
          if (item.quantity === null) {
            this.itemQuantityValues.push(item.stock['@id']);
          }
        });
        if (this.itemQuantityValues.length === 0){
          if (this.id){
            await this.http.put(`${StockTransfersModel.IRI}/${this.id}`, this.transfer).then((response: StockTransfersModel) => {
              this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            });
          }else{
            await this.http.post(StockTransfersModel.IRI, this.transfer).then((response: StockTransfersModel) => {
              if (response){
                this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            });
          }
        }
      }
    }
    setTimeout(() => {
      this.submitted = false;
      this.noSelectedItem = false;
    }, 3000);

  }
  addItem = () => {
    const transferItem = new StockTransferItemModel();
    this.transfer.items.push(transferItem);
    this.rowControl();
  }
  ngAfterViewInit(): void {}
  removeItem = (id, eventTotal, i: number) => {
    this.transfer.items.splice(i, 1);
    this.autoCompleteValues.splice(i, 1);
    this.transfer.amount = this.transfer.amount - eventTotal;
    if ( id !== undefined){
      this.http.delete(`${StockTransferItemModel.IRI}/${id}`).then((response: StockTransferItemModel) => {
        if (response){
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    }
    this.rowControl();
  }
  delete(id): void {
    this.http.delete(`${StockTransfersModel.IRI}/` + id ).then((response: StockTransfersModel) => {
        this.router.navigate([`/stock-transfers`]);
    });
  }
  async onVariantSelect(event: StockModel, i: number): Promise<void> {
    const findStock = this.transfer.items.findIndex(obj =>  obj.stock === event['@id']);
    if (findStock !== -1){
      this.findStockValues.push(findStock);
      this.findStockAlert = true;
      this.transfer.items.splice(this.transfer.items.length - 1, 1);
      this.autoCompleteValues.splice(this.autoCompleteValues.length - 1, 1);
    }else {
    this.isQuantityLoaded = true;
    this.transfer.items[i].variant = event.variants[0]['@id'];
    this.transfer.items[i].stock = event['@id'];
    this.transfer.items[i].quantity = null;
    this.transfer.items[i].total = 0;
      // @ts-ignore
    this.transfer.items[i].symbol = event.currency?.symbol;
    this.firstQuantityControl(event.amount);
    this.transfer.items[i].price = event.variants[0].price;
      // @ts-ignore
    this.transfer.items[i].unit = event.unit.name;

    this.autoCompleteValues.push(this.transfer.items[i].variant);
    this.itemQuantities.push(this.transfer.items[i].quantity );
    // origin StockQuantity
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    await this.http.get(StockQuantityModel.IRI, {location: this.transfer.origin, stock: event['@id'], stockVariant: event.variants[0]['@id']} ).subscribe((response: StockQuantityModel) => {
      this.originStockAmount = response['hydra:member'];
      if (response['hydra:totalItems'] !== 0) {
        this.transfer.items[i].originStock = this.originStockAmount[0].amount;
        this.transfer.items[i].originStockFirst = this.originStockAmount[0].amount;
        this.transfer.items[i].price = this.originStockAmount[0].averagePrice;
      } else {
        this.transfer.items[i].originStock = 0;
        this.transfer.items[i].originStockFirst = 0;
      }
      this.transfer.items[i].originStockQuantity = this.originStockAmount[0]['@id'];
    });
    // origin StockQuantity
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    await this.http.get(StockQuantityModel.IRI, { location: this.transfer.destination, stock: event['@id'], stockVariant: event.variants[0]['@id']} ).subscribe((response: StockQuantityModel) => {
      this.destinationAmount = response['hydra:member'];
      if (response['hydra:totalItems'] !== 0) {
        this.transfer.items[i].destinationStock = this.destinationAmount[0].amount;
        this.transfer.items[i].destinationStockFirst = this.destinationAmount[0].amount;
      } else {
        this.transfer.items[i].destinationStock = 0;
        this.transfer.items[i].destinationStockFirst = 0;
      }
      this.transfer.items[i].destinationStockQuantity = this.destinationAmount[0]['@id'];
      this.isQuantityLoaded = false;
    });
    }
    this.rowControl();
  }

  onQuantityChange(event: Event, quantity, i: number): void {
    if (this.id){
      if ( this.itemQuantities[i] > quantity ){
        this.transfer.items[i].originStock = this.transfer.items[i].originStockFirst + (this.itemQuantities[i] - quantity) ;
        // tslint:disable-next-line:max-line-length
        this.transfer.items[i].destinationStock = this.transfer.items[i].destinationStockFirst - (this.itemQuantities[i] - quantity) ;
        this.transfer.items[i].total = quantity * this.transfer.items[i].price;
        this.totals[i] = (quantity) * this.transfer.items[i].price;
        this.transfer.amount = this.totals.reduce((x, y) => x + y);
        this.afterQuantityControl(this.transfer.items[i].originStock);
      }else{

        this.transfer.items[i].originStock = this.transfer.items[i].originStockFirst - ( quantity - this.itemQuantities[i]) ;
        // tslint:disable-next-line:max-line-length radix
        this.transfer.items[i].destinationStock = this.transfer.items[i].destinationStockFirst + (quantity - this.itemQuantities[i]) ;
        this.transfer.items[i].total = quantity * this.transfer.items[i].price;
        this.totals[i] = quantity  * this.transfer.items[i].price;
        this.transfer.amount = this.totals.reduce((x, y) => x + y);
        this.afterQuantityControl(this.transfer.items[i].originStock);
      }
    }else{
      // tslint:disable-next-line:radix
      this.transfer.items[i].destinationStock = this.transfer.items[i].destinationStockFirst + parseFloat(quantity ? quantity : 3);
      this.transfer.items[i].total = quantity * this.transfer.items[i].price;
      this.transfer.items[i].originStock = this.transfer.items[i].originStockFirst - (quantity) ;
      this.totals[i] = quantity * this.transfer.items[i].price;
      this.transfer.amount = this.totals.reduce((x, y) => x + y);
      this.afterQuantityControl(this.transfer.items[i].originStock);
    }
    this.calculateTotal();
    this.rowControl();
  }
  firstQuantityControl = (amount) => {
    if ( amount > 0 ){
      this.isReceived = true;
    }else{
      this.isReceived = false;
    }
  }
  afterQuantityControl = (amount) => {
    if ( amount < 0 ){
      this.isReceived = false;
    }else{
      this.isReceived = true;
    }
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void{
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }
  async onChangeStatus( status: string): Promise<void>{
    this.transfer.status = status;
    this.transfer.items.map((obj, i) => {
      this.transfer.items[i].stock = this.transfer.items[i].stock['@id'];
    });
    await this.http.put(`${StockTransfersModel.IRI}/${this.id}`, this.transfer).then((response: StockTransfersModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
    });
  }
  async getActiveInventory(): Promise<any>{
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then( (response: InventoryModel) => {
      this.activeInventory = response;
      this.minDate = new Date(this.activeInventory.startDate);
      this.maxDate = new Date(this.activeInventory.endDate);
      this.transfer.transferDate = this.activeInventory ? new Date(this.activeInventory.startDate) : this.today;
    });
  }
  transferDateControl(event): void{
    if (this.activeInventory?.startDate){
      const selectedDate = new Date(event);
      const InventoryStartDate = new Date(this.activeInventory.startDate);
      if (selectedDate.getTime() < InventoryStartDate.getTime()){
        this.transferDate = this.today;
        this.isCanBeSend = false;
        this.isCanBeSelectDate = this.activeInventory.startDate;
      }else {
        this.minDate = InventoryStartDate;
        this.isCanBeSend = true;
      }
    }else {
      this.minDate = this.today;
    }
  }

  calculateTotal(): void {
    let grandTotal = 0;
    this.transfer.items.map( item => {
      grandTotal = grandTotal + item.total;
    });
    this.transfer.amount = grandTotal;
    this.rowControl();
  }
  rowControl(): void{
    this.isAddStockAvailable = false;
    this.maxQuantityError = false;
    this.transfer.items.map(item => {
      if (item.quantity === null || item.originStock < 0){
        this.isAddStockAvailable = true;
      }
      if (item.stock === undefined || item.stock === null){
        this.isAddStockAvailable = true;
      }
      if (item.quantity > item.originStockFirst){
        this.isAddStockAvailable = true;
        this.maxQuantityError = true;
      }
    });
  }

  getUserInformation(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.userPageInformationService.getItemsAction({user: this.userId, name: 'StockTransfersItems'});
    this.userPageInformationService.getItems().subscribe(response => {
      if (response !== null) {
        this.infoTextData = response;
        this.infoTextShowDisplay = this.infoTextData[0].isActive;
      } else {
        this.infoTextShowDisplay = true;
      }
    });
  }
  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'StockTransfersItems';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'StockTransfersItems'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }
}
