import {ApiResourceModel} from './api-resource.model';
import {CategoryModel} from './category.model';
import {TaxRateModel} from './tax-rate.model';
import {ContactModel} from './contact.model';
import {UnitModel} from './unit.model';
import {StockVariantModel} from './stock-variant.model';
import {StockVariantOptionModel} from './stock-variant-option.model';
import {CurrencyModel} from './currency.model';
import {StockQuantityModel} from './stock-quantity.model';
import {PurchaseOptionModel} from './purchase-option.model';
import {LocationModel} from './location.model';
import {StockPriceChanges} from './stock-price-changes.model';
import {ModifierItemModel} from './modifier-item-model.model';
import {ModifierModel} from './modifier.model';
import {PackOptionModel} from './pack-option.model';

export class StockModel extends ApiResourceModel {
  public static IRI = '/api/stocks';
  public name: string;
  public code: string;
  public isTrackByLot: boolean;
  public comment: string;
  public alertLevel: number;
  public category: CategoryModel|string;
  public tax: TaxRateModel|string;
  public supplier: ContactModel|string;
  public unit: UnitModel|string;
  public averageCost: number;
  public valueInStock: number;
  public amount: number;
  public expectedAmount: number;
  public committedAmount: number;
  public missingAmount: number;
  public variants: StockVariantModel[] = [];
  public stockVariantOptions: StockVariantOptionModel[] = [];
  public useAveragePrice = false;
  public currency: CurrencyModel|string;
  public type: string;
  public isMaking: boolean;
  public isBuying: boolean;
  public trackType: string;
  public tags: string[];
  public stockQuantities: StockQuantityModel[] = [];
  public purchaseOptions: PurchaseOptionModel[] = [];
  public locations: LocationModel[] = [];
  public orderItem: boolean;
  public orderItem1: boolean;
  public orderItem2: boolean;
  public orderItem3: boolean;
  public allergen: string[];
  public prepTime: number;
  public cookTime: number;
  public cookingInstruction: string;
  public about: string;
  public image: string;
  public stockPriceChanges: StockPriceChanges[] = [];
  public id: any;
  public accountingCode: number;
  public modifier: ModifierModel[] = [];
  public extra: ModifierModel[] = [];
  public packOptions: PackOptionModel[] = [];
  unitName: string;
  isArchive: any;
  posId: string;
  isActive: boolean;
}

export enum StockTrackType {
  None = 'NONE',
  Lot = 'LOT',
  Default = 'DEFAULT',
}
