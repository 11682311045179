<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'USERS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'USERS'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}"
              icon="pi pi-plus" (click)="openForm()">
      </button>

    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [loading]="loading" [lazy]="true" [value]="users">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'FIRST_NAME' | translate}}</th>
            <th>{{'LAST_NAME' | translate}}</th>
            <th>{{'EMAIL' | translate}}</th>
            <th>{{'ROLES_TYPE' | translate}}</th>
            <th>{{'ROLES' | translate}}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>{{ row.firstName }}</td>
            <td>{{ row.lastName }}</td>
            <td>{{ row.email }}</td>
            <td>{{ row.roleType.name }}</td>
            <td>{{ row.roles }}</td>
            <td class="p-text-right">
          <span class="p-buttonset">
            <button pButton type="button" icon="pi pi-key" class="p-button-warning p-button-sm" (click)="openChangePassword(row)"></button>
            <button pButton type="button" icon="pi pi-pencil" class="p-button-sm" (click)="edit(row)"></button>
            <button pButton type="button" class="p-button-danger p-button-sm" icon="pi pi-trash" (click)="delete(row)"></button>
          </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-dialog [(visible)]="showForm" appendTo="body" header="{{'USER_CREATE' | translate}}" [style]="{width: '40vw'}">
  <div class="p-grid">
    <div class="p-col-12">
      <label for="firstName">{{'FIRST_NAME' | translate}}</label>
      <input id="firstName" [style]="{width: '100%', height:'45px'}" type="text" pInputText [(ngModel)]="user.firstName" name="firstName" autofocus>
      <small class="p-error">{{ errors['firstName'] }}</small>
    </div>
    <div class="p-col-12">
      <label for="lastName">{{'LAST_NAME' | translate}}</label>
      <input id="lastName" [style]="{width: '100%', height:'45px'}" type="text" pInputText [(ngModel)]="user.lastName" name="lastName">
      <small class="p-error">{{ errors['lastName'] }}</small>
    </div>
    <div class="p-col-12">
      <label for="email">{{'EMAIL' | translate}}</label>
      <input id="email" [style]="{width: '100%', height:'45px'}" type="text" pInputText [(ngModel)]="user.email" name="email">
      <small class="p-error">{{ errors['email'] }}</small>
    </div>
    <div class="p-col-12">
      <label for="roles">{{'ROLES' | translate}}</label>
      <p-dropdown [options]="roles" [(ngModel)]="user.roleType" (onChange)="onChangeRole($event)" optionLabel="name" optionValue="@id" id="roles" name="roles"></p-dropdown>
      <small class="p-error">{{ errors['roles'] }}</small>
    </div>
    <div *ngFor="let permission of permissions" class="p-m-2">
      <p-checkbox name="groupname" [value]="permission['@id']" [label]="permission.name|translate" [(ngModel)]="user.permissions" disabled = "true"></p-checkbox>
    </div>
    <div class="p-col-12" *ngIf="!id">
      <label for="password">{{'PASSWORD' | translate}}</label>
      <input id="password" [style]="{width: '100%', height:'45px'}" type="text" pInputText [(ngModel)]="user.password" name="password">
      <small class="p-error">{{ errors['password'] }}</small>
    </div>
    <div class="p-col-12">
      <label for="languages">{{'LANGUAGE' | translate}}</label>
      <p-dropdown [options]="languages" [(ngModel)]="user.language" optionLabel="name" optionValue="value" id="languages" name="languages"></p-dropdown>
      <small class="p-error">{{ errors['language'] }}</small>
    </div>
    <div class="p-col-12">
      <label for="locations">{{'LOCATIONS' | translate}}</label>
      <p-multiSelect inputId="locations" [style]="{width: '100%', height:'45px'}" [options]="locations" [(ngModel)]="user.locations"
                     appendTo="body" optionLabel="name" optionValue="@id" id="locations" name="locations">

      </p-multiSelect>
      <small class="p-error">{{ errors['locations'] }}</small>
    </div>
  </div>
  <div class="p-grid"[style]="{marginTop:'20px'}">
    <div class="p-col-4 p-offset-2">
      <button pButton [style]="{width: '100%', height:'45px',background: 'none',color: '#444444', borderColor: '#444444'}" label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2" (click)="hideForm()" ></button>
    </div>
    <div class="p-col-4">
      <button pButton [style]="{width: '100%', height:'45px'}" label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
    </div>
  </div>

</p-dialog>

<p-dialog [(visible)]="showPasswordForm" appendTo="body" header="{{'CHANGE_PASSWORD' | translate}}" [style]="{width: '30vw'}">
  <div class="p-fluid">
    <h2></h2>
    <div class="p-field">
      <label for="newPassword">{{'NEW_PASSWORD' | translate}}</label>
      <p-password [(ngModel)]="user.password" mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})." id="newPassword" name="password"></p-password>
    </div>
  </div>

  <div class="p-grid"[style]="{marginTop:'20px'}">
    <div class="p-col-4 p-offset-2">
      <button pButton [style]="{width: '100%', height:'45px',background: 'none',color: '#444444', borderColor: '#444444'}" label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2" (click)="hideForm()" ></button>
    </div>
    <div class="p-col-4">
      <button pButton [style]="{width: '100%', height:'45px'}" label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="changePassword()"></button>
    </div>
  </div>
</p-dialog>
