<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form action="" *ngIf="!loading && !isConnected">
  <div class="p-fluid">
    <div class="p-field">
      <label for="baseUrl">{{ 'BASE_URL'|translate }}</label>
      <input id="baseUrl" type="text" [name]="'baseUrl'" pInputText [(ngModel)]="attributes.baseUrl" autofocus>
    </div>
    <div class="p-field">
      <label for="companyNo">{{ 'COMPANY_CODE'|translate }}</label>
      <input id="companyNo" type="text" [name]="'companyNo'" pInputText [(ngModel)]="attributes.companyNo" autofocus>
    </div>
    <div class="p-field">
      <label for="periodNo">{{ 'PERIOD_NO'|translate }}</label>
      <input id="periodNo" type="text" [name]="'periodNo'" pInputText [(ngModel)]="attributes.periodNo" autofocus>
    </div>
    <div class="p-field">
      <label for="userId">{{ 'USER_NAME'|translate }}</label>
      <input id="userId" type="text" [name]="'userName'" pInputText [(ngModel)]="attributes.userName" autofocus>
    </div>
    <div class="p-field">
      <label for="apikey">{{ 'PASSWORD'|translate }}</label>
      <input id="apikey" type="text" [name]="'password'" pInputText [(ngModel)]="attributes.password" autofocus>
    </div>
  </div>
  <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]="this.submitted" (click)="save()"></button>
</form>
<div *ngIf="!loading && isConnected">

  <img src="https://www.logomuhasebe.com/wp-content/uploads/2019/04/logo-muhasebe-en-son.png" width="250px" alt="">
  <p-divider></p-divider>
  <h2 class="p-d-inline-block">
    <i class="pi pi-users"style="font-size: 1.5rem; margin-right: 10px" on></i> {{ 'SYNC_CONTACT'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="invoiceLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="getContacts()"></p-button>
  <p-divider></p-divider>
  <h2 class="p-d-inline-block">
    <i class="pi pi-file-pdf"style="font-size: 1.5rem; margin-right: 10px" on></i> {{ 'SYNC_INCOMING_INVOICE'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="invoiceLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="getIncomingEinvoices()"></p-button>
  <p-divider></p-divider>

</div>

