import { Component, OnInit, Input } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss']
})
export class PageInfoComponent implements OnInit {
  @Input() pageName: string;
  display: boolean;
  ngSwitchCase: any;

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  showDialog(): void {
    this.display = true;
  }


}
