<ul *ngIf="showAddMenu">
  <li><a (click)="hideMenu()" [state]="{openForm: true}"  routerLink="contacts/create">{{ 'CONTACT'|translate }}</a></li>
  <li><a (click)="hideMenu()" [state]="{status: invoiceStatus.Pending}" routerLink="/sales/create">{{ 'QUOTE'|translate }}</a></li>
  <li><a (click)="hideMenu()" [state]="{status: invoiceStatus.Confirmed}" routerLink="/sales/create">{{ 'ORDER'|translate }}</a></li>
  <li><a (click)="hideMenu()" routerLink="/purchase/create">{{ 'PURCHASE'|translate }}</a></li>
  <li><a (click)="hideMenu()" routerLink="/stocks/create">{{ 'MATERIAL'|translate }}</a></li>
  <li><a (click)="hideMenu()" routerLink="semi-product/create">{{ 'SEMI_PRODUCT'|translate }}</a></li>
  <li><a (click)="hideMenu()" routerLink="/products/create">{{ 'PRODUCT'|translate }}</a></li>
  <li><a (click)="hideMenu()" routerLink="/work-orders/create">{{ 'WORK_ORDER'|translate }}</a></li>
  <li><a (click)="hideMenu()" routerLink="/stock-adjustments/create">{{ 'STOCK_ADJUSTMENTS'|translate }}</a></li>
</ul>

<button id="addButton" pButton type="button" (click)="setShowAddMenu()" class="p-button-raised p-button-rounded p-button-lg" [icon]="showAddMenu ? 'pi pi-times': 'pi pi-plus'"></button>
