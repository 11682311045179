<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div style="
width: 60%;position: absolute;top:0px;bottom: 0px;
background-image: url('assets/category.jpeg');
background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: -1;
    opacity: 0.1;
"></div>
<div class="p-grid" style="width: 100%">
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-6 p-lg-offset-0 p-sm-offset-0 p-xl-offset-3 p-md-offset-0">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'ADD_CATEGORY_PAGE_TITLE' | translate}}
          <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}"> {{ 'ADD_CATEGORY_PAGE_DESCRIPTION' | translate}}</p>
        </h1>
      </div>
    </div>
    <div class="p-col-12" style="background: #ffffff9e;
    padding: 40px 8px;
    border-radius: 10px;">
      <p-treeTable [loading]="loading" [lazy]="true" [value]="data" *ngIf="!loading" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'5%'}"></th>
            <th [style]="{width:'9%'}">{{'CODE' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'20%'}">{{'NAME' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'15%'}">{{'CATEGORY_TYPE' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
            <th [style]="{width:'15%'}">{{'TAX_RATE' | translate}} <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode">
            <td class="p-text-left">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            </td>
            <td>{{rowData.code}}</td>
            <td><a href="javascript:void(0)" [style]="{color:'#0edcdc'}" (click)="edit(rowData)">{{rowData.name}}</a></td>
            <td [style]="{textAlign:'center'}">{{rowData.categoryType | translate }}</td>
            <td [style]="{textAlign:'center'}">%{{rowData.tax?.rate}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="5">
              <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}" icon="pi pi-plus"
                      (click)="openForm()"></button>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
      <div class="p-p-3 p-text-left" [style]="{marginTop: '5%'}">
        <p-button label="İleri" (onClick)="nextPage()" [style]="{float: 'right', marginTop:'10px'}"
                  icon="pi pi-angle-right" iconPos="right">

        </p-button>
        <p-button label="Geri" (onClick)="previousPage()" [style]="{float: 'right', marginTop:'10px',marginRight:'10px'}"
                  icon="pi pi-angle-left" iconPos="left"></p-button>

      </div>

    </div>
  </div>
</div>
<router-outlet></router-outlet>

