<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <a routerLink="/inventory/{{ items[0].inventoryId}}/edit">
        <p-button icon="pi pi-arrow-left"></p-button>
      </a>
      <h3 [style]="{ fontWeight: '600', marginTop: '8px' }">{{ 'INVENTORY_CATEGORY'|translate }}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      {{ items[0].status}}
      <app-page-info [pageName]="'INVENTORY_CATEGORY'"></app-page-info>
      <p-button [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"  label="{{ 'UPDATE' | translate}}" [style]="{marginLeft: '5px', float:'right'}" (onClick)="updateInventory(items[0].itemCode)"></p-button>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>

  <div class="" >
    <div [style]="{padding:'5px'}">
      <div class="div-sums" *ngFor="let item of categories" >
        <table class="tableHeight">
          <tr>
            <td colspan="3" class="title">{{ item.name}}</td>
          </tr>
          <tr>
            <td class="text">{{'HAND_STOCK' | translate}} </td>
            <td> <b class="currency">{{item.stockValue | number : '1.2-3'}}</b></td>
          </tr>
          <tr>
            <td class="text">{{'REAL_STOCK' | translate}} </td>
            <td> <b class="currency">{{item.realStockValue | number : '1.2-3'}}</b></td>
          </tr>
          <tr>
            <td class="text">{{'CONSUMPTION' | translate}} </td>
            <td> <b class="currency">{{item.consuption | number : '1.2-3'}}</b></td>
          </tr>
        </table>
        <div class="symbol">₺</div>
    </div>
    </div>
  </div>

  <div class="tableDiv-content">
    <div class="tableDiv-sub">

      <div><b>{{ 'CODE'|translate}}</b> : {{ items[0].itemCode}}</div>
      <div><b>{{ 'LOCATION'|translate}}</b> : {{ items[0].locationName}}</div>
      <div><b>{{ 'CREATED_AT'|translate}}</b> : {{ items[0].created_at.date | date: 'y-MM-d'}}</div>
      <div><b>{{ 'METHOD'|translate}}</b> : {{ items[0].inventory_count_type | translate}}</div>

    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table styleClass="p-datatable-striped" [loading]="loading" [lazy]="true" [value]="items">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '10%'}">{{'GROUP_CODE' | translate}}</th>
            <th scope="col" [style]="{width: '30%'}">{{'SUB_GROUP' | translate}}</th>
            <th scope="col" [style]="{width: '14%'}">{{'HAND_STOCK' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">{{'REAL_STOCK' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">{{'CONSUMPTION' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">%</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex">
         <!-- <tr *ngIf="row.StockValue !== null">--->
          <tr>
            <td>{{ row.itemCode}}-{{ row.code }}</td>
            <td><a [style]="{color:'#25cde3'}" routerLink="/inventory-stock/{{row.categoryId}}/{{ row.locationId }}/{{ id }}">{{ row.name }}</a></td>
            <td class="p-text-center"> {{ row.StockValue | currency: '₺'}}</td>
            <td class="p-text-center"> {{ row.RealStockValue | currency: '₺'}}</td>
            <td class="p-text-center"> {{ row.StockValue - row.RealStockValue | currency: '₺'}}</td>
            <td class="p-text-center"> % {{ (((row.StockValue - row.RealStockValue) / row.StockValue ) * 100) | number: '1.0-2'}} </td>
            </tr>
        </ng-template>

      </p-table>
    </div>
  </div>

  <div class="sum-div">
    <p-table [loading]="loading" [lazy]="true" [style]="{marginTop:'10px'}"
              styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" [style]="{width: '10%'}"></th>
          <th scope="col" [style]="{width: '36%'}"><a [style]="{color:'#e0e0e0'}" routerLink="/inventory-stock/null/{{ items[0].locationId }}/{{ id }}">{{'ALL_CATEGORIES' | translate}}</a></th>
          <th scope="col" [style]="{width: '11%'}">{{ sums['stockValue'] | currency: '₺' : '': '1.2-3' }}</th>
          <th scope="col" [style]="{width: '10%'}">{{sums['realStockValue'] | currency: '₺': '': '1.2-3' }}</th>
          <th scope="col" [style]="{width: '10%'}">{{sums['consuption'] | currency: '₺': '': '1.2-3'}}</th>
          <th scope="col" [style]="{width: '10%'}">% {{ ((sums['consuption'] / sums['stockValue'] ) * 100) | number: '1.2-2'}}</th>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>

