<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'ORDER_HISTORY' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'ORDER_HISTORY'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
        <p-calendar  (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body"
                     (onClose)="load(null, 'search')" selectionMode="range" [(ngModel)]="parameters.createdAt"
                     name="createdAt" dateFormat="yy-mm-dd">
        </p-calendar>
        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  inputId="dropdown" placeholder="{{ 'SELECT_STATUS' | translate}}" (onChange)="parameters.status = $event.value; load(null, 'search')" baseZIndex="9999" [options]="status" name="materialStatus" [(ngModel)]="parameters.status" optionLabel="name" optionValue="value" ></p-dropdown>

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" [filter]=true
                  placeholder="{{'SELECT_SUPPLIER' | translate}}" [options]="suppliers"  [(ngModel)]="parameters.contactId" name="supplier"  (onChange)="load(null, 'search')"  optionValue="@id" optionLabel="name" ></p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6">
      <div class="p-grid grid-div">
        <div class="p-col div-sums">
          <span class="title">{{'NUMBER_OF_ITEMS' | translate}}</span>
          <span class="total-price">{{ items.length }} <b
            class="currency">{{'PIECE' | translate}}</b></span>
        </div>
        <div class="p-col div-sums">
          <span class="title">{{'TOTAL_VALUE' | translate}}</span>
          <span class="total-price">{{ totals.total | number: '1.2-2'}}<b
            class="currency">{{ currency}}</b></span>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <form (submit)="load(null , 'search')" *ngIf="!detailed">

        <p-table responsiveLayout="scroll" [loading]="tableLoading" [lazy]="true" [value]="items"
                 (onLazyLoad)="load($event, 'search')" [tableStyle]="{'min-width': '50rem'}"
                 [reorderableColumns]="true" (onRowReorder)="onReorder($event)"
                 [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true"
                 styleClass="p-datatable-striped p-datatable-sm" [style]="{width:'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 5%"></th>
              <th style="width: 7%">{{ (parameters.type == invoiceType.Sales ? 'ORDER_NUMBER' : 'CODE') | translate }} <app-tooltip [description]="'ORDER_NUMBER_TOOLTIP'"></app-tooltip> </th>
              <th style="width: 20%">{{ (parameters.type == invoiceType.Sales ? 'CUSTOMER' : 'SUPPLIER')|translate}} <app-tooltip description="{{ parameters.type == invoiceType.Sales ? 'CUSTOMER_TOOLTIP' : 'SUPPLIER_TOOLTIP'}}"></app-tooltip></th>
              <th style="width: 15%">{{ 'LOCATIONS' | translate}} <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip></th>
              <th style="width: 15%">{{ 'TOTAL' | translate}} <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip></th>
              <th style="width: 15%">{{ 'CREATED_AT' | translate}} <app-tooltip description="CREATED_TOOLTIP"></app-tooltip> </th>
              <th style="width: 15%">{{ 'DEADLINE' | translate}} <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip></th>
              <th style="width: 15%" *ngIf="parameters.type === invoiceType.Purchase">{{ 'STATUS' | translate}} <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip></th>
             <!---
              <th [style]="{width: settings|columnWidth: parameters.type: '11'}" pResizableColumn  *ngIf="parameters.type === invoiceType.Purchase">{{ 'IS_RECEIVED' | translate}}</th>
              --->
            </tr>
            <tr>
              <th></th>
              <td><input  [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.code" name="code" id="code"></td>
              <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters['contact.name']" name="contactName" id="contactName"></td>
              <td>
                <p-dropdown placeholder="{{ 'LOCATION' | translate}}"  appendTo="body" [options]="locations" optionValue="id" (onChange)="parameters.location = $event.value;
                load(null, 'search')" [(ngModel)]="parameters.location" optionLabel="name" [ngModelOptions]="{standalone: true}">

                </p-dropdown>
              </td>
              <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.total" name="total" id="total"></td>
              <td><p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" appendTo="body" (onClose)="load()" selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt" dateFormat="yy-mm-dd"></p-calendar></td>
              <td><p-calendar #expectedDate (onSelect)="closeCalendar('expectedDate')" appendTo="body" (onClose)="load()" selectionMode="range" [(ngModel)]="parameters.expectedDate" name="expectedDate" dateFormat="yy-mm-dd"></p-calendar></td>
              <td *ngIf="parameters.type === invoiceType.Sales"><p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body" (onClose)="load()" selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt" dateFormat="yy-mm-dd"></p-calendar></td>
              <td *ngIf="parameters.type === invoiceType.Sales">
                <p-dropdown inputId="dropdown"  (onChange)="parameters.itemStatus = $event.value; load()" baseZIndex="9999" [options]="stockStatuses" name="itemStatus" [(ngModel)]="parameters.itemStatus" optionLabel="name" optionValue="value" ></p-dropdown>
              </td>
              <td *ngIf="parameters.type === invoiceType.Purchase"><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.isReceived" name="isReceived" id="isReceived"></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row  let-index="rowIndex" >
            <tr  [pReorderableRow]="index">
              <td>
                <span class="pi pi-bars" pReorderableRowHandle></span>
                {{ index + 1 }}
              </td>
              <td [style]="{width:'15%'}"><a [style]="{color:'#11b5c4'}" routerLink="/orders/order-details/{{row.id}}">{{ row.code }}</a></td>
              <td>{{ row.customerName ? row.customerName : (row.contact ? row.contact.name : '-') }}</td>
              <td>{{ row.location.name}}</td>
              <td [style]="{textAlign: 'right'}">{{ row.total | currency: (row.currency ? row.currency.code : currency): '' }}</td>
              <td [style]="{textAlign: 'right'}">{{ row.createdAt|date: 'Y/MM/dd' }}</td>
              <td [style]="{textAlign: 'right'}">{{ row.expectedDate ? (row.expectedDate|date: 'Y/MM/dd') : '-' }}</td>
              <td *ngIf="parameters.type === invoiceType.Purchase"> <p-tag [severity]="row.status|statusLabel" [rounded]="false" >{{ row.status | translate }}</p-tag></td>
              <!----
              <td *ngIf="parameters.type === invoiceType.Purchase"><button [routerLink]="['/purchases/receive-list/' + row.id]" type="button" pButton   class="p-button-text" [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></button></td>
                            <td *ngIf="parameters.type === invoiceType.Purchase"><button type="button" pButton [disabled]="row.isReceived" (click)="receive(row.id)" class="p-button-text" [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></button></td>
             ---->
            </tr>
          </ng-template>
          <ng-template pTemplate = "footer">
            <tr *ngIf = "!items.length">
              <td colspan="9">
              <h3>{{'NO_RECORDS' | translate}}</h3>
            </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
          <div class="p-col-4 p-offset-3" style="">
            <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                    [icon]="'pi pi-arrow-down'"
                    (click)="load()"></button>
          </div>
        </div>
        <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
      </form>
      <app-invoice-detailed-list *ngIf="detailed"></app-invoice-detailed-list>
    </div>
  </div>
</p-card>

<p-overlayPanel #op dismissable="true" showCloseIcon="true">
  <ng-template pTemplate>
    <p-table [value]="workOrders" [loading]="workOrdersLoading" style="width: 75px">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'code' | translate}}</th>
          <th>{{'workOrderList.status' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-row>
        <td>
          <a href="" [routerLink]="'/work-orders/' + row.id + '/edit'">{{ row.code }}</a>
        </td>
        <td>
          <p-tag [severity]="row.status|statusLabel">{{ row.status|status }}</p-tag>
        </td>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #dp>
  <ng-template pTemplate>
    <div *ngFor="let item of changeStatusItems">
      <p-button *ngIf="item.state.status !== deliveryActions.PARTIAL_DELIVERED"  [label]="item.label" styleClass="p-button-text" class="p-d-block" (onClick)="changeStatus(item.state.id, item.state.status); dp.hide()"></p-button>
      <p-button *ngIf="item.state.status === deliveryActions.PARTIAL_DELIVERED"  [label]="item.label" styleClass="p-button-text" class="p-d-block" (onClick)="receive(item.state.id); dp.hide()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog  [(visible)]="displayLocations" [style]="{width: '50vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff;border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444" (click)="changeLocation(location.id)">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>
