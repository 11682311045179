import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private authService: AuthService, private router: Router) {
  }

  // @ts-ignore
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.authService.checkLogin().then((response: boolean) => {
      // @ts-ignore
      if (!response.isValid) {
        window.localStorage.getItem('token');
        window.localStorage.getItem('user');
        window.localStorage.getItem('role');
        window.location.href = '/login';
      }
    });

    return true;
  }
}
