<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-2"><h3
      [style]="{ fontWeight: '600' }">{{ 'INVOICE_DELIVERY_NOTES' | translate}}</h3>
    </div>
    <div class="p-col-12 p-md-9" >
      <div class="p-grid grid-div">
        <div class="p-col div-sums">
          <span class="title">{{ 'ORDER_COUNT' | translate}}</span>
          <span class="total-price">{{total}} <b
            class="currency">Adet</b></span>
        </div>
        <div class="p-col div-sums">
          <span class="title">{{'TOTAL' | translate}}</span>
          <span class="total-price">{{ grandTotal | number: '1.2-2'}}<b
            class="currency">{{currency}}</b></span>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-1">
      <app-page-info [pageName]="'INVOICE_DELIVERY_NOTES'"></app-page-info>
    </div>
  </div>

  <div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="errors.length > 0">
    <div class="p-col div-sums alert-div">
      <b> {{ errors[0]?.status}}!</b>
      {{ errors[0]?.message}}
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <form (submit)="load(null )" >
      <p-table [value]="shipments" [tableStyle]="{'min-width': '50rem'}"
               [paginator]="true" [rows]="20" [totalRecords]="total" [lazy]="true" (onLazyLoad)="load($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'15%'}" pSortableColumn="documentNumber" ><p-sortIcon field="documentNumber"></p-sortIcon>{{'NUMBER'| translate}}</th>
            <th scope="col" pSortableColumn="documentDate" ><p-sortIcon field="documentDate"></p-sortIcon>{{'EFFECTIVE_DATE'| translate}}</th>
            <th scope="col" pSortableColumn="invoice.code" ><p-sortIcon field="invoice.code"></p-sortIcon>{{'CODE'| translate}}</th>
            <th scope="col" pSortableColumn="documentType" ><p-sortIcon field="documentType"></p-sortIcon>{{'TYPE'| translate}}</th>
            <th scope="col" [style]="{width:'12%'}" pSortableColumn="invoice.location.name" ><p-sortIcon field="invoice.location.name"></p-sortIcon>{{'LOCATION'| translate}}</th>
            <th scope="col" [style]="{width:'20%'}" pSortableColumn="invoice.contact" ><p-sortIcon field="invoice.contact"></p-sortIcon> {{'SUPPLIER'| translate}}</th>
            <th scope="col" pSortableColumn="invoice.total" ><p-sortIcon field="invoice.total"></p-sortIcon>{{'TOTAL_INCL_TAX'| translate}}</th>
            <th scope="col" >{{'FULLY_ALLOCATED'| translate}}</th>
            <th scope="col" >{{'OPEN_CREDITS'| translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'ACCOUNTING_SYNCED'| translate}}</th>
          </tr>
          <tr>
            <td> <input [style]="{width: '100%',height:'45px',  border:'none', borderBottom: '2px solid #47bfd4'}"
                        type="text" pInputText [(ngModel)]="parameters.documentNumber" name="documentNumber" id="documentNumber"
                        placeholder="{{ 'DOCUMENT_NUMBER' | translate}}"/>
            </td>
            <td></td>
            <td><input [style]="{width: '100%',height:'45px',  border:'none', borderBottom: '2px solid #47bfd4'}"
                       type="text" pInputText [(ngModel)]="parameters['invoice.code']" name="code" id="code"
                       placeholder="{{ 'CODE' | translate}}"/>
            </td>
            <td></td>
            <td>
              <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                          [(ngModel)]="parameters['invoice.location']" placeholder="{{ 'SELECT_LOCATION' | translate}}"
                          (onChange)="changeParameters($event, 'location')"
                          [options]="searchLocations" optionLabel="name" optionValue="id" [showClear]="true">
              </p-dropdown>
            </td>
            <td> <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                             appendTo="body" [(ngModel)]="parameters['invoice.contact']" (onChange)="changeParameters($event, 'contact')"
                             placeholder="{{ 'SELECT_SUPPLIER' | translate }}" [options]="searchSuppliers" [filter]="true"
                             optionLabel="name" optionValue="id" [showClear]="true">
            </p-dropdown></td>
            <td></td>
            <td></td>
            <td></td>
            <td><p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                            [(ngModel)]="parameters['invoice.isInvoiced']" (onChange)="changeParameters($event, 'isInvoiced')"
                            [options]="status" optionLabel="value" optionValue="id" [showClear]="true" placeholder="{{ 'SELECT_STATUS' | translate }}">
            </p-dropdown>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td><a [routerLink]="'/order/receive-manuel/' + row.invoice.id" [style]="{color: '#47bfd4'}">
              <b>{{row.documentNumber}}</b></a></td>
            <td>{{row.documentDate | date: 'dd/MM/Y' }} </td>
            <td>{{row.invoice.code }}</td>
            <td>{{row.documentType | translate}}</td>
            <td>{{row.invoice.location?.name}}</td>
            <td>{{row.invoice.contact?.name}}</td>
            <td [style]="{padding: '0px',textAlign: 'right'}">{{row.invoice.total}}</td>
            <td [style]="{textAlign: 'center'}">{{row.invoice.isReceived ? 'YES' : 'NO'| translate }}</td>
            <td [style]="{textAlign: 'center'}">{{row.invoice.credits.length > 0 ? 'YES' : 'NO'| translate }}</td>
            <td *ngIf="row.invoice.isInvoiced === true" >
              <p-button label="{{'SEND'| translate }}" icon="pi pi-csuccess" styleClass="p-button-sm"></p-button>
            </td>
            <td *ngIf="row.invoice.isInvoiced === false">
              <p-button label="{{'UN_SEND'| translate }}"
                        [disabled]="isSaved" (onClick)="sendInvoiceToAccounting(row.invoice.id)"
                        icon="pi pi-refresh" styleClass="p-button-sm p-button-warning"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
        <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
      </form>
    </div>
  </div>
</p-card>
