import {Component, OnDestroy, OnInit} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {CurrencyService} from '../../../service/currency.service';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';

@Component({
  selector: 'app-stock-create',
  templateUrl: './stock-create.component.html',
  styleUrls: ['./stock-create.component.scss']
})
export class StockCreateComponent implements OnInit, OnDestroy {

  item: StockModel = new StockModel();
  submitted: boolean;
  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private currencyService: CurrencyService, private validationService: ValidationService) { }

  ngOnInit(): void {
    this.item.currency = this.currencyService.getCurrency()['@id'];

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  async save(): Promise<void> {
    this.submitted = true;
    this.errors = [];


    await this.http.post(StockModel.IRI, {
      name: this.item.name,
      type: this.activatedRoute.snapshot.data.type,
      isBuying: this.activatedRoute.snapshot.data.type === 'material',
      isMaking: this.activatedRoute.snapshot.data.type !== 'material',
      currency: this.item.currency
    }).then((response: StockModel) => {
      if (response) {
        this.router.navigate([`/stocks/${response.id}/edit`]);
      }
    });
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }
}
