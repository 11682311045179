<p-card xmlns="http://www.w3.org/1999/html">
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <p-button icon="pi pi-arrow-left" [routerLink]="'/inventory-category/' + categoryId" [style]="{marginRight: '5px', float:'left'}"></p-button>
      <h3 [style]="{ fontWeight: '600', marginTop: '8px' }">{{ 'INVENTORY_STOCKS'|translate }}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'INVENTORY_STOCKS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-4">
      <p-autoComplete (onSelect)="onVariantSelect($event)" placeholder="{{'SEARCH'| translate}}"
                      field="name" [suggestions]="stocks" appendTo="body"
                      [style]="{width:'100%'}" [ngModel]="parameters.stock"
                      (completeMethod)="searchVariant($event)"></p-autoComplete>
    </div>
    <div class="p-col-12 p-md-6 p-lg-1">
      <p-button icon="pi pi-refresh" (click)="parameters.stock= null; load()"></p-button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
  </div>
  <div class="tableDiv-content">
    <div class="tableDiv-sub">
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showCode" [binary]="true" id="code" inputId="binary"></p-checkbox>
        <label for="price"> {{'CODE' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showPrice" [binary]="true" id="price" inputId="binary"></p-checkbox>
        <label for="price"> {{'PRICE' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showStockQuantity" [binary]="true" id="STOCK_QUANTITY" inputId="binary"></p-checkbox>
        <label for="STOCK_QUANTITY"> {{'STOCK_QUANTITY' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showStockValue" [binary]="true" id="STOCK_VALUE" inputId="binary"></p-checkbox>
        <label for="STOCK_VALUE"> {{'STOCK_VALUE' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showOrderItem2" [binary]="true" id="COUNT_ITEM_TWO" inputId="binary"></p-checkbox>
        <label for="STOCK_VALUE"> {{'COUNT_ITEM_TWO' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showOrderItem3" [binary]="true" id="COUNT_ITEM_THREE" inputId="binary"></p-checkbox>
        <label for="STOCK_VALUE"> {{'COUNT_ITEM_THREE' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showConsumptionQuantity" [binary]="true" id="CONSUMPTION_QUANTITY" inputId="binary"></p-checkbox>
        <label for="CONSUMPTION_QUANTITY"> {{'CONSUMPTION_QUANTITY' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showConsumptionValue" [binary]="true" id="CONSUMPTION_VALUE" inputId="binary"></p-checkbox>
        <label for="CONSUMPTION_VALUE"> {{'CONSUMPTION_VALUE' | translate}}</label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showRatio" [binary]="true" id="ratio" inputId="binary"></p-checkbox>
        <label for="ratio"> % {{ 'RATIO'| translate}} </label>
      </div>
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showDescription" [binary]="true" id="description" inputId="binary"></p-checkbox>
        <label for="description"> {{'DESCRIPTION' | translate}}</label>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12 alert p-mt-2" *ngIf="checkActionPermission('CAN_ADD_COUNT_IN_INVENTORY') === false">
      {{ 'CAN_NOT_ADD_COUNT_IN_INVENTORY'| translate}}
    </div>
    <div class="p-col-12 " style="overflow-x: auto;">
      <p-table [loading]="tableLoading" [lazy]="true" [value]="items" [style]="{marginTop:'10px'}"
               (onLazyLoad)="load($event)" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" *ngIf="showCode" [style]="{width:'8%'}" pSortableColumn="stockVariant.code"><p-sortIcon field="stockVariant.code"></p-sortIcon>{{'CODE' | translate}}</th>
            <th scope="col" [style]="{width:'15%'}" pSortableColumn="stockVariant.name"><p-sortIcon field="stockVariant.name"></p-sortIcon>{{'STOCK_NAME' | translate}}</th>
            <th scope="col" *ngIf="showPrice" [style]="{width:'8%'}"  pSortableColumn="price"><p-sortIcon field="price"></p-sortIcon>{{'PRICE' | translate}}</th>
            <th scope="col" *ngIf="showStockQuantity" [style]="{width:'8%'}" pSortableColumn="quantity"><p-sortIcon field="quantity"></p-sortIcon>{{'STOCK_QUANTITY' | translate}}</th>
            <th scope="col" *ngIf="showStockValue" [style]="{width:'8%'}" pSortableColumn="valueInStock"><p-sortIcon field="valueInStock"></p-sortIcon>{{'STOCK_VALUE' | translate}}</th>
            <th scope="col" [style]="{width:'8%'}">{{'UNIT' | translate}}1</th>
            <th scope="col" *ngIf="showOrderItem2" [style]="{width:'10%'}">{{'UNIT' | translate}}2</th>
            <th scope="col" *ngIf="showOrderItem3"[style]="{width:'8%'}">{{'UNIT' | translate}}3</th>
            <th scope="col" [style]="{width:'8%'}">{{'COUNT_QUANTITY' | translate}}</th>
            <th scope="col" [style]="{width:'8%'}" pSortableColumn="countValueInStock"><p-sortIcon field="countValueInStock"></p-sortIcon> {{'COUNT_VALUE' | translate}}</th>
            <th scope="col" *ngIf="showConsumptionQuantity" [style]="{width:'8%'}">{{'CONSUMPTION_QUANTITY' | translate}}</th>
            <th scope="col" *ngIf="showConsumptionValue" [style]="{width:'8%'}">{{'CONSUMPTION_VALUE' | translate}}</th>
            <th scope="col" *ngIf="showRatio" [style]="{width:'8%'}">% {{ 'RATIO'| translate}}</th>
            <th scope="col" *ngIf="showDescription" [style]="{width:'8%'}">{{'DESCRIPTION' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex">
          <tr>
            <td *ngIf="showCode" [style]="{padding:'0px'}">{{ row.stockVariant.code}} </td>
            <td>
              <app-tooltip description="
                    Dönem Başı : {{ row.firstQuantity | number : '1.2-2'}}
                    Satın Alma : {{ row.purchaseQuantity | number : '1.2-2'}}
                    + Transfer : {{ row.addedTransfer | number : '1.2-2'}}
                    - Transfer : {{ row.missingTrasnfer | number : '1.2-2'}}
                    - Fire/Zayi : {{ row.wasteEvent | number : '1.2-2'}}
                    - Satışlar : {{ row.saleQuantity | number : '1.2-2'}}
                    - Üretim : {{ row.produceQuantity | number : '1.2-2'}}
              "></app-tooltip>
              {{ row.stockVariant.name}}
            </td>
            <td [style]="{padding:'0px'}" *ngIf="showPrice">{{ row.price |currency: currencyLabel: ''}}</td>
            <td [style]="{padding:'0px'}" *ngIf="showStockQuantity">
              {{row.quantity | number : '1.2-3'}} {{ row.stock.unit?.name}}</td>
            <td [style]="{padding:'0px'}" *ngIf="showStockValue" >{{ row.quantity * row.price |currency: currencyLabel: ''}}</td>
            <td [style]="{padding:'0px'}">
              <div class="unit-div">
                <p-inputNumber *ngIf="row.inventoryItem.status !== 'CLOSED_INVENTORY' && checkActionPermission('CAN_ADD_COUNT_IN_INVENTORY') === true" [(ngModel)]="row.unit1"
                               (onBlur)="setQuantity( $event.target.value, i, null)" mode="decimal" inputId="withoutgrouping"
                               [minFractionDigits]="2" [maxFractionDigits]="5"
                               [useGrouping]="false" [style]="{width: '80px'}">
                </p-inputNumber>
                <span *ngIf="row.inventoryItem.status === 'CLOSED_INVENTORY' || checkActionPermission('CAN_ADD_COUNT_IN_INVENTORY') === false">{{ row.countQuantity }}</span>
                <span [style]="{marginLeft: '5px'}"> {{ row.stock.unit?.name}}</span>
              </div>
            </td>
            <td [style]="{padding:'0px'}" *ngIf="showOrderItem2">
              <div class="unit-div" *ngIf="row.stock.purchaseOptions[0]">

                <p-inputNumber *ngIf="row.inventoryItem.status !== 'CLOSED_INVENTORY'  && checkActionPermission('CAN_ADD_COUNT_IN_INVENTORY') === true" [(ngModel)]="row.unit2"
                               (onBlur)="setQuantity( $event.target.value, i, row.stock.purchaseOptions[0].baseUnitQuantity)" mode="decimal" inputId="withoutgrouping"
                               [minFractionDigits]="2" [maxFractionDigits]="5"
                               [useGrouping]="false" [style]="{width: '80px'}">
                </p-inputNumber>
                <span *ngIf="row.inventoryItem.status === 'CLOSED_INVENTORY' && !checkActionPermission('CAN_ADD_COUNT_IN_INVENTORY')">{{ row.unit2 }}</span>
                <span [style]="{marginLeft: '5px'}"> {{row.stock.purchaseOptions[0].packName}}<br>
                  <span [style]="{fontSize: '9px'}">({{row.stock.purchaseOptions[0].baseUnitQuantity}} {{ row.stock.unit?.name}})</span>
                </span>
              </div>
            </td>
            <td [style]="{padding:'0px'}" *ngIf="showOrderItem3">
              <div class="unit-div" *ngIf="row.stock.purchaseOptions[1]">

                <p-inputNumber *ngIf="row.inventoryItem.status !== 'CLOSED_INVENTORY'" [(ngModel)]="row.unit3"
                               [minFractionDigits]="2" [maxFractionDigits]="5"
                               (onBlur)="setQuantity( $event.target.value, i,row.stock.purchaseOptions[0].baseUnitQuantity)" mode="decimal" inputId="withoutgrouping"
                               [useGrouping]="false" [style]="{width: '80px'}">
                </p-inputNumber>
                <span *ngIf="row.inventoryItem.status === 'CLOSED_INVENTORY'">{{ row.unit3 }}</span>
                <span [style]="{marginLeft: '5px'}"> {{row.stock.purchaseOptions[1].packName}}</span>
              </div>
            </td>
            <td [style]="{padding:'0px'}" class="p-text-right">{{ row.countQuantity}}</td>
            <td [style]="{padding:'0px'}" class="p-text-right">{{ row.countValueInStock |currency: currencyLabel: '' }}</td>
            <td [style]="{padding:'0px'}" *ngIf="showConsumptionQuantity" class="p-text-right">
              <span [style]="row.consumptionQuantity < 0 ? 'color: #ff2525;border-bottom: 2px solid;padding-bottom: 16px': '' ">
                {{ (( row.firstQuantity + row.purchaseQuantity + row.addedTransfer) -
                (row.missingTrasnfer + row.wasteEvent) - row.countQuantity )| number :  '1.2-3'}}
              </span>
            </td>
            <td [style]="{padding:'0px'}" *ngIf="showConsumptionValue" class="p-text-right">
              <span [style]="row.consumptionQuantity < 0 ? 'color: #ff2525;border-bottom: 2px solid;padding-bottom: 16px': '' ">
                {{ (( row.firstQuantity + row.purchaseQuantity + row.addedTransfer) -
                (row.missingTrasnfer + row.wasteEvent) - row.countQuantity ) * row.price |currency: currencyLabel: ''}}
              </span>
            </td>
            <td *ngIf="showRatio" [style]="{textAlign:'right',padding:'0px'}">
              %{{ row.countQuantity === 0 ? 100
              : getRatio(row.countQuantity,(( row.firstQuantity + row.purchaseQuantity + row.addedTransfer) -
                (row.missingTrasnfer + row.wasteEvent) )
                )}}</td>
            <td [style]="{padding:'0px'}" *ngIf="showDescription">
              <input type="text" pInputText [(ngModel)]="row.description"  (change)="onChangeDescription($event, i, row)">
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
  <div class="sum-div" *ngIf="loading === false">
    <p-table [loading]="loading" [lazy]="true" [value]="items" [style]="{marginTop:'10px'}"
             (onLazyLoad)="load($event)" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" *ngIf="showCode" [style]="{width:'8%'}"></th>
          <th scope="col" [style]="{width:'15%'}"></th>
          <th scope="col" *ngIf="showPrice" [style]="{width:'8%'}" ></th>
          <th scope="col" *ngIf="showStockQuantity" [style]="{width:'10%'}"></th>
          <th scope="col" *ngIf="showStockValue" [style]="{width:'8%'}">{{ sums['stockValue'] | currency: '₺'}}</th>
          <th scope="col" [style]="{width:'8%'}"></th>
          <th scope="col" *ngIf="showOrderItem2" [style]="{width:'8%'}"></th>
          <th scope="col" *ngIf="showOrderItem3" [style]="{width:'8%'}"></th>
          <th scope="col" [style]="{width:'18%'}"></th>
          <th scope="col" [style]="{width:'8%'}">{{ sums['countValue'] | currency: '₺'}}</th>
          <th scope="col" *ngIf="showConsumptionQuantity" [style]="{width:'8%'}"></th>
          <th scope="col" *ngIf="showConsumptionValue" [style]="{width:'8%'}">{{ sums['stockValue'] - sums['countValue']  | currency: '₺'}}</th>
          <th scope="col" *ngIf="showRatio" [style]="{width:'8%'}">%{{ ((sums['stockValue'] - sums['countValue'] ) / sums['stockValue']) * 100  | number: '1.2-2'}}</th>
          <th scope="col" *ngIf="showDescription" [style]="{width:'8%'}"></th>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>

