import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../../service/http.service';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {CompanyIntegrationModel} from '../../../../model/company-integration.model';
import {IncomingInvoiceModel} from '../../../../model/incoming-invoice.model';

@Component({
  selector: 'app-bizim-hesap',
  templateUrl: './bizim-hesap.component.html',
  styleUrls: ['./bizim-hesap.component.scss']
})
export class BizimHesapComponent implements OnInit {
  loading: boolean;
  isConnected: boolean;
  location: number;
  submitted: boolean;
  attributes = {
    firmID: '',
    token: '',
    location: null
  };
  invoiceLoading: boolean;
  parameters = {
    startDate: '',
    endDate: '',
    CurrentPage: 1,
    MaxResultCount: 100,
    SkipCount: 0,
    TCKN_VN_Sender: 9250353261,
  };
  constructor(private http: HttpService, private config: DynamicDialogConfig, translate: TranslateService) { }

  ngOnInit(): void {
    this.isConnected = this.config.data.isConnected;
    this.attributes.location = '/api/locations/' + this.config.data.location;
  }

  async save(): Promise<void> {
    this.submitted = true;

    await this.http.post(`${CompanyIntegrationModel.IRI}/bizim-hesap-connect`, this.attributes).then(response => {
      this.isConnected = true;
    });

    this.submitted = false;
  }
  getIncomingEinvoices(): void {
    this.invoiceLoading = true;
    this.http.get(`${IncomingInvoiceModel.IRI}/e-invoice`, this.parameters).subscribe(response => {
      this.invoiceLoading = false;
    });
  }
  getContacts(): void {
  }
}
