import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {

  constructor() { }
  private dataSubject = new BehaviorSubject<string>(''); // Varsayılan değer boş bir string
  public data$ = this.dataSubject.asObservable();

  sendData(data: string): void {
    this.dataSubject.next(data);
  }
}
