import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {IntegrationAttributeModel} from '../../../../model/integration-attribute.model';
import {IntegrationModel} from '../../../../model/integration.model';
import {HttpService} from '../../../../service/http.service';

@Component({
  selector: 'app-parasut',
  templateUrl: './parasut.component.html',
  styleUrls: ['./parasut.component.scss']
})
export class ParasutComponent implements OnInit {

  isConnected: boolean;
  loading: boolean;
  submitted: boolean;
  contactsLoading: boolean;
  productsLoading: boolean;
  categoriesLoading: boolean;
  locationsLoading: boolean;

  attributes = {
    username: '',
    password: '',
    location: '',
  };

  defaultAttributes = [{
    attrKey: 'username',
    attrValue: ''
  }, {
    attrKey: 'password',
    attrValue: ''
  }];

  constructor(private config: DynamicDialogConfig, private http: HttpService) { }

  ngOnInit(): void {
    this.loading = false;
    this.isConnected = this.config.data.isConnected;
    this.attributes.location = '/api/locations/' + this.config.data.location;
  }

  async save(): Promise<void> {
    this.submitted = true;

    await this.http.post('/api/company_integrations/parasut-connect', this.attributes).then(response => {
      if (response) {
        this.isConnected = true;
      }
    });

    this.submitted = false;
  }

  syncContacts(): void {
    this.contactsLoading = true;

    this.http.get('/api/company_integrations/parasut/contact/' + this.config.data.location).subscribe(response => {
      this.contactsLoading = false;
    });
  }

  syncProducts(): void {
    this.productsLoading = true;

    this.http.get('/api/company_integrations/parasut/product/' + this.config.data.location).subscribe(response => {
      this.productsLoading = false;
    });
  }

  syncCategories(): void {
    this.categoriesLoading = true;

    this.http.get('/api/company_integrations/parasut/category/' + this.config.data.location).subscribe(response => {
      this.categoriesLoading = false;
    });
  }

  syncLocations(): void {
    this.locationsLoading = true;

    this.http.get('/api/company_integrations/parasut/location/' + this.config.data.location).subscribe(response => {
      this.locationsLoading = false;
    });
  }
}
