import { Component, OnInit } from '@angular/core';
import {LocationModel} from '../../../../../model/location.model';
import {CategoryModel} from '../../../../../model/category.model';
import {HttpService} from '../../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationService} from '../../../../../service/location.service';
import {ActivatedRoute} from '@angular/router';
import {CategoryService} from '../../../../../service/category.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {UtilityService} from '../../../../../service/utility.service';
import {StockModel} from "../../../../../model/stock.model";

@Component({
  selector: 'app-by-item',
  templateUrl: './by-item.component.html',
  styleUrls: ['./by-item.component.scss']
})
export class ByItemComponent implements OnInit {


  events: any[] = [];
  options: any;
  info: any;
  menuItem: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  location: LocationModel[] = [];
  categories: CategoryModel[] = [];
  category: any;
  paraLoc: any;
  date: Date | string;
  parameters = {
    date : '',
    location: '',
    category: '',
    id:''
  };
  searchAllLoc: boolean;
  totalQuantity: number;
  totalRecords: number;
  stocks: StockModel[] = [];
  constructor(private http: HttpService, private translate: TranslateService,
              private locationService: LocationService, private route: ActivatedRoute,
              private categoryService: CategoryService, private utilityService: UtilityService) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe( params => {
      const currentDate = new Date();
      const day = new Date(currentDate);
      if (params.date || params.location || params.category){
        this.parameters.date = params.date ? params.date : '';
        this.date = this.parameters.date ? new Date(this.parameters.date) : new Date(this.getFormattedDate(day));
        this.parameters.location = params.location ? params.location : '' ;
        this.parameters.category = params.category ? params.category : '';
      }
      else {
        this.parameters.date = this.getFormattedDate(day);
        this.date = new Date(this.parameters.date);
      }
    });
    this.category = this.parameters.category;
    this.load().then();
  }
  async load(): Promise<void>{
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.http.get(CategoryModel.IRI + '/main-categories', {categoryType: 'product'}).subscribe(response => {
      this.categories = response['hydra:member'];
      this.categories.unshift({
        accountingCode: '',
        categoryMatches: [],
        categoryType: '',
        children: undefined,
        code: '',
        createdAt: '',
        department: '',
        isActive: false,
        location: undefined,
        lvl: 0,
        message: '',
        parent: '',
        parentId: '',
        tax: [],
        updatedAt: '',
        name: this.translate.instant('ALL_CATEGORIES'), id: null});

    });
    if (this.parameters.location === '' || this.parameters.location == null ){
      this.searchAllLoc = true;
    }else {
      this.searchAllLoc = false;
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
    await this.http.get('/api/reports/menu_item_reports', this.parameters).subscribe(response => {
      this.menuItem = response;
      let quantity = 0;
      if (this.menuItem.length > 0){
        this.menuItem.map((item) => {
          quantity = quantity + parseFloat(item.quantity) ;
        });
      }
      this.totalRecords = this.menuItem.length;
      this.totalQuantity = quantity;
      delete this.parameters.id;
      this.loading = false;
    });
  }
  onSelectDate(event): void {
    const start = event;
    this.parameters.date = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    // this.parameters.lastDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
    this.parameters = {...this.parameters};
  }
  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
  }
  onSelectCategory(event): void {
    this.parameters.category = event.value ? event.value : '';
    this.category = this.parameters.category;
  }
  parseFloatS(event): number{
    return parseFloat(event);
  }
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'block';
      }
      // @ts-ignore
      pdf.save(`unpaid_report_byItem.pdf`); // PDF'i indir
    });
  }
  export(): void {
    const parameters = {...this.parameters};
    if (this.searchAllLoc) {
      this.http.download('/api/data/exports/unpaidByItem', parameters)
        .subscribe(r => {this.utilityService.downloadFile(r, 'unpaid_report_byItem.xlsx'); });
    } else {
      this.http.download('/api/data/exports/unpaidByItemLoc', parameters)
        .subscribe(r => {this.utilityService.downloadFile(r, 'unpaid_report_byItem.xlsx'); });
    }
  }
  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  calculateTotal(field: string): number {
    // tslint:disable-next-line:max-line-length
    return (this.menuItem.reduce((total, item) => total + parseFloat(item[field]), 0)) > 0 ? this.menuItem.reduce((total, item) => total + parseFloat(item[field]), 0) : 0;
  }
  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query, type: 'product'}).subscribe((response: StockModel) => {
      this.stocks = response['hydra:member'];
    });
  }
  async onStockSelect(event: StockModel): Promise<void> {
    delete this.parameters.category;
    this.parameters.id = event.id;
  }
}
