<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'STOCK_TRANSFERS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'STOCK_TRANSFERS'"></app-page-info>
      <button pButton pRipple type="button" (click)="openDialog()" [style]="{marginLeft: '5px', float:'right'}"
              label="{{'NEW_TRANSFER' | translate }}">
      </button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <div class="p-inputgroup" [style]="{width: '100%'}">
        <p-calendar id="transferDate" appendTo="body"
                    [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                    selectionMode="range"
                    baseZIndex="9999" [(ngModel)]="parameters.transferDate"
                    name="transferDate" dateFormat="yy-mm-dd"
                    placeholder="{{'SELECT_TRANSFER_DATE'| translate}}">
        </p-calendar>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  (onChange)="parameters.status = $event.value" baseZIndex="9999" [options]="transferStatuses"
                  name="materialStatus" [(ngModel)]="parameters.status"
                  optionLabel="name" optionValue="value">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="locations" [(ngModel)]="parameters.origin" name="location"
                  (onChange)="onSelectOrigin($event)" optionValue="@id" optionLabel="name"
                  placeholder="{{'TRANSFER_ORIGIN'| translate}}">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="destinationLocations" [(ngModel)]="parameters.destination" name="location"
                  (onChange)="onSelectDestination($event)" optionValue="@id" optionLabel="name"
                  placeholder="{{'TRANSFER_DESTINATION'| translate}}">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button label="{{'SEARCH'| translate}}" (click)="load(null , 'search'); onSearch()"></p-button>
    </div>
  </div>
  <div class="p-grid" >
    <div class="p-col-12" *ngIf="infoTextShowDisplay">
      <h4><b>{{'WHAT_IS_STOCK_TRANSFER' |translate}}</b></h4>
      <p>{{'WHAT_IS_STOCK_TRANSFER_DESCRIPTION' |translate}}</p>
      <p>{{'WHAT_IS_STOCK_TRANSFER_DESCRIPTION_1' |translate}}</p>
      <ul>
        <li><b>{{'WHAT_IS_STOCK_TRANSFER_TITLE_1' |translate}}</b> {{'WHAT_IS_STOCK_TRANSFER_TITLE_1_DESCRIPTION' |translate}}</li>
        <li><b>{{'WHAT_IS_STOCK_TRANSFER_TITLE_2' |translate}}</b> {{'WHAT_IS_STOCK_TRANSFER_TITLE_2_DESCRIPTION' |translate}}</li>
        <li><b>{{'WHAT_IS_STOCK_TRANSFER_TITLE_3' |translate}}</b> {{'WHAT_IS_STOCK_TRANSFER_TITLE_3_DESCRIPTION' |translate}}</li>
      </ul>
    </div>
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px" >
      <form (ngSubmit)="load(null, 'search')" >
        <p-table [loading]="loading" [lazy]="true" [value]="items" [tableStyle]="{'min-width': '50rem'}"
                 (onLazyLoad)="load($event)" [rowsPerPageOptions]="[10,25,50]"
                 [rows]="25" [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
                 [resizableColumns]="true" columnResizeMode="expand"
                 styleClass="p-datatable-striped" summary *ngIf = "search">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '5%'}" pSortableColumn="id">
                <p-sortIcon field="id"></p-sortIcon>
                {{'CODE' | translate}}
              </th>
              <th [style]="{width: '12%'}" pResizableColumn
                  pSortableColumn="transferDate">
                <p-sortIcon field="transferDate"></p-sortIcon>
                {{'TRANSFER_DATE' |translate}}
                <app-tooltip description="TRANSFER_DATE_TOOLTIP"></app-tooltip>
              </th>
              <th pSortableColumn="origin.id">
                <p-sortIcon field="origin.id"></p-sortIcon>
                {{ 'TRANSFER_ORIGIN' | translate }}
                <app-tooltip description="TRANSFER_ORIGIN"></app-tooltip>
              </th>
              <th pSortableColumn="destination.id">
                <p-sortIcon field="destination.id"></p-sortIcon>
                {{ 'TRANSFER_DESTINATION' | translate}}
                <app-tooltip description="TRANSFER_DESTINATION"></app-tooltip>
              </th>
              <th [style]="{width: '12%'}"  pSortableColumn="amount">
                <p-sortIcon field="amount"></p-sortIcon>
                {{ 'TRANSFER_VALUE' | translate}}
                <app-tooltip description="TRANSFER_VALUE"></app-tooltip>
              </th>
              <th pSortableColumn="status">
                <p-sortIcon field="status"></p-sortIcon>
                {{ 'TRANSFER_STATUS' | translate}}
                <app-tooltip description="TRANSFER_STATUS"></app-tooltip>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-i="rowIndex">
            <tr>
              <td><a [style]="{color: '#13c1d7'}" href="javascript:void(0)" (click)="edit(row)">{{ row.code }}</a></td>
              <td [style]="{textAlign: 'center'}">{{ row.transferDate | date: ('dd-MM-Y') }}</td>
              <td [style]="{textAlign: 'center'}"><span>{{ row.origin.name }}</span></td>
              <td [style]="{textAlign: 'center'}"><span>{{ row.destination.name }}</span></td>
              <td [style]="{textAlign: 'center'}">{{ row.amount | number }}</td>
              <td>
                <p-tag [severity]="row.status|statusLabel" [style]="{width: '100%'}">{{ row.status| translate }}</p-tag>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            {{items?.length != 0 ? '' : 'NO_RECORDS' | translate }}
          </ng-template>
        </p-table>
      </form>
    </div>
  </div>
</p-card>
<p-sidebar position="right" [(visible)]="display" [style]="{width: '80vw'}" (onHide)="reload()">
  <app-stock-transfers-items *ngIf="display" [item]="item"></app-stock-transfers-items>
</p-sidebar>
<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
