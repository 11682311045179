import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {InventoryModel, InventoryStatus} from '../../model/inventory.model';
import {LocationModel} from '../../model/location.model';
import {HttpService} from '../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessageService} from '../../service/flash-message.service';
import {TranslateService} from '@ngx-translate/core';
import {ValidationService} from '../../service/validation.service';
import {FlashMessage, FlashMessageModel} from '../../model/flash-message.model';
import {Subscription} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {QueueModel} from '../../model/queue.model';
import {UserPageInformationModel} from '../../model/user-page-information.model';
import {UserPageInformationService} from '../../service/user-page-information.service';
import {UserModel} from '../../model/user.model';


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  loading: boolean;
  codeLoading: boolean;
  total: number;
  display: boolean;
  inventory: InventoryModel = new InventoryModel();
  inventories: InventoryModel[] = [];
  inventoriesClosed: InventoryModel[] = [];
  item: InventoryModel = new InventoryModel();
  locations: LocationModel[] = [];
  errors = [];
  defaultDate: Date;
  submitted: boolean;
  validationSubscriber: Subscription;
  id: number;
  InventoryStatus: any[];
  parameters: {
    status: string,
    page: 1;
    itemPerPage: 50;
    pagination: false
  };
  moreLoading: boolean;
  tabMenuItems: MenuItem[];
  activeItem: MenuItem;
  openableInventory: boolean;
  noOpenInventoryDialog: boolean;
  private dayCount: number;
  isLocationSelected: boolean;
  queueDisplay = false;
  queueCloseDisplay = false;
  private actions: any;
  displayAction: boolean;

  infoTextShowDisplay = false;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private flashMessageService: FlashMessageService,
              private router: Router,
              private translate: TranslateService,
              private validationService: ValidationService,
              private datepipe: DatePipe, private userPageInformationService: UserPageInformationService) {
  }

  ngOnInit(): void {
    this.infoTextShowDisplay = false;
    this.getUserInformation();
    this.loadLocations();
    this.loadInventories();
    setTimeout(() => {
      this.InventoryStatus = [
        {name: this.translate.instant('ALL_INVENTORY'), value: ''},
        {name: this.translate.instant(InventoryStatus.LOCKED), value: InventoryStatus.LOCKED},
        {name: this.translate.instant(InventoryStatus.REGISTERED), value: InventoryStatus.REGISTERED}
      ];
    }, 500);
    this.tabMenuItems = [
      {
        label: this.translate.instant('OPENED_INVENTORY_TITLE'),
        title: 'OPENED_INVENTORY_TITLE',
        command: () => {
          this.activeItem = this.tabMenuItems[0];
        }
      },
      {
        label: this.translate.instant('CLOSED_INVENTORY_TITLE'),
        title: 'CLOSED_INVENTORY_TITLE',
        command: () => {
          this.activeItem = this.tabMenuItems[1];
        }
      },
    ];
    this.activeItem = this.tabMenuItems[0];
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'NEW_INVENTORY'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
      this.controlQueueStatus();
    });

    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'CLOSE_INVENTORY'}).subscribe(response => {
      this.queueCloseDisplay = response['hydra:totalItems'] > 0;
      this.controlQueueStatus();
    });
  }

  loadInventories(): void {
    this.loading = true;
    this.openableInventory = true;
    this.http.get(`${InventoryModel.IRI}/allInventories`).subscribe(response => {
      this.inventories = response;
      this.loading = false;
      this.inventories.map(item => {
        if (item.status === 'OPENED_INVENTORY'){
          this.openableInventory = false;
        }
      });
    });
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  loadLocations(): void {
    this.http.get(LocationModel.IRI  + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });
  }

  hideForm(): void {
    this.display = false;
  }

  new(): void {
    if ( this.checkActionPermission('CAN_NEW_INVENTORY')) {
      this.display = true;
      this.loading = true;
      this.codeLoading = true;
      this.http.get(`${InventoryModel.IRI}/inventory_number`).subscribe((response: InventoryModel) => {
        this.inventory.code = response.code;
        this.inventory.number = response.number;
        this.inventory.status = response.status;
        this.inventory.startDate = new Date(response.startDate);
        this.inventory.endDate = new Date(response.endDate);
        this.codeLoading = false;
        this.loading = false;
      });
    }else {
        this.displayAction = true;
      }
  }

  async save(): Promise<void> {

    this.errors = [];
    this.submitted = true;
    if (this.inventory.locations.length === 0) {
      this.isLocationSelected = true;
    } else {
      this.isLocationSelected = false;
      if (this.id) {
        delete this.inventory.items;
        await this.http.put(`${InventoryModel.IRI}/${this.inventory.id}`, this.inventory).then((response: InventoryModel) => {
          if (response) {
            this.loadInventories();
            this.hideForm();
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          }
        });
      } else {
        await this.http.post(InventoryModel.IRI, this.inventory).then((response: InventoryModel) => {
          if (response) {
            this.inventories.push(response);
            this.loadInventories();
            this.hideForm();
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_UPDATED'));
          }
        });
      }
    }
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'NEW_INVENTORY'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
      this.controlQueueStatus();
    });
    this.submitted = false;
  }

  async edit(inventory: InventoryModel): Promise<void> {
    this.openForm();
    this.inventory = {...inventory};
    console.log(this.inventory);
    this.inventory.startDate = new Date(this.inventory.startDate);
    this.inventory.endDate = new Date(this.inventory.endDate);
    this.id = inventory.id;
    this.inventory.locations.map((f, i) => {
      this.inventory.locations[i] = f['@id'];
    });
  }

  openForm(): void {
    this.display = true;
    this.inventory = new InventoryModel();
  }

  noOpen(): void {
    this.noOpenInventoryDialog = true;
  }

  countInventoryDate(): string {
    // @ts-ignore
    const startDate = new Date(this.inventory.startDate);
    const endDate = new Date(this.inventory.endDate);
    const diffInTime = endDate.getTime() - startDate.getTime();
    this.dayCount = Math.ceil(diffInTime / (1000 * 3600 * 24));
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    return 'Seçtiğiniz Envanter Tarihleri : <br><b>' + this.datepipe.transform(this.inventory.startDate, 'yyyy-MM-dd') + ' </b> ile  <b>' + this.datepipe.transform(this.inventory.endDate, 'yyyy-MM-dd') + ' </b> Tarihleri Arasında <b>' + (this.dayCount) + ' </b>Günlüktür';
  }

  controlQueueStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'NEW_INVENTORY'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 10000);
    }
    if (this.queueCloseDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'CLOSE_INVENTORY'}).subscribe(response => {
          this.queueCloseDisplay = response['hydra:totalItems'] > 0;
        });
      }, 10000);
    }
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].inventory.findIndex(obj => obj.name === text);
      return this.actions[0].inventory[findIndex].value ? this.actions[0].inventory[findIndex].value : false;
    }
  }
  test(event): void {
    const newEndDate = new Date(this.inventory.endDate);
    newEndDate.setHours(12, 0, 0);
    this.inventory.endDate = newEndDate;
    console.log(this.inventory.endDate);
  }

  getUserInformation(): void {

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryComponent'});
    this.userPageInformationService.getItems().subscribe(response => {
      if (response !== null) {
        this.infoTextData = response;
        this.infoTextShowDisplay = this.infoTextData[0].isActive;
      } else {
        this.infoTextShowDisplay = true;
      }
    });
  }
  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'InventoryComponent';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryComponent'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }
}
