import {ApiResourceModel} from './api-resource.model';
import {StockVariantModel} from './stock-variant.model';
import {ProductRecipeItemVariantOptionModel} from './product-recipe-item-variant-option.model';

export class ProductRecipeItemModel extends ApiResourceModel {
  public static IRI = '/api/product_recipe_items';
  public stock: StockVariantModel|string;
  public quantity = 1;
  public amount: number;
  public totalAmount: number;
  public note: string;
  public cost: number;
  public wastage: number;
  public amountWithWastage = 0;
  public productRecipeItemVariantOptions: ProductRecipeItemVariantOptionModel[] = [];
  public recipe: string;
  public currency: string;
  public rate: number;
}
