import { Component, OnInit } from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../../service/http.service';
import {LazyLoadEvent} from 'primeng/api';
import {ContactService} from '../../../service/contact.service';
import {ContactModel} from '../../../model/contact.model';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';
import {ChartModule} from 'primeng/chart';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-price-report',
  templateUrl: './price-report.component.html',
  styleUrls: ['./price-report.component.scss']
})
export class PriceReportComponent implements OnInit {
  loading: boolean;
  stocks: StockModel[] = [];
  parameters = {
    name: '',
    type: 'material',
    'supplier.id': '',
    page: 0,
    itemsPerPage: 20
  };
  type: string;
  searchContacts: ContactModel[] = [];
  tableLoading: boolean;
  staticsDisplay: boolean;
  data: any;
  chartOptions: any;
  stockName: string;
  constructor(private translate: TranslateService, private http: HttpService, private contactService: ContactService) { }

  ngOnInit(): void {
    this.load();
  }

  load(event: LazyLoadEvent = null, type = null): void{
    this.loading = true;
    if (type !== 'search'){
      this.parameters.page = this.parameters.page + 1;
    }else {
      this.stocks = [];
      this.parameters.page = 1;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(StockModel.IRI, this.parameters).subscribe(response => {
      this.stocks = response['hydra:member'];
      this.loading = false;
    });
    this.searchContacts = this.contactService.getSearchContacts();
  }
  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }
  changeContact(event): void {
    this.parameters['supplier.id'] = event.value;
    this.stocks = [];
    this.load(null, 'search');
  }
  openPriceDialog(row): void {
    const dates = [];
    const prices = [];
    this.stockName = row.name;
    this.http.get(StockPriceChanges.IRI, {'stock.id': row.id}).subscribe(response => {
      response['hydra:member'].map(item => {
        prices.push(item.price);
        const date = new Date(item.createdAt);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        dates.push( month + '-' + year);
      });
      setTimeout(() => {
        this.data = {
          labels: dates,
          datasets: [{
            type: 'line',
            label: this.translate.instant('PRICE_CHANGE_GRAPHICS'),
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            data: prices
          }]
        };
        this.staticsDisplay = true;
      }, 100);
    });
  }
}
