<form action="" (ngSubmit)="save()" (submit)="save()">
  <p-card>
    <p-splitter [panelSizes]="[30,70]" [style]="{'border': 'none'}">
      <ng-template pTemplate>
        <h5>{{ 'STOCK_CREATE' | translate}}</h5>
      </ng-template>
      <ng-template pTemplate>
        <p class="p-ml-3 page-sub-title">{{ 'STOCK_CREATE_DESCRIPTION' | translate }}</p>
      </ng-template>
    </p-splitter>
    <div class="p-fluid p-p-3">
      <div class="p-field">
        <label for="name">{{'NAME' | translate}}</label>
        <input id="name" type="text" pInputText [(ngModel)]="item.name" name="name" autofocus>
        <small class="p-error">{{ errors['name'] }}</small>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button type="submit" pButton label="{{'SAVE' | translate}}" class="p-ml-3 p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
    </ng-template>
  </p-card>
</form>

