<div class="p-grid" style="width: 100%">
  <div class="p-col-5 p-offset-2">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'SELECT_ACCOUNT_PAGE_TITLE' | translate}}
        </h1>
      </div>
      <div class="p-p-3 p-text-left" [style]="{fontSize: '20px'}">
        {{ 'SELECT_ACCOUNT_PAGE_DESCRIPTION' | translate}}
      </div>
    </div>
  </div>
  <div class="p-col-5">
    <div class="p-p-3 p-text-left" [style]="{marginTop: '42%'}">
      <div *ngIf="!loading">
        <div class="p-grid">
          <div class="p-col-12">
            <div *ngFor="let item of integrations">
              <div *ngIf="item.isActive && item.type ==='accounting'"  class="p-col-12">
                <img [src]="item.logo " width="150" alt="">
                <p-button *ngIf="item.name === 'Luca'" class="p-button-secondary" (click)="openModal(item.id)" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="activeIntegrations[item['@id']]" (click)="disconnect(activeIntegrations[item['@id']])" class="p-button-secondary"  [icon]="'pi pi-trash'" styleClass="p-button-secondary p-ml-2" [style]="{float: 'right'}" [label]="'DISCONNECT'|translate"></p-button>
                <p-button *ngIf="item.name === 'KolayBi'" class="p-button-secondary" (click)="connectKolayBi()" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Paraşüt'" class="p-button-secondary" (click)="connectParasut(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Efatura'" class="p-button-secondary" (click)="connectEfatura(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Logo'" class="p-button-secondary" (click)="connectLogo(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-divider></p-divider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p-button label="İleri" (onClick)="nextPage()" [style]="{float: 'right', marginTop:'100px'}"
                icon="pi pi-angle-right" iconPos="right">

      </p-button> <p-button label="Geri" (onClick)="previousPage()" [style]="{float: 'right', marginTop:'100px',marginRight:'10px'}"
                            icon="pi pi-angle-left" iconPos="left"></p-button>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

