import {Component, Input, OnInit} from '@angular/core';
import {ProductRecipeModel} from '../../../model/product-recipe.model';

@Component({
  selector: 'app-product-recipe-table',
  templateUrl: './product-recipe-table.component.html',
  styleUrls: ['./product-recipe-table.component.scss']
})
export class ProductRecipeTableComponent implements OnInit {

  constructor() { }

  @Input() items: ProductRecipeModel[] = [];
  @Input() loading: boolean;
  @Input() showHeader: boolean;

  ngOnInit(): void {
  }

}
