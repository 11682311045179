import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AddressModel} from '../../model/address.model';
import {CountryModel} from '../../model/country.model';
import {HttpService} from '../../service/http.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../service/validation.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {

  address: AddressModel = new AddressModel();
  countries: CountryModel[] = [];
  submitted: boolean;
  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private ref: DynamicDialogRef, private config: DynamicDialogConfig,
              private validationService: ValidationService) { }

  ngOnInit(): void {
    if (this.config.data.id) {
      this.http.get(this.config.data.id).subscribe((response: AddressModel) => {
        this.address = response;
        if (this.address.country) {
          this.address.country = this.address.country['@id'];
        }
      });
    }

    this.loadCountries();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  loadCountries(): void {
    this.http.get(CountryModel.IRI, {pagination: false}).subscribe(response => {
      this.countries = response['hydra:member'];

      if (this.countries.length > 0 && this.address.country == null) {
        this.address.country = this.countries[0]['@id'];
      }
    });
  }

  async save(): Promise<void> {
    this.submitted = true;
    if (this.address.id) {
      await this.http.put(`${AddressModel.IRI}/${this.address.id}`, this.address).then((response: AddressModel) => {
        if (response) {
          this.ref.close({saved: true, id: response['@id'], body: response.body});
        }
      }).catch(error => {

      });
    } else {
      await this.http.post(AddressModel.IRI, this.address).then((response: AddressModel) => {
        if (response) {
          this.ref.close({saved: true, id: response['@id'], body: response.body});
        }
      }).catch(error => {

      });
    }

    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }
}
