import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UnitModel} from '../model/unit.model';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  units = new BehaviorSubject<UnitModel[]>(null);

  constructor(private http: HttpService) { }

  getUnits(): Observable<UnitModel[]> {
    return this.units.asObservable();
  }

  getUnitsAction(params = {}): void {
    this.http.get(UnitModel.IRI, params).subscribe(response => {
      this.units.next(response['hydra:member']);
    });
  }

  async save(unit: UnitModel): Promise<any> {
    return await this.http.post(UnitModel.IRI, unit).then(response => {
      if (response !== undefined) {
        const units = this.units.getValue();
        units.push(response);
        this.units.next(units);
      }

      return response;
    });
  }

  async update(unit: UnitModel): Promise<any> {
    return await this.http.put(`${UnitModel.IRI}/${unit.id}`, unit).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.units.next(null);
    this.units.unsubscribe();
  }
}
