<p>select-einvoice works!</p>
<p-card>
  <p-steps [model]="items"></p-steps>
  <p-divider></p-divider>
  <div class="p-grid p-offset-3">
    <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" text="{{'STOCK_IMPORT_REMAINING'| translate }}">

    </p-message>

    <p-button icon="pi pi-download" (click)="download('stocks')" styleClass="p-button-text" [label]="'DOWNLOAD_TEMPLATE'|translate"></p-button>
    <p-fileUpload name="file" class="p-d-inline-block" styleClass="p-button-text" chooseIcon="pi pi-upload" mode="basic" [chooseLabel]="'IMPORT'|translate"  #fileUpload auto="true" customUpload="true" multiple="multiple" (uploadHandler)="upload($event, 'stock', fileUpload)"></p-fileUpload>
    <div class="p-mt-4" *ngIf="entity === 'stock'">
      <p-table [value]="response?.errors">
        <ng-template pTemplate="body" let-error>
          <tr>
            <td><p-message severity="error" *ngIf="response?.errors.length > 0" class="p-d-block" [text]="error"></p-message></td>
          </tr>
        </ng-template>
      </p-table>
  </div>
  </div>
  <p-button label="Next" (onClick)="nextPage()" [style]="{float: 'right'}" icon="pi pi-angle-right" iconPos="right"></p-button>
</p-card>
<router-outlet></router-outlet>
