import { Component, OnInit } from '@angular/core';
import {CreditModel} from '../../../model/credit.model';
import {HttpService} from '../../../service/http.service';
import {DialogService} from 'primeng/dynamicdialog';
import {CreditItems} from '../../../model/credit-items.model';

@Component({
  selector: 'app-credit-remembers',
  templateUrl: './credit-remembers.component.html',
  styleUrls: ['./credit-remembers.component.scss'],
  providers: [DialogService]
})
export class CreditRemembersComponent implements OnInit {
  loading: boolean;
  credits: CreditModel[] = [];
  creditItems = [];
  parameters: {
    name: '',
    page: 1,
    itemsPerPage: 20
  };
  showCreditDetailDialog: boolean;
  currencyLabel: string;
  creditItem: any;
  constructor( private http: HttpService) { }

  ngOnInit(): void {
    // @ts-ignore
    // this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.http.get(CreditModel.IRI, this.parameters).subscribe(response => {
      response['hydra:member'].map((item, i) => {
        if (item.items.length > 0) {
          this.credits.push(item);
        }
      });
    });
  }
  showCreditDetail(event): void{
    this.creditItems = event.items;
    this.showCreditDetailDialog = true;
  }


}
