import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from './http.service';
import {UserPageInformationModel} from '../model/user-page-information.model';

@Injectable({
  providedIn: 'root'
})
export class UserPageInformationService {



  items = new BehaviorSubject<UserPageInformationModel[]>(null);

  constructor(private http: HttpService) { }

  getItems(): Observable<UserPageInformationModel[]> {
    return this.items.asObservable();
  }

  getItemsAction(params = {}): void {
    this.http.get(UserPageInformationModel.IRI, params).subscribe(response => {
      this.items.next(response['hydra:member']);
    });
  }

  async save(item: UserPageInformationModel): Promise<any> {
    return await this.http.post(UserPageInformationModel.IRI, item).then(response => {
      if (response !== undefined) {
        const items = this.items.getValue();
        items.push(response);
        this.items.next(items);
      }

      return response;
    });
  }

  async update(item: UserPageInformationModel): Promise<any> {
    return await this.http.put(`${UserPageInformationModel.IRI}/${item.id}`, item).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.items.next(null);
    this.items.unsubscribe();
  }
}
