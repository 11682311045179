<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div *ngIf="!loading">
  <div class="p-fluid">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <a  *ngIf="!copy"  class="p-button-text p-button-secondary p-text-left cursor-pointer" style="font-size: 13px; color: #607D8B; font-weight: bold" (click)="copy = true"><i class="pi pi-copy"></i> {{ 'COPY_VARIANT_OPTIONS'|translate }}</a>
        <p-autoComplete *ngIf="copy" field="name" [suggestions]="autoCompleteValues" (onSelect)="loadOptions($event)" [placeholder]="'PRODUCT_NAME'|translate" (completeMethod)="searchStock($event)"></p-autoComplete>
      </div>
    </div>
  </div>
  <div class="p-fluid">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label>{{'VARIANT_OPTION' | translate}}</label>
      </div>
      <div class="p-field p-col p-col-7">
        <label>{{'VARIANT_OPTION_VALUES' | translate}}</label>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid" *ngFor="let item of items; index as key">
      <div class="p-field p-col-4">
        <input  type="text" name="name" pInputText [(ngModel)]="item.name" autofocus>
      </div>
      <div class="p-field p-col p-col-7">
        <p-chips  [(ngModel)]="item.optionValues" field="name" separator=","   (onAdd)="addOption(key, $event)"></p-chips>
      </div>
      <div class="p-field p-col p-col-1">
        <button pButton pInputText iconPos="left" icon="pi pi-trash" class="p-button-text p-button-danger p-mb-1" (click)="remove(key, item.id)"></button>
      </div>
    </div>
  </div>
  <button pButton (click)="add()" class="p-button-text" label="{{'ADD' | translate}}"></button>
  <button pButton (click)="save()" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="submitted || !isValid()" label="{{'SAVE' | translate}}"></button>

</div>
