import {ApiResourceModel} from './api-resource.model';
import {PermissionModel} from './permission.model';

export class RoleModel extends ApiResourceModel{
  public static IRI = '/api/roles';
  id: number;
  public name: string;
  public sa1: boolean;
  public sa2: boolean;
  public sa3: boolean;
  public sa4: boolean;
  public sa5: boolean;
  public sa6: boolean;
  public de1: boolean;
  public de2: boolean;
  public de3: boolean;
  public de4: boolean;
  public in1: boolean;
  public in2: boolean;
  public in3: boolean;
  public in4: boolean;
  public in5: boolean;
  public in6: boolean;
  public in7: boolean;
  public ur1: boolean;
  public ur2: boolean;
  public ur3: boolean;
  public ku1: boolean;
  public ku2: boolean;
  public ra1: boolean;
  public ra2: boolean;
  public ra3: boolean;
  public ra4: boolean;
  public permissions: PermissionModel[] = [];
  public actions: any[];
}
