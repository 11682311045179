<div *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col-6">
      <h2>{{ variant?.stock?.name}} / {{ variant?.name}}</h2>
      <h3>{{ 'PRODUCT_RECIPE' | translate }}</h3>
    </div>
    <div class="p-col-6">
      <p-checkbox label="{{'WEIGHTED_AVERAGE' | translate}}" [ngModel]="aof " [binary]="true"
                  (click)="onChangeSsa()"></p-checkbox>
      <p-checkbox label="{{'LAST_PURCHASE' | translate}}" [ngModel]="ssa" [binary]="true"
                  (click)="onChangeAof()"></p-checkbox>
    </div>
  </div>
  <div *ngIf="fdh === false" class="p-grid grid-div">
    <div class="p-col div-sums">
      <span class="title">{{'SALE_PRICE'| translate}}</span>
      <span class="total-price">{{ variant?.salePrice | number: '1.2-2' }} <b
        class="currency">{{ variant.stock?.currency?.symbol}}</b></span>
    </div>
    <div class="p-col div-sums {{ variant?.profit < 0 ? 'alert-div' : ''}}">
      <span class="title">{{'COST'| translate}}</span>
      <span class="total-price">{{ variant?.totalCost |  number: '1.2-2'}} <b
        class="currency">{{ variant.stock?.currency?.symbol}}</b></span>
    </div>
    <div class="p-col div-sums {{ variant?.profit < 0 ? 'alert-div' : ''}}">
      <span class="title">{{'COST'| translate}} %</span>
      <span class="total-price">{{ (variant?.margin ? variant.margin : '') | number: '1.2-2'}}%</span>
    </div>
    <div class="p-col div-sums {{ variant?.profit < 0 ? 'alert-div' : ''}}">
      <span class="title">{{'PROFIT'| translate}}</span>
      <span class="total-price">{{ variant?.profit |  number: '1.2-2'}} <b
        class="currency">{{ variant.stock?.currency?.symbol}}</b></span>
    </div>

  </div>

  <div *ngIf="fdh === true" class="p-grid grid-div">
    <div class="p-col div-sums" style="background: #d9f2b0">
      <span class="title">{{'SALE_PRICE'| translate}}</span>
      <span class="total-price">
        <input type="number" min="1" autofocus value="{{ testPrice.salesPrice }}" style="border: none;
            font-size: 40px;
            width: 170px;
            text-align: right;
            padding: 0px;background: #d9f2b0;
            color: #626262;" (change)="onTestPriceCalculate($event)">
        <b class="currency">{{ variant.stock?.currency?.symbol}}</b>
      </span>
    </div>
    <div class="p-col div-sums">
      <span class="title">{{'COST'| translate}}</span>
      <span class="total-price">{{ testPrice.cost |  number: '1.2-2'}} <b
        class="currency">{{ variant.stock?.currency?.symbol}}</b></span>
    </div>
    <div class="p-col div-sums">
      <span class="title">{{'COST'| translate}} %</span>
      <span class="total-price">{{ testPrice.costRate | number: '1.2-2'}}%</span>
    </div>
    <div class="p-col div-sums">
      <span class="title">{{'PROFIT'| translate}}</span>
      <span class="total-price">{{ testPrice.profit |  number: '1.2-2'}} <b
        class="currency">{{ variant.stock?.currency?.symbol}}</b></span>
    </div>

  </div>
  <div class="p-grid">
    <div class="p-col-12" *ngIf="fdh === true">
      Not : Fiyat Dengesi Hesaplamada Sadece Bilgi Amaçlıdır.Ürünün Satış Fiyatı Değişmez
    </div>
    <div class="p-col-12" [style]="{    display: 'flex',alignItems: 'center'}">
      <p-checkbox label="Fiyat Dengesi Hesaplama" [ngModel]="fdh" [binary]="true" (click)="onChangeFdh()"></p-checkbox>
    </div>
  </div>
  <div class="p-grid grid-div" *ngIf="isSaveAvailable">
    <div class="p-col div-sums alert-div">
      <b>Uyarı!</b> Ürün Maliyeti Satış Fiyatından Büyük Olamaz Lütfen Giriş Bilgilerini Kontrol Ediniz
    </div>
  </div>
  <div class="p-grid" *ngIf="translateInput">
    <div class="p-col-4">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Toplam {{variant?.stock?.unit.name}}" [ngModel]="recipe.totalWeight">
        <button type="button" pButton pRipple icon="pi pi-refresh" styleClass="p-button-warn"
                (click)="translateQuantity(recipe.totalWeight)"></button>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2" [style]="{    display: 'flex',alignItems: 'center'}">
      {{'SERVING_SIZE' | translate}} :
    </div>
    <div class="p-col-4">
      <p-inputNumber [ngModel]="recipe.servingSize" (onBlur)="changeServingSize($event)"
                     step="1"></p-inputNumber>
    </div>
    <div class="p-col-2" [style]="{    display: 'flex',alignItems: 'center'}">
      {{'SERVING_SIZE_WEIGHT' | translate}} :
    </div>
    <div class="p-col-4">
      <b style="font-size: 27px;">{{recipe.totalWeight | number: '1.2-2'}} Kg</b>
    </div>
  </div>

  <!----Modifier ve Extralar Varsa---->
  <div class="p-grid p-mt-3" *ngIf="stepShow === true ">
    <div class="p-col">
      <p-steps [model]="stepItems" [readonly]="false" [(activeIndex)]="activeIndex"></p-steps>
    </div>

  </div>

  <div class="p-grid p-mt-3 alert-div" *ngIf="modifiersAlert === true ">
    <div class="p-col">
      Ürün'e Tanımlananan modifierlar'ın içeriği eksik
    </div>
  </div>
  <div class="p-grid p-mt-3 alert-div" *ngIf="extrasAlert === true ">
    <div class="p-col">
      Ürün'e Tanımlananan ektralar'ın içeriği eksik
    </div>
  </div>
  <router-outlet></router-outlet>

  <!----Modifier ve Extralar yoksa---->
  <div class="p-grid" *ngIf="recipe.variantRecipeItems.length === 0">
    <div class="p-col-12" [style]="{marginTop: '20px'}">
      <button  pButton label="{{'SEARCH_IN_COOK_BOOK' | translate}}" class="p-mb-3 add-button "
              [icon]="searchSameRecipeLoading ? 'pi pi-spin pi-spinner' : 'pi pi-search'"
              (click)="searchSameRecipeInCookBook(variant?.stock?.name)"></button>
    </div>
    <div class="p-col-12" *ngIf="sameRecipeInCookBook.length > 0">
      <p-table [value]="sameRecipeInCookBook" [tableStyle]="{'min-width': '50rem'}"
               [paginator]="true" [rows]="20" [totalRecords]="total"
               [rowsPerPageOptions]="[10,25,50]">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'5%'}"></th>
            <th [style]="{width:'50%'}">{{ 'NAME' | translate}}</th>
            <th [style]="{width:'15%'}" >{{ 'COST' | translate}}</th>
            <th [style]="{width:'17%'}" >{{ 'DETAIL' | translate}}</th>
            <th [style]="{width:'20%'}" >{{ 'ACTION' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cookBook let-i="rowIndex">
          <tr>
            <td>{{i+1}}</td>
            <td>{{cookBook.name}}</td>
            <td [style]="{textAlign:'right'}">{{cookBook.totalCost | currency: '₺'}}</td>
            <td>
              <button type="button" pButton pRipple icon="pi pi-refresh" label="{{'SHOW_RECIPE_DETAIL' | translate}}"
                        styleClass="p-button-warn" (click)="showRecipeItems(cookBook)">
              </button>
            </td>
            <td><button type="button" pButton pRipple icon="pi pi-refresh"  label="{{'USE_THIS_COOK_BOOK_RECIPE' | translate}}"
                        styleClass="p-button-warn" (click)="setThisCookBook(cookBook)"></button></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <button pButton pRipple type="button" label="{{'RECIPE'| translate }}" (click)="onRecipeOrPackOption('recipe')" class="p-button-rounded"></button>
      <button pButton pRipple type="button" label="{{'PACK_OPTIONS'| translate }}" (click)="onRecipeOrPackOption('pack')" class="p-button-rounded"></button>
    </div>
    <div class="p-col-12" [style]="{marginTop: '20px'}" *ngIf="recipeOrPackOption">
      <p-table [value]="recipe.variantRecipeItems" dataKey="id" styleClass="p-datatable-sm" [loading]="submitted">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'12%'}" scope="col" *ngIf="activeIndex === 0">{{ 'SKU' | translate}} </th>
            <th [style]="{width:'25%'}" scope="col" *ngIf="activeIndex === 1">{{ 'MODIFIER' | translate}} </th>
            <th [style]="{width:'25%'}" scope="col" *ngIf="activeIndex === 2">{{ 'EXTRAS' | translate}} </th>
            <th [style]="{width:'30%'}" scope="col">{{ 'STOCK' | translate}}</th>
            <th [style]="{width:'10%'}" scope="col">{{ 'RECIPE_AMOUNT' | translate}} </th>
            <th [style]="{width:'10%'}" scope="col"
                *ngIf="recipe.servingSize > 1">{{ 'SERVING_SIZE_AMOUNT' | translate}} </th>
            <th [style]="{width:'10%'}" scope="col">{{ 'WASTAGE' | translate}}</th>
            <th [style]="{width:'12%'}" scope="col">{{ 'AMOUNT_WITH_WASTAGE' | translate}}</th>
            <th [style]="{width:'10%'}" scope="col">{{ 'PRICE' | translate}}</th>
            <th [style]="{width:'10%'}" scope="col">{{ 'COST' | translate}}</th>
            <th [style]="{width:'5%'}" scope="col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr [ngClass]="checkPriceDifference(i)" *ngIf="(
          activeIndex === 0 && item.type === 'MAIN') ||
          (activeIndex === 1 && item.type === 'MODIFIER') ||
          (activeIndex === 2 && item.type === 'EXTRA')">
            <td *ngIf="activeIndex === 0">

            </td>
            <td *ngIf="activeIndex === 1">
              {{ item.modifierItem?.name}} {{ item.modifierItem?.id}}
            </td>
            <td *ngIf="activeIndex === 2">
              {{ item.modifierItem?.name}} {{ item.modifierItem?.id}}
            </td>
            <td pEditableColumn class="{{ hasErrors('stock', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete appendTo="body" [(ngModel)]="autoCompleteValues[i]"
                                  (onSelect)="onVariantSelect($event, i)" field="name" [suggestions]="variants"
                                  (completeMethod)="searchVariant($event)"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ autoCompleteValues[i]?.name }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="{{ hasErrors('amount', i) ? 'has-error': '' }}" *ngIf="recipe.servingSize > 1">

              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="3" [(ngModel)]="item.bigAmount"
                                 *ngIf="activeIndex !== 2"
                                 (ngModelChange)="
                                 item.amountWithWastage = calculateAmountWithWastage(item.bigAmount/recipe.servingSize, item.wastage, i);
                                 item.amount = calculateAmountWithWastage(item.bigAmount/recipe.servingSize, item.wastage, i);
                                 item.cost = calculateCost( getAveragePrice(item) , item.amountWithWastage, autoCompleteValues[i].totalCost, autoCompleteValues[i].stock?.isMaking);">
                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.bigAmount | number: '1.2-3' }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign: 'right'}" pEditableColumn class="{{ hasErrors('amount', i) ? 'has-error': '' }}">

              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="3" [(ngModel)]="item.amount"
                                 *ngIf="activeIndex !== 2"
                                 (ngModelChange)=" item.amountWithWastage = calculateAmountWithWastage(item.amount, item.wastage, i);
                                 item.cost = calculateCost( getAveragePrice(item) , item.amountWithWastage, autoCompleteValues[i].totalCost, autoCompleteValues[i].stock?.isMaking);">
                  </p-inputNumber>

                  <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="3" [(ngModel)]="item.amount"
                                 *ngIf="activeIndex === 2">
                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.amount | number: '1.2-3' }} {{ autoCompleteValues[i]?.stock?.unit?.name }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td  [style]="{textAlign: 'right'}" pEditableColumn class="{{ hasErrors('wastage', i) ? 'has-error': '' }}">

              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber prefix="%" mode="decimal" maxFractionDigits="5" minFractionDigits="3"
                                 [(ngModel)]="item.wastage"
                                 (ngModelChange)="item.amountWithWastage = calculateAmountWithWastage(item.amount, item.wastage, i);item.cost = calculateCost(item.stock.stockQuantities[0].averagePrice, item.amountWithWastage, autoCompleteValues[i].totalCost, autoCompleteValues[i].stock?.isMaking);">

                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  %{{ item.wastage }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign: 'right'}">
              {{ item.amountWithWastage | number: '1.2-3' }} {{ autoCompleteValues[i]?.stock?.unit?.name }}
            </td>
            <td [style]="{textAlign: 'right'}">
              {{ (item.cost / item.amountWithWastage) | number: '1.2-2'}} {{ currencies[i] ? currencies[i] : currency }}
            </td>
            <td [style]="{textAlign: 'right'}">
            <span class="danger-cell">
              {{ item.cost| number: '1.2-2'}} {{ currencies[i] ? currencies[i] : currency }}
              {{ rates[i] ? '(' + rates[i] + ')' : '' }}
            </span>
            </td>
            <td>
              <i class="pi pi-trash" *ngIf="activeIndex === 0" [style]="{ color: '#ff2525'}"
                 (click)="removeItem(i)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-12" [style]="{marginTop: '20px'}" *ngIf="!recipeOrPackOption && packStocks !== undefined" >
      <p-table [value]="packStocks" dataKey="id" styleClass="p-datatable-sm" [loading]="submitted">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'80%'}" scope="col">{{ 'STOCK' | translate}}</th>
            <th [style]="{width:'15%'}" scope="col">{{ 'QUANTITY' | translate}}</th>
            <th [style]="{width:'5%'}" scope="col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td pEditableColumn class="{{ hasErrors('stock', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete appendTo="body" [(ngModel)]="autoCompletePackValues[i]"
                                  (onSelect)="onPackVariantSelect($event, i)" field="name" [suggestions]="variants"
                                  (completeMethod)="searchVariant($event)"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ autoCompletePackValues[i].name }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="{{ hasErrors('amount', i) ? 'has-error': '' }}">

              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="3" min="0.001" [(ngModel)]="item.quantity"
                                 (ngModelChange)="changePackQuantity($event , i)">
                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.quantity | number: '1.2-3' }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <i class="pi pi-trash" [style]="{ color: '#ff2525'}"
                 (click)="removePackItem(i)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="p-fluid p-pt-3 p-mb-3">
    <div class="p-grid p-mt-3 alert-div" *ngIf="ssa === true ">
      <div class="p-col">
        Son Satın Alma Fiyatı Sadece Bilgi Amaçlıdır.Kayıt Yapamazsınız
      </div>
    </div>
  </div>
  <button *ngIf="activeIndex === 0 && !recipeOrPackOption" pButton label="{{'ADD_STOCK' | translate}}" class="p-mb-3 add-button "
          [icon]="'pi pi-plus'"
          (click)="addPackItem()">

  </button><button *ngIf="activeIndex === 0 && recipeOrPackOption" pButton label="{{'ADD_STOCK' | translate}}" class="p-mb-3 add-button "
          [icon]="'pi pi-plus'"
          (click)="addItem()"></button>
  <button pButton label="{{'SAVE' | translate}}" class="p-mb-3"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]=" ssa === true || this.submitted || modifiersAlert || extrasAlert || isAddStockAvailable"
          (click)="save()"></button>
  <button *ngIf="activeIndex === 0 && recipeOrPackOption" pButton label="{{'SAME_PRODUCT' | translate}}" [style]="{float: 'right'}"
          class="p-mb-3 add-button "
          [icon]="sameProductTableLoading ? 'pi pi-spin pi-spinner' : 'pi pi-eye'"
          (click)="sameProduct()"></button>

  <div class="p-grid grid-div" *ngIf="sameShowError">
    <div class="p-col div-sums alert-div">
      <b>Uyarı!</b> Benzer Ürünleri Görmek İçin Lütfen Reçeteye Bir Ürün Ekleyin ve Kaydedin.
    </div>
  </div>
  <div class="p-grid grid-div" *ngIf="sameProductAlert">
    <div class="p-col div-sums alert-div">
      <b>Uyarı!</b> Benzer Ürün Bulunamadı
    </div>
  </div>
  <div class="p-grid" [style]="{marginTop: '20px'}" *ngIf="sameProductValues.length > 0">
    <div *ngIf="recipe.variantRecipeItems.length !== 0">
      <div class="p-grid grid-div p-mb-3">
        <div class="p-col div-sums info-div" style="padding: 10px">
          <b>Hatırlatma !</b> Bu Ürünün Reçetesini Aşağıdaki Listede Bulunan Reçetesi Olmayan Seçtiğiniz Ürünlere
          de Aynı Reçeteyi Ekleyebilirsiniz.
        </div>
      </div>
      <p-table [value]="sameProductValues" [loading]="sameProductTableLoading" [(selection)]="selectedProducts"
               dataKey="id" styleClass="p-datatable-sm" selectionMode="multiple" (onRowSelect)="onRowSelect($event)"
               (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem" scope="col">
              <p-tableHeaderCheckbox (click)="selectAll($event)"></p-tableHeaderCheckbox>
            </th>
            <th [style]="{width: '20%'}" scope="col">{{'CODE' | translate}}</th>
            <th [style]="{width: '50%'}" scope="col">{{'NAME' | translate}}</th>
            <th [style]="{width: '20%'}" scope="col">{{'LOCATION' | translate}}</th>
            <th [style]="{width: '20%'}" scope="col">{{'SALE_PRICE' | translate}}</th>
            <th [style]="{width: '10%'}" scope="col">{{'RECIPES' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td>
              <p-tableCheckbox [value]="product"></p-tableCheckbox>
            </td>
            <td>{{product.code}} {{product.id}}</td>
            <td>{{product.stock?.name}} / {{product.name}}</td>
            <td>{{ getLocationName(product.stock.locations[0])}}</td>
            <td>{{product.salePrice | currency: product.stock.currency.symbol}}</td>
            <td>{{ product.variantRecipes?.length > 0 ? 'Var' : 'Yok' }}</td>
          </tr>
        </ng-template>
      </p-table>
      <button pButton label="{{'SAVE' | translate}}" class="p-mb-3 p-mt-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              (click)="saveSameProduct()"></button>
    </div>
  </div>
</div>
<p-sidebar [(visible)]="preparationShow" [style]="{width: '50vw'}" position="right" [baseZIndex]="1"
           [autoZIndex]="false">
  <div class="p-grid">
    <div class="p-col-4">
      {{ 'RECIPE_PREPARATION_ITEM' | translate}}
    </div>
    <div class="p-col-8">
      <p-autoComplete appendTo="body" field="name" [suggestions]="preparations"
                      (completeMethod)="searchPreparation($event)">
      </p-autoComplete>
    </div>
  </div>
  <div class="p-grid">

    <div class="p-col-4">
      {{ 'QUANTITY' | translate}}
    </div>
    <div class="p-col-8">
      <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="2"
                     [(ngModel)]="preparationQuantity"
                     (ngModelChange)="onPreparationCalculate()">

      </p-inputNumber>
    </div>
  </div>

  <div class="p-grid p-mt-3 p-mb-3" *ngIf="preparationQuantity > 0">
    <p-table [value]="preparations[0]?.variantRecipes[0]?.variantRecipeItems" dataKey="id" styleClass="p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width:'10%'}" scope="col">{{ 'SKU' | translate}} </th>
          <th [style]="{width:'30%'}" scope="col">{{ 'STOCK' | translate}}
            <app-tooltip description="PRODUCT_RECIPE_ITEM_TOOLTIP"></app-tooltip>
          </th>
          <th [style]="{width:'10%'}" scope="col">{{ 'AMOUNT' | translate}}
            <app-tooltip description="PRODUCT_RECIPE_QUANTITY_TOOLTIP"></app-tooltip>
          </th>
          <th [style]="{width:'10%'}" scope="col">{{ 'WASTAGE' | translate}}
            <app-tooltip description="PRODUCT_RECIPE_WASTAGE_TOOLTIP"></app-tooltip>
          </th>
          <th [style]="{width:'12%'}" scope="col">{{ 'AMOUNT_WITH_WASTAGE' | translate}}
            <app-tooltip description="PRODUCT_RECIPE_AMOUNT_WASTAGE_TOOLTIP"></app-tooltip>
          </th>
          <th [style]="{width:'5%'}" scope="col">{{ 'UNIT' | translate}}</th>
          <th [style]="{width:'10%'}" scope="col">{{ 'COST' | translate}}
            <app-tooltip description="PRODUCT_RECIPE_COST_TOOLTIP"></app-tooltip>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-itemPreparation let-i="rowIndex">
        <tr>
          <td>
            {{ itemPreparation.variant.code}}
          </td>
          <td class="{{ hasErrors('stock', i) ? 'has-error': '' }}">
            {{ itemPreparation.variant.stock.name}}
          </td>
          <td class="{{ hasErrors('amount', i) ? 'has-error': '' }}">
            {{ itemPreparation.amount | number: '1.2-2' }}
          </td>
          <td class="{{ hasErrors('wastage', i) ? 'has-error': '' }}">
            % {{ itemPreparation.wastage }}
          </td>
          <td>
            {{ itemPreparation.amountWithWastage | number: '1.2-2' }}
          </td>
          <td>
            {{ autoCompleteValues[i]?.stock?.unit?.name }}
          </td>
          <td>
            <span class="danger-cell">
              {{ itemPreparation.cost|currency: (currencies[i] ? currencies[i] : '₺'): '' }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <hr>
  <button pButton label="{{'ADD' | translate}}" class="p-mb-3"
          [icon]="'pi pi-arrow-left'"
          [disabled]="!preparationQuantity || isAdded == false" (click)="addPreparationToRecipe()"></button>
</p-sidebar>

<p-dialog [(visible)]="cookbookDetailDisplay" [style]="{width: '50vw'}">
  <div class="p-grid">
    <div class="p-col-12">
      <h3>{{selectedCookBook?.name}}</h3>
    </div>
    <div class="p-col-12" [style]="{marginTop: '20px'}">
      <p-table [value]="selectedCookBook?.items" styleClass="p-datatable-sm" >
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'40%'}" scope="col">{{ 'STOCK' | translate}}</th>
            <th [style]="{width:'10%'}" scope="col">{{ 'PRICE' | translate}}</th>
            <th [style]="{width:'10%'}" scope="col">{{ 'INIT' | translate}}</th>
            <th [style]="{width:'15%'}" scope="col">{{ 'RECIPE_AMOUNT' | translate}} </th>
            <th [style]="{width:'10%'}" scope="col">{{ 'COST' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td>{{item.cookBookStock.name}}</td>
            <td [style]="{textAlign:'right'}">{{item.cookBookStock.price | currency : '₺'}}</td>
            <td [style]="{textAlign:'right'}">{{item.cookBookStock.unit}}</td>
            <td [style]="{textAlign:'right'}">{{ item.amount | number: '1.2-3' }}</td>
            <td [style]="{textAlign: 'right'}">
              {{ item.totalCost| number: '1.2-2'}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>
