import { Injectable } from '@angular/core';
import {CurrencyModel} from '../model/currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor() { }

  getCurrency(): CurrencyModel {
    let currency = JSON.parse(localStorage.getItem('currency'));

    if (!currency) {
      currency = new CurrencyModel();
      currency.code = 'TRY';
      currency.symbol = '₺';
    }

    return currency;
  }
}
