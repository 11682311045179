<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card *ngIf="loading === false">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6 flex">
      <a routerLink="/orders/incoming-invoice-list">
        <p-button icon="pi pi-arrow-left"></p-button>
      </a>
      <h3 [style]="{ fontWeight: '600' }">{{ 'CREATE_PURCHASE' | translate }}</h3>

    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEMS'"></app-page-info>
      <p-button styleClass="p-button-secondary p-d-inline-block"
                [style]="{'float': 'right' }" [disabled]="isSaveAble"
                label="{{'SAVE' | translate}}" [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
                (click)="save()">
      </p-button>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3">
    <div class="p-col div-sums">
      <table>
        <tr>
          <td [style]="{width: '30%'}"><b
            class="fw-500">{{ 'ORDER_NUMBER'|translate}}</b>
          </td>
          <td [style]="{width: '1%'}">:</td>
          <td [style]="{width: '70%'}">
            <input [class.border-0]="true" type="text" pInputText
                   [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                   [(ngModel)]="invoice.code" [disabled]="invoice.isReceived">
            <small class="p-error">{{ errors['code'] }}</small>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'LOCATION'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-dropdown [ngModel]="invoice.location" [options]="locations"
                        placeholder="{{ 'SELECT_LOCATION' | translate}}"
                        [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                        appendTo="body"
                        name="location" optionLabel="name" optionValue="@id"
                        (onChange)="changeLocation($event)"></p-dropdown>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'SUPPLIER'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-dropdown [ngModel]="invoice.contact" [options]="searchSuppliers"
                        placeholder="{{ 'SELECT_SUPPLIER' | translate}}"
                        [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                        appendTo="body"
                        [filter]="true" filterBy="name"
                        name="contact" optionLabel="name" optionValue="@id"
                        (onChange)="changeSupplier($event)"></p-dropdown>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'DELIVERY_TIME'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-calendar [(ngModel)]="shipment.documentDate"
                        [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                        [disabled]="invoice.isReceived">
            </p-calendar>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500"> {{ 'ITEM_COUNT' | translate}}</b></td>
          <td>:</td>
          <td>{{ invoice.items.length }}</td>
        </tr>
        <tr>
          <td><b class="fw-500"> {{ 'TOTAL_EXL_TAX' | translate}}</b></td>
          <td>:</td>
          <td [style]="{textAlign:'right',fontSize:'35px'}">{{ invoice.total | currency: currencySymbol }}</td>
        </tr>
      </table>
    </div>
    <div class="p-col div-sums">
      <div class="inside-div">
        <img class="inside-img" src="assets/img/truck-delivery.png"/>
        <table>
          <tr>
            <td [style]="{width: '30%'}"><b class="fw-500">{{'SUPPLIER' | translate}}:</b></td>
            <td [style]="{width: '1%'}">:</td>
            <td [style]="{width: '70%'}"><b class="fw-500"></b> {{ invoiceContact.name}}</td>
          </tr>
          <tr>
            <td><b class="fw-500">{{'TAX_AGENCY_NUMBER' | translate}}:</b></td>
            <td>:</td>
            <td><b class="fw-500"></b> {{ invoiceContact.taxAgencyNumber}}</td>
          </tr>
          <tr>
            <td><b class="fw-500">{{'SUPPLIER_EMAIL' | translate}}</b></td>
            <td>:</td>
            <td>{{ invoiceContact.email }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3" >
    <div class="p-col div-sums alert-div" *ngIf="isSaveAble">
      <b>Fatura İçeriğinde Stoklarlarla Eşlenmemiş Satırlar Bulunmaktadır. </b>
    </div>
  </div>

  <div class="p-grid grid-div p-mt-3 p-mb-3" >
    <p-button label="{{ 'AUTO_SYNC' | translate}}" (onClick)="autoSync()"></p-button>
  </div>
  <div class="p-grid p-mt-3">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="invoice.items" dataKey="id" responsiveLayout="scroll" [loading]="tableLoading"
               styleClass="p-datatable-striped p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '25%'}">{{ 'CODE' | translate}}</th>
            <th scope="col" [style]="{width: '25%'}">{{ 'STOCK' | translate}}</th>
            <th scope="col" [style]="{width: '12%'}">{{ 'UNIT' | translate}}</th>
            <th scope="col" [style]="{width: '5%'}">{{ 'STOCK_TAX' | translate}}</th>
            <th scope="col" [style]="{width: '5%'}">{{ 'INVOICE_TAX' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">{{ 'UNIT_PRICE' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">{{ 'DISCOUNT' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}">{{ 'TOTAL_PRICE' | translate}}</th>
            <th scope="col" [style]="{width: '5%'}"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td>{{ item.name}} / {{ item.description}}</td>
            <td *ngIf="item.variant === null" pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete [(ngModel)]="autoCompleteValues[i]"
                                  (onSelect)="onVariantSelect($event, i, item.name)"
                                  field="name" [suggestions]="variants" appendTo="body"
                                  (completeMethod)="searchVariant($event)"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.variant?.name}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td *ngIf="item.variant !== null">
              <i class="pi pi-refresh" [style]="{color:'#14c6cc',fontSize: '10px',marginRight: '10px'}" (click)="removeVariant(i)"></i> {{ item.variant.name}}
            </td>
            <td >
              <p-dropdown [options]="item.optionsValue" [ngModel]="item.purchaseOption" *ngIf="item.variant !== null" class="p-inputtext-sm"
                          placeholder="{{ 'SELECT_UNIT' | translate }}"
                          optionValue="@id" optionLabel="unit.name" appendTo="body" (onChange)="test($event, i, item)"></p-dropdown>
            </td>
            <td [style]="{textAlign:'right'}">% {{ item.stock?.tax?.rate}}</td>
            <td [style]="{textAlign:'right'}">% {{ item.itemTaxRate }}</td>
            <td [style]="{textAlign:'right'}">{{ item.quantity | number : '1.2-2'}}</td>
            <td [style]="{textAlign:'right'}">{{ item.unitPrice | currency : currencySymbol}}</td>
            <td [style]="{textAlign:'right'}">{{ item.discount | currency : currencySymbol}}</td>
            <td [style]="{textAlign:'right'}">{{ item.subtotal| currency : currencySymbol}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
  <div class="p-grid p-mt-3">
    <div class="p-col-7">
          <textarea pInputTextarea style="width: 100%" placeholder="{{'COMMENT' | translate}}"
                    [style]="{minHeight:'120px'}"></textarea><br>
    </div>
    <div class="p-col-5">
      <div class="tableDiv-content">
        {{'PRICING_SECTION'| translate}} <span [style]="{float:'right'}">{{'SUM' |translate}}</span>
        <div class="tableDiv-sub" [style]="{marginTop:'10px'}">
          {{'TOTAL'| translate}} <span
          [style]="{float:'right'}">{{ this.invoice.subtotal | currency: currencySymbol}}</span>
        </div>
        <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
          {{'DISCOUNT'| translate}} %
          <span [style]="{float:'right'}">
                <input name="discountRateValue" [(ngModel)]="invoice.discountRate"
                       [style]="{height:'30px',width: '100px',border: 'none',background: '#f8f9fa'}">
               </span>
        </div>
        <div class="tableDiv-sub" [style]="{marginTop:'3px',display:'none'}">
          {{'DISCOUNT'| translate}} TL <span [style]="{float:'right'}">
              <input name="discountValue" [(ngModel)]="discountValue"
                     [style]="{height:'30px', width:'100px',border: 'none',background: '#f8f9fa'}">
            </span>
        </div>
        <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
          {{'DISCOUNT_TOTAL'| translate}} <span
          [style]="{float:'right'}">{{ invoice.discount | currency: currencySymbol}}</span>
        </div>
        <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
          {{'SUBTOTAL'| translate}} <span
          [style]="{float:'right'}">{{ invoice.subtotal | currency: currencySymbol}}</span>
        </div>

        <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
          {{'TAX'| translate}} <span
          [style]="{float:'right'}">{{ invoice.tax | currency: currencySymbol}}</span>
        </div>
        <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
          {{'GRAND_TOTAL'| translate}} <span
          [style]="{float:'right'}">{{ invoice.total | currency: currencySymbol}}</span>
        </div>
      </div>
    </div>
  </div>
</p-card>

<p-sidebar position="right" [(visible)]="newMaterialDialog" [style]="{width:'80em'}">
  <app-new-material-create *ngIf="newMaterialDialog === true" [type]="'material'"
                           [item]="item"></app-new-material-create>
</p-sidebar>

