import {ApiResourceModel} from './api-resource.model';
import {PermissionModel} from './permission.model';
import {OperationModel} from './operation.model';
import {LocationModel} from './location.model';
import {RoleModel} from './role.model';

export class UserModel extends ApiResourceModel {
  public static IRI = '/api/users';
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  roles: string[] = [];
  permissions: PermissionModel[] = [];
  operations: OperationModel[] = [];
  language: string;
  dataTableSettings = [];
  locations: LocationModel[] = [];
  id: number;
  roleType: RoleModel[] = [];
  approval: boolean;
}
