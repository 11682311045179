import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {UnitModel} from './unit.model';
import {StockVariantModel} from './stock-variant.model';
import {StockQuantityModel} from './stock-quantity.model';

export class StockTransferItemModel  extends ApiResourceModel{
  public static IRI = '/api/stock_transfer_items';
  public stock: StockModel | string;
  public variant: StockVariantModel;
  public originStock: number;
  public transferQuantity: number;
  public destinationStock: number;
  public price: number;
  public unit: UnitModel| string;
  public quantity: number| null;
  public total: number;
  public stockVariant: StockVariantModel;
  public originLocation: string;
  public destinationLocation: string;
  public originStockFirst: number;
  public destinationStockFirst: number;
  public originStockQuantity: StockQuantityModel | string;
  public destinationStockQuantity: StockQuantityModel | string;
  public description: string;
  public symbol: string;
}
