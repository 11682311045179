import { Component, OnInit } from '@angular/core';
import {UnitModel} from '../../../../model/unit.model';
import {UnitService} from '../../../../service/unit.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {SectorModel} from '../../../../model/sector.model';
import {SectorService} from '../../../../service/sector.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-sector',
  templateUrl: './create-sector.component.html',
  styleUrls: ['./create-sector.component.scss']
})
export class CreateSectorComponent implements OnInit {

  item: SectorModel = new SectorModel();
  submitted: boolean;
  errors = [];

  constructor(private sectorService: SectorService,
              private flashMessageService: FlashMessageService,
              private ref: DynamicDialogRef,
              private validationService: ValidationService,
              private config: DynamicDialogConfig,
              private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.config.data?.item) {
      this.item = this.config.data.item;
    }

    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;

    if (this.item.id) {
      await this.sectorService.update(this.item).then(response => {
        if (response !== undefined) {
          this.sectorService.getSectorsAction({isActive: true});
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    } else {
      await this.sectorService.save(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }

    this.submitted = false;
  }

  close(): void {
    this.ref.close();
  }

}
