<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'INVENTORY_ITEMS' | translate }}</h3>

    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEMS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" (onClick)="createExcel()"
                [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <a target="_blank" href="{{apiUrl + '/api/stocks/pdf'}}">
        <p-button icon="pi pi-file-pdf" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      </a>
      <button pButton pRipple type="button" label="{{ 'NEW' | translate}}"
              (click)="openForm()" [style]="{marginLeft: '5px', float:'right'}">
      </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none',height:'45px', borderBottom: '2px solid #47bfd4'}"
                  [(ngModel)]="parameters['stock.category.id']" [filter]="true" filterBy="name"
                  placeholder="{{'SELECT_CATEGORY' | translate}}" (onChange)="parameters['stock.category.id'] = $event.value; loadSearchDataFilter()"
                  [options]="categories" optionLabel="name" optionValue="id" appendTo="body">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none',height:'45px', borderBottom: '2px solid #47bfd4'}"
                  [(ngModel)]="parameters['stock.supplier.id']" [filter]="true" filterBy="name"
                  placeholder="{{ 'SELECT_SUPPLIER' | translate }}" (onChange)="parameters['stock.supplier.id'] = $event.value; loadSearchDataFilter()"
                  [options]="searchSuppliers" optionLabel="name" optionValue="id" appendTo="body" >
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none',height:'45px', borderBottom: '2px solid #47bfd4'}"
                  [(ngModel)]="parameters['stock.locations.id']"
                  (onChange)="changeParameters($event,'locations')" [options]="locations"
                  optionLabel="name" optionValue="id" appendTo="body">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <input [style]="{width: '100%',height:'45px',  border:'none', borderBottom: '2px solid #47bfd4'}"
             type="text" pInputText [(ngModel)]="parameters['stock.name']" name="name"
             (change)="changeParameters($event,'name')"
             placeholder="{{ 'SEARCH_EG_NAME_CODE' | translate}}"/>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2 active-switch">
      <div><p-inputSwitch [(ngModel)]="stockIsActive" (onChange)="stockIsActive ? 0: 1 ; load()"></p-inputSwitch></div>
      <div class="active-switch-text">{{( stockIsActive ? 'ACTIVE': 'PASSIVE') | translate}}</div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" [style]="{padding: '20px', marginBottom:'20px'}"
                 text="{{'STOCK_IMPORT_REMAINING'| translate }}">
      </p-message>
      <p-table [loading]="tableLoading" [resizableColumns]="true" [rowsPerPageOptions]="[10,25,50]"
               [lazy]="true" (onLazyLoad)="load($event)"
               [rows]="20" [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
               [value]="products" [scrollable]="true"
               styleClass="p-datatable-striped p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'6%'}"  pSortableColumn="code">
              <p-sortIcon field="code"></p-sortIcon>
              {{'SKU' | translate}}
              <app-tooltip description="PRODUCTS_SKU_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col" [style]="{width:'25%'}"  pSortableColumn="stock.name">
              <p-sortIcon field="stock.name"></p-sortIcon>
              {{'STOCK' | translate}}
              <app-tooltip description="PRODUCTS_STOCK_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col" pSortableColumn="stock.category.id">
              <p-sortIcon field="stock.category.id"></p-sortIcon>
              {{'CATEGORY' | translate}}
              <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col" pSortableColumn="stock.supplier">{{'SUPPLIER' | translate}}
              <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col"  pSortableColumn="stock.tax">{{'TAX' | translate}}
              <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col"  pSortableColumn="price">
              <p-sortIcon field="variants.price"></p-sortIcon>
              {{ 'PRICE'|translate }}
              <app-tooltip description="PRODUCTS_PRICE_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col" pSortableColumn="minOrder">
              <p-sortIcon field="variants.minOrder"></p-sortIcon>
              {{'MIN_ORDER' | translate}}
              <app-tooltip description="MIN_ORDER_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col"  pSortableColumn="amount">
              <p-sortIcon field="variants.amount"></p-sortIcon>
              {{'IN_STOCK' | translate}}
              <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col">{{'IN_VALUE' | translate}}
              <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip>
            </th>
            <th scope="col"  [style]="{width:'3%',padding:'0px'}"></th>
            <!--<th scope="col" [style]="{width: '10%'}" >{{'LAST_COUNT' | translate}} <app-tooltip description="LAST_COUNT_TOOLTIP"></app-tooltip></th>-->

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td [style]="{width:'6%'}">{{ row.code }}</td>
            <td [style]="{width:'25%'}">
              <a href="javascript:void(0)" [style]="{color:'#10bee5'}" (click)="edit(row.stock.id)"><i
              class="pi pi-pencil" [style]="{fontSize: '10px',marginRight: '10px'}"></i>
              </a>
              {{ row.stock.name }}
            </td>
            <td>{{ row.stock.category?.parent?.name }} {{ row.stock.category?.parent ? '/' : '' }} {{ row.stock.category?.name }}</td>
            <td>{{ row.purchaseOptions?.length > 0 ? 'Multi' : row.stock.supplier?.name  }}</td>
            <td [style]="{textAlign: 'center'}">%{{ row.stock.tax?.rate  }}</td>
            <td [style]="{textAlign: 'right'}">
              <div *ngFor=" let qt of row.stock.stockQuantities">
                  <span *ngIf="qt.location === '/api/locations/' + parameters.location">
                    {{ qt.lastPurchasePrice | currency: row.stock.currency.symbol: 'symbol':'1.2-4'}}
                    <app-tooltip description="LAST_PURCHASE_PRICE_DESCRIPTION"></app-tooltip> &nbsp;
                  </span>
              </div>

              <br>
              <div *ngFor=" let qt of row.stock.stockQuantities">
                  <span *ngIf="qt.location === '/api/locations/' + parameters.location">
                    {{ qt.averagePrice | currency: row.stock.currency.symbol: 'symbol':'1.2-4'}}
                    <app-tooltip description="AVERAGE_PRICE_DESCRIPTION" [style]="{color: '#55c416'}"></app-tooltip> &nbsp;
                  </span>
              </div>
            </td>
            <td [style]="{textAlign: 'right'}">{{ row.minOrder }} {{ row.stock.unit?.name }}</td>
            <td [style]="{textAlign: 'right'}">
              <i class="pi pi-map-marker" [style]="{float: 'left'}"
                 (click)="showLocationQuantity(row)"></i>
              <div *ngFor=" let qt of row.stock.stockQuantities">
                  <span *ngIf="qt.location === '/api/locations/' + parameters.location">
                    {{ qt.amount | number : '1.2-3' }} {{ row.stock.unit?.name }}
                  </span>
              </div>
            </td>
            <td [style]="{textAlign: 'right'}">
              <div *ngFor=" let qt of row.stock.stockQuantities">
                  <span *ngIf="qt.location === '/api/locations/' + parameters.location">
                    {{ qt.valueInStock |currency: row.stock.currency.symbol: 'symbol':'1.2-4'}}
                  </span>
              </div>
            </td>
            <td [style]="{textAlign: 'right',width:'3%'}">
              <i class="pi pi-book" *ngIf="row.stock?.isMaking === true" [style]="{fontSize: '1.2rem', color:'#10bee5'}" style="font-size: 2rem" (click)="onLoadRecipes(row.id)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="sum-div">
    <p-table [loading]="loading" [lazy]="true" [style]="{marginTop:'10px'}"
             styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" [style]="{width: '8%'}"></th>
          <th scope="col" [style]="{width: '30%'}"></th>
          <th scope="col" [style]="{width: '20%'}"></th>
          <th scope="col" [style]="{width: '15%'}"></th>
          <th scope="col" [style]="{width: '5%'}"></th>
          <th scope="col" [style]="{width: '10%'}"></th>
          <th scope="col" [style]="{width: '10%'}"></th>
          <th scope="col"
              [style]="{width: '10%',textAlign: 'right',fontSize:'14px'}">{{sumInStock | number: '1.2-2'}}</th>
          <th scope="col"
              [style]="{width: '10%',textAlign: 'right',fontSize:'14px'}">{{sumValueInStock | number: '1.2-2'}}</th>
          <!--<th scope="col" [style]="{width: '10%'}" >{{'LAST_COUNT' | translate}} <app-tooltip description="LAST_COUNT_TOOLTIP"></app-tooltip></th>-->

        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>
<p-confirmPopup></p-confirmPopup>
<p-sidebar (onHide)="reload()"  [(visible)]="display" [style]="{width:'90em'}" position="right">
  <!--- <app-new-material-create *ngIf="displayCreate" [type]="'material'" [item]="item" ></app-new-material-create>--->
  <app-inventory-item-create *ngIf="displayCreate && display" [type]="'material'" [item]="item"></app-inventory-item-create>
</p-sidebar>

<p-sidebar [(visible)]="showQuantitiesDialog" position="right" [style]="{width:'60em'}">
  <h3>Depolar ve Stock Miktarları</h3>
  <hr>
  <p-table *ngIf="showQuantitiesDialog" [value]="quantities['stockQuantities']"
           styleClass="p-datatable-striped" columnResizeMode="expand">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" [style]="{width:'40%'}"></th>
        <th scope="col" [style]="{textAlign:'right',width:'20%'}">{{ 'IN_STOCK' | translate}}</th>
        <th scope="col" [style]="{textAlign:'right',width:'20%'}">{{ 'PRICE' | translate}}</th>
        <th scope="col" [style]="{textAlign:'right',width:'20%'}">{{ 'VALUE' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ getLocationName(item.location)}}</td>
        <td
          [style]="{textAlign:'right', padding:'0px'}">{{ item.amount| number: '1.2-2'}} {{ quantities.unit?.name}}</td>
        <td
          [style]="{textAlign:'right', padding:'0px'}">{{ item.averagePrice | currency: quantities.currency?.symbol}}</td>
        <td
          [style]="{textAlign:'right', padding:'0px'}">{{ item.amount * item.averagePrice | currency: quantities.currency?.symbol}}</td>
      </tr>
    </ng-template>
  </p-table>

</p-sidebar>
<p-sidebar *ngIf="parameters.location" [(visible)]="recipeDialog" [style]="{width: '70vw'}" position="right"  #x (onHide)="x.destroyModal(); load(null)">
  <app-variant-recipe *ngIf="recipeDialog" variantId="{{ recipeId }}"></app-variant-recipe>
</p-sidebar>

<p-dialog [(visible)]="selectLocationShow" [style]="{width:'50vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff; border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444"
           (click)="parameters.location = location.id;
           parameters['stock.locations.id'] = location.id;
           load(null);selectLocationShow = false">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
