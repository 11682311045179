<div class="p-grid">
  <div class="p-col-12">
    <h3>{{'WHAT_IS_PRODUCE_PLANNING' | translate}}</h3>
    <p>{{'PRODUCE_PLANNING_DESCRIPTION' | translate}}</p>
  </div>
  <div class="p-col-12">
    <b>{{'PRODUCE_PLANNING_IN_QAPERA' | translate}} </b>
  </div>
  <div class="p-col-12">
    1- <b>{{'SELECT_DEFAULT_PRODUCE_LOCATION' | translate}} </b>
    {{'SELECT_DEFAULT_PRODUCE_LOCATION_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    2- <b>{{'CREATE_SALE' | translate}} </b>
    {{'CREATE_SALE_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    3- <b>{{'CREATE_WORK_ORDER' | translate}} </b>
    {{'CREATE_WORK_ORDER_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    4- <b>{{'FINISH_WORK_ORDER' | translate}} </b>
    {{'FINISH_WORK_ORDER_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    5- <b>{{'DELIVERY_INVOICE' | translate}} </b>
    {{'DELIVERY_INVOICE_DESCRIPTION' | translate}}
  </div>
</div>
