<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div style="
width: 60%;position: absolute;top:0px;bottom: 0px;
background-image: url('assets/store.jpeg');
background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: -1;
    opacity: 0.1;
"></div>

<div class="p-grid">
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-6 p-lg-offset-0 p-sm-offset-0 p-xl-offset-3 p-md-offset-0">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'ADD_LOCATION_PAGE_TITLE' | translate}}
        </h1>
      </div>
      <div class="p-p-3 p-text-left" [style]="{fontSize: '20px'}">
        {{ 'ADD_LOCATION_PAGE_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12" style="background: #ffffff9e;
    padding: 40px 8px;
    border-radius: 10px;">
        <p-table [value]="locations">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'25%'}">{{'LOCATION' | translate}}
                <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr >
              <td>{{row.name}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td>
                <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}" icon="pi pi-plus"
                        (click)="openForm()"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>
      <div class="p-p-3 p-text-left" [style]="{marginTop: '5%'}">
        <p-button label="İleri" (onClick)="nextPage()" [style]="{float: 'right', marginTop:'10px'}"
                  icon="pi pi-angle-right" iconPos="right">

        </p-button>
        <p-button label="Geri" (onClick)="previousPage()" [style]="{float: 'right', marginTop:'10px',marginRight:'10px'}"
                  icon="pi pi-angle-left" iconPos="left"></p-button>

      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<p-sidebar [(visible)]="showForm" position="right" [modal]="true" styleClass="p-sidebar-md">
  <div class="p-fluid">
    <h2>{{'LOCATION_CREATE' | translate}}</h2>

    <div class="p-field">
      <label for="name">{{'LOCATION' | translate}}
        <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
      </label>
      <input id="name" name="LocationName" type="text" pInputText [(ngModel)]="location.name" autofocus>
    </div>

  </div>
  <ng-template pTemplate="footer">
    <div class="p-grid">
      <div class="p-col-6">
        <button pButton label="{{'CANCEL' | translate}}"
                [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#444444'}" icon="pi pi-times"
                class="p-mr-2" (click)="hideForm()"></button>
      </div>
      <div class="p-col-6">
        <button pButton label="{{'SAVE' | translate}}" [style]="{width: '100%'}"
                [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted || this.loading"
                (click)="save()"></button>
      </div>
    </div>
  </ng-template>

</p-sidebar>
