<p-table [value]="workOrder.materials" responsiveLayout="scroll" styleClass="p-datatable-striped" class="p-d-block p-mb-5">
  <ng-template pTemplate="header">
    <tr>
      <th colspan="5">{{ workOrder.variant.name }}</th>
    </tr>
    <tr>
      <th>{{ 'PRODUCT'|translate }}</th>
      <th>{{ 'REQUIRED_AMOUNT'|translate }}</th>
      <th>{{ 'USABLE_AMOUNT'|translate }}</th>
      <th>{{ 'NEED_AMOUNT'|translate }}</th>
      <th>{{ 'PURCHASE_LIST'|translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr>
      <td>
        {{item.variant.name}}
        <a [routerLink]="['/stocks', item.variant.stock.id, 'edit']" ><i class="pi pi-link"></i></a>
      </td>
      <td>{{ item.totalQuantity }}</td>
      <td>{{item.variant.usableAmount}}</td>
      <td>{{  needAmount(item.variant.amount, item.totalQuantity) }}</td>
      <td>
        <p-button [icon]="ids.indexOf(item.variant) > -1 ? 'pi pi-trash' : 'pi pi-plus'" [label]="(items.indexOf(i) > -1 ? 'REMOVE' : 'ADD')|translate" (click)="ids.indexOf(item.variant) > -1 ? remove(i) : add(item.variant, item.totalQuantity, i)" styleClass="p-button-text"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div *ngFor="let childWorkOrder of workOrder.children">
  <app-pick-list-table [workOrder]="childWorkOrder" [ids]="this.ids" [quantities]="this.quantities"></app-pick-list-table>
</div>

<div class="p-text-right" *ngIf="workOrder['@context'] !== undefined">
  <p-button [disabled]="ids.length < 1" routerLink="/purchase/create" [state]="{ids: this.ids, quantities: this.quantities, workOrderId: workOrder['@id']}" [label]="'CREATE_PURCHASE_REQUEST'|translate" ></p-button>
</div>
