import { Component, OnInit } from '@angular/core';
import {CurrencyModel} from '../../../model/currency.model';
import {HttpService} from '../../../service/http.service';
import {CompanyModel} from '../../../model/company.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {LocationModel} from '../../../model/location.model';
import {ValidationService} from '../../../service/validation.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import { MediaObjectModel } from 'src/app/model/media-object.model';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  submitted: boolean;
  errors = [];
  apiUrl: string;
  imageLoading: boolean;
  imageDeleting: boolean;
  tabMenuItems: MenuItem[];
  activeTabMenuItem: MenuItem;
  validationSubscriber: Subscription;

  constructor(private http: HttpService, private flashMessageService: FlashMessageService,
              private validationService: ValidationService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.loading = true;

    this.http.get(CompanyModel.IRI).subscribe((response: CompanyModel) => {
      this.company = response;
      this.company.currency = this.company.currency ? this.company.currency['@id'] : null;

      this.setLocations();

      this.loading = false;
    });

    this.loadLocations();
    this.loadCurrencies();

    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });
    this.tabMenuItems = [
      {
        title: 'GENERAL',
        label: this.translate.instant('GENERAL'),
        command: event => {
          this.changeTabMenu(0);
        }
      },
      {
        title: 'POS',
        label: this.translate.instant('POS'),
        command: event => {
          this.changeTabMenu(1);
        }
      }
    ];
    this.activeTabMenuItem = this.tabMenuItems[0];
  }

  loadCurrencies(): void {
    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
    });
  }

  loadLocations(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;

      this.setLocations();
    });
  }

  setLocations(): void {
    if (this.locations.length > 0) {
      if (this.company.defaultSalesLocation === null) {
        this.company.defaultSalesLocation = this.locations[0]['@id'];
      }

      if (this.company.defaultPurchaseLocation === null) {
        this.company.defaultPurchaseLocation = this.locations[0]['@id'];
      }

      if (this.company.defaultManufacturingLocation === null) {
        this.company.defaultManufacturingLocation = this.locations[0]['@id'];
      }
    }
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    await this.http.put(`${CompanyModel.IRI}/${this.company.id}`, this.company).then((response: CompanyModel) => {
      if (response !== undefined) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        localStorage.setItem('currency', JSON.stringify(response.currency));
      }
    });

    this.submitted = false;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

  async upload(event, fileUpload): Promise<void> {

    this.imageLoading = true;
    for (const file of event.files) {
      const fd = new FormData();
      fd.append('file', file);

      await this.http.post(MediaObjectModel.IRI, fd).then(async (response: MediaObjectModel) => {

        this.company.logo = response.contentUrl;

        await this.http.put(`${CompanyModel.IRI}/${this.company.id}`, this.company).then((companyModel: CompanyModel) => {
          this.company = companyModel;
        });
      });
    }

    this.imageLoading = false;
    fileUpload.clear();
  }

  async deleteImage(): Promise<void> {
    this.imageDeleting = true;

    await this.http.put(`${CompanyModel.IRI}/${this.company.id}`, {logo: null}).then((companyModel: CompanyModel) => {
      this.company = companyModel;
    });

    this.imageDeleting = false;
  }
  changeTabMenu(menu): void{
    this.activeTabMenuItem = this.tabMenuItems[menu];
  }
}
