import {ApiResourceModel} from './api-resource.model';
import {ContactModel} from './contact.model';
import {CurrencyModel} from './currency.model';
import {LocationModel} from './location.model';

export class IncomingInvoiceModel extends ApiResourceModel {
  public static IRI = '/api/incoming_invoices';
  category: string;
  contact: ContactModel|string;
  currency: CurrencyModel;
  location: LocationModel;
  name: string;
  subtotal: number;
  discount: number;
  tax: number;
  total: number;
  invoiceDate: Date|string;
  invoiceNumber: string;
  relatedId: string;
  discountRate: number;
  discountAmount: number;
  incomingInvoiceItems: any;

}
