import {Component, OnInit} from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../service/location.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {classNames} from "@angular/cdk/schematics";
import {UtilityService} from "../../../../service/utility.service";

@Component({
  selector: 'app-daily-production-report',
  templateUrl: './daily-production-report.component.html',
  styleUrls: ['./daily-production-report.component.scss']
})
export class DailyProductionReportComponent implements OnInit {
  events: any[] = [];
  options: any;
  dataIncoming: any;
  info: any;
  sale: any;
  saleDates: any;
  chartData: any;
  categories: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  basicData: any;
  basicOptions: any;
  totalProdCost = 0;
  totalProdInc = 0;
  totalCost = 0;
  location: LocationModel[] = [];
  dateRange: Date[] = [];
  parameters = {
    date: '',
    startDate: '',
    lastDate: '',
    location: ''
  };

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService,
              private utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.startDate;
        // @ts-ignore
        this.parameters.lastDate = params.lastDate;
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

        const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));
        console.log(dateRange);

        const selectedDate = dateRange.map(date => this.getFormattedDate(date));

        this.parameters.date = selectedDate.join(',');

        this.parameters.location = params.location ? params.location : '';
      } else {
        this.getThisWeekDates();
      }
    });

    this.options = {
      initialDate: new Date(),
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      eventColor: '#378006'
    };
    this.http.get('/api/dasboard_reports', {
      'startDate': this.parameters.startDate,
      'endDate': this.parameters.lastDate
    }).subscribe(response => {
      this.info = response;
      this.dateRange = [new Date(this.info.startDate), new Date(this.info.lastDate)];
    });
    this.load().then();
    //this.loading = false;

  }

  async load(): Promise<void> {
    this.loading = true;
    this.totalProdCost = 0;
    this.totalProdInc = 0;
    this.totalCost = 0;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get('/api/reports/work_orders_reports', this.parameters).subscribe(response => {
      this.saleDates = response;

    });
    this.http.get('/api/reports/work_orders_reports', this.parameters).subscribe(response => {
      this.chartData = response;
      if (this.chartData.length < 15) {
        const count = 15 - this.chartData.length;
        for (let i = 0; i < (count/2); i++) {
          this.chartData.unshift({
            cost: 0,
            costLocation: 0,
            costLocationRate: 0,
            costRate: 0,
            date: 0,
            profit: 0,
            profitRate: 0,
            quantity: 0,
            salePrice: 0
          })
        }
        for (let i = 0; i < (count/2); i++) {
          this.chartData.push({
            cost: 0,
            costLocation: 0,
            costLocationRate: 0,
            costRate: 0,
            date: 0,
            profit: 0,
            profitRate: 0,
            quantity: 0,
            salePrice: 0
          })
        }
      }
      this.saleDates.map(item => {
        this.totalProdCost += item.cost;
        this.totalProdInc += item.salePrice;
        this.totalCost += (item.cost + item.costLocation);
      });
      this.basicData = {
        labels: this.chartData.map(item => item.date),
        datasets: [
          {
            type: 'bar',
            label: this.translate.instant('PRODUCTION_REVENUE'),
            backgroundColor: '#a4c7a2',
            data: this.chartData.map(item => item.salePrice),
          },
          {
            type: 'bar',
            label: this.translate.instant('COST_BENEFIT_ANALYSIS'),
            backgroundColor: '#885f5f',
            data: this.chartData.map(item => item.cost + item.costLocation)
          },
          {
            type: 'bar',
            label: this.translate.instant('PROFIT_IN_LOCAL_CURRENCY'),
            backgroundColor: '#47bfd4',
            data: this.chartData.map(item => item.profit)
          },
          {
            type: 'line',
            label: this.translate.instant('PROFIT_IN_LOCAL_CURRENCY'),
            borderColor: 'rgb(255, 3, 0)',
            borderWidth: 2,
            fill: false,
            data: this.chartData.map(item => item.profit)
          }
        ]
      };
      this.basicOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      this.loading = false;

    });

  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    if (event[1] === null) {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    } else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }

    this.parameters = {...this.parameters};


  }

  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getThisWeekDates(): void {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Haftanın ilk gününü al
    const thisWeekDates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      thisWeekDates.push(this.getFormattedDate(date));
    }
    this.parameters.startDate = this.getFormattedDate(firstDayOfWeek);
    this.parameters.lastDate = thisWeekDates[thisWeekDates.length - 1];

    this.parameters.date = thisWeekDates.join(',');

  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`daily_report.pdf`); // PDF'i indir
    });
  }
  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/dailyWorkOrder', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'daily_work_order_report.xlsx'); });
  }

  getTotal(field: string): number {
    return this.saleDates.reduce((acc, item) => acc + item[field], 0);
  }
}
