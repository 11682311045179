import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {CurrencyModel} from '../../../model/currency.model';
import {Subscription} from 'rxjs';
import {PurchaseOptionModel} from '../../../model/purchase-option.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {CurrencyService} from '../../../service/currency.service';
import {ValidationService} from '../../../service/validation.service';
import {CategoryService} from '../../../service/category.service';
import {TaxService} from '../../../service/tax.service';
import {UnitService} from '../../../service/unit.service';
import {ContactService} from '../../../service/contact.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CreateCategoryComponent} from '../../settings/category/create-category/create-category.component';
import {CreateTaxComponent} from '../../settings/tax/create-tax/create-tax.component';
import {CreateUnitComponent} from '../../settings/unit/create-unit/create-unit.component';
import {ContactCreateDialogComponent} from '../../contact/contact-create-dialog/contact-create-dialog.component';
import {LocationModel} from '../../../model/location.model';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {ProductRecipeModel} from '../../../model/product-recipe.model';
import {ProductRecipeItemModel} from '../../../model/product-recipe-item.model';
import {StockTransactionModel} from '../../../model/stock-transaction.model';
import {InvoiceModel} from '../../../model/invoice.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {ProductOperationModel} from '../../../model/product-operation.model';

@Component({
  selector: 'app-new-material-update',
  templateUrl: './new-material-update.component.html',
  styleUrls: ['./new-material-update.component.scss']
})
export class NewMaterialUpdateComponent implements OnInit , OnDestroy {
  @Input()  item: StockModel = new StockModel();
  @Input() type: string;
  @Input() id: number;
  @Input() loading: boolean;
  submitted: boolean;
  errors = [];
  suppliers: ContactModel[] = [];
  taxes: TaxRateModel[] = [];
  categories: CategoryModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  trackTypes: any[] = [];
  currency: string;
  validationSubscriber: Subscription;
  contactTypes: [{ name: any; value: any }, {name: any, value: any }];
  locations: LocationModel[] = [];
  tabMenuItems: MenuItem[];
  activeTabMenuItem: MenuItem;
  activeTab: number;
  recipe: ProductRecipeModel[] = [];
  recipeItem: ProductRecipeItemModel[] = [];
  stockTransactions: StockTransactionModel[] = [];
  allergens = [];
  parameters = {
    id: '',
    date: '',
    page: 1,
    itemsPerPage: 20,
  };

  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  rates: string[] = [];

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private dialogService: DialogService, private translate: TranslateService,
              private flashMessageService: FlashMessageService, private currencyService: CurrencyService,
              private validationService: ValidationService, private categoryService: CategoryService,
              private taxService: TaxService, private unitService: UnitService, private contactService: ContactService) { }

  async ngOnInit(): Promise<void> {
    this.loading = false;
    this.loadFormData().then();
    await setTimeout(() => {
      this.item = null;
      this.contactTypes = [
        {name: 'Tedarikçi', value: 'supplier'},
        {name: 'Müşteri', value: 'customer'},
      ];
      // @ts-ignore
      this.allergens = [
        {
          name: this.translate.instant('CEREALS_CONTAINING_GLUTEN'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('CRUSTACEAN_SHELLFISH'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('EGGS'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('FISH'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('MILK'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('NUTS'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('PEANUTS'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        },
        {
          name: this.translate.instant('SOYBEANS'),
          noInfo: false,
          contains: false,
          mayContain: false,
          doesNotContain: false,
        }
      ];

    }, 500);
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
    this.tabMenuLoad();
  }

  async loadFormData(): Promise<void> {
    this.loading = true;
    await this.categoryService.getCategoriesAction({isActive: true});
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
    });

    await this.taxService.getTaxesAction({isActive: true});
    this.taxService.getTaxes().subscribe(response => {
      this.taxes = response;
    });

    await this.unitService.getUnitsAction({isActive: true});
    this.unitService.getUnits().subscribe(response => {
      this.units = response;
    });

    await this.contactService.getContactsAction({pagination: false});
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.suppliers = response;
      }
    });

    await this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
      if (this.currencies.length > 0 && this.item.currency == null) {
        this.item.currency = this.currencies[0]['@id'];
      }
    });
    await this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response) => {
      this.locations = response;
      this.loading = false;
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    await this.http.put(`${StockModel.IRI}/${this.item.id}`, this.item).then((response: StockModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
    });
    this.submitted = false;
  }

  addCategory(): void {
    this.dialogService.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
    });
  }

  addTax(): void {
    this.dialogService.open(CreateTaxComponent, {
      width: '50vw',
      header: this.translate.instant('TAX_RATE_CREATE'),
    });
  }

  addUnit(): void {
    this.dialogService.open(CreateUnitComponent, {
      width: '50vw',
      header: this.translate.instant('UNIT_CREATE'),
    });
  }

  addContact(): void {
    this.dialogService.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
    });
  }

  changeCurrency(event): void {
    this.currencies.map((item) => {
      if (item['@id'] === event.value) {
        this.currency = item.code;
      }
    });
  }

  addItem(): void {
    const options = new PurchaseOptionModel();
    // @ts-ignore
    // @ts-ignore
    this.item.purchaseOptions.push(options);
    this.item.purchaseOptions.map((data, index) => {
      this.item.purchaseOptions[index].stock = this.item['@id'];
      this.item.purchaseOptions[index].supplier = this.item.purchaseOptions[index].supplier['@id'];
      this.item.purchaseOptions[index].unit = this.item.purchaseOptions[index].unit['@id'];
    });
  }

  removeItem(i: number): void {
    this.item.purchaseOptions.splice(i, 1);
  }

  changeUnit(row, i, type): void {
    if ( type === 'unit'){
      this.http.get(UnitModel.IRI, {id: row.unit}).subscribe(response => {
        this.item.purchaseOptions[i].unitName = response['hydra:member'][0].name;
        if (row.packPerCase === null && row.packName === '') {
          this.item.purchaseOptions[i].orderingUnit = row.packQuantity + ' ' + response['hydra:member'][0].name;
        } else if (row.packPerCase !== null && row.packName === '') {
          this.item.purchaseOptions[i].orderingUnit = 'Tanımsız';
        } else{
          this.item.purchaseOptions[i].orderingUnit = row.packName + '(' + row.packPerCase + '*' + row.packQuantity + ' ' + response['hydra:member'][0].name + ')';
        }
      });
      if (this.item.unit !== row.unit ) {
        this.item.purchaseOptions[i].baseUnitQuantity = 1;
     }else{
        this.item.purchaseOptions[i].baseUnitQuantity = null;
     }

    }else if (row.packPerCase === null && row.packName === '') {
      this.item.purchaseOptions[i].orderingUnit = row.packQuantity + ' ' + row.unitName;
    } else if (row.packPerCase !== null && row.packName === '') {
      this.item.purchaseOptions[i].orderingUnit = 'Tanımsız';
    } else{
      this.item.purchaseOptions[i].orderingUnit = row.packName + '(' + row.packPerCase + '*' + row.packQuantity + ' ' + row.unitName + ')';
    }

  }

  tabMenuSelect(id): void {

   this.activeTabMenuItem = this.tabMenuItems[id];
   this.activeTab = id;
   if (id === 1){
     this.loading = true;
     this.http.get(ProductRecipeModel.IRI, {'items.stock.id' : this.item.variants[0].id }).subscribe(res => {
       this.recipe = res['hydra:member'];
       this.loading = false;
     });
   }
   if (id === 2){
     this.loading = true;
     this.loadOperations(this.item);
   }
   if (id === 3){
     this.loading = true;
     this.loadRecipe(this.item);
   }
   if (id === 4) {
     if (this.item.allergen.length === 0 ){
       this.item.allergen = this.allergens;
     }
   }
   if (id === 5) {
     this.loading = true;
     this.loadStockChanges();

   }
   if (id === 6) {
    this.loading = true;

   }
  }

  loadStockChanges(event: LazyLoadEvent = null): void{
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(StockTransactionModel.IRI, {variantId: this.item.variants[0].id}).subscribe(data => {
      this.stockTransactions = data['hydra:member'];
      this.stockTransactions.map((stockTransaction, i) => {
        if (stockTransaction.relatedType === 'App\\Entity\\Invoice') {
          this.http.get(InvoiceModel.IRI, { id: stockTransaction.relatedById }).subscribe(res => {
            this.stockTransactions[i].comment = res['hydra:member'];
          });
        }
      });
      this.loading = false;
    });
  }

  change(event, i, noInfo, contains, mayContain, doesNotContain): void {

    // @ts-ignore
    this.item.allergen[i].noInfo = noInfo;
    // @ts-ignore
    this.item.allergen[i].contains = contains;
    // @ts-ignore
    this.item.allergen[i].mayContain = mayContain;
    // @ts-ignore
    this.item.allergen[i].doesNotContain = doesNotContain;
  }

  tabMenuLoad(): void {
    // @ts-ignore
    this.tabMenuItems = [
      { label: this.translate.instant('PURCHASING_INVENTORY'),
        title: 'PURCHASING_INVENTORY',
        command: (event) => {
          this.tabMenuSelect(0);
        },
      },
      { label: this.translate.instant('PRODUCT_RECIPE'),
        title: 'PRODUCT_RECIPE',
        command: (event) => {
          this.tabMenuSelect(1);
        },
        visible: this.type === 'material' ? false : true
      },
      { label: this.translate.instant('OPERATIONS'),
        title: 'OPERATIONS',
        command: (event) => {
          this.tabMenuSelect(2);
        },
        visible: this.type === 'material' ? false : true
      },
      { label: this.translate.instant('IN_RECIPES'),
        title: 'IN_RECIPES',
        command: (event) => {
          this.tabMenuSelect(3);
        },
        visible: this.type !== 'material' ? false : true
      },
      { label: this.translate.instant('ALLERGENS'),
        title: 'ALLERGENS',
        command: (event) => {
          this.tabMenuSelect(4);
        },
      },
      { label: this.translate.instant('STOCK_MOVEMENTS'),
        title: 'STOCK_MOVEMENTS',
        command: (event) => {
          this.tabMenuSelect(5);
        },
      },
      { label: this.translate.instant('PROCEDURE'),
        title: 'PROCEDURE',
        command: (event) => {
          this.tabMenuSelect(6);
        },
        visible: this.type === 'material' ? false : true
      }
    ];
    this.activeTabMenuItem = this.tabMenuItems[0];
  }

  loadRecipe(stock: StockModel): void {
    this.loading = true;
    this.http.get(`${ProductRecipeModel.IRI}?product.id=${stock.id}`).subscribe((response: ProductRecipeModel[]) => {
      if (response['hydra:member'].length > 0) {
      }
      this.loading = false;
    });
  }

  loadOperations(stock: StockModel): void {
  }
  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

}
