import {ApiResourceModel} from './api-resource.model';
import {AddressModel} from './address.model';
import { CategoryModel} from './category.model';
import {TaxRateModel} from './tax-rate.model';
import {InvoiceModel} from './invoice.model';

export class ContactModel extends ApiResourceModel{
  public static IRI = '/api/contacts';
  public code: string;
  public name: string;
  public email: string;
  public phoneNumber: string;
  public comment: string;
  public address: AddressModel;
  public ccMail: string;
  public deliveryDay: number;
  public paymentTerm: string;
  public taxAgency: string;
  public taxAgencyNumber: number;
  public contactNumber: string;
  public monthDay: number;
  public type: string;
  public id: any;
  public deliveryAddresses: any;
  public invoices: InvoiceModel [] = [];
}

export enum ContactType {
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER'
}
