import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-production-report-tab',
  templateUrl: './production-report-tab.component.html',
  styleUrls: ['./production-report-tab.component.scss']
})
export class ProductionReportTabComponent implements OnInit {
  @Input() pageName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
