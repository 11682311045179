<div class="div-sums p-flex-row mb-3">
  <div class="p-col inside-div-no-background {{ pageName == 'daily-income' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/daily-income'" [style]="{cursor:'pointer'}">
    {{'DAILY_INCOME_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'product-group' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/product-group'" [style]="{cursor:'pointer'}">
    {{'PRODUCT_GROUP_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'menu-item-group' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/menu-item-group'" [style]="{cursor:'pointer'}">
    {{'MENU_ITEM_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'menu-group-comparison' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/menu-group-comparison'"  [style]="{cursor:'pointer'}">
    {{'MENU_GROUP_COMPARISON_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'unpaid-report' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/unpaid-report/by-daily'"   [style]="{cursor:'pointer'}">
    {{'UNPAID_REPORT' | translate}}
  </div>
</div>
