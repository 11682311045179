
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ id ? transfer.code : 'NEW_STOCK_TRANSFER' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
      <app-page-info [pageName]="'NEW_STOCK_TRANSFER'"></app-page-info>
      <p-button icon="pi pi-print" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="transfer.status !== 'SENT' && transfer.status !== 'REJECTED' && id">
        <p-button [style]="{width: '100%'}" [disabled]="!isCanBeSend"
                  label="{{ 'SEND_TRANSFER' | translate}}"
                  (click)="onChangeStatus( 'SENT' )">
        </p-button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="transfer.status !== 'SENT' && transfer.status !== 'REJECTED' && id && transfer.status !== 'PREPARING'">
      <p-button [style]="{width: '100%'}"
                label="{{ 'PREPARING' | translate}}"
                (click)="onChangeStatus( 'PREPARING' )">
      </p-button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="transfer.status === 'REQUEST' || transfer.status === 'PREPARING'">
      <p-button [style]="{width: '100%'}"
                styleClass="p-button-success"
                label="{{ 'CANCEL_TRANSFER' | translate}}"
                (click)="onChangeStatus( 'REJECTED' )">
      </p-button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="transfer.status !== 'SENT'">
      <p-button *ngIf="isDeleteAble === true"
                [style]="{width: '100%'}"
                styleClass="p-button-danger"
                label="{{ 'DELETE' | translate}}"
                (click)="delete( transfer.id )">
      </p-button>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3">
    <div class="p-col div-sums">
      <span class="title">{{'NUMBER_OF_ITEMS' | translate}}</span>
      <span class="total-price">{{ transfer.items.length}} <b
        class="currency">{{'PIECE' | translate}}</b></span>
    </div>
    <div class="p-col div-sums">
      <span>{{'TOTAL' | translate}}</span>
      <span class="total-price">{{ transfer.amount|currency: currencyLabel: ''}} <b
        class="currency"></b></span>
    </div>
  </div>
  <p-message *ngIf="activeInventory"  [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info"
              text="Transfer Tarihi, Açık Olan Envanter tarihleri olan  {{ minDate | date : 'dd-MM-Y' }} ile {{ maxDate | date : 'dd-MM-Y' }} arasında olmalıdır"
              styleClass="mr-2">
  </p-message>
  <p-message *ngIf="!isCanBeSend" [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info" text="Seçtiğiniz Tarih Açık Olan Envanter Başlangıç Tarihin {{ isCanBeSelectDate | date : 'Y-M-dd' }}'den Daha Eski Olamaz" styleClass="mr-2"></p-message>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-6">
      <label for="code">{{ 'TRANSFER_CODE'|translate}}</label><br>
      <input id="code" width="100%" type="text" name="code"  [(ngModel)]="transfer.code"  pInputText [disabled]="transfer.status === 'SENT'">
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6">
      <label for="transferDate">{{ 'TRANSFER_DATE'|translate}}</label>
      <p-calendar id="transferDate" appendTo="body" [(ngModel)]="transfer.transferDate" (onSelect)="transferDateControl($event)"
                  [minDate]="minDate"  [maxDate]="maxDate"  name="transferDate" dateFormat="yy-mm-dd" date
                  placeholder="{{'SELECT_TRANSFER_DATE'| translate}}"  ></p-calendar>
      <small class="p-error"></small>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-6">
      <label for="origin">{{ 'TRANSFER_ORIGIN'|translate}}</label>
      <p-dropdown id="origin" name="origin"  placeholder="{{'SELECT_ORIGIN'| translate}}"
                  [disabled]="transfer.status === 'SENT'"
                  (onChange)="onOriginChange()"
                  [(ngModel)]="transfer.origin"
                  [options]="locations"
                  appendTo="body"
                  optionLabel="name"
                  optionValue="@id" ></p-dropdown>
      <small *ngIf="isOriginSelected == true" class="p-error">{{ 'SELECT_ORIGIN' | translate}}</small>
      <h4 *ngIf="infoTextShowDisplay"><b>{{'TRANSFER_ORIGIN' |translate}}</b></h4>
      <p *ngIf="infoTextShowDisplay">{{'TRANSFER_ORIGIN_DESCRIPTION' |translate}}</p>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6">
      <label for="destination">{{ 'TRANSFER_DESTINATION'|translate}}</label>
      <p-dropdown id="destination"  name="destination" placeholder="{{'SELECT_DESTINATION'| translate}}"
                  [disabled]="transfer.status === 'SENT'"
                  data-ng-change="calculateCorrelations()"
                  (onChange)="onDestinationChange()"
                  [(ngModel)]="transfer.destination"
                  [options]="destinationLocations"
                  appendTo="body" optionLabel="name"  optionValue="@id"></p-dropdown>
      <small *ngIf="isDestinationSelected == true" class="p-error">{{ 'SELECT_DESTINATION' | translate}}</small>
      <small *ngIf="noSameLocation" class="p-error">{{ 'LOCATIONS_CANNOT_BE_THE_SAME' | translate}}</small>
      <h4 *ngIf="infoTextShowDisplay"><b>{{'TRANSFER_DESTINATION' |translate}}</b></h4>
      <p *ngIf="infoTextShowDisplay">{{'TRANSFER_DESTINATION_DESCRIPTION' |translate}}</p>
    </div>
  </div>
  <div class="p-grid" *ngIf="!noSameLocation && !isOriginSelected && this.transfer.destination !== null">
    <div class="p-col-12" *ngIf="noSelectedItem" [style]="{marginTop:'10px'}">
      <p-message severity="error" text="{{ 'NO_SELECTED_ITEM' | translate}}"><br></p-message>
    </div>
    <div class="p-col-12" *ngIf="maxQuantityError" [style]="{marginTop:'10px'}">
      <p-message severity="error" text="{{ 'TRANSFER_QUANTITY_NOT_BIGGER_THEN_ORIGIN_STOCK' | translate}}"><br></p-message>
    </div>
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-table [loading]="isQuantityLoaded" [value]="transfer.items"
               dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'40%'}">{{'STOCK' | translate}}</th>
            <th [style]="{width:'10%'}">{{'CURRENT_IN_STOCK_AT_ORIGIN' | translate}} </th>
            <th [style]="{width:'10%'}">{{'TRANSFER_QUANTITY' | translate}} </th>
            <th [style]="{width:'10%'}">{{'CURRENT_IN_STOCK_AT_DESTINATION' | translate}}</th>
            <th [style]="{width:'10%'}">{{'UNIT_PRICE' | translate}}</th>
            <th [style]="{width:'10%'}">{{'TOTAL' | translate}}</th>
            <th [style]="{width:'3%'}"></th>
          </tr>

        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td pEditableColumn [style]="{padding: '8px',borderBottom: '1px solid'}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete appendTo="body"   autofocus  [(ngModel)]="autoCompleteValues[i]"
                                  [suggestions]="variants"  name="stock" (completeMethod)="searchStock($event, i)"
                                  (onSelect)="onVariantSelect($event, i);" field="name"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ autoCompleteValues[i] ? autoCompleteValues[i].name : '' }}
                </ng-template>
              </p-cellEditor>

            </td>
            <td><span [ngStyle]="{textAlign: 'right','color' : item.originStock < 0 ? '#ff2525' : ''}">
              {{ item.originStock| number: '1.2-2' }} {{ item.unit }}
            </span>
              <small class="p-error"></small></td>
            <td pEditableColumn [style]="{padding: '0px',textAlign:'right'}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input type="number" pInputText name="quantity" min="0.001"  max="{{ item.originStockFirst}}"
                         placeholder="Max {{ item.originStockFirst}} {{ item.unit }}" [(ngModel)]="item.quantity"
                         (change)="onQuantityChange($event, $event.target.value, i)">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.quantity | number: '1.2-3' }} {{ item.unit }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign:'right'}">{{ item.destinationStock | number: '1.2-2' }} {{ item.unit }}</td>
            <td [style]="{textAlign:'right'}">{{ item.price | currency: item.symbol}}</td>
            <td [style]="{textAlign:'right'}">{{ item.total| currency: item.symbol}}</td>
            <td><i class="pi pi-trash" [style]="{color:'#ff2525'}" (click)="removeItem(item.id,item.total, i)"></i></td>

          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="!noSameLocation && !isOriginSelected && this.transfer.destination !== null">
        <button pButton label="{{'ADD_STOCK' | translate}}" class="p-mb-3 add-button p-mt-3" [icon]="'pi pi-plus'"
                (click)="addItem()" [disabled]="isAddStockAvailable"></button>
        <button pButton label="{{'SAVE' | translate}}" class="p-mb-3"
                [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="this.submitted || isAddStockAvailable" (click)="save()"></button>
      </div>

    </div>
  </div>


  <p-dialog header="{{'WARNING' | translate}}" [(visible)]="findStockAlert" [style]="{width: '50vw'}">
    <p>{{ 'STOCK_TRANSFER_SAME_ITEM_WARNING' | translate}}</p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="findStockAlert=false" label="{{'I_UNDERSTAND' | translate }}" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>

