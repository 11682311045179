import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SectorModel} from '../model/sector.model';
import {HttpService} from './http.service';
import {CookBookCategoryModel} from '../model/cook-book-category.model';

@Injectable({
  providedIn: 'root'
})
export class CookBookCategoryService {


  items = new BehaviorSubject<CookBookCategoryModel[]>(null);

  constructor(private http: HttpService) { }

  getItems(): Observable<CookBookCategoryModel[]> {
    return this.items.asObservable();
  }

  getItemsAction(params = {}): void {
    this.http.get(CookBookCategoryModel.IRI, params).subscribe(response => {
      this.items.next(response['hydra:member']);
    });
  }

  async save(sector: CookBookCategoryModel): Promise<any> {
    return await this.http.post(CookBookCategoryModel.IRI, sector).then(response => {
      if (response !== undefined) {
        const items = this.items.getValue();
        items.push(response);
        this.items.next(items);
      }

      return response;
    });
  }

  async update(item: CookBookCategoryModel): Promise<any> {
    return await this.http.put(`${CookBookCategoryModel.IRI}/${item.id}`, item).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.items.next(null);
    this.items.unsubscribe();
  }
}
