import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {ProductOperationItemModel} from './product-operation-item.model';


export class ProductOperationModel extends ApiResourceModel {
  public static IRI = '/api/product_operations';
  public cost: number;
  public product: StockModel|string;
  public items: ProductOperationItemModel[] = [];

}
