<p-card>
  <app-loading-indicator [loading]="false"></app-loading-indicator>
  <div class="main-content-div">
    <div class="main-content-div-row1">
      <div class="left-div">
        <div class="back-div">
        </div>
        <div class="search-div">
        </div>
      </div>
      <div class="right-div">
        <button pButton pRipple [style]="{padding: '13px', width: '52px'}" [routerLink]="['/purchases']"  class="p-button-secondary">
          <i class="pi pi-print" style="font-size: 25px"></i>
        </button>
      </div>
    </div>
    <div class="main-content-div-row3">

    </div>

  </div>
</p-card>

