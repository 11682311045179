<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form action="" *ngIf="!loading && !isConnected">
  <div class="p-fluid">
    <div class="p-field">
      <label for="userId">{{ 'EMAIL'|translate }}</label>
      <input id="userId" type="text" [name]="'email'" pInputText [(ngModel)]="attributes.email" autofocus>
    </div>
    <div class="p-field">
      <label for="apikey">{{ 'PASSWORD'|translate }}</label>
      <input id="apikey" type="text" [name]="'password'" pInputText [(ngModel)]="attributes.password" autofocus>
    </div>
  </div>
  <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]="this.submitted" (click)="save()"></button>
</form>
<div *ngIf="!loading && isConnected">
  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMCYS6Dg_8hpLEeQbJghJLm4qt1yUaJndjmg&s" width="250px" alt="">
  <p-divider></p-divider>
  <h2 class="p-d-inline-block">
    <i class="pi pi-users"style="font-size: 1.5rem; margin-right: 10px" on></i> {{ 'SYNC_CONTACT'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="invoiceLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="getContacts()"></p-button>
  <p-divider></p-divider>
  <h2 class="p-d-inline-block">
    <i class="pi pi-file-pdf"style="font-size: 1.5rem; margin-right: 10px" on></i> {{ 'SYNC_INCOMING_INVOICE'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="invoiceLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="getIncomingEinvoices()"></p-button>
  <p-divider></p-divider>
</div>

