// @ts-ignore

import {Component, OnInit, ViewChild} from '@angular/core';
import {InventoryItemModel} from '../../../model/inventory-item.model';
import {CategoryModel} from '../../../model/category.model';
import {CompanyModel} from '../../../model/company.model';
import {InventoryModel} from '../../../model/inventory.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CategoryService} from '../../../service/category.service';
import {CurrencyService} from '../../../service/currency.service';
import {LocationModel} from '../../../model/location.model';
import {LazyLoadEvent} from 'primeng/api';
import {UtilityService} from '../../../service/utility.service';
import {StockModel} from '../../../model/stock.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {Table} from 'primeng/table';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {environment} from '../../../../environments/environment';
import html2pdf from 'html2pdf.js';



@Component({
  selector: 'app-inventory-report',
  templateUrl: './inventory-report.component.html',
  styleUrls: ['./inventory-report.component.scss']
})
export class InventoryReportComponent implements OnInit {
  @ViewChild('dt') dt: Table;
  inventory: InventoryModel;
  loading: boolean;
  total: number;
  // @ts-ignore
  items: InventoryItemModel = [];
  categories: CategoryModel[] = [];
  searchCategories: CategoryModel[] = [];
  company: CompanyModel = new CompanyModel();
  id: any;
  parameters = {
    location: '',
    category: '',
    search: '',
    inventory: '',
    query: '',
    stockId: '',
    itemsPerPage: 20,
    page: 0
  };
  isSelected: boolean;
  reportValues = [];
  currencyLabel: string;
  locations: LocationModel[] = [];
  showQuantityColumns: boolean;
  tableLoading: boolean;
  inventories: any[] = [];
  chartOptions: any;
  data: any;
  dataG: any;
  basicData: any;
  horizontalOptions: any;
  variants: StockVariantModel[] = [];
  valueConsum: any;
  valueProf: any;
  loadingCount = 0;
  selectedInventories: any[] = [];
  selectedCategories: any[] = [];
  selectedLocations: any[] = [];
  description: boolean;
  chartOptions2: any;
  first = 0;
  isStocks: boolean;
  isDetails: boolean;
  noSelected: boolean;
  baseURL: string;





  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private categoryService: CategoryService,
              private currencyService: CurrencyService,
              private utilityService: UtilityService,
  ) {
  }

  ngOnInit(): void {
    this.baseURL = environment.apiUrl;
    this.loading = true;
    this.isSelected = false;
    this.noSelected = false;
    this.http.get(`${InventoryModel.IRI}/allInventories`).subscribe(response => {
      response.map(item => {
        const inventory = {
          name: item.code + ' ( ' + item.start_date + ' - ' + item.end_date + ' )',
          id : item.id
        };
        this.inventories.push(inventory);
        });
      });
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination : false}).subscribe(response => {
      if (response !== undefined) {
        this.locations = response;
        this.loading = false;
      }
    });
    this.http.get(`${CategoryModel.IRI}/category-type/stock?mainCategory=yes`).subscribe(category => {
      this.searchCategories = category['hydra:member'];
      this.searchCategories.unshift({
        categoryMatches: [],
        accountingCode: '',
        categoryType: '',
        children: undefined,
        code: '',
        createdAt: '',
        department: '',
        isActive: false,
        location: undefined,
        lvl: 0,
        message: '',
        parent: '',
        parentId: '',
        tax: [],
        updatedAt: "",
        name: this.translate.instant('ALL_CATEGORIES'), id: null
      });
    });
  }
  loadInventoryReportData(event: LazyLoadEvent = null): void {
    if (this.isSelected) {
      this.noSelected = false;
      if (this.loadingCount === 0) {
        this.loading = true;
        this.tableLoading = false;
        this.loadingCount = 1;
      } else {
        this.tableLoading = true;
      }
      if (event?.sortField) {
        this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
      }
      this.parameters.page = 1;
      this.parameters = {...this.parameters};
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
      this.http.get(`${InventoryModel.IRI}/inventory_report`, this.parameters).subscribe(data => {
        this.reportValues = data;
        this.loading = false;
        this.tableLoading = false;
        this.valueConsum = (this.reportValues[0].locations[0].lastQuantityValue /
          this.reportValues[0].locations[0].totalSales[0].total) * 100;
        this.valueProf = ((this.reportValues[0].locations[0].totalSales[0].total -
          this.reportValues[0].locations[0].lastQuantityValue) / this.reportValues[0].locations[0].totalSales[0].total) * 100;
        this.loadChart();
      });

      this.parameters.stockId = '';
    }else{
      this.noSelected = true;
    }
  }
  changeLocation(event): void {
    this.parameters.location = event.value.toString();
    if (this.parameters.inventory && this.parameters.location){
      this.isSelected = true;
    }
  }
  changeCategory(event): void {
    this.parameters.category = event.value;
  }
  // tslint:disable-next-line:typedef
  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  }
  async onVariantSelect(event: StockModel): Promise<void> {
    this.parameters.stockId = event.id;
    this.first = 0;
    this.loadInventoryReportData();
    this.isStocks = true;
  }
  resetTheSearch(): void{
    this.parameters.search = '';
    this.parameters.stockId = '';
    this.parameters.query = '';
    this.loadInventoryReportData();
  }
  changeInventory(event): void {
    const myArray = event.value;
    myArray.sort((a, b) => {
      return a - b;
    });
    this.parameters.inventory = myArray.toString();
    if (this.parameters.inventory && this.parameters.location){
      this.isSelected = true;
    }
  }
  handlePageChange(event): void {
    this.first = event.first;
  }
  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/inventoryReport', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'inventory_report.xlsx'); });
  }
  // tslint:disable-next-line:typedef
  loadChart(){
    this.data = {
      labels: this.reportValues[0].totals.map(item => item.name),
      datasets: [{
        type: 'bar',
        label: this.translate.instant('TOTAL_SALES'),
        backgroundColor: '#367f5c',
        data: this.reportValues[0].totals.map(item => item.catSales),
        borderColor: 'white',
        borderWidth: 2
      }, {
        type: 'bar',
        label: this.translate.instant('TOTAL_CONSUMETION'),
        backgroundColor: '#71b69b',
        data: this.reportValues[0].totals.map(item => item.lastQuantityValue),
        borderColor: 'white',
        borderWidth: 2
      }, {
        type: 'bar',
        label: this.translate.instant('TOTAL_PROFIT'),
        backgroundColor: '#afd8ca',
        data: this.reportValues[0].totals.map(item => (item.catSales - item.lastQuantityValue)),
        borderColor: 'white',
        borderWidth: 2
      }, {
        type: 'bar',
        label: this.translate.instant('COGS_CONSUME'),
        backgroundColor: '#e6f1ec',
        data: this.reportValues[0].totals.map(item => item.saleQuantityValue),
        borderColor: 'white',
        borderWidth: 2
      }, ]
    };
    this.dataG = {
      labels: [this.translate.instant('GRAND_TOTALS')],
      datasets: [{
        type: 'bar',
        label: this.translate.instant('TOTAL_SALES'),
        backgroundColor: '#367f5c',
        data: [this.reportValues[0].locations[0].totalSales[0].total],
        borderColor: 'white',
        borderWidth: 2
      }, {
        type: 'bar',
        label: this.translate.instant('TOTAL_CONSUMETION'),
        backgroundColor: '#71b69b',
        data: [this.reportValues[0].locations[0].lastQuantityValue],
        borderColor: 'white',
        borderWidth: 2
      }, {
        type: 'bar',
        label: this.translate.instant('TOTAL_PROFIT'),
        backgroundColor: '#afd8ca',
        data: [(this.reportValues[0].locations[0].totalSales[0].total -
            this.reportValues[0].locations[0].lastQuantityValue)],
        borderColor: 'white',
        borderWidth: 2
      }],

    };
    this.chartOptions =  {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
            autoSkip: false,  // Otomatik atlama işlevini devre dışı bırakır
            maxRotation: 0,   // Etiketlerin yatayda (düz) görünmesini sağlar
            minRotation: 0    // En küçük dönüş açısı
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057',
            maxRotation: 0,
            minRotation: 0
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
    this.basicData = {
      labels: [this.translate.instant('TOTAL_SALES'), this.translate.instant('LAST_QUANTITY_VALUE'),
        this.translate.instant('TOTAL_PROFIT')],
      datasets: [
        {
          backgroundColor: '#e6f1ec',
          data: [this.reportValues[0].locations[0].totalSales[0].total.toLocaleString(),
            this.reportValues[0].locations[0].lastQuantityValue.toLocaleString(),
            (this.reportValues[0].locations[0].totalSales[0].total.toLocaleString() -
              this.reportValues[0].locations[0].lastQuantityValue.toLocaleString())
          ],
          dataPointWidth: 10,
        }
      ]
    };
    this.horizontalOptions = {
      type: 'column',
      dataPointWidth: 2, // Sütun genişliğini burada belirleyin
      dataPointMaxWidth: 2, // Sütun genişliğini burada belirleyin
      dataPointMinWidth: 2, // Sütun genişliğini burada belirleyin
      indexAxis: 'y',
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057',
            mirror: true,
            min: 0,
            max: 10,
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };

    this.chartOptions2 = {
        animationEnabled: true,
        title: {
          text: this.translate.instant('GRAND_TOTALS')
        },
        axisX: {
        },
        axisY: {
        },
        toolTip: {
          shared: true
        },
        legend: {
          cursor: 'pointer',
          itemclick: (e: any) => {
            if (typeof(e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
              e.dataSeries.visible = false;
            }
            else {
              e.dataSeries.visible = true;
            }
            e.chart.render();
          }
        },
        data: [{
          type: 'column',
          name: '',
          dataPoints: [
            {label: this.translate.instant('TOTAL_SALES'),
              y: parseFloat(this.reportValues[0].locations[0].totalSales[0].netTotal), color: '#367f5c'},
            {label: this.translate.instant('TOTAL_CONSUMETION'),
              y: parseFloat(this.reportValues[0].locations[0].lastQuantityValue), color: '#71b69b'},
            {label: this.translate.instant('TOTAL_PROFIT'),
              y: parseFloat(String(this.reportValues[0].locations[0].totalSales[0].netTotal -
                this.reportValues[0].locations[0].lastQuantityValue)), color: '#afd8ca'}
          ]
        }]
  };
  }
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const  pdfheight = 297; // Resmin yüksekliği
      const  pdfwidth = 217;   // Resmin genişliği


      const pdf = new jsPDF({
        unit: 'mm',
        format: [pdfwidth, pdfheight],
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.internal.pageSize.height = pdfHeight;
      pdf.addImage(imgData, 'JPEG', 10, 10, pdfWidth * 0.9, pdfHeight * 0.9);

      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`inventory.pdf`); // PDF'i indir
    });

  }
  downloadPdf(): void {
    const companyObject = JSON.parse(localStorage.getItem('company'));
    const companyID = companyObject.id;
    const url = `${this.baseURL}/api/pdf/creator/inventoryReport?location=${this.parameters.location}&inventory=${this.parameters.inventory}&category=${this.parameters.category}&companyId=${companyID}`;
    window.open(url, '_blank');
  }
}
