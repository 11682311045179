import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';

export class CompanyIntegrationModel extends ApiResourceModel {
  public static IRI = '/api/company_integrations';
  company: string;
  location: LocationModel;
  integration: string;
  isActive: boolean;
}
