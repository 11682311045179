import {ApiResourceModel} from './api-resource.model';
import {CreditItems} from './credit-items.model';
import {ContactModel} from './contact.model';
import {CurrencyModel} from './currency.model';
import {InvoiceModel} from './invoice.model';

export class CreditModel extends ApiResourceModel{
  public static IRI = '/api/credits';
  public items: CreditItems[] = [];
  public contact: ContactModel;
  public location: Location;
  public status: string;
  public code: string;
  public series: string;
  public number: number;
  public subtotal: number;
  public tax: number;
  public total: number;
  public totalUnits: number;
  public currency: CurrencyModel;
  public invoice: InvoiceModel;
  public id: any;
}
