import {ApiResourceModel} from './api-resource.model';

export class StockMediaObjectModel extends ApiResourceModel{
  public static IRI = '/api/stock_media_objects';
  stock: string;
  variant: string;
  mediaObject: string;
  showInWorkOrder: boolean;
  description: string;
}
