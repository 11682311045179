<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'ORDERS_BY_SUPPLIER' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <p-button icon="pi pi-question" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
      <form (ngSubmit)="load(null, 'search')">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-lg-2 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                      name="search" pInputText placeholder="{{ 'GLOBAL_SEARCH'| translate }}"  [(ngModel)]="parameters.name" (change)="changeNameInput($event)" />
          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                        [options]="searchContacts" [(ngModel)]="parameters['supplier.id']" (onChange)="changeContact($event)"
                        placeholder="{{ 'SELECT_SUPPLIER' | translate}}"
                        optionLabel="name" optionValue="id"[filter]="true" filterBy="name">
            </p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <p-button label="Submit" type="submit"></p-button>
          </div>
        </div>
      </form>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table #table responsiveLayout="stack" [tableStyle]="{'min-width': '45rem'}"
               [value]="suppliers"
               [rows]="parameters.itemsPerPage" [showCurrentPageReport]="true" [resizableColumns]="true"
               styleClass="p-datatable-striped"  columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'60%'}">{{ 'SUPPLIER'| translate }}</th>
            <th [style]="{width:'15%'}">{{ 'PURCHASES'| translate }}</th>
            <th [style]="{width:'10%'}">{{ 'QUANTITY'| translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex" >
          <tr *ngIf="row.invoices.length > 0 ">
            <td>{{ row.name }}</td>
            <td [style]="{textAlign:'right'}">{{ incoiceTotalCalculator(row.invoices) | number : '1.2-2' }}</td>
            <td [style]="{textAlign:'right'}">{{ row.invoices.length}}</td>
           </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td></td>
            <td></td>
            <td>222</td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</p-card>
<p-dialog [(visible)]="staticsDisplay">
  <h4>{{ stockName }}</h4>
  <p-chart type="bar" [data]="data" [options]="chartOptions" width="700px" height="400px"></p-chart>
</p-dialog>
