<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <p-button icon="pi pi-arrow-left" [routerLink]="'/inventory'" [style]="{marginRight: '5px', float:'left'}"></p-button>
      <h3 [style]="{ fontWeight: '600', marginTop: '8px' }">{{inventory.code}}</h3></div>

    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'INVENTORY_ITEMS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <!--<p-button icon="pi pi-refresh" class="p-button-success"  label=" {{'UPDATE_INVENTORY' | translate }}"
      [style]="{marginLeft: '5px', float:'right'}"></p-button>-->
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" [style]="{padding: '20px', marginBottom:'20px'}" text="{{'CLOSE_INVENTORY_REMAINING'| translate }}">
      </p-message>
      <p-table styleClass="p-datatable-striped"  [loading]="loading" [lazy]="true" [value]="inventory.items" [totalRecords]="total" (onLazyLoad)="load($event)"
               [resizableColumns]="true" columnResizeMode="expand"
               styleClass="p-datatable-striped p-datatable-md" selectionMode="multiple"  [(selection)]="selectedItems" dataKey="code"
               (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '8%'}"pResizableColumn>{{ 'CODE'|translate }}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{ 'LOCATION'|translate }}</th>
            <th scope="col" [style]="{width: '10%'}"pResizableColumn>{{ 'STATUS'|translate }}</th>
            <th scope="col" [style]="{width: '30%'}"pResizableColumn>{{ 'CLOSED_METHOD'|translate }}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'CREATED_AT' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'CREATED_USER' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'UPDATED_AT' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'UPDATED_USER' | translate}}</th>
            <th scope="col" [style]="{width: '5%'}"pResizableColumn> <p-tableHeaderCheckbox (click)="selectAll($event)" ></p-tableHeaderCheckbox></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex">
          <tr>
            <td>{{row.code}}</td>
            <td><a  [style]="{ color:'#0ab1d2'}" routerLink="/inventory-category/{{row.id}}">{{row.location.name}}</a></td>
            <td>
              <text *ngIf="row.status === 'CLOSED_INVENTORY' " [ngStyle]="{'color' : '#444444',textAlign : 'center'}" >{{ row.status | translate }}</text>
              <text *ngIf="row.status === 'LOCKED_INVENTORY' " [ngStyle]="{'color' : '#ff2525',textAlign : 'center'}" (click)="showLockedBy(row.lockedBy)">{{row.status | translate}} </text>
              <text *ngIf="row.status === 'OPENED_INVENTORY'">{{row.status | translate}}</text>
            </td>
            <td>
              {{ row.location.inventoryCountType | translate}}
            </td>
            <td>{{row.createdAt |date: 'Y-M-d'  }}</td>
            <td>{{row.createdBy.firstName  }} {{row.createdBy.lastName  }}</td>
            <td>{{row.updatedAt |date: 'Y-M-d'  }}</td>
            <td>{{row.updatedBy.firstName  }} {{row.updatedBy.lastName  }}</td>
            <td class="p-text-right">
              <p-tableCheckbox [value]="row" *ngIf="row.status === 'OPENED_INVENTORY' || row.status === 'LOCKED_INVENTORY'"></p-tableCheckbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 50px" *ngIf="inventory.status !== 'CLOSED_INVENTORY'">
    <div class="p-col-2">
      <button pButton type="button" (click)="confirm('DELETE_INVENTORY')" class="p-button-danger"
              [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#444444'}"
              icon="pi pi-trash" label=" {{'DELETE_INVENTORY' | translate}}">
      </button>
    </div>
    <div class="p-col-2">
      <button pButton type="button"   icon="pi pi-plus"  label="{{'MOVES_CONTROL' | translate }}">
      </button>
    </div>
    <div class="p-col-2">
      <button pButton type="button" (click)="confirm('LOCKED_INVENTORY')" class="p-button-warning" icon="pi pi-key"
              [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#8f3939'}"
              label=" {{'LOCK_INVENTORY' | translate }}"></button>
    </div>
    <div class="p-col-3">
      <button pButton type="button" (click)="confirm('UNLOCK_INVENTORY')" icon="pi pi-key"
              [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#444444'}"
              label=" {{'UNLOCK_INVENTORY' | translate }}"></button>
    </div>
    <div class="p-col-3">
      <button pButton type="button" (click)="showDialog()"  icon="pi pi-pi-sync"
              [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#444444'}"
              label=" {{'UPDATE_AND_CLOSE_PERIOD' | translate }}"></button>
    </div>
  </div>
</p-card>
<p-confirmDialog header="{{ 'ALERT' | translate }}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-dialog [(visible)]="display" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    {{'UPDATE_AND_CLOSE_PERIOD' | translate }}
  </ng-template>
  Bu Depolarda İşlem Yapıyorsunuz
  <ul>
    <li *ngFor="let item of selectedItems">
      {{item.location?.name}}
    </li>
  </ul>
  Dikkat Bu İşlemler Geri Alınamaz !
  <ng-template pTemplate="footer">
    <button pButton type="button" class="p-button-primary" (click)="confirm('CLOSED_INVENTORY')" icon="pi pi-sign-in"  label=" {{'CLOSE_INVENTORY' | translate }}"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="unlockDisplay" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    {{'UPDATE_AND_CLOSE_PERIOD' | translate }}
  </ng-template>
  Bu Depolarda İşlem Yapıyorsunuz
  <ul>
    <li *ngFor="let item of selectedItems">
      {{item.location?.name}}
    </li>
  </ul>
  {{ info}}
  <ng-template pTemplate="footer">
    <button  pButton type="button" class="p-button-primary" (click)="confirm('unlock')" icon="pi pi-sign-in"  label=" {{'UNLOCK_INVENTORY' | translate }}"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
