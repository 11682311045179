<form (submit)="save()">
  <div class="p-fluid">
    <div class="p-field">
      <label for="name">{{'TAX' | translate}} <app-tooltip description="TAX_RATE_NAME_TOOLTIP"></app-tooltip></label>
      <input id="name" type="text" pInputText [(ngModel)]="item.name" name="name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-field">
      <label for="rate">{{'RATE' | translate}} <app-tooltip description="TAX_RATE_TOOLTIP"></app-tooltip></label>
      <p-inputNumber name="rate" [(ngModel)]="item.rate" id="rate" prefix="%"></p-inputNumber>
      <small class="p-error">{{ errors['rate'] }}</small>
    </div>
  </div>
  <div class="p-grid"[style]="{marginTop:'20px'}">
    <div class="p-col-4 p-offset-2">
      <button pButton type="reset" (click)="close()" [style]="{width: '100%', height:'45px',background: 'none',color: '#444444', borderColor: '#444444'}" label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2"></button>
    </div>
    <div class="p-col-4">
      <button pButton type="submit" [style]="{width: '100%', height:'45px'}" label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"></button>
    </div>
  </div>
</form>
