<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading" id="pdf-content">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PRODUCTION_REPORTS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'daily-production'"></app-page-info>
      <p-button icon="pi pi-file-excel" (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid" id="button">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>

  <app-production-report-tab pageName="daily-production"></app-production-report-tab>
  <div>
    <div class="p-grid">
      <div class="p-col-12 div-sums" style="padding: 0px !important">
        <div>
          <div class="inside-div-no-background" style="overflow: auto;max-height: 600px; padding: 3px !important">
            <p-table [value]="saleDates" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width: '7%'}" pSortableColumn="date">
                    <p-sortIcon field="date"></p-sortIcon>{{'DATE' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="cost">
                    <p-sortIcon field="cost"></p-sortIcon>{{'PRODUCTION_COST' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="salePrice">
                    <p-sortIcon field="salePrice"></p-sortIcon>{{'PRODUCTION_REVENUE' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="costRate">
                    <p-sortIcon field="costRate"></p-sortIcon>{{'COST%' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="costLocation">
                    <p-sortIcon field="costLocation"></p-sortIcon>{{'OPERATING_COST' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="costLocationRate">
                    <p-sortIcon field="costLocationRate"></p-sortIcon>{{'COST_BENEFIT_ANALYSIS' | translate}}</th>
                  <th [style]="{width: '11%'}" pSortableColumn="costLocationRate">
                    <p-sortIcon field="costLocationRate"></p-sortIcon>{{'COST%' | translate}}</th>
                  <th [style]="{width: '11%'}" pSortableColumn="profit">
                    <p-sortIcon field="profit"></p-sortIcon>{{'PROFIT_IN_LOCAL_CURRENCY' | translate}}</th>
                  <th [style]="{width: '11%'}" pSortableColumn="profitRate">
                    <p-sortIcon field="profitRate"></p-sortIcon>{{'PROFIT_MARGIN' | translate}}</th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-dates>
                <tr>
                  <td [style]="{cursor: 'pointer', color:'#0edcdc'}"
                      [routerLink]="'/work-order/work-order-reports/product-production-report'"
                      [queryParams]="{location: parameters.location, date: dates.date}">{{dates.date}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.cost | number }}{{info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.salePrice | number }}{{info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.costRate | number:'1.2-2' }}%</td>
                  <td [style]="{textAlign:'right'}">{{dates.costLocation | number}}{{info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.costLocation + dates.cost) | number}}{{info.currency}}</td>
                  <td [ngStyle]="{'color': dates.costLocationRate > 100 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{dates.costLocationRate | number:'1.2-2'}}%</td>
                  <td [ngStyle]="{'color': dates.profit < 0 ? '#ff0300' : 'black', 'textAlign': 'right'}" >{{dates.profit | number}}{{info.currency}}</td>
                  <td [ngStyle]="{'color': dates.profitRate < 0 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{dates.profitRate | number:'1.2-2'}}%</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td></td>
                  <td [style]="{textAlign:'right'}">{{ getTotal('cost') | number }}{{info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{ getTotal('salePrice') | number }}{{info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{ (getTotal('cost') / getTotal('salePrice'))*100 | number:'1.2-2' }}%</td>
                  <td [style]="{textAlign:'right'}">{{ getTotal('costLocation') | number }}{{info.currency}}</td>
                  <td [style]="{textAlign:'right'}">{{ totalCost | number }}{{info.currency}}</td>
                  <td [ngStyle]="{'color': (totalCost / getTotal('salePrice')) *100 > 100 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{ (totalCost / getTotal('salePrice')) * 100 | number:'1.2-2' }}%</td>
                  <td [ngStyle]="{'color': getTotal('profit') < 0 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{ getTotal('profit') | number }}{{info.currency}}</td>
                  <td [ngStyle]="{'color': ((getTotal('profit') / getTotal('salePrice')) *100 < 0) ? '#ff0300' : 'black', 'textAlign': 'right'}">{{ (getTotal('profit') / getTotal('salePrice')) * 100 | number:'1.2-2' }}%</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="p-col-12 div-sums p-grid p-mt-6" [style]="{padding: '0px !important'}">
        <div class="p-col-12">
          <p-chart type="bar" [data]="basicData" [options]="basicOptions" height="400px"></p-chart>
        </div>
      </div>
    </div>
  </div>
</p-card>

