<p-button icon="pi pi-question" [style]="{marginLeft: '5px', float:'right'}" (onClick)="showDialog()"></p-button>
<p-dialog header="{{'PAGE_INFO'| translate}}" position="top"
          [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}">
  <container-element [ngSwitch]="pageName">
    <some-element *ngSwitchCase="'MENU_ITEM_CREATE'">
      <app-menu-item-create-page-info></app-menu-item-create-page-info>
    </some-element>
    <some-element *ngSwitchCase="'INVENTORY'">
      <app-inventory-list-page-info></app-inventory-list-page-info>
    </some-element>
    <some-element *ngSwitchDefault>
      {{'pageName'}}"
    </some-element>
  </container-element>
</p-dialog>
