
<div *ngIf="!loading">
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <p-table [value]="workOrder.materials" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'STOCK'|translate }}</th>
        <th>{{ 'AMOUNT'|translate}}</th>
        <th>{{ 'BARCODE'|translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-material let-i="rowIndex">
      <tr *ngFor="let batch of material.batches; let k = index">
        <td>{{ material.variant.name }}</td>
        <td>{{ material.totalQuantity }}</td>
        <td>
          <p-dropdown appendTo="body" placeholder="Select barcode" [(ngModel)]="material.batches[k].batch" (onChange)="addBatch($event, i, material.variant['@id'])" [options]="batches[material.variant['@id']]" optionLabel="barcode" [optionValue]="'@id'"></p-dropdown>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-button styleClass="p-button-secondary p-mt-2" [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'" style="float: right" (onClick)="save()" [label]="'SAVE'|translate"></p-button>
</div>
