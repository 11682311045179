
<div style="
width: 60%;position: absolute;top:0px;bottom: 0px;
background-image: url('assets/okay.jpeg');
background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: -1;
    opacity: 0.1;
"></div>
<div class="p-grid" style="width: 100%">
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-6 p-lg-offset-0 p-sm-offset-0 p-xl-offset-3 p-md-offset-0">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'THAT_S_IT' | translate}}
          </h1>
      </div>
      <div class="p-col-12" style="background: #ffffff9e;
          padding: 40px 8px;
          border-radius: 10px;">
        <p [style]="{fontSize: '18px',textAlign: 'justify',fontWeight: '600'}">
          {{'INSTALL_DEMO_PRODUCTS_AND_FINISHED'| translate}}</p>
        <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">{{ 'INITIAL_PAGE_LIST_ITEM_6' | translate}}</p>
        <p><p-button label="{{'INSTALL_DEMO_PRODUCTS_AND_FINISHED'| translate}}" [style]="{width: '100%'}" (onClick)="installDemoData()" icon="pi pi-angle-right" iconPos="right"></p-button></p>

      </div>
    <!---
      <div class="p-col-12" style="background: #ffffff9e;
          padding: 40px 8px;
          border-radius: 10px;margin-top: 10px">
        <div style="font-size: 22px;font-weight: 600;">
          {{'CONTINUE_INSTALLATION'| translate}}
        </div>
        <p [style]="{textAlign: 'justify'}">{{ 'INITIAL_PAGE_LIST_ITEM_7' | translate}}</p>
        <br>
        <p [style]="{textAlign: 'justify'}">{{ 'INITIAL_PAGE_LIST_ITEM_2' | translate}}</p>
        <p [style]="{textAlign: 'justify'}">{{ 'INITIAL_PAGE_LIST_ITEM_3' | translate}}</p>
        <p [style]="{textAlign: 'justify'}">{{ 'INITIAL_PAGE_LIST_ITEM_4' | translate}}</p>
        <p [style]="{textAlign: 'justify'}">{{ 'INITIAL_PAGE_LIST_ITEM_5' | translate}}</p>
        <p><p-button label="{{'CONTINUE_INSTALLATION'| translate}}" [style]="{width: '100%'}" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button></p>

      </div>
      --->
    </div>
  </div>
</div>
<router-outlet></router-outlet>
