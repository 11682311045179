<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form action="" *ngIf="!loading && !isConnected">
  <div class="p-fluid">
    <div class="p-field">
      <label for="username">{{ 'USERNAME'|translate }}</label>
      <input id="username" type="text" [name]="'username'" pInputText [(ngModel)]="attributes.username" autofocus>
    </div>
    <div class="p-field">
      <label for="password">{{ 'PASSWROD'|translate }}</label>
      <input id="password" type="password" [name]="'password'" pInputText [(ngModel)]="attributes.password" autofocus>
    </div>
  </div>
  <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
</form>
<div *ngIf="!loading && isConnected">
  <img src="https://www.pazarlamasyon.com/wp-content/uploads/2019/05/parasut.jpg" width="250px" alt="">
  <p-divider></p-divider>
  <h2 class="p-d-inline-block"><i class="pi pi-user" style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_CONTACT'|translate }}</h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3" [disabled]="contactsLoading" [style]="{'float': 'right' }" label="" [icon]="contactsLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'" (click)="syncContacts()"></p-button>
  <p-divider></p-divider>
  <!---
  <h2 class="p-d-inline-block"><i class="pi pi-folder" style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_PRODUCT'|translate }}</h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3" [disabled]="productsLoading" [style]="{'float': 'right' }" label="" [icon]="productsLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'" (click)="syncProducts()"></p-button>
  <p-divider></p-divider>
  <h2 class="p-d-inline-block"><i class="pi pi-folder" style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_CATEGORY'|translate }}</h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3" [disabled]="categoriesLoading" [style]="{'float': 'right' }" label="" [icon]="categoriesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'" (click)="syncCategories()"></p-button>
  <p-divider></p-divider>
  <h2 class="p-d-inline-block"><i class="pi pi-folder" style="font-size: 1.5rem; margin-right: 10px"></i> {{ 'SYNC_LOCATION'|translate }}</h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3" [disabled]="locationsLoading" [style]="{'float': 'right' }" label="" [icon]="locationsLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'" (click)="syncLocations()"></p-button>
  <p-divider></p-divider>
  --->
</div>

