<p-card>
<div class="p-grid">
  <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'IRREGULAR_PRICES' | translate}}</h3></div>
  <div class="p-col-6 p-md-6 p-sm-6">
    <app-page-info [pageName]="'IRREGULAR_PRICES'"></app-page-info>
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
</div>
<div class="p-grid">
  <div class="p-col-12" style="overflow-x: auto;">


  </div>
</div>
</p-card>
