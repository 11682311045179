import {HttpService} from './http.service';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import {LocationModel} from '../model/location.model';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UserModel} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
 locations = new BehaviorSubject<LocationModel[]>( null);
 user: UserModel = new UserModel();
 total = new BehaviorSubject<number>( 0);
  constructor(private http: HttpService, private translate: TranslateService) { }

  getLocations(): Observable<LocationModel[]>{
    return this.locations.asObservable();
  }
  getLocationAction(params = {}): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl' , params).toPromise().then(response => {
      this.locations.next(response);
      this.total.next(response.length);
      }
    );
  }
  getTotal(): Observable<number>{
    return this.total.asObservable();
  }

  getSearchLocation(params = {}): any [] {
    const searchLocations = [];
    this.http.get(LocationModel.IRI, params).toPromise().then(response => {
      response['hydra:member'].map(item => {
        searchLocations.push({ name: item.name, id: item.id});
      });
      searchLocations.unshift({ name: this.translate.instant('ALL_LOCATIONS'), id: null});
    });
    return searchLocations;
  }

  // tslint:disable-next-line:typedef
  getFilesystem() {
    return this.http.get(`${LocationModel.IRI}/main-location`)
      .toPromise()
      .then(res => res['hydra:member'] as TreeNode[]);
  }
  unsubscribe(): void {
    this.locations.next(null);
    this.locations.unsubscribe();
  }


}
