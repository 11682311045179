import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../service/http.service';
import {WorkOrderOperationModel} from '../../model/work-order-operation.model';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {InvoiceModel, InvoiceProductionStatus} from '../../model/invoice.model';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../model/user.model';
import {DataTableSettingService} from '../../service/data-table-setting.service';
import { environment } from '../../../environments/environment';
import {DialogService} from 'primeng/dynamicdialog';
import {InvoiceProductionsComponent} from '../invoice/invoice-productions/invoice-productions.component';
import {WorkOrderModel} from '../../model/work-order.model';
import {WorkOrderMaterialModel} from '../../model/work-order-material.model';
import {WorkOrderCloseComponent} from '../work-order/work-order-close/work-order-close.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DialogService]
})
export class HomeComponent implements OnInit {

  operations: WorkOrderOperationModel[] = [];
  filteredData: WorkOrderOperationModel[] = [];
  loading: boolean;
  total: number;
  apiUrl: string;
  printing: number;
  isTrackByLot: boolean;

  invoiceProductionStatus = InvoiceProductionStatus;

  started = `${InvoiceProductionStatus.Status_Started},${InvoiceProductionStatus.Status_Paused},${InvoiceProductionStatus.Status_Not_Started}`;

  user: UserModel = new UserModel();
  settings = [];

  parameters = {
    status: this.started,
    page: 1,
    itemsPerPage: 100,
    'workOrder.code': '',
    'workOrder.variant.name': '',
    'workOrder.invoice.contact.name': '',
    'operation.name': '',
    'resource.name': '',
    plannedQuantity: '',
    plannedTime: '',
    'workOrder.description': ''
  };

  constructor(private http: HttpService, private confirmationService: ConfirmationService, private translate: TranslateService,
              private dataTableSettingService: DataTableSettingService, private dialog: DialogService) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
  }

  load(event: LazyLoadEvent = null): void {

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }

    this.loading = true;

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));

    this.http.get(WorkOrderOperationModel.IRI, this.parameters).subscribe((response) => {
      this.operations = response['hydra:member'];
      this.filteredData = response['hydra:member'];
      this.total = response['hydra:totalItems'];
      this.loading = false;

    });
  }

  changeStatus(event, id: number, status: string, row: WorkOrderOperationModel = null): void {
    if (row) {
      // @ts-ignore
      row.workOrder.materials.map((material: WorkOrderMaterialModel) => {
        // @ts-ignore
        this.isTrackByLot = material.variant.stock.trackType === 'LOT';
      });
    }

    if (status === InvoiceProductionStatus.Status_Finished) {
      if (this.isTrackByLot && row.isLastOperation) {
        this.selectBatches(row);
      } else {
        this.confirmationService.confirm({
          message: this.translate.instant('ALERT_COMPLETE_OPERATION'),
          acceptLabel: this.translate.instant('YES'),
          rejectLabel: this.translate.instant('NO'),
          accept: () => {
            this.loading = true;
            this.http.put(`${WorkOrderOperationModel.IRI}/${id}`, {status}).then((response) => {
              this.load();
            });
          }
        });
      }
    } else {
      this.loading = true;
      this.http.put(`${WorkOrderOperationModel.IRI}/${id}`, {status}).then((response) => {
        this.load();
      });
    }
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      'productions',
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  showProductions(row: WorkOrderOperationModel): void {
    let id = null;
    let type = 'workOrder';

    // @ts-ignore
    if (row.workOrder.invoice) {
      // @ts-ignore
      id = row.workOrder.invoice.id;
      type = 'invoice';
    } else {
      id = row.workOrder.id;
    }

    this.dialog.open(InvoiceProductionsComponent, {
      width: '50vw',
      header: this.translate.instant('PRODUCTION_STATUS'),
      // @ts-ignore
      data: {id, type}
    });
  }

  print(workOrderId: number, id: number): void  {
    this.printing = id;

    this.http.get(`${WorkOrderModel.IRI}/${workOrderId}/pdf`).subscribe(response => {
      this.printing = null;
      window.open(response.response, '_blank');
    });
  }

  selectBatches(row: WorkOrderOperationModel): void {
    const ref = this.dialog.open(WorkOrderCloseComponent, {
      width: '50vw',
      header: this.translate.instant('PRODUCTION_STATUS'),
      data: {id: row.workOrder.id, operationId: row.id}
    });

    ref.onClose.subscribe(response => {
      if (response.saved) {
        this.load();
      }
    });
  }
}
