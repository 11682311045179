import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {StockTransfersModel, TransferStatus} from '../../../model/stock-transfers.model';
import {LocationModel} from '../../../model/location.model';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CurrencyService} from '../../../service/currency.service';
import {Router} from '@angular/router';
import {StockModel} from '../../../model/stock.model';
import {UnitModel} from '../../../model/unit.model';
import {InventoryModel} from '../../../model/inventory.model';
import {UserPageInformationModel} from '../../../model/user-page-information.model';
import {UserModel} from '../../../model/user.model';
import {UserPageInformationService} from '../../../service/user-page-information.service';


@Component({
  selector: 'app-stock-transfers',
  templateUrl: './stock-transfers.component.html',
  styleUrls: ['./stock-transfers.component.scss']
})
export class StockTransfersComponent implements OnInit {
  items: StockTransfersModel[] = [];
  item: StockTransfersModel = new StockTransfersModel();
  locations: LocationModel[] = [];
  destinationLocations: any[] = [];
  loading: boolean;
  settings = [];
  parameters = {
    code: '',
    transferDate: '',
    origin: '',
    destination: '',
    amount: '',
    page: 1,
    itemsPerPage: 10,
    status: ''

  };
  total: 0;
  // tslint:disable-next-line:max-line-length
  transferStatuses: any[];
  moreLoading: boolean;
  display: boolean;
  displayAction: boolean;
  private actions: any;
  minDate: Date;
  maxDate: Date;
  activeInventory: InventoryModel;
  search: boolean;
  infoTextShowDisplay = false;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;

  constructor(private http: HttpService,
              private translate: TranslateService,
              private flashMessageService: FlashMessageService,
              private currencyService: CurrencyService,
              private router: Router,
              private userPageInformationService: UserPageInformationService) {
  }

  ngOnInit(): void {
    this.getUserInformation();
    this.loading = true;
    this.search = false;
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });
    this.http.get(LocationModel.IRI, {pagination: false}).subscribe(response => {
      this.destinationLocations = response['hydra:member'];
    });
    this.load();
    this.setTransferStatus();
  }

  setTransferStatus(): void {
    // @ts-ignore
    this.transferStatuses = [
      {name: this.translate.instant('ALL_TRANSFERS'), value: ''},
      {name: this.translate.instant(TransferStatus.Request), value: TransferStatus.Request},
      {name: this.translate.instant(TransferStatus.Preparing), value: TransferStatus.Preparing},
      {name: this.translate.instant(TransferStatus.Sent), value: TransferStatus.Sent},
      {name: this.translate.instant(TransferStatus.Rejected), value: TransferStatus.Rejected},

    ];
  }

  changeStatus = (event, id) => {
    this.http.put(`${StockTransfersModel.IRI}/${id}`, {status: event.value}).then((response: StockTransfersModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
    });
  };

  // tslint:disable-next-line:typedef

  load(event: LazyLoadEvent = null, type = null): void {
    this.loading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    this.parameters[`order[transferDate]`] = 'DESC';

    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }
    let parameters = {...this.parameters};
    if (this.parameters.transferDate) {
      const start = new Date(this.parameters.transferDate[0]);
      const end = new Date(this.parameters.transferDate[1]);
      parameters = {
        ...parameters, ...{
          'transferDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'transferDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };
    }
    this.parameters = parameters;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(StockTransfersModel.IRI, this.parameters).subscribe((response: StockTransfersModel[]) => {
      this.items = response['hydra:member'];
      // @ts-ignore
      this.total = response['hydra:totalItems'];
      if (this.total <= this.parameters.page * this.parameters.itemsPerPage || this.total === 0) {
        this.moreLoading = false;
      } else {
        this.moreLoading = true;
      }
      this.loading = false;
    });

  }

  async getActiveInventory(): Promise<any> {
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      this.activeInventory = response;
      this.minDate = new Date(this.activeInventory.startDate);
      this.maxDate = new Date(this.activeInventory.endDate);
    });
  }

  reset(): void {
    this.router.navigate([`/stock-transfers`]);
  }

  openDialog(): void {
    if (this.checkActionPermission('CAN_ADD_NEW_STOCK')) {
      this.item = new StockTransfersModel();
      this.display = true;
    } else {
      this.displayAction = true;
    }
  }

  reload(): void {
    this.load(null, 'search');
  }

  async edit(event): Promise<void> {
    if (this.checkActionPermission('CAN_CONFIRM_STOCK_TRANSFERS')) {
      await this.http.get(`${StockTransfersModel.IRI}/${event.id}`).subscribe((response) => {
        this.item = response;
        this.item.origin = this.item.origin ? this.item.origin['@id'] : null;
        this.item.destination = this.item.destination ? this.item.destination['@id'] : null;
        this.display = true;
      });
    } else {
      this.displayAction = true;
    }
  }

  onSelectOrigin(event): void {
    this.parameters.origin = event.value;
  }

  onSelectDestination(event): void {
    this.parameters.destination = event.value;
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].inventory.findIndex(obj => obj.name === text);
      return this.actions[0].inventory[findIndex].value ? this.actions[0].inventory[findIndex].value : false;
    }
  }

  onSearch(): void {
    this.search = true;
  }

  getUserInformation(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.userPageInformationService.getItemsAction({user: this.userId, name: 'StockTransfers'});
    this.userPageInformationService.getItems().subscribe(response => {
      if (response !== null) {
        this.infoTextData = response;
        this.infoTextShowDisplay = this.infoTextData[0].isActive;
      } else {
        this.infoTextShowDisplay = true;
      }
    });
  }
  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'StockTransfers';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'StockTransfers'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }
}
