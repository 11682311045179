<div style="
width: 60%;position: absolute;top:0px;bottom: 0px;
background-image: url('assets/okay.jpeg');
background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: -1;
    opacity: 0.1;
"></div>
<div class="p-grid" style="width: 100%">
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-6 p-lg-offset-0 p-sm-offset-0 p-xl-offset-3 p-md-offset-0">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'INITIAL_PAGE_TITLE' | translate}}
        </h1>
      </div>

      <div class="p-col-12" style="background: #ffffff9e;
          padding: 40px 8px;
          border-radius: 10px;">
        <div class="p-p-3 p-text-left" [style]="{fontSize: '20px'}">
          <p [style]="{fontSize: '18px',textAlign: 'justify',fontWeight: '600'}">
            {{'INITIAL_PAGE_DESCRIPTION'| translate}}</p>
        </div>
        <div class="p-p-3 p-text-left">
            <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">1 -{{ 'INITIAL_PAGE_LIST_ITEM_1' | translate}}</p>
            <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">2- {{ 'INITIAL_PAGE_LIST_ITEM_8' | translate}}</p>
            <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">3- {{ 'INITIAL_PAGE_LIST_ITEM_9' | translate}}</p>
            <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">4- {{ 'INITIAL_PAGE_LIST_ITEM_10' | translate}}</p>
            <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">5- {{ 'INITIAL_PAGE_LIST_ITEM_11' | translate}}</p>
        </div>
        <p><p-button label="{{'LETS_GO' | translate}}" [style]="{float: 'right'}" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button></p>


      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
