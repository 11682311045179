import {Component, OnInit} from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../service/location.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {StockModel} from "../../../../model/stock.model";
import {StockVariantModel} from "../../../../model/stock-variant.model";
import {UtilityService} from "../../../../service/utility.service";

@Component({
  selector: 'app-product-production-report',
  templateUrl: './product-production-report.component.html',
  styleUrls: ['./product-production-report.component.scss']
})
export class ProductProductionReportComponent implements OnInit {
  events: any[] = [];
  options: any;
  dataIncoming: any;
  info: any;
  sale: any;
  saleDates: any;
  categories: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  basicData: any;
  basicOptions: any;
  totalProdCost = 0;
  totalProdInc = 0;
  totalCost = 0;
  location: LocationModel[] = [];
  stocks: StockVariantModel[] = [];
  dateRange: Date[] = [];
  first = 0;
  parameters = {
    id: '',
    date: '',
    startDate: '',
    lastDate: '',
    location: ''
  };

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService,
              private utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.route.queryParams.subscribe(params => {
      if (params.date || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.date;
        // @ts-ignore
        this.parameters.lastDate = params.date;
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

        const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));
        console.log(dateRange);

        const selectedDate = dateRange.map(date => this.getFormattedDate(date));

        this.parameters.date = selectedDate.join(',');

        this.parameters.location = params.location ? params.location : '';
      }
      else {
        this.getThisWeekDates();
      }
    });
    this.http.get('/api/dasboard_reports', {'startDate': this.parameters.startDate, 'endDate': this.parameters.lastDate}).subscribe(response => {
      this.info = response;
      this.dateRange = [new Date(this.info.startDate), new Date(this.info.lastDate)];
    });
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get('/api/reports/work_order_items_reports', this.parameters).subscribe(response => {
      this.saleDates = response;
      this.loading = false;
    });
    this.parameters.id = '';
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    if (event[1] === null){
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    }else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }
    this.parameters = {...this.parameters};


  }
  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }
  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  getThisWeekDates(): void {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Haftanın ilk gününü al
    const thisWeekDates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      thisWeekDates.push(this.getFormattedDate(date));
    }
    this.parameters.startDate = this.getFormattedDate(firstDayOfWeek);
    this.parameters.lastDate = thisWeekDates[thisWeekDates.length-1];
    this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

    this.parameters.date = thisWeekDates.join(',');

  }
  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`daily_report.pdf`); // PDF'i indir
    });
  }
  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/productWorkOrder', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'product_work_order_report.xlsx'); });
  }
  getTotal(field: string): number {
    return this.saleDates.reduce((acc, item) => {
      const fieldValue = item?.[field];
      return fieldValue ? acc + parseFloat(fieldValue) : acc;
    }, 0);
  }
  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.stocks = response['hydra:member'];
    });
  }


  async onVariantSelect(event: StockModel): Promise<void> {
    this.parameters.id = event.id;
    this.first = 0;
  }


}
