import {Component, Input, OnInit} from '@angular/core';
import {CategoryModel} from '../../../model/category.model';
import {HttpService} from '../../../service/http.service';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-manuel-sale',
  templateUrl: './manuel-sale.component.html',
  styleUrls: ['./manuel-sale.component.scss']
})
export class ManuelSaleComponent implements OnInit {
  @Input() location: number;
  categories: any[] = [];
  selectedCategories: any[] = [];
  mainCategories: CategoryModel[] = [];
  loading: boolean;
  submitted: boolean;
  mainCategory: number;
  subCategory: number;
  parameters = {
    'stock.category.id': '',
    'stock.type': 'product',
    pagination: false
  };
  products: StockVariantModel[] = [];
  saleList: any[] = [];
  keyDisplay: boolean;
  constructor(private http: HttpService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.http.get(`${CategoryModel.IRI}/main-categories`, {categoryType: 'product'})
      .subscribe(response => {
        this.mainCategories = response['hydra:member'];
      });

    this.http.get(`${CategoryModel.IRI}/category-type/product?search=no`).subscribe(category => {
      this.categories = category['hydra:member'];
    });
  }

  save(): void {
    console.log('save');
  }

  addItem(product): void {
    console.log('add_item');
    this.saleList.push(product);
  }
  selectMainCategory(id): void {
    this.products = [];
    this.mainCategory = id;
    const filterData = this.categories.filter(obj => parseFloat(obj.parent_id) === id);
    this.selectedCategories = filterData;
  }
  selectSubCategory(id): void {
    this.products = [];
    this.subCategory = id;
    this.parameters['stock.category.id'] = id;
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe(response => {
      this.products = response['hydra:member'];
    });
  }
  showKeyDisplay(item): void {
    this.keyDisplay = true;
  }
}
