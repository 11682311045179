<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{'PRODUCT_OPERATION' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">
        {{ stock.name }}
      </p>
    </ng-template>
  </p-splitter>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
    <app-product-menu [item]="stock" active="operations"></app-product-menu>

    <p-table class="p-mt-5" [value]="operation.items" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'OPERATION' | translate}} <app-tooltip [description]="'OPERATION_DESCRIPTION'"></app-tooltip></th>
          <th>{{ 'RESOURCE' | translate}} <app-tooltip [description]="'RESOURCE_DESCRIPTION'"></app-tooltip></th>
          <th *ngFor="let variantOption of stock.stockVariantOptions">
            {{ variantOption.name }}
          </th>
          <th>{{ 'DEPENDENCIES' | translate}} <app-tooltip [description]="'DEPENDENCIES_DESCRIPTION'"></app-tooltip></th>
          <th [style]="{width: '80px !important'}">{{ 'CURRENCY' | translate}} <app-tooltip [description]="'RESOURCE_CURRENCY_DESCRIPTION'"></app-tooltip></th>
          <th>{{ 'COST' | translate}} <app-tooltip [description]="'RESOURCE_COST_DESCRIPTION'"></app-tooltip></th>
          <th>{{ 'TIME' | translate}} <app-tooltip [description]="'RESOURCE_TIME_DESCRIPTION'"></app-tooltip></th>
          <th>{{ 'TOTAL_COST' | translate}} <app-tooltip [description]="'RESOURCE_TOTAL_TIME_DESCRIPTION'"></app-tooltip></th>
          <th>{{ 'NOTE' | translate}} <app-tooltip description="PRODUCT_RECIPE_NOTES_TOOLTIP"></app-tooltip></th>
          <th width="5%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown optionValue="@id" optionLabel="name" [(ngModel)]="item.operation" [options]="operations"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngFor="let o of operations">{{ o['@id'] == item.operation ? o.name : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown optionValue="@id" optionLabel="name" [(ngModel)]="item.resource" (onChange)="onSelectOperation($event, i)"  [options]="resources"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngFor="let r of resources">{{ r['@id'] == item.resource ? r.name : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn *ngFor="let variantOption of stock.stockVariantOptions;let j = index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-multiSelect  [options]="variantOption.optionValues"  [(ngModel)]="item.productOperationItemVariantOptions[j].variantOptionValues" optionLabel="name" optionValue="@id"></p-multiSelect>
              </ng-template>
              <ng-template pTemplate="output">
              <span *ngFor="let v of variantOption.optionValues">
                <span *ngFor="let k of item.productOperationItemVariantOptions[j]?.variantOptionValues">
                  {{ k === v['@id'] ? v.name : '' }}
                </span>
              </span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-multiSelect optionValue="@id" optionLabel="name" [(ngModel)]="item.dependencies"  [options]="operations"></p-multiSelect>
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngFor="let r of operations">{{ item.dependencies && item.dependencies.indexOf(r['@id']) > -1 ? r.name +' ' : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ currency }}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="currency" [currency]="currency" [(ngModel)]="item.cost" (ngModelChange)="item.totalCost = calculateTotalCost(item.cost, item.time)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.cost|currency: currency: '' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" maxFractionDigits="2" minFractionDigits="2" [(ngModel)]="item.time" (ngModelChange)="item.totalCost = calculateTotalCost(item.cost, item.time)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.time }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ item.totalCost|currency: currency: '' }}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText  name="notes" [(ngModel)]="item.notes" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.notes }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <button pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" iconPos="left" (click)="removeItem(i)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-fluid p-pt-3 p-mb-3">
      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addItem()" iconPos="left"></button>
    </div>
    <button pButton label="{{'SAVE' | translate}}" class="p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
  </div>
</p-card>
