<form (submit)="save()">
  <div class="p-fluid">
    <div class="p-field">
      <label for="name">{{'NAME' | translate}}</label>
      <input id="name" name="name" type="text" pInputText [(ngModel)]="item.name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-field">
      <label for="name">{{'CODE' | translate}}</label>
      <input id="code" name="code" type="text" pInputText [(ngModel)]="item.code" autofocus>
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-field">
      <label for="name">{{'PRICE' | translate}}</label>
      <input id="price" name="price"  type="number" pInputText [(ngModel)]="item.price" autofocus>
      <small class="p-error">{{ errors['price'] }}</small>
    </div>
    <div class="p-field">
      <label for="name">{{'UNIT' | translate}}</label>
      <input id="unit" name="unit" type="text" pInputText [(ngModel)]="item.unit" autofocus>
      <small class="p-error">{{ errors['unit'] }}</small>
    </div>
    <div class="p-field">
      <label for="name" style="display: flex;align-items: center;">
        {{'CATEGORY' | translate}}
        <span class="p-inputgroup-addon" (click)="addCategory('stock')">
         <i class="pi pi-plus"></i>
      </span>
      </label>
      <div style="width: 400px">
        <p-dropdown
          [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
          [options]="categories" appendTo="body" (ngModelChange)="onSetCategory($event)"
          [(ngModel)]="item.category" autoZIndex="true"
          optionLabel="name" optionValue="@id" placeholder="{{ 'SELECT'| translate}}"
          [filter]="true" filterBy="name">
        </p-dropdown>
      </div>

      <small class="p-error">{{ errors['unit'] }}</small>
    </div>
  </div>
  <div class="p-grid" [style]="{marginTop:'20px'}">
    <div class="p-col-4 p-offset-2">
      <button pButton type="reset" (click)="close()"
              [style]="{width: '100%', height:'45px',background: 'none',color: '#444444', borderColor: '#444444'}"
              label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2"></button>
    </div>
    <div class="p-col-4">
      <button pButton type="submit" [style]="{width: '100%', height:'45px'}" label="{{'SAVE' | translate}}"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"></button>
    </div>
  </div>
</form>
