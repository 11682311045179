import { Component, OnInit } from '@angular/core';
import {WorkOrderModel} from '../../../model/work-order.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss']
})
export class PickListComponent implements OnInit {

  workOrder: WorkOrderModel = new WorkOrderModel();
  id: number;
  loading: boolean;

  constructor(private http: HttpService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loading = true;
    // tslint:disable-next-line:radix
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));

    this.http.get(`${WorkOrderModel.IRI}/${this.id}`).subscribe((response: WorkOrderModel) => {
      this.workOrder = response;
      this.loading = false;
    });
  }
}
