<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading" id="pdf-content">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'DAILY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'PRODUCT_GROUP'"></app-page-info>
      <p-button icon="pi pi-file-excel" (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid" id="button">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start"  selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
      [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
      [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
      placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <app-report-tabs pageName="product-group"></app-report-tabs>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-col div-sums">
        <div>
          <div class="inside-div-no-background">
            <p-table [value]="categories.categories" dataKey="mCatId" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width: '5%'}"></th>
                  <th [style]="{width: '47%'}">{{'PRODUCT_GROUP' | translate}}</th>
                  <th [style]="{width: '12%', textAlign:'center'}">{{'NET_SALES' | translate}}</th>
                  <th [style]="{width: '12%', textAlign:'center'}">{{'TAX' | translate}}</th>
                  <th [style]="{width: '12%', textAlign:'center'}">{{'DISCOUNT' | translate}}</th>
                  <th [style]="{width: '12%', textAlign:'center'}">{{'TOTAL' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-cat let-expanded="expanded">
                <tr>
                  <td [style]="{textAlign:'left'}">
                    <button type="button" pButton pRipple [pRowToggler]="cat"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  </td>
                  <td [style]="{textAlign:'left', cursor: 'pointer'}"
                      [routerLink]="['/reports/sale-reports/menu-item-group']"
                      [queryParams]="{startDate: parameters.startDate, lastDate: parameters.lastDate, location: parameters.location, category: cat.mCatId}"
                      (mouseover)="toggleTooltip(cat.mCatId)" (mouseout)="toggleTooltip(cat.id)">
                    {{cat.name}}
                    <div *ngIf="showTooltipMap.get(cat.mCatId)" class="tooltip">
                      {{cat.name}} kategorisinin Menü Ürün Grubunu görmek için tıklayın
                    </div>
                  </td>
                  <td
                    [style]="{textAlign:'center'}">{{(cat.sumSales?.total ? cat.sumSales?.total: 0 )| number: '1.1-2'}}{{info.currency}}</td>
                  <td
                    [style]="{textAlign:'center'}">{{cat.sumSales?.taxTotal | number: '1.1-2'}}{{info.currency}}</td>
                  <td
                    [style]="{textAlign:'center'}">{{cat.sumSales?.discountTotal | number: '1.1-2'}}{{info.currency}}</td>
                  <td [style]="{textAlign:'center'}">{{ (cat.sumSales?.subTotal) | number: '1.1-2'}}{{info.currency}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-cat>
                <tr>
                  <td colspan="6" style="padding: 0">
                    <p-table [value]="cat.subCategories" styleClass="p-datatable-sm" dataKey="subCatId"
                             [style]="{width: '100%'}">
                      <ng-template pTemplate="body" let-sub let-expanded="expanded">
                        <tr [style]="{cursor: 'pointed'}">
                          <td [style]="{width: '5%', textAlign:'right'}">
                            <button type="button" pButton pRipple [pRowToggler]="sub"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                          </td>
                          <td [style]="{width: '47%', textAlign:'left'}">{{sub.name}}</td>
                          <td
                            [style]="{textAlign:'right'}">{{sub.sumSales?.total | number: '1.1-2'}}{{info.currency}}</td>
                          <td
                            [style]="{textAlign:'right'}">{{sub.sumSales?.taxTotal | number: '1.1-2'}}{{info.currency}}</td>
                          <td
                            [style]="{textAlign:'right'}">{{sub.sumSales?.discountTotal| number: '1.1-2'}}{{info.currency}}</td>
                          <td
                            [style]="{textAlign:'right'}">{{sub.sumSales?.subTotal  | number: '1.1-2'}}{{info.currency}}</td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="rowexpansion" let-sub>
                        <tr>
                          <td colspan="6" style="padding: 0">
                            <p-table [value]="sub.menuItem" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                              <ng-template pTemplate="body" let-item>
                                <tr [style]="{cursor: 'pointed'}">
                                  <td [style]="{width: '5%', textAlign:'left'}"></td>
                                  <td [style]="{width: '47%', textAlign:'left'}">{{item.name}}</td>
                                  <td
                                    [style]="{textAlign:'right'}">{{item.sumSales?.total | number: '1.1-2'}}{{info.currency}}</td>
                                  <td
                                    [style]="{textAlign:'right'}">{{item.sumSales?.taxTotal | number: '1.1-2'}}{{info.currency}}</td>
                                  <td
                                    [style]="{textAlign:'right'}">{{item.sumSales?.discountTotal  | number: '1.1-2'}}{{info.currency}}</td>
                                  <td
                                    [style]="{textAlign:'right'}">{{item.sumSales?.subTotal | number: '1.1-2'}}{{info.currency}}</td>
                                </tr>
                              </ng-template>
                            </p-table>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>

