import {ApiResourceModel} from './api-resource.model';
import {ContactModel} from './contact.model';
import {InvoiceItemModel} from './invoice-item.model';
import {WorkOrderModel} from './work-order.model';
import {CurrencyModel} from './currency.model';
import {UserModel} from './user.model';
import {ShipmentModel} from './shipment.model';
import {CreditModel} from './credit.model';
import {IncomingInvoiceModel} from './incoming-invoice.model';

export class InvoiceModel extends ApiResourceModel {
  public static IRI = '/api/invoices';
  public contact: ContactModel|string;
  public customerName: string;
  public location: Location|string;
  public totalUnits: number;
  public subtotal: number;
  public tax: number;
  public total: number;
  public expectedDate: Date|string;
  public type: string;
  public isReceived: boolean;
  public materialStatus: string;
  public itemStatus: string;
  public status: string;
  public code: string;
  public series: string;
  public number: number;
  public items: InvoiceItemModel[] = [];
  public description: string;
  public discountRate: number;
  public discount: number;
  public workOrder: WorkOrderModel|string;
  public currency: CurrencyModel;
  public normalizedSubtotal: number;
  public normalizedTax: number;
  public normalizedTotal: number;
  public shipmentCode: string;
  public shipmentLink: string;
  public shippingAddress;
  public deliveryAddress;
  public billingAddress;
  public rank: number;
  public isInvoiced: boolean;
  public tags: string;
  public createdBy: UserModel[] = [];
  public shipments: ShipmentModel[] = [];
  public credits: CreditModel[] = [];
  public incomingInvoices: IncomingInvoiceModel[] = [];
  public accountingCode: any;
  public purchaseInvoice: number;
  public invoiceNumber: string;

}

export enum InvoiceStatus {
  Confirmed = 'CONFIRMED',
  Pending = 'WAITING_FOR_APPROVAL',
  Declined = 'DECLINED',
  Send = 'SEND',
  Created = 'CREATED',
  Returned = 'RETURNED',
  Viewed_Supplier= 'VIEWED_SUPPLIER',
  Declined_Supplier= 'DECLINED_SUPPLIER',
  Confirmed_Supplier= 'CONFIRMED_SUPPLIER',
}

export enum InvoiceType {
  Sales = 'SALES',
  Purchase = 'PURCHASE',
  Return = 'RETURN'
}

export enum InvoiceDeliveryStatus {
  DELIVERY_STATUS_PENDING = 'PENDING',
  DELIVERY_STATUS_PACKAGED = 'PACKAGED',
  DELIVERY_STATUS_DELIVERED = 'DELIVERED',
  DELIVERY_STATUS_PARTIAL_DELIVERED = 'PARTIAL_DELIVERED',
  DELIVERY_STATUS_READY = 'READY',
}

export enum InvoiceStockStatus {
  StatusAvailable= 'AVAILABLE',
  StatusNotAvailable= 'NOT_AVAILABLE',
  StatusExpected= 'EXPECTED',
  StatusNotRecipe= 'NOT_RECIPE',
}

export enum InvoiceProductionStatus {
  Status_Not_Started = 'NOT_STARTED',
  Status_Started = 'STARTED',
  Status_Finished = 'FINISHED',
  Status_Paused = 'PAUSED',
}




