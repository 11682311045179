import { Component, OnInit } from '@angular/core';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {StockModel} from '../../../model/stock.model';
import {StockVariantOptionModel} from '../../../model/stock-variant-option.model';
import {CurrencyModel} from '../../../model/currency.model';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {UserModel} from '../../../model/user.model';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../service/utility.service';
import {CurrencyService} from '../../../service/currency.service';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {StockTransactionsComponent} from '../../stock/stock-transactions/stock-transactions.component';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {Subscription} from 'rxjs';
import {CategoryService} from '../../../service/category.service';
import {ContactService} from '../../../service/contact.service';
import {LocationModel} from '../../../model/location.model';


@Component({
  selector: 'app-setting-inventory-items',
  templateUrl: './setting-inventory-items.component.html',
  styleUrls: ['./setting-inventory-items.component.scss'],
  providers: [DialogService]
})
export class SettingInventoryItemsComponent implements OnInit {

  items: StockVariantModel[] = [];
  item: StockModel = new StockModel();
  variantOption: StockVariantOptionModel = new StockVariantOptionModel();
  loading: boolean;
  total: number;
  currency: CurrencyModel = new CurrencyModel();
  companyIntegration: CompanyIntegrationModel[] = [];
  entities = [];
  user: UserModel = new UserModel();
  settings = [];

  parameters = {
    id: '',
    name: '',
    code: '',
    type: 'material',
    'category.id': '',
    'supplier.id': '',
    'locations.id': '',
    page: 1,
    itemsPerPage: 20,
  };
  menuItems: MenuItem[];
  display: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  stockModel: StockModel[];
  id: number;
  errors = [];

  suppliers: ContactModel[] = [];
  taxes: TaxRateModel[] = [];
  categories: CategoryModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  trackTypes: any[] = [];
  variantOptions: StockVariantOptionModel[] = [];
  validationSubscriber: Subscription;
  contactTypes: [{ name: any; value: any }, {name: any, value: any }];
  type: string;

  constructor(private http: HttpService, private confirmationService: ConfirmationService, private dialog: DialogService,
              private translate: TranslateService, private utilityService: UtilityService, private currencyService: CurrencyService,
              private categoryService: CategoryService, private contactService: ContactService,
              private dataTableSettingService: DataTableSettingService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuItems = [
        { label: this.translate.instant(' ADD'),
          icon: 'pi pi-plus',
          command: () => {
           this.openForm();
          } },
      ];
    }, 1000);
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.entities.push('stock');
    this.currency = this.currencyService.getCurrency();
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.companyIntegration = response['hydra:member'];
    });
    this.loadSearchData().then();
  }

  load(event: LazyLoadEvent = null): void{
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.loading = true;
    this.http.get(StockModel.IRI, this.parameters).subscribe((response: StockModel[]) => {
      this.items = response['hydra:member'];
      // @ts-ignore
      this.total = response['hydra:totalItems'];
      this.loading = false;
    });
  }
  delete(event, row): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translate.instant('SUCCESS_DELETED'),
      header: this.translate.instant('ALERT'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translate.instant('YES'),
      rejectLabel: this.translate.instant('NO'),
      accept: () => {
        this.http.delete(`${StockModel.IRI}/${row.id}`).then(() => {
          this.items = this.items.filter(val => val.id !== row.id);
        });
      }
    });
  }
  showTransactions(id: number, status: string): void {
    const ref = this.dialog.open(StockTransactionsComponent, {
      width: '50vw',
      header: this.translate.instant('STOCK_TRANSACTIONS'),
      data: {variantId: id, status, isActive: 1}
    });
  }
  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;
    delete parameters.id;

    this.http.download('/api/data/exports/stocks', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'stocks.xlsx'); });
  }
  onResize(event): void {
    this.dataTableSettingService.save(
      'stocks',
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }
  async edit(event): Promise<void> {
    this.loading = true;
    this.displayCreate = false;
    this.displayUpdate = true;
    this.http.get(`${StockModel.IRI}/${event.id}`).subscribe((response) => {
      this.item = response;
      this.item.category = this.item.category ? this.item.category['@id'] : null;
      this.item.locations = this.item.locations ? this.item.locations : null;
      this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
      this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
      this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
      // @ts-ignore
      this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
      this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
      this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
      this.item.purchaseOptions.map((data, i) => {
        this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
          this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
          this.item.purchaseOptions[i].supplier = data.supplier['@id'];
          this.item.purchaseOptions[i].unit = data.unit['@id'];
          if (data.packName === '' && data.packPerCase === null ){
            this.item.purchaseOptions[i].orderingUnit = data.packQuantity  + ' ' + unit['hydra:member'][0].name;
          }else{
            this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity  + ' ' + unit['hydra:member'][0].name +  ')';
          }

        });
      });
      this.loading = false;

    });

    this.display = true;

  }
  reload(): void {
    this.displayCreate = false;
    this.displayUpdate = false;
    this.display = false;
    this.load();
    this.item = null;
  }
  openForm(): void {
    this.display = true;
    this.displayCreate = true;
    this.displayUpdate = false;
  }
  async loadSearchData(): Promise<void> {
    await this.categoryService.getCategoriesAction({isActive: true});
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
    });
    await this.contactService.getContactsAction({pagination: false, type: 'supplier'});
    this.contactService.getContacts().subscribe(response => {
      this.suppliers = response;
    });

    await this.http.get(LocationModel.IRI + '/subscriptionControl', { pagination: false}).subscribe(response => {
      this.locations = response;
    });
  }
  changeParameters(event, type): void {
    if (type === 'category'){
      this.parameters['category.id'] = event.value;
    }
    if (type === 'supplier'){
      this.parameters['supplier.id'] = event.value;
    }
    if (type === 'locations'){
      this.parameters['locations.id'] = event.value;
    }
    this.load();
  }

}
