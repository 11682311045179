import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../../../../service/http.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TaxRateModel} from '../../../../model/tax-rate.model';
import {CompanyModel} from '../../../../model/company.model';
import {Subscription} from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ValidationService} from '../../../../service/validation.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {TaxService} from '../../../../service/tax.service';
import {CreateTaxComponent} from '../../../settings/tax/create-tax/create-tax.component';

@Component({
  selector: 'app-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.scss'],
  providers: [DialogService]
})
export class AddTaxComponent implements OnInit, OnDestroy {

  items: TaxRateModel[] = [];
  activeItems: TaxRateModel[] = [];
  item: TaxRateModel = new TaxRateModel();
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean;
  errors = [];

  validationSubscriber: Subscription;

  constructor(private router: Router, private http: HttpService, private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private validationService: ValidationService, private flashMessageService: FlashMessageService,
              private taxService: TaxService, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.load();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });

  }
  load(): void {
    this.taxService.getTaxesAction({isActive: true});

    this.loading = true;

    this.taxService.getTaxes().subscribe(response => {
      if (response) {
        this.items = response;

        this.items.map(item => {
          if (item.isActive) {
            this.activeItems.push(item);
          }
        });
        this.loading = false;
      }
    });
  }

  openForm(item: TaxRateModel = null): void {
    this.dialogService.open(CreateTaxComponent, {
      width: '50vw',
      header: this.translate.instant('TAX_RATE_CREATE'),
      data: {item}
    });
  }

  hideForm(): void {
    this.errors = [];
    this.showForm = false;
    this.item = new TaxRateModel();
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();

    this.taxService.taxes.next([]);
  }

  nextPage(): void {
    this.router.navigate(['initial-setup/add-category']).then(r => {
    });
  }

  previousPage(): void {
    this.router.navigate(['initial-setup/add-unit']).then(r => {
    });
  }

}
