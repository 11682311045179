import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {StockRequestItemsModel} from './stock-request-items.model';

export class StockRequestModel extends ApiResourceModel{
  public  static IRI = '/api/stock_requests';
   code: string;
   series: string;
   number: number;
   status: string;
   items: StockRequestItemsModel[] = [];
   location: LocationModel|string;
   destination: LocationModel|string;
   requestDate: Date| string;
}

export enum RequestStatus {
  Seen= 'SEEN',
  Rejected= 'REJECTED',
  Registered= 'REGISTERED'
}
