import {Component, OnInit} from '@angular/core';
import {LocationModel} from '../../../model/location.model';
import {LazyLoadEvent} from 'primeng/api';
import {IncomingInvoiceModel} from '../../../model/incoming-invoice.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {ContactService} from '../../../service/contact.service';
import {InvoiceModel} from '../../../model/invoice.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {StockModel} from '../../../model/stock.model';
import {UnitModel} from '../../../model/unit.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {isArray} from 'rxjs/internal-compatibility';
import {getHtmlHeadTagElement} from '@angular/cdk/schematics';
import {StockMatchModel} from '../../../model/stock-match-model.model';
import {promise} from 'protractor';
import {ContactModel} from '../../../model/contact.model';

@Component({
  selector: 'app-incoming-invoice-receive',
  templateUrl: './incoming-invoice-receive.component.html',
  styleUrls: ['./incoming-invoice-receive.component.scss']
})
export class IncomingInvoiceReceiveComponent implements OnInit {
  loading: boolean;
  locations: LocationModel[] = [];
  incomingInvoice: IncomingInvoiceModel[] = [];
  invoiceCategory: any[] = [];
  invoiceContact: ContactModel;
  total: number;
  apiUrl: string;
  searchSuppliers = [];
  invoice: InvoiceModel = new InvoiceModel();
  shipment: ShipmentModel = new ShipmentModel();
  errors = [];
  id: string;
  selectLocation: boolean;
  stocks: StockModel = new StockModel();
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  newMaterialDialog: boolean;
  item: StockModel = new StockModel();
  allergens = [];
  contactEmail: string;
  contactTaxNumber: number;
  contactName: string;
  isSaveAble: boolean;
  currencySymbol: string;
  tableLoading: boolean;

  constructor(private http: HttpService,
              private router: Router,
              private contactService: ContactService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private flashMessageService: FlashMessageService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.apiUrl = environment.apiUrl;
    this.searchSuppliers = this.contactService.getSearchContacts({pagination: false, type: 'supplier'});

    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
    });

    this.id = this.route.snapshot.paramMap.get('id');
    this.http.get(`${InvoiceModel.IRI}/invoice-number?type=purchase`).subscribe((response: InvoiceModel) => {
      this.invoice.series = response.series;
      this.invoice.number = response.number;
      this.invoice.code = response.code;
      this.invoice.expectedDate = new Date(response.expectedDate);
    });
    this.load();
  }
  async autoSync(): Promise<void> {
    const code = this.invoice.code;
    this.http.get(`${IncomingInvoiceModel.IRI}/auto-sync/${this.id}`).subscribe((response: IncomingInvoiceModel) => {
      this.load();
    });
  }
  load(): void {
    this.tableLoading = true;
    this.invoice = new InvoiceModel();
    this.http.get(`${IncomingInvoiceModel.IRI}/${this.id}`).subscribe((response: IncomingInvoiceModel) => {

      this.shipment.documentNumber = response.invoiceNumber;
      this.shipment.documentDate = new Date(response.invoiceDate);
      // @ts-ignore
      this.invoiceContact = response.contact;
      this.invoice.currency = response.currency;
      this.currencySymbol = response.currency.symbol;
      this.invoice.total = response.total;
      this.invoice.subtotal = response.subtotal;
      this.invoice.tax = response.tax;
      this.invoice.discount = response.discount;
      this.invoice.location = response.location['@id'];
      this.invoice.contact = response.contact['@id'];
      let accountCode = '';
      response.incomingInvoiceItems.map((item) => {
        // @ts-ignore
        item.variant?.stock.category.stockAccountingCodes.map(codes => {
          if (codes.location['@id'] === this.invoice.location) {
            accountCode =  codes.code;
          }
        });
        // @ts-ignore
        // @ts-ignore
        const items = {
          // @ts-ignore
          unitPrice: item.price,
          // @ts-ignore
          name: item.name,
          // @ts-ignore
          quantity: item.quantity,
          // @ts-ignore
          incomingQuantity: item.quantity,
          // @ts-ignore
          total: item.total + item.tax,
          // @ts-ignore
          subtotal: item.total,
          // @ts-ignore
          tax: item.tax,
          // @ts-ignore
          taxRate: item.variant?.stock.tax ? item.variant?.stock.tax : null,
          // @ts-ignore
          discount: item.discountAmount,
          // @ts-ignore
          variant: item.variant ? item.variant :  null ,
          stock: item.stock ? item.stock :  null ,
          purchaseOption: item.purchaseOption ? item.purchaseOption['@id'] : null,
          optionsValue: item.variant?.stock.purchaseOptions ? item.variant?.stock.purchaseOptions : null,
          accountingCode: accountCode,
          itemTaxRate: item.taxRate,
          stockTaxRate: item.variant?.stock.tax.taxRate ? item.variant?.stock.tax.taxRate : null,
        };
        // @ts-ignore
        this.invoice.items.push(items);
      });
      this.invoice.total = response.total;
      // @ts-ignore
      this.contactEmail = response.contact.email;
      // @ts-ignore
      this.contactTaxNumber = response.contact.taxAgencyNumber;
      // @ts-ignore
      this.contactName = response.contact.name;
      this.loading = false;
      this.tableLoading = false;
    });
    this.rowControl();
    this.http.get(`${InvoiceModel.IRI}/invoice-number?type=purchase`).subscribe((response: InvoiceModel) => {
      this.invoice.series = response.series;
      this.invoice.number = response.number;
      this.invoice.code = response.code;
      this.invoice.expectedDate = new Date(response.expectedDate);
    });
  }

  removeVariant(i): void {
    this.invoice.items[i].variant = null;
    this.invoice.items[i].purchaseOption = null;
    this.invoice.items[i].optionsValue = null;
  }

  changeLocation(event, value = null): void {
    this.selectLocation = true;
    this.invoice.location = event.value;
    const choseId = event.value.split('/');
    // this.parameters.locations = event.value;
    this.http.get(StockVariantModel.IRI, {
      location: choseId[3],
      pagination: false
    }).subscribe((response: StockVariantModel) => {
      this.stocks = response['hydra:member'];
    });
  }

  changeSupplier(event, value = null): void {
    this.invoice.contact = event.value;
  }

  searchVariant = event => {
    this.http.get(StockModel.IRI, {name: event.query, type: 'material'}).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  }

  onVariantSelect(event: StockModel, i: number, name = null): void {
    this.invoice.items[i].variant = event.variants[0];
    this.invoice.items[i].stock = event['@id'];
    this.invoice.items[i].optionsValue = event.purchaseOptions;
    // @ts-ignore
    event.category.stockAccountingCodes.map(item => {
      if (item.location['@id'] === this.invoice.location) {
        this.invoice.items[i].accountingCode = item.code;
      }
    });
    this.invoice.items[i].taxRate = event.tax;
    this.rowControl();
  }
  test(event, i, item): void {
    this.invoice.items[i].purchaseOption = event.value;

    this.http.post(`${StockMatchModel.IRI}/new-match`, {
      name: item.name,
      variant: item.variant['@id'],
      stock: item.stock,
      purchaseOption: event.value,
    }).then((response: StockModel) => {
    });
  }
  save(): void {
    this.invoice.type = 'PURCHASE';
    this.invoice.status = 'CONFIRMED';
    this.invoice.isInvoiced = false;
    // @ts-ignore
    if (this.invoice.contact?.name){
      this.invoice.contact = this.invoice.contact['@id'];
    }
    this.invoice.currency = this.invoice.currency['@id'];
    this.invoice.items.map((item, i) => {
      item.variant = item.variant['@id'];
      item.stock = item.stock['@id'];
      item.taxRate = item.taxRate['@id'];
    });
    this.http.post(InvoiceModel.IRI, this.invoice).then((response: InvoiceModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
      this.http.get(InvoiceModel.IRI + '/asdlasd', this.invoice).subscribe();
      this.http.put(`${IncomingInvoiceModel.IRI}/${this.id}`, { invoice: response['@id']})
        .then((res: IncomingInvoiceModel) => {
          window.location.href = '/order/receive-manuel/' + response.id + ';type=INVOICE';
        }).catch(err => {
      });
    }).catch(err => {
    });
  }

  rowControl(): void {
    /*
    this.isSaveAble = true;
    this.invoice.items.map(item => {
      this.isSaveAble = !item.variant;
    });
     */
  }
}

