<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'RESOURCES' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'RESOURCES_DESCRIPTION' | translate }}</p>
      <button pButton label="{{'NEW' | translate}}" icon="pi pi-plus" class="p-button-success new" (click)="openForm()"></button>
    </ng-template>
  </p-splitter>
  <p-checkbox name="showAll" class="p-mb-3" [value]="showAll" (click)="showAll = !showAll" [label]="'SHOW_ALL'|translate"></p-checkbox>
  <p-table [loading]="loading" [lazy]="true" [value]="items">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'RESOURCE' | translate}} <app-tooltip description="RESOURCE_NAME_TOOLTIP"></app-tooltip></th>
        <th>{{'COST' | translate}} <app-tooltip description="RESOURCE_COST_TOOLTIP"></app-tooltip></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr *ngIf="showAll || row.isActive">
        <td><a href="javascript:void (0)" (click)="edit(row)">{{ row.name }}</a></td>
        <td>{{ row.price }}</td>
        <td class="p-text-right">
          <p-inputSwitch [(ngModel)]="row.isActive" (onChange)="changeStatus(row)" ></p-inputSwitch>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<p-sidebar [(visible)]="showForm" position="right"  [modal]="true" styleClass="p-sidebar-lg">
  <div class="p-fluid">
    <h2>{{'RESOURCE_CREATE' | translate}}</h2>
    <div class="p-field">
      <label for="name">{{'RESOURCE' | translate}} <app-tooltip description="RESOURCE_NAME_TOOLTIP"></app-tooltip></label>
      <input id="name" type="text" pInputText [(ngModel)]="item.name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-field">
      <label for="price">{{'COST' | translate}} <app-tooltip description="RESOURCE_COST_TOOLTIP"></app-tooltip></label>
      <p-inputNumber [(ngModel)]="item.price" mode="decimal" minFractionDigits="2" maxFractionDigits="2" id="price"></p-inputNumber>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2" (click)="hideForm()" ></button>
    <button pButton label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
  </ng-template>
</p-sidebar>

<p-confirmPopup></p-confirmPopup>
