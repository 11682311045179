<p-card>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'LOCATION_EXPENSES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'LOCATION_EXPENSES'"></app-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-checkbox [(ngModel)]="isFilter" [binary]="true" inputId="binary" (onChange)="locationFilter()"></p-checkbox>
      <label for="binary">  {{'OWNER_LOCATIONS' | translate}}</label>
      <p-divider></p-divider>
      <p-table [loading]="loading" [lazy]="true" [value]="items" *ngIf="!loading"
               [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'2%'}"></th>
            <th scope="col" class="p-text-left" [style]="{width:'60%'}">{{'LOCATION' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'DAILY_EXPENSES' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'MONTHLY_EXPENSES' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'YEARLY_EXPENSES' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td class="p-text-left">{{i+1}}</td>
            <td class="p-text-left"><a href="javascript:void(0)" (click)="edit(item)">{{item.name}}</a></td>
            <td class="p-text-right">{{(item.locationExpenses[0]? item.locationExpenses[0].total / 30 : 0) | currency: item.currency.symbol }}</td>
            <td class="p-text-right">{{(item.locationExpenses[0]? item.locationExpenses[0].total : 0) | currency: item.currency.symbol }}</td>
            <td class="p-text-right">{{(item.locationExpenses[0]? item.locationExpenses[0].total * 12 : 0) | currency: item.currency.symbol }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>
<p-sidebar [(visible)]="display" [style]="{width:'50em'}" *ngIf="display" position="right">
  <div class="p-grid grid-div">
    <div class="p-col div-sums">
      <span class="title">{{'DAILY_EXPENSES'| translate}}</span>
      <span class="total-price">{{ item.locationExpenses[0]?.total / 30 | currency: item.currency.symbol }}

      </span>
    </div>
    <div class="p-col div-sums">
      <span class="title">{{'MONTHLY_EXPENSES'| translate}}</span>
      <span class="total-price">{{ item.locationExpenses[0]?.total | currency: item.currency.symbol }}

      </span>
    </div>
    <div class="p-col div-sums">
      <span class="title">{{'YEARLY_EXPENSES'| translate}}</span>
      <span class="total-price">{{ item.locationExpenses[0]?.total * 12 | currency: item.currency.symbol }}

      </span>
    </div>
  </div>

  <!----Modifier ve Extralar yoksa---->
  <div class="p-grid">
    <div class="p-col-12" [style]="{marginTop: '20px'}">
      <p-table [value]="this.item.locationExpenses[0].items" dataKey="id" styleClass="p-datatable-sm" [loading]="submitted">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'3%'}"></th>
            <th [style]="{width:'70%'}" scope="col">{{ 'EXPENSE_TYPE' | translate}}</th>
            <th [style]="{width:'30%'}" scope="col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td>{{i+1}}</td>
            <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete appendTo="body" [(ngModel)]="autoCompleteValues[i]"
                                (onSelect)="onExpenseSelect($event, i)" field="name" [suggestions]="expenses"
                                (completeMethod)="searchExpense($event)"></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ autoCompleteValues[i]?.name }}
              </ng-template>
            </p-cellEditor>
          </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                                 [(ngModel)]="item.amount"
                                 (ngModelChange)="calculate()">

                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.amount | number: '1.2-2' }}
                </ng-template>
              </p-cellEditor>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
    <button pButton class="p-mb-3 add-button "
            [icon]="'pi pi-plus'"
            (click)="addItems()">
  </button>
  <button pButton label="{{'EXPENSE_TYPE' | translate}}" class="p-mb-3 add-button "
          [icon]="'pi pi-plus'"
          (click)="addExpenseType()">
  </button>
  <p-button [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'" [style]="{width:'100%'}"
            type="submit" label="{{'SAVE' | translate}}" (onClick)="save()">
  </p-button>
</p-sidebar>
