import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CategoryModel} from '../model/category.model';
import {HttpService} from './http.service';
import {TreeNode} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {main} from "@angular/compiler-cli/src/main";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  categories = new BehaviorSubject<CategoryModel[]>(null);

  constructor(private http: HttpService, private translate: TranslateService) {
  }

  getCategories(): Observable<CategoryModel[]> {
    return this.categories.asObservable();
  }

  getCategoriesAction(params = {}): void {
    this.http.get(CategoryModel.IRI, params).toPromise().then(response => {
      this.categories.next(response['hydra:member']);
    });
  }

  getProductCategories(): void {
    this.http.get(`${CategoryModel.IRI}/category-type/product?search=yes`).subscribe(category => {
      this.categories.next(category['hydra:member']);
    });
  }
  getStockCategories(): void {
    this.http.get(`${CategoryModel.IRI}/category-type/stock?search=yes`).subscribe(category => {
      this.categories.next(category['hydra:member']);
    });
  }

  getSearchCategories(params = {}): any [] {
    const searchCategories = [];
    this.http.get(CategoryModel.IRI, params).toPromise().then(response => {
      response['hydra:member'].map((item) => {
        if (item.parent) {
          searchCategories.push({name: item.name, id: item.id, parent: item.parent});
        }
      });
      searchCategories.unshift({name: this.translate.instant('ALL_CATEGORIES'), id: null});
    });
    return searchCategories;
  }

  getSearchCategory(params = {}): any [] {
    const searchCategories = [];
    let i = 0;
    searchCategories.unshift({name: this.translate.instant('ALL_CATEGORIES'), id: null});
    this.http.get(CategoryModel.IRI, params).toPromise().then(response => {
      response['hydra:member'].map((item) => {
        if (!item.parent) {
          i = 0;
          for (const cat of searchCategories){
            if (item.name === cat.name){
              i = 1;
            }
          }
          if (i === 0 ){
            searchCategories.push({name: item.name, id: item.name, parent: item.parent});
          }
        }
      });
    });
    return searchCategories;
  }


  async save(category: CategoryModel): Promise<any> {
    return await this.http.post(CategoryModel.IRI, category).then((response: CategoryModel) => {
      if (response !== undefined) {
        const categories = this.categories.getValue();
        categories.push(response);
        this.categories.next(categories);
      }
      return response;
    });
  }

  async update(category: CategoryModel): Promise<any> {
    return await this.http.put(`${CategoryModel.IRI}/${category.id}`, category).then(response => {
      return response;
    });
  }

  // tslint:disable-next-line:typedef
  getFilesystem(params = {}) {
    return this.http.get(`${CategoryModel.IRI}/main-categories`, params)
      .toPromise()
      .then(res => res['hydra:member'] as TreeNode[]);
  }

  unsubscribe(): void {
    this.categories.next([]);
    this.categories.unsubscribe();
  }
}
