import {Component, OnInit} from '@angular/core';
import {HttpService} from "../../../../service/http.service";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {CompanyIntegrationModel} from "../../../../model/company-integration.model";
import {QueueModel} from '../../../../model/queue.model';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';

@Component({
  selector: 'app-menulux',
  templateUrl: './menulux.component.html',
  styleUrls: ['./menulux.component.scss']
})
export class MenuluxComponent implements OnInit {

  loading: boolean;
  isConnected: boolean;
  contactsLoading: boolean;
  productsLoading: boolean;
  locationLoading: boolean;
  productLoading: boolean;
  categoryLoading: boolean;
  tSalesLoading: boolean;
  ySalesLoading: boolean;
  l7dSalesLoading: boolean;
  mSalesLoading: boolean;
  lmSalesLoading: boolean;
  dateRangeSalesLoading: boolean;
  dateSalesLoading: boolean;
  submitted: boolean;
  attributes = {
    apitoken: '',
    location: null
  };
  parameters = {
    date: '',
  };
  rangeDates: any;
  startDate: string;
  startDateValue: string;
  endDate: string;
  errorMessageStatus: boolean;
  errorMessage: '';
  monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  queueDisplay = false;

  constructor(private http: HttpService, private config: DynamicDialogConfig, private flashMessageService: FlashMessageService) {
  }

  ngOnInit(): void {
    this.isConnected = this.config.data.isConnected;
    this.attributes.location = '/api/locations/' + this.config.data.location;
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'PRODUCT_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
    });
  }

  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'PRODUCT_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 10000);
    }
  }


  async save(): Promise<void> {
    this.submitted = true;

    await this.http.post(`${CompanyIntegrationModel.IRI}/menulux-connect`, this.attributes).then(response => {
      this.isConnected = true;
    });

    this.submitted = false;
  }

  syncLocation(): void {
    this.errorMessageStatus = false;
    this.locationLoading = true;

    this.http.get('/api/company_integrations/menulux/location/' + this.config.data.location).subscribe(
      response => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.locationLoading = false;
      },
      error => {
        if (error.status === 500) {
          this.errorMessageStatus = true;
          this.errorMessage = error.error.detail;
          this.locationLoading = false;
        }
      }
    );

  }

  syncCategories(): void {
    this.errorMessageStatus = false;
    this.categoryLoading = true;
    this.http.get('/api/company_integrations/menulux/category/' + this.config.data.location).subscribe(
      response => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.categoryLoading = false;
      },
      error => {
        if (error.status === 500) {
          this.errorMessageStatus = true;
          this.errorMessage = error.error.detail;
          this.categoryLoading = false;
        }
      }
    );
  }

  syncProducts(): void {
    this.productLoading = true;
    this.errorMessageStatus = false;
    this.http.get('/api/company_integrations/menulux/product/' + this.config.data.location).subscribe(
      response => {
        this.queueDisplay = true;
        this.controlImportStatus();
        this.productLoading = false;
      },
      error => {
        if (error.status === 500) {
          this.errorMessageStatus = true;
          this.errorMessage = error.error.detail;
          this.productLoading = false;
        }
      }
    );
  }

  syncSales(option): void {
    if (option === 'T') {
      this.tSalesLoading = true;
      option= '';
    }
    if (option === 'Y') {
      this.ySalesLoading = true;
    }
    if (option === 'L7D') {
      this.l7dSalesLoading = true;
    }
    if (option === 'M') {
      this.mSalesLoading = true;
    }
    if (option === 'LM') {
      this.lmSalesLoading = true;
    }
    if (option === 'DR') {
      this.dateRangeSalesLoading = true;
      option = '?start_date=' + this.startDate + '&end_date=' + this.endDate;
    }
    if (option === 'SD') {
      this.dateSalesLoading = true;
      option = 'SD&specificDate=' + this.startDate;
    }



    this.http.get('/api/company_integrations/menulux/sales/' + this.config.data.location + option).subscribe(response => {
      this.tSalesLoading = false;
      this.ySalesLoading = false;
      this.l7dSalesLoading = false;
      this.mSalesLoading = false;
      this.lmSalesLoading = false;
      this.dateRangeSalesLoading = false;
      this.dateSalesLoading = false;
    });
  }

  onSelectDate(event): void {
    const start = new Date(this.startDateValue);
    const day = start.getDate() < 10 ? `0${start.getDate()}` : start.getDate();
    this.startDate = `${day}-${this.monthNames[start.getMonth()]}-${start.getFullYear()}`;
  }

  onSelectRangeDate(): void {
    console.log(this.config.data);
    let parameters = {...this.parameters};
    if (this.parameters.date) {
      const start = new Date(this.parameters.date[0]);
      const end = new Date(this.parameters.date[1]);
      const startDay = start.getDate().toString().padStart(2, '0');
      const startMonth = (start.getMonth() + 1).toString().padStart(2, '0');
      const startYear = start.getFullYear();
      const endDay = end.getDate().toString().padStart(2, '0');
      const endMonth = (end.getMonth() + 1).toString().padStart(2, '0');
      const endYear = end.getFullYear();
      parameters = {
        ...parameters, ...{
          'startDate': `${startYear}-${startMonth}-${startDay}T00:00:00`,
          'endDate': `${endYear}-${endMonth}-${endDay}T23:59:59`
        }
      };
    }
    this.startDate = parameters['startDate'];
    this.endDate = parameters['endDate'];
    this.parameters = parameters;

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    console.log(this.parameters);

  }


}

