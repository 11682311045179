
<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'STOCK_MOVEMENTS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'STOCK_MOVEMENTS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <form action="" (ngSubmit)="load()">
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  inputId="dropdown" placeholder="{{ 'SELECT' | translate}}"
                  (onChange)="parameters.variantId = $event.value"
                  appendTo="body"
                  [filter]="true" filterBy="name"
                  [options]="variants" name="materialStatus"
                  [(ngModel)]="parameters.variantId" optionLabel="name"
                  optionValue="id"></p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
        <p-calendar id="start" name="start"  selectionMode="range"
                    [style]="{width: '100%'}"
                    [ngModel]="parameters.start" (ngModelChange)="onStartChange($event)"
                    dateFormat="yy-mm-dd" placeholder="{{'SELECT_TRANSFER_DATE'| translate}}">
        </p-calendar>
       <!-- <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span> -->
      </div>

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  inputId="dropdown" placeholder="{{ 'SELECT_STATUS' | translate}}"
                  (onChange)="parameters.relatedType = $event.value"
                  appendTo="body"
                  [options]="stockMovementTypes" name="materialStatus"
                  [(ngModel)]="parameters.relatedType" optionLabel="name"
                  optionValue="value"></p-dropdown>

    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  inputId="dropdown" placeholder="{{ 'SELECT_LOCATION' | translate}}"
                  (onChange)="parameters.location = $event.value;"
                  appendTo="body"
                  [options]="locations" name="movementLocation" [(ngModel)]="parameters.location"
                  optionLabel="name"
                  optionValue="id"></p-dropdown>

    </div>
    <div class="p-col-6 p-md-3 p-lg-2">

      <p-button label="Submit" type="submit"></p-button>


    </div>
  </div>
  </form>
  <div class="p-grid">
    <div class="p-col-12">
      <app-flash-messages></app-flash-messages>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="items" [tableStyle]="{'min-width': '50rem'}" [loading]="loading"
               [rows]="20" [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'15%'}"> {{'DATE' | translate}}</th>
            <th [style]="{width:'8%'}"> {{'CODE' | translate}}</th>
            <th [style]="{width:'15%'}"> {{'ACTION'| translate}}</th>
            <th [style]="{width:'10%'}"> {{'QUANTITY' | translate}}</th>
            <th [style]="{width:'10%'}"> {{'PRICE' | translate}}</th>
            <th [style]="{width:'10%'}"> {{'VALUE' | translate}}</th>
            <th [style]="{width:'10%'}"> {{'STOCK_QUANTITY' | translate}}</th>
            <th [style]="{width:'10%'}"> {{'VALUE' | translate}}</th>
            <th [style]="{width:'10%'}"> {{'AVERAGE_PRICE_DESCRIPTION' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td> {{ item.createdAt | date: 'Y-M-dd H:M:s' }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.type | translate }}</td>
            <td>
              <span *ngIf="item.type !== 'PURCHASE' && item.type !== 'ADD_STOCK_TRANSFER' && item.type !== 'INVENTORY_STOCK_TRANSFER' ">-</span>
              {{ item.quantity | number: '1.2-2'}}
            </td>
            <td> {{ item.unitPrice | number: '1.2-2'}}</td>
            <td> <span *ngIf="item.type !== 'PURCHASE' && item.type !== 'ADD_STOCK_TRANSFER' && item.type !== 'INVENTORY_STOCK_TRANSFER' ">-</span>
              {{ item.valueInStock | number: '1.2-2'}}</td>
            <td>{{ item.quantityAfter | number: '1.2-2'}}</td>
            <td>{{ item.quantityAfter * item.unitPrice | number: '1.2-2'}}</td>
            <td> {{ item.unitPrice | number: '1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="load()"></button>
        </div>
      </div>

    </div>
  </div>
</p-card>
