<p-card>
  <p-splitter [panelSizes]="[30,70]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'DATA_IMPORT' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'DATA_IMPORT_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <p-divider></p-divider>
  <div *ngIf="true">
    <div>
      <div class="p-pt-1">
        <p>{{ 'CUSTOMER_IMPORT'|translate }}</p>
        <p-button icon="pi pi-download" (click)="download('contacts')" styleClass="p-button-text" [label]="'DOWNLOAD_TEMPLATE'|translate"></p-button>
        <p-fileUpload name="file" class="p-d-inline-block" styleClass="p-button-text" chooseIcon="pi pi-upload" mode="basic" [chooseLabel]="'IMPORT'|translate" #fileUpload auto="true" customUpload="true" multiple="multiple" (uploadHandler)="upload($event, 'contact', fileUpload)"></p-fileUpload>
        <div class="p-mt-4" *ngIf="entity === 'contact'">
          <p-message severity="success" *ngIf="response?.valid > 0" class="p-d-block" [text]="response?.valid + ' öğe başarıyla kaydedildi.'"></p-message>
          <p-message severity="error" *ngIf="response?.invalid > 0" class="p-d-block"  [text]="response?.invalid + ' öğe kaydedilemedi'"></p-message>
          <app-loading-indicator [loading]="loading"></app-loading-indicator>
        </div>
      </div>
    </div>
    <div>
      <div class="p-pt-1">
        <p>{{ 'STOCK_IMPORT'|translate }}</p>
        <div class="required">
          <div class="required-row">
            <div class="required-title">{{'NAME' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'NAME_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'CODE' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'CODE_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'CATEGORY' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'CATEGORY_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'TAGS' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'TAGS_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'TAX' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'TAX_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'BARCODE' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'BARCODE_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'SUPPLIER' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'SUPPLIER_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'CURRENCY' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'CURRENCY_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'UNIT' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'UNIT_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'PRICE' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'PRICE_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'ALERT_LEVEL' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'ALERT_LEVEL_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'MIN_ORDER' | translate}} <span class="required-text">({{ 'REQUIRED' | translate }})</span> </div>
            <div class="required-tooltip">{{'MIN_ORDER_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'LOCATION1' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'LOCATION_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'LOCATION2' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'LOCATION_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'LOCATION3' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'LOCATION_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'LOCATION4' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'LOCATION_TOOLTIP' | translate}}</div>
          </div>
          <div class="required-row">
            <div class="required-title">{{'LOCATION5' | translate}} <span class="required-text"></span> </div>
            <div class="required-tooltip">{{'LOCATION_TOOLTIP' | translate}}</div>
          </div>
        </div>
        <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" text="{{'STOCK_IMPORT_REMAINING'| translate }}">

        </p-message>

        <p-button icon="pi pi-download" (click)="download('stocks')" styleClass="p-button-text" [label]="'DOWNLOAD_TEMPLATE'|translate"></p-button>
        <p-fileUpload name="file" class="p-d-inline-block" styleClass="p-button-text" chooseIcon="pi pi-upload" mode="basic"
                      [chooseLabel]="'IMPORT'|translate"  #fileUpload auto="true" customUpload="true"
                      multiple="multiple" (uploadHandler)="upload($event, 'stock', fileUpload)"></p-fileUpload>
        <div class="p-mt-4" *ngIf="entity === 'stock'">
          <p-table [value]="response?.errors">
            <ng-template pTemplate="body" let-error>
              <tr>
                <td><p-message severity="error" *ngIf="response?.errors.length > 0" class="p-d-block" [text]="error"></p-message></td>
              </tr>
            </ng-template>
          </p-table>

          <p-message severity="success" *ngIf="response?.valid > 0" class="p-d-block" [text]="response?.valid + ' öğe başarıyla kaydedildi.'"></p-message>
          <p-message severity="error" *ngIf="response?.invalid > 0" class="p-d-block"  [text]="response?.invalid + ' öğe kaydedilemedi'"></p-message>
          <app-loading-indicator [loading]="loading"></app-loading-indicator>
        </div>

      </div>
    </div>
    <div>
      <div class="p-pt-1">
        <p>{{ 'MENU_ITEMS_IMPORT'|translate }}</p>
        <p-button icon="pi pi-download" (click)="download('product')" styleClass="p-button-text" [label]="'DOWNLOAD_TEMPLATE'|translate"></p-button>
        <p-fileUpload name="file" class="p-d-inline-block" styleClass="p-button-text" chooseIcon="pi pi-upload"
                      mode="basic" [chooseLabel]="'IMPORT'|translate" #fileUpload auto="true" customUpload="true"
                      multiple="multiple" (uploadHandler)="upload($event, 'product', fileUpload)"></p-fileUpload>
        <div class="p-mt-4" *ngIf="entity === 'contact'">
          <p-message severity="success" *ngIf="response?.valid > 0" class="p-d-block" [text]="response?.valid + ' öğe başarıyla kaydedildi.'"></p-message>
          <p-message severity="error" *ngIf="response?.invalid > 0" class="p-d-block"  [text]="response?.invalid + ' öğe kaydedilemedi'"></p-message>
          <app-loading-indicator [loading]="loading"></app-loading-indicator>
        </div>
        <p-divider></p-divider>
      </div>
    </div>
  </div>
</p-card>
