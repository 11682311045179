<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card class="cardarea" *ngIf="loading === false">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600'}">
        <button pButton pRipple [routerLink]="['/orders/receive-order']" icon="pi pi-arrow-left"></button>
        {{ (shipment.documentType | translate) }}
        {{ shipment.documentNumber ? shipment.documentNumber : ''}}
      </h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'ORDER_DETAIL'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
      <p-splitButton *ngIf="editAction === true" [style]="{marginLeft: '5px', float:'right'}"
                     [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" label="{{ 'SAVE' | translate }}"
                     (onClick)="save()" [disabled]="this.submitted"></p-splitButton>
      <p-button *ngIf="editAction === false && invoice.isReceived === false"
                [style]="{marginLeft: '5px', float:'right'}" label="{{ 'EDIT' | translate }}"
                (onClick)="edit()"></p-button>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === true">
    <div class="p-col-12 p-md-6 p-lg-66">
      <div class="warning-div">
        {{ 'RECEIVE_DESCRIPTION' | translate}}
      </div>
    </div>
  </div>
  <p-message *ngIf="!isCanBeSend" [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info"
             text="Seçtiğiniz Tarih Açık Olan Envanter Başlangıç Tarihin {{ isCanBeSelectDate | date : 'Y-M-dd' }}'den Daha Eski Olamaz"
             styleClass="mr-2"></p-message>

  <div class="p-grid" *ngIf="editAction === false" style="margin-top: 30px;">
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'DOCUMENT_DATE'| translate}}</b>
      <span [style]="{float: 'right'}">{{ shipment.documentDate | date: 'Y/MM/d' }}</span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'PAYMENT_DUE_DATE'| translate}}</b><span
      [style]="{float: 'right'}">{{ shipment.paymentDate | date }}</span>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === false">
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'TOTAL_INCL_TAX' | translate}}</b>
      <span [style]="{float: 'right'}">{{ shipment.invoice.total | currency: shipment.invoice.currency.symbol}}</span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'TOTAL_EXCL_TAX' | translate}}</b>
      <span
        [style]="{float: 'right'}">{{ shipment.invoice.subtotal | currency: shipment.invoice.currency.symbol}}</span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'DOCUMENT_TYPE'| translate}}</b>
      <span [style]="{float: 'right'}">{{ shipment.documentType | translate }} {{ shipment.documentNumber }} </span>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === true" style="margin-top: 30px;">
    <div class="p-col-12 p-md-6 p-lg-3">
      <label [style]="{fontSize: '14px'}" class="p-pb-2">{{'DOCUMENT_TYPE' | translate}}</label>
      <div class="p-grid">
        <div class="p-col-12">
          <p-dropdown dataKey="getDocument" [placeholder]="'SELECT'|translate" [(ngModel)]="shipment.documentType"
                      [options]="documentType" optionLabel="name" optionValue="value"></p-dropdown>
          <small class="p-error">{{ errors['documentType'] }}</small>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="documentNumber" [style]="{fontSize: '14px'}">{{'DOCUMENT_NUMBER' | translate}}</label>
      <input id="documentNumber" [style]="{height: '45px',width:'100% !important'}"
             [(ngModel)]="shipment.documentNumber" type="text" pInputText autofocus>
      <small class="p-error">{{ totalError.length > 0 ? totalError[0].documentNumber : '' }}</small>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="documentDate" [style]="{fontSize: '10px'}">{{'DOCUMENT_DATE' | translate}}</label>
      <p-calendar id="documentDate" [style]="{height: '30px'}" (onSelect)="shipmentDateControl($event)"
                  [(ngModel)]="shipment.documentDate" [minDate]="minDate" [maxDate]="today" dateFormat="mm-dd-yy"
                  inputId="dateformat"></p-calendar>
      <small class="p-error">{{ errors['documentDate']}}</small>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === true">
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="totalIncTax" [style]="{fontSize: '14px'}">{{'TOTAL_INCL_TAX' | translate}}</label>
      <p-inputNumber id="totalIncTax" [style]="{height: '45px',width:'100% !important'}" [(ngModel)]="shipment.total"
                     mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"></p-inputNumber>
      <small>{{ 'GRAND_TOTAL_CODE' | translate}}: {{ invoice.total | currency: currencyLabel}}</small>
      <small class="p-error">{{ totalError.length > 0 ? totalError[0].shipmentTotal : '' }}</small>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="paymentDueDate" [style]="{fontSize: '14px'}">{{'PAYMENT_DUE_DATE' | translate}}</label>
      <p-calendar id="paymentDueDate" [(ngModel)]="shipment.paymentDate" dateFormat="mm-dd-yy"
                  inputId="dateformat"></p-calendar>
      <small class="p-error"></small>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 40px">
    <div class="p-col-12 p-md-6 p-lg-3"></div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="field-checkbox">
        <p-checkbox [(ngModel)]="discountRateChecked" [binary]="true" id="rate"></p-checkbox>
        <label for="rate">{{'SHOW_DISCOUNT_COLUMN'|translate}}</label>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [style]="{display: 'none'}">
      <div class="field-checkbox">
        <p-checkbox [(ngModel)]="discountValueChecked" [binary]="true" id="value"></p-checkbox>
        <label for="value">2. {{'SHOW_DISCOUNT_COLUMN'|translate}}</label>
      </div>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 5px">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="invoice.items" [loading]="tableLoading">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'6%'}">{{ 'CODE' | translate}}</th>
            <th [style]="{width:'16%'}">{{ 'PRODUCT_NAME' | translate}}</th>
            <th [style]="{width:'5%'}">{{ 'ORDER_QUANTITY' | translate}}</th>
            <th [style]="{width:'5%'}">{{ 'DOCUMENT_QUANTITY' | translate}}</th>
            <th [style]="{width:'7%'}">{{ 'PRICE' | translate}}</th>
            <th [style]="{width:'6%'}" *ngIf="priceChange">{{ 'PRICE_DIFFERENCE' | translate}}</th>
            <th [style]="{width:'6%'}" *ngIf="discountRateChecked">{{ 'DISCOUNT' | translate}} %</th>
            <th [style]="{width:'6%'}" *ngIf="discountRateChecked">{{ 'DISCOUNT' | translate}} {{ currencyLabel }}</th>
            <th [style]="{width:'6%',display:'none'}" *ngIf="discountValueChecked">2.{{ 'DISCOUNT' | translate}}</th>
            <th [style]="{width:'8%'}">{{ 'GRAND_TOTAL' | translate}}</th>
            <th [style]="{width:'7%'}">{{ 'RECIVED' | translate}}</th>
            <th [style]="{width:'5%'}">{{ 'TAX_RATE' | translate}}</th>
            <th [style]="{width:'5%'}">{{ 'TAX_VALUE' | translate}}</th>
            <th [style]="{width:'3%'}">{{ 'MARK' | translate}}</th>
            <th [style]="{width:'4%'}"></th>
            <!---<th width="5%">{{ 'DELETE' | translate}}</th>--->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i="rowIndex" let-row>
          <tr [class.selectedRow]="row.marked">
            <td [style]="{padding: '10px 0'}">{{ row.purchaseOption === null ? row.variant.code : row.purchaseOption.code }} </td>
            <td [style]="{padding: '10px 0'}">{{ row.purchaseOption === null ? row.variant.name : row.purchaseOption.name}}</td>
            <td [style]="{padding: '10px 0'}"> {{ row.documentQuantity }}
              <br>
              <span *ngIf="row.purchaseOption?.packName === null">
                {{row.purchaseOption?.unit?.name}}
              </span>
              <span *ngIf="row.purchaseOption?.packName !== null">
                {{row.purchaseOption?.packName}}
              </span>

              <span *ngIf="row.purchaseOption == null">
                {{row.unit?.name}}
              </span>
             </td>
            <td [style]="{padding: '10px 0'}" pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber pInputText min="0" [style]="{height: '30px'}" [(ngModel)]="row.quantity"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 (onBlur)="changeInInvoiceItemQuantity(row, i, $event)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  <b
                    [class.alertTextQuantity]="row.incomingQuantity === 0 ? row.remainingQuantity : row.incomingQuantity !== row.quantity">
                    {{row.quantity}}</b>
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{padding: '10px 0'}" *ngIf="isDiscount == true">
              {{ row.unitPrice | currency: currencyLabel }}
            </td>
            <td [style]="{padding: '10px 0'}" pEditableColumn *ngIf="isDiscount == false">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="row.unitPrice" inputId="withoutgrouping" [useGrouping]="false"
                                 (onBlur)="changeInInvoiceItemPrice(row, i, $event.target.value)"
                                 [minFractionDigits]="2" [maxFractionDigits]="5">

                  </p-inputNumber>

                </ng-template>
                <ng-template pTemplate="output">
                  {{ row.unitPrice | currency: currencyLabel }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{padding: '10px 0'}" *ngIf="priceChange">
              <!--priceChance--->
              <div class="p-grid" [style]="{padding: '1px'}" *ngFor="let ord of priceCreditValuesOrder">
                <div class="p-col-12" [style]="{padding: '1px'}" *ngIf="ord === i">
                  <p-checkbox name="priceChangeValues" [(ngModel)]="priceChangeValues"
                              [style]="{width: '15px',height: '15px',fontSize:'8px'}"
                              value="{{row.stock['@id']}}" (onChange)="onPriceUpdate(row.stock['@id'], row, i)"
                              label="Fiyat Güncelle">

                  </p-checkbox>
                </div>
                <div class="p-col-12" [style]="{padding: '1px'}" *ngIf="ord === i">
                  <p-checkbox name="priceCreditValues" [(ngModel)]="priceCreditValues"
                              [style]="{width: '15px',height: '15px',fontSize:'8px'}"
                              value="{{row.stock['@id']}}" (onChange)="onPriceCreditChange(row.stock['@id'], row, i)"
                              [disabled]="!disabledValues"
                              label="Credit"></p-checkbox>
                </div>
              </div>
            </td>

            <td [style]="{padding: '10px 0'}" *ngIf="discountRateChecked" pEditableColumn>
              <!---İndirim Oranı--->
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber name="firstDiscountRateValues" [ngModel]="firstDiscountRateValues[i]"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 (onBlur)="onFirstDiscountRateValues($event.target.value, i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  %{{ firstDiscountRateValues[i] | number: '1.1-2'}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{padding: '10px 0'}" *ngIf="discountRateChecked" pEditableColumn>
              {{ firstDiscountValues[i] | currency : currencyLabel}}
            </td>
            <td [style]="{padding: '10px 0'}" *ngIf="discountValueChecked" pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber name="firstDiscountRateValues" [ngModel]="secondDiscountValues[i]"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 (onBlur)="onSecondDiscountRateValues($event.target.value, i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ secondDiscountValues[i] | currency: currencyLabel}}
                </ng-template>
              </p-cellEditor>

            </td>
            <td [style]="{padding: '10px 0'}" pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [ngModel]="row.subtotal" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 (onBlur)="changeInInvoiceItemSubTotal(row, i, $event.target.value)" ></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ row.subtotal | currency: currencyLabel}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn [style]="{padding: '0px'}">
              <div class="p-grid">
                <div class="p-col-6" [style]="{padding: '1px'}">
                  <p-cellEditor [style]="{padding: '0px'}">
                    <ng-template pTemplate="input" >
                      <p-inputNumber pInputText min="0" max="{{ row.quantity}}"
                                     [style]="{width: '60px',border:'none',background:'none',padding:'0px',marginLeft: '-20px'}"
                                     [(ngModel)]="row.incomingQuantity"
                                     [minFractionDigits]="2" [maxFractionDigits]="5"
                                     (onBlur)="changeInInvoiceItemIncomingQuantity(row, i, $event)"></p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <b [class.alertTextQuantity]="row.incomingQuantity === 0 ? row.remainingQuantity : row.incomingQuantity !== row.quantity">
                        {{ row.incomingQuantity }}
                      </b>
                    </ng-template>
                  </p-cellEditor>
                </div>
                <!--Credit Select--->
                <div class="p-col-6" [style]="{padding: '1px',display:'flex'}"
                     *ngIf="row.incomingQuantity === 0 ? row.remainingQuantity : row.incomingQuantity !== row.quantity">
                  <p-checkbox name="groupname" value="{{ row.stock['@id']}}" [(ngModel)]="creditValues"
                              (click)="itemCreditSelect(row.stock['@id'], row)"
                              [style]="{width: '15px',height: '15px',fontSize:'8px'}" label="Credit"></p-checkbox>
                </div>
              </div>
            </td>
            <td [style]="{padding: '10px 0'}">{{ row.taxRate.name}}</td>
            <td [style]="{padding: '10px 0'}">{{ row.tax | currency: currencyLabel}}</td>
            <td [style]="{padding: '10px 0'}">
              <p-checkbox name="groupname" value="Test" [binary]="true" [(ngModel)]="row.marked"></p-checkbox>
            </td>
            <td [style]="{padding: '10px 0'}">
              <button pButton pRipple [style]="{color:'#ff2525'}" (click)="deleteItem(i)" type="button"
                      icon="pi pi-trash" class="p-button-rounded p-button-text"></button>
            </td>
            <!---<td><button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"></button></td>--->
          </tr>
        </ng-template>
        <!---
        <ng-template pTemplate="footer">
          <tr>
            <td></td>
            <td>
              <p-autoComplete class="autoComplete" placeholder="{{'NEW_ITEM_ADD' | translate}}"
                              (onSelect)="onVariantSelect($event)" field="name" [suggestions]="variants"
                              (completeMethod)="searchVariant($event)">
              </p-autoComplete>
            </td>
            <td colspan="3"></td>
            <td *ngIf="discountRateChecked"></td>
            <td *ngIf="discountValueChecked"></td>
            <td colspan="6"></td>

          </tr>
        </ng-template>
        ---->
      </p-table>
      <br>
      <div *ngIf="creditRemember === true  ">
        <hr>
        <div class="p-fluid p-formgrid p-grid" style="margin-top: 20px">
          <div class="p-field p-col-8"><h5>{{'CREDIT_REMINDER'|translate}}</h5></div>
          <div class="p-field p-col-4">
            <p-button label="{{'SEND_CREDIT_REMINDER'|translate}}" icon="pi pi-check"></p-button>
          </div>
        </div>
        <p-table [value]="credit.items">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '10%'}">{{ 'CODE' | translate}}</th>
              <th [style]="{width: '20%'}">{{ 'PRODUCT_NAME' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'PRICE' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'GRAND_TOTAL' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'TAX_RATE' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'TAX_VALUE' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'REASON' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'STATUS' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-creditItem>
            <tr>
              <td>{{ creditItem.variant.code }}</td>
              <td>{{ creditItem.stock.name }}</td>
              <td>{{ creditItem.quantity }}</td>
              <td *ngIf="creditItem.type === 'CHANGE_PRICE'">{{ creditItem.newPrice | currency: currencyLabel}}</td>
              <td *ngIf="creditItem.type === 'CHANGE_QUANTITY'">{{ creditItem.price | currency: currencyLabel}}</td>
              <td
                *ngIf="creditItem.type === 'CHANGE_QUANTITY'">{{ creditItem.quantity * creditItem.price | currency: currencyLabel }}</td>
              <td
                *ngIf="creditItem.type === 'CHANGE_PRICE'">{{ creditItem.quantity * creditItem.newPrice | currency: currencyLabel }}</td>
              <td>{{ creditItem.stock.tax.name }}</td>
              <td *ngIf="creditItem.type === 'CHANGE_QUANTITY'">
                {{ creditItem.quantity * creditItem.price * (creditItem.stock.tax.rate / 100) | currency: currencyLabel }}
                {{ onCreditItemTax(i, creditItem.quantity * creditItem.price * (creditItem.stock.tax.rate / 100))}}
              </td>
              <td *ngIf="creditItem.type === 'CHANGE_PRICE'">
                {{ creditItem.quantity * creditItem.newPrice * (creditItem.stock.tax.rate / 100) | currency: currencyLabel }}
                {{ onCreditItemTax(i, creditItem.quantity * creditItem.newPrice * (creditItem.stock.tax.rate / 100))}}
              </td>
              <td><input type="text" pInputText name="reason" [ngModel]="creditItem.reason"/></td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <hr>
      <div class="p-grid">
        <div class="p-col-8">
          <textarea pInputTextarea style="width: 100%" placeholder="{{'COMMENT' | translate}}"
                    [style]="{minHeight:'120px'}"></textarea><br>
          <p-fileUpload name="myfile[]" url="./upload.php"></p-fileUpload>
        </div>
        <div class="p-col-4">
          <div class="tableDiv-content">
            {{'PRICING_SECTION'| translate}} <span [style]="{float:'right'}">{{'SUM' |translate}}</span>
            <div class="tableDiv-sub" [style]="{marginTop:'10px'}">
              {{'TOTAL'| translate}} <span [style]="{float:'right'}">{{ firstSubTotal | currency: currencyLabel}}</span>
            </div>
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'DISCOUNT'| translate}} %
              <span [style]="{float:'right'}">
                <input name="discountRateValue" [(ngModel)]="invoice.discountRate"
                       (change)="reCalculateInvoiceByRate($event)"
                       [style]="{height:'30px',width: '100px',border: 'none',background: '#f8f9fa'}">
               </span>
            </div>
            <div class="tableDiv-sub" [style]="{marginTop:'3px',display:'none'}">
              {{'DISCOUNT'| translate}} TL <span [style]="{float:'right'}">
              <input name="discountValue" [(ngModel)]="discountValue" (change)="reCalculateInvoiceByDiscount($event)"
                     [style]="{height:'30px', width:'100px',border: 'none',background: '#f8f9fa'}">
            </span>
            </div>
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'DISCOUNT_TOTAL'| translate}} <span
              [style]="{float:'right'}">{{ invoice.discount | currency: currencyLabel}}</span>
            </div>
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'SUBTOTAL'| translate}} <span
              [style]="{float:'right'}">{{ invoice.subtotal | currency: currencyLabel}}</span>
            </div>

            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'TAX'| translate}} <span [style]="{float:'right'}">{{ invoice.tax | currency: currencyLabel}}</span>
            </div>
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'GRAND_TOTAL'| translate}} <span
              [style]="{float:'right'}">{{ invoice.total | currency: currencyLabel}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <h6>{{'HISTORY'| translate}}</h6>
      <p-table [value]="shipments">
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.documentDate | date: 'd-M-Y'}} /
              {{ item.documentType}}{{ item.documentNumber}} /
              {{ item.createdBy.firstName}} {{ item.createdBy.lastName }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-toast></p-toast>
<p-confirmDialog #cd header="{{'WARNING'| translate}}" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                 [style]="{width: '50vw'}">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO'| translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
