import {ApiResourceModel} from './api-resource.model';
import {StockVariantModel} from './stock-variant.model';
import {WorkOrderOperationBatchModel} from './work-order-operation-batch.model';

export class WorkOrderMaterialModel extends ApiResourceModel {
  public id: number;
  public variant: StockVariantModel;
  public notes: string;
  public plannedQuantity: number;
  public totalQuantity: number;
  public cost: number;
  public unitCost: number;
  public status: string;
  public wastage: number;
  public expectedDate: Date|string;
  public createWorkOrder: boolean;
  public batches: WorkOrderOperationBatchModel[] = [];
  public stockQuantity: number| null;
  public needStockQuantity: number| null;
  public itemStatus: string| null;
  batchesData: any[];
  batchIndex: number;
}
