<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading" id="pdf-content">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PRODUCTION_REPORTS' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'daily-production'"></app-page-info>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid" id="button">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>

  <app-production-report-tab pageName="order-report"></app-production-report-tab>
  <div class="p-grid">
    <div class="p-col-12 div-sums" style="padding: 0px !important">
      <div>
        <div class="inside-div-no-background" style="overflow: auto;max-height: 600px; padding: 3px !important">
          <p-table [value]="saleDates" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th [style]="{width: '7%'}">{{ 'DATE' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_NUMBER' | translate }}</th>
                <th [style]="{width: '3%'}">{{ 'CUSTOMER' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'LOT' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'LABEL' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'PRODUCT' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_QUANTITY' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_PLACEMENT_LOCATION' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_DATE' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_CONFIRMED_BY' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_START_DATE' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_END_DATE' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_SENT_TO_LOCATION' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'ORDER_SENT_DATE' | translate }}</th>
                <th [style]="{width: '6%'}">{{ 'DETAILS' | translate }}</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dates>
              <tr>
                <td>{{dates.date}}</td>
                <td>{{dates.invoice_number}}</td>
                <td>{{dates.customer}}</td>
                <td></td>
                <td></td>
                <td>{{dates.name}}</td>
                <td>{{dates.quantity}}</td>
                <td>{{dates.location}}</td>
                <td>{{dates.invoice_date}}</td>
                <td>{{dates.confirming}}</td>
                <td>{{dates.start_date}}</td>
                <td>{{dates.end_date}}</td>
                <td>{{dates.dest_location}}</td>
                <td>{{dates.sent_date}}</td>
                <td></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</p-card>

