import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {ProductRecipeItemModel} from './product-recipe-item.model';

export class ProductRecipeModel extends ApiResourceModel{
  public static IRI = '/api/product_recipes';
  public cost: number;
  public product: StockModel|string;
  public isNeedRecalculate: boolean;
  public items: ProductRecipeItemModel[] = [];
}
