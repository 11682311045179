<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'PRINT_PICK_LIST' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'PICK_LIST_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
    <div>
      <app-pick-list-table [workOrder]="workOrder"></app-pick-list-table>
    </div>
  </div>
</p-card>
