import {Component, OnInit, ViewChild} from '@angular/core';
import {StockAdjustmentModel} from '../../../model/stock-adjustment.model';
import {HttpService} from '../../../service/http.service';
import {LazyLoadEvent} from 'primeng/api';
import {LocationModel} from '../../../model/location.model';
import {TranslateService} from '@ngx-translate/core';
import {StockTransfersModel} from '../../../model/stock-transfers.model';

@Component({
  selector: 'app-stock-adjustment-list',
  templateUrl: './stock-adjustment-list.component.html',
  styleUrls: ['./stock-adjustment-list.component.scss']
})
export class StockAdjustmentListComponent implements OnInit {
  @ViewChild('adjustmentDate') adjustmentDate;
  adjustments: StockAdjustmentModel[] = [];
  item: StockAdjustmentModel = new StockAdjustmentModel();
  loading: boolean;
  total: number;
  moreLoading: boolean;
  parameters = {
    'location.id': '',
    adjustmentDate: [],
    page: 0,
    itemsPerPage: 20,
  };
  searchLocations: LocationModel[] = [];
  display: boolean;

  constructor(private http: HttpService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination : false}).subscribe(response => {
      if (response !== undefined) {
        this.searchLocations = response;
        // @ts-ignore
        this.searchLocations.unshift({ name: this.translate.instant('ALL_LOCATIONS'), id: null});
        this.loading = false;
      }
    });
  }

  load(event: LazyLoadEvent = null, type = null): void {
    this.loading = true;
    if (type !== 'search'){
      this.parameters.page = this.parameters.page + 1 ;
    }else {
      this.adjustments = [];
      this.parameters.page = 1;
    }    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));

    let parameters = {...this.parameters};
    if (this.parameters.adjustmentDate) {
      const start = new Date(this.parameters.adjustmentDate[0]);
      const end = new Date(this.parameters.adjustmentDate[1]);

      parameters = {...parameters, ...{
          'adjustmentDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'adjustmentDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }};
    }

    this.http.get(StockAdjustmentModel.IRI, this.parameters).subscribe(response => {
      response['hydra:member'].map(res => {
        this.adjustments.push(res);
      });
      this.total = response['hydra:totalItems'];
      if (this.total <= this.parameters.page * this.parameters.itemsPerPage || this.total === 0) {
        this.moreLoading = false;
      }else {
        this.moreLoading = true;
      }
      this.loading = false;
    });

  }
  closeCalendar(field: string): void {
    let isClose = true;
    this.parameters.adjustmentDate.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    if (isClose) {
      this.adjustmentDate.overlayVisible = false;
      this.adjustmentDate.datepickerClick = true;
    }
  }
  changeLocation(event): void{
    this.parameters['location.id'] = event.value;
    this.load(null, 'search');
  }
  add(): void {
    this.item = new StockAdjustmentModel();
    this.display = true;
  }
  async edit(event): Promise<void> {
    await this.http.get(`${StockAdjustmentModel.IRI}/${event.id}`).subscribe((response) => {
      this.item = response;
      this.item.location = this.item.location ? this.item.location['@id'] : null;
      this.display = true;
    });
  }
}
