<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'STOCK_ADJUSTMENTS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'STOCK_ADJUSTMENTS'"></app-page-info>
      <button pButton pRipple type="button" [style]="{marginLeft: '5px', float:'right'}"
              (click)="add()" label="{{'NEW_ADJUSTMENT' | translate }}">
      </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
        <p-calendar  #adjustmentDate (onSelect)="closeCalendar('adjustmentDate')"
                     placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body" (onClose)="load(null, 'search')"
                     selectionMode="range" [(ngModel)]="parameters.adjustmentDate" name="createdAt" dateFormat="yy-mm-dd"></p-calendar>
        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="searchLocations" [(ngModel)]="parameters['location.id']"
                  (onChange)="changeLocation($event)"
                  optionLabel="name" optionValue="@id"
                  [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <form action="" (ngSubmit)="load(null, 'search')" (submit)="load(null, 'search')">
        <p-table [loading]="loading" [lazy]="true" [value]="adjustments" [tableStyle]="{'min-width': '50rem'}" (onLazyLoad)="load($event)"
                 [showCurrentPageReport]="true" [resizableColumns]="true" columnResizeMode="expand"
                 styleClass="p-datatable-gridlines p-datatable-md">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '10%'}">{{'CODE' | translate}}</th>
              <th>{{'DATE' | translate}}</th>
              <th pResizableColumn>{{'LOCATION' | translate}}</th>
              <th pResizableColumn>{{'REASON' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td><a href="javascript:void(0)" (click)="edit(row)">{{ row.code }}</a></td>
              <td>{{ row.adjustmentDate|date: 'M-d-Y' }}</td>
              <td>{{ row.location?.name }}</td>
              <td>{{ row.reason }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            {{adjustments.length != 0 ?  '' :'NO_RECORDS' | translate }}
          </ng-template>
        </p-table>
      </form>
      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="load()"></button>
        </div>
      </div>
    </div>
  </div>
</p-card>

<p-dialog [(visible)]="display" [style]="{width: '60vw'}" (onHide)="load(null, 'search')">
  <app-stock-adjustment *ngIf="display" [item]="item"></app-stock-adjustment>
</p-dialog>

