<p-table  [value]="items" dataKey="id" responsiveLayout="scroll" [loading]="loading" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr *ngIf="showHeader">
      <th>{{ 'STOCK' | translate}}</th>
      <th [style]="{width: '120px !important'}">{{ 'AMOUNT' | translate}}</th>
      <th [style]="{width: '120px !important'}" >{{ 'CURRENCY' | translate}}</th>
      <th [style]="{width: '120px !important'}">{{ 'COST' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr>
      <td style="width: 50%">{{ item.stock.name }}</td>
      <td>{{ item.amountWithWastage }}</td>
      <td>{{ item.stock.stock.currency.code }}</td>
      <td>{{ item.cost }}</td>
    </tr>
    <tr *ngIf="item.recipes.length> 0">
      <td [colSpan]="4">
        <app-product-recipe-table *ngIf="item.recipes.length> 0" [items]="item.recipes" [loading]="loading" [showHeader]="false"></app-product-recipe-table>
      </td>
    </tr>
  </ng-template>
</p-table>
