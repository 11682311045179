import {ApiResourceModel} from './api-resource.model';
import {TaxRateModel} from './tax-rate.model';

export class CategoryModel extends ApiResourceModel{
  public static IRI = '/api/categories';
  public name: string;
  public parent: any;
  public parentId: string;
  public isActive = true;
  public tax: TaxRateModel[];
  public code: string;
  public categoryType: string;
  public accountingCode: string;
  public department: string;
  public id: number;
  public lvl: number;
  children: any;
  location: any;
  public categoryMatches: CategoryModel[];


}
