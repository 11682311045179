import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productions-events',
  templateUrl: './productions-events.component.html',
  styleUrls: ['./productions-events.component.scss']
})
export class ProductionsEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
