import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {Subscription} from 'rxjs';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrencyService} from '../../../service/currency.service';
import {ValidationService} from '../../../service/validation.service';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {CurrencyModel} from '../../../model/currency.model';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {CategoryService} from '../../../service/category.service';
import {TaxService} from '../../../service/tax.service';
import {UnitService} from '../../../service/unit.service';
import {ContactService} from '../../../service/contact.service';
import {CreateCategoryComponent} from '../../settings/category/create-category/create-category.component';
import {CreateTaxComponent} from '../../settings/tax/create-tax/create-tax.component';
import {CreateUnitComponent} from '../../settings/unit/create-unit/create-unit.component';
import {ContactCreateDialogComponent} from '../../contact/contact-create-dialog/contact-create-dialog.component';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {LocationModel} from '../../../model/location.model';
import { MenuItem} from 'primeng/api';
import {ProductRecipeModel} from '../../../model/product-recipe.model';
import {MediaObjectModel} from '../../../model/media-object.model';
import {environment} from '../../../../environments/environment';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {StockTransfersModel} from '../../../model/stock-transfers.model';
import {WasteEvents} from '../../../model/waste-events.model';
import {ModifierModel} from '../../../model/modifier.model';


@Component({
  selector: 'app-new-material-create',
  templateUrl: './new-material-create.component.html',
  styleUrls: ['./new-material-create.component.scss']
})
export class NewMaterialCreateComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() item: StockModel = new StockModel();
  id: number;
  loading: boolean;
  submitted: boolean;
  errors = [];
  suppliers: ContactModel[] = [];
  taxes: TaxRateModel[] = [];
  categories: CategoryModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  currency: string;
  categoryCode: any;
  validationSubscriber: Subscription;
  contactTypes: [{ name: any; value: any }, { name: any, value: any }];
  locations: LocationModel[] = [];
  stockCount: any;
  tabMenuItems: MenuItem[];
  activeTabMenuItem: MenuItem;
  activeTab: number;
  recipe: ProductRecipeModel[] = [];
  allergens = [];
  parameters = {
    'stock.id': '',
    'location.id': '',
    id: '',
    date: '',
    page: 1,
    itemsPerPage: 20,
  };
  readyTime: string;
  options: boolean;
  purchaseOptions: boolean;
  nameEditable: boolean;
  variantOptionsDisplay: boolean;
  imageLoading: boolean;
  imageDeleting: boolean;
  transactionDetailShow: boolean;
  apiUrl: string;
  stockName: string;
  staticsDisplay: boolean;
  data: any;
  chartOptions: any;
  noInfo: boolean;
  contains: boolean;
  mayContain: boolean;
  doesNotContain: boolean;
  shipment: ShipmentModel[] = [];
  transfer: StockTransfersModel[] = [];
  waste: WasteEvents[] = [];
  info: any[] = [];
  information = {
    code: '',
    date: '',
    user: '',
    location: '',
    destination: ''
  };
 selectedCode: string;
 modifiers: ModifierModel [] = [];
  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private dialogService: DialogService, private translate: TranslateService,
              private flashMessageService: FlashMessageService, private currencyService: CurrencyService,
              private validationService: ValidationService, private categoryService: CategoryService,
              private taxService: TaxService, private unitService: UnitService, private contactService: ContactService) {
  }

  async ngOnInit(): Promise<void> {
    this.apiUrl = environment.apiUrl;
    this.tabMenuLoad();
    if (this.item.id) {
      this.id = this.item.id;
      this.options = true;
      this.nameEditable = false;
    } else {
      this.nameEditable = true;
    }
    if (this.item.category === null) {
      this.options = true;
    }
    this.item.currency = this.currencyService.getCurrency()['@id'];
    if (this.item.modifier){
      this.item.modifier = this.item.modifier['@id'];
    }
    if (this.item.extra){
      this.item.extra = this.item.extra['@id'];
    }
    this.readyTime = this.item.prepTime + this.item.cookTime + ' ' + this.translate.instant('MIN');
    setTimeout(() => {
      this.loadFormData().then();
      this.contactTypes = [
        {name: this.translate.instant('SUPPLIER'), value: 'supplier'},
        {name: this.translate.instant('CUSTOMER'), value: 'customer'},
      ];
      this.purchaseOptions = false;
    }, 500);
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });

    this.allergens = [
      {
        name: this.translate.instant('CEREALS_CONTAINING_GLUTEN'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('CRUSTACEAN_SHELLFISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('EGGS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('FISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('MILK'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('NUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('PEANUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('SOYBEANS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      }
    ];
    this.variantOptionsDisplay = this.item.type !== 'material';
    this.purchaseOptions = false;
  }
  test(): void {
  }
  async loadFormData(): Promise<void> {
    this.loading = true;
    /*
    if (this.id){
      await this.http.get(`${StockModel.IRI}/${this.id}`).subscribe((response) => {
        this.item = response['hydra:member'];
      });
      this.http.get(ProductRecipeModel.IRI, {'items.stock.id' : this.id }).subscribe(res => {
        this.recipe = res['hydra:member'];
        this.loading = false;
      });
    }

     */
    await this.http.get(ModifierModel.IRI, {pagination : false}).subscribe(res => {
      this.modifiers = res['hydra:member'];
    });
    this.categoryService.getProductCategories();
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
    });

    await this.taxService.getTaxesAction({isActive: true});
    this.taxService.getTaxes().subscribe(response => {
      this.taxes = response;
    });

    await this.unitService.getUnitsAction({isActive: true});
    this.unitService.getUnits().subscribe(response => {
      this.units = response;
    });

    this.contactService.getContactsAction({pagination: false});
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.suppliers = response;
      }
    });

    await this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
    });

    await this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response) => {
      this.locations = response;
      if (!this.item.id){
        this.item.locations.push(this.locations[0]['@id']);
      }
      this.loading = false;
    });

  }

  async saveStock(): Promise<void> {
    this.submitted = true;
    this.loading = true;
    this.errors = [];
    const currencies = JSON.parse(window.localStorage.getItem('currency'));

    await this.http.post(StockModel.IRI, {
      name: this.item.name,
      type: this.activatedRoute.snapshot.data.type,
      isBuying: this.activatedRoute.snapshot.data.type === 'material',
      isMaking: this.activatedRoute.snapshot.data.type !== 'material',
      currency: currencies['@id'],
      allergen: this.allergens,
      locations: [this.locations[0]['@id']]
    }).then((response: StockModel) => {
      if (response) {
        this.item = response;
        this.item.variants = response.variants;
        this.item.currency = response.currency ? response.currency['@id'] : null;
        this.item.stockQuantities.map((quantity, i) => {
          this.item.stockQuantities[i] = quantity['@id'];
        });
        this.item.stockQuantities.map((stockQuantity, i) => {
          this.item.stockQuantities[i] = stockQuantity['@id'];
        });
        this.item.locations.map((location, i) => {
          this.item.locations[i] = location['@id'];
        });
        this.id = response.id;
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        if (this.item.category === null) {
          this.options = true;
        }
      }
      this.tabMenuLoad();
      this.loading = false;
    });
    this.submitted = false;
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    this.item.stockVariantOptions.map((option, i) => {
      this.item.stockVariantOptions[i] = option['@id'];
    });
    this.item.variants.map((variant,i) => {
      if (variant.variantRecipes) {
        variant.variantRecipes = variant.variantRecipes['@id'];
      }
      if (!variant.id){
        this.item.variants[i].stock = this.item['@id'];
      }
    });
    delete this.item.stockQuantities;
    delete this.item.purchaseOptions;
    delete this.item.stockVariantOptions;
    await this.http.put(`${StockModel.IRI}/${this.item.id}`, this.item).then((response: StockModel) => {
      if (response) {
        this.item = response;
        this.item.category = this.item.category ? this.item.category['@id'] : null;
        this.item.locations = this.item.locations ? this.item.locations : null;
        this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
        this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
        this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
        this.item.modifier = this.item.modifier ? this.item.modifier['@id'] : null;
        this.item.extra = this.item.extra ? this.item.extra['@id'] : null;
        // @ts-ignore
        this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
        this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
        this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
        this.item.purchaseOptions.map((data, i) => {
          this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
            this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
            this.item.purchaseOptions[i].supplier = data.supplier['@id'];
            this.item.purchaseOptions[i].unit = data.unit['@id'];
            if (data.packName === '' && data.packPerCase === null) {
              this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
            } else {
              this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
            }

          });
        });
        this.item.stockQuantities.map((stockQuantity, i) => {
          this.item.stockQuantities[i] = stockQuantity['@id'];
        });
        this.item.locations.map((location, i) => {
          this.item.locations[i] = location['@id'];
        });
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
    });
    this.submitted = false;
  }

  addCategory(): void {
    this.dialogService.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: {
        type: 'product'
      }
    });
  }

  addTax(): void {
    this.dialogService.open(CreateTaxComponent, {
      width: '50vw',
      header: this.translate.instant('TAX_RATE_CREATE'),
    });
  }

  addUnit(): void {
    this.dialogService.open(CreateUnitComponent, {
      width: '50vw',
      header: this.translate.instant('UNIT_CREATE'),
    });
  }

  addContact(): void {
    this.dialogService.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
    });
  }

  changeCurrency(event): void {
    this.currencies.map((item) => {
      if (item['@id'] === event.value) {
        this.currency = item.code;
      }
    });
  }

  addItem(): void {
    const options = new StockVariantModel();
    // @ts-ignore
    this.item.variants.push(options);
  }

  removeItem(i: number): void {
    this.item.variants.splice(i, 1);
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

  async onChangeCategory(event: any): Promise<void> {
    /*
    await this.http.get(StockModel.IRI, {'category.id': event.value}).subscribe((response) => {
      this.categoryCode = response['hydra:totalItems'];
      this.stockCount = response['hydra:totalItems'] + 1;
    });
    await this.http.get(CategoryModel.IRI, {id: event.value}).subscribe((res) => {
      this.categoryCode = res['hydra:member'][0].code;
      if (this.stockCount < 10) {
        this.stockCount = '00' + this.stockCount;
      }
      if (this.stockCount < 100) {
        this.stockCount = '0' + this.stockCount;
      }
      this.item.variants[0].code = this.categoryCode + '-' + this.stockCount;
    });
*/
  }

  tabMenuLoad(): void {
    this.tabMenuItems = [
      {
        label: this.translate.instant('SIZE'),
        title: 'SIZE',
        command: () => {
          this.tabMenuSelect(0);
        },

        visible: this.type !== 'material'
      },
      {
        label: this.translate.instant('ALLERGENS'),
        title: 'ALLERGENS',
        command: () => {
          this.tabMenuSelect(1);
        }
      },
      {
        label: this.translate.instant('PROCEDURE'),
        title: 'PROCEDURE',
        command: () => {
          this.tabMenuSelect(2);
        },
        visible: this.type !== 'material'
      }
    ];
    this.activeTabMenuItem = this.tabMenuItems[0];
  }

  tabMenuSelect(id): void {

    this.activeTabMenuItem = this.tabMenuItems[id];
    this.activeTab = id;
    if (id === 1) {
      if (this.item.allergen.length === 0) {
        this.item.allergen = this.allergens;
      }
    }
  }

  change(event, i, noInfo, contains, mayContain, doesNotContain): void {

    // @ts-ignore
    this.item.allergen[i].noInfo = noInfo;
    // @ts-ignore
    this.item.allergen[i].contains = contains;
    // @ts-ignore
    this.item.allergen[i].mayContain = mayContain;
    // @ts-ignore
    this.item.allergen[i].doesNotContain = doesNotContain;
  }

  changeTime(event): void {
    this.readyTime = event.prepTime + event.cookTime + ' ' + this.translate.instant('MIN');
  }

  onEditableName(status): void {
    this.nameEditable = status;
  }

  async upload(event, fileUpload): Promise<void> {

    this.imageLoading = true;
    for (const file of event.files) {
      const fd = new FormData();
      fd.append('file', file);
      await this.http.post(MediaObjectModel.IRI, fd).then(async (response: MediaObjectModel) => {

        this.item.image = response.contentUrl;

        await this.http.put(`${StockModel.IRI}/${this.item.id}`, {image: this.item.image}).then((stockModel: StockModel) => {
        });
      });
    }
    this.imageLoading = false;
    fileUpload.clear();
  }

  async deleteImage(): Promise<void> {
    this.imageDeleting = true;

    await this.http.put(`${StockModel.IRI}/${this.item.id}`, {image: null}).then((stockModel: StockModel) => {
    });

    this.imageDeleting = false;
  }

  getChartOptions(event): void {
    const dates = [];
    const prices = [];
    this.http.get(StockPriceChanges.IRI, {'stock.id': this.id, 'location.id': event.value}).subscribe(response => {
      response['hydra:member'].map(item => {
        prices.push(item.price);
        const date = new Date(item.createdAt);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        dates.push(month + '-' + year);
      });
      setTimeout(() => {
        this.data = {
          labels: dates,
          datasets: [{
            type: 'line',
            label: this.translate.instant('PRICE_CHANGE_GRAPHICS'),
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            data: prices
          }]
        };
      }, 100);
    });
  }


}
