import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AsistantService {

  constructor(private translate: TranslateService) {
  }

  getDashBoardSteps(): any {
    return [
      {
        element: '#orders_header',
        title: this.translate.instant('ORDERS'),
        content: this.translate.instant('ORDERS_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#inventory_header',
        title: this.translate.instant('INVENTORY'),
        content: this.translate.instant('INVENTORY_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#suppliers_header',
        title: this.translate.instant('SUPPLIERS'),
        content: this.translate.instant('SUPPLIERS_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#accounting_header',
        title: this.translate.instant('ACCOUNTING'),
        content: this.translate.instant('ACCOUNTING_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#reports_header',
        title: this.translate.instant('REPORTS'),
        content: this.translate.instant('REPORTS_TOUR_DESCRIPTION'),
        placement: 'top-start',
      },
      {
        element: '#settings_header',
        title: this.translate.instant('SETTINGS'),
        content: this.translate.instant('SETTINGS_TOUR_DESCRIPTION'),
        placement: 'top-start',
      },
      {
        element: '#income_sums',
        title: this.translate.instant('INCOME'),
        content: this.translate.instant('INCOME_TOUR_DESCRIPTION'),
        placement: 'bottom',
      },
      {
        element: '#menu_analysis',
        title: this.translate.instant('MENU_ANALYSIS'),
        content: this.translate.instant('MENU_ANALYSIS_TOUR_DESCRIPTION'),
        placement: 'top-start',
      },
    ];
  }
  getNewOrderPageSteps(): any {
    return [
      {
        element: '#new_order_page_select_category',
        title: this.translate.instant('SELECT_CATEGORY'),
        content: this.translate.instant('ORDERS_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#new_order_page_select_location',
        title: this.translate.instant('SELECT_LOCATION'),
        content: this.translate.instant('ORDERS_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#new_order_page_table_row0',
        title: this.translate.instant('ADD_STOCK_ROW'),
        content: this.translate.instant('ADD_STOCK_ROW_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#new_order_page_table_row_add_all0',
        title: this.translate.instant('ADD_BY_DIFFERENCE'),
        content: this.translate.instant('ADD_BY_DIFFERENCE_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#new_order_page_table_row_add_number0',
        title: this.translate.instant('ADD_BY_NUMBER'),
        content: this.translate.instant('ADD_BY_NUMBER_TOUR_DESCRIPTION'),
        placement: 'right-start',
      }
    ];
  }
  getPlanningPageSteps(): any {
    return [
      {
        element: '#expanded0',
        title: this.translate.instant('SHOW_SALES_INVOICE'),
        content: this.translate.instant('ADD_STOCK_ROW_TOUR_DESCRIPTION'),
        placement: 'right-start',
      },
      {
        element: '#itemStatus0',
        title: this.translate.instant('ADD_STOCK_ROW'),
        content: this.translate.instant('ADD_STOCK_ROW_TOUR_DESCRIPTION'),
        placement: 'left-start',
      },
      {
        element: '#materialStatus0',
        title: this.translate.instant('ADD_STOCK_ROW'),
        content: this.translate.instant('ADD_STOCK_ROW_TOUR_DESCRIPTION'),
        placement: 'left-start',
      },
      {
        element: '#recipe0',
        title: this.translate.instant('ADD_STOCK_ROW'),
        content: this.translate.instant('ADD_STOCK_ROW_TOUR_DESCRIPTION'),
        placement: 'left-start',
      },
      {
        element: '#make0',
        title: this.translate.instant('ADD_STOCK_ROW'),
        content: this.translate.instant('ADD_STOCK_ROW_TOUR_DESCRIPTION'),
        placement: 'left-start',
      }
    ];
  }
}
