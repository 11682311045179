import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantOptionValuesModel} from './stock-variant-option-values.model';
import {VariantRecipeModel} from './variant-recipe.model';
import {ModifierModel} from './modifier.model';

export class StockVariantModel extends ApiResourceModel {
  public static IRI = '/api/stock_variants';
  public code: string;
  public alertLevel = 1;
  public averageCost = 0;
  public valueInStock = 0;
  public amount = 0;
  public defaultPrice = 0;
  public salePrice = 0;
  public expectedAmount = 0;
  public committedAmount = 0;
  public missingAmount = 0;
  public usableAmount = 0;
  public stock: StockModel = new StockModel();
  public stockVariantOptionValues: StockVariantOptionValuesModel[] = [];
  public variantRecipes: VariantRecipeModel[] = [];
  public price = 0;
  public materialCost = 0;
  public operationCost = 0;
  public totalCost = 0;
  public isDeletable = true;
  name: string;
  public minOrder: 1;
  public orderQuantity: number;
  public barcode: number;
  public id: any;
  public profit: any;
  public margin: number;
  public totalWeight: number;
  public modifierGroup: ModifierModel;
  public extraGroup: ModifierModel;
}
