import { Component, OnInit } from '@angular/core';
import {InvoiceStatus} from '../../model/invoice.model';

@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss']
})
export class QuickAddComponent implements OnInit {

  items = [];
  showAddMenu = false;

  invoiceStatus = InvoiceStatus;

  constructor() { }

  ngOnInit(): void {
    this.items = [];
  }

  setShowAddMenu(): void {
    this.showAddMenu = !this.showAddMenu;
  }

  hideMenu(): void {
    this.showAddMenu = false;
  }
}
