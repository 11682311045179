import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CookBookCategoryModel} from '../model/cook-book-category.model';
import {HttpService} from './http.service';
import {CookBookStockModel} from '../model/cook-book-stock.model';

@Injectable({
  providedIn: 'root'
})
export class CookBookStockService {




  items = new BehaviorSubject<CookBookStockModel[]>(null);

  constructor(private http: HttpService) { }

  getItems(): Observable<CookBookStockModel[]> {
    return this.items.asObservable();
  }

  getItemsAction(params = {}): void {
    this.http.get(CookBookStockModel.IRI, params).subscribe(response => {
      this.items.next(response['hydra:member']);
    });
  }

  async save(stock: CookBookStockModel): Promise<any> {
    return await this.http.post(CookBookStockModel.IRI, stock).then(response => {
      return response;
    });
  }

  async update(item: CookBookStockModel): Promise<any> {
    return await this.http.put(`${CookBookStockModel.IRI}/${item.id}`, item).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.items.next(null);
    this.items.unsubscribe();
  }
}
