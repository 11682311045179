import {Component, OnInit} from '@angular/core';
import {ContactModel} from '../../../model/contact.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {StockModel} from '../../../model/stock.model';
import {CategoryModel} from '../../../model/category.model';
import {CategoryService} from '../../../service/category.service';
import {LocationModel} from '../../../model/location.model';
import {DatePipe} from '@angular/common';
import {InvoiceModel} from '../../../model/invoice.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {DialogService} from 'primeng/dynamicdialog';
import {ContactService} from '../../../service/contact.service';
import {UnitModel} from '../../../model/unit.model';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {MenuItem} from 'primeng/api';
import {LocationService} from '../../../service/location.service';
import {UserModel} from '../../../model/user.model';
import {environment} from '../../../../environments/environment';
import {StockQuantityModel} from '../../../model/stock-quantity.model';

@Component({
  selector: 'app-place-orders',
  templateUrl: './place-orders.component.html',
  styleUrls: ['./place-orders.component.scss'],
  providers: [DialogService],
})
export class PlaceOrdersComponent implements OnInit {
  id: any;
  stocks: StockModel[] = [];
  noData: boolean;
  loading: boolean;
  submitted: boolean;
  parameters = {
    id: '',
    type: 'material',
    'variants.code': '',
    name: '',
    'supplier.name': '',
    'category.name': '',
    'variants.price': '',
    'supplier.id': '',
    'category.id': '',
    locations: '',
    page: 0,
    itemsPerPage: 20,
  };
  invoices = [
    {
      key: 0,
      supplierName: '',
      data: {
        code: '',
        contact: '',
        currency: '',
        discountRate: null,
        expectedDate: null,
        location: '',
        number: 0,
        series: '',
        status: 'CONFIRMED',
        subtotal: 0,
        tax: 0,
        total: 0,
        type: 'PURCHASE',
        description: '',
        items: []
      }
    }
  ];
  invoicesItems = [];
  selectInvoicesItems = [];
  totalRecords: number;
  contacts: ContactModel[] = [];
  searchContacts: ContactModel[] = [];
  categories: CategoryModel[] = [];
  locations: LocationModel[] = [];
  searchLocations: LocationModel[] = [];
  supplierCount: number;
  display: boolean;
  activeInvoice: number;
  firstValue: any;
  stockDialog: boolean;
  displayInvoiceStatus: boolean;
  displaySideBar: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  order: any;
  stockOrderQuantityValues = [{
    key: 0,
    data: {
      stock: '',
      orderQuantity: 0
    }
  }];
  invoiceInfo: boolean;
  invoiceInfoComment: any;
  invoiceRouteId: any;
  actionItems: MenuItem[] = [];
  dataImportDialog: boolean;
  total: number;
  tableLoading: boolean;
  moreLoading: boolean;
  displayAddContact: boolean;
  displaySuppliers: boolean;
  count: number;
  itemClickValues: number;
  user: UserModel = new UserModel();
  apiUrl: string;
  stockQuantityId: number;
  displaySuppliersCount: number;
  searchCategory: any;
  searchCategories = [];
  actions: any[];

  constructor(private http: HttpService, private route: ActivatedRoute,
              private translate: TranslateService,
              private categoryService: CategoryService,
              private contactService: ContactService,
              private locationService: LocationService,
              private router: Router,
              private datePipe: DatePipe,
              private dialog: DialogService,
              private confirmationService: ConfirmationService,
              private flashMessageService: FlashMessageService) {
  }

  ngOnInit(): void {
    this.displaySuppliersCount  = 0;
    this.apiUrl = environment.apiUrl;
    const userJson = localStorage.getItem('user');
    if (userJson) {
      this.user = JSON.parse(userJson);
    }
    this.onLoad();
    this.parameters.page = 0;
    this.activeInvoice = 1;
    this.firstValue = '';
    if (window.localStorage.getItem('invoices') !== undefined && window.localStorage.getItem('invoices').length > 1) {
      this.invoices = JSON.parse(window.localStorage.getItem('invoices'));
      if (this.invoices) {
        this.invoices.map((invoices, i) => {
          this.invoices[i].data.expectedDate = new Date(invoices.data.expectedDate);
        });
        this.activeInvoice = this.invoices.length - 1;
        this.supplierCount = this.invoices.length - 1;
      }
    }
    this.actionItems = [
      {
        label: this.translate.instant('ADD_NEW_PRODUCT'),
        command: () => {
          this.openForm();
        }
      },
      {
        label: this.translate.instant('IMPORT_INVENTORY_ITEMS'),
        command: () => {
          this.dataImport();
        }
      },
      {
        label: this.translate.instant('WEEKLY_ORDERS_SCHEDULES')
      },
    ];
    this.actions = JSON.parse(localStorage.getItem('actions'));
  }

  checkActionPermission(text, type): boolean {
    let item;

    if (this.actions){
      if (type === 'ORDERS') {
        item = this.actions[0].orders?.find(order => order.name === text);
      }
    }

    return item ? item.value : false;
  }

  async onLoadStock(type = null): Promise<void> {
    this.displaySideBar = false;
    if (type !== 'search') {
      this.parameters.page = this.parameters.page + 1;
    } else {
      this.stocks = [];
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.noData = false;
    this.tableLoading = true;
    // @ts-ignore
    await this.http.get(StockModel.IRI, this.parameters).subscribe((response: StockModel[]) => {
      // @ts-ignore
      if (response['hydra:totalItems'] === 0) {
        this.noData = true;
        this.stocks = [];
      } else {
        this.noData = false;
        // this.stocks = response['hydra:member'];
        response['hydra:member'].map(res => {
          this.stocks.push(res);
        });
        // @ts-ignore
        this.total = response['hydra:totalItems'];
        this.tableLoading = false;
        this.setStockValues();
        this.moreLoading = this.total >= this.parameters.page * this.parameters.itemsPerPage;
      }
      this.changeVariantAmount();
    });

  }

  changeVariantAmount(): void {
    this.stocks.map((stock, i) => {
      // @ts-ignore
      const findIndex = stock.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters.locations);
      this.stocks[i].variants[0].amount = stock.stockQuantities[findIndex].amount;
      if (stock.stockQuantities[findIndex].alertLevel > 0) {
        this.stocks[i].variants[0].alertLevel = stock.stockQuantities[findIndex].alertLevel;
      }
      if (stock.stockQuantities[findIndex].lastPurchasePrice > 0) {
        this.stocks[i].variants[0].price = stock.stockQuantities[findIndex].lastPurchasePrice;
      }
    });
  }

  async onLoad(): Promise<void> {
    this.loading = true;
    await this.http.get(`${UserModel.IRI}/${this.user.id}`).subscribe(response => {
        response.locations.map((item, i) => {
          if (i === 0) {
            this.parameters.locations = item;
          }
          this.locations.push(item);
        });
        this.searchLocations = this.locations;
        if (this.displaySuppliersCount === 0){
          this.displaySuppliers = true;
          this.displaySuppliersCount  = 1;
        }
        this.loading = false;
      }
    );
    this.searchContacts = [];
    // this.categories = this.categoryService.getSearchCategories({type: 'stock'});
    this.searchCategories = await this.categoryService.getSearchCategories({categoryType: 'stock'});
    this.searchContacts = this.contactService.getSearchContacts({pagination: false, type: 'supplier'});
    this.noData = false;
  }

  async changeContact(event): Promise<void> {
    const objIndex = this.invoices.findIndex((obj => obj.key === event.value));
    if (objIndex !== -1) {
      this.activeInvoice = objIndex;
    }
    this.parameters.page = 0;
    this.stocks = [];
    this.onLoadStock().then();
  }

  async changeCategory(): Promise<void> {
    const choseId = this.parameters['category.id'].split('/');
    // this.parameters.locations = event.value;
    this.parameters['category.id'] = choseId[3];
    this.parameters.page = 0;
    this.stocks = [];
    this.onLoadStock().then();
  }

  async changeLocation(event, value = null): Promise<void> {
    this.invoices = [];
    this.activeInvoice = null;
    this.supplierCount = 0;
    window.localStorage.setItem('invoices', null);
    if (value) {
      this.parameters.locations = value;
    } else {
      this.parameters.locations = event.value;
    }
    this.parameters.page = 0;
    this.stocks = [];
    // this.invoices[this.activeInvoice].data.location = '/api/locations/' + event.value;
    this.onLoadStock().then();
    this.displaySuppliers = false;
  }

  async onChangeQuantity(event: any, row, i, option = null): Promise<void> {
    const status = 'WAITING_FOR_APPROVAL';
    if (event.value === 0 || event.value === null) {

      const objStockIndex = this.stocks.findIndex((obj => obj['@id'] === row['@id']));
      this.stocks[objStockIndex].variants[0].orderQuantity = null;

      const getInvoice = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
      // tslint:disable-next-line:max-line-length
      const getItems = this.invoices[getInvoice].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
      setTimeout(() => {
        this.removeInvoiceItem(getItems, this.invoices[getInvoice].key, row['@id']);
      }, 100);
    }
    this.invoicesItems = [{
      currency: row.currency['@id'] ? row.currency['@id'] : null,
      nonDiscountedUnitPrice: 0,
      quantity: parseFloat(event.value),
      stock: row['@id'],
      name: option === null ? row.variants[0].name : option.name,
      code: option === null ? row.variants[0].code : option.code,
      subtotal: option === null ? row.variants[0].price * event.value : option.price * event.value,
      // tslint:disable-next-line:max-line-length
      tax: option === null ? row.variants[0].price * event.value * (row.tax.rate / 100) : (option.price * event.value) * (row.tax.rate / 100),
      taxRate: row.tax['@id'],
      unitPrice: option === null ? row.variants[0].price : option.price,
      variant: row.variants[0]['@id'],
      // tslint:disable-next-line:max-line-length
      totalOrder: option === null ? event.value : option.baseUnitQuantity === null ? event.value * option.packQuantity : event.value * option.baseUnitQuantity,
      unit: row.unit.name,
      orderType: option === null ? null : option.orderType,
      purchaseOption: option === null ? null : option['@id']
    }];
    const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
    this.activeInvoice = objInvoiceIndex;
    if (this.invoices.length === 0 || objInvoiceIndex === -1) {
      this.loading = true;
      this.invoices.push({
        key: option === null ? row.supplier.id : option.supplier.id,
        supplierName: option === null ? row.supplier.name : option.supplier.name,
        data: {
          contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
          currency: row.currency['@id'],
          discountRate: null,
          expectedDate: new Date(),
          location: '/api/locations/' + this.parameters.locations,
          code: '',
          number: 0,
          series: '',
          status,
          subtotal: 0,
          tax: 0,
          total: 0,
          type: 'PURCHASE',
          description: '',
          items: this.invoicesItems
        }
      });
      this.supplierCount = this.invoices.length;
      this.activeInvoice = this.invoices.length - 1;
      this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
      // SubTotal Calculation
      // tslint:disable-next-line:no-shadowed-variable
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
        sum += item.subtotal;
      });
      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * (row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * (row.tax.rate / 100));
      this.loading = false;

    } else {
      const objIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
      // tslint:disable-next-line:max-line-length
      const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
      if (objIndexStock !== -1) {
        this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
      } else {
        this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
      }
      this.activeInvoice = objIndex;
      this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
      // SubTotal Calculation
      // tslint:disable-next-line:no-shadowed-variable
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
        sum += item.subtotal;
      });
      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * (row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * (row.tax.rate / 100));
    }
    this.setInvoicesLocalStorage();
  }
  async onChangeQuantityTotal(quantity, row, option = null): Promise<void> {
    if (option === null) {
      row.variants[0].orderQuantity = quantity;
    } else {
      option.orderQuantity = quantity;
    }
    const status = 'WAITING_FOR_APPROVAL';
    if (quantity === 0) {

    }
    this.invoicesItems = [{
      currency: row.currency['@id'] ? row.currency['@id'] : null,
      nonDiscountedUnitPrice: 0,
      quantity,
      stock: row['@id'],
      name: option === null ? row.variants[0].name : option.name,
      code: option === null ? row.variants[0].code : option.code,
      subtotal: option === null ? row.variants[0].price * quantity : option.price * quantity,
      tax: option === null ?
        (row.variants[0].price * quantity) * (row.tax.rate / 100)
        : (option.price * quantity) * (row.tax.rate / 100),
      taxRate: row.tax['@id'],
      unitPrice: option === null ? row.variants[0].price : option.price,
      variant: row.variants[0]['@id'],
      // tslint:disable-next-line:max-line-length
      totalOrder: option === null ? quantity * row.variants[0].minOrder : option.baseUnitQuantity === null ? quantity * option.packQuantity : quantity * option.baseUnitQuantity,
      unit: row.unit.name,
      orderType: option === null ? null : option.orderType,
      purchaseOption: option === null ? null : option['@id']
    }];
    const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));

    if (this.invoices.length === 0 || objInvoiceIndex === -1) {
      this.loading = true;
      this.invoices.push({
        key: option === null ? row.supplier.id : option.supplier.id,
        supplierName: option === null ? row.supplier.name : option.supplier.name,
        data: {
          code: '',
          contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
          currency: row.currency['@id'],
          discountRate: null,
          expectedDate: new Date(),
          location: '/api/locations/' + this.parameters.locations,
          number: 0,
          series: '',
          status,
          subtotal: 0,
          tax: 0,
          total: 0,
          type: 'PURCHASE',
          description: '',
          items: this.invoicesItems
        }
      });
      this.supplierCount = this.invoices.length;
      this.activeInvoice = this.invoices.length - 1;
      // SubTotal Calculation
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
        sum += item.subtotal;
      });

      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * (row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * (row.tax.rate / 100));
      this.loading = false;

    } else {
      const objIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
      // tslint:disable-next-line:max-line-length
      const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
      if (objIndexStock !== -1) {
        this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
      } else {
        this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
      }
      this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
        sum += item.subtotal;
      });
      this.activeInvoice = objIndex;
      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * (row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * (row.tax.rate / 100));
    }
    this.setInvoicesLocalStorage();
  }

  changeActiveInvoice(i): void {
    this.activeInvoice = i;
    this.parameters['supplier.id'] = String(this.invoices[i].key);
    this.selectInvoicesItems = this.invoices[i].data.items;
  }

  setDescription(event): void {
    this.invoices[this.activeInvoice].data.description = event.value;
  }

  setLocation(event): void {
    this.invoices[this.activeInvoice].data.location = event.value;
  }

  changeAlertLevel(alertLevel, row, i, option = null, optionAlertLevel = null): void {
    this.stocks.map((stock) => {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      const findIndex = stock.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters.locations && obj.stock === row['@id']);
      if (findIndex !== -1) {
        this.stockQuantityId = stock.stockQuantities[findIndex].id;
      }
    });
    if (option !== null) {
      alertLevel = optionAlertLevel * option;
    }

    this.http.put(`${StockQuantityModel.IRI}/${(this.stockQuantityId)}`, {alertLevel}).then(response => {
      row.variants[0].alertLevel = response.alertLevel;
    });
    this.onLoadStock().then();

  }

  addContact(item: ContactModel = null): void {
    this.displayAddContact = true;
  }

  onDisplayInvoiceStatus(): void {
    this.displayInvoiceStatus = true;
  }

  async edit(event): Promise<void> {
    this.item = new StockModel();
    const response = await this.http.get(`${StockModel.IRI}/${event.id}`).toPromise();
    this.item = response;
    this.item.category = this.item.category ? this.item.category['@id'] : null;
    this.item.locations = this.item.locations ? this.item.locations : null;
    this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
    this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
    this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
    this.item.orderItem = true;
    // @ts-ignore
    this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
    this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
    this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
    this.item.purchaseOptions.map((data, i) => {
      this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
        this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
        this.item.purchaseOptions[i].supplier = data.supplier['@id'];
        this.item.purchaseOptions[i].unit = data.unit['@id'];
        if (data.packName === '' && data.packPerCase === null) {
          this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
        } else {
          this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
        }
      });
    });
    this.item.stockQuantities.map((stockQuantity, i) => {
      this.item.stockQuantities[i] = stockQuantity['@id'];
    });
    this.displayCreate = true;
    setTimeout(() => {
      this.displaySideBar = true;
    }, 100);
  }


  reload(): void {
    this.displayCreate = false;
    this.displayUpdate = false;
    setTimeout(() => {
      this.displaySideBar = false;
    }, 100);
    this.displayAddContact = false;
    this.parameters.page = 1;
    this.stocks = [];
    this.onLoadStock('search').then();
  }

  openForm(): void {
    this.item = new StockModel();
    setTimeout(() => {
      this.displaySideBar = true;
    }, 100);
    this.displayCreate = true;
    this.displayUpdate = false;
  }
  onSidebarShow(): void {
    this.displayCreate = true;
    this.displayUpdate = false;
  }

  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }

  async save(key): Promise<void> {
    this.submitted = true;
    const dataCount = this.invoices.length;
    const invoiceIndex = this.invoices.findIndex((obj => obj.key === key));
    await this.http.get(`${InvoiceModel.IRI}/invoice-number?type=PURCHASE`).subscribe((res: InvoiceModel) => {
      this.invoices[invoiceIndex].data.number = res.number;
      this.invoices[invoiceIndex].data.code = res.code;
      this.invoices[invoiceIndex].data.series = res.series;
      this.http.post(InvoiceModel.IRI, this.invoices[invoiceIndex].data).then((response: InvoiceModel) => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
          this.invoices.splice(objInvoiceIndex, 1);
          this.activeInvoice = this.invoices.length - 1;
          this.supplierCount = this.invoices.length;
          this.submitted = false;
          this.invoiceInfoComment = 'Invoice Number : ' + response.code;
          this.invoiceRouteId = response.id;
          this.invoiceInfo = true;
          this.setInvoicesLocalStorage();
        } else {
          this.submitted = false;
        }
      }).catch(err => {
      });
    });
    this.onLoadStock().then();
  }

  removeInvoiceItem(i, key, stock): void {
    this.itemClickValues = -1;
    this.invoices[this.activeInvoice].data.items.splice(i, 1);
    if (this.invoices[this.activeInvoice].data.items.length === 0) {
      const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
      this.invoices.splice(objInvoiceIndex, 1);
      this.activeInvoice = this.invoices.length - 1 ;
      // this.supplierCount = this.invoices.length - 1;
    }
    const objStockIndex = this.stocks.findIndex((obj => obj['@id'] === stock));
    this.stocks[objStockIndex].variants[0].orderQuantity = null;
    this.setInvoicesLocalStorage();
    this.setStockValues();
    this.supplierCount = this.invoices.length;
  }

  removeInvoice(key): void {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_YOU_SURE_THAT_YOU_WANT_TO_PERFORM_THIS_ACTION'),
      accept: () => {
        const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
        this.invoices.splice(objInvoiceIndex, 1);
        this.activeInvoice = this.invoices.length - 1;
        this.supplierCount = this.invoices.length;
        this.parameters.page = 0;
        this.stocks = [];
        this.onLoadStock().then();
        this.setInvoicesLocalStorage();
        this.setStockValues();
      }
    });
  }

  setInvoicesLocalStorage(): void {
    window.localStorage.setItem('invoices', JSON.stringify(this.invoices));
  }

  setStockValues(): void {
    this.stocks.map((stock) => {
      stock.purchaseOptions.map((data, i) => {
        // tslint:disable-next-line:max-line-length
        data.alertLevel = data.baseUnitQuantity === null ? Math.ceil(stock.variants[0].alertLevel / data.packQuantity) : Math.ceil(stock.variants[0].alertLevel / data.baseUnitQuantity);
        // tslint:disable-next-line:max-line-length
        data.onHand = data.baseUnitQuantity === null ? Math.floor(stock.variants[0].amount / data.packQuantity) : Math.floor(stock.variants[0].amount / data.baseUnitQuantity);
        // tslint:disable-next-line:max-line-length
        if (data.unit instanceof UnitModel) {
          data.orderType = data.packName === null || data.packPerCase === null ? data.packQuantity + ' ' + data.unit.name : data.packName + '(' + data.packPerCase + 'x' + data.packQuantity + ' ' + data.unit.name + ')';
        }
      });
    });
    if (this.invoices.length > 0) {
      this.stockOrderQuantityValues = [];
      this.invoices.map((invoice, i) => {
        invoice.data.items.forEach((item) => {
          this.stocks.map(stock => {
            if (stock.purchaseOptions) {
              stock.purchaseOptions.forEach((option) => {
                if (!(option.supplier instanceof ContactModel) || option.supplier.id === invoice.key && stock['@id'] === option.stock) {
                  option.orderQuantity = item.quantity;
                }
              });
            }
            // @ts-ignore
            if (stock.supplier.id === invoice.key && stock['@id'] === item.stock) {
              stock.variants[0].orderQuantity = item.quantity;
            }
          });
          this.stockOrderQuantityValues.push({
            key: invoice.key,
            data: {
              stock: item.stock,
              orderQuantity: item.quantity
            }
          });
        });
      });
    }
  }

  textSlice(text): string {
    const newText = text.slice(0, 10) + '...';
    return newText;
  }

  dataImport(): void {
    this.dataImportDialog = true;
  }

  setDate(event: any): void {
  }

  orderTypeText(order, unit): string {
    let text = '';
    if (order.baseUnitQuantity === null) {
      text = order.packQuantity + '' + order.packName + '';
    } else {
      text = order.baseUnitQuantity + '' + unit + '';
    }
    return text;
  }

  onInvoiceItemClick(i): void {
    if (this.itemClickValues === i) {
      this.itemClickValues = -1;
    } else {
      this.itemClickValues = i;
    }
  }

  getLastQuantity(event, item, i): void {
    this.invoices[this.activeInvoice].data.items[i].quantity = event.value;
    this.invoices[this.activeInvoice].data.items[i].totalOrder = event.value;
    this.invoices[this.activeInvoice].data.items[i].subtotal = event.value * item.unitPrice;
    const findStockInStocks = this.stocks.findIndex(obj => obj['@id'] === item.stock);
    if (findStockInStocks !== -1) {
      this.stocks[findStockInStocks].variants[0].orderQuantity = event.value;
    }
    this.invoiceRecalculate();
  }

  invoiceRecalculate(): void {
    let subtotal = 0;
    this.invoices[this.activeInvoice].data.items.map((item, i) => {
      subtotal += item.quantity * item.unitPrice;
    });
    this.invoices[this.activeInvoice].data.subtotal = subtotal;
  }

  priceCheck(price, row): string {
    if (row.purchaseOptions.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < row.purchaseOptions.length; i++) {
        if (price > (row.purchaseOptions[i].price / row.purchaseOptions[0].baseUnitQuantity)) {
          return 'red';
        } else if (price < (row.purchaseOptions[0].price / row.purchaseOptions[0].baseUnitQuantity)) {
          return 'green';
        }
      }
    } else {
      return '';
    }

  }

  priceCheckOption(price, optionPrice): string {
    if (price > optionPrice) {
      return 'green';
    } else {
      return '';
    }
  }
}
