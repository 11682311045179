<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'WASTE_REPORTS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'WASTE_EVENTS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
       </div>
  </div>
  <div class="p-grid">
    <div class="p-col-3">
      <div class="left-area">
        <div class="contentarea">
          <span class="title">{{'TOTAL_WASTE'| translate}}</span>
          <span class="price">{{ wasteTotal }}</span>
          <span class="box-text">There is no waste value in the previous period. (01/12-31/12)</span>
        </div>
        <p-divider></p-divider>
        <div class="contentarea">
          <span class="title">{{'TOTAL_PURCHASES'| translate}}</span>
          <span class="price">{{ wasteTotal }}</span>
          <span class="box-text">There is no waste value in the previous period. (01/12-31/12)</span>
        </div>
      </div>

    </div>
    <div class="p-col-9">
      <div class="left-area">
        <p-chart type="bar" [data]="basicData" [options]="basicOptions" [style]="{width:'100%'}"></p-chart>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="wasteEvents" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '10%'}">{{'DATE' |translate}}</th>
            <th [style]="{width: '7%'}">{{'CODE' |translate}}</th>
            <th>{{'NAME' |translate}}</th>
            <th>{{'TYPE' |translate}}</th>
            <th>{{'QUANTITY' |translate}}</th>
            <th>{{'CREATED_USER' |translate}}</th>
            <th>{{'DESCRIPTION' |translate}}</th>
            <th>{{'VALUE' |translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{item.items.date | date: 'Y-M-d'}}</td>
            <td>{{item.items.code}}</td>
            <td>{{item.stock.name}}</td>
            <td>{{item.stock.type |  translate}}</td>
            <td><span [style]="{float: 'right'}">{{item.quantity}} {{ item.stock.unit.name}}</span></td>
            <td>{{item.createdBy.firstName}} {{item.createdBy.lastName}}</td>
            <td>{{item.items.description |translate}}</td>
            <td><span [style]="{float: 'right'}">{{item.value | currency: item.stock.currency.symbol}}</span></td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</p-card>


