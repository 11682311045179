import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {LocationService} from '../../../service/location.service';
import {LocationModel} from '../../../model/location.model';
import {ActivatedRoute} from '@angular/router';
import {checkDuplicateMessages} from '@angular/localize/src/tools/src/extract/duplicates';
import {posix} from 'path';

@Component({
  selector: 'app-income-expense-analysis',
  templateUrl: './income-expense-analysis.component.html',
  styleUrls: ['./income-expense-analysis.component.scss']
})
export class IncomeExpenseAnalysisComponent implements OnInit {
  loading: boolean;
  data: any;
  chartData: any;
  chartOptions: any;
  dataDays: any[] = [];
  dataDaysSaleTotal: any[] = [];
  dataDaysProfitTotal: any[] = [];
  dataDaysCostTotal: any[] = [];
  sale = {
    subTotal: 0,
    total: 0,
    netTotal: 0,
    taxTotal: 0,
    discount: 0,
    totalCuver: 0,
    totalCustomer: 0,
    totalCheck: 0,
    totalCost: 0,
    totalCuverAverage: 0,
    totalCustomerAverage: 0,
    totalAverage: 0,
    checkCost: 0,
    customerCost: 0,
  };
  saleDates: any;
  info: any;
  locations: LocationModel[] = [];
  dateRange: Date[] = [];
  parameters = {
    // date: [],
    startDate: '',
    lastDate: '',
    location: '',
    saleDate: ''
  };

  constructor(private http: HttpService, private locationService: LocationService, private route: ActivatedRoute, ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.locations = this.locationService.getSearchLocation({type: 'supplier'});
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.startDate;
        // @ts-ignore
        this.parameters.lastDate = params.lastDate;
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

        const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));

        const selectedDate = dateRange.map(date => this.getFormattedDate(date));

        this.parameters.saleDate = selectedDate.join(',');

        this.parameters.location = params.location ? params.location : '';
      } else {
        this.getThisWeekDates();
      }
    });
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    this.dataDays = [];
    this.dataDaysSaleTotal = [];
    this.dataDaysProfitTotal = [];
    this.dataDaysCostTotal = [];
    this.sale = {
      subTotal: 0,
      total: 0,
      netTotal: 0,
      taxTotal: 0,
      discount: 0,
      totalCuver: 0,
      totalCustomer: 0,
      totalCheck: 0,
      totalCost: 0,
      totalCuverAverage: 0,
      totalCustomerAverage: 0,
      totalAverage: 0,
      checkCost: 0,
      customerCost: 0,
    };
    await this.http.get('/api/reports/daily_income_reports', this.parameters).subscribe(response => {
      this.saleDates = response;
      this.saleDates.map(item => {
        this.sale.subTotal += parseFloat(item.subTotal);
        this.sale.total += parseFloat(item.total);
        this.sale.netTotal += parseFloat(item.netTotal);
        this.sale.taxTotal += parseFloat(item.tax);
        this.sale.discount += parseFloat(item.discount);
        this.sale.totalCuver += parseFloat(item.cuver);
        this.sale.totalCustomer += parseFloat(item.saleCount);
        this.sale.totalCheck += parseFloat(item.saleCount);
        this.sale.totalCost += parseFloat(item.totalCost);
        this.dataDays.push(item.saleDate);
        this.dataDaysSaleTotal.push(Math.ceil(item.netTotal));
        this.dataDaysProfitTotal.push(Math.ceil(item.netTotal - item.totalCost));
        this.dataDaysCostTotal.push(Math.ceil(item.totalCost));
      });
      this.sale.totalCuverAverage = this.sale.netTotal / this.sale.totalCuver;
      this.sale.totalCustomerAverage = this.sale.netTotal / this.sale.totalCustomer;
      this.sale.totalAverage = this.sale.netTotal / this.sale.totalCustomer;
      this.sale.checkCost = this.sale.totalCost / this.sale.totalCheck;
      this.sale.customerCost = this.sale.totalCost / this.sale.totalCustomer;
      if (this.saleDates.length < 15) {
        const count = 15 - this.saleDates.length;
        for (let i = 0; i < (count / 2); i++) {
          this.dataDaysSaleTotal.unshift(0);
          this.dataDaysProfitTotal.unshift(0);
          this.dataDays.unshift(0);
          this.dataDaysCostTotal.unshift(0);
        }
        for (let i = 0; i < (count / 2); i++) {
          this.dataDaysSaleTotal.push(0);
          this.dataDaysProfitTotal.push(0);
          this.dataDays.push(0);
          this.dataDaysCostTotal.push(0);
        }
      }
    });

    setTimeout(() => {
      this.data = {
        labels: this.dataDays,
        datasets: [{
          type: 'bar',
          label: 'Gelir',
          backgroundColor: '#a4c7a2',
          data: this.dataDaysSaleTotal,
          borderColor: '#a4c7a2',
          borderWidth: 1,
          order: 2 // Çubuk grafiğin z-index değeri
        }, {
          type: 'bar',
          label: 'Gider',
          backgroundColor: '#885f5f',
          data: this.dataDaysCostTotal,
          borderColor: '#885f5f',
          borderWidth: 1,
          order: 2 // Çubuk grafiğin z-index değeri
        }, {
          type: 'line',
          label: 'Kâr',
          borderColor: 'rgb(255, 3, 0)',
          data: this.dataDaysProfitTotal,
          borderWidth: 3,
          fill: true, // Arka planı doldur
          backgroundColor: 'rgba(253,175,174,0.32)',
          order: 5 // Çizgi grafiğin z-index değeri
        }, {
          type: 'bar',
          label: 'Kâr',
          backgroundColor: '#47bfd4',
          data: this.dataDaysProfitTotal,
          borderColor: '#47bfd4',
          borderWidth: 1,
          order: 2 // Çubuk grafiğin z-index değeri
        }]
      };

      this.chartOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      this.loading = false;
    }, 2000);

    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    console.log(this.parameters.location);
    this.parameters = {...this.parameters};
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    if (event[1] === null) {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    } else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }
    // @ts-ignore
    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    this.parameters.saleDate = selectedDate.join(',');

    this.parameters = {...this.parameters};

    this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

  }

  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getThisWeekDates(): void {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Haftanın ilk gününü al
    const thisWeekDates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      thisWeekDates.push(this.getFormattedDate(date));
    }
    this.parameters.startDate = this.getFormattedDate(firstDayOfWeek);
    this.parameters.lastDate = thisWeekDates[thisWeekDates.length - 1];
    this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

    this.parameters.saleDate = thisWeekDates.join(',');

  }
}
