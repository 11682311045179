import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MenuItem} from 'primeng/api';
import { TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../service/http.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  items: MenuItem[];
  activeMenu: string;
  loading: boolean;

  constructor(private translate: TranslateService, private http: HttpService, private confirmationService: ConfirmationService) { }

  setActiveMenu(link): void {
    this.items.map(item => {
      if (item.routerLink === link) {
       this.activeMenu = link;
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.items = [
        {
          label: this.translate.instant('GENERAL'),
          routerLink: 'general'
        },
        {
          label: this.translate.instant('USERS'),
          routerLink: 'users'
        },
        {
          label: this.translate.instant('CATEGORIES'),
          routerLink: 'categories'
        },
        {
          label: this.translate.instant('UNITS'),
          routerLink: 'units'
        },
        {
          label: this.translate.instant('TAX_RATES'),
          routerLink: 'tax-rates'
        },
        {
          label: this.translate.instant('LOCATIONS'),
          routerLink: 'locations'
        },
        {
          label: this.translate.instant('INVENTORY_ITEMS'),
          routerLink: 'setting-inventory-items'
        },
        {
          label: this.translate.instant('OPERATIONS'),
          routerLink: 'operations'
        },
        {
          label: this.translate.instant('RESOURCES'),
          routerLink: 'resources'
        },
        {
          label: this.translate.instant('ROLES'),
          routerLink: 'roles'
        },
        {
          label: this.translate.instant('INTEGRATIONS'),
          routerLink: 'integrations'
        },
        {
          label: this.translate.instant('PRINT_TEMPLATE'),
          routerLink: 'print-templates'
        },
        {
          label: this.translate.instant('SUBSCRIPTION'),
          routerLink: 'subscription'
        },
        {
          label: this.translate.instant('DATA_IMPORT'),
          routerLink: 'import'
        },
        {
          label: this.translate.instant('FLUSH_ALL_DATA'),
          routerLink: null
        },
      ];

      this.items.map(item => {
        if (window.location.href.indexOf(item.routerLink) > 0) {
          this.activeMenu = item.routerLink;
        }
      });
    }, 1000);
  }

  async flushAllData(): Promise<void> {
    this.confirmationService.confirm({
      message: this.translate.instant('FLUSH_ALL_DATA_CONFIRM'),
      acceptLabel: this.translate.instant('YES'),
      rejectLabel: this.translate.instant('NO'),
      accept: async () => {
        this.loading = true;
        await this.http.delete('/api/company/truncate');
        this.loading = false;
      }
    });
  }
}
