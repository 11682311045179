import {Component, Input, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrencyService} from '../../../service/currency.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {VariantRecipeModel} from '../../../model/variant-recipe.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {StockModel} from '../../../model/stock.model';
import {Subscription} from 'rxjs';
import {VariantRecipeItemModel} from '../../../model/variant-recipe-item.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {SidebarService} from '../../../service/sidebar.service';
import {LocationModel} from '../../../model/location.model';
import {combineAll} from 'rxjs/operators';
import {classNames} from '@angular/cdk/schematics';
import {CookBookModel} from '../../../model/cook-book.model';
import {StockTransfersModel} from '../../../model/stock-transfers.model';
import {PackOptionModel} from '../../../model/pack-option.model';


@Component({
  selector: 'app-variant-recipe',
  templateUrl: './variant-recipe.component.html',
  styleUrls: ['./variant-recipe.component.scss']
})
export class VariantRecipeComponent implements OnInit {
  @Input() variantId: number;
  location: any;
  id: number;
  loading: boolean;
  recipe: VariantRecipeModel = new VariantRecipeModel();
  variant: StockVariantModel = new StockVariantModel();
  variants: StockVariantModel[] = [];
  preparations: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  autoCompletePackValues: any[] = [];
  sameProductValues: StockVariantModel[] = [];
  locations: LocationModel[] = [];
  currencies: string[] = [];
  currency: string;
  rates: string[] = [];
  stocks: StockModel[] = [];
  submitted: boolean;
  errors = [];
  validationSubscriber: Subscription;
  total: number;
  isSaveAvailable: boolean;
  isAddStockAvailable: boolean;
  selectedProducts: any[] = [];
  sameProductTableLoading: boolean;
  sameShowError: boolean;
  preparationShow: boolean;
  preparationQuantity: number;
  isAdded = true;
  modifierOptions: any[] = [];
  extraOptions: any[] = [];
  stepItems: MenuItem[];
  activeIndex = 0;
  translateInput: false;
  stepShow: boolean;
  modifiersAlert: boolean;
  extrasAlert: boolean;
  aof: boolean;
  ssa: boolean;
  fdh: boolean;
  averagePrices: any[] = [];
  testPrice = {
    salesPrice: 0,
    cost: 0,
    costRate: 0,
    profit: 0,
  };
  sameProductAlert: boolean;
  sameRecipeInCookBook: any[] = [];
  searchSameRecipeLoading: boolean;
  cookbookDetailDisplay: boolean;
  selectedCookBook: any;
  recipeOrPackOption: boolean;
  packStocks: PackOptionModel[] = [];
  stockID: number;

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private currencyService: CurrencyService, private flashMessageService: FlashMessageService,
              public translate: TranslateService, private sidebarService: SidebarService) {
  }

  ngOnInit(): void {
    this.location = parseFloat(window.localStorage.getItem('locationID'));
    this.stepShow = false;
    this.aof = true;
    this.recipeOrPackOption = true;
    this.fdh = false;
    this.load().then();
    this.recipe.variant = '/api/stock_variants/' + this.variantId;
    this.stepItems = [{
      label: this.translate.instant('MAIN_RECIPE'),
      command: () => {
        this.activeIndex = 0;
      }
    },
      {
        label: this.translate.instant('MODIFIERS'),
        command: () => {
          this.activeIndex = 1;
        }
      },
      {
        label: this.translate.instant('EXTRAS'),
        command: () => {
          this.activeIndex = 2;
        }
      }
    ];

  }

  async load(): Promise<void> {
    this.loading = true;
    this.currency = '₺';
    await this.http.get(`${StockVariantModel.IRI}/${this.variantId}`).subscribe((response) => {
      this.variant = response;
      this.stockID = response.stock.id;
      this.onLoadPackStock();
      // @ts-ignore
      this.variant.stock.modifier?.items?.map(item => {
        this.modifierOptions.push(item);
      });
      // @ts-ignore
      this.variant.stock.extra?.items?.map(item => {
        this.extraOptions.push(item);
      });
    });
    await this.http.get(LocationModel.IRI).subscribe((response) => {
      this.locations = response['hydra:member'];
    });
    await this.http.get(`${VariantRecipeModel.IRI}?variant.id=${this.variantId}`).subscribe((response: VariantRecipeModel[]) => {
      if (response['hydra:member'].length > 0) {
        let subtotal = 0;
        this.recipe = response['hydra:member'][0];
        this.recipe.variant = this.recipe.variant['@id'] ? this.recipe.variant['@id'] : null;
        this.recipe.variantRecipeItems.map((item, i) => {
          // @ts-ignore
          const findIndex = item.stock.stockQuantities.findIndex((obj) => obj.location === '/api/locations/' + this.location);
          // @ts-ignore
          this.averagePrices.push({
            // @ts-ignore
            averagePrice: item.stock.stockQuantities[findIndex]?.averagePrice,
            // @ts-ignore
            lastPurchasePrice: item.stock.stockQuantities[findIndex]?.lastPurchasePrice
          });
          subtotal += item.cost;
          this.recipe.variantRecipeItems[i].currency = item.currency['@id'] ? item.currency['@id'] : null;
          this.recipe.variantRecipeItems[i].recipe = this.recipe['@id'] ? this.recipe['@id'] : null;
          if (item.stock !== undefined) {
            // @ts-ignore
            this.variants.push(item.variant);
            // @ts-ignore
            this.autoCompleteValues.push(item.variant);
            // @ts-ignore
            this.currencies.push(item.currency.code);

            // this.recipe.variantRecipeItems[i].stock = item.stock['@id'] ? item.stock['@id'] : null;
            this.recipe.variantRecipeItems[i].variant = item.variant['@id'] ? item.variant['@id'] : null;

            if (item.rate) {
              this.rates[i] = item.rate.toString();
            }
          }
        });
        this.total = subtotal;
        this.modifierControl();
        this.extraControl();
      } else {
        this.recipe.servingSize = 1;
        this.modifierControl();
        this.extraControl();
      }
      this.loading = false;
    });
  }

  getAveragePrice(item): number {

    let averagePrice = 0;
    item.stock.stockQuantities.map(qt => {
      if (qt.location === '/api/locations/' + this.location) {
        averagePrice = qt.averagePrice;
      }
    });
    return averagePrice;
  }

  modifierControl(): void {
    if (this.modifierOptions?.length > 0) {
      this.stepShow = true;
      this.modifierOptions?.map((modifier) => {
        // @ts-ignore
        const findModifier = this.recipe.variantRecipeItems.findIndex(obj => (obj.modifierItem?.originalId === modifier.originalId));
        if (findModifier === -1) {
          this.addItem(modifier, null);
        }

      });
      this.rowControl();
    }
  }

  extraControl(): void {
    if (this.extraOptions?.length > 0) {
      this.stepShow = true;
      this.extraOptions?.map((extra) => {
        // @ts-ignore
        const findExtra = this.recipe.variantRecipeItems.findIndex(obj => (obj.modifierItem?.originalId === extra.originalId));
        console.log(findExtra);
        console.log(extra.name);

        if ((findExtra === -1)) {
          this.addItem(null, extra);
        }
      });
      this.rowControl();
    }
  }

  sameProduct(): void {
    this.sameProductTableLoading = true;

    if (this.recipe.variantRecipeItems.length === 0) {
      this.sameProductTableLoading = false;
      this.sameShowError = true;
      setTimeout(() => {
        this.sameShowError = false;
      }, 2000);
    } else {
      this.http.get(StockVariantModel.IRI + '/same-product', {
        stockName: this.variant.stock.name,
        variantName: this.variant.name,
        location: localStorage.getItem('locationID')
      }).subscribe(response => {

        const total = response['hydra:totalItems'];
        if (total === 0) {
          this.sameProductAlert = true;
        } else {
          this.sameProductValues = response['hydra:member'];
        }
        this.sameProductTableLoading = false;
      });
    }
  }

  async save(): Promise<void> {
    this.submitted = true;
    this.packStocks.map(pack => {
      pack.stock = '/api/stocks/' + this.stockID;
      if (pack.id){
        this.http.put(`${PackOptionModel.IRI}/${pack.id}`, pack).then((response: PackOptionModel) => {
          this.sidebarService.toggle();
        }).catch(err => {
        });
      }else {
        this.http.post(PackOptionModel.IRI, pack).then((response: PackOptionModel) => {
          this.sidebarService.toggle();
        });
      }
    });
    await this.http.put(`${StockVariantModel.IRI}/${this.variant.id}`, this.variant).then((response: StockVariantModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      this.sidebarService.toggle();
    }).catch(err => {
    });
    this.recipe.variantRecipeItems.map((item, key) => {
      if (this.recipe.variantRecipeItems[key].modifierItem) {
        this.recipe.variantRecipeItems[key].modifierItem = item.modifierItem['@id'];
      }
      this.recipe.variantRecipeItems[key].stock = this.recipe.variantRecipeItems[key].stock['@id'];
    });

    if (this.recipe.id) {
      this.recipe.variant = this.recipe.variant['@id'];
      await this.http.put(`${VariantRecipeModel.IRI}/${this.recipe.id}`, this.recipe).then((response: VariantRecipeModel) => {
        if (response) {
          this.recipe = response;
          this.sidebarService.toggle();
          this.submitted = false;
          this.recipe.variantRecipeItems.map((item, key) => {
            this.recipe.variantRecipeItems[key].stock = item.stock['@id'];
            this.recipe.variantRecipeItems[key].variant = item.variant['@id'];
            this.recipe.variantRecipeItems[key].currency = item.currency['@id'];
            this.recipe.variantRecipeItems[key].modifierItem = item.modifierItem['@id'];
          });
        }
      });
    } else {
      await this.http.post(VariantRecipeModel.IRI, this.recipe).then((response: VariantRecipeModel) => {
        if (response) {
          this.recipe.id = response.id;
          this.recipe.variantRecipeItems = response.variantRecipeItems;
          this.recipe.variantRecipeItems.map((item, key) => {
            this.recipe.variantRecipeItems[key].stock = item.stock['@id'];
          });

          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }
    this.submitted = false;
  }

  checkPriceDifference(item: VariantRecipeItemModel, variant: StockVariantModel): object {
    this.calculateTotal();
    let cost = 0;

    if (variant === undefined || variant.stock === undefined) {
      return {'default-row': true};
    }

    if (variant.stock.isMaking) {
      cost = variant?.totalCost * item.amountWithWastage;
    } else {
      cost = variant?.price * item.amountWithWastage;
    }

    if (cost.toFixed(3) > item.cost.toFixed(3)) {
      return {'danger-row': true};
    } else if (cost.toFixed(3) < item.cost.toFixed(3)) {
      return {'success-row': true};
    }

    return {'default-row': true};
  }

  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {
        name: event.query,
        'stock.isActive': 1
      }
    ).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  calculateAmountWithWastage(amount: number, wastage: number, i: number): number {
    this.calculateTotal();
    if (amount !== undefined && wastage !== undefined) {
      return amount + (amount / 100 * wastage);
    }

    return 0;
  }

  searchStock = event => {
    this.http.get(StockModel.IRI, {name: event.query, isActive: 1}).subscribe((response: StockModel[]) => {
      this.stocks = response['hydra:member'];
    });
  }

  onVariantSelect(variant: StockVariantModel, i: number): void {
    let averagePrice = 0;
    variant.stock.stockQuantities.map((qt) => {
      // @ts-ignore
      if (qt.location === '/api/locations/' + this.location) {
        averagePrice = qt.averagePrice;
      }
    });
    // @ts-ignore
    this.currencies.push(variant.stock.currency.symbol);
    this.recipe.variantRecipeItems[i].stock = variant.stock;
    this.recipe.variantRecipeItems[i].variant = variant['@id'];
    // tslint:disable-next-line:max-line-length
    this.recipe.variantRecipeItems[i].cost = this.calculateCost(averagePrice,
      this.recipe.variantRecipeItems[i].amount, variant.totalCost, variant.stock.isMaking);
    this.recipe.variantRecipeItems[i].currency = variant.stock.currency['@id'];
    // @ts-ignore
    const currencyId = variant.stock.currency.id;
    this.http.get('/api/currency_rates/today', {id: currencyId}).subscribe(response => {
      if (response) {
        this.rates[i] = response.rate;
      }
    });
  }
  onPackVariantSelect(variant: StockVariantModel, i: number): void {
    const item = {
      packStock : variant.stock,
      packVariant: variant,
      quantity: ''
    };
    this.packStocks[i].packStock = variant.stock['@id'];
    this.packStocks[i].packVariant = variant['@id'];
  }

  calculateCost = (unitPrice, amount, totalCost: number, isMaking = false) => {

    this.submitted = true;
    this.calculateTotal();
    let cost = 0;

    if (isMaking) {
      if (amount !== undefined && totalCost !== undefined) {
        cost = totalCost * amount;
      }
    } else {
      if (amount !== undefined && unitPrice !== undefined) {
        cost = unitPrice * amount;
      }
    }
    this.submitted = false;
    return cost;
  }

  changeServingSize(event): void {
    this.recipe.servingSize = parseFloat(event.target.value);
    this.recipe.variantRecipeItems.map((item, i) => {
      item.cost = (item.bigAmount / this.recipe.servingSize) * this.averagePrices[i].averagePrice;
      item.amount = item.bigAmount / this.recipe.servingSize;
      item.amountWithWastage = item.bigAmount / this.recipe.servingSize;
    });
    this.calculateTotal();
  }

  addItem(modifier = null, extra = null): void {
    const item = new VariantRecipeItemModel();
    item.type = 'MAIN';
    if (modifier) {
      item.modifierItem = modifier;
      item.type = 'MODIFIER';
    } else if (extra) {
      item.modifierItem = extra;
      item.type = 'EXTRA';
    }
    item.cost = 0;
    item.wastage = 0;
    this.recipe.variantRecipeItems.push(item);
    this.rowControl();
  }

  addPackItem(): void {
    const item = new PackOptionModel();
    item.quantity = 1;
    this.packStocks.push(item);
  }

  removeItem = i => {
    if (this.recipe.variantRecipeItems[i].id) {
      this.http.delete(`${VariantRecipeItemModel.IRI}/${this.recipe.variantRecipeItems[i].id}`).then(() => {
        this.recipe.variantRecipeItems.splice(i, 1);
        this.autoCompleteValues.splice(i, 1);
        this.rates.splice(i, 1);
        this.calculateTotal();
        this.save().then();
      });
    } else {
      this.recipe.variantRecipeItems.splice(i, 1);
      this.autoCompleteValues.splice(i, 1);
      this.rates.splice(i, 1);
    }
    this.rowControl();
  }

  removePackItem = i => {
    if (this.packStocks[i].id) {
      this.http.delete(`${PackOptionModel.IRI}/${this.packStocks[i].id}`).then(() => {
        this.packStocks.splice(i, 1);
        this.autoCompletePackValues.splice(i, 1);
      });
    } else {
      this.packStocks.splice(i, 1);
      this.autoCompletePackValues.splice(i, 1);

    }
    this.rowControl();
  }

  calculateTotal(): void {
    let grandTotal = 0;
    let grandWeight = 0;
    this.recipe.variantRecipeItems.map(item => {
      grandTotal = grandTotal + item.cost;
      grandWeight = grandWeight + item.amount;
    });
    this.variant.totalCost = (grandTotal);
    this.recipe.totalWeight = (grandWeight);
    this.variant.profit = this.variant.salePrice - (grandTotal);
    if ((grandTotal) > this.variant.salePrice) {
      this.variant.margin = 0.00;
      this.isSaveAvailable = true;
    } else {
      if ((grandTotal) === 0.0) {
        this.variant.margin = 0.00;
      } else {
        this.variant.margin = parseFloat(((((grandTotal) / this.variant.salePrice) * 100)).toFixed(2));
      }
      this.isSaveAvailable = false;
    }
    this.rowControl();
  }

  calculateTotalWithServingSize(): void {
    let grandTotal = 0;
    this.recipe.variantRecipeItems.map((item, i) => {
      item.cost = (item.bigAmount / this.recipe.servingSize) * this.averagePrices[i].averagePrice;
      grandTotal = grandTotal + item.cost;
      item.amount = item.bigAmount / this.recipe.servingSize;
      item.amountWithWastage = item.bigAmount / this.recipe.servingSize;
    });
    this.variant.totalCost = (grandTotal / this.recipe.servingSize);
    this.variant.profit = this.variant.salePrice - (grandTotal / this.recipe.servingSize);
    if ((grandTotal / this.recipe.servingSize) > this.variant.salePrice) {
      this.variant.margin = 0.00;
      this.isSaveAvailable = true;
    } else {
      if ((grandTotal / this.recipe.servingSize) === 0.0) {
        this.variant.margin = 0.00;
      } else {
        this.variant.margin = parseFloat(((((grandTotal / this.recipe.servingSize) / this.variant.salePrice) * 100)).toFixed(2));
      }
      this.isSaveAvailable = false;
    }
    this.rowControl();
  }

  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  onCurrencyChange(rate: string, i: number): void {
    this.recipe.variantRecipeItems[i].rate = parseFloat(rate);
  }

  rowControl(): void {
    console.log(this.recipe.variantRecipeItems);
    this.modifiersAlert = false;
    this.extrasAlert = false;
    this.isAddStockAvailable = false;
    this.recipe.variantRecipeItems.map(item => {
      if (item.amountWithWastage === 0 || item.amountWithWastage === undefined) {
        this.isAddStockAvailable = false;
      }else if (item.stock === null || item.stock === undefined) {
        this.isAddStockAvailable = true;
      }else if (item.amountWithWastage === null || item.amountWithWastage === null) {
        this.isAddStockAvailable = true;
      }
      if (item.extraItem !== undefined && item.stock === undefined) {
        this.extrasAlert = true;
      }
      if (item.modifierItem !== undefined && item.stock === undefined) {
        this.modifiersAlert = true;
      }
    });
  }

  weightChange(event, i): void {

    let totalWeight = 0;
    setTimeout(() => {
      this.recipe.variantRecipeItems.map(item => {
        totalWeight = totalWeight + item.weight;
      });
      this.recipe.totalWeight = totalWeight;
    }, 100);

  }

  translateQuantity(weight): void {
    this.recipe.variantRecipeItems.map((item, i) => {
      this.recipe.variantRecipeItems[i].amount = item.amount / weight;
      this.recipe.variantRecipeItems[i].amountWithWastage = item.amountWithWastage / weight;
      this.recipe.variantRecipeItems[i].cost = item.cost / weight;
      this.calculateTotal();
    });
  }

  getLocationName(id): string {
    const findIndex = this.locations.findIndex(obj => obj['@id'] === id);
    if (findIndex === -1) {
      return '';
    } else {
      return this.locations[findIndex].name;
    }
  }

  getLocationId(id): number {
    const findIndex = this.locations.findIndex(obj => obj['@id'] === id);
    if (findIndex === -1) {
      return 0;
    } else {
      return this.locations[findIndex].id;
    }
  }

  saveSameProduct1(): void {
    const recipeItems = [];
    this.recipe.variantRecipeItems.map(e => {
      const item = {
        variant: e.variant,
        currency: e.currency,
        amount: e.amount,
        amountWithWastage: e.amountWithWastage,
        cost: e.cost,
        quantity: e.quantity,
        stock: e.stock['@id'],
        wastage: e.wastage,
        type: e.type,
      };
      recipeItems.push(item);
    });

    this.selectedProducts.map((item, key) => {
      this.submitted = true;
      this.http.put(`${StockVariantModel.IRI}/${item.id}`, {
        totalCost: this.variant.totalCost,
        profit: this.variant.profit,
        margin: this.variant.margin,
        variantRecipes: this.recipe.variantRecipeItems
      }).then((response: VariantRecipeModel) => {
        if (response) {
          const newRecipe = {
            variant: '/api/stock_variants/' + item.id,
            servingSize: this.recipe.servingSize,
            variantRecipeItems: recipeItems
          };
          this.http.post(VariantRecipeModel.IRI, newRecipe).then((res) => {
          }).catch(err => {
          });
        }
        if (key === this.selectedProducts.length - 1) {
          this.submitted = false;
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(err => {
      });
    });
  }

  saveSameProduct(): void {

    this.submitted = true;
    this.http.post(VariantRecipeModel.IRI + '/' + this.recipe.id + '/recipe-dublicate', {
      recipe: this.recipe.id,
      sameProducts: this.selectedProducts
    }).then(response => {
      this.submitted = false;
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
    });
  }

  onRowUnselect(event): void {
    const index = this.selectedProducts.indexOf(event.data);
    if (index !== -1) {
      this.selectedProducts.splice(index, 1);
    }
  }

  addPreparation(): void {
    this.preparationShow = true;
  }

  searchPreparation = event => {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.type': 'semi_product'}).subscribe((response: StockVariantModel) => {
      this.preparations = response['hydra:member'];
      this.preparations[0]?.variantRecipes[0]?.variantRecipeItems.map((item) => {
        item.firstAmount = item.amount;
        item.firstAmountWithWastage = item.amountWithWastage;
      });
    });
  }

  onPreparationCalculate(): void {
    this.preparations[0]?.variantRecipes[0]?.variantRecipeItems.map((item) => {
      item.amount = item.firstAmount * this.preparationQuantity;
      item.amountWithWastage = item.firstAmountWithWastage * this.preparationQuantity;
      item.cost = item.cost * this.preparationQuantity;
    });
  }

  addPreparationToRecipe(): void {
    this.isAdded = false;
    this.preparations[0]?.variantRecipes[0]?.variantRecipeItems.map((item) => {
      // @ts-ignore
      const newItem = {
        amount: item.amount,
        amountWithWastage: item.amountWithWastage,
        cost: item.cost,
        currency: item.currency['@id'],
        firstAmountWithWastage: item.firstAmountWithWastage,
        quantity: item.quantity,
        variant: item.variant['@id'],
        stock: item.stock['@id'],
        wastage: item.wastage
      };
      // @ts-ignore
      this.recipe.variantRecipeItems.push(newItem);
      // @ts-ignore
      this.variants.push(item.variant);
      // @ts-ignore
      this.autoCompleteValues.push(item.variant);
      // @ts-ignore
      this.currencies.push(item.currency.code);
    });
    this.calculateTotal();
  }

  setModifierItemId(item, modifier, i): void {
    // this.variant.variantRecipes[0].variantRecipeItems[i].modifierItem = '/api/variant_recipe_items/' + modifier;
  }

  onChangeAof(): void {
    if (this.aof === true) {
      this.aof = false;
      this.ssa = true;
    } else {
      this.aof = true;
      this.ssa = false;
    }

    setTimeout(() => {
      this.recipe.variantRecipeItems.map((item, i) => {
        this.recipe.variantRecipeItems[i].cost = item.amountWithWastage * this.averagePrices[i].lastPurchasePrice;
      });
      this.calculateTotal();
    }, 500);
  }

  onChangeSsa(): void {
    if (this.ssa === true) {
      this.aof = true;
      this.ssa = false;
    } else {
      this.aof = false;
      this.ssa = true;
    }
    setTimeout(() => {
      this.recipe.variantRecipeItems.map((item, i) => {
        this.recipe.variantRecipeItems[i].cost = item.amountWithWastage * this.averagePrices[i].averagePrice;
      });
      this.calculateTotal();
    }, 300);
  }

  onChangeFdh(): void {
    this.testPrice.salesPrice = this.variant?.salePrice;
    this.testPrice.cost = this.variant?.totalCost;
    if (this.fdh === true) {
      this.fdh = false;
    } else {
      this.fdh = true;
    }
  }

  onTestPriceCalculate(event): void {
    this.testPrice.costRate = (this.testPrice.cost / parseFloat(event.target.value)) * 100;
    this.testPrice.profit = parseFloat(event.target.value) - this.testPrice.cost;
  }

  searchSameRecipeInCookBook(name): void {
    this.searchSameRecipeLoading = true;
    this.http.get(CookBookModel.IRI + '/same_recipe_in_cook_book/' + name).subscribe(response => {
      this.sameRecipeInCookBook = response;
      this.searchSameRecipeLoading = false;
      console.log(response);
    });
  }

  showRecipeItems(cookBook): void {
    this.loading = true;
    this.http.get(`${CookBookModel.IRI}/${cookBook.id}`).subscribe(response => {
      this.selectedCookBook = response;
      this.cookbookDetailDisplay = true;
      this.loading = false;
    });
  }

  setThisCookBook(cookBook): void {
    this.loading = true;
    this.http.get(CookBookModel.IRI + '/save_recipe_from_cookbook', {
      cookbook: cookBook.id,
      variant: this.variant.id
    }).subscribe(response => {
      this.load().then();
    });
  }

  onRecipeOrPackOption(type): void {
    if (type === 'recipe'){
      this.recipeOrPackOption = true;
    }else {
      this.recipeOrPackOption = false;
    }
  }

  onLoadPackStock(): void {
    this.http.get(PackOptionModel.IRI, {
      stock: this.stockID
    }).subscribe((response) => {
      this.packStocks = response['hydra:member'];
      this.packStocks.map((pack , i) => {
        this.autoCompletePackValues.push( pack.packStock );
        pack.packStock = pack.packStock['@id'];
      });
      this.loading = false;
    });
  }
  changePackQuantity(event , i): void {
    this.packStocks[i].quantity = event;
}
}
