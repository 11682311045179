
<div class="p-grid">
  <div class="p-col-8 p-offset-2">
    <app-loading-indicator [loading]="loading"></app-loading-indicator>
      <p-card class="p-mt-6">
        <div class="p-p-3 p-text-center">
          <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
        </div>
        <h1 class="p-text-center">{{ 'CHOSE_PACKET'|translate }}</h1>
        <p class="p-text-center">{{ 'CHOSE_PACKET_DESCRIPTION'|translate }}</p>
        <app-subscription [onlyUserLocation]="true"></app-subscription>
      </p-card>
  </div>
</div>

