import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../service/auth.service';
import {environment} from '../../../../environments/environment';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {ActivatedRoute} from '@angular/router';
import {LocationModel} from '../../../model/location.model';
import {HttpService} from '../../../service/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted: boolean;
  email: string;
  password: string;
  needLogin: boolean;
  apiUrl: string;

  constructor(private authService: AuthService, private flashMessageService: FlashMessageService, private route: ActivatedRoute,
              private http: HttpService) {
  }

  async ngOnInit(): Promise<void> {

    const token = this.route.snapshot.queryParamMap.get('token');

    if (token) {
      await this.authService.loginWithToken(token);
    }

    this.apiUrl = environment.apiUrl;
    await this.authService.checkLogin().then(response => {
      if (response) {
        // window.location.href = '/';
      }
    });
    this.needLogin = true;
  }

  async login(): Promise<void> {
    this.submitted = true;
    await this.authService.login(this.email, this.password).then(response => {
      if (response.token) {
        this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe( location => {
         if ( location.length === 0){
           window.location.href = '/payment';
         }else {
           window.location.href = '/';
         }
        });
        return true;
      } else {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'LOGIN_FAILED'));
        return false;
      }
    });

    this.submitted = false;
  }
}
