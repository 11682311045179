import {Component, OnInit} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../../service/http.service';
import {ContactService} from '../../../service/contact.service';
import {LazyLoadEvent} from 'primeng/api';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';
import {CurrencyService} from '../../../service/currency.service';
import {UtilityService} from '../../../service/utility.service';
import {LocationModel} from '../../../model/location.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

@Component({
  selector: 'app-price-change-report',
  templateUrl: './price-change-report.component.html',
  styleUrls: ['./price-change-report.component.scss']
})
export class PriceChangeReportComponent implements OnInit {

  loading: boolean;
  stocks: StockModel[] = [];
  suppliers: ContactModel[] = [];
  parameters = {
    id: null,
    page: 1,
    date: [],
    'supplier.id': '',
    itemsPerPage: 20,
    location: null,
    'location.id': '',
    startDate : '',
    endDate : ''
  };
  total: 0;
  type: string;
  searchContacts: ContactModel[] = [];
  tableLoading: boolean;
  staticsDisplay: boolean;
  data: any;
  chartOptions: any;
  stockName: string;
  currencyLabel: string;
  locations: LocationModel[] = [];
  dateRange: Date;
  variants: StockVariantModel[] = [];
  first = 0;


  constructor(private translate: TranslateService, private http: HttpService, private utilityService: UtilityService,
              private contactService: ContactService, private currencyService: CurrencyService) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });
    this.http.get(StockPriceChanges.IRI, {pagination: false}).subscribe(response => {
    });
    this.searchContacts = this.contactService.getSearchContacts({pagination: false});
    this.loading = false;
  }

  load(event: LazyLoadEvent = null, type = null): void {
    if (event?.sortField) {
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }
    this.loading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }else{
      this.parameters.page = 1;
    }


    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(StockModel.IRI, this.parameters).subscribe(response => {
      // tslint:disable-next-line:triple-equals
      if (type == 'search' && this.parameters.startDate && this.parameters.endDate) {
        this.stocks = [];
        this.total = 0;
        response['hydra:member'].map(item => {
          const c = new Date(item.createdAt);
          const createDate = `${c.getFullYear()}-${c.getMonth() + 1}-${c.getDate()}`;
          if (createDate >= this.parameters.startDate && createDate <= this.parameters.endDate) {
            this.stocks.push(item);
            this.total += 1;
            this.loading = false;
          }else{
            this.loading = false;
          }
        });

      } else {
        this.stocks = response['hydra:member'];
        this.total = response['hydra:totalItems'];
        this.loading = false;
      }
    });
    this.parameters.id = null;
  }

  changeContact(event): void {
    this.parameters['supplier.id'] = event.value;
    this.stocks = [];
  }

  changeLocation(event): void {
    this.parameters.location = event.value;
    this.stocks = [];
  }

  onStartChange(event): void {
    const start = event[0];
    const end = event[1];
    this.parameters.startDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    this.parameters.endDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
  }

  openPriceDialog(row): void {
    const dates = [];
    const prices = [];
    this.stockName = row.name;
    this.http.get(StockPriceChanges.IRI, {'stock.id': row.id}).subscribe(response => {
      response['hydra:member'].map(item => {
        prices.push(item.price);
        // @ts-ignore
        const date = new Date(item.createdAt);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        dates.push(month + '-' + year);
      });
      setTimeout(() => {
        this.data = {
          labels: dates,
          datasets: [{
            type: 'line',
            label: this.translate.instant('PRICE_CHANGE_GRAPHICS'),
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            data: prices
          }]
        };
        this.staticsDisplay = true;
      }, 100);
    });
  }

  calculateChange(a, b): string {
    const ratio = ((a / b) * 100) - 100;
    if (b === null || b === 0) {
      return ('%' + 100);
    } else {
      return ('%' + ratio.toFixed(2));
    }
  }

  async onVariantSelect(event: StockModel): Promise<void> {
    this.parameters.id = event.id;
  }
  handlePageChange(event): void {
    this.first = event.first;
    this.parameters.page = (this.first / this.parameters.itemsPerPage) + 1;
  }
  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  }

  export(): void {
    this.http.download('/api/data/exports/priceChanges')
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'priceChanges.xlsx');
      });
  }
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`inventory.pdf`); // PDF'i indir
    });
  }

}
