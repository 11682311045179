<app-stock-list-menu></app-stock-list-menu>
<p-card>
  <button pButton i18n-label label=" {{ 'EXPORT'|translate }}" icon="pi pi-download"  [style]="{float: 'right'}" class="p-button-secondary p-button-text" (click)="export()"></button>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'STOCKS' | translate}}</h5>
      <app-sync *ngIf="companyIntegration.length > 0" [entities]="entities" (callback)="load()"></app-sync>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'STOCKS_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <span class="p-buttonset p-mb-5 p-d-inline-block">
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['stock.type'] == 'product'"
            (click)="parameters['stock.type'] = 'product'; load()" label="{{'PRODUCT' | translate}}"></button>
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['stock.type'] == 'semi_product'"
            (click)="parameters['stock.type'] = 'semi_product'; load()" label="{{'SEMI_PRODUCT' | translate}}"></button>
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['stock.type'] == 'material'"
            (click)="parameters['stock.type'] = 'material'; load()" label="{{'MATERIAL' | translate}}"></button>
</span>
  <form action="" (ngSubmit)="load()" (submit)="load()">
    <p-table [loading]="loading" [lazy]="true" [value]="items" [totalRecords]="total" (onLazyLoad)="load($event)" [rowsPerPageOptions]="[10,25,50]"
             [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true" columnResizeMode="expand"
             styleClass="p-datatable-striped" (onColResize)="onResize($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: settings|columnWidth: 'stocks': '0'}" pResizableColumn scope="col">{{'STOCK' | translate}} <app-tooltip description="PRODUCTS_NAME_TOOLTIP"></app-tooltip> </th>
          <th [style]="{width: settings|columnWidth: 'stocks': '1'}" pResizableColumn scope="col">{{'SKU' | translate}} <app-tooltip description="PRODUCTS_SKU_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '2'}" pResizableColumn scope="col">{{'CATEGORY' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '3'}" pResizableColumn scope="col">{{'TAX' | translate}} <app-tooltip description="PRODUCTS_TAX_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '4'}" pResizableColumn scope="col">{{'AVERAGE_COST' | translate}} <app-tooltip description="PRODUCTS_AVERAGE_COST_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '5'}" pResizableColumn scope="col">{{'CURRENCY' | translate}} <app-tooltip description="PRODUCTS_CURRENCY_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '6'}" pResizableColumn scope="col">{{'IN_STOCK' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '7'}" pResizableColumn scope="col">{{'EXPECTED_AMOUNT' | translate}} <app-tooltip description="EXPECTED_AMOUNT_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '8'}" pResizableColumn scope="col">{{'COMMITTED_AMOUNT' | translate}} <app-tooltip description="RESERVED_AMOUNT_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '9'}" pResizableColumn scope="col">{{'ALERT_LEVEL' | translate}} <app-tooltip description="ALERT_LEVEL_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '10'}" pResizableColumn scope="col">{{'MISSING_STOCK' | translate}} <app-tooltip description="MISSING_AMOUNT_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'stocks': '10'}" pResizableColumn scope="col">{{'MIN_ORDER' | translate}} <app-tooltip description="MIN_ORDER_TOOLTIP"></app-tooltip></th>
          <th scope="col"></th>
        </tr>
        <tr>
          <td><input type="text" pInputText [(ngModel)]="parameters.name" name="name"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.code" name="code"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['stock.category.name']" name="category"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['stock.tax.name']" name="tax"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.averageCost" name="averageCost"></td>
          <td></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.valueInStock" name="amount"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.expectedAmount" name="expectedAmount"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.committedAmount" name="committedAmount"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.alertLevel" name="alertLevel"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.missingAmount" name="missingAmount"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.minOrder" name="missingAmount"></td>
          <th scope="col">
            <button type="submit" class="p-d-none" pButton label="{{'stock.search' | translate}}" (click)="load()"></button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td><a  routerLink="/stocks/{{row.stock.id}}/edit">{{ row.name }}</a></td>
          <td>{{ row.code }}</td>
          <td>{{ row.stock.category?.parent?.name }} {{ row.stock.category?.parent ? '/' : '' }} {{ row.stock.category?.name }}</td>
          <td>{{ row.stock.tax ? row.stock.tax['name'] : '-' }}</td>
          <td>{{ row.averageCost }}</td>
          <td>{{ row.stock.currency ? row.stock.currency.code : currency.code }}</td>
          <td><a type="button" href="javascript:void(0)" class="p-button-text" (click)="showTransactions(row.id, 'in_stock')">{{ row.amount }}</a></td>
          <td><a type="button" href="javascript:void(0)" class="p-button-text"  (click)="showTransactions(row.id, 'expected')">{{row.expectedAmount}}</a></td>
          <td><a type="button" href="javascript:void(0)" class="p-button-text"  (click)="showTransactions(row.id, 'committed')">{{ row.committedAmount }}</a></td>
          <td>{{ row.alertLevel }}</td>
          <td>{{ row.minOrder }}</td>
          <td><a type="button" href="javascript:void(0)" class="p-button-text"  (click)="showTransactions(row.id, 'missing')">{{ row.missingAmount }}</a></td>
          <td class="p-text-right">
          <span class="p-buttonset">
            <button *ngIf="row.stock.type == 'material'" pButton type="button" icon="pi pi-plus" class="p-button-text"
            label="{{'BUY' | translate}}" (click)="buy(row.id)"></button>
            <button *ngIf="row.stock.type === 'product' || row.stock.type === 'semi_product'" pButton type="button" icon="pi pi-plus" class="p-button-text"
            label="{{'MAKE' | translate}}" (click)="make(row.id, 1)"></button>
          </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</p-card>

<p-confirmPopup></p-confirmPopup>
