import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../service/auth.service';
import {HttpService} from '../../../service/http.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean;
  email: string;
  needLogin: boolean;
  apiUrl: string;

  constructor(private authService: AuthService, private http: HttpService, private flashMessageService: FlashMessageService) { }

  async ngOnInit(): Promise<void> {
    this.apiUrl = environment.apiUrl;
    await this.authService.checkLogin().then(response => {
      if (response.isValid) {
        window.location.href = '/';
      }
    });
    this.needLogin = true;
  }

  async send(): Promise<void> {
    this.submitted = true;
    await this.http.post('/api/security/forgot-password', {email: this.email}).then((response) => {
      // @ts-ignore
      if (response.success) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.sendPasswordToken'));
      }
    }).catch(error => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'flashMessages.error'));
    });

    this.submitted = false;
  }
}
