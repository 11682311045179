<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'SALES' | translate }}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'SALES'"></app-page-info>
      <button pButton pRipple type="button" label="{{ 'NEW' | translate}}"
              (click)="openForm()" [style]="{marginLeft: '5px', float:'right'}">
      </button>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2 p-md-2 p-sm-12">
      <p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}"
                  appendTo="body" (onClose)="load()" selectionMode="range" [(ngModel)]="parameters.createdAt"
                  name="createdAt" dateFormat="yy-mm-dd"></p-calendar>
    </div>
    <div class="p-col-2 p-md-2 p-sm-12">
      <p-dropdown inputId="dropdown" (onChange)="parameters.deliveryStatus = $event.value; load()" baseZIndex="9999"
                  [options]="deliveryStatuses" name="materialStatus" [(ngModel)]="parameters.deliveryStatus"
                  optionLabel="name" optionValue="value"></p-dropdown>
    </div>
    <div class="p-col-2 p-md-2 p-sm-12">
      <p-dropdown [options]="suppliers" [(ngModel)]="parameters.contactId" name="supplier" (onChange)="load()"
                  placeholder="{{'SELECT_SUPPLIER' | translate}}" optionValue="@id" optionLabel="name"></p-dropdown>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-sm-6">

      <form (submit)="load()" *ngIf="!detailed">
        <div class="card">
          <p-table responsiveLayout="scroll" [loading]="loading" [lazy]="true" [value]="items" [totalRecords]="total"
                   (onLazyLoad)="load($event)" [rowsPerPageOptions]="[10,25,50]" [reorderableColumns]="true"
                   (onRowReorder)="onReorder($event)"
                   [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true"
                   styleClass="p-datatable-striped" columnResizeMode="expand" (onColResize)="onResize($event)"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width:3rem"></th>
                <th [style]="{width: settings|columnWidth: parameters.type: '0'}"
                    pResizableColumn>{{ (parameters.type == invoiceType.Sales ? 'ORDER_NUMBER' : 'PURCHASE_NUMBER') | translate }}
                  <app-tooltip [description]="'ORDER_NUMBER_TOOLTIP'"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '1'}"
                    pResizableColumn>{{ (parameters.type == invoiceType.Sales ? 'CUSTOMER' : 'SUPPLIER')|translate}}
                  <app-tooltip
                    description="{{ parameters.type == invoiceType.Sales ? 'CUSTOMER_TOOLTIP' : 'SUPPLIER_TOOLTIP'}}"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '2'}"
                    pResizableColumn>{{ 'TAGS'|translate  }}</th>
                <th [style]="{width: settings|columnWidth: parameters.type: '3'}" pResizableColumn>{{ 'TOTAL' | translate}}
                  <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '4'}"
                    pResizableColumn>{{ 'CURRENCY' | translate}}
                  <app-tooltip description="CURRENCY_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '5'}"
                    pResizableColumn>{{ 'CREATED_AT' | translate}}
                  <app-tooltip description="CREATED_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '6'}"
                    pResizableColumn>{{ 'DEADLINE' | translate}}
                  <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '6'}" pResizableColumn
                    *ngIf="parameters.type === invoiceType.Purchase">{{ 'STATUS' | translate}}
                  <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '7'}" pResizableColumn
                    *ngIf="parameters.type === invoiceType.Sales">{{ 'STOCK_STATUS' | translate}}
                  <app-tooltip description="STOCK_STATUS_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '8'}" pResizableColumn
                    *ngIf="parameters.type === invoiceType.Sales">{{ 'MATERIAL_STATUS' | translate}}
                  <app-tooltip description="MATERIAL_STATUS_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '9'}" pResizableColumn
                    *ngIf="parameters.type === invoiceType.Sales && parameters.status == invoiceStatus.Confirmed">{{ 'PRODUCTION_STATUS' | translate}}
                  <app-tooltip description="PRODUCTION_STATUS_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '10'}" pResizableColumn
                    *ngIf="parameters.type === invoiceType.Sales && parameters.status == invoiceStatus.Confirmed">{{ 'DELIVERY_STATUS' | translate}}
                  <app-tooltip description="DELIVERY_STATUS_TOOLTIP"></app-tooltip>
                </th>
                <th [style]="{width: settings|columnWidth: parameters.type: '11'}" pResizableColumn
                    *ngIf="parameters.type === invoiceType.Purchase">{{ 'IS_RECEIVED' | translate}}</th>
              </tr>
              <tr>
                <th style="width:3rem"></th>
                <td><input type="text" pInputText [(ngModel)]="parameters.code" name="code"></td>
                <td><input type="text" pInputText [(ngModel)]="parameters['contact.name']" name="contactName"></td>
                <td><input type="text" pInputText [(ngModel)]="parameters.tags" name="tags"></td>
                <td><input type="text" pInputText [(ngModel)]="parameters.total" name="total"></td>
                <td><input type="text" pInputText [(ngModel)]="parameters['currency.code']" name="currency"></td>
                <td>
                  <p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" appendTo="body" (onClose)="load()"
                              selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt"
                              dateFormat="yy-mm-dd"></p-calendar>
                </td>
                <td>
                  <p-calendar #expectedDate (onSelect)="closeCalendar('expectedDate')" appendTo="body" (onClose)="load()"
                              selectionMode="range" [(ngModel)]="parameters.expectedDate" name="expectedDate"
                              dateFormat="yy-mm-dd"></p-calendar>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales">
                  <p-calendar #createdAt (onSelect)="closeCalendar('createdAt')"
                              placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body" (onClose)="load()"
                              selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt"
                              dateFormat="yy-mm-dd"></p-calendar>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales">
                  <p-dropdown inputId="dropdown" (onChange)="parameters.itemStatus = $event.value; load()" appendTo="body"
                              baseZIndex="9999" [options]="stockStatuses" name="itemStatus"
                              [(ngModel)]="parameters.itemStatus" optionLabel="name" optionValue="value"></p-dropdown>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales">
                  <p-dropdown inputId="dropdown" (onChange)="parameters.materialStatus = $event.value; load()"
                              appendTo="body" baseZIndex="9999" [options]="stockStatuses" name="materialStatus"
                              [(ngModel)]="parameters.materialStatus" optionLabel="name" optionValue="value"></p-dropdown>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales && parameters.status == invoiceStatus.Confirmed">
                  <p-dropdown inputId="dropdown" (onChange)="parameters.productionStatus = $event.value; load()"
                              appendTo="body" baseZIndex="9999" [options]="productionStatuses" name="materialStatus"
                              [(ngModel)]="parameters.productionStatus" optionLabel="name" optionValue="value"></p-dropdown>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales && parameters.status == invoiceStatus.Confirmed">
                  <p-dropdown inputId="dropdown" (onChange)="parameters.deliveryStatus = $event.value; load()"
                              appendTo="body" baseZIndex="9999" [options]="deliveryStatuses" name="materialStatus"
                              [(ngModel)]="parameters.deliveryStatus" optionLabel="name" optionValue="value"></p-dropdown>
                </td>
                <td *ngIf="parameters.type === invoiceType.Purchase">
                  <input type="text" pInputText [(ngModel)]="parameters.isReceived" name="isReceived"></td>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-index="rowIndex">
              <tr [pReorderableRow]="index">
                <td>
                  <span class="pi pi-bars" pReorderableRowHandle></span>
                  {{ index + 1 }}
                </td>
                <td width="15%"><a routerLink="/{{row.type.toLowerCase()}}/{{row.id}}/edit">{{ row.code }}</a></td>
                <td>{{ row.customerName ? row.customerName : (row.contact ? row.contact.name : '-') }}</td>
                <td>{{ row.tags }}</td>
                <td
                  style="text-align: right">{{ row.total|currency: (row.currency ? row.currency.code : currency): '' }}</td>
                <td>{{ row.currency ? row.currency.code : currency }}</td>
                <td>{{ row.createdAt|date: 'M/d/yyyy' }}</td>
                <td>{{ row.expectedDate ? (row.expectedDate|date: 'shortDate') : '-' }}</td>
                <td *ngIf="parameters.type === invoiceType.Purchase"> {{ row.status | translate }}</td>
                <td *ngIf="parameters.type === invoiceType.Sales">
                  <p-tag [severity]="row.itemStatus|statusLabel">{{ row.itemStatus|status: row.itemExpectedDate }}</p-tag>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales">
                  <p-tag
                    [severity]="row.materialStatus|statusLabel">{{ row.materialStatus|status: row.materialExpectedDate }}</p-tag>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales && parameters.status == invoiceStatus.Confirmed">
                  <p-tag [severity]="row.productionStatus|statusLabel" icon="pi pi-chevron-down" class="cursor-pointer"
                         (click)="loadWorkOrders(row.id); op.toggle($event);">{{ row.productionStatus|translate }} </p-tag>
                </td>
                <td *ngIf="parameters.type === invoiceType.Sales && parameters.status == invoiceStatus.Confirmed">
                  <p-tag (click)="openChangeStatusPanel(dp, $event, row.id)" [severity]="row.deliveryStatus|statusLabel"
                         class="cursor-pointer" icon="pi pi-chevron-down"
                         *ngIf="row.deliveryStatus != deliveryActions.DELIVERED">{{row.deliveryStatus|translate}} </p-tag>
                  <p-tag severity="success"
                         *ngIf="row.deliveryStatus == deliveryActions.DELIVERED">{{row.deliveryStatus|translate}}</p-tag>
                </td>
                <td *ngIf="parameters.type === invoiceType.Purchase">
                  <button [routerLink]="['/purchases/receive-list/' + row.id]" type="button" pButton class="p-button-text"
                          [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></button>
                </td>
                <!----
                <td *ngIf="parameters.type === invoiceType.Purchase"><button type="button" pButton [disabled]="row.isReceived" (click)="receive(row.id)" class="p-button-text" [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></button></td>
                ---->
              </tr>
            </ng-template>
          </p-table>
        </div>
        <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
      </form>
      <app-invoice-detailed-list *ngIf="detailed"></app-invoice-detailed-list>
      <table class="total-box p-p-3" *ngIf="!detailed">
        <tr>
          <th>{{ 'SUBTOTAL' | translate}}</th>
          <td>{{ totals.subtotal|currency: currency }}</td>
        </tr>
        <tr>
          <th>{{ 'TAX' | translate}}</th>
          <td>{{ totals.tax|currency: currency }}</td>
        </tr>
        <tr>
          <th>{{ 'TOTAL' | translate}}</th>
          <td>{{ totals.total|currency: currency }}</td>
        </tr>
      </table>
      <div class="clearfix"></div>
    </div>
  </div>

</p-card>
<p-sidebar (onHide)="reload()" [(visible)]="display" [style]="{width:'80em'}" position="right">
  <!--- <app-new-material-create *ngIf="displayCreate" [type]="'material'" [item]="item" ></app-new-material-create>--->
  <app-invoice-create></app-invoice-create>
</p-sidebar>


<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>

</p-card>
<p-overlayPanel #op dismissable="true" showCloseIcon="true">
  <ng-template pTemplate>
    <p-table [value]="workOrders" [loading]="workOrdersLoading" style="width: 75px" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'code' | translate}}</th>
          <th>{{'workOrderList.status' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-row>
        <td>
          <a href="" [routerLink]="'/work-orders/' + row.id + '/edit'">{{ row.code }}</a>
        </td>
        <td>
          <p-tag [severity]="row.status|statusLabel">{{ row.status|status }}</p-tag>
        </td>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #dp>
  <ng-template pTemplate>
    <div *ngFor="let item of changeStatusItems">
      <p-button *ngIf="item.state.status !== deliveryActions.PARTIAL_DELIVERED" [label]="item.label"
                styleClass="p-button-text" class="p-d-block"
                (onClick)="changeStatus(item.state.id, item.state.status); dp.hide()"></p-button>
      <p-button *ngIf="item.state.status === deliveryActions.PARTIAL_DELIVERED" [label]="item.label"
                styleClass="p-button-text" class="p-d-block" (onClick)="receive(item.state.id); dp.hide()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>
