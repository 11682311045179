import {Component, OnInit} from '@angular/core';
import {LocationModel} from '../../../model/location.model';
import {IncomingInvoiceModel} from '../../../model/incoming-invoice.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactService} from '../../../service/contact.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {ContactModel} from '../../../model/contact.model';

@Component({
  selector: 'app-incoming-invoice-list',
  templateUrl: './incoming-invoice-list.component.html',
  styleUrls: ['./incoming-invoice-list.component.scss']
})
export class IncomingInvoiceListComponent implements OnInit {
  loading: boolean;
  locations: LocationModel[] = [];
  incomingInvoices: IncomingInvoiceModel[] = [];
  hideInvoices: IncomingInvoiceModel[] = [];
  invoiceCategory: any[] = [];
  parameters = {
    id: '',
    category: '',
    contact: '',
    'contact.hideInvoice': 0,
    location: 0,
    page: 1,
    ettn: '',
    invoice: '',
    itemsPerPage: 20,
    isActive: 1,
  };
  total: number;
  selectedRow: boolean;
  apiUrl: string;
  searchSuppliers = [];
  invoiceItemsShow: boolean;
  invoiceItems: any[] = [];
  invoiceLoading: boolean;
  selectLocationShow: boolean;
  selectLocationShowCount = 0;
  errorMessage: string;
  errorMessageShow: boolean;
  eInvoiceSynchroMenus: MenuItem[];
  dateRangeDisplay: boolean;
  today: Date = new Date();
  isHideInvoice: boolean;
  isHide = false;
  message: string;
  message2: string;
  messageHideInvoice: string;
  messageShowInvoice: string;
  display: boolean;
  supplierLoading: boolean;
  displayAction: boolean;
  hideInvoiceAndSupplierDisplay: boolean;
  displayInvoiceCloseOptions: boolean;
  actions: any;
  id: number;
  items: MenuItem[];
  activeItem: MenuItem;

  constructor(private http: HttpService, private router: Router, private contactService: ContactService,
              private activatedRoute: ActivatedRoute, private translate: TranslateService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.loadSearchData().then();
    this.eInvoiceSynchroMenus = [
      {
        label: this.translate.instant('TODAY_INCOMING_INVOICE'), icon: 'pi pi-refresh', command: () => {
          this.getIncomingEinvoices(this.today);
        }
      },
      {
        label: this.translate.instant('SELECT_DATE'), icon: 'pi pi-refresh', command: () => {
          this.dateRangeDisplay = true;
        }
      }
    ];

    setTimeout(() => {
      this.items = [
        {
          label: this.translate.instant('HIDE_SUPPILERS'), title: 'SUPPLIERS', command: () => {
            this.activeItem = this.items[0];
          }
        },
        {
          label: this.translate.instant('HIDE_INVOICES'), title: 'INVOICES', command: () => {
            console.log('faturalar');
            this.activeItem = this.items[1];
          }
        },
      ];
      this.activeItem = this.items[0];
    }, 200);

  }

  async loadSearchData(): Promise<void> {
    this.loading = true;

    await this.http.get(`${ContactModel.IRI}/get-contact-by-type/supplier`).subscribe(category => {
      this.searchSuppliers = category['hydra:member'];
    });
    await this.http.get(`${LocationModel.IRI}/subscriptionControl`).subscribe(response => {
      this.locations = response;
      if (response.length === 1) {
        this.selectLocationShow = false;
        this.parameters.location = this.locations[0].id;
      } else {
        this.selectLocationShow = true;
      }
      this.loading = false;
    });
  }

  changeParameters(event, type): void {
    if (type === 'category') {
      this.parameters['category.id'] = event.value;
    }
    if (type === 'contact') {
      this.parameters.contact = event.value;
    }
    if (type === 'location') {
      this.parameters.location = event.value;
    }
    this.load(null);
  }

  load(event: LazyLoadEvent = null): void {
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.loading = true;
    this.parameters['contact.hideInvoice'] = 0;
    if (this.display) {
      this.parameters['contact.hideInvoice'] = 1;
    } else {
      this.parameters['contact.hideInvoice'] = 0;
    }

    this.http.get(IncomingInvoiceModel.IRI, this.parameters).subscribe(response => {
      this.incomingInvoices = response['hydra:member'];
      this.total = response['hydra:totalItems'];

      this.loading = false;
    });

    this.http.get(IncomingInvoiceModel.IRI, {isActive: 0}).subscribe(response => {
      this.hideInvoices = response['hydra:member'];
    });
  }

  setIncomingInvoice(relatedId, i): void {
    this.selectedRow = i;
  }

  viewInvoicesPdf(ettn): void {
    this.parameters.ettn = ettn;
    this.http.get('/api/company_integrations/logo/get-invoice-inbox-pdf', this.parameters).subscribe(response => {
    });
  }

  viewInvoiceItems(item): void {
    this.invoiceItems = item.incomingInvoiceItems;
    this.id = item.id;
    this.invoiceItemsShow = true;
  }

  downloadPdf(ettn): void {
    console.log(ettn);
    this.http.get('/api/incoming_invoices/e-invoice?location=' +
      this.parameters.location + '&&ettn=' + ettn).subscribe(response => {
      window.location.href = response;
    });
  }

  getIncomingEinvoices(range): void {
    const dateString = range;
    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Ay indeksleri 0'dan başlar, bu yüzden 1 eklenir
    const day = String(dateObj.getDate()).padStart(2, '0');
    const resultDateString = year + '-' + month + '-' + day;
    this.dateRangeDisplay = false;
    this.invoiceLoading = true;
    this.http.get('/api/incoming_invoices/e-invoice?location=' +
      this.parameters.location + '&&date=' + resultDateString).subscribe(response => {
      if (response['hydra:member'][1] === 'NOT_FOUND_ACCOUNTING') {
        this.errorMessage = this.translate.instant('NOT_FOUND_ACCOUNTING');
        this.errorMessageShow = true;
        setTimeout(() => {
          this.errorMessageShow = false;
        }, 10000);
      }
      if (response.success === false) {
        this.errorMessage = response.message.authenticate;
        this.errorMessageShow = true;
        setTimeout(() => {
          this.errorMessageShow = false;
        }, 5000);
      }
      this.invoiceLoading = false;
      this.load();
    }).catch(e => {
        this.invoiceLoading = false;
      }
    );
  }

  hideSameInvoice(event, email): void {
    this.confirmationService.confirm({
      message: this.translate.instant('HIDE_THIS_COMPANY_INCOMING_INVOICES'),
      accept: () => {
        this.http.put(`${ContactModel.IRI}/${event}`, {
          hideInvoice: true,
          email: email ? email : 'sample@contact.com'
        }).then(response => {
          if (response) {
            this.load();
          }
        });
      }
    });
  }

  hideThisInvoice(id): void {
    this.confirmationService.confirm({
      message: this.translate.instant('HIDE_THIS_INCOMING_INVOICES'),
      accept: () => {
        this.http.put(`${IncomingInvoiceModel.IRI}/${id}`, {
          isActive: false,
        }).then(response => {
          if (response) {
            this.invoiceItemsShow = false;
            this.load();
          }
        });
      }
    });
  }

  showSameInvoice(event, email): void {
    this.confirmationService.confirm({
      message: this.translate.instant('SHOW_THIS_COMPANY_INCOMING_INVOICES'),
      accept: () => {
        this.http.put(`${ContactModel.IRI}/${event}`, {
          hideInvoice: false,
          email: email ? email : 'sample@contact.com'
        }).then(response => {
          if (response) {
            this.hideInvoiceAndSupplierDisplay = false;
            this.load();
          }
        });
      }
    });
  }

  showInvoice(event, email): void {
    this.confirmationService.confirm({
      message: this.translate.instant('SHOW_THIS_INCOMING_INVOICES'),
      accept: () => {
        this.http.put(`${IncomingInvoiceModel.IRI}/${event}`, {
          isActive: true
        }).then(response => {
          if (response) {
            this.hideInvoiceAndSupplierDisplay = false;
            this.load();
          }
        });
      }
    });
  }

  onMessage(event): void {
    this.message = event + this.translate.instant('HIDE_CHOOSE_COMPANY_INCOMING_INVOICES');
    this.message2 = event + this.translate.instant('SHOW_CHOOSE_COMPANY_INCOMING_INVOICES');
    this.messageHideInvoice = event + this.translate.instant('HIDE_THIS_INCOMING_INVOICES');
    this.messageShowInvoice = event + this.translate.instant('SHOW_THIS_INCOMING_INVOICES');
  }

  async openDialog(): Promise<void> {
    this.hideInvoiceAndSupplierDisplay = true;
  }

  reload(): void {
    this.load();
  }

  goToPage(link): void {
    if (this.checkActionPermission('INCOMING_INVOICE_RECEIVE_AVAILABLE')) {
      window.location.href = link;
    } else {
      this.displayAction = true;
    }
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].orders.findIndex(obj => obj.name === text);
      return this.actions[0].orders[findIndex].value ? this.actions[0].orders[findIndex].value : false;
    }
  }
}
