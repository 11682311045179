import {Component, OnInit, ViewChild} from '@angular/core';
import {
  InvoiceDeliveryStatus,
  InvoiceModel, InvoiceProductionStatus,
  InvoiceStatus,
  InvoiceStockStatus,
  InvoiceType
} from '../../../model/invoice.model';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {InvoiceReceiveComponent} from '../../invoice/invoice-receive/invoice-receive.component';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../service/utility.service';
import {WorkOrderModel} from '../../../model/work-order.model';
import {CurrencyService} from '../../../service/currency.service';
import {DeliveryActions} from '../../../model/invoice-item.model';
import {UserModel} from '../../../model/user.model';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {ContactModel} from '../../../model/contact.model';
import {LocationModel} from '../../../model/location.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ShipmentModel} from '../../../model/shipment.model';
import {DatePipe} from '@angular/common';
import {ShipmentItemModel} from '../../../model/shipment-item.model';



@Component({
  selector: 'app-return-invoice',
  templateUrl: './return-invoice.component.html',
  styleUrls: ['./return-invoice.component.scss'],
  providers: [DialogService, DynamicDialogConfig]
})
export class ReturnInvoiceComponent implements OnInit {

  @ViewChild('createdAt') createdAt;
  @ViewChild('expectedDate') expectedDate;

  loading: boolean;
  workOrdersLoading: boolean;
  total: number;
  items: InvoiceModel[] = [];
  workOrders: WorkOrderModel[] = [];
  currency: string;
  changeStatusItems: MenuItem[] = [];
  detailed: boolean;
  entities = [];
  grandTotal = 0;

  user: UserModel = new UserModel();
  settings = [];

  invoiceType = InvoiceType;
  invoiceStatus = [];
  status = [];
  invoiceDeliveryStatus = InvoiceDeliveryStatus;
  deliveryActions = DeliveryActions;
  suppliers: any[];

  totals = {
    total: 0,
    tax: 0,
    subtotal: 0
  };

  parameters = {
    code: '',
    'contact.name': '',
    tags: '',
    total: '',
    expectedDate: [],
    createdAt: [],
    materialStatus: '',
    itemStatus: '',
    isReceived: '',
    contactId: '',
    productionStatus: '',
    type: InvoiceType.Return,
    page: 0,
    location: '',
    itemsPerPage: 50,
    'currency.code': '',
    status: ''
  };

  stockStatuses = [];
  productionStatuses = [];
  deliveryStatuses = [];
  moreLoading: boolean;
  locations: LocationModel [] = [];
  shipment: ShipmentModel = new ShipmentModel();
  constructor(private http: HttpService,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private utilityService: UtilityService,
              private currencyService: CurrencyService,
              private dataTableSettingService: DataTableSettingService,
              private flashMessageService: FlashMessageService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.entities.push('invoice');
    this.currency = this.currencyService.getCurrency().symbol;
    setTimeout(() => {
      this.suppliers = [
        { name: this.translate.instant('ALL'), value: ''}
      ];
    }, 500);
    this.loadSuppliers();
    this.loadLocations();
  }
  loadLocations(): void {
    this.http.get(`${UserModel.IRI}/${this.user.id}`).subscribe(response => {
        response.locations.map(item => {
          this.locations.push(item);
        });
        // @ts-ignore
        this.locations.unshift({ name: this.translate.instant('ALL_LOCATIONS'), '@id': ''});
      }
    );
  }
  load(event: LazyLoadEvent = null, type = null): void {
    setTimeout(() => {
      this.status = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceStatus.Created).toString(), value: InvoiceStatus.Created},
        {name: this.translate.instant(InvoiceStatus.Send).toString(), value: InvoiceStatus.Send},
      ];
    }, 200);


    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    if (type !== 'search'){
      this.parameters.page = this.parameters.page + 1;
    }else {
      this.items = [];
      this.parameters.page = 1;
    }
    this.route.data.subscribe(data => {this.parameters.type =  data.type; });

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    let parameters = {...this.parameters};

    if (this.parameters.expectedDate) {
      const start = new Date(this.parameters.expectedDate[0]);
      const end = new Date(this.parameters.expectedDate[1]);

      parameters = {...parameters, ...{
          'expectedDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'expectedDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }};

      delete parameters.expectedDate;
    }
    if (this.parameters.createdAt) {
      const start = new Date(this.parameters.createdAt[0]);
      const end = new Date(this.parameters.createdAt[1]);

      parameters = {...parameters, ...{
          'createdAt[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'createdAt[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }};

      delete parameters.expectedDate;
    }

    this.loading = true;
    this.http.get(InvoiceModel.IRI, parameters).subscribe((response => {
      this.grandTotal = 0;
      this.total = response['hydra:totalItems'];
      response['hydra:member'].map( res => {
          this.items.push(res);
        }
      );
      if (this.total <= this.parameters.page * this.parameters.itemsPerPage){
        this.moreLoading = false;
      }else {
        this.moreLoading = true;
      }
      this.items.map(((item: InvoiceModel) => {
        this.grandTotal += item.normalizedTotal;
      }));
      this.totals.total = this.grandTotal;
      this.loading = false;
    }));

    this.http.get(`/api/invoices/totals`, parameters).subscribe((response: any) => {
      this.totals = response;
    });
  }
  loadSuppliers(): void {
    this.http.get(ContactModel.IRI, {type: 'supplier'}).subscribe((response: ContactModel) => {
      response['hydra:member'].map((items: any) => {
        this.suppliers.push({ name: items.name, value: items['@id'] });
      });
    });
  }
  receive(id: number): void {
    const ref = this.dialogService.open(InvoiceReceiveComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('PURCHASE_RECEIVE'),
    });

    ref.onClose.subscribe(() => {
      this.load();
    });
  }

  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;

    this.http.download('/api/data/exports/invoices', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'invoices.xlsx'); });
  }

  loadWorkOrders(id: number): void {
    this.workOrdersLoading = true;
    this.workOrders = [];
    this.http.get(WorkOrderModel.IRI, {'invoice.id': id}).subscribe((response: WorkOrderModel[]) => {
      this.workOrders = response['hydra:member'];
      this.workOrdersLoading = false;
    });
  }

  openChangeStatusPanel(panel, event, id: number): void {
    this.changeStatusItems = [
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED), state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED}},
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED), state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED  }},
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED), state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED}},
    ];

    panel.toggle(event);
  }

  changeStatus(id: number, status: string): any {
    this.loading = true;
    this.parameters.page = 0;
    this.http.put(`${InvoiceModel.IRI}/${id}`, {deliveryStatus: status}).then(response => {
      this.load(null, 'search');
    });
  }


  onResize(event): void {
    this.dataTableSettingService.save(
      this.parameters.type,
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  onReorder(event): void {
    this.http.post('/api/reorder', {drag: this.items[event.dragIndex].id, drop: this.items[event.dropIndex].id});
  }
  closeCalendar(field: string): void {
    let isClose = true;
    this.parameters.createdAt.map(item => {
      if (!item) {
        isClose = false;
      }
    });
    if (isClose) {
      this.createdAt.overlayVisible = false;
      this.createdAt.datepickerClick = true;
    }
  }

  sendInvoiceToAccounting(id): void {
    /*
    this.http.put(`${InvoiceModel.IRI}/${id}`, {
      isInvoiced: true,
      status: 'DELIVERED',
    }).then((res: InvoiceModel) => {
      if (res) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
    }).catch(err => {
    });
     */
    this.confirmationService.confirm({
      message: this.translate.instant('ALERT_SEND_RETURN_INVOICE'),
      accept: () => {
        this.http.get(`${InvoiceModel.IRI}/${id}/send`).toPromise().then(response => {
          if (response[0].isSuccess === true){
            this.http.put(`${InvoiceModel.IRI}/${id}`, {
              isInvoiced: true,
              status: 'SEND',
            }).then((res: InvoiceModel) => {
              if (res) {
                this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
              }
            }).catch(err => {
            });
          }
        });
      }
    });
  }

  saveInvoice(invoice): void {
    console.log(invoice);
    const date = new Date();
    const datePipe = new DatePipe('en-US');

    this.shipment.invoice = invoice['@id'];
    this.shipment.documentType = 'INVOICE';
    this.shipment.documentDate = datePipe.transform(date, 'yyyy-MM-dd');
    this.shipment.documentNumber = invoice.code;
    invoice.items.map((item, i) => {
      const shipmentItem = new ShipmentItemModel();
      shipmentItem.invoiceItem = item['@id'];
      shipmentItem.quantity = item.remainingQuantity;
      this.shipment.items.push(shipmentItem);
    });

    this.http.post(ShipmentModel.IRI, this.shipment).then((response: ShipmentModel) => {
     if (response) {
       this.load(null, 'search');
       this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
     }
    }).catch(error => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error));
    });
  }
}

