import {ApiResourceModel} from './api-resource.model';
import {UserModel} from './user.model';

export class UserPageInformationModel extends ApiResourceModel{
  public static IRI = '/api/user_page_informations';
  name: string;
  id: number;
  user: string;
  isActive: boolean;
}
