<p-card>
  <p-button *ngIf="this.id && !loading" [label]="(item.isArchive ? 'UN_ARCHIVE' : 'ARCHIVE')|translate" [disabled]="archiveLoading" [icon]="archiveLoading ? 'pi pi-spin pi-spinner' : ''" style="float: right" (click)="archive()" styleClass="p-button-secondary p-button-sm p-mb-2 p-float-right"></p-button>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'STOCK_UPDATE' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">
        {{ item.name }}
      </p>
    </ng-template>
  </p-splitter>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
    <app-product-menu [item]="item" active="generalInfo"></app-product-menu>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="name">{{'NAME' | translate}}</label>
        <input id="name" name="name" type="text" pInputText [(ngModel)]="item.name" (blur)="!this.id ? save(): null" autofocus>
        <small class="p-error">{{ errors['name'] }}</small>
      </div>
      <div class="p-field p-col-4">
        <label for="taxRate" class="p-pb-2">{{'TAX' | translate}}</label>
        <div class="p-grid">
          <div class="p-col-11">
            <p-dropdown [filter]="true" [placeholder]="'SELECT'|translate" [options]="taxes" [(ngModel)]="item.tax" optionLabel="name" id="taxRate"  optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['tax'] }}</small>
          </div>
          <div class="p-col-1">
            <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addTax()" icon="pi pi-plus"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label for="name" class="p-pb-2">{{'CATEGORY' | translate}}</label>
       <div class="p-grid">
         <div class="p-col-11">
           <p-dropdown [filter]="true" [placeholder]="'SELECT'|translate" [options]="categories" [(ngModel)]="item.category" optionLabel="name" id="category"  optionValue="@id"></p-dropdown>
           <small class="p-error">{{ errors['category'] }}</small>
         </div>
         <div class="p-col-1">
           <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addCategory()" icon="pi pi-plus"></p-button>
         </div>
       </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="supplier" class="p-pb-2">{{'SUPPLIER' | translate}}</label>
        <div class="p-grid">
          <div class="p-col-11">
            <p-dropdown [filter]="true" [placeholder]="'CONTACT_PLACEHOLDER'|translate" [options]="suppliers" [(ngModel)]="item.supplier" optionLabel="name" id="supplier"  optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['supplier'] }}</small>
          </div>
          <div class="p-col-1">
            <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addContact()" icon="pi pi-plus"></p-button>
          </div>
        </div>
      </div>
      <div class="p-field p-col">
        <label for="unit" class="p-pb-2">{{'UNIT' | translate}}</label>
        <div class="p-grid">
          <div class="p-col-11">
            <p-dropdown [filter]="true" [placeholder]="'SELECT'|translate" [options]="units" [(ngModel)]="item.unit" optionLabel="name" id="unit"  optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['unit'] }}</small>
          </div>
          <div class="p-col-1">
            <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addUnit()" icon="pi pi-plus"></p-button>
          </div>
        </div>
      </div>
      <div class="p-field p-col">
        <label for="currency">{{'CURRENCY' | translate}}</label>
        <p-dropdown [options]="currencies" [(ngModel)]="item.currency" (onChange)="changeCurrency($event)"  optionLabel="code" id="currency" optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['currency'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label for="trackType">{{'STOCK_TRACK_TYPE' | translate}}</label>
        <p-dropdown [options]="trackTypes" optionValue="code" optionLabel="name" [(ngModel)]="item.trackType" id="trackType" ></p-dropdown>
      </div>
      <div class="p-field p-col-4">
        <label for="tags">{{'TAGS' | translate}}</label>
        <p-chips [(ngModel)]="item.tags" id="tags" name="tags"></p-chips>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div class="p-pt-5">
          <p-checkbox id="isTrackByLot" [(ngModel)]="item.isTrackByLot" [binary]="true" label="{{'TRACK_BY_LOT' | translate}}"></p-checkbox>
          <p-checkbox id="useAveragePrice" [(ngModel)]="item.useAveragePrice" [binary]="true" label="{{'USE_AVERAGE_COST' | translate}}" class="p-ml-5"></p-checkbox>
          <p-checkbox id="isMaking" [(ngModel)]="item.isMaking" [binary]="true" label="{{'IS_MAKING' | translate}}" class="p-ml-5"></p-checkbox>
          <p-checkbox id="isBuying" [(ngModel)]="item.isBuying" [binary]="true" label="{{'IS_BUYING' | translate}}" class="p-ml-5"></p-checkbox>
        </div>
      </div>
    </div>

    <p-table [value]="item.variants" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let option of variantOptions">
            {{ option.name }}
          </th>
          <th>{{ 'SKU' | translate}} <app-tooltip description="SKU_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: '110px !important'}">{{ 'CURRENCY' | translate}} <app-tooltip description="PRODUCTS_CURRENCY_TOOLTIP"></app-tooltip></th>
          <th>{{ 'SALE_PRICE' | translate}} <app-tooltip description="STOCK_SALES_PRICE_TOOLTIP"></app-tooltip></th>
          <th>{{ 'PURCHASE_PRICE' | translate}} <app-tooltip description="STOCK_PURCHASE_PRICE_TOOLTIP"></app-tooltip></th>
          <th *ngIf="item.isMaking">{{ 'MATERIAL_COST' | translate}} <app-tooltip description="STOCK_MATERIAL_COST_TOOLTIP"></app-tooltip></th>
          <th *ngIf="item.isMaking">{{ 'OPERATION_COST' | translate}}<app-tooltip description="STOCK_OPERATION_COST_TOOLTIP"></app-tooltip></th>
          <th *ngIf="item.isMaking">{{ 'TOTAL_COST' | translate}} <app-tooltip description="STOCK_TOTAL_COST_TOOLTIP"></app-tooltip></th>
          <th>{{ 'AVAILABLE_AMOUNT' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
          <th>{{ 'ALERT_LEVEL' | translate}}</th>
          <th>{{ 'MIN_ORDER' | translate}}</th>
          <th width="10%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-variant let-i="rowIndex">
        <tr>
          <td pEditableColumn *ngFor="let option of variantOptions;let j = index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [options]="option.optionValues" [(ngModel)]="variant.stockVariantOptionValues[j].variantOptionValue" optionLabel="name" optionValue="@id"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngFor="let value of option.optionValues">{{ value['@id'] === variant.stockVariantOptionValues[j]?.variantOptionValue ? value.name : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" name="variantCode" pInputText [(ngModel)]="variant.code">
              </ng-template>
              <ng-template pTemplate="output">
                <span>{{ variant.code }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ currency }}</td>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="variant.salePrice" [minFractionDigits]="2" [maxFractionDigits]="5"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ variant.salePrice|currency: currency: '' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="variant.defaultPrice" [minFractionDigits]="2" [maxFractionDigits]="5"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ variant.defaultPrice|currency: currency: '' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td *ngIf="item.isMaking">
            {{ variant.materialCost|currency: currency: '' }}
          </td>
          <td *ngIf="item.isMaking">
            {{ variant.operationCost|currency: currency: '' }}
          </td>
          <td *ngIf="item.isMaking">
            {{ variant.totalCost|currency: currency: '' }}
          </td>
          <td pEditableColumn [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" [(ngModel)]="variant.amount" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ variant.amount }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" [(ngModel)]="variant.alertLevel" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ variant.alertLevel }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" [(ngModel)]="variant.minOrder" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ variant.minOrder }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td align="center">
            <span class="p-buttonset">
              <button pButton type="button" iconPos="left" icon="pi pi-chart-bar" class="p-button-text"  [routerLink]="['/products', item.id, 'stats', variant.id]"></button>
              <button pButton type="button" iconPos="left" icon="pi pi-eye" class="p-button-text" (click)="recipe(variant.id)"></button>
              <button pButton type="button" [disabled]="!variant.isDeletable" iconPos="left" icon="pi pi-trash" class="p-button-text p-button-danger" (click)="removeVariant(i)"></button>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-fluid p-pt-3">
      <div class="p-field p-formgrid p-grid p-pl-0">
        <div class="p-col-2" *ngIf="variantOptions.length > 0">
          <button (click)="addVariant()" class="p-button-secondary" [icon]="stockCodeLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'" [disabled]="stockCodeLoading" pButton label="{{'ADD_VARIANT' | translate}}" ></button>
        </div>
        <div class="p-col-2">
          <button pButton icon="pi pi-cog" class="p-button-secondary p-button-text" label="{{'VARIANT_OPTIONS' | translate}}" (click)="addVariantOption()" ></button>
        </div>
      </div>
      <div class="p-field">
        <label for="comment"></label>
        <p-editor id="comment" [(ngModel)]="item.comment" [style]="{'height':'120px'}"></p-editor>
      </div>
    </div>
    <button pButton label="{{'SAVE' | translate}}" class="p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
  </div>
</p-card>

