<app-loading-indicator [loading]="productionsLoading"></app-loading-indicator>
<div *ngIf="!productionsLoading && type === 'invoice'">
  <p><span style="font-weight: bold">{{ 'EXPECTED_DATE'|translate }}: </span>{{ invoice.expectedDate.date|date: 'M-d-Y' }}</p>
  <p><span style="font-weight: bold">{{ 'TAGS'|translate }}: </span>{{ invoice?.tags }}</p>
  <p><span style="font-weight: bold">{{ 'CUSTOMER'|translate }}: </span>{{ invoice?.contact?.name }}</p>
  <p-divider></p-divider>
  <div *ngFor="let item of invoice.items">
    <h4>{{ item?.variant?.name }}</h4>
    <p-table [value]="item.workOrders" responsiveLayout="scroll" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'OPERATION'|translate }}</th>
          <th>{{ 'RESOURCE'|translate }}</th>
          <th>{{ 'DESCRIPTION'|translate }}</th>
          <th>{{ 'STATUS'|translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-workOrder>
        <tr *ngFor="let operation of workOrder.operations">
          <td>{{ operation?.operation.name }}</td>
          <td>{{ operation?.resource.name }}</td>
          <td>{{ operation?.notes }}</td>
          <td><p-tag [severity]="operation.status|statusLabel">{{ operation?.status|translate }}</p-tag></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div style="margin-top: 20px" [innerHTML]="invoice.description"></div>
</div>

<div *ngIf="!productionsLoading && type === 'workOrder'">
  <h4>{{ workOrder?.variant?.name }}</h4>
  <p-table [value]="workOrder.operations" responsiveLayout="scroll" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'OPERATION'|translate }}</th>
        <th>{{ 'RESOURCE'|translate }}</th>
        <th>{{ 'STATUS'|translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-operation>
      <td>{{ operation?.operation.name }}</td>
      <td>{{ operation?.resource.name }}</td>
      <td><p-tag [severity]="operation.status|statusLabel">{{ operation?.status|translate }}</p-tag></td>
    </ng-template>
  </p-table>
</div>
