import {ApiResourceModel} from './api-resource.model';


export class SaleStatisticModel  extends ApiResourceModel {
  public static IRI = '/api/sale_statistics';
  public variant: string;
  public year: string;
  public month: string;
  public total: number;
  public price: number;
  public quantity: number;
}
