import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {LazyLoadEvent} from 'primeng/api';
import {StockTransactionModel} from '../../../model/stock-transaction.model';
import {TranslateService} from '@ngx-translate/core';
import {LocationModel} from '../../../model/location.model';

@Component({
  selector: 'app-stock-movements',
  templateUrl: './stock-movements.component.html',
  styleUrls: ['./stock-movements.component.scss']
})
export class StockMovementsComponent implements OnInit {

  locations: Location[] = [];
  variants: StockVariantModel[] = [];
  loading: boolean;
  items: StockTransactionModel[] = [];
  parameters = {
    variantId: 0,
    relatedType: '',
    location: 0,
    start: '',
    end: '',
    date: '',
    page: 1,
    itemsPerPage: 20,
  };
  total: number;
  moreLoading: boolean;
  stockMovementTypes: any[] = [];
  constructor( private http: HttpService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });
    this.stockMovementTypes = [
      {name: this.translate.instant('ALL'), value: ''},
      {name: this.translate.instant('PURCHASE'), value: 'Shipment'},
      {name: this.translate.instant('STOCK_TRANSFERS'), value: 'Transfer'},
      {name: this.translate.instant('WASTE_EVENTS'), value: 'Waste'},
      {name: this.translate.instant('SALES'), value: 'Sale'},
    ];
    this.http.get(StockVariantModel.IRI, {type: 'material'}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  searchStock = (event) => {
      this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
        this.variants = response['hydra:member'];
        this.parameters.variantId = response['hydra:member']['@id'];
      });
  }

  onVariantSelect(event): void {
    this.parameters.variantId = event.id;
    this.load();
  }
  onStartChange(event): void {
    this.parameters.start = event;
    // this.load();
  }
  onEndChange(event): void {
    this.parameters.end = event;
  }
  load(event: LazyLoadEvent = null): void{
    this.loading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }
    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    let parameters = {...this.parameters};
    if (this.parameters.start) {
      const start = new Date(this.parameters.start[0]);
      const end = new Date(this.parameters.start[1]);
      parameters = {...parameters, ...{
          startDate: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          endDate: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }};
    }

    this.parameters = parameters;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(`${StockTransactionModel.IRI}`, this.parameters ).subscribe((response => {
      this.total = response['hydra:totalItems'];
      this.items = response['hydra:member'].reverse();
      this.loading = false;
    }));

  }
}

