import {Component, OnInit} from '@angular/core';
import {CategoryModel} from '../../../../model/category.model';
import {Subscription} from 'rxjs';
import {CategoryService} from '../../../../service/category.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {TaxRateModel} from '../../../../model/tax-rate.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ContactModel} from '../../../../model/contact.model';
import {resolve} from '@angular/compiler-cli/src/ngtsc/file_system';
import {ContactService} from '../../../../service/contact.service';
import {CheckboxModule} from 'primeng/checkbox';
import {isArray} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {

  item: CategoryModel = new CategoryModel();
  supplier: ContactModel [];
  items: CategoryModel[] = [];
  validationSubscriber: Subscription;
  showForm: boolean;
  submitted: boolean;
  errors = [];
  taxes: TaxRateModel[] = [];
  codeLoading: boolean;
  loading: boolean;
  isOpen: boolean;
  categoryTypes: any [];
  isSelectedSupplier: boolean;
  searchContacts: ContactModel[] = [];
  showCode = false;
  mainCategories: CategoryModel[] = [];
  selectedCategories: CategoryModel[] = [];
  type: string;

  constructor(private categoryService: CategoryService,
              private flashMessageService: FlashMessageService,
              private ref: DynamicDialogRef,
              private validationService: ValidationService,
              private http: HttpService,
              private config: DynamicDialogConfig,
              private contactService: ContactService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.http.get(`${CategoryModel.IRI}/main-categories`, {categoryType: 'product'})
      .subscribe(response => {
        this.mainCategories = response['hydra:member'];
      });
    // this.loadCategory().then();
    this.categoryTypes = [
      {name: this.translate.instant('STOCK'), value: 'stock'},
      {name: this.translate.instant('MENU_ITEMS'), value: 'product'}
    ];
    if (this.config.data?.item) {
      this.item = this.config.data.item;
    }
    if (this.config.data?.type === 'product') {
      this.type = 'product';
    }else {
      this.type = 'stock';
    }
    console.log(this.type);
    this.searchContacts = [];
    this.searchContacts = this.contactService.getSearchContacts({pagination: false, type: 'supplier'});
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    this.http.get(TaxRateModel.IRI).subscribe(response => {
      this.taxes = response['hydra:member'];
    });
    this.loading = true;
    this.http.get(`${CategoryModel.IRI}/main-categories`, {categoryType: this.type})
      .subscribe(response => {
        this.items = response['hydra:member'];
        this.loading = false;
      });

    if (this.item.id === undefined) {
      this.codeLoading = true;
      this.http.get(`${CategoryModel.IRI}/category-number`).subscribe((response: CategoryModel) => {
        this.item.code = response.code;
        this.codeLoading = false;
      });
    }else {
      this.item.categoryMatches.map((matches, i) => {
        this.item.categoryMatches[i] = matches['@id'];
      });
    }
  }

  async loadCategory(): Promise<void> {
    await this.categoryService.getCategories().subscribe(response => {
      this.items = response;
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    if (this.item.id) {
      await this.categoryService.update(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          this.isOpen = false;
        }
        this.submitted = false;
      });
    } else {
      this.item.categoryType = this.type;
      await this.categoryService.save(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
        this.submitted = false;
      });
    }
    this.loadCategory().then();
  }

  getParentNumber = (event) => {
    this.item.parent = event.value;
    this.codeLoading = true;
    this.http.get(`${CategoryModel.IRI}/category-number?category=${event.value}`).subscribe((response: CategoryModel) => {
      this.item.code = response.code;
      this.codeLoading = false;
    });
  }
  addMatches(event): void {
    // this.item.categoryMatches = [];
    this.item.categoryMatches = event.value;
  }

  close(): void {
    this.ref.close();
  }

}
