<app-stock-list-menu></app-stock-list-menu>
<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'STOCK_REQUESTS' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'STOCK_REQUEST_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <span class="p-buttonset p-mb-5 p-d-inline-block">
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['status'] == 'seen'"
            (click)="parameters['status'] = 'seen'; load()" label="{{'SEEN_REQUESTS' | translate}}"></button>
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['status'] == 'rejected'"
            (click)="parameters['status'] = 'rejected'; load()" label="{{'REJECTED_REQUESTS' | translate}}"></button>
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['status'] == 'registered'"
            (click)="parameters['status'] = 'registered'; load()" label="{{'REGISTERED_REQUESTS' | translate}}"></button>
</span>

  <span class="p-buttonset p-mb-5 p-d-inline-block p-text-right" [style]="{float: 'right', marginRight: '13px'}">
   <a target="_parent" class="icon" routerLink="/stock-requests/create" ><span class="pi pi-plus-circle"></span> {{'NEW_REQUEST' | translate }}</a>
</span>


  <form action="" (ngSubmit)="load()" (submit)="load()">
    <p-table [loading]="loading" [lazy]="true" [value]="request" [totalRecords]="total" (onLazyLoad)="load($event)" [rowsPerPageOptions]="[10,25,50]"
             [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true" columnResizeMode="expand"
             styleClass="p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: '10%'}" pResizableColumn>{{'CODE' | translate}}</th>
          <th [style]="{width: '10%'}" pResizableColumn>{{'DATE' | translate}}</th>
          <th [style]="{width: '35%'}"pResizableColumn>{{'LOCATION' | translate}}</th>
          <th [style]="{width: '35%'}"pResizableColumn>{{'DESTINATION' | translate}}</th>
          <th [style]="{width: '15%'}"pResizableColumn>{{'STATUS' | translate}}</th>
          <th [style]="{width: '20%'}"pResizableColumn>{{'ACTIONS' | translate}}</th>
        </tr>
        <tr>
          <td><input type="text" pInputText [(ngModel)]="parameters.code" name="code"></td>
          <td><p-calendar id="requestDate" appendTo="body" selectionMode="range" baseZIndex="9999" (onClose)="load()" [(ngModel)]="parameters.requestDate" name="requestDate" dateFormat="yy-mm-dd"  placeholder="{{'SELECT_TRANSFER_DATE'| translate}}"  ></p-calendar></td>
          <td><p-dropdown id="origin" name="origin"  placeholder="{{'REQUEST_LOCATION_SELECT'| translate}}" (onChange)="load()" [(ngModel)]="parameters.location" [options]="locations"   appendTo="body" optionLabel="name"  optionValue="@id" ></p-dropdown>
          <td><p-dropdown id="destination" name="destination"  placeholder="{{'REQUEST_LOCATION_SELECT'| translate}}" (onChange)="load()" [(ngModel)]="parameters.destination" [options]="locations"   appendTo="body" optionLabel="name"  optionValue="@id" ></p-dropdown>
          </td>
          <td><p-dropdown inputId="dropdown"  appendTo="body"  [options]="requestStatuses" placeholder="{{'SELECT_TRANSFER_STATUS'| translate}}" name="status"  optionLabel="name" optionValue="value" ></p-dropdown>
          </td>
          <td>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td><a target="_blank" routerLink="/stock-requests/{{row.id}}/edit">{{ row.code }}</a></td>
          <td>{{ row.requestDate|date: 'Y-m-d' }}</td>
          <td>{{ row.location?.name }}</td>
          <td>{{ row.destination?.name }}</td>
          <td><p-tag [severity]="row.status|statusLabel">{{ row.status| translate }}</p-tag></td>
          <td>
            <p-dropdown inputId="dropdown"  appendTo="body" [(ngModel)]="row.status"  (onChange)="changeStatus($event, row.id)" [options]="requestStatuses" name="status"  optionLabel="name" optionValue="value" ></p-dropdown>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</p-card>
