import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ShipmentModel} from '../../../model/shipment.model';
import {ContactService} from '../../../service/contact.service';
import {LocationService} from '../../../service/location.service';
import {InvoiceModel} from '../../../model/invoice.model';
import {ActivatedRoute} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api';
import {StockAccountingCodeModel} from '../../../model/stock-accounting-code.model';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';

@Component({
  selector: 'app-invoice-delivery-notes',
  templateUrl: './invoice-delivery-notes.component.html',
  styleUrls: ['./invoice-delivery-notes.component.scss']
})
export class InvoiceDeliveryNotesComponent implements OnInit {
  loading: boolean;
  shipments: ShipmentModel[] = [];
  parameters = {
    documentNumber: '',
    'invoice.contact': '',
    'invoice.location': '',
    'invoice.code': '',
    'invoice.isInvoiced': 0,
    page: 1,
    itemsPerPage: 20
  };
  searchSuppliers = [];
  searchLocations = [];
  invoices: InvoiceModel[] = [];
  selectedInvoice: string;
  total: number;
  errors: any[] = [];
  isSaved: boolean;
  status: any[] = [];
  grandTotal: number;
  currency: string;

  constructor(private http: HttpService, private contactService: ContactService, private locationService: LocationService,
              private route: ActivatedRoute, private translate: TranslateService, private flashMessageService: FlashMessageService) {
  }

  ngOnInit(): void {
    this.selectedInvoice = this.route.snapshot.paramMap.get('purchase');
    this.loading = true;
    this.loadSearchData().then();
    this.status = [
      {value: this.translate.instant('SEND'), id: 1},
      {value: this.translate.instant('UN_SEND'), id: 0}
    ];
  }

  load(event: LazyLoadEvent = null): void {
    this.loading = true;
    if (event) {
      this.parameters.page = (event.first / this.parameters.itemsPerPage) + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    } else {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[documentDate]`] = 'DESC';
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(ShipmentModel.IRI, this.parameters).subscribe(response => {
      this.grandTotal = 0;
      this.shipments = response['hydra:member'];
      response['hydra:member'].map(res => {
        this.currency = res.invoice.currency.symbol;
      });
      this.total = response['hydra:totalItems'];
      this.loadTotal();
      this.loading = false;
    });

    /*
    let no = 0;
    this.http.get(InvoiceModel.IRI).subscribe(response => {
      this.invoices = response['hydra:member'];
      this.invoices.map((item , i) => {
        if (item.shipments.length > 0 ) {
          this.shipments.push(item.shipments[item.shipments.length - 1]);
          this.shipments[no].invoice = item;
          no = no + 1;
        }
      });

    });
    */
  }

  loadTotal(): void {
    this.grandTotal = 0;
    this.shipments.map(res => {
      if (res.invoice instanceof InvoiceModel) {
        this.grandTotal += res.invoice.total;
      }
    });
  }

  async loadSearchData(): Promise<void> {
    this.searchLocations = this.locationService.getSearchLocation();
    this.searchSuppliers = this.contactService.getSearchContacts({pagination: false, type: 'supplier'});
  }

  changeParameters(event, type): void {
    if (type === 'isInvoiced') {
      this.parameters['invoice.isInvoiced'] = event.value;
    }
    if (type === 'contact') {
      this.parameters['invoice.contact'] = event.value;
    }
    if (type === 'location') {
      this.parameters['invoice.location'] = event.value;
    }
    this.load();
  }

  sendInvoiceToAccounting(id): void {
    this.errors = [];
    this.isSaved = true;
    this.http.get(`${InvoiceModel.IRI}/${id}/send`).toPromise().then(response => {
      if (response['hydra:member'][0].isSuccess === false) {
        this.errors.push({
          status: this.translate.instant('UN_SAVED'),
          message: response['hydra:member'][0].Message,
        });
      } else {
        this.errors = [];
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
      this.load();
      this.isSaved = false;
    });
  }

}
