import {ApiResourceModel} from "./api-resource.model";
import {StockModel} from "./stock.model";
import {StockVariantOptionValueModel} from "./stock-variant-option-value.model";

export class StockVariantOptionModel extends ApiResourceModel{
  public static IRI = '/api/stock_variant_options';
  public name: string;
  public stock: StockModel;
  public optionValues: StockVariantOptionValueModel[] = [];
}
