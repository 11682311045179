import {Component, Input, OnInit} from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {CompanyModel} from '../../../../model/company.model';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {LazyLoadEvent} from 'primeng/api';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class LocationSubscriptionComponent implements OnInit {
  @Input() onlyUserLocation: boolean;
  items: LocationModel[] = [];
  item = new LocationModel();
  loading: boolean;
  private dayCount: number;
  displayPay: boolean;
  monthlyPlans = [
    {
      name: 'Temel',
      plain: 'A1',
      description: 'Envanter, sipariş ve alımlarınızı kontrol altına alır',
      code: '904d1ce4-8bf1-44c2-be7e-8978c9bf3931',
      price: 39,
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Profesyonel',
      plain: 'A2',
      description: 'Satılan malın maliyetini azaltmak için imkân ve araçlar sunar',
      code: 'b5ca7841-3346-4b14-b677-f2ed2d5d78c2',
      price: 69,
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Girişim',
      plain: 'A3',
      description: 'Çok şubeli işletmeler ve pazaryerleri üzerinde hâkimiyet sağlar',
      code: 'c7e11dc0-a380-4453-8e11-84c7f3946ef4',
      price: 89,
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
  ];
  annualPlans = [
    {
      name: 'Temel',
      plain: 'Y1',
      description: 'Envanter, sipariş ve alımlarınızı kontrol altına alır',
      code: '3c810c0f-c210-4678-9ffa-8ff4c9cf352b',
      price: 35,
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Profesyonel',
      plain: 'Y2',
      description: 'Satılan malın maliyetini azaltmak için imkân ve araçlar sunar',
      code: 'e26a46e2-9ad7-41fc-941e-4151ed948c49',
      price: 65,
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'Tedarikçi sipariş otomasyonu ve onayları'},
        {name: 'Tarif maliyeti ve dijital yemek kitabı'},
        {name: 'Envanter atık takibi'},
        {name: 'Gelişmiş karlılık raporlaması'},
        {name: 'Gerçek ve teorik raporlama'},
        {name: 'API erişimi'},
        {name: 'Ayda 200 fatura taraması'},
      ]
    },
    {
      name: 'Girişim',
      plain: 'Y3',
      description: 'Çok şubeli işletmeler ve pazaryerleri üzerinde hâkimiyet sağlar',
      code: '4eed8932-b586-4f5b-a4ee-17baeaaca7b3',
      price: 95,
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'Tedarikçi sipariş otomasyonu ve onayları'},
        {name: 'Tarif maliyeti ve dijital yemek kitabı'},
        {name: 'Envanter atık takibi'},
        {name: 'Gelişmiş karlılık raporlaması'},
        {name: 'Fiili ve teorik raporlama'},
        {name: 'API erişimi'},
        {name: 'Ayda 300 fatura taraması'},
      ]
    },
  ];
  // Qapera 2024 Abonelik Bilgileri
  monthlyPlansTl = [
    {
      name: 'Temel',
      plain: 'A1',
      description: 'Envanter, sipariş ve alımlarınızı kontrol altına alır',
      code: '4fde7d4c-b0b8-4627-865a-a21cf53080c2',
      price: '1.590',
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Profesyonel',
      plain: 'A2',
      description: 'Satılan malın maliyetini azaltmak için imkân ve araçlar sunar',
      code: 'd7863176-619c-4576-bb7f-580ac7f197d6',
      price: '2.960',
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Girişim',
      plain: 'A3',
      description: 'Çok şubeli işletmeler ve pazaryerleri üzerinde hâkimiyet sağlar',
      code: 'de2cad1b-1d8e-42e7-8f1f-5ded120e3183',
      price: '3.490',
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
  ];
  annualPlansTL = [
    {
      name: 'Temel',
      plain: 'Y1',
      description: 'Envanter, sipariş ve alımlarınızı kontrol altına alır',
      code: 'a642fc7e-dce6-47dd-a701-d7494cfabad7',
      price: '1.050',
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'POS & muhasebe yazılımı Entegrasyonları'},
        {name: 'Satın alma siparişlerini verme ve alma'},
        {name: 'Envanter sayımı ve transferler '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'SMM ve satın alma raporlaması '},
        {name: 'Borç hesapları yönetimi '},
        {name: 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Profesyonel',
      plain: 'Y2',
      description: 'Satılan malın maliyetini azaltmak için imkân ve araçlar sunar',
      code: '16a95313-9414-4c36-b2d0-88f52399c92e',
      price: '1.950',
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'Tedarikçi sipariş otomasyonu ve onayları'},
        {name: 'Tarif maliyeti ve dijital yemek kitabı'},
        {name: 'Envanter atık takibi'},
        {name: 'Gelişmiş karlılık raporlaması'},
        {name: 'Gerçek ve teorik raporlama'},
        {name: 'API erişimi'},
        {name: 'Ayda 200 fatura taraması'},
      ]
    },
    {
      name: 'Girişim',
      plain: 'Y3',
      description: 'Çok şubeli işletmeler ve pazaryerleri üzerinde hâkimiyet sağlar',
      code: '0236b022-f874-48d8-948f-5c3e31f1ff80',
      price: '2.550',
      priceSub: 'Konum başına / Aylık',
      specification: [
        {name: 'Tedarikçi sipariş otomasyonu ve onayları'},
        {name: 'Tarif maliyeti ve dijital yemek kitabı'},
        {name: 'Envanter atık takibi'},
        {name: 'Gelişmiş karlılık raporlaması'},
        {name: 'Fiili ve teorik raporlama'},
        {name: 'API erişimi'},
        {name: 'Ayda 300 fatura taraması'},
      ]
    },
  ];

  paymentForm: string = null;
  card = {
    cardHolderName: '',
    cardNumber: '',
    expireMonth: null,
    expireYear: null,
    cvc: '',
    companyName: '',
    companyAddress: '',
    taxOffice: '',
    taxNumber: '',
    city: '',
    zipCode: '',
    price: '',
    phone: '',
    email: '',
  };
  selectedPlain: null;
  selectedPlainPrice: string;
  index: number;
  selectedPlainCode: null;
  selectedPlainType: null;
  plainSelected: boolean;
  submitted: boolean;
  company: CompanyModel = new CompanyModel();
  userLocations: any;
  isFilter: boolean;
  companyLanguage: string;
  plainPriceSymbol: string;
  error: boolean;
  total: number;
  parameters = {
    page: 1,
    itemsPerPage: 10,
  };
  details: any[];
  detailsDisplay: boolean;

  constructor(private http: HttpService, private flashMessageService: FlashMessageService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    const userLocationsJson = localStorage.getItem('locations');
    const language = localStorage.getItem('language');
    this.plainPriceSymbol = '₺';
    this.companyLanguage = language;
    if (this.companyLanguage === 'en') {
      this.plainPriceSymbol = '$';
    }
    this.userLocations = JSON.parse(userLocationsJson);

    this.http.get(CompanyModel.IRI).subscribe(response => {
      this.company = response;
    });
  }

  load(event: LazyLoadEvent = null): void {
    this.loading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));

    this.http.get(LocationModel.IRI, this.parameters).subscribe((response: LocationModel[]) => {
      this.items = response['hydra:member'];
      this.total = response['hydra:totalItems'];
      this.loading = false;
    });
  }

  calculateDay(date): number {

    const today = new Date();
    // @ts-ignore
    const futureDate = new Date(date);
    const diffInTime = futureDate.getTime() - today.getTime();
    const remainingDay = Math.ceil(diffInTime / (1000 * 3600 * 24));
    if (remainingDay < 0) {
      return 0;
    } else {
      return remainingDay;
    }
  }

  showDialog(item): void {
    this.plainSelected = false;
    this.item = item;
    this.displayPay = true;
  }

  onSelectPlain(plain, code, price): void {
    this.selectedPlain = plain;
    this.selectedPlainCode = code;
    this.selectedPlainPrice = price;
    this.plainSelected = true;
  }

  async send(): Promise<void> {
    this.error = false;
    if (!this.card.companyName
      || !this.card.companyAddress
      || !this.card.taxNumber
      || !this.card.taxOffice
      || !this.card.zipCode
      || !this.card.city
      || !this.card.phone
      || !this.card.email
    ) {
      this.error = true;
    } else {
      this.submitted = true;
      delete this.item.currency;
      delete this.item.contact;
      delete this.item.locationSubscriptions;
      this.card.price = this.selectedPlainPrice;
      await this.http.post(`${LocationModel.IRI}/subscription?code=${this.selectedPlainCode}&location=${this.item.id}&plain=${this.selectedPlain}`, this.card).then(response => {
        if (response.status === 'success') {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'Abonelik İşlemi Tamamlandı'));
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        } else {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, response.errorMessage));
        }
      });
      this.submitted = false;
    }
  }

  locationFilter(): void {
    if (this.isFilter) {
      this.items = this.items.filter((location) => {
        const findIndex = this.userLocations.findIndex(obj => obj.id !== location.id);
        return findIndex === -1;
      });
    } else {
      this.http.get(LocationModel.IRI).subscribe((response: LocationModel[]) => {
        this.items = response['hydra:member'];
      });
    }
  }

  async subscriptionCancel(code): Promise<void> {
    await this.http.post(`${LocationModel.IRI}/subscription-cancel?code=${code}`, this.card).then(response => {
      if (response.status === 'success') {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'Abonelik İptal Edildi'));
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } else {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, response.errorMessage));
      }
    });
  }
  async subscriptionDetail(code): Promise<void> {
    this.loading = true;
    this.http.get(LocationModel.IRI + '/subscriptionDetail/' + code).subscribe((response) => {
      this.details = response.data;
      this.detailsDisplay = true;
      this.loading = false;
    });
  }

  convertTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

}
