import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {LocationModel} from '../model/location.model';
import {promise} from 'protractor';
import {any} from 'codelyzer/util/function';


@Injectable({
  providedIn: 'root'
})
export class ProduceCompanyService {
  company: any[];

  constructor(private http: HttpService) {
  }

  getProduceLocation(): any {
    this.company = JSON.parse(localStorage.getItem('company'));
    // @ts-ignore
    localStorage.setItem('manufacturingLocation', this.company ? this.company.manufacturingLocation : 0);
    // @ts-ignore
    return this.company?.manufacturingLocation;
  }

  getProduceContact(): any {
    this.company = JSON.parse(localStorage.getItem('company'));

    const contact = [];
    // @ts-ignore
    if (this.company) {
      // @ts-ignore
      this.http.get(LocationModel.IRI, {id: this.company.manufacturingLocation}).toPromise().then(response => {
        response['hydra:member'].map((item, i) => {
          if (item.contact) {
            contact[i] = item.contact.id;
            localStorage.setItem('manufacturingLocationContact', item.contact.id);
          }
        });
      });
      return contact;
    }

  }

}
