<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'ROLES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'ROLES'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'} "label="{{'NEW' | translate}}" icon="pi pi-plus" (click)="openForm()"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [loading]="loading" [lazy]="true" [value]="roles">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'NAME' | translate}}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>{{ row.name | translate}}</td>
            <td class="p-text-right">
          <span class="p-buttonset">
            <button pButton type="button" icon="pi pi-pencil" class="p-button-sm" (click)="edit(row)"></button>
            <button pButton type="button" class="p-button-danger p-button-sm" icon="pi pi-trash" (click)="delete(row)"></button>
          </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-sidebar [(visible)]="showForm" position="right"  [modal]="true" styleClass="p-sidebar-lg">
  <div class="p-fluid">
    <h2 *ngIf="role.id === undefined">{{ 'ROLE_CREATE' | translate}} {{ role.id}}</h2>
    <h2 *ngIf="role.id !== null">{{role.name}}</h2>
    <div class="p-field">
      <label for="firstName">{{'NAME' | translate}}</label>
      <input id="firstName" type="text" pInputText [(ngModel)]="role.name" name="firstName" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>

    <div class="p-field-12">
      <h3>{{ "PERMISSIONS" | translate }}</h3>
      <div *ngFor="let permission of permissions" class="p-m-2">
        <div class="p-field-4">
          <p-checkbox name="groupname" (onChange)="onCheckedMenu(permission.name, $event)" [value]="permission['@id']" [label]="permission.name|translate"
                      [(ngModel)]="role.permissions"
          ></p-checkbox>
        </div>
      </div>
      <small class="p-error">{{ errors['permissions'] }}</small>
    </div>
  </div>
  <div class="row" *ngIf="role.actions">
    <div class="col-md-6">
      <div class="col-md-12 add-div">
        <h3><b>{{ 'ORDERS'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].orders">
          <div class="col-lg-10">
            <div [style]="{width:'50px',float: 'inherit',textAlign:'right'}" *ngIf="item.inPageAction === true">-</div>
            {{item.title}}
          </div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value"  class="ORDERS"></p-inputSwitch></div>
        </div>

      </div>
      <div class="col-md-12 add-div">
        <h3><b>{{ 'INVENTORY'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].inventory">
          <div class="col-lg-10"><div [style]="{width:'50px',float: 'inherit',textAlign:'right'}" *ngIf="item.inPageAction === true">-</div>{{item.title}}</div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value" class="INVENTORY"></p-inputSwitch></div>
        </div>

      </div>
      <div class="col-md-12 add-div">
        <h3><b>{{ 'SUPPLIERS'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].suppliers;">
          <div class="col-lg-10"><div [style]="{width:'50px',float: 'inherit',textAlign:'right'}" *ngIf="item.inPageAction === true">-</div>{{item.title}}</div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value"  class="SUPPLIERS"></p-inputSwitch></div>
        </div>


      </div>

    </div>
    <div class="col-md-6">
      <div class="col-md-12 add-div">
        <h3><b>{{ 'PRODUCE'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].production">
          <div class="col-lg-10"><div [style]="{width:'50px',float: 'inherit',textAlign:'right'}" *ngIf="item.inPageAction === true">-</div>{{item.title}}</div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value"  class="PRODUCE"></p-inputSwitch></div>
        </div>


      </div>
      <div class="col-md-12 add-div">
        <h3><b>{{ 'ACCOUNTING'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].accounting">
          <div class="col-lg-10"><div [style]="{width:'50px',float: 'inherit',textAlign:'right'}" *ngIf="item.inPageAction === true">-</div>{{item.title}}</div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value" class="ACCOUNTING"></p-inputSwitch></div>
        </div>
      </div>
      <div class="col-md-12 add-div">
        <h3><b>{{ 'REPORTS'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].reports;">
          <div class="col-lg-10"><div [style]="{width:'50px',float: 'inherit',textAlign:'right'}">-</div>{{item.title}}</div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value" class="REPORTS"></p-inputSwitch></div>
        </div>

      </div>
      <div class="col-md-12 add-div">
        <h3><b>{{ 'SETTINGS'| translate}}</b></h3>
        <div *ngFor="let item of newRoles[0].settings">
          <div class="col-lg-10"><div [style]="{width:'50px',float: 'inherit',textAlign:'right'}" *ngIf="item.inPageAction === true">-</div>{{item.title}}</div>
          <div class="col-lg-2"><p-inputSwitch name="sa1" [(ngModel)]="item.value" class="SETTING"></p-inputSwitch></div>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-field-12">
      <button pButton label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2" (click)="hideForm()" ></button>
      <button pButton label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
    </div>
  </ng-template>

</p-sidebar>


