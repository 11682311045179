import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService} from 'primeng/api';
import {ResourceModel} from '../../../model/resource.model';
import { TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {
  items: ResourceModel[] = [];
  item: ResourceModel = new ResourceModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean
  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private confirmationService: ConfirmationService, private translate: TranslateService,
              private validationService: ValidationService, private flashMessageService: FlashMessageService) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.loading = true;
    this.http.get(ResourceModel.IRI).subscribe((response: ResourceModel[]) => {
      this.items = response['hydra:member'];
      this.loading = false;
    });

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  openForm(): void {
    this.showForm = true;
  }

  edit(row): void {
    this.item = {...row};
    this.showForm = true;
  }

  changeStatus(row): void {
    this.http.put(`${ResourceModel.IRI}/${row.id}`, {isActive: row.isActive}).then((response) => {
      row.isActive = response.isActive;
    });
  }

  hideForm(): void {
    this.errors = [];
    this.showForm = false;
    this.item = new ResourceModel();
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;

    if (this.item.id) {
      await this.http.put(`${ResourceModel.IRI}/${this.item.id}`, this.item).then((response: ResourceModel) => {
        if (response) {
          this.load();
          this.hideForm();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    } else {
      await this.http.post(ResourceModel.IRI, this.item).then((response: ResourceModel) => {
        if (response) {
          this.items.push(response);
          this.hideForm();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }

    this.submitted = false;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }
}
