import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UnitModel} from '../model/unit.model';
import {HttpService} from './http.service';
import {ExpensModel} from '../model/expens.model';

@Injectable({
  providedIn: 'root'
})
export class ExpensService {



  expenses = new BehaviorSubject<ExpensModel[]>(null);

  constructor(private http: HttpService) { }

  getExpenses(): Observable<ExpensModel[]> {
    return this.expenses.asObservable();
  }

  getExpensAction(params = {}): void {
    this.http.get(ExpensModel.IRI, params).subscribe(response => {
      this.expenses.next(response['hydra:member']);
    });
  }

  async save(unit: ExpensModel): Promise<any> {
    return await this.http.post(ExpensModel.IRI, unit).then(response => {
      if (response !== undefined) {
        const units = this.expenses.getValue();
        this.expenses.next(units);
      }

      return response;
    });
  }

  async update(expens: ExpensModel): Promise<any> {
    return await this.http.put(`${ExpensModel.IRI}/${expens.id}`, expens).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.expenses.next(null);
    this.expenses.unsubscribe();
  }
}
