import {ApiResourceModel} from './api-resource.model';
import {CountryModel} from './country.model';


export class AddressModel extends ApiResourceModel {
  public static IRI = '/api/addresses';
  name: string;
  street: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  country: CountryModel|string;
  body: string;
}
