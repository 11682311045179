<div class="div-sums p-flex-row mb-3">
  <div class="p-col inside-div-no-background {{ pageName == 'daily-production' ? 'active-div' :  ''}}"
       [routerLink]="'/work-order/work-order-reports/daily-production-report'" [style]="{cursor:'pointer'}">
    {{'DAILY_PRODUCTION_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'product-production' ? 'active-div' :  ''}}"
       [routerLink]="'/work-order/work-order-reports/product-production-report'" [style]="{cursor:'pointer'}">
    {{'PRODUCT_PRODUCTION_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'order-report' ? 'active-div' :  ''}}"
       [routerLink]="'/work-order/work-order-reports/order-report'" [style]="{cursor:'pointer'}">
    {{'ORDER_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'department-production-report' ? 'active-div' :  ''}}"
       [routerLink]="'/work-order/work-order-reports/department-production-report'" [style]="{cursor:'pointer'}">
    {{'DEPARTMENT_PRODUCTION_REPORT' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'unpaid-report' ? 'active-div' :  ''}}"
       [routerLink]=""   [style]="{cursor:'pointer'}">
    {{'INCOME_EXPENSE_ANALYSIS' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ pageName == 'unpaid-production-report' ? 'active-div' :  ''}}"
       [routerLink]="'/work-order/work-order-reports/unpaid-production-report'" [style]="{cursor:'pointer'}">
    {{'RETURN_REPORT' | translate}}
  </div>
</div>
