import { Component, OnInit } from '@angular/core';
import {CategoryModel} from '../../../model/category.model';
import {LocationModel} from '../../../model/location.model';
import {WasteEvents} from '../../../model/waste-events.model';
import {Subscription} from 'rxjs';
import {HttpService} from '../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api';
import {WasteEventItems} from '../../../model/waste-event-items.model';
import {CategoryService} from '../../../service/category.service';

@Component({
  selector: 'app-waste-report',
  templateUrl: './waste-report.component.html',
  styleUrls: ['./waste-report.component.scss']
})
export class WasteReportComponent implements OnInit {
  loading: boolean;
  categories: string [];
  locations: LocationModel[] = [];
  wasteEvents: WasteEvents[] = [];
  parameters = {
    id: '',
    type: 'material',
    'variants.code': '',
    name: '',
    'category.name': '',
    'category.id': null,
    page: 0,
    itemsPerPage: 20,
  };
  total: number;
  moreLoading;
  display: boolean;
  searchLocations: LocationModel[] = [];
  errors = [];
  validationSubscriber: Subscription;
  id: any;
  basicData: any;
  basicOptions: any;
  wasteTotal: number;


  constructor(private http: HttpService, private translate: TranslateService, private categoryService: CategoryService) { }

  ngOnInit(): void {
    const categoriesData = [];
    this.categoryService.getCategoriesAction();
    this.categoryService.getCategories().subscribe(response => {
      if (response !== null) {
      }
      if (response) {
        response.map(item => {
          categoriesData.push(item.name);
        });
      }
    });
    setTimeout(() => {
      this.basicData = {
        labels: categoriesData,
        datasets: [
          {
            label: ['dddd'],
            backgroundColor: '#42A5F5',
            data: [65]
          }
        ]
      };
    }, 100);
    this.load();
  }
  async load(event: LazyLoadEvent = null): Promise<void>{
    this.loading = true;
    this.http.get(WasteEventItems.IRI).subscribe(response => {
      this.wasteEvents = response['hydra:member'];
      this.loading = false;
      let total = 0;
      this.wasteEvents.map(item => {
        total += item.value;
      });
      this.wasteTotal = total;
    });
  }
}
