import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {HttpService} from '../../../service/http.service';
import {StockVariantModel} from '../../../model/stock-variant.model';

@Component({
  selector: 'app-product-recipe-show',
  templateUrl: './product-recipe-show.component.html',
  styleUrls: ['./product-recipe-show.component.scss']
})
export class ProductRecipeShowComponent implements OnInit {

  items = [];
  loading: boolean;
  recipeItemsNotAvailable: boolean;

  constructor(private config: DynamicDialogConfig, private http: HttpService) { }

  ngOnInit(): void {
    this.loading = true;
    this.recipeItemsNotAvailable = false;

    this.http.get(`${StockVariantModel.IRI}/${this.config.data.id}/recipes`).toPromise().then((response) => {
      this.items = response['hydra:member'];
      if (this.items.length < 1) {
        this.recipeItemsNotAvailable = true;
      }
      this.loading = false;
    });
  }

}
