<div class="p-fluid">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-8">
      <label for="supplier">{{'STOCK' | translate}}</label>
      <p-dropdown [options]="variants" filter="true"  [required]="false" [(ngModel)]="mediaObject.variant" optionLabel="name" id="supplier"  optionValue="@id"></p-dropdown>
    </div>
    <div class="p-field p-col-8">
      <label for="description">{{'DESCRIPTION' | translate}}</label>
      <input pInputText [(ngModel)]="mediaObject.description" name="description" id="description" />
    </div>
  </div>
  <p-fileUpload name="myfile[]" #fileUpload customUpload="true" multiple="multiple" (uploadHandler)="upload($event, fileUpload)"></p-fileUpload>
  <p-table [value]="items" *ngIf="!loading">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'STOCK' | translate}}</th>
        <th>{{'DESCRIPTION' | translate}}</th>
        <th>{{'DOWNLOAD' | translate}}</th>
        <th>{{'SHOW_IN_WORK_ORDER' | translate}}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-i="rowIndex" let-row>
      <tr>
        <td>{{ row.variant?.name }}</td>
        <td>{{ row.description }}</td>
        <td><a [href]="apiUrl + '/' + row.mediaObject.contentUrl">{{'DOWNLOAD' | translate}}</a></td>
        <td><p-checkbox [(ngModel)]="row.showInWorkOrder" [binary]="true" (onChange)="showInWorkOrder($event, row['@id'])"></p-checkbox></td>
        <td><p-button [icon]="deleting === i ? 'pi pi-spin pi-spinner': 'pi pi-trash'" label="" styleClass="p-button-text" (onClick)="delete(row.id, i)"></p-button> </td>
      </tr>
    </ng-template>
  </p-table>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
</div>

