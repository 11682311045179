import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {StockModel} from '../model/stock.model';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  stocks = new BehaviorSubject<StockModel>(null);

  stocksData = this.stocks.asObservable();

  constructor( private http: HttpService) { }

  getStocks(): Observable<StockModel> {
    return this.stocks.asObservable();
  }
  getStocksAction(params = {}): void {
    this.http.get(StockModel.IRI, params).subscribe((response) => {
      this.stocks.next(response);
    });
  }
  async save(stock: StockModel): Promise<void> {
   return await this.http.post(StockModel.IRI, stock).subscribe((response) => {
     if (response !== undefined) {
       const stocks = this.stocks.getValue();
       if (stocks && stocks['hydra:member']) {
         stocks['hydra:member'].push(response);
         this.stocks.next(stocks);
       }
     }
     return response;
   });
  }

  async update(stock: StockModel): Promise<void> {
    return  await this.http.put(`${StockModel.IRI}/${stock.id}`, stock).then((response) => {
      return response;
    });
  }

  updateStocks(message: StockModel): void {
    this.stocks.next(message);
  }
}
