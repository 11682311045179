import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../service/http.service";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {StockTransactionModel} from "../../../model/stock-transaction.model";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-stock-transactions',
  templateUrl: './stock-transactions.component.html',
  styleUrls: ['./stock-transactions.component.scss']
})
export class StockTransactionsComponent implements OnInit {

  transactions: StockTransactionModel[] = [];
  parameters: {};

  constructor(private http: HttpService, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.parameters = this.config.data;
    this.loadTransactions()
  }

  loadTransactions() {
    this.http.get(StockTransactionModel.IRI, this.parameters).subscribe((response: StockTransactionModel[]) => {
      this.transactions = response['hydra:member'];
    })
  }

  changeStatus(status: string) {
    this.parameters = {...this.config.data, ...{status: status}};
    this.loadTransactions();
  }
}
