import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recive-order-page-info',
  templateUrl: './recive-order-page-info.component.html',
  styleUrls: ['./recive-order-page-info.component.scss']
})
export class ReciveOrderPageInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
