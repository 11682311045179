import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../../../../service/http.service';
import {UnitModel} from '../../../../model/unit.model';
import {UnitService} from '../../../../service/unit.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {CreateUnitComponent} from '../../../settings/unit/create-unit/create-unit.component';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.scss'],
  providers: [DialogService]
})
export class AddUnitComponent implements OnInit, OnDestroy {
  loading: boolean;
  submitted: boolean;
  unit: UnitModel = new UnitModel();
  units: UnitModel [] = [];
  errors: any[] = [];
  validationSubscriber: Subscription;

  constructor(private router: Router, private http: HttpService, private translate: TranslateService,
              private unitService: UnitService, private flashMessageService: FlashMessageService,
              private validationService: ValidationService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.load();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  load(): void {
    this.unitService.getUnitsAction();

    this.loading = true;

    this.unitService.getUnits().subscribe(response => {
      if (response !== null) {
        this.loading = false;
      }
      if (response) {
        this.units = response;
      }
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    this.unitService.save(this.unit).then(response => {
    });
    this.submitted = false;
  }

  nextPage(): void {
    this.router.navigate(['initial-setup/add-tax']).then(r => {
    });
  }

  previousPage(): void {
    this.router.navigate(['initial-setup/add-location']).then(r => {
    });
  }

  openForm(item: UnitModel): void {
    this.dialogService.open(CreateUnitComponent, {
      width: '50vw',
      header: this.translate.instant('UNIT_CREATE'),
      data: {item}
    });
  }

  close(): void {

  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();

    this.unitService.units.next([]);
  }

}
