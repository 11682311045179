import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LocationModel} from '../../../model/location.model';
import {HttpService} from '../../../service/http.service';
import {CategoryService} from '../../../service/category.service';
import {CategoryModel} from '../../../model/category.model';
import {StockModel} from '../../../model/stock.model';
import {LazyLoadEvent} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {WasteEvents} from '../../../model/waste-events.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ValidationService} from '../../../service/validation.service';
import {Subscription} from 'rxjs';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {WasteEventItems} from '../../../model/waste-event-items.model';
import {InventoryModel} from '../../../model/inventory.model';
import {StockTransferItemModel} from '../../../model/stock-transfer-item.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {StockQuantityModel} from '../../../model/stock-quantity.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {UtilityService} from '../../../service/utility.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-waste-events',
  templateUrl: './waste-events.component.html',
  styleUrls: ['./waste-events.component.scss']
})
export class WasteEventsComponent implements OnInit {
  loading: boolean;
  categories: CategoryModel[] = [];
  locations: LocationModel[] = [];
  wasteEvents: WasteEvents[] = [];
  wasteEvent: WasteEvents = new WasteEvents();
  stocks: StockModel[] = [];
  stockTypes: [];
  descriptionTypes: [];
  parameters = {
    name: '',
    type: '',
    location: '',
    page: 1,
    itemsPerPage: 1000,
    isActive: 1,
    startDate: '',
    lastDate: '',
    category: '',
    date: '',
    description: ''
  };
  dateRange: Date[] = [];
  total: number;
  moreLoading;
  display: boolean;
  today = new Date();
  searchLocations: LocationModel[] = [];
  validationSubscriber: Subscription;
  values = [];
  id: any;
  draftDisplay: boolean;
  minDate: Date;
  activeInventory: InventoryModel;
  isCanBeSend = true;
  isCanBeSelectDate: Date | string;
  submitted: boolean;
  maxDate: Date;
  isAddStockAvailable: boolean;
  autoCompleteValues: StockVariantModel[] = [];
  variants: StockVariantModel[] = [];
  findStockValues = [];
  findStockAlert: boolean;
  tableLoading: boolean;
  recipeDisplay: boolean;
  willAddRecipeItems: any [];
  addRecipeValue = 1;
  isCategory = false;
  wasteEventsByCategory = [];
  totalValue: number;
  private willAddRecipeError: boolean;
  currentDay = this.today.getDay();
  firstDayOfWeek = new Date(this.today);
  lastDayOfWeek = new Date(this.today);
  errors = {
    location: '',
    description: '',
    category: '',
    type: ''
  };
  isCanSave: boolean;

  constructor(private http: HttpService, private categoryService: CategoryService, private translate: TranslateService,
              private flashMessageService: FlashMessageService, private validationService: ValidationService,
              private utilityService: UtilityService, private renderer: Renderer2) {

    this.firstDayOfWeek.setDate(this.today.getDate() - this.currentDay + (this.currentDay === 0 ? -6 : 1));
    this.lastDayOfWeek.setDate(this.firstDayOfWeek.getDate() + 6);
  }

  ngOnInit(): void {
    this.draftDisplay = true;
    const currentDate = new Date();
    const day = new Date(currentDate);
    this.dateRange = [new Date(this.getFormattedDate(this.firstDayOfWeek)), new Date(this.getFormattedDate( this.lastDayOfWeek))];
    const dateRange = this.getDateRange(new Date(this.firstDayOfWeek), new Date(this.lastDayOfWeek));
    const selectedDate = dateRange.map(date => this.getFormattedDate(date));
    this.parameters.date = selectedDate.join(',');

    this.load().then();
    this.onLoadSearch().then();
    this.getActiveInventory().then();
    this.wasteEvent.date = new Date();
    this.http.get(`${CategoryModel.IRI}/category-type/stock?mainCategory=yes`).subscribe(category => {
      this.categories = category['hydra:member'];
      this.categories.unshift({
        categoryMatches: [],
        accountingCode: '',
        categoryType: '',
        children: undefined,
        code: '',
        createdAt: '',
        department: '',
        isActive: false,
        location: undefined,
        lvl: 0,
        message: '',
        parent: '',
        parentId: '',
        tax: [],
        updatedAt: '',
        name: this.translate.instant('ALL_CATEGORIES'), id: null
      });
    });
    // @ts-ignore
    this.stockTypes = [
      {name: this.translate.instant('material'), value: 'material'},
      {name: this.translate.instant('semi_product'), value: 'semi_product'},
      {name: this.translate.instant('product'), value: 'product'}
    ];
    // @ts-ignore
    this.descriptionTypes = [
      {name: this.translate.instant('DROPPED_BROKEN'), value: 'DROPPED_BROKEN'},
      {name: this.translate.instant('EXPIRED_SPOILED'), value: 'EXPIRED_SPOILED'},
      {name: this.translate.instant('STAFF_MEALS_DRINK'), value: 'STAFF_MEALS_DRINK'},
      {name: this.translate.instant('WRONG_ORDER'), value: 'WRONG_ORDER'},
      {name: this.translate.instant('INVENTORY_STOCK_CONSUME'), value: 'INVENTORY_STOCK_CONSUME'},
      {name: this.translate.instant('OTHER'), value: 'OTHER'},
    ];
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
  }

  saveControl(): void {
    this.errors = {
      location: '',
      description: '',
      category: '',
      type: ''
    };
    this.isCanSave = true;
    if (this.wasteEvent.location === undefined  || this.wasteEvent.location === null){
      this.isCanSave = false;
      this.errors.location = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.wasteEvent.description === undefined  || this.wasteEvent.description === null){
      this.isCanSave = false;
      this.errors.description = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
  }

  async save(): Promise<void> {
    this.saveControl();
    delay(100);
    this.rowControl();
    if (this.isCanSave) {
      this.submitted = true;
      if (this.id) {
        this.wasteEvent.WasteEventItem.map((item, i) => {
          this.wasteEvent.WasteEventItem[i].stock = item.stock['@id'];
          this.wasteEvent.WasteEventItem[i].variant = item.variant['@id'];
        });
        this.http.put(`${WasteEvents.IRI}/${this.id}`, this.wasteEvent).then((response: WasteEvents) => {
          if (response) {
            setTimeout(() => {
              this.display = false;
            }, 2000);
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
            this.wasteEvent = new WasteEvents();
            this.wasteEvent.date = new Date();
            this.values = [];
            this.wasteEvent.location = null;
            this.load();
          }
          this.loading = false;
          this.submitted = false;
        });
      } else {
        this.wasteEvent.WasteEventItem.map((item, i) => {
          this.wasteEvent.WasteEventItem[i].stock = item.stock;
        });
        this.http.post(WasteEvents.IRI, this.wasteEvent).then(response => {
          this.submitted = false;
          this.loading = false;
          this.load();
          if (response) {
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
            this.display = false;
            /*
            this.wasteEvent = new WasteEvents();
            this.wasteEvent.date = new Date();
            this.values = [];
            this.wasteEvent.location = null;
            // this.load();
             */

          }
        });
      }
      // this.onHideDialog();
    }

  }

  async load(event: LazyLoadEvent = null): Promise<void> {
    this.loading = true;
    if (this.parameters.category) {
      this.isCategory = true;
      if (event) {
        this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
      }

      if (event && event.rows) {
        this.parameters.itemsPerPage = event.rows;
      }
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
      if (this.parameters.page === 0) {
        this.parameters.page = 1;
      }
      this.wasteEventsByCategory = [];
      this.totalValue = 0;
      this.http.get(WasteEvents.IRI, this.parameters).subscribe(response => {
        this.wasteEvents = response['hydra:member'];

        this.wasteEvents.forEach(item => {
          item.WasteEventItem.forEach(wasteEv => {
            // @ts-ignore
            if (wasteEv.stock.category.parent.id === this.parameters.category) {

              this.wasteEventsByCategory.push({
                date: item.date,
                series: item.series,
                number: item.number,
                stock: wasteEv.stock,
                location: item.location,
                description: item.description,
                createdBy: item.createdBy,
                value: wasteEv.value
              });
              this.totalValue += wasteEv.value;
            }

          });
        });

        this.total = this.wasteEventsByCategory.length;
        this.loading = false;
        console.log(this.wasteEventsByCategory);
      });

    } else {
      this.isCategory = false;
      if (event) {
        this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
      }

      if (event && event.rows) {
        this.parameters.itemsPerPage = event.rows;
      }
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
      if (this.parameters.page === 0) {
        this.parameters.page = 1;
      }
      this.totalValue = 0;
      this.http.get(WasteEvents.IRI, this.parameters).subscribe(response => {
        this.wasteEvents = response['hydra:member'];
        this.total = response['hydra:totalItems'];
        this.wasteEvents.forEach(item => {
          this.totalValue += item.total;
        });
        this.loading = false;
      });
    }

  }

  async onLoadStock(event: LazyLoadEvent = null): Promise<void> {
    this.parameters.page = this.parameters.page + 1;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.loading = true;
    // this.parameters.isActive = 1;
    // @ts-ignore
    await this.http.get(StockModel.IRI, this.parameters).subscribe((response) => {
      if (response['hydra:totalItems'] === 0) {
        this.loading = false;
        this.stocks = [];
      } else {
        this.loading = false;
        // this.stocks = response['hydra:member'];
        response['hydra:member'].map(res => {
          this.stocks.push(res);
        });
        this.total = response['hydra:totalItems'];
        if (this.total >= this.parameters.page * this.parameters.itemsPerPage) {
          this.moreLoading = true;
        } else {
          this.moreLoading = false;
        }
      }
    });
  }

  addItem = () => {
    this.saveControl();
    delay(100);
    if (this.isCanSave) {
      const item = new WasteEventItems();
      item.total = 0;
      item.value = 0;
      this.wasteEvent.WasteEventItem.push(item);
      setTimeout(() => {
        this.focusAutoCompleteById(1); // id=1 olan inputa odaklan
      }, 10);
      this.rowControl();
    }
  }

  focusAutoCompleteById(id: number): void {
    const autoCompleteElement = document.getElementById('autocomplete-' + id);
    if (autoCompleteElement) {
      autoCompleteElement.focus();
    }
  }

  rowControl(): void {
    this.isAddStockAvailable = false;
    this.wasteEvent.WasteEventItem.map(item => {
      if (item.quantity === null || item.quantity === 0) {
        this.isAddStockAvailable = true;
      }
      if (item.quantity > item.maxWaste ) {
        this.isAddStockAvailable = true;
      }
      if (item.maxWaste < 0 || item.maxWaste === 0) {
        this.isAddStockAvailable = true;
      }
      if (!item.stock) {
        this.isAddStockAvailable = true;
      }
    });
    let total = 0;
    this.wasteEvent.WasteEventItem.map(item => {
      total = total + item.value;
    });
    this.wasteEvent.total = total;
  }

  removeItem = (id, eventTotal, i: number) => {
    this.wasteEvent.WasteEventItem.splice(i, 1);
    this.autoCompleteValues.splice(i, 1);
    this.wasteEvent.total = this.wasteEvent.total - eventTotal;
    if (id !== undefined) {
      this.http.delete(`${WasteEventItems.IRI}/${id}`).then((response: WasteEventItems) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    }
    this.rowControl();
  }

  searchStock = (event, i) => {
    this.http.get(StockModel.IRI, {
      isActive: 1,
      type: 'material',
      name: event.query
    }).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  }
  searchRecipe = (event, i) => {
    this.http.get(StockModel.IRI, {
      isActive: 1,
      type: 'semi_product',
      name: event.query
    }).subscribe((response: StockModel) => {
      this.variants = response['hydra:member'];
    });
  }

  async onVariantSelect(event: StockModel, i: number): Promise<void> {
    const findStock = this.wasteEvent.WasteEventItem.findIndex(obj => obj.stock === event['@id']);
    if (findStock !== -1) {
      this.findStockValues.push(findStock);
      this.findStockAlert = true;
    } else {
      this.wasteEvent.WasteEventItem[i].variant = event.variants[0]['@id'];
      this.wasteEvent.WasteEventItem[i].stock = event['@id'];
      this.wasteEvent.WasteEventItem[i].quantity = 0;
      this.wasteEvent.WasteEventItem[i].total = 0;
      this.wasteEvent.WasteEventItem[i].type = event.type;
      // @ts-ignore
      this.wasteEvent.WasteEventItem[i].symbol = event.currency?.symbol;
      // @ts-ignore
      this.wasteEvent.symbol = event.currency?.symbol;
      // @ts-ignore
      this.wasteEvent.WasteEventItem[i].unit = event.unit.name;

      // @ts-ignore
      this.autoCompleteValues.push(this.wasteEvent.WasteEventItem[i].variant);
      this.tableLoading = true;
      // @ts-ignore
      await this.http.get(StockQuantityModel.IRI, {
        location: this.wasteEvent.location,
        stock: event['@id'],
        stockVariant: event.variants[0]['@id']
      }).subscribe((response: StockQuantityModel) => {
        this.wasteEvent.WasteEventItem[i].price = response['hydra:member'][0].averagePrice ?
          response['hydra:member'][0].averagePrice : event.variants[0].salePrice;
        this.wasteEvent.WasteEventItem[i].maxWaste = response['hydra:member'][0].amount;
        this.tableLoading = false;
        setTimeout(() => {
          this.rowControl();
        }, 200);
      });
    }
  }

  async onRecipeSelect(event: StockModel, i: number): Promise<void> {
    this.willAddRecipeItems = event.variants[0].variantRecipes[0].variantRecipeItems;
    // tslint:disable-next-line:no-shadowed-variable
    this.willAddRecipeItems.map(async (item, i) => {
      await this.http.get(StockQuantityModel.IRI, {
        location: this.wasteEvent.location,
        stock: item.stock['@id'],
        stockVariant: item.stock.variants[0]['@id']
      }).subscribe((response: StockQuantityModel) => {
        // tslint:disable-next-line:max-line-length
        this.willAddRecipeItems[i].price = response['hydra:member'][0].averagePrice ? response['hydra:member'][0].averagePrice : event.variants[0].salePrice;
        this.willAddRecipeItems[i].quantity = this.addRecipeValue * this.willAddRecipeItems[i].amountWithWastage;
        this.willAddRecipeItems[i].total = this.willAddRecipeItems[i].price * this.willAddRecipeItems[i].quantity;
        this.willAddRecipeItems[i].amount = response['hydra:member'][0].amount;
        this.tableLoading = false;
      });
    });
    this.willAddRecipeControl();
  }

  willAddRecipeControl(): void {
    this.willAddRecipeError = false;
    this.willAddRecipeItems.map(async (item, i) => {
      this.willAddRecipeItems[i].quantity = this.addRecipeValue * this.willAddRecipeItems[i].amountWithWastage;
      this.willAddRecipeItems[i].total = this.willAddRecipeItems[i].price * this.willAddRecipeItems[i].quantity;
      if (this.willAddRecipeItems[i].amount === 0) {
        this.willAddRecipeError = true;
      }
    });
  }

  addWasteEventtem(): void {
    this.willAddRecipeItems.map(async (item, i) => {
      const wasteItem = {
        stock: item.stock['@id'],
        variant: item.variant['@id'],
        value: item.total,
        total: item.total,
        quantity: item.quantity,
        oldQuantity: item.quantity,
        type: 'semi_product',
        symbol: item.stock.currency?.symbol,
        unit: item.stock.unit?.name,
        price: item.price,
        maxWaste: item.amount,
      };
      // @ts-ignore
      this.wasteEvent.WasteEventItem.push(wasteItem);
      this.autoCompleteValues.push(item.variant);
      this.rowControl();
    });
  }

  onQuantityChange(row, i): void {
    if (row.quantity > row.maxWaste) {
      this.wasteEvent.WasteEventItem[i].quantity = row.maxWaste;
    }
    this.wasteEvent.WasteEventItem[i].value = row.quantity * row.price;
    let total = 0;
    this.wasteEvent.WasteEventItem.map(item => {
      total = total + item.value;
    });
    this.wasteEvent.total = total;

    this.rowControl();
  }

  async onLoadSearch(): Promise<void> {
    this.loading = true;
    await this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      if (response !== undefined) {
        this.locations = response;
        this.locations.unshift({
          accountingCode: undefined,
          city: '',
          code: '',
          companyAddress: undefined,
          companyName: undefined,
          contact: undefined,
          createdAt: '',
          currency: undefined,
          data: undefined,
          functions: [],
          inventoryCountType: '',
          isActive: false,
          isDefault: false,
          locationExpenses: undefined,
          locationSubscriptions: undefined,
          message: '',
          number: 0,
          parent: '',
          parentId: '',
          storeId: '',
          taxNumber: undefined,
          taxOffice: undefined,
          updatedAt: '',
          zipCode: '',
          name: this.translate.instant('ALL_LOCATIONS'),
          id: null
        });
      }
      this.loading = false;
    });
  }

  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }

  changeCategory(event): void {
    this.parameters.page = 0;
    this.stocks = [];
    this.onLoadStock().then();
  }

  onShowDialog(): void {
    this.wasteEvent = new WasteEvents();
    this.wasteEvent.date = this.activeInventory ? new Date(this.activeInventory.startDate) : new Date(),
      this.values = [];
    this.wasteEvent.location = null;
    const company = JSON.parse(localStorage.getItem('company'));
    this.wasteEvent.symbol = company.currency.symbol;
    this.wasteEvent.total = 0;


    this.display = true;
  }

  onHideDialog(): void {
    this.wasteEvent = new WasteEvents();
    this.values = [];
    this.wasteEvent.location = null;
    this.display = false;
    this.load();
  }


  edit(event): void {
    this.loading = true;
    this.draftDisplay = true;
    this.http.get(WasteEvents.IRI + '/' + event).subscribe(response => {
      this.wasteEvent = response;
      // @ts-ignore
      this.wasteEvent.symbol = this.wasteEvent.location?.currency.symbol;
      this.wasteEvent.date = new Date(this.wasteEvent.date);
      // @ts-ignore
      this.parameters.locations = this.wasteEvent.location['@id'];
      this.wasteEvent.location = this.wasteEvent.location['@id'];
      this.id = event;
      this.wasteEvent.WasteEventItem.map((item, i) => {
        this.wasteEvent.WasteEventItem[i].stock = item.stock;
        // @ts-ignore
        this.wasteEvent.WasteEventItem[i].stockValues = item.stock;
        this.wasteEvent.WasteEventItem[i].variant = item.variant;
        this.wasteEvent.WasteEventItem[i].price = item.value / item.quantity;
        // @ts-ignore
        this.autoCompleteValues.push(item.variant);
        // @ts-ignore
        this.wasteEvent.WasteEventItem[i].symbol = item.stock.currency?.symbol;
      });
      this.loading = false;
      this.display = true;
    });
  }


  onChangeLocation(event): void {
    console.log(this.autoCompleteValues);
    this.stocks = [];
    const choseId = event.value.split('/');
    this.parameters.location = event.value;
    this.wasteEvent.location = event.value;
    // this.parameters.locations = choseId[3];
    this.parameters.page = 0;
    // this.onLoadStock().then();
    if( this.wasteEvent.WasteEventItem.length > 0 ){
      this.wasteEvent.WasteEventItem.map((wasteItem, i) => {

        this.http.get(StockQuantityModel.IRI, {
          location: choseId[3],
          stock: this.autoCompleteValues[i].id,
          // @ts-ignore
          stockVariant: this.autoCompleteValues[i].variants[0].id
        }).subscribe((response: StockQuantityModel) => {
          this.wasteEvent.WasteEventItem[i].price = response['hydra:member'][0].averagePrice ?
            response['hydra:member'][0].averagePrice : event.variants[0].salePrice;
          this.wasteEvent.WasteEventItem[i].maxWaste = response['hydra:member'][0].amount;
          this.tableLoading = false;
          setTimeout(() => {
            this.rowControl();
          }, 200);
        });
      })
    }
  }

  async getActiveInventory(): Promise<any> {
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      this.activeInventory = response;
      this.minDate = new Date(this.activeInventory.startDate);
      this.maxDate = new Date(this.activeInventory.endDate);
    });
  }

  wasteEventDateControl(event): void {
    if (this.activeInventory.startDate) {
      const selectedDate = new Date(event);
      const InventoryStartDate = new Date(this.activeInventory.startDate);
      if (selectedDate.getTime() < InventoryStartDate.getTime()) {
        this.wasteEvent.date = this.today;
        this.isCanBeSend = false;
        this.isCanBeSelectDate = this.activeInventory.startDate;
      } else {
        this.minDate = InventoryStartDate;
        this.isCanBeSend = true;
      }
    } else {
      this.minDate = this.today;
    }
  }

  addRecipeDisplay(): void {
    this.recipeDisplay = true;
  }

  onSelectCategory(event): void {
    console.log(event.value);
    this.parameters.category = event.value ? event.value : '';
  }

  onSelectWaste(event): void {
    this.parameters.description = event.value ? event.value : '';
  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    this.parameters.startDate = this.getFormattedDate(start);
    this.parameters.lastDate = this.getFormattedDate(end);
    this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
    const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));

    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    this.parameters.date = selectedDate.join(',');
    this.parameters = {...this.parameters};
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }

  export(): void {
      if (this.isCategory) {
          const parameters = {...this.parameters};
          this.http.download('/api/data/exports/wasteEventsItem', parameters)
              .subscribe(r => {
                  this.utilityService.downloadFile(r, 'waste_event_item.xlsx');
              });
      }else{
          const parameters = {...this.parameters};
          this.http.download('/api/data/exports/wasteEvents', parameters)
              .subscribe(r => {
                  this.utilityService.downloadFile(r, 'waste_event.xlsx');
              });
      }
  }
  generatePdf(): void {
    if (this.isCategory){
      const content = document.getElementById('pdf-content-item') as HTMLElement; // PDF içeriği
      html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);

            pdf.save(`waste_event_item.pdf`); // PDF'i indir
        });
    }else{
      const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
      html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);

            pdf.save(`waste_event.pdf`); // PDF'i indir
        });
    }
  }
}
