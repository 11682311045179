import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {CategoryModel} from './category.model';

export class StockAccountingCodeModel extends ApiResourceModel{
  public static IRI = '/api/stock_accounting_codes';
  public location: LocationModel;
  public category: CategoryModel;
  public code: string;
}
