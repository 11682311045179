import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {StockTransferItemModel} from './stock-transfer-item.model';

export class StockTransfersModel extends ApiResourceModel{
  public static IRI = '/api/stock_transfers';
  public code: string;
  public transferDate: Date| string;
  public origin: LocationModel| string;
  public destination: LocationModel| string;
  public amount: number;
  public items: StockTransferItemModel[] = [];
  public location: LocationModel|string;
  public series: string;
  public number: number;
  public status: string;
}

export enum TransferStatus {

  Request= 'TRANSFER_REQUEST',
  Preparing= 'PREPARING',
  Rejected= 'REJECTED',
  Sent= 'SENT'

}
