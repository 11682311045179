import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntegrationModel} from '../../../../model/integration.model';
import {CompanyIntegrationModel} from '../../../../model/company-integration.model';
import {HttpService} from '../../../../service/http.service';
import {LingaComponent} from '../../../settings/integrations/linga/linga.component';
import {DialogService} from 'primeng/dynamicdialog';
import {LucaComponent} from '../../../settings/integrations/luca/luca.component';
import {ParasutComponent} from '../../../settings/integrations/parasut/parasut.component';
import {LogoComponent} from '../../../settings/integrations/logo/logo.component';
import {EfaturaComponent} from '../../../settings/integrations/efatura/efatura.component';
import {environment} from '../../../../../environments/environment';
import {KolaybiComponent} from '../../../settings/integrations/kolaybi/kolaybi.component';

@Component({
  selector: 'app-select-pos',
  templateUrl: './select-pos.component.html',
  styleUrls: ['./select-pos.component.scss']
})
export class SelectPosComponent implements OnInit {
  loading: boolean;
  items: MenuItem[];
  integrations: IntegrationModel[] = [];
  activeIntegrations = [];
  constructor(private translate: TranslateService, private router: Router,
              private http: HttpService, private dialogService: DialogService
              ) { }

  ngOnInit(): void {
    this.items = [

      {label: this.translate.instant('POS_INTEGRATION_STEP'), routerLink: 'POS_INTEGRATION'},
      {label: this.translate.instant('ACCOUNT_INTEGRATION_STEP'), routerLink: 'ACCOUNT_INTEGRATION'},
      {label: this.translate.instant('E_INVOICE_INTEGRATION_STEP'), routerLink: 'E_INVOICE_INTEGRATION_STEP'},
      {label: this.translate.instant('STOCK_IMPORT_STEP'), routerLink: 'STOCK_IMPORT'},
    ];
    this.load();
  }
  nextPage(): void {

    this.router.navigate(['initial-setup/select-account']).then(r => {
    });

  }

  previousPage(): void {

    this.router.navigate(['initial-setup']).then(r => {
    });

  }

  load(): void {
    this.loading = true;
    this.http.get(IntegrationModel.IRI).subscribe((response: IntegrationModel[]) => {
      this.integrations = response['hydra:member'];
      this.loading = false;
    });

    this.loadIntegrations();
  }

  loadIntegrations(): void {
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.activeIntegrations = [];
      response['hydra:member'].map((item: CompanyIntegrationModel) => {
        if (item.isActive) {
          // @ts-ignore
          this.activeIntegrations[item.integration['@id']] = item.id;
        }
      });
    });
  }

  connectLinga(isConnected: boolean): void
  {
    const ref = this.dialogService.open(LingaComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Linga',
    });
  }
  async disconnect(id: number): Promise<void> {
    await this.http.put(`${CompanyIntegrationModel.IRI}/${id}`, {isActive: false}).then(response => {
      if (response) {
        this.loadIntegrations();
      }
    });
  }

}
