import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';
import {WasteEventsRecipeModel} from './waste-events-recipe.model';

export class WasteEventItems  extends ApiResourceModel{
  public static IRI = '/api/waste_event_items';
  public stock: StockModel[];
  public variant: StockVariantModel[];
  public total: number;
  public value: number;
  public quantity: number;
  public oldQuantity: number;
  public price: number;
  public unit: string;
  public type: string;
  public maxWaste: number;
}
