<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form action="" *ngIf="!loading && !isConnected">
  <div class="p-fluid">
    <div class="p-field">
      <label for="userId">{{ 'MAIL'|translate }}</label>
      <input id="userId" type="text" [name]="'userName'" pInputText [(ngModel)]="attributes.email" autofocus>
    </div>
    <div class="p-field">
      <label for="apikey">{{ 'PASSWORD'|translate }}</label>
      <input id="apikey" type="text" [name]="'password'" pInputText [(ngModel)]="attributes.password" autofocus>
    </div>
  </div>
  <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]="this.submitted" (click)="save()"></button>
</form>
<div *ngIf="!loading && isConnected">
  <img src="https://portal.eformul.net/assets/images/eformul-dark.png" width="250px" alt="">
  <p-divider></p-divider>
  <h2 class="p-d-inline-block">
    <i class="pi pi-users"style="font-size: 1.5rem; margin-right: 10px" on></i> {{ 'SYNC_LOCATION'|translate }}
  </h2>
  <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
            [disabled]="invoiceLoading" [style]="{'float': 'right' }"
            label="{{'UPDATE' | translate}}" [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
            (click)="getLocations()"></p-button>
</div>

