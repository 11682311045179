import {Component, Input, OnInit} from '@angular/core';
import {ResourceModel} from '../../../model/resource.model';
import {OperationModel} from '../../../model/operation.model';
import {StockModel} from '../../../model/stock.model';
import {ProductOperationModel} from '../../../model/product-operation.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductOperationItemModel} from '../../../model/product-operation-item.model';
import {ProductOperationItemVariantOptionModel} from '../../../model/product-operation-item-variant-option.model';
import {CurrencyService} from '../../../service/currency.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';

@Component({
  selector: 'app-product-operation',
  templateUrl: './product-operation.component.html',
  styleUrls: ['./product-operation.component.scss']
})
export class ProductOperationComponent implements OnInit {
  @Input() stockId: number;
  resources: ResourceModel[] = [];
  operations: OperationModel[] = [];
  stock: StockModel = new StockModel();
  operation: ProductOperationModel = new ProductOperationModel();
  currency: string;
  dependencies: [];

  loading: boolean;
  submitted: boolean;
  id: number;

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private currencyService: CurrencyService, private flashMessageService: FlashMessageService) { }

  async ngOnInit(): Promise<void> {
    this.loadFormOptions();
    this.loading = true;
    if (this.stockId) {
      this.id = this.stockId;
    }else{
      this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    }
    this.currency = this.currencyService.getCurrency().code;

    await this.http.get(`${StockModel.IRI}/${this.id}`).toPromise().then((response: StockModel) => {
      this.stock = response;
      this.operation.product = this.stock['@id'];
    });

    await this.http.get(`${ProductOperationModel.IRI}?product.id=${this.id}`).toPromise().then((response: ProductOperationModel[]) => {
      response['hydra:member'].map((item: ProductOperationModel) => {
        this.operation = item;

        // tslint:disable-next-line:no-shadowed-variable
        this.operation.items.map((item, i) => {

        });
      });
    });

    const options = [];

    this.operation.items.map(item => {
      item.productOperationItemVariantOptions.map(option => {
        if (options.indexOf(option.variantOption) < 0) {
          options.push(option.variantOption);
        }
      });
    });

    this.stock.stockVariantOptions.map(option => {
      if (options.indexOf(option['@id']) < 0) {
        this.operation.items.map((item, key) => {
          const operationItemVariantOption = new ProductOperationItemVariantOptionModel();
          operationItemVariantOption.variantOptionValues = [];
          operationItemVariantOption.variantOption = option['@id'];
          this.operation.items[key].productOperationItemVariantOptions.push(operationItemVariantOption);
        });
      }
    });

    this.loading = false;
  }

  loadFormOptions(): void {
    this.http.get(ResourceModel.IRI, {isActive: true}).subscribe((response: ResourceModel[]) => {
      this.resources = response['hydra:member'];
    });

    this.http.get(OperationModel.IRI, {isActive: true}).subscribe((response: OperationModel[]) => {
      this.operations = response['hydra:member'];
    });
  }

  addItem = () => {
    const item = new ProductOperationItemModel();
    if (this.resources.length > 0) {
      item.resource = this.resources[0]['@id'];
      item.cost = this.resources[0].price;
    }

    if (this.operations.length > 0) {
      item.operation = this.operations[0]['@id'];
    }

    this.stock.stockVariantOptions.map((variantOption, i) => {
      const productOperationItemVariantOption = new ProductOperationItemVariantOptionModel();
      productOperationItemVariantOption.variantOption = variantOption['@id'];
      productOperationItemVariantOption.variantOptionValues = [];

      item.productOperationItemVariantOptions.push(productOperationItemVariantOption);
    });

    this.operation.items.push(item);
  }

  removeItem = i => {
    this.operation.items.splice(i, 1);
  }

  calculateTotalCost(cost, time): number{
    let totalCost = 0;

    if (cost !== undefined && time !== undefined) {
      totalCost = cost * time;
    }

    return totalCost;
  }

  async save(): Promise<void> {
    this.operation.items.map((item, key) => {
      this.onSelectDependencies(key);
    });

    this.submitted = true;

    if (this.operation.id) {
      await this.http.put(`${ProductOperationModel.IRI}/${this.operation.id}`, this.operation).then((response: ProductOperationModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(error => {
      });
    } else {
      await this.http.post(ProductOperationModel.IRI, this.operation).then((response: ProductOperationModel) => {
        if (response) {
          this.operation.id = response.id;
          this.operation.items = response.items;

          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      }).catch(error => {
      });
    }

    this.submitted = false;
  }

  onSelectOperation(resource, key: number): void {
    this.resources.map(item => {
      if (item['@id'] === resource.value) {
        this.operation.items[key].cost = item.price;
      }
    });
  }

  onSelectDependencies(key: number): void {
    this.operation.items[key].isActive = this.operation.items[key].dependencies?.length <= 0;
  }
}
