<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'UNITS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'UNITS'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}"
              icon="pi pi-plus" (click)="openForm(null)"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <p-checkbox name="showAll" class="p-mb-3" [value]="showAll" (click)="showAll = !showAll" [label]="'SHOW_ALL'|translate">
      </p-checkbox>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [loading]="loading" [lazy]="true" [value]="items">
        <ng-template pTemplate="header">
          <tr>
            <th id="UNIT">{{'UNIT' | translate}}</th>
            <th id="null"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr *ngIf="showAll || row.isActive">
            <td><a href="javascript:void(0)" (click)="edit(row)">{{ row.name }}</a></td>
            <td class="p-text-right">
              <p-inputSwitch [(ngModel)]="row.isActive" (onChange)="changeStatus(row)" ></p-inputSwitch>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 30px">
    <div class="p-col-4">
      <label class="p-d-block">{{'SELECT_UNIT_CATEGORY' | translate}}</label>
      <p-dropdown [options]="activeItems" [showClear]="true" [(ngModel)]="company.defaultUnit" [placeholder]="'SELECT'|translate" optionLabel="name" id="unit" (onChange)="setIsDefault($event)"  optionValue="@id">
      </p-dropdown>
    </div>
  </div>
</p-card>
<p-confirmPopup></p-confirmPopup>
