import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationService} from '../../../../service/location.service';
import {ActivatedRoute} from '@angular/router';
import {CategoryModel} from '../../../../model/category.model';
import {CategoryService} from '../../../../service/category.service';
import {UtilityService} from '../../../../service/utility.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {dirname} from "@angular/compiler-cli/src/ngtsc/file_system";
import {LazyLoadEvent} from "primeng/api";
import {SortEvent} from 'primeng/api';
import {StockModel} from "../../../../model/stock.model";
import {StockVariantModel} from "../../../../model/stock-variant.model";

@Component({
  selector: 'app-menu-item-group',
  templateUrl: './menu-item-group.component.html',
  styleUrls: ['./menu-item-group.component.scss']
})
export class MenuItemGroupComponent implements OnInit {
  events: any[] = [];
  options: any;
  info: any;
  menuItem: any;
  loading: boolean;
  tableLoading: boolean;
  data: any;
  chartOptions: any;
  locations: LocationModel[] = [];
  categories: CategoryModel[] = [];
  category: any;
  location: any;
  dateRange: Date[] = [];
  parameters = {
    startDate: '',
    lastDate: '',
    location: '',
    category: '',
    type: 'item',
    excel: 1,
    id:''
  };
  searchAllLoc: boolean;
  pdf: any;
  totalQuantity: number;
  totalRecords: number;
  stocks: StockModel[] = [];

  constructor(private http: HttpService, private translate: TranslateService,
              private locationService: LocationService, private route: ActivatedRoute,
              private categoryService: CategoryService, private utilityService: UtilityService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location || params.category) {
        if (params.startDate) {
          this.parameters.startDate = params.startDate ? params.startDate : '' ;
          this.parameters.lastDate = params.lastDate ? params.lastDate : '' ;
          this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
        }
        this.parameters.location = params.location ? params.location : '';
        this.parameters.category = params.category ? params.category : '';
        this.cdr.detectChanges(); // Değişiklik algılamayı başlat

      } else {
        const currentDate = new Date();
        const day = new Date(currentDate);
        this.parameters.startDate = this.getFormattedDate(day);
        this.parameters.lastDate = this.getFormattedDate(day);
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
      }
    });
    this.load().then();
  }

  async load(): Promise<void> {
    this.loading = true;
    delete this.parameters.excel;
    this.locations = this.locationService.getSearchLocation({type: 'supplier'});
    await this.http.get(`${CategoryModel.IRI}/category-type/product?mainCategory=yes`).subscribe(category => {
      this.categories = category['hydra:member'];
      this.categories.unshift({
        categoryMatches: [],
        accountingCode: '',
        categoryType: '',
        children: undefined,
        code: '',
        createdAt: '',
        department: '',
        isActive: false,
        location: undefined,
        lvl: 0,
        message: '',
        parent: '',
        parentId: '',
        tax: [],
        updatedAt: "",
        name: this.translate.instant('ALL_CATEGORIES'), id: null
      });
    });
    if (this.parameters.location === '' || this.parameters.location == null) {
      this.searchAllLoc = true;
    } else {
      this.searchAllLoc = false;
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
    await this.http.get('/api/reports/menu_item_reports', this.parameters).subscribe(response => {
      this.menuItem = response;
      let quantity = 0;

      if (this.menuItem.length > 0) {
        this.menuItem.map((item) => {
          quantity = quantity + parseFloat(item.quantity);
        })
      }
      this.totalQuantity = quantity;
      this.totalRecords = this.menuItem.length;
      this.loading = false;
      delete this.parameters.id;
    });
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    console.log('eee', event[1]);
    if (event[1] === null){
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    }else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }
    // @ts-ignore
    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    //this.parameters.saleDate = selectedDate.join(',');

    this.parameters = {...this.parameters};


  }
  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
  }

  onSelectCategory(event): void {
    this.parameters.category = event.value ? event.value : '';
  }

  parseFloatS(event): number {
    return parseFloat(event);
  }

  export(): void {
    this.parameters.excel = 1;
    const parameters = {...this.parameters};
    if (this.searchAllLoc) {
      this.http.download('/api/data/exports/menuItemReport', parameters)
        .subscribe(r => {
          this.utilityService.downloadFile(r, 'menu_item_report.xlsx');
        });
    } else {
      this.http.download('/api/data/exports/menuItemReportLoc', parameters)
        .subscribe(r => {
          this.utilityService.downloadFile(r, 'menu_item_report.xlsx');
        });
    }

  }

  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği


    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);

      pdf.save(`menu_item_group.pdf`); // PDF'i indir
    });
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  calculateTotal(field: string): number {
    // tslint:disable-next-line:max-line-length
    return (this.menuItem.reduce((total, item) => total + parseFloat(item[field]), 0)) > 0 ? this.menuItem.reduce((total, item) => total + parseFloat(item[field]), 0) : 0;
  }

  onSort(event: any): void {
    this.parameters[`order[${event.field}]`] = event.order > 0 ? 'ASC' : 'DESC';
    this.load();
  }
  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query, type: 'product'}).subscribe((response: StockModel) => {
      this.stocks = response['hydra:member'];
    });
  }
  async onStockSelect(event: StockModel): Promise<void> {
    delete this.parameters.category;
    this.parameters.id = event.id;
  }
}
