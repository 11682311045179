import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {ContactModel} from './contact.model';
import {UnitModel} from './unit.model';

export class PurchaseOptionModel extends ApiResourceModel{
  public static IRI = '/api/purchase_options';
  public stock: StockModel| string;
  public supplier: ContactModel| string;
  public name: string;
  public code: string;
  public packQuantity: number;
  public unit: UnitModel| string;
  public packName: any| string;
  public packPerCase: number;
  public price: number;
  public discount: number;
  public orderingUnit: string;
  public unitName: UnitModel| string;
  public baseUnitQuantity: number| null;
  public alertLevel: number| null;
  public orderQuantity: number| null;
  public onHand: number| null;
  public orderType: string | null;
  isActive: boolean;
  isMainContact: boolean;
  isCount: boolean;
}
