<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form (submit)="save()">
  <div class="p-fluid">
    <div class="p-field">
      <label for="name">{{'SELECT_PARENT' | translate}}</label>
      <p-dropdown [options]="items" name="parent"
                  (onChange)="getParentNumber($event)" filter="true" appendTo="body"
                  [placeholder]="'SELECT_PARENT'|translate" [required]="false"
                  [(ngModel)]="item.parent"
                  optionLabel="name"
                  id="parent"  optionValue="@id">
      </p-dropdown>
    </div>
    <div class="p-field">
      <label for="name">{{'CATEGORY' | translate}}</label>
      <input id="name" name="name" type="text" pInputText [(ngModel)]="item.name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-field">
      <label for="code">{{'CODE' | translate}}</label>
      <span class="p-float-label p-input-icon-right">
      <i class="pi pi-spin pi-spinner" *ngIf="loading || codeLoading"></i>
      <input type="text" id="code" name="code" pInputText [(ngModel)]="item.code" [disabled]="loading || codeLoading">
      </span>
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-field">
      <label for="tax">{{'CATEGORY_TYPE' | translate}}</label>
      <p-dropdown [options]="categoryTypes" name="categoryType" filter="true" appendTo="body" [placeholder]="'SELECT_TYPE'|translate"
                  [required]="false" [(ngModel)]="item.categoryType" (change)="item.categoryType = $event.target.value" optionLabel="name" id="type"  optionValue="value"></p-dropdown>
      <small class="p-error">{{ errors['type'] }}</small>
    </div>
    <div class="p-field" *ngIf="item.categoryType ==='stock'">
      <label for="tax">{{'CATEGORY_MATCHES' | translate}}</label>
      <p-multiSelect appendTo="body" [style]="{width: '100%'}" [options]="mainCategories" [(ngModel)]="item.categoryMatches" display="chip"
                     defaultLabel="{{'SELECT' | translate}}" name="categoryMatches" id="categoryMatches" optionLabel="name" optionValue="@id" (onChange)="addMatches($event)">
      </p-multiSelect>

      <small class="p-error">{{ errors['type'] }}</small>
    </div>

  </div>
  <br>
  <button pButton type="reset" label="{{'CANCEL' | translate}}" (click)="close()" icon="pi pi-times" class="p-mr-2"  ></button>
  <button pButton type="submit" label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]="this.submitted"></button>
</form>

