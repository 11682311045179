<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }" *ngIf="type === 'material'">{{ 'INVENTORY_ITEMS' | translate }}</h3>
      <h3 [style]="{ fontWeight: '600' }" *ngIf="type === 'semi_product'">{{ 'SUB_RECIPES' | translate }}</h3>
      <h3 [style]="{ fontWeight: '600' }" *ngIf="type === 'product'">{{ 'MENU_ITEMS' | translate }}</h3>

    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEMS'"></app-page-info>
      <p-button icon="pi pi-arrow-down"  (onClick)="createExcel()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <a target="_blank" href="{{apiUrl + '/api/stocks/pdf'}}"> <p-button icon="pi pi-file-pdf"[style]="{marginLeft: '5px', float:'right'}"></p-button></a>
      <button pButton pRipple type="button" label="{{ 'NEW' | translate}}"
              (click)="openForm()" [style]="{marginLeft: '5px', float:'right'}">
      </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                   [(ngModel)]="parameters['stock.category.id']"
                   placeholder="{{'SELECT_CATEGORY' | translate}}" (onChange)="changeParameters($event,'category')"
                   [options]="searchCategories"  optionLabel="name" optionValue="id" [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  appendTo="body" [(ngModel)]="parameters['stock.supplier.id']" placeholder="{{ 'SELECT_SUPPLIER' | translate }}"
                  (onChange)="changeParameters($event,'supplier')"  [options]="searchSuppliers"
                  optionLabel="name" optionValue="id" [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                   [(ngModel)]="parameters['stock.locations.id']" placeholder="{{ 'SELECT_LOCATION' | translate}}"
                   (onChange)="changeParameters($event,'locations')"  [options]="searchLocations"
                   optionLabel="name" optionValue="id" [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <input [style]="{width: '100%',height:'45px',  border:'none', borderBottom: '2px solid #47bfd4'}"
             type="text" pInputText [(ngModel)]="parameters.name" name="name"
             (change)="changeParameters($event,'name')"
             placeholder="{{ 'SEARCH_EG_NAME_CODE' | translate}}"/>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" [style]="{padding: '20px', marginBottom:'20px'}" text="{{'STOCK_IMPORT_REMAINING'| translate }}">
      </p-message>
        <p-table [loading]="tableLoading" [lazy]="true" [value]="products"
                 [showCurrentPageReport]="true" [resizableColumns]="true" [tableStyle]="{'min-width': '50rem'}"
                 (onLazyLoad)="loadStocksData($event, 'search')"
                 [globalFilterFields]="['sku']" dataKey="name"
                 styleClass="p-datatable-striped"  columnResizeMode="expand" (onColResize)="onResize($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '10px'}"></th>
              <th [style]="{width: '8%'}"  pSortableColumn="variants.code"><p-sortIcon field="variants.code"></p-sortIcon>{{'SKU' | translate}} <app-tooltip description="PRODUCTS_SKU_TOOLTIP"></app-tooltip></th>
              <th [style]="{width: '20%'}" pSortableColumn="name">{{'STOCK' | translate}} <app-tooltip description="PRODUCTS_STOCK_TOOLTIP"></app-tooltip></th>
              <th [style]="{width: '10%'}" pSortableColumn="category" >{{'CATEGORY' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'material'" [style]="{width: '15%'}" pSortableColumn="supplier"  >{{'SUPPLIER' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'material'" [style]="{width: '10%'}" pSortableColumn="variants.price"><p-sortIcon field="variants.price"></p-sortIcon>{{ 'PRICE'|translate }} <app-tooltip description="PRODUCTS_PRICE_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'product'" [style]="{width: '10%'}" pSortableColumn="variants.salePrice"><p-sortIcon field="variants.salePrice"></p-sortIcon>{{ 'SALE_PRICE'|translate }} <app-tooltip description="PRODUCTS_SALES_PRICE_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type !== 'material'" [style]="{width: '10%'}" >{{ 'COST'|translate }} <app-tooltip description="PRODUCTS_COST_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'semi_product'" [style]="{width: '10%'}" >{{ 'UNIT'|translate }} <app-tooltip description="PRODUCTS_COST_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'test'" [style]="{width: '10%'}" >{{ 'MATERIAL_COST'|translate }} <app-tooltip description="PRODUCTS_COST_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'product'" [style]="{width: '10%'}" >{{ 'PROFIT'|translate }} <app-tooltip description="PRODUCTS_PROFIT_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'product'" [style]="{width: '10%'}" >{{ 'MARGIN'|translate }} <app-tooltip description="PRODUCTS_MARGIN_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type === 'material'" [style]="{width: '10%'}" pSortableColumn="variants.minOrder"><p-sortIcon field="variants.minOrder"></p-sortIcon>{{'MIN_ORDER' | translate}} <app-tooltip description="MIN_ORDER_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type !== 'product'" [style]="{width: '10%'}" pSortableColumn="variants.amount"><p-sortIcon field="variants.amount"></p-sortIcon>{{'IN_STOCK' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type !== 'product'" [style]="{width: '10%'}" >{{'IN_VALUE' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type !== 'product'" [style]="{width: '10%'}" >{{'LAST_COUNT' | translate}} <app-tooltip description="LAST_COUNT_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type !== 'material'" [style]="{width: '10%'}" >{{'PECIPES' | translate}} <app-tooltip description="LAST_COUNT_TOOLTIP"></app-tooltip></th>
              <th *ngIf="type !== 'matearial'" [style]="{width: '10%'}" >{{'LOCATION_PRICE' | translate}} <app-tooltip description="LAST_COUNT_TOOLTIP"></app-tooltip></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-expanded="expanded">
            <tr [style]="{background : expanded ? '#cdcdcd' : ''}">
              <td [style]="{padding : '0px'}">
                <button *ngIf="row.type === 'product'" type="button" pButton pRipple [pRowToggler]="row"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
              </td>
              <td>{{ row.variants[0].code }}</td>
              <td><a href="javascript:void(0)" (click)="edit(row)"><i class="pi pi-pencil" [style]="{fontSize: '10px',marginRight: '10px'}"></i> </a>{{ row.name }}</td>
              <td>{{ row.category?.parent?.name }} {{ row.category?.parent ? '/' : '' }} {{ row.category?.name }}</td>
              <td *ngIf="type === 'material'" >{{ row.purchaseOptions?.length > 0 ? 'Multi' : row.supplier?.name  }}</td>
              <td *ngIf="type === 'material'" >
                <app-tooltip description="LAST_PURCHASE_PRICE_DESCRIPTION"></app-tooltip> &nbsp;
                {{ row.variants[0].price|currency: row.currency.symbol }}
                <br>
                <app-tooltip description="AVERAGE_PRICE_DESCRIPTION" [style]="{color: '#55c416'}"></app-tooltip> &nbsp;
                <span [style]="{color: '#55c416'}">{{ getAveragePrice(row) |currency: row.currency.symbol }}</span>
              </td>
              <td *ngIf="type === 'product'">{{ row.variants[0].salePrice|currency: row.currency.symbol}}</td>
              <td *ngIf="type === 'product'">{{ cost(row)|currency: row.currency.symbol }}</td>
              <td *ngIf="type === 'semi_product'">{{ row.variants[0].materialCost |currency: row.currency.symbol }}</td>
              <td *ngIf="type === 'semi_product'">{{ row.unit?.name }}</td>
              <td *ngIf="type === 'product'" >{{ row.profit|currency: row.currency.code: '' }}</td>
              <td *ngIf="type === 'product'">{{ row.margin|percent: '1.2-2' }}</td>
              <td *ngIf="type === 'material'" >{{ row.variants[0].minOrder }} {{ row.unit?.name }}</td>

              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type !== 'product' &&  !parameters.locations">{{ row.variants[0].amount | number: '1.2-2'}} {{ row.unit?.name }} <i class="pi pi-map-marker" (click)="showLocationQuantity(row)"></i></td>
              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type !== 'product' && parameters.locations">{{ getStockQuantitiesAmount(row) | number: '1.2-2'}} {{ row.unit?.name }} <i class="pi pi-map-marker" (click)="showLocationQuantity(row)"></i></td>

              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type === 'material' && !parameters.locations" >{{ row.variants[0].amount * row.variants[0].price | currency: row.currency.symbol }}</td>
              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type === 'semi_product' && !parameters.locations" >{{ row.variants[0].amount * row.variants[0].materialCost | currency: row.currency.symbol }}</td>
              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type !== 'product' && !parameters.locations" >{{ row.variants[0].amount * row.variants[0].salePrice | currency: row.currency.symbol }}</td>

              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type === 'material' && parameters.locations" >
                {{ getStockQuantitiesAmount(row) * row.variants[0].price | currency: row.currency.symbol }} <br>
                <span [style]="{color: '#55c416'}">{{ getStockQuantitiesAmount(row) * getAveragePrice(row) | currency: row.currency.symbol }}</span>
              </td>
              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type === 'semi_product' && parameters.locations" >{{ getStockQuantitiesAmount(row) * row.variants[0].materialCost | currency: row.currency.symbol }}</td>
              <td [style]="{textAlign:'right', padding:'0px'}" *ngIf="type !== 'product' && parameters.locations" >{{getStockQuantitiesAmount(row) * row.variants[0].salePrice | currency: row.currency.symbol }}</td>

              <td *ngIf="type !== 'product'">{{ getStockQuantitiesLastCount(row)}}</td>
              <td *ngIf="type !== 'material'"></td>
              <td *ngIf="type !== 'material'"></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-row>
            <tr>
              <td colspan="16" [style]="{padding : '0px'}">
                <div>
                    <p-table [value]="row.variants" dataKey="id">
                    <ng-template pTemplate="body" let-variant>
                      <tr>
                        <td [style]="{width: '10px', padding:'25px'}"></td>
                        <td [style]="{width: '8%',padding:'0px'}">{{variant.code}}</td>
                        <td [style]="{width: '20%',padding:'0px'}">{{variant.name}}</td>
                        <td [style]="{width: '10%',padding:'0px'}"></td>
                        <td [style]="{width: '10%',padding:'0px'}">{{variant.salePrice | currency: row.currency.symbol}}</td>
                        <td [style]="{width: '10%',padding:'0px'}">{{ getRecipeTotal(variant.variantRecipes[0]?.variantRecipeItems)}}</td>
                        <td [style]="{width: '10%',padding:'0px'}">{{variant.salePrice}}</td>
                        <td [style]="{width: '10%',padding:'0px'}">{{variant.salePrice}}</td>
                        <td [style]="{width: '10%',padding:'0px'}"><button pButton type="button" label="{{ 'RECIPE' | translate}}" (click)="onLoadRecipes(variant.id)" ></button></td>
                        <td [style]="{width: '10%',padding:'0px'}"><button pButton type="button" label="Click" ></button></td>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">There are no order for this product yet.</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </td>
            </tr>
    </ng-template>
        </p-table>
        <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
          <div class="p-col-4 p-offset-4" style="">
            <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                    [icon]="'pi pi-arrow-down'"
                    (click)="loadStocksData()"></button>
          </div>
        </div>
    </div>
  </div>

</p-card>

<p-confirmPopup></p-confirmPopup>
<p-sidebar [(visible)]="display" [style]="{width:'80em'}" position="right" (onHide)="reload()">
  <app-new-material-create *ngIf="displayCreate" [type]="type" [item]="item" ></app-new-material-create>
</p-sidebar>

<p-sidebar [(visible)]="showQuantitiesDialog" position="right"  [style]="{width:'60em'}">
  <h3>Depolar ve Stock Miktarları</h3>
  <hr>
  <p-table *ngIf="showQuantitiesDialog" [value]="quantities['stockQuantities']"
           styleClass="p-datatable-striped"  columnResizeMode="expand">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width:'40%'}"></th>
        <th [style]="{textAlign:'right',width:'20%'}">{{ 'IN_STOCK' | translate}}</th>
        <th [style]="{textAlign:'right',width:'20%'}">{{ 'PRICE' | translate}}</th>
        <th [style]="{textAlign:'right',width:'20%'}">{{ 'VALUE' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ getLocationName(item.location)}}</td>
        <td [style]="{textAlign:'right', padding:'0px'}">{{ item.amount| number: '1.2-2'}} {{ quantities.unit?.name}}</td>
        <td [style]="{textAlign:'right', padding:'0px'}">{{ item.averagePrice | currency: quantities.currency?.symbol}}</td>
        <td [style]="{textAlign:'right', padding:'0px'}">{{ item.amount * item.averagePrice | currency: quantities.currency?.symbol}}</td>
      </tr>
    </ng-template>
  </p-table>

</p-sidebar>
<p-sidebar  [(visible)]="recipeDialog" [style]="{width: '70vw'}" position="right">
  <app-variant-recipe *ngIf="recipeDialog"  variantId="{{ recipeId }}" ></app-variant-recipe>
</p-sidebar>


