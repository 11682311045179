import { Component, OnInit } from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../service/location.service';
import {UtilityService} from '../../../../service/utility.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



@Component({
  selector: 'app-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.scss']
})
export class ProductGroupComponent implements OnInit {recalculatingItem: number;
  events: any[] = [];
  options: any;
  info: any;
  categories: any;
  loading: boolean;
  data: any;
  location: LocationModel[] = [];
  dateRange: Date[] = [];
  showTooltipMap = new Map<number, boolean>();
  parameters = {
    startDate : '',
    lastDate : '',
    location: '',
    order: ''
  };
  subItem: any;
  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService, private utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.route.queryParams.subscribe( params => {
      if (params.location || params.date){
        this.parameters.location = params.location ? params.location : '' ;
        this.parameters.startDate = params.date ? params.date : '' ;
        this.parameters.lastDate = params.date ? params.date : '' ;
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
      }
      else {
        const currentDate = new Date();
        const day = new Date(currentDate);
        this.parameters.startDate = this.getFormattedDate(day);
        this.parameters.lastDate = this.getFormattedDate(day);
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
      }
    });
    this.load().then();

  }
  async load(): Promise<void>{
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/reports/categories_reports', this.parameters).subscribe(response => {
      this.categories = response;
      this.loading = false;
    });
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    console.log('eee', event[1]);
    if (event[1] === null){
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    }else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }
    // @ts-ignore
    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    //this.parameters.saleDate = selectedDate.join(',');

    this.parameters = {...this.parameters};


  }
  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }
  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }
  toggleTooltip(catId: number): void {
    this.showTooltipMap.set(catId, true);
    setTimeout(() => {
      this.showTooltipMap.set(catId, false);
    }, 1000);
  }
  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/productGroupReport', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'product_group_report.xlsx'); });
  }
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        format: 'a3'
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdf.internal.pageSize.getHeight());
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`product_group_report.pdf`); // PDF'i indir
    });
  }
  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
