
export class FlashMessageModel {
  severity: string;
  message: string;

  constructor(severity: string, message: string = 'SUCCESS_UPDATED') {
    this.severity = severity;
    this.message = message;
  }
}

export enum FlashMessage {
  Success = 'success',
  Error = 'error',
  Warning = 'warn',
  Info = 'info',
}
