<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'SALES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'RECEIVE_ORDERS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" class="p-button-secondary"
              [routerLink]="['/sales/create']" label="{{'NEW_SALE' | translate }}"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <!--
      <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
        <p-calendar (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body"
                    (onClose)="load(null, 'search')" selectionMode="range" [(ngModel)]="parameters.createdAt"
                    name="createdAt" dateFormat="yy-mm-dd">
        </p-calendar>
        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
      </div>
      -->
    </div>

    <div class="p-col-12 p-md-6 p-lg-2">
      <!---
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="suppliers" [(ngModel)]="parameters.contactId" name="supplier"
                  (onChange)="load(null, 'search')" placeholder="{{'SELECT_SUPPLIER' | translate}}" optionValue="@id"
                  optionLabel="name">

      </p-dropdown>
      --->
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="errors.length > 0">
    <div class="p-col div-sums alert-div">
      <b> {{ errors[0]?.status}}!</b>
      {{ errors[0]?.message}}
    </div>
  </div>
  <div class="p-grid">
    <p-tabMenu [model]="tabMenuItems"  [activeItem]="tabMenuActiveItem"></p-tabMenu>
   <div *ngIf="tabMenuActiveItem.title === 'waiting'">
     <div class="row">
       <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
         <form (submit)="load()" *ngIf="!detailed">

           <p-table responsiveLayout="scroll" [loading]="tableLoading" [lazy]="true" [value]="items"
                    (onLazyLoad)="load($event, 'SEARCH')" [tableStyle]="{'min-width': '50rem'}"
                    [reorderableColumns]="true" [rows]="20"
                    [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
                    styleClass="p-datatable-striped"
           >
             <ng-template pTemplate="header">
               <tr>
                 <th [style]="{width:'5%'}" id="empty"></th>
                 <th [style]="{width:'7%'}" id="code">{{ 'CODE' | translate }}
                   <app-tooltip [description]="'ORDER_NUMBER_TOOLTIP'"></app-tooltip>
                 </th>
                 <th [style]="{width:'20%'}" id="SUPPLIER">{{'SUPPLIER'|translate}}
                   <app-tooltip
                     description="{{ parameters.type == invoiceType.Sales ? 'CUSTOMER_TOOLTIP' : 'SUPPLIER_TOOLTIP'}}"></app-tooltip>
                 </th>
                 <th [style]="{width:'15%'}" id="LOCATION">{{ 'LOCATION' | translate}}
                   <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip>
                 </th>
                 <th [style]="{width:'15%'}" id="TOTAL">{{ 'TOTAL' | translate}}
                   <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip>
                 </th>
                 <th [style]="{width:'12%'}" id="CREATED_AT">{{ 'CREATED_AT' | translate}}
                   <app-tooltip description="CREATED_TOOLTIP"></app-tooltip>
                 </th>
                 <th [style]="{width:'7%'}" id="IS_RECEIVED"
                     *ngIf="parameters.type === invoiceType.Purchase"></th>
               </tr>
               <tr>
                 <td [style]="{width:'15%'}"></td>
                 <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.code"
                            name="code"></td>
                 <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText
                            [(ngModel)]="parameters['contact.name']" name="contactName"></td>
                 <td>
                   <p-dropdown appendTo="body" [options]="locations" optionValue="id" (onChange)="parameters.location = $event.value;
                load(null, 'search')" [ngModel]="parameters.location" optionLabel="name">

                   </p-dropdown>
                 </td>
                 <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.total"
                            name="total"></td>
                 <td>
                   <p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" appendTo="body" (onClose)="load()"
                               selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt"
                               dateFormat="yy-mm-dd"></p-calendar>
                 </td>

                 <td *ngIf="parameters.type === invoiceType.Purchase">
                 </td>
               </tr>
             </ng-template>
             <ng-template pTemplate="body" let-row let-index="rowIndex">

               <tr>
                 <td>
                   {{ index + 1 }}
                 </td>
                 <td [style]="{width:'15%'}">{{ row.code }}</td>
                 <td>{{ row.customerName ? row.customerName : (row.contact ? row.contact.name : '-') }}</td>
                 <td>{{ row.location.name }}</td>
                 <td
                   style="text-align: right">{{ row.total|currency: (row.currency ? row.currency.code : currency): '' }}</td>
                 <td>{{ row.createdAt|date: 'YYYY/MM/d' }}</td>
                 <td *ngIf="parameters.type === invoiceType.Sales">
                   <p-tag [severity]="row.itemStatus|statusLabel">{{ row.itemStatus|status: row.itemExpectedDate }}</p-tag>
                 </td>
                 <td *ngIf="parameters.type === invoiceType.Purchase" [style]="{padding: '0px'}">
                   <p-button [routerLink]="['/sales/create/']"
                           [queryParams]="{ poInvoice: row.id }" icon="pi pi-eye"
                           [label]="'DETAILS'|translate "></p-button>
                 </td>
               </tr>
             </ng-template>
           </p-table>

           <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
         </form>
         <app-invoice-detailed-list *ngIf="detailed"></app-invoice-detailed-list>
       </div>
     </div>
   </div>
   <div *ngIf="tabMenuActiveItem.title === 'sales'">
     <div class="row">
       <div class="p-col-12 p-md-12 p-sm-6">

         <form (submit)="loadSales()" *ngIf="!detailed">
             <p-table responsiveLayout="scroll" [loading]="loading" [lazy]="true" [value]="itemsSales" [totalRecords]="totalSales"
                      (onLazyLoad)="loadSales($event)" [rowsPerPageOptions]="[10,25,50]" [reorderableColumns]="true"
                      (onRowReorder)="onReorder($event)"
                      [paginator]="true" [rows]="20" [showCurrentPageReport]="true" [resizableColumns]="true"
                      styleClass="p-datatable-striped" columnResizeMode="expand" (onColResize)="onResize($event)"
             >
               <ng-template pTemplate="header">
                 <tr>
                   <th [style]="{width:'3%'}"></th>
                   <th [style]="{width:'6%'}">{{ (parametersSales.type == invoiceType.Sales ? 'CODE' : 'CODE') | translate }}
                     <app-tooltip [description]="'ORDER_NUMBER_TOOLTIP'"></app-tooltip>
                   </th>
                   <th [style]="{width:'14%'}">{{'LOCATION'|translate}}
                     <app-tooltip
                       description="{{'LOCATION_TOOLTIP'}}"></app-tooltip>
                   </th>
                   <th [style]="{width:'14%'}">{{ (parametersSales.type == invoiceType.Sales ? 'CUSTOMER' : 'SUPPLIER')|translate}}
                     <app-tooltip
                       description="{{ parameters.type == invoiceType.Sales ? 'CUSTOMER_TOOLTIP' : 'SUPPLIER_TOOLTIP'}}"></app-tooltip>
                   </th>
                   <th [style]="{width:'10%'}">{{ 'TOTAL' | translate}}
                     <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip>
                   </th>
                   <th [style]="{width:'10%'}">{{ 'CREATED_AT' | translate}}
                     <app-tooltip description="CREATED_TOOLTIP"></app-tooltip>
                   </th>
                   <th [style]="{width:'10%'}">{{ 'DEADLINE' | translate}}
                     <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip>
                   </th>
                   <th [style]="{width:'12%'}">{{ 'STOCK_STATUS' | translate}}
                     <app-tooltip description="STOCK_STATUS_TOOLTIP"></app-tooltip>
                   </th>
                   <!---
                   <th [style]="{width:'12%'}" pResizableColumn>{{ 'MATERIAL_STATUS' | translate}}
                     <app-tooltip description="MATERIAL_STATUS_TOOLTIP"></app-tooltip>
                   </th>
                   --->
                   <th [style]="{width:'11%'}" pResizableColumn>{{ 'PRODUCTION_STATUS' | translate}}
                     <app-tooltip description="PRODUCTION_STATUS_TOOLTIP"></app-tooltip>
                   </th>
                   <th [style]="{width:'11%'}" pResizableColumn >{{ 'DELIVERY_STATUS' | translate}}
                     <app-tooltip description="DELIVERY_STATUS_TOOLTIP"></app-tooltip>
                   </th>

                   <th [style]="{width:'15%'}" pResizableColumn >{{ 'ACCOUNTING' | translate}}
                     <app-tooltip description="DELIVERY_STATUS_TOOLTIP"></app-tooltip>
                   </th>
                 </tr>
                 <tr>
                   <th style="width:3rem"></th>
                   <td><input type="text" pInputText [(ngModel)]="parametersSales.code" name="code"></td>
                   <td><input type="text" pInputText [(ngModel)]="parametersSales['contact.name']" name="contactName"></td>
                   <td><input type="text" pInputText [(ngModel)]="parametersSales.total" name="total"></td>
                   <td>
                     <p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" appendTo="body" (onClose)="load()"
                                 selectionMode="range" [(ngModel)]="parametersSales.createdAt" name="createdAt"
                                 dateFormat="yy-mm-dd"></p-calendar>
                   </td>
                   <td>
                     <p-calendar #expectedDate (onSelect)="closeCalendar('expectedDate')" appendTo="body" (onClose)="load()"
                                 selectionMode="range" [(ngModel)]="parametersSales.expectedDate" name="expectedDate"
                                 dateFormat="yy-mm-dd"></p-calendar>
                   </td>
                   <!---
                   <td>
                     <p-dropdown inputId="dropdown" (onChange)="parametersSales.materialStatus = $event.value; load()"
                                 appendTo="body" baseZIndex="9999" [options]="stockStatuses" name="materialStatus"
                                 [(ngModel)]="parametersSales.materialStatus" optionLabel="name" optionValue="value"></p-dropdown>
                   </td>
                   --->
                   <td>
                     <p-dropdown inputId="dropdown" (onChange)="parametersSales.productionStatus = $event.value; load()"
                                 appendTo="body" baseZIndex="9999" [options]="productionStatuses" name="materialStatus"
                                 [(ngModel)]="parametersSales.productionStatus" optionLabel="name" optionValue="value"></p-dropdown>
                   </td>
                   <td>
                     <p-dropdown inputId="dropdown" (onChange)="parametersSales.deliveryStatus = $event.value; load()"
                                 appendTo="body" baseZIndex="9999" [options]="deliveryStatuses" name="materialStatus"
                                 [(ngModel)]="parametersSales.deliveryStatus" optionLabel="name" optionValue="value"></p-dropdown>
                   </td>
                 </tr>
               </ng-template>
               <ng-template pTemplate="body" let-row let-index="rowIndex">
                 <tr [pReorderableRow]="index">
                   <td>
                     {{ index + 1 }}
                   </td>
                   <td width="15%"><a routerLink="/{{row.type.toLowerCase()}}/{{row.id}}/edit">{{ row.code }}</a></td>
                   <td>{{ row.targetLocation ? row.targetLocation.name : '' }}</td>
                   <td>{{ row.contact?.name }}</td>
                   <td [style]="{textAlign:'right'}">{{ row.total | number: '1.2-2' }} {{ row.currency ? row.currency.symbol : currency }}</td>
                   <td [style]="{textAlign:'right'}">{{ row.createdAt|date: 'd-MM-yyyy' }}</td>
                   <td [style]="{textAlign:'right'}">{{ row.expectedDate ? (row.expectedDate|date: 'd-MM-yyyy' ): '-'}}</td>
                   <td>
                     <p-tag [severity]="row.itemStatus|statusLabel">{{ row.itemStatus|status: row.itemExpectedDate }}</p-tag>
                   </td>
                   <!---
                   <td>
                     <p-tag
                       [severity]="row.materialStatus|statusLabel">{{ row.materialStatus|status: row.materialExpectedDate }}</p-tag>
                   </td>
                   --->
                   <td>
                     <p-tag [severity]="row.productionStatus|statusLabel" class="cursor-pointer"
                            (click)="loadWorkOrders(row.id);">{{ row.productionStatus|translate }} </p-tag>
                   </td>
                   <td>
                     <p-tag (click)="openChangeStatusPanel(dp, $event, row.id, row.itemStatus)" [severity]="row.deliveryStatus|statusLabel"
                            class="cursor-pointer" icon="pi pi-chevron-down"
                            *ngIf="row.deliveryStatus != deliveryActions.DELIVERED">{{row.deliveryStatus|translate}} </p-tag>
                     <p-tag severity="success"
                            *ngIf="row.deliveryStatus == deliveryActions.DELIVERED">{{row.deliveryStatus|translate}}</p-tag>
                   </td>
                   <td *ngIf="row.invoiceNumber === null ">
                     <button *ngIf="row.deliveryStatus == deliveryActions.DELIVERED && row.contact"
                             style="height: 32px;width: 100%" pButton pRipple type="button"
                             label="{{'SEND_TO_ACCOUNTING'| translate}}" class="p-button-rounded p-button-secondary" (click)="sendInvoiceToAccounting(row.id)">
                     </button>

                     <button  *ngIf="row.deliveryStatus == deliveryActions.DELIVERED && !row.contact" style="height: 32px;width: 100%" pButton pRipple type="button"
                              label="{{'NO_CONTACT_IN_INVOICE'| translate}}" class="p-button-rounded p-button-warning" >
                     </button>
                   </td>
                   <td *ngIf="row.invoiceNumber !== null ">

                     <button  style="height: 32px;width: 100%" pButton pRipple type="button" icon="pi pi-file-pdf"
                             label="{{'VIEW_ACCOUNT_INVOICE'| translate}}" class="p-button-rounded" (click)="downloadPdf(row.invoiceNumber)">
                     </button>
                   </td>
                 </tr>
               </ng-template>
             </p-table>

           <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
         </form>
         <app-invoice-detailed-list *ngIf="detailed"></app-invoice-detailed-list>
         <table class="total-box p-p-3" *ngIf="!detailed">
           <tr>
             <th>{{ 'SUBTOTAL' | translate}}</th>
             <td>{{ totals.subtotal|currency: currency }}</td>
           </tr>
           <tr>
             <th>{{ 'TAX' | translate}}</th>
             <td>{{ totals.tax|currency: currency }}</td>
           </tr>
           <tr>
             <th>{{ 'TOTAL' | translate}}</th>
             <td>{{ totals.total|currency: currency }}</td>
           </tr>
         </table>
         <div class="clearfix"></div>
         <p-overlayPanel #op appendTo="body">
           <ng-template pTemplate>
             <p-table [value]="workOrders" [loading]="workOrdersLoading" style="width: 75px">
               <ng-template pTemplate="header">
                 <tr>
                   <td>{{'CODE' | translate}}</td>
                   <td>{{'STATUS' | translate}}</td>
                 </tr>
               </ng-template>
               <ng-template pTemplate="body" let-i="rowIndex" let-row>
                 <td>
                   <a href="" [routerLink]="'/work-orders/' + row.id + '/edit'">{{ row.code }}</a>
                 </td>
                 <td>
                   <p-tag [severity]="row.status|statusLabel">{{ row.status|status }}</p-tag>
                 </td>
               </ng-template>
             </p-table>
           </ng-template>
         </p-overlayPanel>
         <p-overlayPanel #dp appendTo="body">
           <ng-template pTemplate>
             <div *ngFor="let item of changeStatusItems">
               <p-button [disabled]="item.title  === 'NOT_AVAILABLE'" *ngIf="item.state.status !== deliveryActions.PARTIAL_DELIVERED" [label]="item.label"
                         styleClass="p-button-text" class="p-d-block"
                         (onClick)="changeStatus(item.state.id, item.state.status); dp.hide()"></p-button>
               <p-button [disabled]="item.title  === 'NOT_AVAILABLE'" *ngIf="item.state.status === deliveryActions.PARTIAL_DELIVERED" [label]="item.label"
                         styleClass="p-button-text" class="p-d-block" (onClick)="receive(item.state.id); dp.hide()"></p-button>
             </div>
           </ng-template>
         </p-overlayPanel>
       </div>
     </div>
   </div>
  </div>
</p-card>



