import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ContactModel} from '../../../model/contact.model';
import {HttpService} from '../../../service/http.service';
import {InvoiceModel, InvoiceStatus, InvoiceStockStatus, InvoiceType} from '../../../model/invoice.model';
import {InvoiceItemModel} from '../../../model/invoice-item.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {LocationModel} from '../../../model/location.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {DialogService} from 'primeng/dynamicdialog';
import {QuickStockCreateComponent} from '../../stock/quick-stock-create/quick-stock-create.component';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {StockService} from '../../../service/stock.service';
import {StockModel} from '../../../model/stock.model';
import {environment} from '../../../../environments/environment';
import {MenuItem} from 'primeng/api';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CurrencyModel} from '../../../model/currency.model';
import {CurrencyService} from '../../../service/currency.service';
import {ValidationService} from '../../../service/validation.service';
import {AddressComponent} from '../../address/address.component';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {CompanyModel} from '../../../model/company.model';
import {InvoiceReceiveComponent} from '../invoice-receive/invoice-receive.component';
import {ContactService} from '../../../service/contact.service';
import {ContactCreateDialogComponent} from '../../contact/contact-create-dialog/contact-create-dialog.component';
import {PrintTemplateModel, PrintTemplateTypes} from '../../../model/print-template.model';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.scss'],
  providers: [DialogService],
})
export class InvoiceCreateComponent implements OnInit {

  contacts: ContactModel[] = [];
  locations: LocationModel[] = [];
  taxRates: TaxRateModel[] = [];
  currencies: CurrencyModel[] = [];
  loading = true;
  discountLoading = false;
  confirmLoading = true;
  deleting: boolean;
  invoice: InvoiceModel = new InvoiceModel();
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  submitted = false;
  id: number;
  apiUrl: string;
  printItems: MenuItem[] = [];
  taxes: TaxRateModel[] = [];
  status: string;
  currencyLabel: string;
  locationId: number;
  manualCustomer: boolean;
  shipmentText: string;
  billingAddressSameAsShippingAddress = true;
  shippingAddressString: string;
  billingAddressString: string;

  invoiceTypes = InvoiceType;
  invoiceStatus = InvoiceStatus;

  stockSubscriber: Subscription;
  validationSubscriber: Subscription;

  errors = [];
  company: CompanyModel = new CompanyModel();
  billable: boolean;
  sendingBill: boolean;
  printing: boolean;
  tableLoading: boolean;
  deliverAddressDisplay: boolean;
  selectedContact: any;

  constructor(private http: HttpService, private route: ActivatedRoute, private flashMessageService: FlashMessageService,
              private router: Router, private dialog: DialogService, private translate: TranslateService,
              private stockService: StockService, private currencyService: CurrencyService,
              private validationService: ValidationService, private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.loadFormData();
    this.route.queryParams.subscribe(params => {
      if (params.poInvoice) {
        this.http.get(`${InvoiceModel.IRI}/${params.poInvoice}`).subscribe((response: InvoiceModel) => {
          this.invoice.purchaseInvoice = params.poInvoice;
          this.invoice.contact = response.contact['@id'];
          this.invoice.items = [];
          response.items.map((item, i) => {
            const invoiceItem = {
              currency: item.currency,
              nonDiscountedUnitPrice: item.nonDiscountedUnitPrice,
              quantity: item.quantity,
              stock: item.stock,
              name: item.name,
              code: item.code,
              subtotal: item.subtotal,
              tax: item.tax,
              taxRate: item.taxRate,
              unitPrice: item.unitPrice,
              variant: item.variant,
              discountRate: null,
              discount: item.discount,
              purchaseOption: item.purchaseOption['@id'],
            };
            // @ts-ignore
            this.invoice.items.push(invoiceItem);
            this.invoice.items[i].taxRate = item.taxRate['@id'];
            // @ts-ignore
            this.autoCompleteValues.push(item.variant);
            // @ts-ignore
            this.autoCompleteValues[i].stock = item.stock;

            this.invoice.items[i].variant = item.variant['@id'];
            if (item.stock) {
              // @ts-ignore
              this.taxes.push(item.stock?.tax);
              this.invoice.items[i].stock = item?.stock['@id'];
            }
            setTimeout(() => {
              // @ts-ignore
              this.setStatus(item.variant['@id'], item.quantity, i);
            }, 3000);
          });
          this.calculateTotal();
        });
      }
    });

    this.invoice.currency = this.currencyService.getCurrency()['@id'];
    this.invoice.expectedDate = new Date();
    this.currencyLabel = this.currencyService.getCurrency().code;

    if (history.state.status !== undefined) {
      this.invoice.status = history.state.status;
    } else {
      this.invoice.status = InvoiceStatus.Confirmed;
    }

    this.route.data.subscribe(data => {
      this.invoice.type = data.type;
    });
    // tslint:disable-next-line:radix
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));

    if (this.id) {
      this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
        this.invoice = response;
        this.invoice.expectedDate = new Date(this.invoice.expectedDate);
        this.currencyLabel = this.invoice.currency?.code;
        if (this.invoice.contact) {
          this.selectedContact = this.invoice.contact;
        }
        this.invoice.contact = this.invoice.contact ? this.invoice.contact['@id'] : null;
        this.invoice.currency = this.invoice.currency ? this.invoice.currency['@id'] : null;
        // @ts-ignore
        this.locationId = this.invoice.location ? this.invoice.location.id : null;
        this.invoice.location = this.invoice.location ? this.invoice.location['@id'] : null;
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].taxRate = item.taxRate['@id'];
          // @ts-ignore
          this.autoCompleteValues.push(item.variant);
          // @ts-ignore
          this.autoCompleteValues[i].stock = item.stock;
          this.calculateTotalPrice(i);
          this.setStatus(this.invoice.items[i].variant['@id'], item.quantity, i);
          this.invoice.items[i].variant = item.variant['@id'];
          if (item.stock) {
            // @ts-ignore
            this.taxes.push(item.stock?.tax);
            this.invoice.items[i].stock = item?.stock['@id'];
          }
        });

        if (!this.invoice.contact && this.invoice.customerName) {
          this.manualCustomer = true;
        }

        if (this.invoice.shipmentCode) {
          this.shipmentText = this.invoice.shipmentCode;
        }

        if (this.invoice.shipmentLink) {
          this.shipmentText = `${this.invoice.shipmentCode} - ${this.invoice.shipmentLink}`;
        }

        this.billingAddressSameAsShippingAddress = false;

        if ((this.invoice.billingAddress &&
            this.invoice.shippingAddress &&
            this.invoice.billingAddress['@id'] === this.invoice.shippingAddress['@id']) ||
          this.invoice.billingAddress === null
        ) {
          this.billingAddressSameAsShippingAddress = true;
        }

        if (this.invoice.deliveryAddress) {
          this.shippingAddressString = this.invoice.deliveryAddress.address;
        }

        if (this.invoice.billingAddress) {
          this.billingAddressString = this.invoice.billingAddress.body;
        }
        this.loading = false;
      });
    } else {
      this.http.get(`${InvoiceModel.IRI}/invoice-number?type=${this.invoice.type}`).subscribe((response: InvoiceModel) => {
        this.invoice.series = response.series;
        this.invoice.number = response.number;
        this.invoice.code = response.code;
        this.invoice.expectedDate = new Date();
        this.invoice.total = 0;
        this.invoice.subtotal = 0;
        this.invoice.tax = 0;

        if (history.state.workOrderId !== undefined) {
          this.invoice.workOrder = history.state.workOrderId;
        }

        if (history.state.ids !== undefined && history.state.ids.length > 0) {
          history.state.ids.map((item: StockVariantModel, key: number) => {
            if (this.invoice.items.length < history.state.ids.length) {
              this.addItem();
            }
            this.autoCompleteValues.push(item);
            this.onVariantSelect(item, key);
            this.invoice.items[key].quantity = history.state.quantities[key];
            this.calculateTotalPrice(key);
          });
        }

        this.loading = false;
      });
    }

    this.loadFormData();

    this.apiUrl = environment.apiUrl;

    this.addItem();

    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });

    this.stockSubscriber = this.stockService.stocksData.subscribe((data: StockModel) => {
      if (data) {

        const invoiceItem = new InvoiceItemModel();
        invoiceItem.variant = data.variants[0]['@id'];
        invoiceItem.unitPrice = data.variants[0].salePrice;
        invoiceItem.quantity = 1;
        invoiceItem.subtotal = invoiceItem.quantity * invoiceItem.unitPrice;
        // @ts-ignore
        invoiceItem.tax = (invoiceItem.subtotal / 100) * data.tax.rate;
        invoiceItem.stock = data['@id'];
        invoiceItem.status = InvoiceStockStatus.StatusNotAvailable;
        invoiceItem.materialStatus = InvoiceStockStatus.StatusNotRecipe;
        // @ts-ignore
        invoiceItem.taxRate = data.tax['@id'];

        this.autoCompleteValues.push(data.variants[0]);

        if (this.autoCompleteValues.length > this.invoice.items.length) {
          this.addItem();
        }

        this.invoice.items[this.invoice.items.length - 1] = invoiceItem;
        this.calculateTotalPrice(this.invoice.items.length - 1);
      }
    });

    this.http.get(PrintTemplateModel.IRI, {type: PrintTemplateTypes.Invoice}).subscribe(response => {
      response['hydra:member'].map((item: PrintTemplateModel) => {
        this.printItems.push({
          label: this.translate.instant(item.name),
          command: () => this.print(item.id)
        });
      });
    });

    this.company = JSON.parse(localStorage.getItem('company'));

    if (this.company) {
      this.http.get(CompanyIntegrationModel.IRI, {'company.id': this.company.id}).subscribe(response => {
        if (response['hydra:totalItems'] > 0) {
          this.billable = true;
        }
      });
    }
    this.calculateTotal();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.stockService.updateStocks(null);
    this.stockSubscriber.unsubscribe();
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

  loadFormData(): void {

    this.contactService.getContactsAction({type: 'supplier', pagination: false});
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.contacts = response;
      }
    });

    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
      if (this.locations.length > 0 && this.invoice.location === undefined) {
        this.invoice.location = this.locations[0]['@id'];
        this.locationId = this.locations[0].id;
      }
    });

    this.http.get(TaxRateModel.IRI).subscribe((response: TaxRateModel[]) => {
      this.taxRates = response['hydra:member'];
      this.invoice.items.map((item, i) => {
        if (item.taxRate === undefined) {
          this.taxes.push(this.taxRates[0]);
          this.invoice.items[i].taxRate = this.taxRates[0]['@id'];
          this.invoice.items[i].tax = this.taxRates[0].rate;
        }
      });
    });

    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];

      if (this.currencies.length > 0 && !this.invoice.currency) {
        this.invoice.currency = this.currencies[0]['@id'];
      }
    });
  }

  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  };

  addItem = () => {
    const invoiceItem = new InvoiceItemModel();

    if (this.taxRates.length > 0) {
      this.taxes.push(this.taxRates[0]);
      invoiceItem.taxRate = this.taxRates[0]['@id'];
      invoiceItem.tax = invoiceItem.tax = this.taxRates[0].rate;
    }

    this.invoice.items.push(invoiceItem);
  };

  removeItem = (i: number) => {
    this.invoice.items.splice(i, 1);
  }

  // tslint:disable-next-line:typedef
  async save() {
    this.errors = [];
    delete this.invoice.shipments;
    delete this.invoice.credits;
    delete this.invoice.createdBy;
    delete this.invoice.incomingInvoices;
    delete this.invoice.workOrder;
    // @ts-ignore
    delete this.invoice.workOrders;
    // @ts-ignore
    if (this.invoice.targetLocation) {
      // @ts-ignore
      this.invoice.targetLocation = this.invoice.targetLocation['@id'];
    }
    if (this.invoice.deliveryAddress) {
      // @ts-ignore
      this.invoice.deliveryAddress = this.invoice.deliveryAddress['@id'];
    }
    this.invoice.items.map((item, key) => {
      this.invoice.items[key].currency = this.invoice.currency;
      if (this.invoice.items[key].purchaseOption) {
        this.invoice.items[key].purchaseOption = this.invoice.items[key].purchaseOption['@id'];
      }
    });


    this.submitted = true;
    if (this.id) {
      await this.http.put(`${InvoiceModel.IRI}/${this.id}`, this.invoice).then((response: InvoiceModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(err => {
      });
    } else {
      await this.http.post(InvoiceModel.IRI, this.invoice).then((response: InvoiceModel) => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          this.router.navigate(['/in-company-orders']);
        }
      }).catch(err => {
      });
    }

    this.submitted = false;
  }

  setStatus(variant: string, qty: number, i: number): void {
    if (variant !== undefined && qty !== undefined && this.invoice.type === InvoiceType.Sales) {
      this.tableLoading = true;
      this.http.get(`${variant}/status?qty=${qty}&location=${this.locationId}`).subscribe(response => {
        // @ts-ignore
        this.invoice.items[i].status = response.status;
        this.invoice.items[i].locationQuantity = response.quantity;
        // @ts-ignore
        this.invoice.items[i].materialStatus = response.materialStatus;
        // @ts-ignore
        this.invoice.items[i].stockExpectedDate = response.stockExpectedDate;

        this.tableLoading = false;
        this.calculateTotalPrice(i);
      });
    }
  }

  confirm(status: string): void {
    this.confirmLoading = true;
    this.status = status;
    this.http.put(`${InvoiceModel.IRI}/${this.id}`, {status: this.status}).then((response: InvoiceModel) => {
      this.invoice.status = response.status;
      this.confirmLoading = false;

      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));

      if (this.status === 'declined') {
        this.router.navigate(['/sales']);
      }
    });
  }

  onVariantSelect(event: StockVariantModel, i: number): void {
    this.invoice.items[i].variant = event['@id'];
    this.invoice.items[i].stock = event.stock['@id'];
    if (this.invoice.type === InvoiceType.Sales) {
      this.invoice.items[i].unitPrice = event.salePrice;
      this.invoice.items[i].nonDiscountedUnitPrice = event.salePrice;
    } else {
      this.invoice.items[i].unitPrice = event.defaultPrice;
      this.invoice.items[i].nonDiscountedUnitPrice = event.defaultPrice;
    }

    if (event.stock.tax) {
      this.invoice.items[i].taxRate = event.stock.tax['@id'];
    }

    // @ts-ignore
    this.taxes.push(event.stock.tax);
    // @ts-ignore
    /*
    const findIndex = event.stock.stockQuantities.findIndex((obj) => obj.location === this.invoice.location);
    this.invoice.items[i].unitPrice = event.stock.stockQuantities[findIndex].averagePrice;
     */
  }

  onContactsSelect(event): void {
    this.discountLoading = true;
    this.http.get(event.value).subscribe((response: ContactModel) => {
      this.selectedContact = response;
      if (response.address) {
        this.invoice.billingAddress = response.address['@id'];
        this.billingAddressString = response.address.body;
        this.invoice.shippingAddress = response.address['@id'];
        this.shippingAddressString = response.address.body;
      }

      this.invoice.items.map((item, i) => {
        if (this.invoice.discountRate) {
          this.invoice.items[i].unitPrice = this.invoice.items[i].nonDiscountedUnitPrice / 100 * (100 - this.invoice.discountRate);
        } else {
          this.invoice.items[i].unitPrice = this.invoice.items[i].nonDiscountedUnitPrice;
        }

        this.calculateTotalPrice(i);
      });

      this.discountLoading = false;
    });
  }

  onContactsDeliveryAddressSelect(event): void {
    this.invoice.deliveryAddress = event['@id'];
    this.shippingAddressString = event.address;
    this.deliverAddressDisplay = false;

  }

  itemTotalPrice(qty: number, unitPrice: number): number {
    let totalPrice = 0;
    if (qty !== undefined && unitPrice !== undefined) {
      totalPrice = qty * unitPrice;
    }
    return totalPrice;
  }

  calculateTotalPrice(i: number): void {
    let totalPrice = 0;
    let tax = 0;

    if (this.invoice.items[i].quantity !== undefined && this.invoice.items[i].unitPrice !== undefined) {
      this.invoice.items[i].subtotal = this.invoice.items[i].quantity * this.invoice.items[i].unitPrice;
      // tslint:disable-next-line:max-line-length
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      this.taxRates.map(taxItem => {
        if (this.invoice.items[i].taxRate === taxItem['@id']) {
          this.invoice.items[i].tax = (this.invoice.items[i].subtotal / 100) * taxItem.rate;
        }
      });
    }
    this.invoice.itemStatus = 'AVAILABLE';
    // tslint:disable-next-line:no-shadowed-variable
    this.invoice.items.map((item, i) => {
      if (item.subtotal !== undefined && item.tax !== undefined) {
        totalPrice += item.subtotal;
        tax += item.tax;
      }
      if (this.invoice.items[i].locationQuantity !== undefined && this.invoice.items[i].status === 'NOT_AVAILABLE') {
        this.invoice.itemStatus = 'NOT_AVAILABLE';
      }
    });

    this.invoice.subtotal = totalPrice;
    this.invoice.tax = tax;
    this.invoice.total = this.invoice.subtotal + this.invoice.tax;
  }

  calculateTotal(): void {
    let totalPrice = 0;
    let tax = 0;
    this.invoice.items.map(item => {
      if (item.subtotal !== undefined && item.tax !== undefined) {
        totalPrice += item.subtotal;
        tax += item.tax;
      }
    });

    this.invoice.subtotal = totalPrice;
    this.invoice.tax = tax;
    this.invoice.total = this.invoice.subtotal + this.invoice.tax;

  }

  createProduct(): void {
    const ref = this.dialog.open(QuickStockCreateComponent, {
      width: '50vw',
      header: this.translate.instant('QUICK_STOCK_CREATE'),
    });
  }

  print(id: number): void {
    this.printing = true;
    this.http.get(`${InvoiceModel.IRI}/${this.id}/pdf`, {templateId: id}).subscribe(response => {
      this.printing = false;
      window.open(response.response, '_blank');
    });
  }

  onCurrencyChange(): void {
    this.currencies.map(item => {
      if (item['@id'] === this.invoice.currency) {
        this.currencyLabel = item.code;
      }
    });
  }

  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  setTax(i, event): void {
    this.taxRates.map(item => {
      if (item['@id'] === event.value) {
        this.taxes[i] = item;
      }
    });
  }

  setManualCustomer(): void {
    this.manualCustomer = !this.manualCustomer;
  }

  openAddressDialog(address): void {
    this.deliverAddressDisplay = true;
  }

  async sendAccounting(): Promise<void> {
    this.sendingBill = true;
    await this.http.get(`${InvoiceModel.IRI}/${this.invoice.id}/send`).toPromise().then(response => {
      this.invoice.isInvoiced = response.isInvoiced;

      if (response.isInvoiced) {
        // tslint:disable-next-line:max-line-length
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, this.translate.instant('ACCOUNTED_SUCCESSFULLY')));
      } else {
        // tslint:disable-next-line:max-line-length
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, this.translate.instant('ACCOUNTED_FAILED')));
      }
    });

    this.sendingBill = false;
  }

  receive(): void {
    const ref = this.dialog.open(InvoiceReceiveComponent, {
      data: {id: this.invoice.id},
      width: '50vw',
      header: this.translate.instant('PURCHASE_RECEIVE'),
    });

    ref.onClose.subscribe(() => {
      this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
        this.invoice.isReceived = response.isReceived;
      });
    });
  }

  addContact(): void {
    this.dialog.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
    });
  }

  async deleteInvoice(): Promise<void> {
    this.deleting = true;
    const type = this.invoice.type === this.invoiceTypes.Sales ? 'sales' : 'purchases';

    await this.http.delete(`${InvoiceModel.IRI}/${this.invoice.id}`).then(() => {
      this.router.navigate([type]);
    });

    this.deleting = false;
  }
}
