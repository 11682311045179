import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceDeliveryStatus, InvoiceModel, InvoiceType} from '../../../model/invoice.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {CreditModel} from '../../../model/credit.model';
import {Subscription} from 'rxjs';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {InventoryModel} from '../../../model/inventory.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {ShipmentItemModel} from '../../../model/shipment-item.model';
import {DatePipe} from '@angular/common';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {WorkOrderModel} from '../../../model/work-order.model';
import {UserModel} from '../../../model/user.model';
import {DeliveryActions} from '../../../model/invoice-item.model';
import {LocationModel} from '../../../model/location.model';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {UtilityService} from '../../../service/utility.service';
import {CurrencyService} from '../../../service/currency.service';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {ContactModel} from '../../../model/contact.model';
import {InvoiceReceiveComponent} from '../../invoice/invoice-receive/invoice-receive.component';
import {any} from 'codelyzer/util/function';
import {SplitButtonModule} from 'primeng/splitbutton';

@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.scss'],
  providers: [DialogService, DynamicDialogConfig]
})
export class SalesListComponent implements OnInit {


  @ViewChild('createdAt') createdAt;
  @ViewChild('expectedDate') expectedDate;

  loading: boolean;
  workOrdersLoading: boolean;
  total: number;
  totalSales: number;
  items: InvoiceModel[] = [];
  itemsSales: InvoiceModel[] = [];
  workOrders: WorkOrderModel[] = [];
  currency: string;
  changeStatusItems: MenuItem[] = [];
  detailed: boolean;
  entities = [];
  totalPrice = 0;

  user: UserModel = new UserModel();
  settings = [];

  invoiceType = InvoiceType;
  invoiceStatus = [];
  status = [];
  invoiceDeliveryStatus = InvoiceDeliveryStatus;
  deliveryActions = DeliveryActions;
  suppliers: any[];
  grandTotal: number;
  totals = {
    total: 0,
    tax: 0,
    subtotal: 0
  };

  parameters = {
    code: '',
    contact: localStorage.getItem('manufacturingLocationContact'),
    tags: '',
    total: '',
    isReceived: false,
    expectedDate: [],
    createdAt: [],
    status: 'CONFIRMED',
    contactId: '',
    productionStatus: '',
    type: InvoiceType.Purchase,
    location: '',
    page: 0,
    itemsPerPage: 20,
    'currency.code': '',
    createdSaleInvoice: false
  };
  parametersSales = {
    code: '',
    'contact.name': '',
    tags: '',
    total: '',
    isReceived: false,
    expectedDate: [],
    createdAt: [],
    status: 'CONFIRMED',
    contactId: '',
    productionStatus: '',
    type: InvoiceType.Sales,
    location: '',
    page: 0,
    itemsPerPage: 20,
    'currency.code': ''
  };
  confirmedStatus: any [];
  isReceivedStatus: any [];
  stockStatuses = [];
  productionStatuses = [];
  deliveryStatuses = [];
  moreLoading: boolean;
  tableLoading: boolean;
  locations: LocationModel [] = [];
  tabMenuItems: MenuItem[];
  tabMenuActiveItem: MenuItem;
  scrollableItems: MenuItem[];
  errors: any[] = [];
  isSaved: boolean;
  accountingBefore: MenuItem[];
  accountingAfter: MenuItem[];

  constructor(private http: HttpService, private dialogService: DialogService, private route: ActivatedRoute,
              private translate: TranslateService, private utilityService: UtilityService, private currencyService: CurrencyService,
              private dataTableSettingService: DataTableSettingService, private flashMessageService: FlashMessageService) {
  }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.entities.push('invoice');
    this.currency = this.currencyService.getCurrency().code;
    setTimeout(() => {
      this.suppliers = [
        {name: this.translate.instant('ALL_SUPPLIERS'), value: ''}
      ];
      this.accountingBefore = [
        {
          label: this.translate.instant('SEND_TO_ACCOUNTING'), icon: 'pi pi-send', command: (event) => {
            this.sendInvoiceToAccounting(event);
          }
        },
      ];
      this.accountingAfter = [
        {
          label: this.translate.instant('SHOW_ACCOUNTING_INVOICE'), icon: 'pi pi-file-pdf', command: (event) => {
            this.downloadPdf(event);
          }
        },
      ];
    }, 100);
    this.loadLocations();
    this.loadSuppliers();
    this.confirmedStatus = [
      {name: this.translate.instant('ALL'), value: 'CONFIRMED,VIEWED_SUPPLIER,CONFIRMED_SUPPLIER'},
      {name: this.translate.instant('CONFIRMED'), value: 'CONFIRMED'},
      {name: this.translate.instant('VIEWED_SUPPLIER'), value: 'VIEWED_SUPPLIER'},
      {name: this.translate.instant('CONFIRMED_SUPPLIER'), value: 'CONFIRMED_SUPPLIER'}
    ];
    this.isReceivedStatus = [
      {name: this.translate.instant('ALL'), value: ''},
      {name: this.translate.instant('RECEIVE'), value: '0'},
      {name: this.translate.instant('RECEIVED'), value: '1'},
    ];
    this.tabMenuItems = [
      {
        label: this.translate.instant('WAITING_FOR_APPROVAL'), title: 'waiting',
        command: () => {
          this.tabMenuActiveItem = this.tabMenuItems[0];
        }
      },
      {
        label: this.translate.instant('SALES'), title: 'sales',
        command: () => {
          this.tabMenuActiveItem = this.tabMenuItems[1];
        }
      }
    ];

    this.scrollableItems = Array.from({length: 50}, (_, i) => ({label: `Tab ${i + 1}`}));
    this.tabMenuActiveItem = this.tabMenuItems[1];
  }

  load(event: LazyLoadEvent = null, type = null): void {
    this.loading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    this.route.data.subscribe(data => {
      this.parameters.type = data.type;
    });
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.parameters.createdSaleInvoice = false;
    let parameters = {...this.parameters};

    if (this.parameters.expectedDate) {
      const start = new Date(this.parameters.expectedDate[0]);
      const end = new Date(this.parameters.expectedDate[1]);

      parameters = {
        ...parameters, ...{
          'expectedDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'expectedDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parameters.expectedDate;
    }
    if (this.parameters.createdAt) {
      const start = new Date(this.parameters.createdAt[0]);
      const end = new Date(this.parameters.createdAt[1]);

      parameters = {
        ...parameters, ...{
          'createdAt[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'createdAt[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parameters.expectedDate;
    }

    this.tableLoading = true;
    this.items = [];
    this.http.get(InvoiceModel.IRI, parameters).subscribe((response => {
      this.grandTotal = 0;
      this.total = response['hydra:totalItems'];
      response['hydra:member'].map(res => {
        this.grandTotal += res.total;
        this.items.push(res);
      });
      this.totals.total = this.grandTotal;
      this.items.map(((item: InvoiceModel) => {
        this.totalPrice += item.normalizedTotal;
      }));
      this.tableLoading = false;
      this.loading = false;
    }));

    this.http.get(`/api/invoices/totals`, {
      status: 'CONFIRMED,VIEWED_SUPPLIER,CONFIRMED_SUPPLIER',
    }).subscribe((response: any) => {
      this.totals = response;
    });
  }

  loadSales(event: LazyLoadEvent = null, type = null): void {
    this.tableLoading = true;
    this.loading = true;
    if (event) {
      this.parametersSales.page = event.first / this.parametersSales.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parametersSales.itemsPerPage = event.rows;
    }

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parametersSales = Object.fromEntries(Object.entries(this.parametersSales).filter(([_, v]) => v != ''));
    let parametersSales = {...this.parametersSales};

    if (this.parametersSales.expectedDate) {
      const start = new Date(this.parametersSales.expectedDate[0]);
      const end = new Date(this.parametersSales.expectedDate[1]);

      parametersSales = {
        ...parametersSales, ...{
          'expectedDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'expectedDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parametersSales.expectedDate;
    }
    if (this.parametersSales.createdAt) {
      const start = new Date(this.parametersSales.createdAt[0]);
      const end = new Date(this.parametersSales.createdAt[1]);

      parametersSales = {
        ...parametersSales, ...{
          'createdAt[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'createdAt[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parametersSales.expectedDate;
    }
    this.itemsSales = [];
    this.http.get(InvoiceModel.IRI, parametersSales).subscribe((response => {
      this.grandTotal = 0;
      this.totalSales = response['hydra:totalItems'];
      response['hydra:member'].map(res => {
        this.grandTotal += res.total;
        this.itemsSales.push(res);
      });
      this.totals.total = this.grandTotal;
      this.itemsSales.map(((item: InvoiceModel) => {
        this.totalPrice += item.normalizedTotal;
      }));

      this.tableLoading = false;
      this.loading = false;
    }));
  }

  loadSuppliers(): void {
    this.http.get(ContactModel.IRI, {type: 'supplier'}).subscribe((response: ContactModel) => {
      response['hydra:member'].map((items: any) => {
        this.suppliers.push({name: items.name, value: items['@id']});
      });
    });
  }

  loadLocations(): void {
    this.http.get(`${UserModel.IRI}/${this.user.id}`).subscribe(response => {
        response.locations.map(item => {
          this.locations.push(item);
        });
        // @ts-ignore
        this.locations.unshift({name: this.translate.instant('ALL_LOCATIONS'), '@id': ''});
      }
    );
  }

  receive(id: number): void {
    const ref = this.dialogService.open(InvoiceReceiveComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('PURCHASE_RECEIVE'),
    });

    ref.onClose.subscribe(() => {
      this.load();
      this.loadSales();
    });
  }

  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;

    this.http.download('/api/data/exports/invoices', parameters)
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'invoices.xlsx');
      });
  }

  loadWorkOrders(id: number): void {
    this.workOrdersLoading = true;
    this.workOrders = [];
    this.http.get(WorkOrderModel.IRI, {'invoice.id': id}).subscribe((response: WorkOrderModel[]) => {
      this.workOrders = response['hydra:member'];
      this.workOrdersLoading = false;
    });
  }

  openChangeStatusPanel(panel, event, id: number, itemStatus: string): void {
    this.changeStatusItems = [
      // tslint:disable-next-line:max-line-length
      /*
      {
        label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED),
        state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED, itemStatus},
        title: itemStatus
      },
       */
      // tslint:disable-next-line:max-line-length

      {
        label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED),
        state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED}
      },

      // tslint:disable-next-line:max-line-length
      {
        label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED),
        state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED},
        title: itemStatus
      },
    ];

    panel.toggle(event);
  }

  changeStatus(id: number, status: string): any {
    this.loading = true;
    this.http.put(`${InvoiceModel.IRI}/${id}`, {deliveryStatus: status}).then(response => {
      this.load();
      this.loadSales();
    });
  }

  closeCalendar(field: string): void {
    let isClose = true;

    if (field === 'expectedDate') {
      this.parameters.expectedDate.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    } else {
      this.parameters.createdAt.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    }

    if (isClose) {
      this.createdAt.overlayVisible = false;
      this.createdAt.datepickerClick = true;
      this.expectedDate.overlayVisible = false;
      this.expectedDate.datepickerClick = true;
    }
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      this.parameters.type,
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  onReorder(event): void {
    this.http.post('/api/reorder', {drag: this.items[event.dragIndex].id, drop: this.items[event.dropIndex].id});
  }

  sendInvoiceToAccounting(id): void {
    this.errors = [];
    this.isSaved = true;
    this.loading = true;
    this.http.get(`${InvoiceModel.IRI}/${id}/send`).toPromise().then(response => {
      if (response.message.httpStatusCode === 400) {
        this.errors.push({
          status: this.translate.instant('UN_SAVED'),
          message: response.message.message,
        });
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, this.translate.instant('UN_SAVED')));
      } else {
        this.errors = [];
        this.loadSales();
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
      this.isSaved = false;
      this.loading = false;
    });
  }

  downloadPdf(ettn): void {
    console.log(ettn);
    this.http.get('/api/incoming_invoices/e-invoice?location=' +
      this.parameters.location + '&&ettn=' + ettn).subscribe(response => {
      window.location.href = response;
    });
  }
}
