import { Component, OnInit } from '@angular/core';
import {CompanyModel} from '../../../../model/company.model';
import {Router} from '@angular/router';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  locations: LocationModel[] = [];
  location: LocationModel = new LocationModel();
  showForm: boolean;
  loading: boolean;
  constructor(private router: Router, private http: HttpService) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe((response: LocationModel[]) => {
      this.locations = response;
      this.loading = false;
    });

    this.http.get(`${LocationModel.IRI}/location-number`).subscribe((response: LocationModel) => {
      this.location.code = response.code;
    });
  }

  nextPage(): void {
    this.router.navigate(['initial-setup/add-unit']).then(r => {
    });
  }

  previousPage(): void {
    this.router.navigate(['initial-setup/company-information']).then(r => {
    });
  }

  openForm(): void {
    this.showForm = true;
  }
  hideForm(): void {
    this.showForm = false;
    this.location = new LocationModel();
  }
  async save(): Promise<void> {

    await this.http.post(LocationModel.IRI, this.location).then((response: LocationModel) => {
      if (response) {
        this.locations.push(response);
        this.hideForm();
      }
    });
  }

}
