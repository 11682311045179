import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {DeliveryActions, InvoiceItemModel} from '../../../model/invoice-item.model';
import {CurrencyService} from '../../../service/currency.service';
import {
  InvoiceDeliveryStatus,
  InvoiceModel,
  InvoiceProductionStatus,
  InvoiceStatus,
  InvoiceStockStatus
} from '../../../model/invoice.model';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceReceiveComponent} from '../invoice-receive/invoice-receive.component';
import {DialogService} from 'primeng/dynamicdialog';
import {UserModel} from '../../../model/user.model';
import {DataTableSettingService} from '../../../service/data-table-setting.service';

@Component({
  selector: 'app-invoice-detailed-list',
  templateUrl: './invoice-detailed-list.component.html',
  styleUrls: ['./invoice-detailed-list.component.scss']
})
export class InvoiceDetailedListComponent implements OnInit {

  items: InvoiceItemModel[] = [];
  loading: boolean;
  total: number;
  currency: string;
  user: UserModel = new UserModel();
  settings = [];

  parameters = {
    'invoice.code': '',
    'contact.name': '',
    'variant.name': '',
    'invoice.total': '',
    'invoice.expectedDate': [],
    'invoice.status': InvoiceStatus.Confirmed,
    materialStatus: '',
    'stock.tags': '',
    status: '',
    'invoice.isReceived': '',
    productionStatus: '',
    'invoice.type': 'sales',
    page: 1,
    quantity: '',
    remainingQuantity: '',
    subtotal: '',
    tax: '',
    total: '',
    itemsPerPage: 20,
    'invoice.currency.code': '',
    'invoice.contact.name': '',
    deliveryStatus: [
      InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING,
      InvoiceDeliveryStatus.DELIVERY_STATUS_READY,
      InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED,
      InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED,
      InvoiceStatus.Pending
    ].join(',')
  };

  deliveryActions: MenuItem[] = [];
  deliveryActionsConstants = DeliveryActions;

  constructor(private http: HttpService, private currencyService: CurrencyService, private translate: TranslateService,
              private dialogService: DialogService, private dataTableSettingService: DataTableSettingService) { }

  id: number;

  stockStatuses = [];
  productionStatuses = [];
  deliveryStatuses = [];

  totals = {
    total: 0,
    tax: 0,
    subtotal: 0,
    remainingSubtotal: 0,
    remainingTax: 0,
    remainingTotal: 0,
  };

  ngOnInit(): void {

    setTimeout(() => {

      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user) {
        this.settings = this.user.dataTableSettings;
      }

      this.stockStatuses = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceStockStatus.StatusAvailable.toString()), value: InvoiceStockStatus.StatusAvailable},
        {name: this.translate.instant(InvoiceStockStatus.StatusNotAvailable.toString()), value: InvoiceStockStatus.StatusNotAvailable},
        {name: this.translate.instant(InvoiceStockStatus.StatusExpected.toString()), value: InvoiceStockStatus.StatusExpected},
      ];

      this.productionStatuses = [
        {name: this.translate.instant('ALL'), value: ''},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Not_Started), value: InvoiceProductionStatus.Status_Not_Started},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Started), value: InvoiceProductionStatus.Status_Started},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Paused), value: InvoiceProductionStatus.Status_Paused},
        {name: this.translate.instant(InvoiceProductionStatus.Status_Finished), value: InvoiceProductionStatus.Status_Finished},
      ];

      this.deliveryStatuses = [
        {name: this.translate.instant('ALL'), value: [
            InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING,
            InvoiceDeliveryStatus.DELIVERY_STATUS_READY,
            InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED,
            InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED,
            InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED,
          ].join(',')},
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_READY), value: InvoiceDeliveryStatus.DELIVERY_STATUS_READY},
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING), value: InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING},
        // tslint:disable-next-line:max-line-length
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED), value: InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED},
        // tslint:disable-next-line:max-line-length
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED), value: InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED},
        // tslint:disable-next-line:max-line-length
        {name: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED), value: InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED}
      ];
    }, 500);

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    this.currency = this.currencyService.getCurrency().code;

    this.deliveryActions = [
      {
        label: this.translate.instant('PACKAGED'),
        command: () => {
          this.changeStatus(this.id, 'packaged');
        }
      },
      {
        label: this.translate.instant('PARTIAL_DELIVERED'),
      },
      {
        label: this.translate.instant('DELIVERED'),
        command: () => {
          this.changeStatus(this.id, 'packaged');
        }
      },
    ];
  }

  load(event = null): void {
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    this.loading = true;
    this.http.get(InvoiceItemModel.IRI, this.parameters).subscribe((response) => {
      this.items = response['hydra:member'];
      this.total = response['hydra:totalItems'];
      this.loading = false;
    });

    this.http.get(`/api/invoice_items/totals`, this.parameters).subscribe((response: any) => {
      this.totals = response;
    });
  }

  openChangeStatusPanel(panel, event, id: number, invoiceId: number): void {
    this.deliveryActions = [
      {label: this.translate.instant(DeliveryActions.PACKAGED), state: {id, status: DeliveryActions.PACKAGED}},
      // tslint:disable-next-line:max-line-length
      {label: this.translate.instant(DeliveryActions.PARTIAL_DELIVERED), state: {id: invoiceId, itemId: id, status: DeliveryActions.PARTIAL_DELIVERED}},
      {label: this.translate.instant(DeliveryActions.DELIVERED), state: {id, status: DeliveryActions.DELIVERED}},
    ];

    panel.toggle(event);
  }

  changeStatus(id: number, status: string): any {
    this.loading = true;
    this.http.put(`${InvoiceItemModel.IRI}/${id}`, {deliveryStatus: status}).then(response => {
      this.load();
    });
  }

  receive(id: number, itemId: number): void {
    const ref = this.dialogService.open(InvoiceReceiveComponent, {
      data: {id, itemId},
      width: '50vw',
      header: this.translate.instant('PURCHASE_RECEIVE'),
    });

    ref.onClose.subscribe(() => {
      this.load();
    });
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      'invoiceDetailedList',
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }
}
