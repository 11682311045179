import { Component, OnInit } from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../../service/http.service';
import {ContactService} from '../../../service/contact.service';
import {CurrencyService} from '../../../service/currency.service';
import {LazyLoadEvent} from 'primeng/api';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';

@Component({
  selector: 'app-orders-by-supplier',
  templateUrl: './orders-by-supplier.component.html',
  styleUrls: ['./orders-by-supplier.component.scss']
})
export class OrdersBySupplierComponent implements OnInit {

  loading: boolean;
  suppliers: ContactModel[] = [];
  parameters = {
    name: '',
    'invoice.type': 'purchase',
    'invoice.isReceived': true,
    'supplier.id': '',
    page: 0,
    itemsPerPage: 2000
  };
  type: string;
  searchContacts: ContactModel[] = [];
  tableLoading: boolean;
  staticsDisplay: boolean;
  data: any;
  chartOptions: any;
  stockName: string;
  currencyLabel: string;
  invoicesTotal: number[] = [];
  countInvoices: [];
  constructor(private translate: TranslateService, private http: HttpService, private contactService: ContactService,
              private currencyService: CurrencyService) { }

  ngOnInit(): void {
    this.load();
  }

  load(event: LazyLoadEvent = null, type = null): void{
    this.loading = true;
    if (type !== 'search'){
      this.parameters.page = this.parameters.page + 1;
    }else {
      this.suppliers = [];
      this.parameters.page = 1;
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get(ContactModel.IRI, this.parameters).subscribe(response => {
      this.suppliers = response['hydra:member'];
      this.loading = false;
    });
    this.searchContacts = this.contactService.getSearchContacts();
  }
  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }
  changeContact(event): void {
    this.parameters['supplier.id'] = event.value;
    this.suppliers = [];
  }

  incoiceTotalCalculator(invoices): number {
    if (invoices !== undefined){
      let total = 0;
      invoices.map(item => {
        total += item.total;
      });
      return total;
    }else {
      return 0;
    }
  }
}
