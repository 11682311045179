<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'DAILY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'UNPAID_REPORT'"></app-page-info>
      <p-button icon="pi pi-file-excel" [style]="{marginLeft: '5px', float:'right'}" (onClick)="export()"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id"
        [filter]="true" filterBy="name" placeholder="{{ 'SELECT_LOCATION' | translate }}">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar dateFormat="yy-mm-dd" [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="date" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <app-report-tabs pageName="unpaid-report"></app-report-tabs>
  <app-unpaid-tab page="group"></app-unpaid-tab>
  <div class="full-div" *ngIf="!menuItem.length">
    <h5> {{ 'NO_RECORDS_FOUND'| translate}}</h5>
  </div>
  <div class="p-grid" id="pdf-content">
    <div class="p-col-12" *ngIf="menuItem.length > 0">
      <p-table [value]="menuItem" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '42%'}">{{ 'PRODUCT' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center'}">{{ 'SALE_QUANTITY' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center'}">{{ 'SALE_QUANTITY_RANK' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center'}">{{ 'GROSS_SALE_QUANTITY' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center', color: 'red'}">{{ 'DISCOUNT' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center'}">{{ 'TOTAL' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center'}">{{'NET_INCOME' | translate}}</th>
            <th [style]="{width: '12%', textAlign:'center'}">{{ 'RETURN_QUANTITY' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cat>
          <tr [style]="{background: 'rgba(230, 247, 250, 0.27)'}">
            <td [style]="{width: '42%'}"><b>{{cat.name}}</b></td>
            <td [style]="{width: '12%', textAlign:'center'}">{{cat.amountByName?.quantity | number: '1.1-2'}}</td>
            <td [style]="{width: '12%', textAlign:'left'}">{{totalQuantity > 0 ? ((cat.amountByName?.quantity / totalQuantity) * 100  | number: '1.1-2') : 0}}%</td>
            <td
              [style]="{width: '12%', textAlign:'right'}">{{cat.amountByName?.subTotal| number: '1.1-2'}}{{info.currency}}</td>
            <td [style]="{width: '12%', textAlign:'right', color: 'red'}">{{cat.amountByName?.discount | number: '1.1-2'}}{{info.currency}}</td>
            <td [style]="{width: '12%', textAlign:'right'}">{{cat.amountByName?.subTotal - cat.amountByName?.discount | number: '1.1-2'}}{{info.currency}}</td>
            <td
              [style]="{width: '12%', textAlign:'right'}">{{cat.amountByName?.subTotal - cat.amountByName?.tax - cat.amountByName?.discount| number: '1.1-2'}}{{info.currency}}</td>
            <td
              [style]="{width: '12%', textAlign:'right'}">{{ 0 | number: '1.1-2'}}{{info.currency}}</td>
          </tr>
          <tr>
            <td colspan="8" style="padding: 0" >
              <p-table [value]="cat.subCategories" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                <ng-template pTemplate="body" let-sub>
                  <tr [style]="{cursor: 'pointed'}">
                    <td [style]="{width: '42%', textAlign:'left'}"><b [style]="{marginLeft:'10px'}">{{sub.name}}</b></td>

                    <td [style]="{width: '12%', textAlign:'center'}">{{sub.productGroup?.quantity | number: '1.1-2'}}</td>
                    <td [style]="{width: '12%', textAlign:'left'}" >{{cat.amountByName.quantity > 0 ? ((sub.productGroup?.quantity / cat.amountByName?.quantity)*100  | number: '1.1-2') : 0}}%  </td>
                    <td
                      [style]="{width: '12%', textAlign:'right'}">{{sub.productGroup?.subTotal | number: '1.1-2'}}{{info.currency}}</td>
                    <td [style]="{width: '12%', textAlign:'right', color: 'red'}">{{sub.productGroup?.discount | number: '1.1-2'}}{{info.currency}}</td>
                    <td [style]="{width: '12%', textAlign:'right'}">{{sub.productGroup?.subTotal - sub.productGroup?.discount  | number: '1.1-2'}}{{info.currency}}</td>
                    <td
                      [style]="{width: '12%', textAlign:'right'}">{{sub.productGroup?.total | number: '1.1-2'}}{{info.currency}}</td>
                    <td
                      [style]="{width: '12%', textAlign:'right'}">{{ 0 | number: '1.1-2'}}{{info.currency}}</td>

                  </tr>
                  <tr *ngIf="sub.subCategories">
                    <td colspan="8" style="padding: 0">
                      <p-table [value]="sub.subCategories" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                        <ng-template pTemplate="body" let-row>
                          <tr [style]="{cursor: 'pointed'}">
                            <!--<td [style]="{width: '4%', textAlign:'left'}">{{row.code}}</td>--->
                            <td [style]="{width: '42%', textAlign:'left'}"><b [style]="{marginLeft:'20px'}"> - {{row.name}}</b></td>
                            <td [style]="{width: '12%', textAlign:'center'}">{{row.productGroup?.quantity | number: '1.1-2'}}</td>
                            <td [style]="{width: '12%', textAlign:'left'}" >{{sub.productGroup.quantity > 0 ? ((row.productGroup?.quantity / sub.productGroup?.quantity)*100  | number: '1.1-2') : 0}}%  </td>
                            <td
                              [style]="{width: '12%', textAlign:'right'}">{{row.productGroup?.subTotal  | number: '1.1-2'}}{{info.currency}}</td>
                            <td
                              [style]="{width: '12%', textAlign:'right', color: 'red'}">{{row.productGroup?.discount | number: '1.1-2'}}{{info.currency}}</td>
                            <td [style]="{width: '12%', textAlign:'right'}">{{row.productGroup?.subTotal - row.productGroup?.discount | number: '1.1-2'}}{{info.currency}}</td>
                            <td
                              [style]="{width: '12%', textAlign:'right'}">{{row.productGroup?.total | number: '1.1-2'}}{{info.currency}}</td>
                            <td
                              [style]="{width: '12%', textAlign:'right'}">{{ 0 | number: '1.1-2'}}{{info.currency}}</td>

                          </tr>
                          <tr>
                            <td colspan="9" style="padding: 0">
                              <p-table [value]="row.menuItem" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                                <ng-template pTemplate="body" let-item>
                                  <tr [style]="{cursor: 'pointed'}">
                                    <td [style]="{width: '28%', textAlign:'left'}">{{item.name}}</td>
                                    <td [style]="{width: '8%', textAlign:'center'}">{{item.productGroup?.quantity | number: '1.1-2'}}</td>
                                    <td [style]="{width: '12%', textAlign:'left'}" >{{row.productGroup.quantity > 0 ? ((item.productGroup?.quantity / row.productGroup?.quantity)*100  | number: '1.1-2') : 0}}%  </td>
                                    <td
                                      [style]="{width: '8%', textAlign:'right'}">{{item.productGroup?.subTotal | number: '1.1-2'}}{{info.currency}}</td>
                                    <td
                                      [style]="{width: '8%', textAlign:'right', color: 'red'}">{{item.productGroup?.discount | number: '1.1-2'}}{{info.currency}}</td>
                                    <td
                                      [style]="{width: '8%', textAlign:'right'}">{{item.productGroup?.subTotal - item.productGroup?.discount | number: '1.1-2'}}{{info.currency}}</td>
                                    <td
                                      [style]="{width: '8%', textAlign:'right'}">{{item.productGroup?.subTotal - item.productGroup?.discount - item.productGroup?.taxTotal | number: '1.1-2'}}{{info.currency}}</td>
                                    <td
                                      [style]="{width: '8%', textAlign:'right'}">{{ 0 | number: '1.1-2'}}{{info.currency}}</td>
                                  </tr>
                                </ng-template>
                              </p-table>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </td>
                  </tr>
                  <tr *ngIf="!sub.subCategories">
                    <td colspan="8" style="padding: 0">
                      <p-table [value]="sub.menuItem" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                        <ng-template pTemplate="body" let-row>
                          <tr [style]="{cursor: 'pointed'}">
                            <td [style]="{width: '42%', textAlign:'left'}"><span [style]="{marginLeft:'10px'}">{{row.name}}</span></td>

                            <td [style]="{width: '12%', textAlign:'center'}">{{row.productGroup?.quantity | number: '1.1-2'}}</td>
                            <td [style]="{width: '12%', textAlign:'left'}" >{{sub.productGroup.quantity > 0 ? ((row.productGroup?.quantity / sub.productGroup?.quantity)*100  | number: '1.1-2') : 0}}%  </td>
                            <td
                              [style]="{width: '12%', textAlign:'right'}">{{row.productGroup?.subTotal | number: '1.1-2'}}{{info.currency}}</td>
                            <td [style]="{width: '12%', textAlign:'right', color: 'red'}">{{row.productGroup?.discount | number: '1.1-2'}}{{info.currency}}</td>
                            <td [style]="{width: '12%', textAlign:'right'}">{{row.productGroup?.subTotal - row.productGroup?.discount  | number: '1.1-2'}}{{info.currency}}</td>
                            <td
                              [style]="{width: '12%', textAlign:'right'}">{{row.productGroup?.total | number: '1.1-2'}}{{info.currency}}</td>
                            <td
                              [style]="{width: '12%', textAlign:'right'}">{{ 0 | number: '1.1-2'}}{{info.currency}}</td>

                          </tr>
                        </ng-template>
                      </p-table>
                    </td>
                  </tr>

                </ng-template>
              </p-table>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

