<p-card xmlns="http://www.w3.org/1999/html">
  <div class="PageHead">
    <div class="Tools">
      <form action="" (ngSubmit)="load()" (submit)="load()">
      <div class="FormGroup">
        <input type="text" pInputText [(ngModel)]="parameters.name" name="name" placeholder="Arama (e.g. name / code)">
      </div>
      <div class="FormGroup">
        <p-dropdown  [(ngModel)]="parameters['category.id']" placeholder="Select a Category" (onChange)="changeParameters($event,'category')" [options]="categories"  optionLabel="name" optionValue="@id" [showClear]="true"></p-dropdown>
      </div>
      <div class="FormGroup">
        <p-dropdown appendTo="body" [(ngModel)]="parameters['supplier.id']" placeholder="Select a Supplier"(onChange)="changeParameters($event,'supplier')"  [options]="suppliers"  optionLabel="name" optionValue="@id" [showClear]="true"></p-dropdown>
      </div>
      <div class="FormGroup">
        <p-dropdown  [(ngModel)]="parameters['locations.id']" placeholder="Select a Location"(onChange)="changeParameters($event,'locations')"  [options]="locations" optionLabel="name" optionValue="@id" [showClear]="true"></p-dropdown>
      </div>
        <button type="submit" class="p-d-none" pButton label="{{'stock.search' | translate}}" (click)="load()"></button>
      </form>
    </div>
    <div class="ToolsOposite">
      <div class="FormGroup">
        <p-button icon="pi pi-arrow-down"></p-button>
      </div>
      <div class="FormGroup">
        <p-splitButton label="{{ ' ADD' | translate}}" icon="pi pi-plus"  [model]="menuItems" styleClass="p-button-success mb-2"></p-splitButton>
      </div>
    </div>
  </div>
  <div>
    <form action="" (ngSubmit)="load()" (submit)="load()">
      <p-table [loading]="loading" [lazy]="true" [value]="items" [totalRecords]="total" (onLazyLoad)="load($event)" [rowsPerPageOptions]="[10,25,50]"
               [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true" columnResizeMode="expand"
               styleClass="p-datatable-striped" (onColResize)="onResize($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: settings|columnWidth: 'stocks': '0'}" pResizableColumn>{{'STOCK' | translate}} <app-tooltip description="PRODUCTS_NAME_TOOLTIP"></app-tooltip> </th>
            <th [style]="{width: settings|columnWidth: 'stocks': '1'}" pResizableColumn>{{'SKU' | translate}} <app-tooltip description="PRODUCTS_SKU_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '2'}" pResizableColumn>{{'SUPPLIER' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '3'}" pResizableColumn>{{'CATEGORY' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '4'}" pResizableColumn>{{'TAX' | translate}} <app-tooltip description="PRODUCTS_TAX_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '5'}" pResizableColumn>{{'PRICE' | translate}} <app-tooltip description="PRODUCTS_AVERAGE_COST_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '6'}" pResizableColumn>{{'UNIT' | translate}} <app-tooltip description="PRODUCTS_CURRENCY_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '7'}" pResizableColumn>{{'ALERT_LEVEL' | translate}} <app-tooltip description="ALERT_LEVEL_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '8'}" pResizableColumn>{{'MIN_ORDER' | translate}} <app-tooltip description="MIN_ORDER_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '9'}" pResizableColumn>{{'IN_STOCK' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: settings|columnWidth: 'stocks': '10'}" pResizableColumn>{{'IN_STOCK_VALUE' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td><a href="javascript:void(0)" (click)="edit(row)">{{ row.name }}</a></td>
            <td>{{ row.variants[0].code }}</td>
            <td>{{row.purchaseOptions?.length > 0 ? 'Multi' : row.supplier?.name  }} </td>
            <td>{{ row.category?.parent?.name }} {{ row.category?.parent ? '/' : '' }} {{ row.category?.name }}</td>
            <td>{{ row.tax ? row.tax['name'] : '-' }}</td>
            <td>{{ row.variants[0].price }}</td>
            <td>{{ row.unit?.name }}</td>
            <td>{{ row.variants[0].alertLevel }} {{ row.unit?.name }}</td>
            <td>{{ row.variants[0].minOrder }} {{ row.unit?.name }}</td>
            <td>{{ row.variants[0].amount }}</td>
            <td>{{ row.variants[0].amount * row.variants[0].price }}</td>
          </tr>
        </ng-template>
      </p-table>
    </form>
  </div>
</p-card>


<p-sidebar [(visible)]="display" [style]="{width:'80em'}" position="right" (onHide)="reload()">
  <app-new-material-update *ngIf="displayUpdate" [item]="item" [loading]="true" [type]="type"></app-new-material-update>
  <app-new-material-create *ngIf="displayCreate"></app-new-material-create>
</p-sidebar>



