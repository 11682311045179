<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ (invoice.type == invoiceTypes.Sales ? (invoice.status === invoiceStatus.Confirmed ? 'CREATE_INVOICE' : 'CREATE_QUOTE') : 'CREATE_PURCHASE') | translate}}</h3>


    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEMS'"></app-page-info>
      <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [style]="{marginLeft: '5px', float:'right'}"
              [disabled]="this.submitted" (click)="save()">
      </button>
    </div>
  </div><!---
  <p-splitButton *ngIf="id && !loading" [label]="'PRINT'|translate" [style]="{float: 'right'}"
                 [icon]="printing ? 'pi pi-spin pi-spinner': 'pi pi-print'" (onClick)="print(1)" [model]="printItems"
                 styleClass="p-button-secondary p-mb-2 p-float-right">

  </p-splitButton>
  <p-button *ngIf="id && !loading && invoice.type === invoiceTypes.Purchase"
            styleClass="p-button-secondary p-mb-2 p-mr-2" style="float:right;" type="button"
            [disabled]="invoice.isReceived" (click)="receive()"
            [label]="(invoice.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></p-button>

    <p-button *ngIf="id && !loading" [label]="'DELETE'|translate" [style]="{float: 'right'}"
            [icon]="deleting ? 'pi pi-spin pi-spinner': 'pi pi-trash'" (onClick)="deleteInvoice()"
            styleClass="p-button-danger p-mb-2 p-mr-2 p-float-right"></p-button>
  <p-button *ngIf="id && !loading && billable && invoice.type === invoiceTypes.Sales"
            [label]="(invoice.isInvoiced ? 'IS_ACCOUNTED' : 'SEND_TO_ACCOUNTING')|translate" [style]="{float: 'right'}"
            [disabled]="sendingBill || invoice.isInvoiced"
            [icon]="sendingBill ? 'pi pi-spin pi-spinner' : (invoice.isInvoiced ? 'pi pi-check' : 'pi pi-money-bill')"
            (onClick)="sendAccounting()" styleClass="p-button-secondary p-mb-2 p-float-right p-mr-2"></p-button>

  <p-splitter [panelSizes]="[30,70]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ (invoice.type == invoiceTypes.Sales ? (invoice.status === invoiceStatus.Confirmed ? 'CREATE_INVOICE' : 'CREATE_QUOTE') : 'CREATE_PURCHASE') | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">
        {{ (invoice.type == invoiceTypes.Sales ? (invoice.status === invoiceStatus.Confirmed ? 'CREATE_INVOICE_DESCRIPTION' : 'CREATE_QUOTE_DESCRIPTION') : 'CREATE_PURCHASE_DESCRIPTION') | translate}}
      </p>
    </ng-template>
  </p-splitter>
   --->
  <div *ngIf="!loading">
    <span class="p-buttonset p-mb-5 p-d-inline-block" *ngIf="invoice.status === 'WAITING_FOR_APPROVAL'">
      <button class="p-button-secondary" pButton label="{{ 'CONFIRM' | translate}}"
              [icon]="confirmLoading && status === invoiceStatus.Confirmed ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              [disabled]="!this.confirmLoading" (click)="confirm(invoiceStatus.Confirmed)"></button>
      <button class="p-button-danger" pButton label="{{ 'DECLINE' | translate}}"
              [icon]="confirmLoading && status === invoiceStatus.Declined ? 'pi pi-spin pi-spinner' : 'pi pi-ban'"
              [disabled]="!this.confirmLoading" (click)="confirm(invoiceStatus.Declined)"></button>
    </span>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label
          for="code">{{ (invoice.status === invoiceStatus.Pending ? 'QUOTE_NUMBER' : 'ORDER_NUMBER')|translate}}</label>
        <input id="code" name="code" type="text" pInputText [(ngModel)]="invoice.code" [disabled]="invoice.isReceived"
               autofocus>
        <small class="p-error">{{ errors['code'] }}</small>
      </div>
      <div class="p-field p-col-4">
        <label for="supplier">{{ 'DEADLINE' | translate}}</label>
        <p-calendar [(ngModel)]="invoice.expectedDate" [disabled]="invoice.isReceived"></p-calendar>
        <small class="p-error">{{ errors['expectedDate'] }}</small>
      </div>

    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="tags">{{ 'TAGS'|translate}}</label>
        <input id="tags" name="tags" type="text" pInputText [(ngModel)]="invoice.tags" [disabled]="invoice.isReceived">
      </div>
      <div class="p-field p-col-4">
        <label for="location">{{ 'LOCATION' | translate}}</label>
        <p-dropdown id="location" [options]="locations" [(ngModel)]="invoice.location" [disabled]="invoice.isReceived"
                    optionLabel="name" optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['location'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="supplier"
               class="p-pb-2">{{ (invoice.type == invoiceTypes.Sales ? 'CUSTOMER' : 'SUPPLIER')|translate }} <a
          class="p-d-inline-block p-p-0" style="color: #2196F3"
          (click)="setManualCustomer()">({{ (!manualCustomer ? 'WRITE_MANUAL' : 'SELECT_FROM_LIST')|translate }}
          )</a></label>
        <div class="p-grid">
          <div class="p-col-11">
            <p-dropdown [options]="contacts" *ngIf="!manualCustomer" filter="true"
                        [placeholder]="'CONTACT_PLACEHOLDER'|translate" [required]="false" [(ngModel)]="invoice.contact"
                        optionLabel="name" id="supplier" (onChange)="onContactsSelect($event)"
                        [disabled]="invoice.isReceived" optionValue="@id">

            </p-dropdown>
            <input id="customerName" name="customerName" *ngIf="manualCustomer" type="text" pInputText
                   [(ngModel)]="invoice.customerName" [disabled]="invoice.isReceived" autofocus>
          </div>
          <div class="p-col-1">
            <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addContact()"
                      icon="pi pi-plus"></p-button>
          </div>
        </div>
      </div>
      <div class="p-field p-col-4">
        <label for="currency">{{ 'CURRENCY' | translate}}</label>
        <p-dropdown id="currency" [options]="currencies" [(ngModel)]="invoice.currency" (onChange)="onCurrencyChange()"
                    [disabled]="invoice.isReceived" optionLabel="code" optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['currency'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid" *ngIf="invoice.type === invoiceTypes.Sales">
      <div class="p-field p-col-4">
        <p-button [label]="(shippingAddressString ? shippingAddressString : 'SHIPPING_ADDRESS')|translate"
                  class="p-d-inline-block" icon="pi pi-map-marker" styleClass="p-button-text p-button-secondary"
                  (onClick)="openAddressDialog('shipping')"></p-button>
      </div>
    </div>
    <!---
    <div class="p-fluid p-formgrid p-grid" *ngIf="invoice.type === invoiceTypes.Sales">
      <div class="p-field p-col-8"></div>
      <div class="p-field p-col-4">
        <label for="currency">{{ 'TRACKING_INFO' | translate}}</label>
        <br>
        <p-overlayPanel #op [showCloseIcon]="true"
                        (onHide)="shipmentText = (invoice.shipmentCode ? invoice.shipmentCode : '') + (invoice.shipmentLink ? ' - ' + invoice.shipmentLink : '' )">
          <ng-template pTemplate>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="shipmentCode">{{ 'SHIPMENT_CODE' | translate}}</label>
                <input id="shipmentCode" name="shipmentCode" type="text" pInputText [(ngModel)]="invoice.shipmentCode"/>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="shipmentLink">{{ 'SHIPMENT_LINK' | translate}}</label>
                <input id="shipmentLink" type="text" pInputText [(ngModel)]="invoice.shipmentLink"/>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
        <p-button [label]="shipmentText" class="p-d-inline-block" icon="pi pi-pencil"
                  styleClass="p-button-text p-button-secondary" (click)="op.toggle($event)"></p-button>
      </div>
    </div>
    --->
    <p-table styleClass="p-datatable-striped" [value]="invoice.items" dataKey="id" responsiveLayout="scroll"
    [loading]="tableLoading">
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: '25%'}">{{ 'STOCK' | translate}}
            <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip>
          </th>
          <th [style]="{width: '13%'}">{{ 'ON_HAND_QUANTITY' | translate}}
            <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip>
          </th>
          <th [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}
            <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip>
          </th>
          <th [style]="{width: '10%'}">{{ 'UNIT_PRICE' | translate}}
            <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
          </th>
          <th [style]="{width: '10%'}">{{ 'TOTAL_PRICE' | translate}}
            <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip>
          </th>
          <th [style]="{width: '10%'}">{{ 'TAX' | translate}}
            <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip>
          </th>
          <th [style]="{width: '15%'}" *ngIf="invoice.type == invoiceTypes.Sales">{{ 'STOCK_STATUS' | translate}}
            <app-tooltip [description]="'TOOLTIP_STOCK_STATUS'"></app-tooltip>
          </th>
          <!---
          <th [style]="{width: '20%'}" *ngIf="invoice.type == invoiceTypes.Sales">{{ 'MATERIAL_STATUS' | translate}}
            <app-tooltip [description]="'TOOLTIP_MATERIAL_STATUS'"></app-tooltip>
          </th>--->
          <th [style]="{width: '5%'}"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1"
              class="{{ hasErrors('variant', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete [(ngModel)]="autoCompleteValues[i]" [disabled]="item.isReceived"
                                (onSelect)="onVariantSelect($event, i); setStatus(item.variant, item.quantity, i)"
                                field="name" [suggestions]="variants"
                                (completeMethod)="searchVariant($event)"></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ autoCompleteValues[i] ? autoCompleteValues[i].name : '' }}
                <!--
                <a *ngIf="autoCompleteValues[i] && autoCompleteValues[i].stock"
                   [routerLink]="'/stocks/' + autoCompleteValues[i]?.stock?.id + '/edit'"><i class="pi pi-link"></i></a>
                   -->
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ item.locationQuantity}}</td>
          <td pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity"
                               [min]="item.incomingQuantity" [disabled]="item.isReceived"
                               (ngModelChange)=" setStatus(item.variant, item.quantity, i); calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.quantity}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.unitPrice" mode="decimal" minFractionDigits="2" maxFractionDigits="2"
                               [disabled]="item.isReceived || discountLoading"
                               (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{item.unitPrice|currency: currencyLabel: ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{item.subtotal|currency: currencyLabel: ''}}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [options]="taxRates" [(ngModel)]="item.taxRate"
                            (onChange)="calculateTotalPrice(i); setTax(i, $event)" optionLabel="name" id="tax"
                            optionValue="@id"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{taxes.length > i ? taxes[i]?.name : ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td *ngIf="invoice.type == invoiceTypes.Sales">
            <p-tag [severity]="item.status|statusLabel" *ngIf="item.status !== undefined"
                   class="status">{{ item.status|status:item.expectedDate }}</p-tag>
          </td>
          <!---
          <td *ngIf="invoice.type == invoiceTypes.Sales">
            <p-tag [severity]="item.materialStatus|statusLabel" *ngIf="item.materialStatus !== undefined"
                   severity="success" class="status">{{ item.materialStatus|status: item.stockExpectedDate }}</p-tag>
          </td>
          --->
          <td align="right" [style]="{textAlign: 'right'}">
            <button [disabled]="item.isReceived || item.isWorkOrderCreated" pButton label="" icon="pi pi-trash"
                    class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-fluid  p-pt-3">
      <!---
      <button *ngIf="invoice.type === invoiceTypes.Sales" pButton [label]="'CREATE_PRODUCT'|translate"
              style="width: 150px; display: block; padding: 0 0 10px 0; text-align: left" class="p-button-text"
              (click)="createProduct()" iconPos="left"></button>
              --->
      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addItem()" iconPos="left"
              [disabled]="invoice.isReceived"></button>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="comment"></label>
        <p-editor id="comment" [(ngModel)]="invoice.description" [style]="{'height':'120px'}"></p-editor>
      </div>
      <div class="p-field p-col-4">
        <table style="width: 100%" class="total-box p-p-3">
        <tr>
          <th>{{ 'SUBTOTAL' | translate}}</th>
          <td>{{ invoice.subtotal|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th>{{ 'TAX' | translate}}</th>
          <td>{{ invoice.tax|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th>{{ 'TOTAL' | translate}}</th>
          <td>{{ invoice.total|currency: currencyLabel }}</td>
        </tr>
      </table>
      </div>
    </div>

  </div>
</p-card>

<p-dialog [(visible)]="deliverAddressDisplay" [style]="{width: '60vw'}">
  <div class="p-grid">
    <div class="col-md-12"></div>
    <div class="col-md-12">
      <p-table [value]="selectedContact?.deliveryAddresses" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:20%">{{'NAME' | translate}}</th>
            <th style="width:70%">{{'ADDRESS' | translate}}</th>
            <th style="width:10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="product">
            <td>{{product.name}}</td>
            <td>{{product.address }}</td>
            <td>
              <div class="flex align-items-center justify-content-center gap-2">
                <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onContactsDeliveryAddressSelect(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>
