import {Component, OnInit, ElementRef, Renderer2} from '@angular/core';
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {WorkOrderModel} from '../../../model/work-order.model';
import {HttpService} from '../../../service/http.service';
import {DatePipe} from '@angular/common';
import {SidebarService} from '../../../service/sidebar.service';


@Component({
  selector: 'app-work-order-dashboard',
  templateUrl: './work-order-dashboard.component.html',
  styleUrls: ['./work-order-dashboard.component.scss']
})
export class WorkOrderDashboardComponent implements OnInit {
  loading: boolean;
  locale = 'es';
  parameters = {
    code: '',
    'variant.name': '',
    plannedQuantity: '',
    plannedTime: '',
    status: '',
    materialStatus: '',
    deadline: [],
    pagination: false,
    'exists[parent]': false,
    planning: false,
    itemsPerPage: 200,
    'invoice.contact.name': ''
  };
  options: any;
  events: any[] = [];
  showCalendar: boolean;
  indexNumber: number;
  pending: number;
  started: number;
  paused: number;
  finished: number;
  newWorkOrder: boolean;
  data: any[] = [];
  constructor(private http: HttpService,
              private datePipe: DatePipe,
              private elementRef: ElementRef,
              private renderer: Renderer2, private sidebarService: SidebarService) {
    const name = Calendar.name; // add this line in your constructor
  }

  ngOnInit(): void {
    this.sidebarService.getDisplay().subscribe((display) => {
      this.newWorkOrder = display === true ? false : false;
      this.load().then();
    });
    this.newWorkOrder = false;
    this.load().then();

  }
  async load(): Promise<any> {
    this.loading = true;
    this.pending = 0;
    this.started = 0;
    this.paused = 0;
    this.finished = 0;


    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.parameters.planning = false;
    this.parameters.pagination = false;

    let parameters = {...this.parameters};

    if (this.parameters.deadline) {
      const start = new Date(this.parameters.deadline[0]);
      const end = new Date(this.parameters.deadline[1]);

      parameters = {...parameters, ...{
          'deadline[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'deadline[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }};

      delete parameters.deadline;
    }

    await this.http.get(WorkOrderModel.IRI, parameters).subscribe((response: WorkOrderModel[]) => {
      this.events = response['hydra:member'].map((item, i ) => ({
        title: item.code + ' / ' + item.stock.name + ' / ' + item.plannedQuantity ,
        id: item.id,
        date: this.datePipe.transform(item.deadline, 'yyyy-MM-dd'),
        editable: item.status === 'NOT_STARTED',
        droppable: item.status === 'NOT_STARTED',
        backgroundColor: item.status === 'FINISHED' ? '#219d0e' : item.status === 'NOT_STARTED' ? '#8a8a8a' : item.status === 'PAUSED' ? '#ecc424' : '#0daeca'
      }));
      response['hydra:member'].map((value, i ) => {
        if ( value.status === 'NOT_STARTED'){
          this.pending = this.pending + 1;
        }
        if ( value.status === 'STARTED'){
          this.started = this.started + 1;
        }
        if ( value.status === 'PAUSED'){
          this.paused = this.paused + 1;
        }
        if ( value.status === 'FINISHED'){
          this.finished = this.finished + 1;
        }
      });

      this.options = {
        plugins: [dayGridPlugin, timeGrigPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        locale: window.localStorage.getItem('language'),
        dateClick: this.handleDateClick.bind(this),
        eventClick: this.eventClick.bind(this),
        eventDragStop: this.dragStop.bind(this),
        firstDay: 1,
        /*
       dayMaxEventRows: true,
       views: {
         timeGrid: {
           dayMaxEventRows: 10
         }
       },
       */
        events: this.events
      };
      this.showCalendar = true;

      setTimeout(() => {
        const tableElement = this.elementRef.nativeElement.querySelector('.fc-scrollgrid-sync-table');
        const dayEventsList = this.elementRef.nativeElement.querySelectorAll('.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events');
        const dayEventsDiv = this.elementRef.nativeElement.querySelector('.fc-view-harness-active');
        if (tableElement) {
          this.renderer.removeAttribute(tableElement, 'style');
        }
        if (dayEventsDiv) {
          this.renderer.removeAttribute(dayEventsDiv, 'style');
          dayEventsDiv.setAttribute('style', 'min-height: 400px');
        }
        if (dayEventsList) {
          dayEventsList.forEach(dayEvents => {
            // Her bir öğeye stil ekleyin veya kaldırın
            dayEvents.setAttribute('style', 'overflow: auto; max-height: 115px');
            // veya: dayEvents.removeAttribute('style'); // Stil özelliğini kaldırmak için
          });
        }

        this.loading = false;
      }, 1000);
    });

  }

  handleDateClick(arg): void {
    console.log('date click! ' + arg.dateStr);
    this.data = [{
      expectedDate: arg.dateStr
    }];
    setTimeout(() => {
      this.newWorkOrder = true;
    }, 1000);

  }
  eventClick(arg): void {
    console.log('date click! ' + arg.event.id);
  }
  dragStop(arg): void {
    console.log('Drop Stop! ' + arg.event.id + arg.event.view);
  }

}
