import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {InventoryModel} from './inventory.model';
import {UserModel} from './user.model';

export class InventoryItemModel extends ApiResourceModel{
  public static IRI = '/api/inventory_items';
  public id: any | [];
  public location: LocationModel;
  public method: string;
  public inventory: InventoryModel[] = [];
  public lockedBy: UserModel;
  public code: number;
  public results: any;
  public sums: [];
}

export enum inventoryMethod {

  SD= 'SD',
  STA= 'STA'
}
