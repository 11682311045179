import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-report-tabs',
  templateUrl: './report-tabs.component.html',
  styleUrls: ['./report-tabs.component.scss']
})
export class ReportTabsComponent implements OnInit {
  @Input() pageName: string;
  constructor() { }

  ngOnInit(): void {
  }

}
