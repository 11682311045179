import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../../service/http.service';
import {CompanyIntegrationModel} from '../../../../model/company-integration.model';

@Component({
  selector: 'app-kolaybi',
  templateUrl: './kolaybi.component.html',
  styleUrls: ['./kolaybi.component.scss']
})
export class KolaybiComponent implements OnInit {

  contactsLoading: boolean;
  productsLoading: boolean;

  constructor(private http: HttpService) { }

  ngOnInit(): void {
  }

  syncContacts(): void {
    this.contactsLoading = true;

    this.http.get(`${CompanyIntegrationModel.IRI}/kolaybi/contact`).subscribe(() => {
      this.contactsLoading = false;
    });
  }

  syncProducts(): void {
    this.productsLoading = true;

    this.http.get(`${CompanyIntegrationModel.IRI}/kolaybi/product`).subscribe(() => {
      this.productsLoading = false;
    });
  }
}
