import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TaxRateModel} from '../model/tax-rate.model';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  taxes = new BehaviorSubject<TaxRateModel[]>(null);

  constructor(private http: HttpService) { }

  getTaxes(): Observable<TaxRateModel[]> {
    return this.taxes.asObservable();
  }

  getTaxesAction(params = {}): void {
    this.http.get(TaxRateModel.IRI, params).subscribe(response => {
      this.taxes.next(response['hydra:member']);
    });
  }

  async save(tax: TaxRateModel): Promise<any> {
    return await this.http.post(TaxRateModel.IRI, tax).then(response => {
      if (response !== undefined) {
        const taxes = this.taxes.getValue();
        taxes.push(response);

        this.taxes.next(taxes);
      }

      return response;
    });
  }

  async update(tax: TaxRateModel): Promise<any> {
    return await this.http.put(`${TaxRateModel.IRI}/${tax.id}`, tax).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.taxes.next(null);
    this.taxes.unsubscribe();
  }
}
