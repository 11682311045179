<form action="">
  <p-card>
    <app-loading-indicator [loading]="loading"></app-loading-indicator>
    <div class="p-grid">
      <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'GENERAL' | translate}}</h3></div>
      <div class="p-col-6 p-md-6 p-sm-6">
        <app-page-info [pageName]="'GENERAL'"></app-page-info>
        <button *ngIf="!loading" pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'SAVE' | translate}}"
                type="submit" class="p-ml-3 p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="this.submitted" (click)="save()">
        </button>

      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-lg-12">
        <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTabMenuItem"></p-tabMenu>
      </div>
    </div>

    <div class="p-grid" *ngIf="activeTabMenuItem.title == 'GENERAL'">
      <div class="p-col-12 p-md-8 p-lg-8">
        <h4>{{'INFORMATION'|translate}}</h4>
        <hr>
        <div class="p-grid">
          <div class="p-col-6 p-md-6 p-lg-6">
            <label for="name" class="p-mb-2">{{'COMPANY_NAME' | translate}}</label>
            <input id="name" [style]="{width: '100%', height:'45px',marginTop:'10px'}" type="text" pInputText
                   [(ngModel)]="company.name" name="name" autofocus>
            <small class="p-error">{{ errors['name'] }}</small>
          </div>
          <div class="p-col-6 p-md-6 p-lg-6">
            <label for="phoneNumber">{{'PHONE_NUMBER' | translate}}</label>
            <input id="phoneNumber" [style]="{width: '100%', height:'45px',marginTop:'10px'}" type="text" pInputText
                   [(ngModel)]="company.phoneNumber" name="phoneNumber">
            <small class="p-error">{{ errors['phoneNumber'] }}</small>
          </div>
          <div class="p-col-12 p-md-6 p-lg-6">
            <label for="email">{{'EMAIL' | translate}}</label>
            <input id="email" [style]="{width: '100%', height:'45px',marginTop:'10px'}" type="text" pInputText
                   [(ngModel)]="company.email" name="email">
            <small class="p-error">{{ errors['email'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="currency">{{'CURRENCY' | translate}}</label>
            <p-dropdown [style]="{marginTop:'10px'}" [options]="currencies" [(ngModel)]="company.currency"
                        optionLabel="code" id="currency" name="currency" optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['currency'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="currency">{{'DEFAULT_SALE_LOCATION' | translate}}</label>
            <p-dropdown [style]="{marginTop:'10px'}" placeholder="{{'SELECT_LOCATION' | translate}}" [options]="locations" [(ngModel)]="company.defaultSalesLocation"
                        optionLabel="name" id="defaultSalesLocation" name="defaultSalesLocation"
                        optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['defaultSalesLocation'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="currency">{{'DEFAULT_PURCHASE_LOCATION' | translate}}</label>
            <p-dropdown [style]="{marginTop:'10px'}"  placeholder="{{'SELECT_LOCATION' | translate}}" [options]="locations" [(ngModel)]="company.defaultPurchaseLocation"
                        optionLabel="name" id="defaultPurchaseLocation" name="defaultPurchaseLocation"
                        optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['defaultPurchaseLocation'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="currency">{{'DEFAULT_MANUFACTURING_LOCATION' | translate}}</label>
            <p-dropdown [style]="{marginTop:'10px'}"  placeholder="{{'SELECT_LOCATION' | translate}}" [options]="locations"
                        [(ngModel)]="company.defaultManufacturingLocation" optionLabel="name"
                        id="defaultManufacturingLocation" name="defaultManufacturingLocation"
                        optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['defaultManufacturingLocation'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="defaultDeliveryTime">{{'DEFAULT_DELIVERY_TIME' | translate}}</label>
            <p-inputNumber [style]="{width: '100%', height:'45px',marginTop:'10px'}" id="defaultDeliveryTime"
                           [(ngModel)]="company.defaultDeliveryTime" name="defaultDeliveryTime"></p-inputNumber>
            <small class="p-error">{{ errors['defaultDeliveryTime'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="defaultProductionTime">{{'DEFAULT_MANUFACTURING_TIME' | translate}}</label>
            <p-inputNumber [style]="{width: '100%', height:'45px',marginTop:'10px'}" id="defaultProductionTime"
                           [(ngModel)]="company.defaultProductionTime" name="defaultProductionTime"></p-inputNumber>
            <small class="p-error">{{ errors['defaultProductionTime'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="saleOrderPrefix">{{'SALE_ORDER_PREFIX' | translate}}</label>
            <input id="saleOrderPrefix" [style]="{width: '100%', height:'45px',marginTop:'10px'}" type="text" pInputText
                   [(ngModel)]="company.saleOrderPrefix" name="saleOrderPrefix">
            <small class="p-error">{{ errors['saleOrderPrefix'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="purchaseOrderPrefix">{{'PURCHASE_ORDER_PREFIX' | translate}}</label>
            <input id="purchaseOrderPrefix" [style]="{width: '100%', height:'45px',marginTop:'10px'}" type="text"
                   pInputText [(ngModel)]="company.purchaseOrderPrefix" name="purchaseOrderPrefix">
            <small class="p-error">{{ errors['purchaseOrderPrefix'] }}</small>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="workOrderPrefix">{{'WORK_ORDER_PREFIX' | translate}}</label>
            <input id="workOrderPrefix" [style]="{width: '100%', height:'45px',marginTop:'10px'}" type="text" pInputText
                   [(ngModel)]="company.workOrderPrefix" name="workOrderPrefix">
            <small class="p-error">{{ errors['workOrderPrefix'] }}</small>
          </div>
          <div class="p-col-12 p-md-6" [style]="{display: 'flex',alignItems: 'center'}">
            <p-inputSwitch  binary="true" id="senEmail" name="sendEmail" [(ngModel)]="company.sendEmail"></p-inputSwitch> {{'SEND_MAIL_SUPPLIER' | translate}}
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-4 p-lg-4">
        <h4>{{'LOGO'|translate}}</h4>
        <hr>
        <img *ngIf="company.logo" height="50px" class="p-d-block" [src]="apiUrl + '/' + company.logo" alt="">
        <p-fileUpload mode="basic" class="p-mt-2 p-mr-2 p-d-inline-block" styleClass="p-button-sm p-button-secondary"
                      #fileUpload [chooseIcon]="imageLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'"
                      [chooseLabel]="'SELECT_FILE'|translate" name="file" accept="image/*" maxFileSize="1000000"
                      [auto]="true" [customUpload]="true" (uploadHandler)="upload($event, fileUpload)"></p-fileUpload>
        <p-button [icon]="imageDeleting ?  'pi pi-spin pi-spinner': 'pi pi-trash'" class="p-d-inline-block"
                  (onClick)="deleteImage()" [label]="'DELETE'|translate"
                  styleClass="p-button-sm p-button-danger"></p-button>

      </div>
    </div>
    <div class="p-grid" *ngIf="activeTabMenuItem.title === 'POS'">
      <div class="p-col-12">
      </div>

    </div>
  </p-card>
</form>
