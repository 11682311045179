import {Component, OnInit} from '@angular/core';
import {InventoryItemModel} from '../../../model/inventory-item.model';
import {CategoryModel} from '../../../model/category.model';
import {CompanyModel} from '../../../model/company.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CategoryService} from '../../../service/category.service';
import {InventoryStockModel} from '../../../model/inventory-stock.model';
import {CurrencyService} from '../../../service/currency.service';
import {InventoryModel} from '../../../model/inventory.model';
import {InventoryCategoryModel} from '../../../model/inventory-category.model';

@Component({
  selector: 'app-inventory-category',
  templateUrl: './inventory-category.component.html',
  styleUrls: ['./inventory-category.component.scss']
})
export class InventoryCategoryComponent implements OnInit {
  loading: boolean;
  total: number;
  // @ts-ignore
  items: any = [];
  categories: any = [];
  company: CompanyModel = new CompanyModel();
  id: any;
  parameters = {
    location: '',
    category: '',
  };
  values = [];
  currencyLabel: string;
  inventory: InventoryModel[] = [];
  searchCategories = [];
  sums: [];
  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private categoryService: CategoryService,
              private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      await this.http.get(`${InventoryModel.IRI}/categories/${this.id}`).subscribe((response) => {
        if (response) {
          this.items = response.results;
          this.categories = response.mainCategories;
          this.sums = response.sums;
          this.loading = false;
        }
      });
    }
  }

  changeCategory(event): void {
    this.parameters.category = event.value;
    this.load();
  }

  updateInventory(itemCode): void {
    this.loading = true;
    this.http.get(`${InventoryModel.IRI}/update_inventory/${itemCode}`).subscribe((response) => {
      if (response) {
        this.load();
      }
    });
}

}
