import {Component, OnDestroy, OnInit} from '@angular/core';
import {StockRequestModel} from '../../../model/stock-request.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {LocationModel} from '../../../model/location.model';
import {Subscription} from 'rxjs';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ValidationService} from '../../../service/validation.service';
import {StockRequestItemsModel} from '../../../model/stock-request-items.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {UserModel} from '../../../model/user.model';


@Component({
  selector: 'app-stock-request-items',
  templateUrl: './stock-request-items.component.html',
  styleUrls: ['./stock-request-items.component.scss']
})
export class StockRequestItemsComponent implements OnInit, OnDestroy {

  stockRequest: StockRequestModel = new StockRequestModel();
  loading: boolean;
  submitted: boolean;
  errors = [];
  currencyLabel: string;
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  locations: LocationModel[] = [];
  destinations: LocationModel[] = [];
  id: any;
  isDeleteAble: boolean;
  users: UserModel[] = [];
  user: any;

  validationSubscriber: Subscription;

  constructor(private http: HttpService,
              private activatedRoute: ActivatedRoute,
              private flashMessageService: FlashMessageService,
              private router: Router,
              private validationService: ValidationService) { }


  ngOnInit(): void {
    this.loading = true;
    const userJson = localStorage.getItem('user');
    this.user = JSON.parse(userJson);
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.http.get(`${StockRequestModel.IRI}/${this.id}`).subscribe((response: StockRequestModel) => {
        this.stockRequest = response;
        this.stockRequest.requestDate = new Date(this.stockRequest.requestDate);
        this.stockRequest.location = this.stockRequest.location['@id'];

        this.stockRequest.items.map((item, key) => {
          this.autoCompleteValues.push(item.stockVariant);
          this.stockRequest.items[key].stockVariant = this.stockRequest.items[key].stockVariant['@id'];
        });
        this.loading = false;
        this.isDeleteAble = true;
      });

    } else {
      this.http.get(`${StockRequestModel.IRI}/request_number`).subscribe((response: StockRequestModel) => {
        this.stockRequest = response;
        this.stockRequest.id = null ;
        this.stockRequest.requestDate = new Date(this.stockRequest.requestDate);
        this.loading = false;

        if (this.locations.length > 0) {
          this.stockRequest.location = this.locations[0]['@id'];
        }
      });
    }


    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
      if (this.locations.length > 0) {
        this.stockRequest.location = this.locations[0]['@id'];
      }
    });


    this.http.get(`${UserModel.IRI}/${this.user.id}`).subscribe((response ) => {
      this.destinations = response.locations;
    });

    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });
  }
  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  onVariantSelect(event: StockVariantModel, i: number): void {
    this.stockRequest.items[i].stockVariant = event['@id'];
    this.stockRequest.items[i].stock = event.stock['@id'];
    this.stockRequest.items[i].unitPrice = event.defaultPrice;
  }

  searchVariant(event): void {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  addItem(): void {
    const item = new StockRequestItemsModel();
    this.stockRequest.items.push(item);
    this.autoCompleteValues.push(new StockVariantModel());
  }

  removeItem(i: number): void {
    this.stockRequest.items.splice(i, 1);
    this.autoCompleteValues.splice(i, 1);
  }

  delete(id): void {
    this.http.delete(`${StockRequestModel.IRI}/` + id ).then((response: StockRequestModel) => {
      this.router.navigate([`/stock-requests`]);
    });
  }

  async save(): Promise<void> {
    this.submitted = true;

    if (this.id) {
      await this.http.put(`${StockRequestModel.IRI}/${this.id}`, this.stockRequest).then((response: StockRequestModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(error => {
      });
    } else {
      await this.http.post(StockRequestModel.IRI, this.stockRequest).then((response: StockRequestModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          this.router.navigate([`/stock-requests/${response.id}/edit`]);
        }
      }).catch(error => {
      });
    }

    this.submitted = false;
  }
  calculateTotalPrice(i: number): void {
    this.stockRequest.items[i].totalPrice = this.stockRequest.items[i].quantity * this.stockRequest.items[i].unitPrice;
  }
  // tslint:disable-next-line:typedef
  onKeydown(event) {
    if (event.key === 'Enter') {
      this.addItem();
    }
  }
  ngOnDestroy(): void{
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

}
