import { Component, OnInit } from '@angular/core';
import {CompanyModel} from '../../model/company.model';
import { environment } from '../../../environments/environment';
import {UserModel} from '../../model/user.model';
import {HttpService} from '../../service/http.service';
import {AuthService} from '../../service/auth.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ValidationService} from '../../service/validation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  company: CompanyModel = new CompanyModel();
  submitted: boolean;
  disabled = true;
  apiUrl: string;
  display: boolean;
  onReadAgreement: boolean;
  validationSubscriber: Subscription;
  errors = [];

  constructor(private http: HttpService, private authService: AuthService, private validationService: ValidationService,
              private activatedRoute: ActivatedRoute, private translate: TranslateService ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParamMap.get('lang')){
      this.translate.setDefaultLang(this.activatedRoute.snapshot.queryParamMap.get('lang'));
      this.translate.use(this.activatedRoute.snapshot.queryParamMap.get('lang'));
    }else {
      this.translate.setDefaultLang('tr');
      this.translate.use('tr');
    }
    this.onReadAgreement = false;
    const token = window.localStorage.getItem('token');

    if (token) {
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('permissions');
      window.localStorage.removeItem('currency');
      window.localStorage.removeItem('language');
      window.location.reload();
    }

    this.apiUrl = environment.apiUrl;
    const name = this.activatedRoute.snapshot.queryParamMap.get('name');
    const phone = this.activatedRoute.snapshot.queryParamMap.get('phoneNumber');

    if (name && phone) {
      const nameArray = name.split(' ');

      this.company.phoneNumber = phone;
      this.company.users[0].firstName = nameArray[0];
      this.company.name = name;

      if (nameArray.length > 1) {
        this.company.users[0].lastName = nameArray[1];
      } else {
        this.company.users[0].lastName = nameArray[0];
      }
    }

    const user = new UserModel();
    this.company.users.push(user);
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
  }

  showDialog(): void {
    this.display = true;
  }
  onChangeReadAgreement(): void {
    if (this.onReadAgreement ===  true){
      this.onReadAgreement = false;
    }else {
      this.onReadAgreement = true;
    }
  }

  async login(): Promise<void> {
    if (!this.disabled) {
      this.submitted = true;

      await this.http.post(`${CompanyModel.IRI}/create`, this.company).then(response => {
        if (response) {
          // tslint:disable-next-line:no-shadowed-variable
          this.authService.login(this.company.email, this.company.users[0].password).then(response => {
            if (response) {
              window.location.href = '/initial-setup';
              return true;
            }
          });
        }
      });

      this.submitted = false;
    }
  }

  showResponse(event): void {
    if (event.response) {
      this.disabled = false;
    }
  }
}
