import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  private displaySubject: string;

  setLanguage(language): void {
    this.displaySubject = language;
  }

  getLanguage(): string {
    return this.displaySubject;
  }
}
