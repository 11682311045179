<app-loading-indicator [loading]="loading"></app-loading-indicator>

<form (ngSubmit)="save()" *ngIf="!loading">
  <div class="p-fluid">
    <div class="p-field">
      <label for="code">{{'WORK_ORDER_CREATE' | translate}}</label>
      <input id="code" type="text" pInputText [(ngModel)]="workOrder.code" name="code" autofocus>
    </div>
    <div class="p-field">
      <label for="deadline">{{'DEADLINE' | translate}}</label>
      <p-calendar appendTo="body" [(ngModel)]="workOrder.deadline" id="deadline" name="deadline"></p-calendar>
    </div>
    <div class="p-field">
      <label for="plannedQuantity">{{'QUANTITY' | translate}}</label>
      <p-inputNumber mode="decimal" id="plannedQuantity" name="plannedQuantity" [(ngModel)]="workOrder.plannedQuantity" (ngModelChange)="getStockStatus()" minFractionDigits="2" maxFractionDigits="2" ></p-inputNumber>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="from">{{ 'SOURCE' | translate}}</label>
        <p-dropdown id="from" appendTo="body" [options]="locations" [(ngModel)]="workOrder.source" name="source" optionLabel="name"  optionValue="@id"></p-dropdown>
      </div>
      <div class="p-field p-col">
        <label for="to">{{ 'TARGET' | translate}}</label>
        <p-dropdown id="to" appendTo="body" [options]="locations" [(ngModel)]="workOrder.target" optionLabel="name" name="target" optionValue="@id"></p-dropdown>
      </div>
    </div>
  </div>
  <button pButton label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"></button>
</form>
