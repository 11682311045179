import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService} from 'primeng/api';
import {TaxRateModel} from '../../../model/tax-rate.model';
import { TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {CompanyModel} from '../../../model/company.model';
import {TaxService} from '../../../service/tax.service';
import {DialogService} from 'primeng/dynamicdialog';
import {CreateTaxComponent} from './create-tax/create-tax.component';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
  providers: [DialogService]
})
export class TaxComponent implements OnInit, OnDestroy {
  items: TaxRateModel[] = [];
  activeItems: TaxRateModel[] = [];
  item: TaxRateModel = new TaxRateModel();
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean;
  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private confirmationService: ConfirmationService, private translate: TranslateService,
              private validationService: ValidationService, private flashMessageService: FlashMessageService,
              private taxService: TaxService, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.load();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });

    this.http.get(CompanyModel.IRI).subscribe((response: CompanyModel) => {
      this.company = response;
    });
  }

  load(): void {
    this.taxService.getTaxesAction({isActive: true});

    this.loading = true;

    this.taxService.getTaxes().subscribe(response => {
      if (response) {
        this.items = response;

        this.items.map(item => {
          if (item.isActive) {
            this.activeItems.push(item);
          }
        });
        this.loading = false;
      }
    });
  }

  openForm(item: TaxRateModel = null): void {
    this.dialogService.open(CreateTaxComponent, {
      width: '50vw',
      header: this.translate.instant('TAX_RATE_CREATE'),
      data: {item}
    });
  }

  edit(row): void {
    this.item = {...row};

    this.openForm(this.item);
  }

  changeStatus(row): void {
    this.http.put(`${TaxRateModel.IRI}/${row.id}`, {isActive: row.isActive}).then((response) => {
      row.isActive = response.isActive;
    });
  }

  hideForm(): void {
    this.errors = [];
    this.showForm = false;
    this.item = new TaxRateModel();
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();

    this.taxService.taxes.next([]);
  }

  setIsDefault(row): void {
    this.http.put(`${CompanyModel.IRI}/${this.company.id}`, {defaultTax: row.value}).then();
  }
}
