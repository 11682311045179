<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'INVENTORY' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'INVENTORY'"></app-page-info>
      <p-button type="text"  [style]="{marginLeft: '5px', float:'right'}" [disabled]="queueCloseDisplay"
                (click)="openableInventory ? new() : noOpen()" icon="pi pi-plus"
                label="{{'NEW_INVENTORY' | translate }}"></p-button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 information" *ngIf="infoTextShowDisplay">
      <app-inventory-list-page-info></app-inventory-list-page-info>
    </div>
  </div>
  <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" [style]="{padding: '20px', marginBottom:'20px'}"
             text="{{'NEW_INVENTORY_REMAINING'| translate }}">
  </p-message>
  <p-message severity="warn" *ngIf="queueCloseDisplay" class="p-d-block" [style]="{padding: '20px', marginBottom:'20px'}"
             text="{{'CLOSE_INVENTORY_REMAINING'| translate }}">
  </p-message>
  <p-tabMenu [model]="tabMenuItems" [activeItem]="activeItem"></p-tabMenu>
  <div *ngIf="activeItem.title === 'OPENED_INVENTORY_TITLE'">
    <br>
    <div class="p-grid">
      <div class="p-col-12" style="overflow-x: auto;">
        <p-table styleClass="p-datatable-striped" [loading]="loading" [lazy]="true" [value]="inventories" [totalRecords]="total" (onLazyLoad)="load($event)"
                 [showCurrentPageReport]="true" [resizableColumns]="true" columnResizeMode="expand"
                 styleClass="p-datatable-striped p-datatable-md">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width: '20%'}" pResizableColumn>{{'CODE' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}" pResizableColumn>{{'INFO' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}" pResizableColumn>{{'START_OF_INVENTORY_PERIOD' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}" pResizableColumn>{{'END_OF_INVENTORY_PERIOD' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}"pResizableColumn>{{'STATUS' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}"pResizableColumn>{{'CREATED_AT' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}"pResizableColumn>{{'CREATED_USER' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}"pResizableColumn>{{'UPDATED_AT' | translate}}</th>
              <th scope="col" [style]="{width: '10%'}"pResizableColumn>{{'UPDATED_USER' | translate}}</th>
              <th scope="col" [style]="{width: '5%'}"pResizableColumn></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr *ngIf="item.status === 'OPENED_INVENTORY'">
              <td><a routerLink="/inventory/{{item.id}}/edit">{{ item.code }}</a></td>
              <td>{{item.info}}</td>
              <td>{{item.start_date |date: 'Y-MM-dd' }}</td>
              <td>{{item.end_date |date: 'Y-MM-dd' }}</td>
              <td>{{item.status | translate}}</td>
              <td>{{item.created_at|date: 'Y-MM-dd'  }}</td>
              <td>{{item.createdUserName  }} {{item.createdUserLastName  }}</td>
              <td>{{item.updated_at|date: 'Y-MM-dd'  }}</td>
              <td>{{item.updatedUserName  }} {{item.updatedUserLastName  }}</td>
              <td class="p-text-right">
                <i class="pi pi-pencil" (click)="edit(item)"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
          <div class="p-col-4 p-offset-4" style="">
            <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                    [icon]="'pi pi-arrow-down'"
                    (click)="loadInventories()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="activeItem.title === 'CLOSED_INVENTORY_TITLE'">
    <br>
    <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table styleClass="p-datatable-striped" [loading]="loading" [lazy]="true" [value]="inventories" [totalRecords]="total" (onLazyLoad)="load($event)"
               [showCurrentPageReport]="true" [resizableColumns]="true" columnResizeMode="expand"
               styleClass="p-datatable-striped p-datatable-md">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '8%'}" pResizableColumn>{{'CODE' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}" pResizableColumn>{{'START_OF_INVENTORY_PERIOD' | translate}}</th>
            <th scope="col" [style]="{width: '12%'}" pResizableColumn>{{'END_OF_INVENTORY_PERIOD' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}" pResizableColumn>{{'INFO' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'STATUS' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'CREATED_AT' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'CREATED_USER' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'UPDATED_AT' | translate}}</th>
            <th scope="col" [style]="{width: '15%'}"pResizableColumn>{{'UPDATED_USER' | translate}}</th>
            <th scope="col" [style]="{width: '5%'}"pResizableColumn></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr *ngIf="item.status === 'CLOSED_INVENTORY'">
            <td><a routerLink="/inventory/{{item.id}}/edit">{{ item.code }}</a></td>
            <td>{{item.start_date |date: 'Y-M-d' }}</td>
            <td>{{item.end_date |date: 'Y-M-d' }}</td>
            <td>{{item.info}}</td>
            <td>{{item.status | translate}}</td>
            <td>{{item.created_at|date: 'Y-M-d'  }}</td>
            <td>{{item.createdUserName  }} {{item.createdUserLastName  }}</td>
            <td>{{item.updated_at|date: 'Y-M-d'  }}</td>
            <td>{{item.updatedUserName  }} {{item.updatedUserLastName  }}</td>
            <td class="p-text-right">
              <i class="pi pi-pencil" (click)="edit(item)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="loadInventories()"></button>
        </div>
      </div>

    </div>
  </div></div>
</p-card>


<p-dialog header="{{ 'NEW_INVENTORY' | translate}}" [(visible)]="display" [style]="{width: '50vw'}">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <label for="code">{{ 'CODE'|translate}}</label>
      <span class="p-float-label p-input-icon-right">
        <i class="pi pi-spin pi-spinner" *ngIf="loading || codeLoading"></i>
            <input id="code" class="boxShadow" type="text" name="code" pInputText [(ngModel)]="inventory.code"
                   [disabled]="loading || codeLoading">
        </span>
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-field p-col-6">
      <label for="date">{{ 'INVENTORY_END_DATE'|translate}}</label>
      <p-calendar id="date" appendTo="body" [(ngModel)]="inventory.endDate" [minDate]="inventory.startDate"
                  name="transferDate" dateFormat="yy-mm-dd" (onSelect)="test($event)"  placeholder="{{'SELECT_TRANSFER_DATE'| translate}}"  >
      </p-calendar>
      <small class="p-error"></small>
    </div>
    <div class="p-field p-col-6">
      <label for="locations">{{ 'LOCATION'|translate}}</label>
      <p-multiSelect id="locations" name="locations" appendTo="body" [options]="locations" [(ngModel)]="inventory.locations" defaultLabel="{{ 'SELECT_LOCATION'|translate }}" optionLabel="name"  optionValue="@id"  display="chip"></p-multiSelect>
      <small *ngIf="isLocationSelected == true" class="p-error">{{ 'SELECT_LOCATION' | translate}}</small>
    </div>
    <div class="p-field p-col-6">
      <label for="info">{{ 'INVENTORY_INFO'|translate}}</label>
      <input id="info"  type="text" name="info" pInputText [(ngModel)]="inventory.info"  pInputText>
    </div>
  </div><br>
  <div> <span innerHTML="{{ countInventoryDate() }}"></span></div>
  <ng-template pTemplate="footer">
    <div class="p-grid">
      <div class="p-col-4 p-offset-2">
        <button pButton label="{{'CANCEL' | translate}}"  [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#444444'}"
                icon="pi pi-times" class="p-mr-2" (click)="hideForm()" ></button>
      </div>
      <div class="p-col-4">
        <button pButton label="{{'SAVE' | translate}}" [style]="{width: '100%'}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="this.submitted || this.loading || codeLoading" (click)="save()"></button>
      </div>
    </div>
    </ng-template>
</p-dialog>
<p-dialog header="{{ 'WARNING' | translate}}" [(visible)]="noOpenInventoryDialog" [style]="{width: '50vw'}">
  <p-message severity="warn" text="{{'ALERT_NEW_INVENTORY_MESSAGE' | translate }}"></p-message>
  <p-message severity="warn" *ngIf="queueCloseDisplay" class="p-d-block" [style]="{padding: '20px', marginBottom:'20px'}"
             text="{{'CLOSE_INVENTORY_REMAINING'| translate }}">
  </p-message>
</p-dialog>
<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
