<form (submit)="load()">
<p-table [loading]="loading" [lazy]="true" [value]="items" [totalRecords]="total" (onLazyLoad)="load($event)" [rowsPerPageOptions]="[10,25,50]"
         [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true" styleClass="p-datatable-striped"
         columnResizeMode="expand" (onColResize)="onResize($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '0'}" pResizableColumn>{{  'ORDER_NUMBER' | translate }} <app-tooltip description="ORDER_NUMBER_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '1'}" pResizableColumn>{{ 'CUSTOMER' | translate}} <app-tooltip description="CUSTOMER_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '2'}" pResizableColumn>{{ 'TAGS' | translate}} <app-tooltip description="CUSTOMER_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '3'}" pResizableColumn>{{ 'STOCK' | translate}} <app-tooltip description="STOCK_ITEM_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '4'}" pResizableColumn>{{ 'QUANTITY' | translate}} <app-tooltip description="STOCK_ITEM_QUANTITY_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '5'}" pResizableColumn>{{ 'REMAINING' | translate}} <app-tooltip description="REMAINING_ITEM_QUANTITY_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '6'}" pResizableColumn>{{ 'SUBTOTAL' | translate}} <app-tooltip description="ITEM_SUBTOTAL_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '7'}" pResizableColumn>{{ 'TAX' | translate}} <app-tooltip description="ITEM_TAX_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '8'}" pResizableColumn>{{ 'TOTAL' | translate}} <app-tooltip description="ITEM_TOTAL_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '9'}" pResizableColumn>{{ 'CURRENCY' | translate}} <app-tooltip description="CURRENCY_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '10'}" pResizableColumn>{{ 'STOCK_STATUS' | translate}} <app-tooltip description="STOCK_STATUS_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '11'}" pResizableColumn>{{ 'MATERIAL_STATUS' | translate}} <app-tooltip description="MATERIAL_STATUS_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '12'}" pResizableColumn>{{ 'PRODUCTION_STATUS' | translate}} <app-tooltip description="PRODUCTION_STATUS_TOOLTIP"></app-tooltip></th>
      <th [style]="{width: settings|columnWidth: 'invoiceDetailedList': '13'}" pResizableColumn>{{ 'DELIVERY_STATUS' | translate}} <app-tooltip description="DELIVERY_STATUS_TOOLTIP"></app-tooltip></th>
    </tr>
    <tr>
      <td><input type="text" pInputText [(ngModel)]="parameters['invoice.code']" name="code"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters['invoice.contact.name']" name="contactName"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters['stock.tags']" name="tags"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters['variant.name']" name="variant"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters.quantity" name="quantity"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters.remainingQuantity" name="remainingQuantity"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters.subtotal" name="subtotal"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters.tax" name="tax"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters.total" name="total"></td>
      <td><input type="text" pInputText [(ngModel)]="parameters['invoice.currency.code']" name="currency"></td>
      <td>
        <p-dropdown inputId="dropdown" appendTo="body" (onChange)="parameters.status = $event.value; load()" baseZIndex="9999" [options]="stockStatuses" name="itemStatus" [(ngModel)]="parameters.status" optionLabel="name" optionValue="value" ></p-dropdown>
      </td>
      <td>
        <p-dropdown inputId="dropdown" appendTo="body" (onChange)="parameters.materialStatus = $event.value; load()" baseZIndex="9999" [options]="stockStatuses" name="materialStatus" [(ngModel)]="parameters.materialStatus" optionLabel="name" optionValue="value" ></p-dropdown>
      </td>
      <td>
        <p-dropdown inputId="dropdown" appendTo="body" (onChange)="parameters.productionStatus = $event.value; load()" baseZIndex="9999" [options]="productionStatuses" name="productionStatus" [(ngModel)]="parameters.productionStatus" optionLabel="name" optionValue="value" ></p-dropdown>
      </td>
      <td>
        <p-dropdown inputId="dropdown" appendTo="body" (onChange)="parameters.deliveryStatus = $event.value; load()" baseZIndex="9999" [options]="deliveryStatuses" name="deliveryStatus" [(ngModel)]="parameters.deliveryStatus" optionLabel="name" optionValue="value" ></p-dropdown>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      <td width="15%"><a routerLink="/{{row.invoice.type.toLowerCase()}}/{{row.invoice.id}}/edit">{{ row.invoice.code }}</a></td>
      <td>{{ row.invoice?.customerName ? row.invoice?.customerName :  row.invoice?.contact?.name }}</td>
      <td>{{ row.stock?.tags }}</td>
      <td>{{ row.variant?.name }}</td>
      <td>{{ row.quantity }}</td>
      <td>{{ row.remainingQuantity }}</td>
      <td>{{ row.subtotal|currency: (row.invoice.currency ? row.invoice.currency.code : currency): '' }}</td>
      <td>{{ row.tax }}</td>
      <td>{{ row.total|currency: (row.invoice.currency ? row.invoice.currency.code : currency): '' }}</td>
      <td>{{ row.invoice.currency ? row.invoice.currency.code : currency }}</td>
      <td><p-tag [severity]="row.status|statusLabel">{{ row.status|status }}</p-tag></td>
      <td><p-tag [severity]="row.materialStatus|statusLabel">{{ row.materialStatus|status: row.stockExpectedDate }}</p-tag></td>
      <td>
        <p-tag *ngIf="row.isWorkOrderCreated" [severity]="row.productionStatus|statusLabel">{{ row.productionStatus|status }}</p-tag>
        <p-tag *ngIf="!row.isWorkOrderCreated" routerLink="/work-orders/create" [state]="{quantity: row.quantity, invoice: row.invoice['@id'], invoiceItem: row['@id'], variant: row.variant}" >{{ row.productionStatus|translate }}</p-tag>
      </td>
      <td style="width: 2rem">
        <p-tag (click)="openChangeStatusPanel(dp, $event, row.id, row.invoice.id)" [severity]="'primary'" class="cursor-pointer" icon="pi pi-chevron-down" *ngIf="row.deliveryStatus != 'delivered'">{{row.deliveryStatus|translate}}</p-tag>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-overlayPanel #dp>
  <ng-template pTemplate>
    <div *ngFor="let item of deliveryActions">
      <p-button *ngIf="item.state.status !== deliveryActionsConstants.PARTIAL_DELIVERED"  [label]="item.label" styleClass="p-button-text" class="p-d-block" (onClick)="changeStatus(item.state.id, item.state.status); dp.hide()"></p-button>
      <p-button *ngIf="item.state.status === deliveryActionsConstants.PARTIAL_DELIVERED"  [label]="item.label" styleClass="p-button-text" class="p-d-block" (onClick)="receive(item.state.id, item.state.itemId); dp.hide()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>
  <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
</form>
<table class="total-box p-p-3">
  <tr>
    <th><p>{{ 'SUBTOTAL' | translate}}</p> <p>{{ 'REMAINING'|translate }}</p></th>
    <td><p>{{ totals.subtotal|currency: currency }}</p> <p>{{ totals.remainingSubtotal|currency: currency }}</p></td>
  </tr>
  <tr>
    <th><p>{{ 'TAX' | translate}}</p> <p>{{ 'REMAINING'|translate }}</p></th>
    <td><p>{{ totals.tax|currency: currency }}</p> <p>{{ totals.remainingTax|currency: currency }}</p></td>
  </tr>
  <tr>
    <th><p>{{ 'TOTAL' | translate}}</p> <p>{{ 'REMAINING'|translate }}</p></th>
    <td><p>{{ totals.total|currency: currency }}</p> <p>{{ totals.remainingTotal|currency: currency }}</p></td>
  </tr>
</table>
