import { Component, OnInit } from '@angular/core';
import {CompanyModel} from '../../../model/company.model';
import {HttpService} from '../../../service/http.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CurrencyModel} from '../../../model/currency.model';

@Component({
  selector: 'app-payment-basket',
  templateUrl: './payment-basket.component.html',
  styleUrls: ['./payment-basket.component.scss']
})
export class PaymentBasketComponent implements OnInit {


  company: CompanyModel = new CompanyModel();
  loading: boolean;
  submitted: boolean;
  apiUrl: string;
  plan = 'c2b9d646-87f5-4eb2-b41d-046eeeb17a8d-4';
  planes = [
    {
      name: 'Temel',
      period: 'Aylık',
      quantity: 1,
      plain: 'A1',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 39,
    },
    {
      name: 'Profesyonel',
      period: 'Aylık',
      quantity: 1,
      plain: 'A2',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 69,
    },
    {
      name: 'Girişim',
      period: 'Aylık',
      quantity: 1,
      plain: 'A3',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 89,
    },
    {
      name: 'Temel',
      period: 'Yıllık',
      quantity: 12,
      plain: 'Y1',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 35,
    },
    {
      name: 'Profesyonel',
      period: 'Yıllık',
      quantity: 12,
      plain: 'Y2',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 65,
    },
    {
      name: 'Girişim',
      period: 'Yıllık',
      quantity: 12,
      plain: 'Y3',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 85,
    },
  ];

  paymentForm: string = null;
  card = {
    cardHolderName: '',
    cardNumber: '',
    expireMonth: null,
    expireYear: null,
    cvc: '',
  };
  selectedPlain: string;
  selectedPeriod: string;
  selectedPrice: number;
  packet: string;
  locationCount: number;
  selectedQuantity: number;
  total: number;
  tax: number;
  subTotal: number;
  dolarValue: number;
  payTotal: number;
  constructor(private http: HttpService, private flashMessageService: FlashMessageService , private router: Router) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;

    this.http.get(CurrencyModel.IRI + '/getCurrencies').subscribe(response => {
      this.dolarValue = response.dolar[0];
      });
    this.loading = true;
    this.selectedPrice = 0;

    this.http.get(CompanyModel.IRI).subscribe(response => {
      this.company = response;
      this.locationCount = this.company.locationCount;
      setTimeout(() => {
        this.packet = localStorage.getItem('packet');
        const findIndex = this.planes.findIndex(obj => obj.plain ===  this.packet);
        this.selectedPlain = this.planes[findIndex].name;
        this.selectedPeriod = this.planes[findIndex].period;
        this.selectedPrice = this.planes[findIndex].price;
        this.selectedQuantity = this.planes[findIndex].quantity;
        this.total =  this.selectedQuantity * this.selectedPrice * this.locationCount;
        this.subTotal =  this.total - (this.total * 20 / 100);
        this.tax =  (this.total * 20 / 100);
        this.payTotal =  this.total * this.dolarValue;
      }, 1000);
      this.loading = false;
    });
  }
  async send(): Promise<void> {
    this.submitted = true;
    delete this.company.currency;
    delete this.company.subscriptions;
    await this.http.put(`${CompanyModel.IRI}/${this.company.id}`, this.company).then(response => {
    });
    await this.http.post(`${CompanyModel.IRI}/subscription?code=${this.plan}`, this.card).then(response => {
      if (response.status === 'success') {
        window.location.href = '/';
      } else {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, response.errorMessage));
      }
    });
    this.submitted = false;
  }
}
