import { Component, OnInit } from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {CategoryModel} from '../../../../model/category.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationService} from '../../../../service/location.service';
import {ActivatedRoute} from '@angular/router';
import {CategoryService} from '../../../../service/category.service';

@Component({
  selector: 'app-unpaid-report',
  templateUrl: './unpaid-report.component.html',
  styleUrls: ['./unpaid-report.component.scss']
})
export class UnpaidReportComponent implements OnInit {

  events: any[] = [];
  options: any;
  info: any;
  menuItem: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  location: LocationModel[] = [];
  categories: CategoryModel[] = [];
  category: any;
  paraLoc: any;
  date: Date;
  parameters = {
    date : '',
    location: '',
    category: ''
  };
  searchAllLoc: boolean;
  constructor(private http: HttpService, private translate: TranslateService,
              private locationService: LocationService, private route: ActivatedRoute, private categoryService: CategoryService) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe( params => {
      if (params.date || params.location || params.category){
        this.parameters.date = params.date;
        this.date = new Date(this.parameters.date);
        this.parameters.location = params.location ? params.location : '' ;
        this.parameters.category = params.category;
      }
    });
    this.category = this.parameters.category;
    this.load().then();
  }
  async load(): Promise<void>{
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.categories = this.categoryService.getSearchCategory({categoryType: 'product'});
    if (this.parameters.location === '' || this.parameters.location == null ){
      this.searchAllLoc = true;
    }else {
      this.searchAllLoc = false;
    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
    await this.http.get('/api/reports/menu_item_reports', this.parameters).subscribe(response => {
      this.menuItem = response;
      this.loading = false;
    });
  }
  onSelectDate(event): void {
    const start = event;
    this.parameters.date = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    // this.parameters.lastDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
    this.parameters = {...this.parameters};
  }
  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
  }
  onSelectCategory(event): void {
    this.parameters.category = event.value ? event.value : '';
    this.category = this.parameters.category;
  }
  parseFloatS(event): number{
    return parseFloat(event);
  }

}
