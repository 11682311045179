<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{'STATISTICS' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">
        {{ stock.name }}
      </p>
    </ng-template>
  </p-splitter>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col">
      <div class="card">
        <h5>{{ 'TOTAL_ORDER'|translate }}</h5>
        <p-chart type="line" [data]="totalData"  height="300"></p-chart>
      </div>
    </div>
    <div class="p-col">
      <div class="card">
        <h5>{{ stock.name }} {{ 'ORDER'|translate }}</h5>
        <p-chart type="line" [data]="basicData"  height="300"></p-chart>
      </div>
    </div>
  </div>
  <div>
    <p-table [value]="stats" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'MONTH'|translate }}</th>
          <th>{{ 'UNIT_PRICE'|translate }}</th>
          <th>{{ 'QUANTITY'|translate }}</th>
          <th>{{ 'TOTAL'|translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stat>
        <tr>
          <td>{{stat.year}} / {{stat.month}}</td>
          <td>{{stat.price|currency: stock.currency.code}}</td>
          <td>{{stat.quantity}}</td>
          <td>{{stat.total|currency: stock.currency.code}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </div>
</p-card>
