<app-loading-indicator [loading]="loading"></app-loading-indicator>

<form (ngSubmit)="save()" *ngIf="!loading">
  <div class="p-fluid">
    <div class="p-field">
      <label for="name">{{'STOCK' | translate}}</label>
      <input id="name" type="text" pInputText [(ngModel)]="stock.name" name="name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-field">
      <label for="comment">{{'COMMENT' | translate}}</label>
      <input id="comment" type="text" pInputText [(ngModel)]="stock.comment" name="comment" >
    </div>
    <div class="p-field">
      <label for="salePrice">{{'SALE_PRICE' | translate}}</label>
      <p-inputNumber mode="currency" [currency]="currency" [(ngModel)]="stock.variants[0].salePrice" name="salePrice" id="salePrice"></p-inputNumber>
    </div>
    <div class="p-field">
      <label for="sku">{{'SKU' | translate}}</label>
      <input id="sku" type="text" pInputText [(ngModel)]="stock.variants[0].code" name="sku">
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-field">
      <label for="category">{{'CATEGORY' | translate}}</label>
      <p-dropdown [options]="categories" [(ngModel)]="stock.category" optionLabel="name" id="category" name="category" optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['category'] }}</small>
    </div>
    <div class="p-field">
      <label for="taxRate">{{'TAX' | translate}}</label>
      <p-dropdown [options]="taxes" [(ngModel)]="stock.tax" optionLabel="name" id="taxRate" name="taxRate" optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['tax'] }}</small>
    </div>
    <div class="p-field">
      <label for="currency">{{'CURRENCY' | translate}}</label>
      <p-dropdown [options]="currencies" (onChange)="onChangeCurrency($event)" [(ngModel)]="stock.currency" optionLabel="code" id="currency" name="currency" optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['currency'] }}</small>
    </div>
  </div>
  <span class="p-buttonset">
    <button pButton label="{{'SAVE' | translate}}" [icon]="submitted && !close ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted && !close"></button>
    <button pButton label="{{'SAVE_AND_CLOSE' | translate}}" [icon]="submitted && close ? 'pi pi-spin pi-spinner' : 'pi pi-check'" (click)="close = true" [disabled]="this.submitted && close"></button>
  </span>
</form>
