import { Component, OnInit } from '@angular/core';
import {FlashMessageService} from '../../service/flash-message.service';
import {Subscription} from 'rxjs';
import {MessageService} from 'primeng/api';
import {FlashMessageModel} from '../../model/flash-message.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-flash-messages',
  templateUrl: './flash-messages.component.html',
  styleUrls: ['./flash-messages.component.scss']
})
export class FlashMessagesComponent implements OnInit {

  subscriber: Subscription;

  constructor(private flashMessageService: FlashMessageService, private messageService: MessageService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    this.subscriber = this.flashMessageService.flashMessagesData.subscribe((message: FlashMessageModel) => {
      if (message instanceof FlashMessageModel) {
        this.messageService.add({severity: message.severity, summary: this.translateService.instant(message.message)});
      }
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
