import {ApiResourceModel} from './api-resource.model';
import {StockVariantModel} from './stock-variant.model';
import {StockModel} from './stock.model';

export class VariantRecipeItemModel extends ApiResourceModel{
  public static IRI = '/api/variant_recipe_items';
  public stock: StockModel|string;
  public variant: StockVariantModel|string;
  public quantity = 1;
  public amount: number;
  public firstAmount: number;
  public totalAmount: number;
  public note: string;
  public cost: number;
  public wastage: number;
  public firstWastage: number;
  public amountWithWastage = 0;
  public firstAmountWithWastage = 0;
  public recipe: string;
  public currency: string;
  public rate: number;
  public weight: number;
  public modifierItem: any;
  public extraItem: any;
  public type: string;
  public bigAmount: number;
  public batches: any [];
}
