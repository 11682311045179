<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form (ngSubmit)="save()" *ngIf="!loading">
  <div class="p-fluid p-mb-3">
    <p-table [value]="invoice.items">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'CODE' | translate}}</th>
          <th>{{'DELIVERED' | translate}}</th>
          <th *ngIf="trackByLot">{{'BARCODE' | translate}}</th>
          <th *ngIf="trackByLot && invoiceType.Purchase === invoice.type">{{'EXPIRATION_DATE' | translate}}</th>
          <th>{{'REMAINING' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-row>
        <tr *ngIf="!row.isReceived && (config.data.itemId === undefined || (config.data.itemId !== undefined && config.data.itemId === row.id))">
          <td>{{ row.variant.name }}</td>
          <td><p-inputNumber name="quantity" mode="decimal" [max]="row.remainingQuantity" [(ngModel)]="shipment.items[i].quantity" minFractionDigits="2" maxFractionDigits="5"></p-inputNumber></td>
          <td *ngIf="trackByLot && invoiceType.Purchase === invoice.type"><input *ngIf="trackByLots[i]" pInputText name="barcode"  [(ngModel)]="shipment.items[i].barcode"  /></td>
          <td *ngIf="trackByLot && invoiceType.Sales === invoice.type"><p-dropdown (onChange)="onBatchSelect($event, row.variant['@id'], i)" [(ngModel)]="shipment.items[i].batch" appendTo="body" placeholder="Barkod Seçiniz" *ngIf="trackByLots[i]"  name="barcode" [options]="batches[row.variant['@id']]" optionLabel="barcode" optionValue="@id" ></p-dropdown></td>
          <td *ngIf="trackByLot && invoiceType.Purchase === invoice.type"><p-calendar *ngIf="trackByLots[i]" appendTo="body" [ngModel]="shipment.items[i].expirationDate" name="expirationDate"></p-calendar></td>
          <td>{{row.remainingQuantity}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <button pButton [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" label="{{'SAVE' | translate}}"></button>
</form>

