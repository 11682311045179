import {ContactModel} from './contact.model';


export class ContactDeliveryAddressModel {
  public static IRI = '/api/delivery_addresses';
  public name: string;
  public contact: ContactModel;
  public address: string;
  public id: any;
}
