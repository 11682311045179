import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../../service/http.service';
import {IntegrationAttributeModel} from '../../../../model/integration-attribute.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CompanyModel} from '../../../../model/company.model';
import {CompanyIntegrationModel} from '../../../../model/company-integration.model';
import {IntegrationModel} from '../../../../model/integration.model';

@Component({
  selector: 'app-luca',
  templateUrl: './luca.component.html',
  styleUrls: ['./luca.component.scss']
})
export class LucaComponent implements OnInit {

  attributes: IntegrationAttributeModel[] = [];
  submitted: boolean;
  loading: boolean;
  company: CompanyModel = new CompanyModel();
  isActive = false;

  defaultAttributes = [{
    attrKey: 'musteriId',
    attrValue: '24852455'
  }, {
    attrKey: 'kullaniciKodu',
    attrValue: 'satis'
  }, {
    attrKey: 'parola',
    attrValue: 'kemal@2020'
  }, {
    attrKey: 'firmaId',
    attrValue: '12598900'
  }, {
    attrKey: 'donem',
    attrValue: '202101'
  }];

  constructor(private http: HttpService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.company = JSON.parse(localStorage.getItem('company'));

    if (this.company.id) {
      this.load();
    }
  }

  load(): void {
    this.loading = true;
    this.http.get(IntegrationAttributeModel.IRI, {'company.id': this.company.id, 'integration.id': this.config.data.id})
      .subscribe((response: IntegrationAttributeModel[]) => {
      // @ts-ignore
        if (response['hydra:totalItems'] > 0) {
        this.attributes = response['hydra:member'];
        this.isActive = true;
      } else {
        const attr = new IntegrationAttributeModel();
        // tslint:disable-next-line:no-shadowed-variable
        this.defaultAttributes.map((attr: IntegrationAttributeModel) => {
          attr.integration = `${IntegrationModel.IRI}/${this.config.data.id}`;
          this.attributes.push(attr);
        });
      }

        this.loading = false;
    });
  }

  async save(): Promise<void> {
    this.submitted = true;

    await Promise.all (this.attributes.map( async (attribute: IntegrationAttributeModel, key) => {
      if (attribute.id) {
        await this.http.put(`${IntegrationAttributeModel.IRI}/${attribute.id}`, attribute).then((response: IntegrationAttributeModel) => {
          this.attributes[key] = response;
        });
      } else {
        await this.http.post(IntegrationAttributeModel.IRI, attribute).then((response: IntegrationAttributeModel) => {
          this.attributes[key] = response;
        });
      }
    }));

    if (!this.isActive) {
      const companyIntegration = new CompanyIntegrationModel();
      companyIntegration.company = `${CompanyModel.IRI}/${this.company.id}`;
      companyIntegration.integration = `${IntegrationModel.IRI}/${this.config.data.id}`;
      companyIntegration.isActive = true;

      this.http.post(CompanyIntegrationModel.IRI, companyIntegration).then((response: CompanyIntegrationModel) => {
        this.ref.close({integration: response.integration['@id']});
      });
    } else {
      this.ref.close({integration: null});
    }

    this.submitted = false;
  }
}
