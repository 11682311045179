import {Component, Input, OnInit} from '@angular/core';
import {StockModel, StockTrackType} from '../../../model/stock.model';
import {StockImagesComponent} from '../../stock/stock-images/stock-images.component';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss'],
  providers: [DialogService]
})
export class ProductMenuComponent implements OnInit {

  @Input() item: StockModel = new StockModel();
  @Input() active: string;
  trackTypes = StockTrackType;

  constructor(private dialogService: DialogService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  images(): void {
    const ref = this.dialogService.open(StockImagesComponent, {
      data: {id: this.item['@id']},
      width: '50vw',
      header: this.translate.instant('stock.stockMediaObj'),
    });
  }
}
