import { Component, OnInit } from '@angular/core';
import {StockModel, StockTrackType} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {StockVariantComponent} from '../../stock-variant/stock-variant.component';
import {StockVariantOptionModel} from '../../../model/stock-variant-option.model';
import {StockVariantOptionValuesModel} from '../../../model/stock-variant-option-values.model';
import {CurrencyModel} from '../../../model/currency.model';
import { TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {CurrencyService} from '../../../service/currency.service';
import {ProductRecipeShowComponent} from '../../product/product-recipe-show/product-recipe-show.component';
import {ValidationService} from '../../../service/validation.service';
import {CreateCategoryComponent} from '../../settings/category/create-category/create-category.component';
import {CategoryService} from '../../../service/category.service';
import {TaxService} from '../../../service/tax.service';
import {CreateTaxComponent} from '../../settings/tax/create-tax/create-tax.component';
import {CreateUnitComponent} from '../../settings/unit/create-unit/create-unit.component';
import {UnitService} from '../../../service/unit.service';
import {ContactService} from '../../../service/contact.service';
import {ContactCreateDialogComponent} from '../../contact/contact-create-dialog/contact-create-dialog.component';

@Component({
  selector: 'app-create',
  templateUrl: './stock-update.component.html',
  styleUrls: ['./stock-update.component.scss'],
  providers: [DialogService, DynamicDialogConfig]
})
export class StockUpdateComponent implements OnInit {

  item: StockModel = new StockModel();
  loading: boolean;
  archiveLoading: boolean;
  stockCodeLoading: boolean;
  submitted: boolean;
  id: number;

  suppliers: ContactModel[] = [];
  taxes: TaxRateModel[] = [];
  categories: CategoryModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  trackTypes: any[] = [];
  errors: any = [];

  currency: string;

  variantOptions: StockVariantOptionModel[] = [];

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private dialogService: DialogService, private translate: TranslateService,
              private flashMessageService: FlashMessageService, private currencyService: CurrencyService,
              private validationService: ValidationService, private categoryService: CategoryService,
              private taxService: TaxService, private unitService: UnitService, private contactService: ContactService) { }

  async ngOnInit(): Promise<void> {

    setTimeout(() => {
      this.trackTypes = [
        {
          code: StockTrackType.None,
          name: this.translate.instant('NONE')
        },
        {
          code: StockTrackType.Default,
          name: this.translate.instant('DEFAULT')
        },
        {
          code: StockTrackType.Lot,
          name: this.translate.instant('LOT')
        }
      ];
    }, 500);

    this.loading = true;

    // @ts-ignore
    // tslint:disable-next-line:radix
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    if (this.id) {
      await this.loadItem();
    }

    await this.loadFormData();

    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
  }

  async loadItem(): Promise<void> {
    await this.http.get(`${StockModel.IRI}/${this.id}`).toPromise().then((response: StockModel) => {
      this.item = response;

      this.item.category = this.item.category ? this.item.category['@id'] : null;
      this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
      this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
      this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
      // @ts-ignore
      this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
      this.item.currency = this.item.currency ? this.item.currency['@id'] : null;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.item.variants.length; i++) {
        if (this.item.variants[i].stockVariantOptionValues.length > 0) {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < this.item.variants[i].stockVariantOptionValues.length; j++) {
            if (this.item.variants[i].stockVariantOptionValues[j].variantOptionValue) {
              // tslint:disable-next-line:max-line-length
              this.item.variants[i].stockVariantOptionValues[j].variantOptionValue = this.item.variants[i].stockVariantOptionValues[j].variantOptionValue['@id'];
              // tslint:disable-next-line:max-line-length
              this.item.variants[i].stockVariantOptionValues[j].variantOption = this.item.variants[i].stockVariantOptionValues[j].variantOption['@id'];
            }

          }
        } else {
          this.item.stockVariantOptions.map((item) => {
            const  variantOptions = new StockVariantOptionValuesModel();
            variantOptions.variantOption = item['@id'];
            variantOptions.variantOptionValue = item.optionValues[0]['@id'];
            this.item.variants[i].stockVariantOptionValues.push(variantOptions);
          });
        }
        if (this.item.stockVariantOptions.length > this.item.variants[i].stockVariantOptionValues.length) {
          const options = [];
          this.item.variants[i].stockVariantOptionValues.map((item) => {
            options.push(item.variantOption);
          });

          this.item.stockVariantOptions.map(item => {
            if (options.indexOf(item['@id']) < 0 && item.optionValues.length > 0) {
              const  variantOptions = new StockVariantOptionValuesModel();
              variantOptions.variantOption = item['@id'];
              variantOptions.variantOptionValue = item.optionValues[0]['@id'];
              this.item.variants[i].stockVariantOptionValues.push(variantOptions);
            }
          });
        }
      }
    });

    // this.loadVariantOptions();
  }

  async loadVariantOptions(): Promise<void> {
    await this.http.get(StockVariantOptionModel.IRI, {'stock.id': this.item.id}).subscribe((response: StockVariantOptionModel) => {
      this.variantOptions = response['hydra:member'];
      if (this.variantOptions.length > 0 && this.item.variants.length < 1) {
        this.item.variants = [];
        this.variantOptions[0].optionValues.map((item) => {
          this.addVariant();
        });
      }

      if (this.item.variants.length < 1 && this.variantOptions.length < 1) {
        this.item.variants.push(new StockVariantModel());
      }

      this.loading = false;
    });
  }

  async loadFormData(): Promise<void> {
    this.categoryService.getCategoriesAction({isActive: true});
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
    });

    this.taxService.getTaxesAction({isActive: true});
    this.taxService.getTaxes().subscribe(response => {
      this.taxes = response;
    });

    this.unitService.getUnitsAction({isActive: true});
    this.unitService.getUnits().subscribe(response => {
      this.units = response;
    });

    this.contactService.getContactsAction({pagination: false});
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.suppliers = response['hydra:member'];
      }
    });

    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
      if (this.currencies.length > 0 && this.item.currency == null) {
        this.item.currency = this.currencies[0]['@id'];
      }
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    await this.http.put(`${StockModel.IRI}/${this.item.id}`, this.item).then((response: StockModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
    });

    this.submitted = false;
  }

  async addVariant(): Promise<void> {
    const child = new StockVariantModel();

    this.stockCodeLoading = true;

    await this.http.get(`${StockModel.IRI}/stock_number`).toPromise().then((response: StockModel) => {
      if (response.variants[0].code <= this.item.variants[this.item.variants.length - 1].code) {
        // @ts-ignore
        // tslint:disable-next-line:radix
        let code = parseInt(this.item.variants[this.item.variants.length - 1].code);
        code++;
        child.code = code.toString();
      } else {
        child.code = response.variants[0].code;
      }
      this.stockCodeLoading = false;
    }).catch(() => {
      this.stockCodeLoading = false;
    });

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.variantOptions.length; i++) {
      const variantOptionValue = new StockVariantOptionValuesModel();
      variantOptionValue.variantOption = this.variantOptions[i]['@id'];
      variantOptionValue.variantOptionValue = this.variantOptions[i].optionValues[0]['@id'];
      child.stockVariantOptionValues.push(variantOptionValue);
    }

    this.item.variants.push(child);
  }

  removeVariant(i: number): void {
    if (this.item.variants[i].id !== undefined) {
      this.http.delete(`${StockVariantModel.IRI}/${this.item.variants[i].id}`).then(() => {
        this.item.variants.splice(i, 1);
        if (this.item.variants.length < 1) {
          this.router.navigate(['/stocks']);
        }
      });
    } else {
      this.item.variants.splice(i, 1);
    }
  }

  addVariantOption(): void {
    const ref = this.dialogService.open(StockVariantComponent, {
      data: {id: this.item['@id']},
      width: '50vw',
      header: this.translate.instant('VARIANT_OPTIONS'),
    });

    ref.onClose.subscribe(() => {
      this.ngOnInit();
    });
  }

  recipe(id: number): void {
    const ref = this.dialogService.open(ProductRecipeShowComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('PRODUCT_RECIPE'),
    });
  }

  changeCurrency(event): void {
    this.currencies.map((item) => {
      if (item['@id'] === event.value) {
        this.currency = item.code;
      }
    });
  }

  addCategory(): void {
    this.dialogService.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
    });
  }

  addTax(): void {
    this.dialogService.open(CreateTaxComponent, {
      width: '50vw',
      header: this.translate.instant('TAX_RATE_CREATE'),
    });
  }

  addUnit(): void {
    this.dialogService.open(CreateUnitComponent, {
      width: '50vw',
      header: this.translate.instant('UNIT_CREATE'),
    });
  }

  addContact(): void {
    this.dialogService.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
    });
  }

  async archive(): Promise<void> {
    this.archiveLoading = true;

    await this.http.put(`${StockModel.IRI}/${this.id}`, {isArchive: !this.item.isArchive}).then((response: StockModel) => {
      if (response) {
        this.item.isArchive = response.isArchive;
      }
    });

    this.archiveLoading = false;
  }
}
