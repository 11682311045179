import { Component, OnInit } from '@angular/core';
import {LocationModel} from '../../../../model/location.model';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationService} from '../../../../service/location.service';
import {UtilityService} from '../../../../service/utility.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-menu-group-comparison',
  templateUrl: './menu-group-comparison.component.html',
  styleUrls: ['./menu-group-comparison.component.scss']
})
export class MenuGroupComparisonComponent implements OnInit {
  events: any[] = [];
  options: any;
  info: any;
  monthly: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  location: LocationModel[] = [];
  date: Date[];
  dateRange: Date[] = [];
  parameters = {
    month : '',
    year: '',
    location: ''
  };
  currentMonth;
  monthNames;
  constructor(private http: HttpService, private translate: TranslateService,
              private locationService: LocationService, private utilityService: UtilityService) { }

  ngOnInit(): void {
    const now = new Date();
    this.monthNames = ['', 'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
   // this.currentMonth = this.monthNames[now.getMonth()];
    this.parameters.month = `${now.getMonth() + 1}`;
    this.parameters.year = `${now.getFullYear()}`;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.load().then();
  }
  async load(): Promise<void>{
    this.loading = true;
    this.currentMonth = this.monthNames[this.parameters.month] ;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
    await this.http.get('/api/reports/monthly_income_reports', this.parameters).subscribe(response => {
      this.monthly = response;
      this.loading = false;
    });
  }
  onSelectDate(event): void {
    const start = event;
    this.parameters.month = `${start.getMonth() + 1}`;
    this.parameters.year = `${start.getFullYear()}`;
    this.parameters = {...this.parameters};
  }
  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği


    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);

      pdf.save(`menu_group_comparison.pdf`); // PDF'i indir
    });
  }
  export(): void {
    const parameters = {...this.parameters};
    this.http.download('/api/data/exports/menuGroupReport', parameters)
        .subscribe(r => {
          this.utilityService.downloadFile(r, 'menu_group_report_monthly.xlsx');
        });
  }
}
