import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {UserModel} from './user.model';
import {InventoryItemModel} from './inventory-item.model';

export class InventoryModel extends ApiResourceModel{
  public static IRI = '/api/inventories';
  code: number;
  locations: LocationModel[] = [];
  startDate: Date|string;
  endDate: Date|string;
  info: string;
  method: string;
  status: string;
  series: string;
  number: number;
  updateUser: UserModel;
  items: InventoryItemModel[] = [];
  mainCategories: any[];
  id: any;

}

export enum InventoryStatus {
  REGISTERED = 'OPENED_INVENTORY',
  LOCKED = 'LOCKED_INVENTORY',
  CLOSED = 'CLOSED_INVENTORY'
}

