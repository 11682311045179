import {ApiResourceModel} from './api-resource.model';
import {ShipmentModel} from './shipment.model';
import {InvoiceItemModel} from './invoice-item.model';

export class ShipmentItemModel extends ApiResourceModel{
  public shipment: ShipmentModel;
  public invoiceItem: InvoiceItemModel|string;
  public quantity: number;
  public purchaseOptionQuantity: number;
  public total: number;
  public price: number;

}
