import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnWidth'
})
export class ColumnWidthPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // @ts-ignore
    return value[args[0]] !== undefined && value[args[0]][[args[1]]] !== undefined ? value[args[0]][[args[1]]] + 'px !important' : '';
  }

}
