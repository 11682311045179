import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {MediaObjectModel} from '../../../model/media-object.model';
import {StockMediaObjectModel} from '../../../model/stock-media-object.model';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { environment } from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {StockVariantModel} from '../../../model/stock-variant.model';

@Component({
  selector: 'app-stock-images',
  templateUrl: './stock-images.component.html',
  styleUrls: ['./stock-images.component.scss']
})
export class StockImagesComponent implements OnInit {

  items: StockMediaObjectModel[] = [];
  variants: StockVariantModel[] = [];
  mediaObject = new StockMediaObjectModel();
  apiUrl: string;
  id: number;
  loading: boolean;
  deleting: number;

  constructor(private http: HttpService, private config: DynamicDialogConfig, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), null);
    this.loadVariants();
    this.load();
  }

  load(): void {
    this.loading = true;
    this.http.get(StockMediaObjectModel.IRI, {'stock.id': this.id}).subscribe((response: StockMediaObjectModel[]) => {
      this.items = response['hydra:member'];
      this.loading = false;
    });
  }

  loadVariants(): void {
    this.http.get(StockVariantModel.IRI, {'stock.id': this.id}).subscribe((response: StockVariantModel[]) => {
      this.variants = response['hydra:member'];
      if (this.variants.length > 0) {
        this.mediaObject.variant = this.variants[0]['@id'];
      }
    });
  }

  async upload(event, fileUpload): Promise<void> {

    for (const file of event.files) {
      const fd = new FormData();
      fd.append('file', file);

      await this.http.post(MediaObjectModel.IRI, fd).then((response: StockMediaObjectModel) => {
        this.http.post(StockMediaObjectModel.IRI, {
          stock: this.config.data.id,
          mediaObject: response['@id'],
          description: this.mediaObject.description,
          variant: this.mediaObject.variant
          // tslint:disable-next-line:no-shadowed-variable
        }).then((response: StockMediaObjectModel) => {
          this.load();
        });
      }).catch(error => {
      });
    }

    fileUpload.clear();
  }

  showInWorkOrder(event, id: string): void {
    this.http.put(id, {
      showInWorkOrder: event.checked
    }).then(response => {
    });
  }

  delete(id: number, i: number): void {
    this.deleting = i;
    this.http.delete(`${StockMediaObjectModel.IRI}/${id}`).then(() => {
      this.items.splice(i, 1);
      this.deleting = null;
    });
  }
}
