import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../../service/http.service';
import {ProductRecipeModel} from '../../../model/product-recipe.model';
import {WorkOrderModel} from '../../../model/work-order.model';
import {ValidationService} from '../../../service/validation.service';
import {Subscription} from 'rxjs';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {LocationModel} from '../../../model/location.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {ProduceCompanyService} from '../../../service/produce-company.service';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {FlashMessageService} from '../../../service/flash-message.service';
import {InvoiceModel} from '../../../model/invoice.model';
import {SidebarService} from '../../../service/sidebar.service';
import {StockQuantityBatchModel} from '../../../model/stock-quantity-batch.model';
import {VariantRecipeModel} from '../../../model/variant-recipe.model';
import {UserPageInformationService} from '../../../service/user-page-information.service';
import {UserPageInformationModel} from '../../../model/user-page-information.model';
import {UserModel} from '../../../model/user.model';
import {delay} from 'rxjs/operators';
import {CompanyModel} from '../../../model/company.model';

@Component({
  selector: 'app-planning-view',
  templateUrl: './planning-view.component.html',
  styleUrls: ['./planning-view.component.scss']
})
export class PlanningViewComponent implements OnInit {
  loading: boolean;
  items: any[] = [];
  tableType = 'invoices';
  recipeDialog: boolean;
  recipeId: number;
  recipe: ProductRecipeModel[] = [];
  selectedLocation: any;
  display: boolean;
  workOrder = new WorkOrderModel();
  errors = [];
  validationSubscriber: Subscription;
  locations: LocationModel[] = [];
  getVariant: StockVariantModel[] = [];
  submitted: boolean;
  isSaved: boolean;
  produceContact: any;
  invoices = [
    {
      key: 0,
      supplierName: '',
      data: {
        code: '',
        contact: '',
        currency: '',
        discountRate: null,
        expectedDate: null,
        location: '',
        number: 0,
        series: '',
        status: 'CONFIRMED',
        subtotal: 0,
        tax: 0,
        total: 0,
        type: 'PURCHASE',
        description: '',
        items: []
      }
    }
  ];
  invoicesItems = [];
  selectInvoicesItems = [];
  invoiceInfo: boolean;
  invoiceInfoComment: any;
  invoiceRouteId: any;
  activeInvoice: number;
  supplierCount: number;
  item: StockVariantModel[] = [];
  itemClickValues: number;
  worksOrders: WorkOrderModel[] = [];
  parameters = {
    page: 1,
    code: '',
    name: '',
    'variant.name': '',
    plannedQuantity: '',
    plannedTime: '',
    status: 'NOT_STARTED',
    materialStatus: '',
    deadline: [],
    itemsPerPage: 20,
    'exists[parent]': false,
    'invoice.contact.name': '',
    planning: 1
  };
  batchParameters = {
    page: 1,
    'variant.name': '',
    itemsPerPage: 20,
    isActive: 1,
    'order[variant.name]': 'ASC',
  };
  lotTrackingView: boolean;
  lotTrackingItemView: boolean;
  batchesDisplay: any;
  batches: StockQuantityBatchModel[] = [];
  loadRecipesData: ProductRecipeModel[] = [];
  loadRecipeDisplay: any;
  newWorkOrder: boolean;

  infoTextShowDisplay = false;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;
  manufacturingLocation: string;
  manufacturingLocationDisplay: boolean;
  tableLoading: boolean;
  company: CompanyModel = new CompanyModel();

  constructor(private translate: TranslateService,
              private route: ActivatedRoute,
              private http: HttpService,
              private validationService: ValidationService,
              private produceCompanyService: ProduceCompanyService,
              private confirmationService: ConfirmationService,
              private flashMessageService: FlashMessageService,
              public sidebarService: SidebarService, private userPageInformationService: UserPageInformationService) {
  }

  ngOnInit(): void {
    this.getUserInformation();
    this.load();
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
    this.invoices = [];
  }

  reload(): void {
    this.load();
  }

  load(event: LazyLoadEvent = null): void {
    this.submitted = true;
    this.tableLoading = true;
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    let parameters = {...this.parameters};
    if (this.parameters.deadline) {
      const start = new Date(this.parameters.deadline[0]);
      const end = new Date(this.parameters.deadline[1]);

      parameters = {
        ...parameters, ...{
          'deadline[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'deadline[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parameters.deadline;
    }
    this.selectedLocation = localStorage.getItem('manufacturingLocation');
    this.loading = true;
    this.http.get('/api/planning/get-invoice-items-for-planning', {location: this.selectedLocation}).subscribe(response => {
      this.items = response.data;
      if (response.success === true) {
        this.http.get(WorkOrderModel.IRI, parameters).subscribe((worksOrder: WorkOrderModel[]) => {
          // @ts-ignore
          this.total = worksOrder['hydra:totalItems'];
          this.worksOrders = worksOrder['hydra:member'];
          this.loading = false;
          this.submitted = false;
          this.tableLoading = false;
        });
      }
    });

    this.http.get(LocationModel.IRI + '/subscriptionControl', {isActive: true}).subscribe(response => {
      this.locations = response;
      if (this.locations.length > 0) {
        this.workOrder.source = this.locations[0]['@id'];
        this.workOrder.target = this.locations[0]['@id'];
      }
    });
  }

  async onLoadRecipes(id): Promise<void> {
    this.loading = true;
    this.recipeId = id;
    await this.http.get(ProductRecipeModel.IRI, {'items.stock.id': id}).subscribe(res => {
      this.recipe = res['hydra:member'];
      this.loading = false;
      this.recipeDialog = true;
    });
  }

  produce(row): void {
    this.workOrder = row;
    if (row.stock.isTrackByLot === true) {
      this.lotTrackingView = true;
    }
    this.workOrder.deadline = new Date();
    this.workOrder.barcode = row.variant?.barcode + '' + this.workOrder.number;
    this.materialCheck(this.workOrder.materials);
    this.display = true;
  }

  materialCheck(materials): void {
    materials.map((item, i) => {
      item.batchesData = [];
      item.batches = [];
      if (item.variant.stock?.isTrackByLot === true) {
        this.lotTrackingItemView = true;
        this.http.get(StockQuantityBatchModel.IRI, {
          'variant.id': item.variant.id
        }).subscribe(response => {

          // @ts-ignore
          response['hydra:member'].map((batch) => {
            const newBatch = {
              batch: batch['@id'],
              barcode: batch.barcode,
              amount: batch.amount
            };
            item.batchesData.push(newBatch);
          });
          /*
          material.batchesData = response['hydra:member'];
           */
        });
      }
    });
  }

  async saveWorkOrder(): Promise<void> {
    this.submitted = true;
    this.workOrder.planning = false;
    this.workOrder.stock = this.workOrder.stock['@id'];
    this.workOrder.variant = this.workOrder.variant['@id'];
    this.workOrder.source = this.workOrder.source['@id'];
    this.workOrder.target = this.workOrder.target['@id'];
    this.workOrder.materials.map((item, i) => {
      item.batches = [];
      if (item.variant.stock.isTrackByLot === true) {
        item.batches.push(item.batchesData[item.batchIndex]);
      }
      item.variant = item.variant['@id'];
    });
    this.workOrder.invoices.map((item, i) => {
      this.workOrder.invoices[i] = item['@id'];
    });
    this.workOrder.invoiceItems.map((item, i) => {
      this.workOrder.invoiceItems[i] = item['@id'];
    });
    await this.http.put(`${WorkOrderModel.IRI}/${this.workOrder.id}`, this.workOrder).then((response: WorkOrderModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
      }
      this.display = false;
      this.load();
    }).catch(error => {
    });
  }

  recalculateMaterials(event): void {
    this.isSaved = false;
    this.workOrder.plannedQuantity = parseFloat(event.target.value);
    this.workOrder.materials.map((item, i) => {
      this.workOrder.materials[i].itemStatus = 'AVAILABLE';
      this.workOrder.materials[i].totalQuantity = this.workOrder.materials[i].wastage * parseFloat(event.target.value);
      if (this.workOrder.materials[i].totalQuantity > this.workOrder.materials[i].stockQuantity) {
        // @ts-ignore
        this.workOrder.materials[i].itemStatus = 'NOT_AVAILABLE';
        this.isSaved = true;
      }
    });
  }

  onChangeTargetLocation(event): void {
  }

  async createPurchase(row): Promise<void> {
    await this.http.get(`${StockVariantModel.IRI}/${row.variant.id}`).subscribe((response) => {
      let locationPrice = 0;
      // tslint:disable-next-line:max-line-length
      const locationPriceIndex = response.stock.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.selectedLocation);
      if (locationPriceIndex !== -1) {
        locationPrice = response.stock.stockQuantities[locationPriceIndex].lastPurchasePrice;
      }
      this.invoicesItems = [{
        currency: response.stock.currency['@id'] ? response.stock.currency['@id'] : null,
        nonDiscountedUnitPrice: 0,
        quantity: response.minOrder,
        stock: response.stock['@id'],
        name: response.name,
        discount: 0,
        code: response.code,
        subtotal: response.minOrder * locationPrice,
        tax: (locationPrice * response.minOrder) * (response.stock.tax.rate / 100),
        taxRate: response.stock.tax['@id'],
        unitPrice: locationPrice,
        variant: response['@id'],
        // tslint:disable-next-line:max-line-length
        totalOrder: 1000,
        unit: response.stock.unit?.name,
        orderType: response.stock.purchaseOptions[0].name,
        purchaseOption: response.stock.purchaseOptions[0]['@id']
      }];
      const objIndex = this.invoices.findIndex((obj => obj.key === response.stock.supplier.id));
      if (objIndex === -1) {
        this.invoices.push({
          key: response.stock.supplier.id,
          supplierName: response.stock.supplier.name,
          data: {
            code: '',
            contact: response.stock.supplier['@id'],
            currency: response.stock.currency['@id'],
            discountRate: null,
            expectedDate: new Date(),
            location: '/api/locations/' + localStorage.getItem('manufacturingLocation'),
            number: 0,
            series: '',
            status: 'WAITING_FOR_APPROVAL',
            subtotal: 0,
            tax: 0,
            total: 0,
            type: 'PURCHASE',
            description: '',
            // @ts-ignore
            isReceived: false,
            items: this.invoicesItems
          }
        });
        this.supplierCount = this.invoices.length;
        this.activeInvoice = this.invoices.length - 1;
        // SubTotal Calculation
        let sum = 0;
        this.invoices[this.activeInvoice].data.items.map(item => {
          sum += item.subtotal;
        });

        this.invoices[this.activeInvoice].data.subtotal = sum;
        this.invoices[this.activeInvoice].data.tax = sum * (response.stock.tax.rate / 100);
        this.invoices[this.activeInvoice].data.total = sum + (sum * (response.stock.tax.rate / 100));
        this.loading = false;

      } else {
        // tslint:disable-next-line:max-line-length
        const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === response.stock['@id']));
        if (objIndexStock !== -1) {
          this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
        } else {
          this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
        }
        this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
        let sum = 0;
        this.invoices[this.activeInvoice].data.items.map(item => {
          sum += item.subtotal;
        });
        this.activeInvoice = objIndex;
        this.invoices[this.activeInvoice].data.subtotal = sum;
        this.invoices[this.activeInvoice].data.tax = sum * (response.stock.tax.rate / 100);
        this.invoices[this.activeInvoice].data.total = sum + (sum * (response.stock.tax.rate / 100));
      }
    });
  }

  onInvoiceItemClick(i): void {
    if (this.itemClickValues === i) {
      this.itemClickValues = -1;
    } else {
      this.itemClickValues = i;
    }
  }

  removeInvoiceItem(i, key, stock): void {
    this.itemClickValues = -1;
    this.invoices[this.activeInvoice].data.items.splice(i, 1);
    if (this.invoices[this.activeInvoice].data.items.length === 0) {
      const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
      this.invoices.splice(objInvoiceIndex, 1);
      this.activeInvoice = this.invoices.length - 1;
      // this.supplierCount = this.invoices.length - 1;
    }
    this.supplierCount = this.invoices.length;
  }

  getLastQuantity(event, item, i): void {
    this.invoices[this.activeInvoice].data.items[i].quantity = event.value;
    this.invoices[this.activeInvoice].data.items[i].totalOrder = event.value;
    this.invoices[this.activeInvoice].data.items[i].subtotal = event.value * item.unitPrice;
    this.invoiceRecalculate();
  }

  invoiceRecalculate(): void {
    let subtotal = 0;
    this.invoices[this.activeInvoice].data.items.map((item, i) => {
      subtotal += item.quantity * item.unitPrice;
    });
    this.invoices[this.activeInvoice].data.subtotal = subtotal;
  }

  removeInvoice(key): void {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_YOU_SURE_THAT_YOU_WANT_TO_PERFORM_THIS_ACTION'),
      accept: () => {
        const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
        this.invoices.splice(objInvoiceIndex, 1);
        this.activeInvoice = this.invoices.length - 1;
        this.supplierCount = this.invoices.length;

        this.setInvoicesLocalStorage();
      }
    });
  }

  setInvoicesLocalStorage(): void {
    window.localStorage.setItem('invoices', JSON.stringify(this.invoices));
  }

  async save(key): Promise<void> {
    this.submitted = true;
    const dataCount = this.invoices.length;
    const invoiceIndex = this.invoices.findIndex((obj => obj.key === key));
    await this.http.get(`${InvoiceModel.IRI}/invoice-number?type=PURCHASE`).subscribe((res: InvoiceModel) => {
      this.invoices[invoiceIndex].data.number = res.number;
      this.invoices[invoiceIndex].data.code = res.code;
      this.invoices[invoiceIndex].data.series = res.series;
      this.http.post(InvoiceModel.IRI, this.invoices[invoiceIndex].data).then((response: InvoiceModel) => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
          this.invoices.splice(objInvoiceIndex, 1);
          this.activeInvoice = this.invoices.length - 1;
          this.supplierCount = this.invoices.length;
          this.submitted = false;
          this.invoiceInfoComment = 'Invoice Number : ' + response.code;
          this.invoiceRouteId = response.id;
          this.invoiceInfo = true;
          this.setInvoicesLocalStorage();
        } else {
          this.submitted = false;
        }
      }).catch(err => {
      });
    });
  }

  createBatch(event, row): void {
    console.log(event);
    console.log(event.value.batch);
    row.batchIndex = row.batchesData.findIndex(obj => obj.batch === event.value.batch);
    console.log(row);
  }

  getStockBatches(event: LazyLoadEvent = null): void {
    if (event) {
      this.batchParameters.page = event.first / this.batchParameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.batchParameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.batchParameters = Object.fromEntries(Object.entries(this.batchParameters).filter(([_, v]) => v != ''));

    this.loading = true;
    this.http.get(StockQuantityBatchModel.IRI, this.batchParameters).subscribe(response => {
      this.batches = response['hydra:member'];
      this.loading = false;
      this.batchesDisplay = true;
    });
  }

  calculateDay(date): number {

    const today = new Date();
    // @ts-ignore
    const futureDate = new Date(date);
    const diffInTime = futureDate.getTime() - today.getTime();
    const remainingDay = Math.ceil(diffInTime / (1000 * 3600 * 24));
    if (remainingDay < 0) {
      return 0;
    } else {
      return remainingDay;
    }
  }

  loadRecipes(id): void {
    this.loading = true;
    this.http.get(`${VariantRecipeModel.IRI}/stockInRecipes/${id}`).subscribe((response) => {
      this.loadRecipesData = response;
      this.loadRecipeDisplay = true;
      this.loading = false;
    });
  }

  createNewWorkOrder(): void {
    this.newWorkOrder = true;
  }

  getUserInformation(): void {
    this.manufacturingLocation = localStorage.getItem('manufacturingLocation');
    if (this.manufacturingLocation === 'null') {
      this.manufacturingLocationDisplay = true;

      this.http.get(CompanyModel.IRI).subscribe((response: CompanyModel) => {
        this.company = response;
      });
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryComponent'});
    this.userPageInformationService.getItems().subscribe(response => {
      if (response !== null) {
        this.infoTextData = response;
        this.infoTextShowDisplay = this.infoTextData[0].isActive;
      } else {
        this.infoTextShowDisplay = true;
      }
    });
  }

  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'PLANNING';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'PLANNING'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }

  async saveCompany(): Promise<void> {
    await this.http.put(`${CompanyModel.IRI}/${this.company.id}`, {
      defaultManufacturingLocation: this.company.defaultManufacturingLocation
    }).then((response: CompanyModel) => {
      if (response !== undefined) {
        const locationParts = response.defaultManufacturingLocation.split('/');
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        localStorage.setItem('manufacturingLocation', locationParts[locationParts.length - 1]);
        this.manufacturingLocationDisplay = false;
      }
    });
  }

  loadLocations(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
    });
  }
}
