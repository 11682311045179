import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {InvoiceModel} from '../../../model/invoice.model';
import {WorkOrderModel} from '../../../model/work-order.model';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-invoice-productions',
  templateUrl: './invoice-productions.component.html',
  styleUrls: ['./invoice-productions.component.scss']
})
export class InvoiceProductionsComponent implements OnInit {

  productionsLoading: boolean;
  type = 'workOrder';
  invoice = new InvoiceModel();
  workOrder = new WorkOrderModel();

  constructor(private http: HttpService, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.productionsLoading = true;

    if (this.config.data.type === 'invoice') {
      this.type = this.config.data.type;
      this.http.get(`${InvoiceModel.IRI}/${this.config.data.id}/productions`).subscribe((response: InvoiceModel) => {
        this.invoice = response;

        this.productionsLoading = false;
      });
    } else {
      this.http.get(`${WorkOrderModel.IRI}/${this.config.data.id}/productions`).subscribe((response: WorkOrderModel) => {
        this.workOrder = response;

        this.productionsLoading = false;
      });
    }
  }

}
