<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'MONTHLY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEM_GROUP'"></app-page-info>
      <p-button icon="pi pi-file-excel" (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
                  [(ngModel)]="date" view="month" dateFormat="mm/yy"
                  (ngModelChange)="onSelectDate($event)"></p-calendar>

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <app-report-tabs pageName="menu-group-comparison"></app-report-tabs>
  <div class="div-sums-container p-flex-row">
    <div *ngFor="let m of monthly, let i = index" class="div-sums p-flex-row">
      <div class="p-col"><b>{{this.monthNames[monthly[i][0].month] | translate}} {{monthly[i][0].year}}</b></div>
    </div>
    <div class="p-col div-sums p-flex-row"><b>{{ 'MONTHLY_INCOME_REPORT'| translate}}</b></div>
  </div>
  <div class="p-grid" id="pdf-content">
    <div class="p-col-2" [style]="{paddingRight: '0'}">
      <p-table [value]="monthly[0]" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr style="line-height: 7px">
            <th [style]="{width: '100%'}">{{'PRODUCT' | translate}}</th>
          </tr>
          <tr style="line-height: 7px">
            <th style="color: #47bfd4">Deneme</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr style="border-right: 1px solid #47bfd4">
            <td *ngIf="row.name.length > 20" class="info">{{row.name | slice:0:20 }}...<span
              class="tooltip"><b>{{ row.name }}</b></span></td>
            <td *ngIf="row.name.length <= 20"><b>{{ row.name }}</b></td>
          </tr>
          <tr style="border-right: 1px solid #47bfd4">
            <td colspan="2" style="padding: 0">
              <p-table [value]="row.subCategories" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                <ng-template pTemplate="body" let-row>
                  <tr [style]="{cursor: 'pointed', borderBottom: '1px black'}">
                    <td *ngIf="row.name.length > 20" class="info">{{row.name | slice:0:20 }}...<span
                      class="tooltip">{{ row.name }}</span></td>
                    <td *ngIf="row.name.length <= 20">{{ row.name }}</td>
                  <tr>
                    <td style="padding: 0" *ngIf="row.item">
                      <p-table [value]="row.item" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                        <ng-template pTemplate="body" let-row>
                          <tr [style]="{cursor: 'pointed'}">
                            <td *ngIf="row.name.length > 20" class="info">{{row.name | slice:0:20 }}...<span
                              class="tooltip">{{ row.name }}</span></td>
                            <td *ngIf="row.name.length <= 20">{{ row.name }}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-10 p-grid">
      <div class="p-col-4" *ngFor="let m of monthly, let i = index" [style]="{padding: '0', margin: '8px 0'}">
        <p-table [value]="monthly[i]" styleClass="p-datatable-sm">
          <ng-template pTemplate="header">
            <tr style="line-height: 7px">
              <th colspan="4"
                  [style]="{textAlign:'center'}">{{this.monthNames[monthly[i][0].month] | translate}} {{monthly[i][0].year}}</th>
            </tr>
            <tr style="line-height: 7px">
              <th [style]="{textAlign:'center'}">{{'SALE' | translate}}</th>
              <th [style]="{textAlign:'center'}">{{'S%' | translate}}</th>
              <th [style]="{textAlign:'center'}">{{'COST' | translate}}</th>
              <th [style]="{textAlign:'center'}">{{'C%' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cat>
            <tr style="border-right: 1px solid #47bfd4">
              <td [style]="{textAlign:'right'}"
                  *ngIf="cat.sale.total != null">{{cat.sale.total | number: '1.1-2'}}{{info.currency}}</td>
              <td [style]="{textAlign:'right'}"
                  *ngIf="cat.sale.total == null">{{0 | number: '1.1-2'}}{{info.currency}}</td>
              <td [style]="{textAlign:'right'}"
                  *ngIf="cat.sale.total != null"> {{ (cat.sale.total / cat.totalSale.total) * 100| number: '1.1-2'}}%
              </td>
              <td [style]="{textAlign:'right'}" *ngIf="cat.sale.total == null">{{0 | number: '1.1-2'}}%</td>
              <td [style]="{textAlign:'right'}"
                  *ngIf="cat.sale.totalCOST != null">{{cat.sale.totalCOST | number: '1.1-2'}}{{info.currency}}</td>
              <td [style]="{textAlign:'right'}"
                  *ngIf="cat.sale.totalCOST == null">{{0 | number: '1.1-2'}}{{info.currency}}</td>
              <td [style]="{textAlign:'right'}"
                  *ngIf="cat.sale.totalCOST != null"> {{ (cat.sale.totalCOST / cat.totalSale.totalCOST) * 100| number: '1.1-2'}}
                %
              </td>
              <td [style]="{textAlign:'right'}" *ngIf="cat.sale.totalCOST == null">{{0 | number: '1.1-2'}}%</td>
            </tr>
            <tr style="border-right: 1px solid #47bfd4">
              <td colspan="4" style="padding: 0">
                <p-table [value]="cat.subCategories" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                  <ng-template pTemplate="body" let-sub>
                    <tr style="border-right: 1px solid #47bfd4">
                      <td [style]="{textAlign:'right'}"
                          *ngIf="sub.sale.total != null">{{sub.sale.total | number: '1.1-2'}}{{info.currency}}</td>
                      <td [style]="{textAlign:'right'}"
                          *ngIf="sub.sale.total == null">{{0 | number: '1.1-2'}}{{info.currency}}</td>
                      <td [style]="{textAlign:'right'}"
                          *ngIf="cat.sale.total != null">{{(sub.sale.total / cat.sale.total) * 100 | number: '1.1-2'}}%
                      </td>
                      <td [style]="{textAlign:'right'}" *ngIf="cat.sale.total == null">{{0 | number: '1.1-2'}}%</td>
                      <td [style]="{textAlign:'right'}"
                          *ngIf="sub.sale.totalCOST != null">{{sub.sale.totalCOST | number: '1.1-2'}}{{info.currency}}</td>
                      <td [style]="{textAlign:'right'}"
                          *ngIf="sub.sale.totalCOST == null">{{0 | number: '1.1-2'}}{{info.currency}}</td>
                      <td [style]="{textAlign:'right'}"
                          *ngIf="cat.sale.totalCOST != null">{{(sub.sale.totalCOST / cat.sale.totalCOST) * 100 | number: '1.1-2'}}
                        %
                      </td>
                      <td [style]="{textAlign:'right'}" *ngIf="cat.sale.totalCOST == null">{{0 | number: '1.1-2'}}%</td>
                    <tr>
                      <td colspan="4" style="padding: 0" *ngIf="sub.item">
                        <p-table [value]="sub.item" styleClass="p-datatable-sm" [style]="{width: '100%'}">
                          <ng-template pTemplate="body" let-row>
                            <tr style="border-right: 1px solid #47bfd4">
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.total != null">{{row.sale.total | number: '1.1-2'}}{{info.currency}}</td>
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.total == null">{{0 | number: '1.1-2'}}{{info.currency}}</td>
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.total != null">{{(row.sale.total / cat.sale.total) * 100 | number: '1.1-2'}}
                                %
                              </td>
                              <td [style]="{textAlign:'right'}" *ngIf="row.sale.total == null">{{0 | number: '1.1-2'}}
                                %
                              </td>
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.totalCOST != null">{{row.sale.totalCOST | number: '1.1-2'}}{{info.currency}}</td>
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.totalCOST == null">{{0 | number: '1.1-2'}}{{info.currency}}</td>
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.totalCOST != null">{{(row.sale.totalCOST / cat.sale.totalCOST) * 100 | number: '1.1-2'}}
                                %
                              </td>
                              <td [style]="{textAlign:'right'}"
                                  *ngIf="row.sale.totalCOST == null">{{0 | number: '1.1-2'}}%
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-card>

