import {Component, OnInit, ViewChild} from '@angular/core';
import {ContactModel} from '../../../model/contact.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {StockModel} from '../../../model/stock.model';
import {CategoryModel} from '../../../model/category.model';
import {CategoryService} from '../../../service/category.service';
import {LocationModel} from '../../../model/location.model';
import {DatePipe} from '@angular/common';
import {InvoiceModel} from '../../../model/invoice.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {DialogService} from 'primeng/dynamicdialog';
import {ContactService} from '../../../service/contact.service';
import {UnitModel} from '../../../model/unit.model';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {LocationService} from '../../../service/location.service';
import {UserModel} from '../../../model/user.model';
import {environment} from '../../../../environments/environment';
import {InventoryModel} from '../../../model/inventory.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {StockAccountingCodeModel} from '../../../model/stock-accounting-code.model';
import {UserTypeService} from '../../../service/user-type.service';
import 'primeflex/primeflex.css';
import {SidebarService} from '../../../service/sidebar.service';
import { Table } from 'primeng/table';
import {any} from 'codelyzer/util/function';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
  providers: [DialogService],
})
export class NewOrderComponent implements OnInit {
  id: any;
  stocks: StockModel[] = [];
  noData: boolean;
  loading: boolean;
  submitted: boolean;
  parameters = {
    id: '',
    type: 'material',
    'variants.code': '',
    name: '',
    'supplier.name': '',
    'category.name': '',
    'variants.price': '',
    'supplier.id': '',
    'category.id': '',
    isActive: 1,
    locations: '',
    page: 0,
    itemsPerPage: 20,
  };
  invoices = [
    {
      key: 0,
      supplierName: '',
      data: {
        code: '',
        contact: '',
        currency: '',
        discountRate: null,
        expectedDate: null,
        location: '',
        number: 0,
        series: '',
        status: 'CONFIRMED',
        subtotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
        type: 'PURCHASE',
        description: '',
        items: []
      }
    }
  ];
  invoicesItems = [];
  selectInvoicesItems = [];
  totalRecords: number;
  contacts: ContactModel[] = [];
  searchContacts: ContactModel[] = [];
  categories: CategoryModel[] = [];
  locations: LocationModel[] = [];
  searchLocations: LocationModel[] = [];
  selectedLocation: string;
  supplierCount: number;
  display: boolean;
  activeInvoice: number;
  firstValue: any;
  stockDialog: boolean;
  displayInvoiceStatus: boolean;
  displaySideBar: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  order: any;
  stockOrderQuantityValues = [{
    key: 0,
    location: '',
    data: {
      stock: '',
      orderQuantity: 0,
      option: ''
    }
  }];
  invoiceInfo: boolean;
  invoiceInfoComment: any;
  invoiceRouteId: any;
  actionItems: MenuItem[] = [];
  dataImportDialog: boolean;
  total: number;
  tableLoading: boolean;
  moreLoading: boolean;
  displayAddContact: boolean;
  displaySuppliers: boolean;
  count: number;
  itemClickValues: number;
  user: UserModel = new UserModel();
  apiUrl: string;
  stockQuantityId: number;
  displaySuppliersCount: number;
  searchCategory: any;
  searchCategories = [];
  option: any;
  b: number;
  c: number;
  firstoption: any;
  stockValues = [{
    invoice: 0,
    stock: '',
    option: '',
    orderQuantity: 0
  }];
  activeInventory: InventoryModel;
  today: Date = new Date();
  minDate: Date;
  maxDate: Date;
  accountingCodeAlert: boolean;
  isLocationAccountingCode: boolean;
  actions: any[];
  userType: string;
  lang: string;
  displayLocations: boolean;
  displayAction: boolean;
  @ViewChild('table') table: Table;

  constructor(private http: HttpService, private route: ActivatedRoute,
              private translate: TranslateService,
              private categoryService: CategoryService,
              private contactService: ContactService,
              private locationService: LocationService,
              private router: Router,
              private datePipe: DatePipe,
              private dialog: DialogService,
              private confirmationService: ConfirmationService,
              private flashMessageService: FlashMessageService, private userTypeService: UserTypeService,
              private sidebarService: SidebarService) {
  }

  ngOnInit(): void {
    const nullArray = [];
    if (window.localStorage.getItem('invoices') === null) {
      window.localStorage.setItem('invoices', JSON.stringify(nullArray));
    }
    this.userTypeService.data$.subscribe(data => {
      this.userType = data;
    });
    this.displaySuppliersCount = 0;
    this.apiUrl = environment.apiUrl;
    const userJson = localStorage.getItem('user');
    if (userJson) {
      this.user = JSON.parse(userJson);
    }
    this.onLoad();
    this.parameters.page = 0;
    this.activeInvoice = 1;
    this.firstValue = '';
    this.actionItems = [
      {
        label: this.translate.instant('ADD_NEW_PRODUCT'),
        command: () => {
          this.openForm();
        }
      },
      {
        label: this.translate.instant('IMPORT_INVENTORY_ITEMS'),
        command: () => {
          this.dataImport();
        }
      },
      {
        label: this.translate.instant('WEEKLY_ORDERS_SCHEDULES')
      },
    ];
    this.getActiveInventory().then();
    this.actions = JSON.parse(localStorage.getItem('actions'));
    this.invoices =  JSON.parse(localStorage.getItem('invoices'));
    if (this.invoices.length > 0){
      this.supplierCount = this.invoices.length;
      this.activeInvoice = this.invoices.length - 1;
    }
    this.sidebarService.getDisplay().subscribe((display) => {
      this.displaySideBar = display === true ? false : false;
    });
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].orders.findIndex(obj => obj.name === text);
      return this.actions[0].orders[findIndex].value ? this.actions[0].orders[findIndex].value : false;
    }
  }

  async getActiveInventory(): Promise<any> {
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      if (response !== null) {
        this.activeInventory = response;
        this.minDate = new Date(this.activeInventory.startDate);
        this.maxDate = new Date(this.activeInventory.endDate);
      }
    });
  }

  async onLoadStock(event = null, type = null): Promise<void> {
    this.stocks = [];
    this.displaySideBar = false;
    if (event) {
      if (event.first){
        this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
      }else{
        this.parameters.page = 1;
        this.table.first = 0;
      }

    }
    if (type !== 'search') {
      this.parameters.page = this.parameters.page + 1;
      console.log(this.parameters.page);

    } else {
      this.stocks = [];
      console.log(this.parameters.page);

    }
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.noData = false;
    this.tableLoading = true;
    // @ts-ignore
    await this.http.get(StockModel.IRI, this.parameters).subscribe((response) => {
      this.stocks = [];
      if (response['hydra:totalItems'] === 0) {
        this.noData = true;
        this.stocks = [];
      } else {
        this.noData = false;
        response['hydra:member'].map((res, i) => {
          if (res.purchaseOptions) {
            // tslint:disable-next-line:no-shadowed-variable
            for (let i = 0; i < res.purchaseOptions.length; i++) {
              if (res.purchaseOptions[i].isActive === true) {
                const activeOption = res.purchaseOptions[i];
                res.purchaseOptions.splice(i, 1);
                res.purchaseOptions.unshift(activeOption);
                break; // Döngüden çık
              }
            }
          }
          this.stocks.push(res);
        });
        this.total = response['hydra:totalItems'];
        this.tableLoading = false;
        this.setStockValues();
        this.moreLoading = this.total >= this.parameters.page * this.parameters.itemsPerPage;
        this.http.get(`${StockAccountingCodeModel.IRI}/location-code?location=${this.parameters.locations}`).subscribe((codes) => {
          if (codes['hydra:member'].length > 0) {
            codes['hydra:member'].map((item) => {
              this.stocks.map((obj, i) => {
                if (obj.category['@id'] === item.category['@id']) {
                  this.stocks[i].accountingCode = item.code;
                }
              });
            });
            this.isLocationAccountingCode = true;
          } else {
            this.isLocationAccountingCode = false;
          }
        });
      }
      this.tableLoading = false;
      this.changeVariantAmount();
    });
  }

  changeVariantAmount(): void {
    this.stocks.map((stock, i) => {
      // @ts-ignore
      const findIndex = stock.stockQuantities.findIndex(obj => obj.location['@id'] === '/api/locations/' + this.parameters.locations);
      this.stocks[i].variants[0].amount = stock.stockQuantities[findIndex].amount;
      if (stock.stockQuantities[findIndex].alertLevel > 0) {
        this.stocks[i].variants[0].alertLevel = stock.stockQuantities[findIndex].alertLevel;
      }
      if (stock.stockQuantities[findIndex].lastPurchasePrice > 0) {
        this.stocks[i].variants[0].price = stock.stockQuantities[findIndex].lastPurchasePrice;
      }
    });
  }

  async onLoad(): Promise<void> {
    this.loading = true;
    let locationTotal = 0;
    await this.http.get(`${LocationModel.IRI}/subscriptionControl`).subscribe(response => {
        response.map((item, i) => {
          locationTotal = locationTotal + 1;
          if (i === 0) {
            this.parameters.locations = item.id;
            this.parameters.page = 0;
            this.selectedLocation = '/api/locations/' + item.id;
            this.stocks = [];
            this.onLoadStock().then();
          }
          this.locations.push(item);
        });
        if (locationTotal > 1) {
          this.displayLocations = true;
        }
        this.searchLocations = this.locations;
        if (this.displaySuppliersCount === 0) {
          this.displaySuppliers = true;
          this.displaySuppliersCount = 1;
        }
        this.loading = false;
      }
    );
    this.searchContacts = [];
    await this.http.get(`${CategoryModel.IRI}/category-type/stock?search=yes`).subscribe(category => {
      this.searchCategories = category['hydra:member'];
    });
    await this.http.get(`${ContactModel.IRI}/get-contact-by-type/supplier`).subscribe(category => {
      this.searchContacts = category['hydra:member'];
    });
    this.noData = false;
  }

  async changeContact(event): Promise<void> {
    this.table.first = 0;
    const objIndex = this.invoices.findIndex((obj => obj.key === event.value));
    if (objIndex !== -1) {
      this.activeInvoice = objIndex;
    }
    this.parameters.page = 0;
    this.stocks = [];
    this.onLoadStock().then();
  }

  async changeCategory(event): Promise<void> {
    // const choseId = this.parameters['category.id'].split('/');
    // this.parameters.locations = event.value;
    this.table.first = 0;
    this.parameters['category.id'] = event.value;
    this.parameters.page = 0;
    this.stocks = [];
    this.onLoadStock().then();
  }

  async changeLocation(event, value = null, id = null): Promise<void> {
    this.table.first = 0;
    if (value) {
      this.parameters.locations = id;
      this.selectedLocation = value;
    } else {
      this.parameters.locations = event.value;
      this.selectedLocation = '/api/locations/' + event.value;
    }

    this.parameters.page = 0;
    this.stocks = [];
    // this.invoices[this.activeInvoice].data.location = '/api/locations/' + event.value;
    this.onLoadStock().then();
    this.displaySuppliers = false;
    this.displayLocations = false;

    if (window.localStorage.getItem('invoices') !== undefined) {
      if (window.localStorage.getItem('invoices').length > 1) {
        /*
        const getInvoices = JSON.parse(window.localStorage.getItem('invoices'));
        const targetLocationId = this.selectedLocation; // Değiştirmeniz gereken location ID

        this.invoices = getInvoices.slice().filter(function(invoice) {
          return invoice.data.location === targetLocationId;
        });
         */
        this.invoices = JSON.parse(window.localStorage.getItem('invoices'));
        if (this.invoices) {
          this.invoices.map((invoices, i) => {
            this.invoices[i].data.expectedDate = new Date();
          });
          this.activeInvoice = this.invoices.length - 1;
          this.supplierCount = this.invoices.length;
        }
      }
    }
  }

  async onChangeQuantity(event: any, row, i, option = null): Promise<void> {
    const newKey = parseFloat(row.supplier.id) + parseFloat(this.parameters.locations);

    if (!row.accountingCode) {
      if (this.isLocationAccountingCode === true) {
        this.accountingCodeAlert = true;
      } else {
        console.log(1);
        const status = 'WAITING_FOR_APPROVAL';
        if (event.value === 0 || event.value === null) {

          const objStockIndex = this.stocks.findIndex((obj => obj['@id'] === row['@id']));
          this.stocks[objStockIndex].variants[0].orderQuantity = null;

          const getInvoice = this.invoices.findIndex((obj => obj.key === newKey));
          // tslint:disable-next-line:max-line-length
          const getItems = this.invoices[getInvoice].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
          setTimeout(() => {
            this.removeInvoiceItem(getItems, this.invoices[getInvoice].key, row['@id']);
          }, 100);
        }
        this.invoicesItems = [{
          currency: row.currency['@id'] ? row.currency['@id'] : null,
          nonDiscountedUnitPrice: 0,
          quantity: parseFloat(event.value),
          stock: row['@id'],
          name: option === null ? row.variants[0].name : option.name,
          code: option === null ? row.variants[0].code : option.code,
          subtotal: option === null ? row.variants[0].price * event.value : option.price * event.value,
          // tslint:disable-next-line:max-line-length
          tax: option === null ? row.variants[0].price * event.value * (row.tax.rate / 100) : (option.price * event.value) * (row.tax.rate / 100),
          taxRate: row.tax['@id'],
          unitPrice: option === null ? row.variants[0].price : option.price,
          variant: row.variants[0]['@id'],
          // tslint:disable-next-line:max-line-length
          totalOrder: option === null ? event.value : option.baseUnitQuantity === null ? event.value * option.packQuantity : event.value * option.baseUnitQuantity,
          unit: row.unit.name,
          discountRate: option.discountRate,
          discount: option.discountRate > 0 ? option.price * parseFloat(event.value) * (option.discountRate / 100) : 0,
          orderType: option === null ? null : option.orderType,
          purchaseOption: option === null ? null : option['@id'],
          accountingCode: row.accountingCode
        }];
        const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === newKey));
        this.activeInvoice = objInvoiceIndex;
        if (this.invoices.length === 0 || objInvoiceIndex === -1) {
          this.loading = true;
          this.invoices.push({
            key: newKey,
            // @ts-ignore
            location: this.selectedLocation.toString(),
            supplierName: option === null ? row.supplier.name : option.supplier.name,
            data: {
              contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
              currency: row.currency['@id'],
              discountRate: null,
              expectedDate: new Date(),
              location: this.selectedLocation,
              code: '',
              number: 0,
              series: '',
              status,
              subtotal: 0,
              discount: 0,
              tax: 0,
              total: 0,
              type: 'PURCHASE',
              description: '',
              items: this.invoicesItems
            }
          });
          this.supplierCount = this.invoices.length;
          this.activeInvoice = this.invoices.length - 1;
          this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
          // SubTotal Calculation
          // tslint:disable-next-line:no-shadowed-variable
          let sum = 0;
          let discount = 0;
          let tax = 0;
          this.invoices[this.activeInvoice].data.items.map(item => {
            sum += item.subtotal;
            discount += item.discount;
            tax += item.tax;
          });
          this.invoices[this.activeInvoice].data.subtotal = sum;
          this.invoices[this.activeInvoice].data.discount = discount;
          this.invoices[this.activeInvoice].data.tax = tax;
          this.invoices[this.activeInvoice].data.total = (sum - discount) + tax;
          this.loading = false;

        } else {
          const objIndex = this.invoices.findIndex((obj => obj.key === newKey));
          // tslint:disable-next-line:max-line-length
          const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
          if (objIndexStock !== -1) {
            this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
          } else {
            this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
          }
          this.activeInvoice = objIndex;
          this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
          // SubTotal Calculation
          // tslint:disable-next-line:no-shadowed-variable
          let sum = 0;
          let discount = 0;
          let tax = 0;
          this.invoices[this.activeInvoice].data.items.map(item => {
            sum += item.subtotal;
            discount += item.discount;
            tax += item.tax;
          });
          this.invoices[this.activeInvoice].data.subtotal = sum;
          this.invoices[this.activeInvoice].data.discount = discount;
          this.invoices[this.activeInvoice].data.tax = tax;
          this.invoices[this.activeInvoice].data.total = (sum - discount) + tax;
        }
        this.setInvoicesLocalStorage();
        const stockValueIndex = this.stockValues.findIndex(obj => obj.stock === row['@id'] && obj.option === option.id);
        if (stockValueIndex !== -1) {
          this.stockValues[stockValueIndex].orderQuantity = parseFloat(event.value);
        } else {
          this.stockValues.push({
            invoice: this.activeInvoice,
            stock: row['@id'],
            option: option.id,
            // @ts-ignore
            orderQuantity: parseFloat(event.value)
          });
        }
        this.setInvoicesLocalStorage();
      }
    } else {
      const status = 'WAITING_FOR_APPROVAL';
      if (event.value === 0 || event.value === null) {

        const objStockIndex = this.stocks.findIndex((obj => obj['@id'] === row['@id']));
        this.stocks[objStockIndex].variants[0].orderQuantity = null;

        const getInvoice = this.invoices.findIndex((obj => obj.key === newKey));
        // tslint:disable-next-line:max-line-length
        const getItems = this.invoices[getInvoice].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
        setTimeout(() => {
          this.removeInvoiceItem(getItems, this.invoices[getInvoice].key, row['@id']);
        }, 100);
      }
      this.invoicesItems = [{
        currency: row.currency['@id'] ? row.currency['@id'] : null,
        nonDiscountedUnitPrice: 0,
        quantity: parseFloat(event.value),
        stock: row['@id'],
        name: option === null ? row.variants[0].name : option.name,
        code: option === null ? row.variants[0].code : option.code,
        subtotal: option === null ? row.variants[0].price * event.value : option.price * event.value,
        // tslint:disable-next-line:max-line-length
        tax: option === null ? row.variants[0].price * event.value * (row.tax.rate / 100) : (option.price * event.value) * (row.tax.rate / 100),
        taxRate: row.tax['@id'],
        unitPrice: option === null ? row.variants[0].price : option.price,
        variant: row.variants[0]['@id'],
        // tslint:disable-next-line:max-line-length
        totalOrder: option === null ? event.value : option.baseUnitQuantity === null ? event.value * option.packQuantity : event.value * option.baseUnitQuantity,
        unit: row.unit.name,
        discountRate: option.discountRate,
        discount: option.discountRate > 0 ? option.price * parseFloat(event.value) * (option.discountRate / 100) : 0,
        orderType: option === null ? null : option.orderType,
        purchaseOption: option === null ? null : option['@id'],
        accountingCode: row.accountingCode
      }];
      const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === newKey));
      this.activeInvoice = objInvoiceIndex;
      if (this.invoices.length === 0 || objInvoiceIndex === -1) {
        console.log(3);
        this.loading = true;
        this.invoices.push({
          key: newKey,
          // @ts-ignore
          location: this.selectedLocation.toString(),
          supplierName: option === null ? row.supplier.name : option.supplier.name,
          data: {
            contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
            currency: row.currency['@id'],
            discountRate: null,
            expectedDate: new Date(),
            location: this.selectedLocation,
            code: '',
            number: 0,
            series: '',
            status,
            subtotal: 0,
            discount: 0,
            tax: 0,
            total: 0,
            type: 'PURCHASE',
            description: '',
            items: this.invoicesItems
          }
        });
        this.supplierCount = this.invoices.length;
        this.activeInvoice = this.invoices.length - 1;
        this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
        // SubTotal Calculation
        // tslint:disable-next-line:no-shadowed-variable
        let sum = 0;
        let discount = 0;
        let tax = 0;
        this.invoices[this.activeInvoice].data.items.map(item => {
          sum += item.subtotal;
          discount += item.discount;
          tax += item.tax;
        });
        this.invoices[this.activeInvoice].data.subtotal = sum;
        this.invoices[this.activeInvoice].data.discount = discount;
        this.invoices[this.activeInvoice].data.tax = tax;
        this.invoices[this.activeInvoice].data.total = (sum - discount) + tax;
        this.loading = false;

      } else {
        console.log(4);
        const objIndex = this.invoices.findIndex((obj => obj.key === newKey));
        // tslint:disable-next-line:max-line-length
        const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
        if (objIndexStock !== -1) {
          this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
        } else {
          this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
        }
        this.activeInvoice = objIndex;
        this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
        // SubTotal Calculation
        // tslint:disable-next-line:no-shadowed-variable
        let sum = 0;
        let discount = 0;
        let tax = 0;
        this.invoices[this.activeInvoice].data.items.map(item => {
          sum += item.subtotal;
          discount += item.discount;
          tax += item.tax;
        });
        this.invoices[this.activeInvoice].data.subtotal = sum;
        this.invoices[this.activeInvoice].data.discount = discount;
        this.invoices[this.activeInvoice].data.tax = tax;
        this.invoices[this.activeInvoice].data.total = (sum - discount) + tax;
      }
      this.setInvoicesLocalStorage();
      const stockValueIndex = this.stockValues.findIndex(obj => obj.stock === row['@id'] && obj.option === option.id);
      if (stockValueIndex !== -1) {
        this.stockValues[stockValueIndex].orderQuantity = parseFloat(event.value);
      } else {
        this.stockValues.push({
          invoice: this.activeInvoice,
          stock: row['@id'],
          option: option.id,
          // @ts-ignore
          orderQuantity: parseFloat(event.value)
        });
      }
      this.setInvoicesLocalStorage();
    }
  }

  async onChangeQuantityTotal(quantity, row, option = null): Promise<void> {
    const newKey = parseFloat(row.supplier.id) + parseFloat(this.parameters.locations);
    if (!row.accountingCode && this.isLocationAccountingCode === true) {
      this.accountingCodeAlert = true;
    } else {
      if (option === null) {
        row.variants[0].orderQuantity = quantity;
      } else {
        option.orderQuantity = quantity;
      }
      const status = 'WAITING_FOR_APPROVAL';
      this.invoicesItems = [{
        currency: row.currency['@id'] ? row.currency['@id'] : null,
        nonDiscountedUnitPrice: 0,
        quantity,
        stock: row['@id'],
        name: option === null ? row.variants[0].name : option.name,
        code: option === null ? row.variants[0].code : option.code,
        subtotal: option === null ? row.variants[0].price * quantity : option.price * quantity,
        tax: option === null ?
          (row.variants[0].price * quantity) * (row.tax.rate / 100)
          : (option.price * quantity) * (row.tax.rate / 100),
        taxRate: row.tax['@id'],
        unitPrice: option === null ? row.variants[0].price : option.price,
        variant: row.variants[0]['@id'],
        // tslint:disable-next-line:max-line-length
        totalOrder: option === null ? quantity * row.variants[0].minOrder : option.baseUnitQuantity === null ? quantity * option.packQuantity : quantity * option.baseUnitQuantity,
        unit: row.unit.name,
        discountRate: option.discountRate,
        discount: option.discountRate > 0 ? option.price * quantity * (option.discountRate / 100) : 0,
        orderType: option === null ? null : option.orderType,
        purchaseOption: option === null ? null : option['@id'],
        accountingCode: row.accountingCode
      }];
      const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === newKey));
      console.log(this.invoices);
      console.log(objInvoiceIndex, newKey);

      if (this.invoices.length === 0 || objInvoiceIndex === -1) {
        this.loading = true;
        this.invoices.push({
          key: option === null ? row.supplier.id : option.supplier.id + this.parameters.locations,
          supplierName: option === null ? row.supplier.name : option.supplier.name,
          data: {
            code: '',
            contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
            currency: row.currency['@id'],
            discountRate: null,
            expectedDate: new Date(),
            location: this.selectedLocation,
            number: 0,
            series: '',
            status,
            subtotal: 0,
            discount: 0,
            tax: 0,
            total: 0,
            type: 'PURCHASE',
            description: '',
            items: this.invoicesItems
          }
        });
        this.supplierCount = this.invoices.length;
        this.activeInvoice = this.invoices.length - 1;
        // SubTotal Calculation
        let sum = 0;
        let discount = 0;
        let tax = 0;
        this.invoices[this.activeInvoice].data.items.map(item => {
          sum += item.subtotal;
          discount += item.discount;
          tax += item.tax;
        });

        this.invoices[this.activeInvoice].data.subtotal = sum;
        this.invoices[this.activeInvoice].data.discount = discount;
        this.invoices[this.activeInvoice].data.tax = tax;
        this.invoices[this.activeInvoice].data.total = (sum - discount) + tax;
        this.loading = false;

      } else {
        const objIndex = this.invoices.findIndex((obj => obj.key === newKey));
        // tslint:disable-next-line:max-line-length
        const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
        if (objIndexStock !== -1) {
          this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
        } else {
          this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
        }
        this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
        let sum = 0;
        let discount = 0;
        let tax = 0;
        this.invoices[this.activeInvoice].data.items.map(item => {
          sum += item.subtotal;
          discount += item.discount;
          tax += item.tax;
        });
        this.activeInvoice = objIndex;
        this.invoices[this.activeInvoice].data.subtotal = sum;
        this.invoices[this.activeInvoice].data.tax = tax;
        this.invoices[this.activeInvoice].data.total = (sum - discount) + tax;
      }

      const stockValueIndex = this.stockValues.findIndex(obj => obj.stock === row['@id'] && obj.option === option.id);
      if (stockValueIndex !== -1) {
        this.stockValues[stockValueIndex].orderQuantity = quantity;
      } else {
        this.stockValues.push({
          invoice: this.activeInvoice,
          stock: row['@id'],
          option: option.id,
          // @ts-ignore
          orderQuantity: quantity
        });
      }
      this.setInvoicesLocalStorage();
    }
  }

  changeActiveInvoice(i): void {
    this.activeInvoice = i;
    this.parameters['supplier.id'] = String(this.invoices[i].key);
    this.selectInvoicesItems = this.invoices[i].data.items;
  }

  setDescription(event): void {
    this.invoices[this.activeInvoice].data.description = event.value;
  }

  setLocation(event): void {
    this.invoices[this.activeInvoice].data.location = event.value;
  }

  changeAlertLevel(row, i = null): void {
    let newAlertLevel = 0;
    this.stocks.map((stock) => {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      const findIndex = stock.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters.locations && obj.stock === row['@id']);
      if (findIndex !== -1) {
        this.stockQuantityId = stock.stockQuantities[findIndex].id;
      }
    });
    if (!row.purchaseOptions[i].baseUnitQuantity) {
      newAlertLevel = row.purchaseOptions[i].alertLevel;
    } else {
      newAlertLevel = this.onCeil(row.purchaseOptions[i].alertLevel * row.purchaseOptions[i].baseUnitQuantity);
    }
    this.http.put(`${StockVariantModel.IRI}/${(row.variants[0].id)}`, {alertLevel: newAlertLevel}).then(response => {
      row.variants[0].alertLevel = response.alertLevel;
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
      this.onLoadStock('search').then();
    });
  }

  test(event): void {
  }

  addContact(item: ContactModel = null): void {
    this.displayAddContact = true;
  }

  onDisplayInvoiceStatus(): void {
    this.displayInvoiceStatus = true;
  }

  async edit(event): Promise<void> {

    this.item = new StockModel();
    const response = await this.http.get(`${StockModel.IRI}/${event.id}`).toPromise();
    this.item = response;
    this.item.category = this.item.category ? this.item.category['@id'] : null;
    this.item.locations?.map((location, i) => {
      this.item.locations[i] = location['@id'];
    });
    this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
    this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
    this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
    this.item.orderItem = true;
    // @ts-ignore
    this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
    this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
    this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
    this.item.purchaseOptions.map((data, i) => {
      this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
        this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
        this.item.purchaseOptions[i].supplier = data.supplier['@id'];
        this.item.purchaseOptions[i].unit = data.unit['@id'];
        if (data.packName === '' && data.packPerCase === null) {
          this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
        } else {
          this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
        }
      });
    });
    this.displayCreate = true;
    setTimeout(() => {
      this.displaySideBar = true;
    }, 100);

  }


  reload(): void {
    this.displayCreate = false;
    this.displayUpdate = false;
    setTimeout(() => {
      this.displaySideBar = false;
    }, 100);
    this.displayAddContact = false;
    this.parameters.page = 0;
    // this.stocks = [];
    this.onLoadStock().then();
  }

  openForm(): void {
    this.item = new StockModel();
    this.item.variants.push(new StockVariantModel());
    this.item.variants[0].alertLevel = 1;
    this.item.variants[0].minOrder = 1;
    setTimeout(() => {
      this.displaySideBar = true;
    }, 100);
    this.displayCreate = true;
    this.displayUpdate = false;
  }

  onSidebarShow(): void {
    this.displayCreate = true;
    this.displayUpdate = false;
  }

  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }

  async save(key): Promise<void> {
    if ( this.checkActionPermission('SAVE_ORDER_AVAILABLE')){
      this.submitted = true;
      const dataCount = this.invoices.length;
      const invoiceIndex = this.invoices.findIndex((obj => obj.key === key));
      await this.http.get(`${InvoiceModel.IRI}/invoice-number?type=PURCHASE`).subscribe((res: InvoiceModel) => {
        this.invoices[invoiceIndex].data.number = res.number;
        this.invoices[invoiceIndex].data.code = res.code;
        this.invoices[invoiceIndex].data.series = res.series;
        this.http.post(InvoiceModel.IRI, this.invoices[invoiceIndex].data).then((response: InvoiceModel) => {
          if (response !== undefined) {
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
            const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
            this.stockValues.map((stockValue, i) => {
              if (objInvoiceIndex === stockValue.invoice) {
                this.stockValues.splice(i, 1);
              }
            });
            this.invoices.splice(objInvoiceIndex, 1);
            this.activeInvoice = this.invoices.length - 1;
            this.supplierCount = this.invoices.length;
            this.submitted = false;
            this.invoiceInfoComment = 'Invoice Number : ' + response.code;
            this.invoiceRouteId = response.id;
            this.invoiceInfo = true;
            this.setInvoicesLocalStorage();
            this.setStockValues();
          } else {
            this.submitted = false;
          }
        }).catch(err => {
        });
      });
      this.onLoadStock().then();
    }else {
      this.displayAction = true;
    }
  }

  removeInvoiceItem(i, key, stock): void {
    this.itemClickValues = -1;
    this.invoices[this.activeInvoice].data.items.splice(i, 1);
    if (this.invoices[this.activeInvoice].data.items.length === 0) {
      const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
      this.invoices.splice(objInvoiceIndex, 1);
      this.activeInvoice = this.invoices.length - 1;
      // this.supplierCount = this.invoices.length - 1;
    }
    const objStockIndex = this.stocks.findIndex((obj => obj['@id'] === stock));
    this.stocks[objStockIndex].variants[0].orderQuantity = null;
    this.setInvoicesLocalStorage();
    this.setStockValues();
    this.supplierCount = this.invoices.length;
  }

  removeInvoice(key): void {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_YOU_SURE_THAT_YOU_WANT_TO_PERFORM_THIS_ACTION'),
      accept: () => {
        const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
        this.stockValues.map((stockValue, i) => {
          if (objInvoiceIndex === stockValue.invoice) {
            this.stockValues.splice(i, 1);
          }
        });
        this.invoices.splice(objInvoiceIndex, 1);
        this.activeInvoice = this.invoices.length - 1;
        this.supplierCount = this.invoices.length;
        this.parameters.page = 0;
        this.stocks = [];
        this.parameters['supplier.id'] = null;
        this.onLoadStock().then();
        this.setInvoicesLocalStorage();
        this.setStockValues();
      }
    });
  }

  setInvoicesLocalStorage(): void {
    if (this.invoices.length > 0 && this.invoices[0].key === 0){
      this.invoices.splice(0, 1);
      this.supplierCount = this.invoices.length;
      this.activeInvoice = this.invoices.length - 1;
    }
    window.localStorage.setItem('invoices', JSON.stringify(this.invoices));
  }

  setStockValues(): void {
    this.stocks.map((stock) => {
      stock.purchaseOptions.map((data, i) => {
        // tslint:disable-next-line:max-line-length
        data.alertLevel = data.baseUnitQuantity === null ? Math.ceil(stock.variants[0].alertLevel / data.packQuantity) : Math.ceil(stock.variants[0].alertLevel / data.baseUnitQuantity);
        // tslint:disable-next-line:max-line-length
        data.onHand = data.baseUnitQuantity === null ? Math.floor(stock.variants[0].amount / data.packQuantity) : Math.floor(stock.variants[0].amount / data.baseUnitQuantity);
        // tslint:disable-next-line:max-line-length
        if (data.unit instanceof UnitModel) {
          data.orderType = data.packName === null || data.packPerCase === null ? data.packQuantity + ' ' + data.unit.name : data.packName + '(' + data.packPerCase + 'x' + data.packQuantity + ' ' + data.unit.name + ')';
        }
      });
    });
    this.stocks.map((stock) => {
      stock.purchaseOptions.map((opt) => {
        opt.orderQuantity = null;
        this.invoices.map((inv) => {
          inv.data.items.map((items) => {
            if (items.stock === stock['@id'] && items.purchaseOption === opt['@id']) {
              opt.orderQuantity = items.quantity;
            }
          });
        });
      });
    });
  }

  textSlice(text): string {
    const newText = text.slice(0, 10) + '...';
    return newText;
  }

  dataImport(): void {
    this.dataImportDialog = true;
  }

  setDate(event: any): void {
  }

  orderTypeText(order, unit): string {
    let text = '';
    if (order.baseUnitQuantity === null) {
      text = order.packQuantity + '' + order.packName + '';
    } else {
      text = order.baseUnitQuantity + ' ' + unit + '';
    }
    return text;
  }

  onInvoiceItemClick(i): void {
    if (this.itemClickValues === i) {
      this.itemClickValues = -1;
    } else {
      this.itemClickValues = i;
    }
  }

  getLastQuantity(event, item, i): void {

    this.invoices[this.activeInvoice].data.items[i].quantity = event.value;
    this.invoices[this.activeInvoice].data.items[i].totalOrder = event.value;
    this.invoices[this.activeInvoice].data.items[i].subtotal = event.value * item.unitPrice;
    const findStockInStocks = this.stocks.findIndex(obj => obj['@id'] === item.option.stock);
    if (findStockInStocks !== -1) {
      this.stocks[findStockInStocks].variants[0].orderQuantity = event.value;
    }
    this.invoiceRecalculate();
  }

  invoiceRecalculate(): void {
    let subtotal = 0;
    this.invoices[this.activeInvoice].data.items.map((item, i) => {
      subtotal += item.quantity * item.unitPrice;
    });
    this.invoices[this.activeInvoice].data.subtotal = subtotal;
  }

  priceCheck(price, row): string {
    if (row.purchaseOptions.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < row.purchaseOptions.length; i++) {
        if (price > (row.purchaseOptions[i].price / row.purchaseOptions[0].baseUnitQuantity)) {
          return 'red';
        } else if (price < (row.purchaseOptions[0].price / row.purchaseOptions[0].baseUnitQuantity)) {
          return 'green';
        }
      }
    } else {
      return '';
    }

  }

  priceCheckNew(price, row): string {
    const array = [];
    row.purchaseOptions.map((item, i) => {
      if (!item.baseUnitQuantity) {
        array.push(item.price);
      } else {
        array.push(item.price / item.baseUnitQuantity);
      }
    });
    array.sort((a, b) => a - b);
    if (price === array[0]) {
      return 'green';
    } else if (price < (row.purchaseOptions[0].price / row.purchaseOptions[0].baseUnitQuantity)) {
      return 'red';
    }

  }

  onCeil(value): number {
    return Math.ceil(value);
  }

  onFloor(value): number {
    return Math.floor(value);
  }
}
