import {ApiResourceModel} from './api-resource.model';
import {CurrencyModel} from './currency.model';
import {UserModel} from './user.model';
import {CompanySubscriptionModel} from './company-subscription.model';

export class CompanyModel extends ApiResourceModel{
  public static IRI = '/api/companies';
  public currency: string|CurrencyModel;
  public code: string;
  public symbol: string;
  public name: string;
  public email: string;
  public phoneNumber: string;
  public defaultDeliveryTime: number;
  public defaultProductionTime: number;
  public saleOrderPrefix: string;
  public purchaseOrderPrefix: string;
  public workOrderPrefix: string;
  public defaultSalesLocation: string;
  public defaultPurchaseLocation: string;
  public defaultManufacturingLocation: string;
  public users: UserModel[] = [];
  public logo: string;
  public hasDemoData: boolean;
  public isDemoDataDeleted: boolean;
  public defaultCategory: string;
  public defaultUnit: string;
  public defaultTax: string;
  public commercialName: string;
  public address: string;
  public taxId: string;
  public taxOffice: string;
  public subscriptions: CompanySubscriptionModel[] = [];
  public city: string;
  public state: string;
  public zipCode: string;
  public hasAccountingIntegration: boolean;
  public locationCount: number;
  public hasInitialSetup: boolean;
  public country: any;
  public sendEmail: boolean;
  public producer: boolean;
}
