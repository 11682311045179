
<p-card *ngIf="!loading">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'DAILY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEM_GROUP'"></app-page-info>
      <p-button icon="pi pi-file-excel" (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="locations" appendTo="body" (onChange)="onSelectLocation($event)"
        [(ngModel)]="parameters.location"
        optionLabel="name" optionValue="id"
        [filter]="true" filterBy="name" placeholder="{{ 'SELECT_LOCATION' | translate }}">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="categories" appendTo="body" (onChange)="onSelectCategory($event)"
        [(ngModel)]="parameters.category"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_CATEGORY' | translate }}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start"  selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
      [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
      [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
      placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                      appendTo="body" autofocus [suggestions]="stocks" name="stock"
                      placeholder="{{ 'SEARCH_ITEM'| translate }}"
                      (completeMethod)="searchStock($event)" (onSelect)="onStockSelect($event);"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="p-col-12 p-md-6 p-lg-1">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <app-report-tabs pageName="menu-item-group"></app-report-tabs>
  <div class="full-div" *ngIf="!menuItem.length">
    <h5> {{ 'NO_RECORDS_FOUND'| translate}}</h5>
  </div>
  <div class="p-grid" id="pdf-content">
    <div class="p-col-12" *ngIf="menuItem.length > 0">
      <div class="p-col-12" >
        <p-table [value]="menuItem" styleClass="p-datatable-sm" [paginator]="true"
                 [rows]="40" [totalRecords]="totalRecords">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '12%'}" pSortableColumn="subCategory">
                <p-sortIcon field="subCategory"></p-sortIcon>
                {{ 'MAIN_CATEGORY' | translate}}</th>
              <th [style]="{width: '12%'}" pSortableColumn="category">
                <p-sortIcon field="category"></p-sortIcon>
                {{ 'CATEGORY' | translate}}</th>
              <th [style]="{width: '12%'}" pSortableColumn="name">
                <p-sortIcon field="name"></p-sortIcon>
                {{ 'PRODUCT' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="quantity">
                <p-sortIcon field="quantity"></p-sortIcon>
                {{ 'SALE_QUANTITY' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="quantity">
                <p-sortIcon field="quantity"></p-sortIcon>{{ 'SALE_QUANTITY_RANK' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="subTotal">
                <p-sortIcon field="subTotal"></p-sortIcon>{{'SUBTOTAL' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="discount">
                <p-sortIcon field="discount"></p-sortIcon>{{'DISCOUNT' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="tax">
                <p-sortIcon field="tax"></p-sortIcon>{{'TAX' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="total">
                <p-sortIcon field="total"></p-sortIcon>{{'TOTAL' | translate}}</th>
              <th [style]="{width: '12%', textAlign:'center'}" pSortableColumn="netTotal">
                <p-sortIcon field="netTotal"></p-sortIcon>{{'NET_SALES' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cat>
            <tr>
              <td [style]="{width: '12%'}">{{cat.subCategory}}</td>
              <td [style]="{width: '12%'}">{{cat.category}}</td>
              <td [style]="{width: '12%'}" class="info">{{cat.name}} <p class="tooltip">{{ cat.location }}</p></td>
              <td [style]="{width: '12%', textAlign:'center'}">{{cat.quantity}}</td>
              <td
                [style]="{width: '12%', textAlign:'center'}"> {{((cat.quantity / totalQuantity) * 100) | number: '1.1-2' }}
                %
              </td>
              <td
                [style]="{width: '12%', textAlign:'right'}">{{ parseFloatS(cat.subTotal) | number: '1.1-2'}}{{info.currency}}</td>
              <td [style]="{width: '12%', textAlign:'right'}">{{cat.discount | number: '1.1-2'}}{{info.currency}}</td>
              <td [style]="{width: '12%', textAlign:'right'}">{{cat.tax | number: '1.1-2'}}{{info.currency}}</td>
              <td
                [style]="{width: '12%', textAlign:'right'}">{{parseFloatS(cat.total) | number: '1.1-2'}}{{info.currency}}</td>
              <td

                [style]="{width: '12%', textAlign:'right'}">{{ (cat.netTotal) | number: '1.1-2'}}{{info.currency}}</td>

              <td [style]="{width: '12%', textAlign:'left'}"></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td colspan="3" style="text-align: right; font-weight: bold;">{{ 'TOTAL' | translate }}</td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}">{{ calculateTotal('quantity') | number: '1.1-2' }}</td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}"></td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}">{{ calculateTotal('subTotal') | number: '1.1-2' }}{{ info.currency }}</td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}">{{ calculateTotal('discount') | number: '1.1-2'}}{{ info.currency }}</td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}">{{ calculateTotal('tax') | number: '1.1-2'}}{{ info.currency }}</td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}">{{ calculateTotal('total') | number: '1.1-2'}}{{ info.currency }}</td>
              <td [style]="{textAlign: 'center', fontSize: '14px'}">{{ calculateTotal('netTotal') | number: '1.1-2'}}{{ info.currency }}</td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>
</p-card>

