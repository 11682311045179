<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div class="p-grid">
  <div class="p-sm-12 p-md-12 p-lg-9  p-xl-9 p-xs-12">
    <p-card>
      <div class="p-grid">
        <div class="p-lg-6 p-md-6 p-sm-2 p-xs-4"><h3 [style]="{ fontWeight: '600' }">{{ 'NEW_ORDER' | translate}}</h3></div>
        <div class="p-lg-6 p-md-6 p-sm-2 p-xs-8">
          <app-page-info [pageName]="'NEW_ORDER'"></app-page-info>
          <app-assistant [page]="'NEW_ORDER'" [style]="{float: 'left',marginLeft: '45px'}"></app-assistant>
          <p-splitButton label="{{ 'ACTIONS' | translate}}" *ngIf="checkActionPermission('EDIT_STOCK')" [model]="this.actionItems" [style]="{float:'right'}"
                         styleClass="p-button-outlined mr-2 mb-2">
          </p-splitButton>
        </div>
      </div>
      <app-guest-user *ngIf="userType === 'GUEST'"></app-guest-user>
      <div class="p-grid">

        <div class="p-col-12 p-md-6 p-lg-3 p-xs-12" id="new_order_page_select_category">
          <p-dropdown
            [style]="{width: '100%',border:'none',borderBottom: '2px solid #47bfd4'}"
            [options]="searchCategories" [(ngModel)]="parameters['category.id']"
            placeholder="{{ 'SELECT_CATEGORY'| translate}}" [showClear]="true"
            (onChange)="changeCategory($event)" optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3 p-xs-12">
          <p-dropdown
            [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
            [options]="searchContacts" [(ngModel)]="parameters['supplier.id']" (onChange)="changeContact($event)"
            placeholder="{{'SELECT_SUPPLIER'| translate}}" [showClear]="true"
            optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3 p-xs-12" id="new_order_page_select_location">
          <p-dropdown
            [style]="{width: '100%',border:'none',borderBottom: '2px solid #47bfd4'}"
            [options]="locations" [(ngModel)]="parameters.locations" (onChange)="changeLocation($event,null, null)"
            optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
            [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3 p-xs-12">
          <form (ngSubmit)="onLoadStock($event, 'search')">
            <span class="p-input-icon-left" [style]="{width:'100%'}">
              <i class="pi pi-search"></i>
              <input name="globalSearch" type="text"
                     [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                     pInputText placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                     [(ngModel)]="parameters.name" (change)="changeNameInput($event)"/>
          </span>
          </form>
        </div>
      </div>
      <div class="p-grid">

        <div class="p-col-12" style="overflow-x: auto;margin-top:20px">

          <p-table #table responsiveLayout="scroll" [tableStyle]="{'min-width': '45rem'}"
                   [loading]="tableLoading" [value]="stocks" (onLazyLoad)="onLoadStock($event, 'search')" [lazy]="true"
                   [rows]="20" [showCurrentPageReport]="true" [resizableColumns]="true"
                   dataKey="id"  [reorderableColumns]="true" [paginator]="true"  [totalRecords]="total"
                   styleClass="p-datatable-striped p-datatable-sm" columnResizeMode="expand">
            <ng-template pTemplate="header">
              <tr>
                <th [style]="{width: '30px'}" id="first"></th>
                <th [style]="{textAlign: 'center',width:'25%'}" id="">{{ 'STOCK'| translate }}</th>
                <th [style]="{textAlign: 'left',width:'15%'}" id="MIN_ORDER">{{ 'MIN_ORDER'| translate }}</th>
                <th [style]="{textAlign: 'center',width:'10%'}" id="PRICE">{{ 'PRICE'| translate }}</th>
                <th [style]="{textAlign: 'center',width: '30%'}" id="ON_HAND_PAR">{{ 'ON_HAND_PAR'| translate }}</th>
                <th [style]="{width: '5%'}" id="null"></th>
                <th [style]="{width: '20%'}" id="QUANTITY">{{ 'QUANTITY'| translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-i="rowIndex" let-expanded="expanded">
              <tr id="new_order_page_table_row{{i}}">
                <td [style]="{padding : '0px'}" >
                  <button *ngIf="row.purchaseOptions?.length > 1" type="button" pButton pRipple [pRowToggler]="row"
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
                </td>
                <td>
                  <a href="javascript:void(0)" *ngIf="checkActionPermission('EDIT_STOCK')" (click)="edit(row)">
                  <i class="pi pi-pencil" style="font-size: 10px"></i>
                </a> {{ row.purchaseOptions[0]?.name ? row.purchaseOptions[0]?.name  : row.variants[0].name}}
                  <br>
                  {{ row.purchaseOptions[0]?.supplier?.name? row.purchaseOptions[0]?.supplier?.name : row.supplier?.name| slice:0:14  }}...
                </td>
                <td colspan="5" [style]="{padding: '0px'}">
                  <div class="p-3">
                    <p-table [value]="row.purchaseOptions" dataKey="id">
                      <ng-template pTemplate="body" let-firstoption let-c ="rowIndex">
                        <tr *ngIf=" c == 0" style="background: none !important;">
                <td [style]="{width: '0px'}"></td>
                <!-- Birimle aynı/farklı kontrol-->
                <td [style]="{width: '14%', textAlign: 'right'}" *ngIf="firstoption.unit.id === row.unit.id">{{ row.variants[0].minOrder }} {{ firstoption.unit.name}}</td>
                <td [style]="{width: '14%', textAlign: 'right'}"*ngIf="firstoption.unit.id !== row.unit.id">{{ firstoption.packQuantity }} {{ firstoption.unit.name}}<br>
                  <span [style]="{fontSize: '12px'}" *ngIf="firstoption.packName">{{ firstoption.packName}}
                    <span>({{ firstoption.baseUnitQuantity + ' '  + row.unit.name}})</span>
                  </span>
                </td>
                <!---->
              <!--Birimle aynı/farklı Fiyat Kontrol -->
              <td [style]="{width: '16%',padding:'0px', textAlign: 'right'}" *ngIf="firstoption.unit.id === row.unit.id">
                <span [ngClass]="priceCheckNew(firstoption.price / 1, row)">
                  {{firstoption.price | currency: row.currency.symbol }}
                </span>
               </td>
              <td [style]="{width: '16%',padding:'0px' , textAlign: 'right'}" *ngIf="firstoption.unit.id !== row.unit.id">

                <span [ngClass]="priceCheckNew(firstoption.price / firstoption.baseUnitQuantity, row)">
                  {{firstoption.price / firstoption.baseUnitQuantity | currency: row.currency.symbol }}
                </span>
               <br>
                <span [style]="{fontSize: '12px'}">{{firstoption.price| currency: row.currency.symbol }} / {{firstoption.baseUnitQuantity}} </span>

              </td>
              <!--Birimle aynı/farklı Fiyat Kontrol -->
              <td [style]="{width: '220px'}" >
                <div *ngIf="firstoption.unit.id === row.unit.id" class="on-hand-div">
                  <div *ngIf="!checkActionPermission('CHANGE_MINIMUM_ORDER')">{{ row.variants[0].amount | number : '1.2-3'}} /  {{ firstoption.alertLevel}}</div>
                  <div class="p-grid" *ngIf="checkActionPermission('CHANGE_MINIMUM_ORDER')">
                    <div class="on-hand-div-row1">{{ row.variants[0].amount | number : '1.2-3'}} /</div>
                    <div class="on-hand-div-row2">
                      <p-inplace closable="closable" [style]="{float: 'left'}">
                        <ng-template pTemplate="display">
                         {{ firstoption.alertLevel}}
                        </ng-template>
                        <ng-template pTemplate="content">
                          <div class="p-inputgroup">
                            <input name="alertLevel" type="number" id="{{c}}" pInputText placeholder="AlertLevel"
                                   [(ngModel)]="firstoption.alertLevel" [style]="{width: '75px !important'}">
                            <button type="button"
                                    (click)="changeAlertLevel(row, c)"
                                    pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                          </div>
                        </ng-template>
                      </p-inplace>
                    </div>
                  </div>

                </div>
                <div *ngIf="firstoption.unit.id !== row.unit.id" class="on-hand-div">
                  <div class="p-grid">
                    <div class="on-hand-div-row1">{{ onFloor((row.variants[0].amount  / firstoption.baseUnitQuantity)) }} /</div>
                    <div class="on-hand-div-row2">
                      <p-inplace closable="closable" [style]="{float: 'left'}">
                        <ng-template pTemplate="display">
                          {{ firstoption.alertLevel }}
                        </ng-template>
                        <ng-template pTemplate="content">
                          <div class="p-inputgroup">
                            <input name="alertLevel" type="number" id="{{c}}" pInputText placeholder="AlertLevel"
                                   [(ngModel)]="firstoption.alertLevel" [style]="{width: '75px !important'}">
                            <button type="button"
                                    (click)="changeAlertLevel(row, c)"
                                    pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                          </div>
                        </ng-template>
                      </p-inplace>
                    </div>
                  </div>

                </div>
              </td>
              <td [style]="{width: '50px',padding:'0px'}" id="new_order_page_table_row_add_all{{i}}">
                <div *ngIf="firstoption.unit.id === row.unit.id">
                  <div class="on-hand-div-row3">
                    <button
                      *ngIf="firstoption.orderQuantity === (firstoption.alertLevel - row.variants[0].amount)"
                      pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded"
                      [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'#0cc224'}">
                    </button>
                    <button
                      *ngIf="(firstoption.alertLevel - row.variants[0].amount) > 0 && firstoption.orderQuantity != (firstoption.alertLevel - row.variants[0].amount) "
                      pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded"
                      [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                      (click)="onChangeQuantityTotal((firstoption.alertLevel - row.variants[0].amount), row, firstoption)">
                    </button>
                  </div>
                </div>
                <div *ngIf="firstoption.unit.id !== row.unit.id">
                  <div class="on-hand-div-row3">
                    <button
                      *ngIf="firstoption.orderQuantity === onCeil((firstoption.alertLevel)) - onFloor((row.variants[0].amount / firstoption.baseUnitQuantity))"
                      pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded"
                      [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'#0cc224'}">
                    </button>
                    <button
                      *ngIf="firstoption.orderQuantity >= 0 && firstoption.orderQuantity !== onCeil((firstoption.alertLevel)) - onFloor((row.variants[0].amount / firstoption.baseUnitQuantity))"
                      pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded"
                      [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                      (click)="onChangeQuantityTotal(onCeil((firstoption.alertLevel)) - onFloor((row.variants[0].amount / firstoption.baseUnitQuantity)),row, firstoption)">
                    </button>
                  </div>
                </div>

              </td>
              <td id="new_order_page_table_row_add_number{{i}}" [style]="{width:'20%'}">
                <p-inputNumber [(ngModel)]="firstoption.orderQuantity" mode="decimal" min="1" step="1" [showButtons]="true"
                               (onInput)="onChangeQuantity($event, row , c, firstoption )"
                               [minFractionDigits]="2" [maxFractionDigits]="5"
                               decrementButtonClass="p-button-secondary"
                               incrementButtonClass="p-button-secondary">
                </p-inputNumber>
              </td>
            </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
        <!----Row Expnasion ----->
            <ng-template pTemplate="rowexpansion" let-row>
              <tr>
                <td colspan="7" [style]="{padding: '0px'}">
                  <div class="p-3" style="background: gainsboro;">
                    <p-table [value]="row.purchaseOptions" dataKey="id">
                      <ng-template pTemplate="body" let-option let-b ="rowIndex" style="background: #e8e6e6 !important;">
                        <tr *ngIf=" b > 0 && option.isActive === true" style="background: none !important;">
                          <td [style]="{width: '30px'}"></td>
                          <td [style]="{width: '23%', textAlign: 'left'}">{{option.name}} <br>{{ option.supplier.name | slice:0:14 }}...</td>
                          <!-- Birimle aynı/farklı kontrol-->
                          <td [style]="{width: '12%', textAlign: 'right'}" *ngIf="option.unit.id === row.unit.id">{{ row.variants[0].minOrder }} {{ option.unit.name}}</td>
                          <td [style]="{width: '12%', textAlign: 'right'}"*ngIf="option.unit.id !== row.unit.id">{{ option.packQuantity }} {{ option.unit.name}}<br>
                            <span [style]="{fontSize: '12px'}" *ngIf="option.packName">{{ option.packName}}
                              <span>({{ option.baseUnitQuantity + ' '  + row.unit.name}})</span>

                            </span>
                          </td>
                          <!---->
                          <!--Birimle aynı/farklı Fiyat Kontrol -->
                          <td [style]="{width: '12%',padding:'0px', textAlign: 'right'}" *ngIf="option.unit.id === row.unit.id">
                            <span [ngClass]="priceCheckNew(option.price, row)">
                              {{option.price | currency: row.currency.symbol }}
                            </span>

                          </td>
                          <td [style]="{width: '12%',padding:'0px' , textAlign: 'right'}" *ngIf="option.unit.id !== row.unit.id">
                           <span [ngClass]="priceCheckNew((option.price / option.baseUnitQuantity), row)">
                              {{option.price / option.baseUnitQuantity | currency: row.currency.symbol }}
                            </span>
                            <br>
                            <span [style]="{fontSize: '12px'}">{{option.price| currency: row.currency.symbol }} / {{option.baseUnitQuantity}} </span>

                          </td>
                          <!--Birimle aynı/farklı Fiyat Kontrol -->
                          <td [style]="{width: '220px'}">
                            <div *ngIf="option.unit.id === row.unit.id" class="on-hand-div">
                              <div *ngIf="!checkActionPermission('CHANGE_MINIMUM_ORDER')">{{ row.variants[0].amount | number : '1.2-3'}} /  {{ option.alertLevel}}</div>
                              <div class="p-grid" *ngIf="checkActionPermission('CHANGE_MINIMUM_ORDER')">
                                <div class="on-hand-div-row1">{{ row.variants[0].amount | number : '1.2-3'}} /</div>
                                <div class="on-hand-div-row2">
                                  <p-inplace closable="closable" [style]="{float: 'left'}">
                                    <ng-template pTemplate="display">
                                      {{ option.alertLevel }}
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                      <div class="p-inputgroup">
                                        <input name="alertLevel" type="number" id="{{b}}" pInputText placeholder="AlertLevel"
                                               [(ngModel)]="option.alertLevel" [style]="{width: '75px !important'}">
                                        <button type="button"
                                                (click)="changeAlertLevel(row , b)"
                                                pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                                      </div>
                                    </ng-template>
                                  </p-inplace>
                                </div>
                              </div>

                            </div>
                            <div *ngIf="option.unit.id !== row.unit.id" class="on-hand-div">

                              <div class="p-grid">
                                <div class="on-hand-div-row1">{{ onFloor((row.variants[0].amount  / option.baseUnitQuantity)) }} /</div>
                                <div class="on-hand-div-row2">
                                  <p-inplace closable="closable" [style]="{float: 'left'}">
                                    <ng-template pTemplate="display">
                                     {{ option.alertLevel}}
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                      <div class="p-inputgroup">
                                        <input name="alertLevel" type="number" id="{{b}}" pInputText placeholder="AlertLevel"
                                               [(ngModel)]="option.alertLevel" [style]="{width: '75px !important'}">
                                        <button type="button"
                                                (click)="changeAlertLevel(row , b)"
                                                pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                                      </div>
                                    </ng-template>
                                  </p-inplace>
                                </div>
                              </div>

                            </div>
                          </td>
                          <td [style]="{width: '50px',padding:'0px'}">
                            <div *ngIf="option.unit.id === row.unit.id">
                              <div class="on-hand-div-row3">

                                <button
                                  *ngIf="option.orderQuantity === (option.alertLevel - row.variants[0].amount)"
                                  pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded"
                                  [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'#0cc224'}">
                                </button>

                                <button
                                  *ngIf="(option.alertLevel - row.variants[0].amount) > 0 && option.orderQuantity != (option.alertLevel - row.variants[0].amount) "
                                  pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded"
                                  [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                                  (click)="onChangeQuantityTotal((option.alertLevel - row.variants[0].amount), row, option)">
                                </button>
                              </div>
                            </div>
                            <div *ngIf="option.unit.id !== row.unit.id">
                              <div class="on-hand-div-row3">
                                <button
                                  *ngIf="option.orderQuantity === onCeil((option.alertLevel)) - onFloor((row.variants[0].amount / option.baseUnitQuantity))"
                                  pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded"
                                  [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'#0cc224'}">
                                </button>
                                <button
                                  *ngIf="option.orderQuantity >= 0 && option.orderQuantity !== onCeil((option.alertLevel)) - onFloor((row.variants[0].amount / option.baseUnitQuantity))"
                                  pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded"
                                  [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                                  (click)="onChangeQuantityTotal(onCeil((option.alertLevel)) - onFloor((row.variants[0].amount / option.baseUnitQuantity)),row, option)">
                                </button>
                              </div>
                            </div>

                          </td>
                          <td [style]="{width:'15%'}">
                            <p-inputNumber [(ngModel)]="option.orderQuantity" mode="decimal" min="1" step="1" [showButtons]="true"
                                           (onInput)="onChangeQuantity($event, row , b, option )"
                                           [minFractionDigits]="2" [maxFractionDigits]="5"
                                           decrementButtonClass="p-button-secondary"
                                           incrementButtonClass="p-button-secondary">
                            </p-inputNumber>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                  </td>
                  </tr>
            </ng-template>
      <ng-template pTemplate="footer" *ngIf="noData == true" >
        <tr >
          <td colspan="7">Stok Bulumamadı</td>
        </tr>
      </ng-template>
        </p-table>
      </div>
  </div>
  </p-card>
</div>
<div class="p-sm-12 p-md-12 p-lg-3 p-xl-3" *ngIf="supplierCount > 0">
  <p-card>
    <div class="p-grid">
      <div class="p-col-8 p-lg-10"><h3
        [style]="{ fontWeight: '600' }">{{ invoices[activeInvoice] ? invoices[activeInvoice].supplierName : '' }}</h3>
      </div>
      <div class="p-col-4 p-lg-2" style="cursor: pointer">
        <i class="pi pi-shopping-cart" style="font-size: 2rem;margin-top: 10px;" (click)="op.toggle($event)"></i>
        <span class="CountNotification ng-binding">{{supplierCount }}</span>
      </div>

    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <p-divider></p-divider>
        <p-table [value]="invoices[activeInvoice].data.items ? invoices[activeInvoice].data.items : selectInvoicesItems"
                 styleClass="p-datatable-striped" responsiveLayout="scroll">
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr style="background: #E6F7FA;border-radius: 6px" class="hoverable" (click)="onInvoiceItemClick(i)">
              <td [style]="{padding:'0px',width:'40px'}">
                <p-avatar label="{{ item.quantity | number: '1.1-2'}}" size="large"
                          [style]="{'background-color':'#00B4D2', 'color': '#ffffff',fontSize:'16px'}"></p-avatar>
              </td>
              <td [style]="{padding:'0px 10px',fontSize:'12px'}">
                {{ item.name}}<br>
                {{ item.totalOrder | number: '1.1-2' }} {{ item.unit}} / <b
                [style]="{fontSize:'14px'}">{{ item.unitPrice | currency: '₺'}}</b></td>
              <th [style]="{padding:'0px'}" id="activeInvoice">
                <button
                  [style]="{marginLeft:'10px', width:'20px', height:'20px',marginLeft: '10px',position: 'relative',right: '-2px',top: '-3px',scale: '0.7',float:'right'}"
                  pButton pRipple type="button" icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-text" *ngIf="itemClickValues === i"
                  (click)="removeInvoiceItem(i, invoices[activeInvoice].key,item.stock)">
                </button>
                <b [style]="{float: 'right'}">{{ item.subtotal | currency: '₺'}}</b>
              </th>
            </tr>
            <tr *ngIf="itemClickValues === i">
              <td colspan="3" [style]="{padding: '0px'}">
                <p-inputNumber [style]="{scale: '0.6'}" [(ngModel)]="item.quantity" [showButtons]="true"
                               buttonLayout="horizontal"
                               inputId="horizontal" spinnerMode="horizontal" [step]="1"
                               (onInput)="getLastQuantity($event, item, i)"
                               decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                               incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal">
                </p-inputNumber>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>
      <div class="p-col-12">
        <p-divider></p-divider>
        <div class="p-fluid">
          <div class="p-field">
            <label for="comment">{{'COMMENT' | translate}}</label>
            <input name="comment" id="comment" [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="invoices[activeInvoice].data.description"
                   (change)="setDescription($event)" type="text" pInputText autofocus>
          </div>
          <div class="p-field">
            <label for="deliveryDate">{{'DATE' | translate}}</label>
            <p-calendar id="deliveryDate" [(ngModel)]="invoices[activeInvoice].data.expectedDate" [baseZIndex]="99999" [locale]="lang"
                        (onSelect)="setDate($event)" [defaultDate]="today" [minDate]="today" [maxDate]="maxDate" dateFormat="yy-mm-dd"
            ></p-calendar>
          </div>
          <div class="p-field">
            <label for="location">{{'LOCATION' | translate}}</label>
            <p-dropdown id="location" [options]="locations" [(ngModel)]="invoices[activeInvoice].data.location"
                        (onChange)="setLocation($event)" placeholder="{{ 'SELECT_LOCATION'| translate }}"
                        optionLabel="name" optionValue="@id" [filter]="true" filterBy="name"></p-dropdown>

          </div>

          <div class="p-field">
            <h3>{{'STATUS' | translate}}<b
              style="float: right;color: #64748B;font-weight: 400;">{{ invoices[activeInvoice].data.status | translate}}
              <i (click)="onDisplayInvoiceStatus()" class="pi pi-info"
                 style="border: 1px solid;border-radius: 50%;padding: 3px;color: #00b3d1"></i>
            </b></h3>
          </div>
        </div>
        <p-divider></p-divider>
        <div class="p-grid">
          <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
            <h3>{{ 'TOTAL'| translate }}: <b
              style="float: right;color: #00b3d1">{{ (invoices[activeInvoice] ? invoices[activeInvoice].data.subtotal : '000.00') | currency: '₺' }}</b>
            </h3>
          </div>
          <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
            <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
              <button pButton label="{{ 'CANCEL' | translate}}"
                      [style]="{width:'106%',background: 'none',color: '#444444',borderColor: '#444444'}"
                      icon="pi pi-times" class="p-button-raised p-button-secondary"
                      (click)="removeInvoice(invoices[activeInvoice].key)"></button>
            </div>
            <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
              <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"
                      (click)="save(invoices[activeInvoice].key)"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</div>

<p-dialog  [(visible)]="displayLocations" [style]="{width: '50vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION_FOR_ORDER'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff;border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444" (click)="changeLocation($event, location['@id'], location.id)">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>
<p-toast></p-toast>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{overlayArrowLeft:'0px'}" [appendTo]="'body'">
  <ng-template pTemplate>
    <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addContact()"
              icon="pi pi-plus"></p-button>
    <p-table [value]="invoices" selectionMode="single" [rows]="5" responsiveLayout="scroll">
      <ng-template pTemplate="body" let-rowData let-item let-i="rowIndex">
        <tr>
          <td (click)="changeActiveInvoice(i)">{{ item.supplierName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>


<p-dialog header="Sipariş Onay Durumu" [(visible)]="displayInvoiceStatus" [style]="{width: '50vw'}">
  <p>Siparişi Direk Olarak Onaylayıp Gönderme Yetkiniz Bulunmamaktadır.Sizin Verdiğiniz Siparişler Onaylandıktan Sonra
    Tedarikçiye Mail
    Olarak Gönderilecektir.</p>
  <p>Sipariş Onay Yetkisi Almak İçin Lütfen Yönetici İle İletişime Geçiniz</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayInvoiceStatus=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-sidebar position="right"  [(visible)]="displaySideBar" [style]="{width:'80em'}" (onHide)="reload()">
  <app-inventory-item-create *ngIf="displaySideBar === true"  [type]="'material'" [item]="item" appendto="body"></app-inventory-item-create>
</p-sidebar>


<p-confirmDialog #cd icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="header">
    <h3>{{'WARNING' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO'| translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Siparişiniz Oluşturuldu" [(visible)]="invoiceInfo" [style]="{width: '50vw'}" (onHide)="reload()">
  <a href="{{'/orders/order-details/' + invoiceRouteId}}">{{ invoiceInfoComment }}</a>
</p-dialog>

<p-sidebar [(visible)]="dataImportDialog" [style]="{width:'90em'}" position="right" (onHide)="reload()">
  <app-data-import></app-data-import>
</p-sidebar>

<p-dialog header="Uyarı !" [(visible)]="accountingCodeAlert">
  Eklemeye Çalıştığınız Ürün İçin <b>"Muahasebe Kategori Kodu"</b> Bulunamadı. <br>Lütfen Bu Kategoriye Ait Muhasebe Kodunu Giriniz

</p-dialog>


<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
