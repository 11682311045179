import {ApiResourceModel} from './api-resource.model';
import {StockVariantModel} from './stock-variant.model';
import {StockModel} from './stock.model';
import {LocationModel} from './location.model';
import {InventoryItemModel} from './inventory-item.model';
import {CategoryModel} from './category.model';

export class InventoryStockModel extends ApiResourceModel {
  public static IRI = '/api/inventory_stocks';
  stockVariant: StockVariantModel;
  stock: StockModel;
  location: LocationModel;
  quantity: number;
  unit1: number;
  unit2: number;
  unit2BaseQuantity: number;
  unit3: number;
  unit3BaseQuantity: number;
  valueInStock: number;
  countQuantity: number;
  countValueInStock: number;
  price: number;
  afterCountQuantity: number;
  afterCountValueInStock: number;
  consumptionQuantity: number;
  consumptionValue: number;
  ratio: any;
  inventoryItem: InventoryItemModel;
  category: CategoryModel;
  description: string;
}
