import {ApiResourceModel} from './api-resource.model';
import {OperationModel} from './operation.model';
import {ResourceModel} from './resource.model';
import {ProductOperationItemVariantOptionModel} from './product-operation-item-variant-option.model';

export class ProductOperationItemModel extends ApiResourceModel {
  public static IRI = '/api/product_operations';
  public operation: OperationModel|string;
  public resource: ResourceModel|string;
  public cost: number;
  public time: number;
  public totalCost: number;
  public productOperationItemVariantOptions: ProductOperationItemVariantOptionModel[] = [];
  public dependencies: OperationModel[]|string[];
  public isActive = true;
  public notes: string;
}
