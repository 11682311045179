import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import WebTour from '../../../../assets/webtour';
import {AsistantService} from '../../../service/asistant.service';
import {OpenaiService} from '../../../service/open-ai-service.service';


@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss'],
})
export class AssistantComponent implements OnInit {
  @ViewChild('tourTarget') tourTargets: ElementRef[];
  @Input() page: string;
  @HostListener('dragend', ['$event'])

  steps: any = [];
  display: boolean;
  speak: any [] = [];
  generatedText: string;
  searchTerm: string;
  divStyle = {
    bottom: '100px',
    left: '100px',
    position: 'fixed',
    zIndex: 999
  };

  constructor(private assistantService: AsistantService, private openaiService: OpenaiService, private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    const savedCoordinates = localStorage.getItem('divCoordinates');
    if (savedCoordinates) {
      const {bottom, left} = JSON.parse(savedCoordinates);
      this.divStyle.bottom = bottom;
      this.divStyle.left = left;
    }
    if (this.page === 'DASHBOARD') {
      this.steps = this.assistantService.getDashBoardSteps();
    }
    if (this.page === 'NEW_ORDER') {
      this.steps = this.assistantService.getNewOrderPageSteps();
    }
    if (this.page === 'PLANNING') {
      this.steps = this.assistantService.getPlanningPageSteps();
    }
  }

  startTour(): void {
    const wt = new WebTour();
    wt.setSteps(this.steps);
    wt.start();
  }

  showDialog(position: string): void {
    this.display = true;
  }

  getText(event): void {
    this.speak.push({
      type: 'guest',
      order: event.target.value
    });
    this.setText(event);
  }

  setText(event): void {
    if (event.target.value === 'selam') {
      this.speak.push({
        type: 'assistant',
        order: 'Sanada Selam',
        link: [],
      });
    } else if (event.target.value === 'envanter') {
      this.speak.push({
        type: 'assistant',
        order: 'Envanter İle İlgili ne yapmak istiyorsun',
        link: [],
      });
    } else if (event.target.value === 'sayım') {
      this.speak.push({
        type: 'assistant',
        order: 'O zaman Envante Sayfasına Git',
        link: [],
      });
    } else if (event.target.value === 'DOMATES') {
      this.speak.push({
        type: 'assistant',
        order: 'Aradığınız Kelimeyi Stoklar İçerisinde Buldum. ',
        link: ['/inventory/inventory-items?searchTerm=' + event.target.value],
      });
    } else {
      this.speak.push({
        type: 'assistant',
        order: 'Bu Konu ile İlgili Olarak Tam Bir Malumatım Yok. Lütfen Yöneticiniz İle İletişime Geçiniz',
        link: [],
      });
    }
  }

  generateText(event): void {
    const prompt = event.target.value;

    this.openaiService.generateText(prompt).subscribe((response: any) => {
      this.generatedText = response.choices[0].text;
      this.speak.push({
        type: 'assistant',
        order: response.choices[0].text
      });
    });
  }

  onKeyDown(event: KeyboardEvent): void {
    // Enter tuşuna basıldığında çalışacak fonksiyon
    if (event.keyCode === 13) {
      this.getText(event);
    }
  }

  onDragEnd(event: DragEvent): void {
    const bottom = event.clientY + 'px';
    const left = event.clientX + 'px';

    // Koordinatları localStorage'a kaydedin
    localStorage.setItem('divCoordinates', JSON.stringify({bottom, left}));
  }

}

// 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
