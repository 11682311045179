<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>
  <div class="p-grid">
    <div class="p-col-6">
      {{'WORK_ORDER_DASHBOARD' | translate}} {{ indexNumber}}
    </div>
    <div class="p-col-6"></div>
  </div><div class="p-grid">
  <div class="p-col-8 p-md-8 p-lg-8">
    <div class="p-grid grid-div" >
      <div class="p-col div-sums" [style]="{background:'#219d0e'}">
        <span class="title">{{'COMPLETED_WORKS' | translate}}</span>
        <span class="total-price">{{finished}}<b
          class="currency">{{'PIECE' | translate}}</b></span>
      </div>
      <div class="p-col div-sums" [style]="{background:'#8a8a8a'}">
        <span class="title">{{'WAITING' | translate}}</span>
        <span class="total-price">{{pending}}<b
          class="currency">{{'PIECE' | translate}}</b></span>
      </div>
      <div class="p-col div-sums" [style]="{background:'#0daeca'}">
        <span class="title">{{'IN_PRODUCTION' | translate}}</span>
        <span class="total-price">{{started}}<b
          class="currency">{{'PIECE' | translate}}</b></span>
      </div>
      <div class="p-col div-sums" [style]="{background:'#ecc424'}">
        <span class="title">{{'STOPPED' | translate}}</span>
        <span class="total-price">{{paused}}<b
          class="currency">{{'PIECE' | translate}}</b></span>
      </div>
    </div>
  </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <full-calendar *ngIf="showCalendar"  [options]="options" ></full-calendar>
    </div>

  </div>
</p-card>
<p-sidebar *ngIf="newWorkOrder" [(visible)]="newWorkOrder" [style]="{width: '70vw'}"  position="right">
  <app-work-order-create [data]="data"></app-work-order-create>
</p-sidebar>
