import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {DataTableSettingModel} from '../model/data-table-setting.model';
import {UserModel} from "../model/user.model";

@Injectable({
  providedIn: 'root'
})
export class DataTableSettingService {

  user: UserModel = new UserModel();

  constructor(private http: HttpService) { }

  save(name: string, index: number, width: number, user: string): void {
    this.user = JSON.parse(localStorage.getItem('user'));

    this.http.post(DataTableSettingModel.IRI, {
      name,
      cellIndex: index,
      cellWidth: width,
      user
    }).then((response: DataTableSettingModel) => {
      this.user.dataTableSettings[response.name][response.cellIndex] = response.cellWidth;
      localStorage.setItem('user', JSON.stringify(this.user));
    });
  }
}
