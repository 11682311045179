
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'NEW_ADJUSTMENT' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'NEW_ADJUSTMENT'"></app-page-info>
      <p-button icon="pi pi-print" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <label for="code">{{ 'ORDER_NUMBER'|translate}}</label>
      <input id="code" name="code" type="text" pInputText [(ngModel)]="adjustment.code"  autofocus>
      <small class="p-error">{{ errors['code'] }}</small>
    </div>
    <div class="p-field p-col-6">
      <label for="adjustmentDate">{{ 'ADJUSTMENT_DATE'|translate}}</label>
      <p-calendar id="adjustmentDate" [(ngModel)]="adjustment.adjustmentDate"></p-calendar>
      <small class="p-error">{{ errors['adjustmentDate'] }}</small>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <label for="reason">{{ 'REASON'|translate}}</label>
      <input id="reason"  type="text" pInputText [(ngModel)]="adjustment.reason"  autofocus>
      <small class="p-error">{{ errors['reason'] }}</small>
    </div>
    <div class="p-field p-col-6">
      <label for="location">{{ 'LOCATION' | translate}}</label>
      <p-dropdown id="location" [options]="locations" [(ngModel)]="adjustment.location" optionLabel="name"  optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['location'] }}</small>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">

      <p-table [value]="adjustment.items" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'STOCK' | translate}} <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip></th>
            <th>{{ 'QUANTITY' | translate}} <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip></th>
            <th>{{ 'UNIT_PRICE' | translate}} <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip></th>
            <th>{{ 'TOTAL_PRICE' | translate}} <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip></th>
            <th width="10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td pEditableColumn #input [pEditableColumnRowIndex]="1" class="{{ hasErrors('variant', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete [(ngModel)]="autoCompleteValues[i]" [disabled]="item.isReceived" (onSelect)="onVariantSelect($event, i);" field="name" [suggestions]="variants" (completeMethod)="searchVariant($event)"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ autoCompleteValues[i] ? autoCompleteValues[i].name : '' }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity" (ngModelChange)="calculateTotalPrice(i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.quantity}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="item.unitPrice" (ngModelChange)="calculateTotalPrice(i)" mode="decimal" minFractionDigits="2" maxFractionDigits="2" ></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{item.unitPrice|currency: currencyLabel: ''}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              {{item.totalPrice|currency: currencyLabel: ''}}
            </td>
            <td>
              <button  [disabled]="item.isReceived || item.isWorkOrderCreated" pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-fluid  p-pt-3">

        <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addItem()" iconPos="left"  ></button>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
          <label for="comment"></label>
          <p-editor id="comment" [(ngModel)]="adjustment.description" [style]="{'height':'120px'}"></p-editor>
        </div>
      </div>
      <div class="clearfix"></div>
      <p-button styleClass="p-button-secondary p-mt-3 p-mb-3" label="{{ 'SAVE' | translate}}"
                [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                [disabled]="this.submitted" (click)="save()">
      </p-button>
    </div>
  </div>

