import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {WorkOrderModel} from '../../../model/work-order.model';
import {HttpService} from '../../../service/http.service';
import {StockQuantityBatchModel} from '../../../model/stock-quantity-batch.model';
import {WorkOrderMaterialModel} from '../../../model/work-order-material.model';
import {WorkOrderOperationBatchModel} from '../../../model/work-order-operation-batch.model';
import {WorkOrderOperationModel} from '../../../model/work-order-operation.model';
import {InvoiceProductionStatus} from '../../../model/invoice.model';

@Component({
  selector: 'app-work-order-close',
  templateUrl: './work-order-close.component.html',
  styleUrls: ['./work-order-close.component.scss']
})
export class WorkOrderCloseComponent implements OnInit {

  workOrder = new WorkOrderModel();
  batches: any[] = [];
  materials: string[] = [];
  loading: boolean;
  submitted: boolean;

  constructor(private config: DynamicDialogConfig, private http: HttpService, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(`${WorkOrderModel.IRI}/${this.config.data.id}`).subscribe(response => {
      this.workOrder = response;

      // @ts-ignore
      this.workOrder.materials.map((material: WorkOrderMaterialModel, key: number) => {
        // @ts-ignore
        this.http.get(StockQuantityBatchModel.IRI, {'variant.id': material.variant.id}).subscribe(batches => {
          // @ts-ignore
          this.batches[material.variant['@id']] = batches['hydra:member'];
        });

        const batch = new WorkOrderOperationBatchModel();
        // @ts-ignore
        if (this.workOrder.materials[key].batches === undefined) {
          // @ts-ignore
          this.workOrder.materials[key].batches = [];
        }
        // @ts-ignore
        this.workOrder.materials[key].batches.push(batch);
      });

      this.loading = false;
    });
  }

  addBatch(event, i: number, variant): void {
    const batch = new WorkOrderOperationBatchModel();

    this.batches[variant].map((item) => {
      if (event.value === item['@id']) {
        // @ts-ignore
        if (item.amount < this.workOrder.materials[i].totalQuantity) {
          // @ts-ignore
          batch.amount = this.workOrder.materials[i].totalQuantity;
          batch.barcode = item.barcode;
          // @ts-ignore
          this.workOrder.materials[i].batches.push(batch);
        } else {
          // @ts-ignore
          this.workOrder.materials[i].batches[0].barcode = item.barcode;
          // @ts-ignore
          this.workOrder.materials[i].batches[0].amount = this.workOrder.materials[i].totalQuantity;
          // @ts-ignore
          this.workOrder.materials[i].batches[0].batch = item['@id'];
        }
      }
    });
  }

  async save(): Promise<void> {
    this.submitted = true;

    const item = new WorkOrderModel();

    item.stock = this.workOrder.stock['@id'];
    item.variant = this.workOrder.variant['@id'];
    item['@id'] = this.workOrder['@id'];
    item.id = this.workOrder.id;
    item.barcode = this.workOrder.barcode;
    item.expirationDate = this.workOrder.expirationDate;
    item.plannedQuantity = this.workOrder.plannedQuantity;
    item.subtotal = this.workOrder.subtotal;

    // @ts-ignore
    this.workOrder.materials.map((itemMaterial, key) => {
      item.materials.push(new WorkOrderMaterialModel());

      // @ts-ignore
      item.materials[key]['@id'] = this.workOrder.materials[key]['@id'];
      item.materials[key].id = this.workOrder.materials[key].id;
      item.materials[key].variant = this.workOrder.materials[key].variant['@id'];
      item.materials[key].batches = this.workOrder.materials[key].batches;
    });

    // @ts-ignore
    this.workOrder.operations.map((itemOperation, key) => {
      item.operations.push(new WorkOrderOperationModel());

      // @ts-ignore
      item.operations[key]['@id'] = this.workOrder.operations[key]['@id'];
      item.operations[key].id = this.workOrder.operations[key].id;
    });


    await this.http.put(`${WorkOrderModel.IRI}/${item.id}/batches`, item).then(response => {
    });

    // tslint:disable-next-line:max-line-length
    await this.http.put(`${WorkOrderOperationModel.IRI}/${this.config.data.operationId}`, {status: InvoiceProductionStatus.Status_Finished}).then((response) => {
      if (response) {
        this.ref.close({saved: true});
      }
    });

    this.submitted = true;
  }
}
