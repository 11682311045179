import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CompanyModel} from '../../../../model/company.model';
import {CurrencyModel} from '../../../../model/currency.model';
import {LocationModel} from '../../../../model/location.model';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {HttpService} from '../../../../service/http.service';
import {environment} from '../../../../../environments/environment';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {
  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  countries: any[] = [];
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  submitted: boolean;
  errors = [];
  apiUrl: string;
  tabMenuItems: MenuItem[];
  activeTabMenuItem: MenuItem;
  validationSubscriber: Subscription;
  constructor(private router: Router, private http: HttpService) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadCurrencies();
    this.loadCountries();
    this.apiUrl = environment.apiUrl;
    this.http.get(CompanyModel.IRI).subscribe((response: CompanyModel) => {
      this.company = response;
      this.company.currency = this.company.currency['@id'];
      if (!this.company.country){
        this.company.country = '/api/countries/127';
      }
      this.loading = false;
    });
  }
  loadCurrencies(): void {
    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
    });
  }
  loadCountries(): void {
    this.http.get('/api/countries', {pagination : false}).subscribe((response: CurrencyModel[]) => {
      this.countries = response['hydra:member'];
    });
  }
  nextPage(): void {
    this.loading = true;
    this.errors = [];
    this.submitted = true;
    this.http.put(`${CompanyModel.IRI}/${this.company.id}`, {
      currency: this.company.currency,
      country: this.company.country
    }).then((response: CompanyModel) => {
      if (response !== undefined) {
        localStorage.setItem('currency', JSON.stringify(response.currency));
        this.router.navigate(['initial-setup/add-location']).then(r => {
        });
      }
    });
  }

  previousPage(): void {
    this.router.navigate(['initial-setup']).then(r => {
    });
  }

}
