import { Component, OnInit } from '@angular/core';
import {PermissionModel} from '../../../model/permission.model';
import {Subscription} from 'rxjs';
import {HttpService} from '../../../service/http.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ValidationService} from '../../../service/validation.service';
import {RoleModel} from '../../../model/role.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../../model/user.model';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  roles: RoleModel[] = [];
  role: RoleModel = new RoleModel();
  loading = false;
  showForm = false;
  showPasswordForm = false;
  submitted = false;
  id: number;
  permissions: PermissionModel[] = [];
  errors = [];
  validationSubscriber: Subscription;
  orders = [];
  newRoles = [];


  constructor(private http: HttpService,
              private trasnlate: TranslateService,
              private flashMessageService: FlashMessageService,
              private validationService: ValidationService) { }


  ngOnInit(): void {
    this.loadRoles();
    this.loadPermissions();
    this.validationSubscriber = this.validationService.errors.subscribe((data) => {
      if (data){
        this.errors = data;
      }
    });
    this.orders = [
      { name: this.role.sa1, title: this.trasnlate.instant('PLACE_ORDERS')},
      { name: this.role.sa2, title: this.trasnlate.instant('ORDER_HISTORY')},
      { name: this.role.sa3, title: this.trasnlate.instant('RECEIVE_ORDERS')},
      { name: this.role.sa4, title: this.trasnlate.instant('WEEKLY_SCHEDULES')},
    ];
    // @ts-ignore
    const roles = {
      orders:
        [
          {value: false, name: 'PLACE_ORDERS', title: this.trasnlate.instant('PLACE_ORDERS'), inPageAction: false},
          {value: false, name: 'EDIT_STOCK', title: this.trasnlate.instant('EDIT_STOCK'), inPageAction: true},
          {value: false, name: 'CHANGE_MINIMUM_ORDER', title: this.trasnlate.instant('CHANGE_MINIMUM_ORDER'), inPageAction: true},
          {value: false, name: 'SAVE_ORDER_AVAILABLE', title: this.trasnlate.instant('SAVE_ORDER_AVAILABLE'), inPageAction: true},
          {value: false, name: 'ORDER_HISTORY', title: this.trasnlate.instant('ORDER_HISTORY'), inPageAction: false},
          {value: false, name: 'ORDER_CONFIRM_AVAILABLE', title: this.trasnlate.instant('ORDER_CONFIRM_AVAILABLE'), inPageAction: true},
          {value: false, name: 'ORDER_CANCEL_AVAILABLE', title: this.trasnlate.instant('ORDER_CANCEL_AVAILABLE'), inPageAction: true},
          {value: false, name: 'ORDER_ADD_ITEM_AVAILABLE', title: this.trasnlate.instant('ORDER_ADD_ITEM_AVAILABLE'), inPageAction: true},
          {value: false, name: 'ORDER_SAVE_AVAILABLE', title: this.trasnlate.instant('ORDER_SAVE_AVAILABLE'), inPageAction: true},
          {value: false, name: 'RECEIVE_ORDERS', title: this.trasnlate.instant('RECEIVE_ORDERS'), inPageAction: false},
          {value: false, name: 'RECEIVE_ORDERS_AVAILABLE', title: this.trasnlate.instant('RECEIVE_ORDERS_AVAILABLE'), inPageAction: true},
          {value: false, name: 'INCOMING_INVOICE_RECEIVE', title: this.trasnlate.instant('INCOMING_INVOICE_RECEIVE'), inPageAction: false},
          {value: false, name: 'INCOMING_INVOICE_RECEIVE_AVAILABLE', title: this.trasnlate.instant('INCOMING_INVOICE_RECEIVE_AVAILABLE'), inPageAction: true},
          {value: false, name: 'WEEKLY_SCHEDULES', title: this.trasnlate.instant('WEEKLY_SCHEDULES'), inPageAction: false}
        ],
      inventory:
        [
          {value: false, name: 'INVENTORY_ITEMS', title: this.trasnlate.instant('INVENTORY_ITEMS'), inPageAction: false},
          {value: false, name: 'CAN_EDIT_STOCK', title: this.trasnlate.instant('CAN_EDIT_STOCK'), inPageAction: true},
          {value: false, name: 'CAN_ADD_NEW_STOCK', title: this.trasnlate.instant('CAN_ADD_NEW_STOCK'), inPageAction: true},
          {value: false, name: 'STOCK_TRANSFERS', title: this.trasnlate.instant('STOCK_TRANSFERS'), inPageAction: false},
          {value: false, name: 'CAN_CONFIRM_STOCK_TRANSFERS', title: this.trasnlate.instant('CAN_CONFIRM_STOCK_TRANSFERS'), inPageAction: true},
          {value: false, name: 'CAN_NEW_STOCK_TRANSFERS', title: this.trasnlate.instant('CAN_NEW_STOCK_TRANSFERS'), inPageAction: true},
          {value: false, name: 'INVENTORY', title: this.trasnlate.instant('INVENTORY'), inPageAction: false},
          {value: false, name: 'CAN_NEW_INVENTORY', title: this.trasnlate.instant('CAN_NEW_INVENTORY'), inPageAction: true},
          {value: false, name: 'CAN_CLOSE_INVENTORY', title: this.trasnlate.instant('CAN_CLOSE_INVENTORY'), inPageAction: true},
          {value: false, name: 'CAN_ADD_COUNT_IN_INVENTORY', title: this.trasnlate.instant('CAN_ADD_COUNT_IN_INVENTORY'), inPageAction: true},
          {value: false, name: 'ALLERGENS', title: this.trasnlate.instant('ALLERGENS'), inPageAction: false},
          {value: false, name: 'SUB_RECIPES', title: this.trasnlate.instant('SUB_RECIPES'), inPageAction: false},
          {value: false, name: 'CAN_EDIT_SUB_RECIPES', title: this.trasnlate.instant('CAN_EDIT_SUB_RECIPES'), inPageAction: true},
          {value: false, name: 'CAN_ADD_SUB_RECIPES', title: this.trasnlate.instant('CAN_ADD_SUB_RECIPES'), inPageAction: true},
          {value: false, name: 'CAN_EDIT_SUB_RECIPES_RECIPE', title: this.trasnlate.instant('CAN_EDIT_SUB_RECIPES_RECIPE'), inPageAction: true},
          {value: false, name: 'MENU_ITEMS', title: this.trasnlate.instant('MENU_ITEMS'), inPageAction: false},
          {value: false, name: 'CAN_EDIT_MENU_ITEMS', title: this.trasnlate.instant('CAN_EDIT_MENU_ITEMS'), inPageAction: true},
          {value: false, name: 'CAN_ADD_MENU_ITEMS', title: this.trasnlate.instant('CAN_ADD_MENU_ITEMS'), inPageAction: true},
          {value: false, name: 'CAN_SHOW_RECIPES', title: this.trasnlate.instant('CAN_SHOW_RECIPES'), inPageAction: true},
          {value: false, name: 'CAN_EDIT_RECIPES', title: this.trasnlate.instant('CAN_EDIT_RECIPES'), inPageAction: true},
          {value: false, name: 'COOKBOOK', title: this.trasnlate.instant('COOKBOOK'), inPageAction: false},
          {value: false, name: 'PRODUCTIONS_EVENTS', title: this.trasnlate.instant('PRODUCTIONS_EVENTS'), inPageAction: false},
          {value: false, name: 'WASTE_EVENTS', title: this.trasnlate.instant('WASTE_EVENTS'), inPageAction: false}
        ],
      production:
        [
          {value: false, name: 'PRODUCE_DASHBOARD', title: this.trasnlate.instant('DASHBOARD'), inPageAction: false},
          {value: false, name: 'SALES', title: this.trasnlate.instant('SALES'), inPageAction: false},
          {value: false, name: 'PLAN', title: this.trasnlate.instant('PLAN'), inPageAction: false},
          {value: false, name: 'EXPIRATION_DATE_STOCKS', title: this.trasnlate.instant('EXPIRATION_DATE_STOCKS'), inPageAction: false},
          {value: false, name: 'WORK_ORDERS', title: this.trasnlate.instant('WORK_ORDERS'), inPageAction: false},
          {value: false, name: 'PRODUCTION_REPORTS', title: this.trasnlate.instant('PRODUCTION_REPORTS'), inPageAction: false}
        ],
      suppliers:
        [
          {value: false, name: 'SUPPLIERS', title: this.trasnlate.instant('SUPPLIERS'), inPageAction: false},
          {value: false, name: 'CAN_EDIT_SUPPLIER', title: this.trasnlate.instant('CAN_EDIT_SUPPLIER'), inPageAction: true},
          {value: false, name: 'CAN_ADD_SUPPLIER', title: this.trasnlate.instant('CAN_ADD_SUPPLIER'), inPageAction: true},
          {value: false, name: 'CAN_DELETE_SUPPLIER', title: this.trasnlate.instant('CAN_DELETE_SUPPLIER'), inPageAction: true},
          {value: false, name: 'PRICES', title: this.trasnlate.instant('PRICES'), inPageAction: false}
        ],
      accounting:
        [
          {value: false, name: 'INVOICE_DELIVERY_NOTES', title: this.trasnlate.instant('INVOICE_DELIVERY_NOTES'), inPageAction: false},
          {value: false, name: 'RETURN_INVOICES', title: this.trasnlate.instant('RETURN_INVOICES'), inPageAction: false},
          {value: false, name: 'SCANNED_INVOICES', title: this.trasnlate.instant('SCANNED_INVOICES'), inPageAction: false},
          {value: false, name: 'CREDIT_REMINDER', title: this.trasnlate.instant('CREDIT_REMINDER'), inPageAction: false},
          {value: false, name: 'IRREGULAR_PRICES', title: this.trasnlate.instant('IRREGULAR_PRICES'), inPageAction: false},
        ],
      reports:
        [
          {value: false, name: 'COGS_AND_GROSS_PROFITS', title: this.trasnlate.instant('COGS_AND_GROSS_PROFITS'), inPageAction: false},
          {value: false, name: 'PRICE_REPORTS', title: this.trasnlate.instant('PRICE_REPORTS'), inPageAction: false},
          {value: false, name: 'PRICE_CHANGE_REPORTS', title: this.trasnlate.instant('PRICE_CHANGE_REPORTS'), inPageAction: false},
          {value: false, name: 'ORDERS_BY_SUPPLIER', title: this.trasnlate.instant('ORDERS_BY_SUPPLIER'), inPageAction: false},
          {value: false, name: 'WASTE_REPORTS', title: this.trasnlate.instant('WASTE_REPORTS'), inPageAction: false},
          {value: false, name: 'STOCK_MOVEMENTS', title: this.trasnlate.instant('STOCK_MOVEMENTS'), inPageAction: false},
          {value: false, name: 'INVENTORY_REPORTS', title: this.trasnlate.instant('INVENTORY_REPORTS'), inPageAction: false},
          {value: false, name: 'PURCHASE_REPORTS', title: this.trasnlate.instant('PURCHASE_REPORTS'), inPageAction: false},
          {value: true, name: 'LAST_PURCHASE_REPORTS', title: this.trasnlate.instant('LAST_PURCHASE_REPORTS'), inPageAction: false},
          {value: false, name: 'LAST_PURCHASE_REPORTS', title: this.trasnlate.instant('LAST_PURCHASE_REPORTS'), inPageAction: false},
        ],
      settings:
        [
          {value: false, name: 'GENERAL', title: this.trasnlate.instant('GENERAL'), inPageAction: false},
          {value: false, name: 'USERS', title: this.trasnlate.instant('USERS'), inPageAction: false},
          {value: false, name: 'LOCATIONS', title: this.trasnlate.instant('LOCATIONS'), inPageAction: false},
          {value: false, name: 'UNITS', title: this.trasnlate.instant('UNITS'), inPageAction: false},
          {value: false, name: 'CATEGORIES', title: this.trasnlate.instant('CATEGORIES'), inPageAction: false},
          {value: false, name: 'TAX_RATES', title: this.trasnlate.instant('TAX_RATES'), inPageAction: false},
          {value: false, name: 'ROLES', title: this.trasnlate.instant('ROLES'), inPageAction: false},
          {value: false, name: 'INTEGRATIONS', title: this.trasnlate.instant('INTEGRATIONS'), inPageAction: false},
          {value: false, name: 'DATA_IMPORT', title: this.trasnlate.instant('DATA_IMPORT'), inPageAction: false}
        ]
    };
    this.newRoles.push(roles);
  }
  checkActionPermission(text, type, i): void {
    /*
    if (this.role.actions){
      if (type === 'ORDERS') {
        if (this.newRoles[0].orders.length !== this.role.actions[0].orders.length){
          this.role.actions[0].orders.push(this.newRoles[0].orders[this.newRoles[0].orders.length - 1]);
        }
      } else if (type === 'INVENTORY') {
        if (this.newRoles[0].inventory.length !== this.role.actions[0].inventory.length){
          this.role.actions[0].inventory.push(this.newRoles[0].inventory[this.newRoles[0].inventory.length - 1]);
        }
      } else if (type === 'SUPPLIER') {
        if (this.newRoles[0].supplier.length !== this.role.actions[0].supplier.length){
          this.role.actions[0].supplier.push(this.newRoles[0].supplier[this.newRoles[0].supplier.length - 1]);
        }
      } else if (type === 'ACCOUNTING') {
        if (this.newRoles[0].accounting.length !== this.role.actions[0].accounting.length){
          this.role.actions[0].accounting.push(this.newRoles[0].accounting[this.newRoles[0].accounting.length - 1]);
        }
      } else if (type === 'REPORT') {
        if (this.newRoles[0].report.length !== this.role.actions[0].report.length){
          this.role.actions[0].report.push(this.newRoles[0].report[this.newRoles[0].report.length - 1]);
        }
      } else if (type === 'SETTING') {
        if (this.newRoles[0].setting.length !== this.role.actions[0].setting.length){
          this.role.actions[0].setting.push(this.newRoles[0].setting[this.newRoles[0].setting.length - 1]);
        }
      }
    }
     */
  }
  loadRoles(): void {
    this.loading = true;
    this.http.get(RoleModel.IRI).subscribe((response: RoleModel[]) => {
      this.roles = response['hydra:member'];
      this.loading = false;
    });
  }
  loadPermissions(): void {
    this.http.get(PermissionModel.IRI).subscribe((response: PermissionModel[]) => {
      this.permissions = response['hydra:member'];
    });
  }
  async save(): Promise<void> {
    this.submitted = true;
    this.errors = [];
    this.role.actions = this.newRoles;
    if (this.id) {
      await this.http.put(`${RoleModel.IRI}/${this.role.id}`, this.role).then((response: RoleModel) => {
        this.loadRoles();
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      });
    } else {
      await this.http.post(RoleModel.IRI, this.role).then((response: RoleModel) => {
        if (response !== undefined) {
          this.loadRoles();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.successCreated'));
        }
      });
    }

    this.submitted = false;

    if (this.errors.length < 1) {
      this.hideForm();
    }
    /*
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    await this.http.get(UserModel.IRI + user.id).then((response: UserModel) => {
      window.localStorage.removeItem('actions');
      // @ts-ignore
      window.localStorage.setItem('actions', JSON.stringify(response.actions));
    });
     */
  }

  async edit(role: RoleModel): Promise<void> {
    this.openForm();
    this.role = {...role};
    if (!this.role.actions){
      this.role.actions = this.newRoles;
    }
    this.role.permissions.map((permission, i: number) => {
      this.role.permissions[i] = permission['@id'];
    });
    this.id = role.id;
    this.role.actions[0].orders.forEach((item) => {
      const findIndex = this.newRoles[0].orders.findIndex(obj => obj.name === item.name);
      this.newRoles[0].orders[findIndex].value = item.value;
    });

    this.role.actions[0].inventory.forEach((item) => {
      const findIndex = this.newRoles[0].inventory.findIndex(obj => obj.name === item.name);
      this.newRoles[0].inventory[findIndex].value = item.value;
    });

    this.role.actions[0].production.forEach((item) => {
      const findIndex = this.newRoles[0].production.findIndex(obj => obj.name === item.name);
      this.newRoles[0].production[findIndex].value = item.value;
    });

    this.role.actions[0].suppliers.forEach((item) => {
      const findIndex = this.newRoles[0].suppliers.findIndex(obj => obj.name === item.name);
      this.newRoles[0].suppliers[findIndex].value = item.value;
    });

    this.role.actions[0].accounting.forEach((item) => {
      const findIndex = this.newRoles[0].accounting.findIndex(obj => obj.name === item.name);
      this.newRoles[0].accounting[findIndex].value = item.value;
    });

    this.role.actions[0].reports.forEach((item) => {
      const findIndex = this.newRoles[0].reports.findIndex(obj => obj.name === item.name);
      this.newRoles[0].reports[findIndex].value = item.value;
    });

    this.role.actions[0].settings.forEach((item) => {
      const findIndex = this.newRoles[0].settings.findIndex(obj => obj.name === item.name);
      this.newRoles[0].settings[findIndex].value = item.value;
    });
  }
  async delete(role: RoleModel): Promise<void> {
    await this.http.delete(`${RoleModel.IRI}/${role.id}`).then(() => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.successDeleted'));
    });
    this.loadRoles();
  }
  openForm(): void {
    this.showForm = true;
    this.role = new RoleModel();
  }
  hideForm(): void {
    this.errors = [];
    this.showForm = false;
    this.showPasswordForm = false;
    this.id = null;
  }
  onCheckedMenu(menuItem, event): void{
    for (let i = 0; i < 20 ; i++) {
      if (event.checked === true){
        this.newRoles[0][menuItem.toLowerCase()][i].value = true;
      }else {
        this.newRoles[0][menuItem.toLowerCase()][i].value = false;
      }
    }
  }

}
