import {Component, OnInit} from '@angular/core';
import {CompanyModel} from '../../model/company.model';
import {HttpService} from '../../service/http.service';
import {environment} from '../../../environments/environment';
import {FlashMessageService} from '../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../model/flash-message.model';
import {TabViewModule} from 'primeng/tabview';
import {LocationModel} from '../../model/location.model';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  constructor(private httpService: HttpService, private flashMessageService: FlashMessageService , private router: Router) { }

  ngOnInit(): void {
  }
  nextPage(): void {
    this.router.navigate(['payment/packets']).then(r => {
    });

  }
}
