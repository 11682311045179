<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card class="card" [style]="{overflow:'hidden',minHeight:'500px'}">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PRICE_CHANGE_REPORTS' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <p-button icon="pi pi-question" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-excel" (click)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <form (ngSubmit)="load(null, 'search')">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-lg-3">
        <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                        appendTo="body" autofocus [suggestions]="variants" name="stock"
                        placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                        (completeMethod)="searchStock($event)" (onSelect)="onVariantSelect($event);"
                        field="name">
        </p-autoComplete>
      </div>
      <div class="p-col-12 p-md-6 p-lg-2">
        <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
          [options]="searchContacts" [(ngModel)]="parameters['supplier.id']"
          (onChange)="changeContact($event)"
          placeholder="{{ 'SELECT_SUPPLIER' | translate}}"
          optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-6 p-lg-2">
        <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
          [options]="locations" [filter]="true" filterBy="name" [(ngModel)]="parameters.location"
          (onChange)="changeLocation($event)"
          optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION' | translate}}">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-6 p-lg-3">
        <p-calendar [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
        id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
        appendTo="body" [(ngModel)]="dateRange" (ngModelChange)="onStartChange($event)"
        placeholder="{{'SELECT_TRANSFER_DATE'| translate}}">
        </p-calendar>
      </div>
      <div class="p-col-12 p-md-6 p-lg-2">
        <p-button label="Submit" type="submit"></p-button>
      </div>
    </div>
  </form>
  <div class="card" *ngIf="stocks.length>0" [style]="{width: '100%'}" id="pdf-content">
    <p-table [value]="stocks" [first]="first"
             [paginator]="true" [totalRecords]="total" [lazy]="true" (onLazyLoad)="load($event)"
             [rows]="parameters.itemsPerPage" [showCurrentPageReport]="true" [resizableColumns]="true"
             styleClass="p-datatable-lg" columnResizeMode="expand">
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: '2%'}">{{ 'Chart'| translate }}</th>
          <th [style]="{width: '6%'}" pSortableColumn="name">
            <p-sortIcon field="name"></p-sortIcon>
            {{ 'STOCK'| translate }}</th>
          <th [style]="{width: '5%'}">{{ 'SUPPLIER'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}">{{ 'OLD_PRICE'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}">{{ 'DATE'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}" pSortableColumn="price">
            <p-sortIcon field="price"></p-sortIcon>
            {{ 'NEW_PRICE'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}">{{ 'DATE'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}">{{ 'CHANGE'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}">{{ 'VARIANCE_VALUE'| translate }}</th>
          <th [style]="{textAlign:'center', width: '4%'}">{{ 'AVERAGE_PRICE'| translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row >
        <tr>
          <td class="pi pi-chart-line" style="font-size: 1.2rem" (click)="openPriceDialog(row)"
              [style]="{cursor: 'pointer', textAlign:'center'}"></td>
          <td *ngIf="row.variants[0].name.length > 20" class="width-220 info">
                {{row.variants[0].name | slice:0:18 }}... 
                <p class="tooltip">{{ row.variants[0].name }}</p> 
          </td>  <td *ngIf="row.variants[0].name.length <= 20" class="width-220 info">{{row.variants[0].name}}</td>   
          <td class="width-220 info">{{row.supplier?.name | slice:0:14 }}... <span class="tooltip">{{ row.supplier?.name }}</span></td>
          <td
            [style]="{textAlign:'right'}">{{ row.stockPriceChanges[row.stockPriceChanges.length - 2]?.price | number : '1.2-2'}}{{row.currency.symbol}}</td>
          <td
            [style]="{textAlign:'right'}">{{ row.stockPriceChanges[row.stockPriceChanges.length - 2]?.createdAt | date: 'MM.dd.yyyy' }}</td>
          <td [style]="{textAlign:'right'}">{{ row.variants[0].price | number : '1.2-2'}}{{row.currency.symbol}}</td>
          <td [style]="{textAlign:'right'}">{{ row.variants[0].createdAt | date: 'MM.dd.yyyy' }}</td>
          <td
            [style]="{textAlign:'right'}">{{ calculateChange(row.variants[0].price, (row.stockPriceChanges[row.stockPriceChanges.length - 2]?.price))  }}</td>
          <td [style]="{textAlign:'right'}"><span *ngIf=" row.stockPriceChanges.length > 0">
            {{ row.variants[0].price - (row.stockPriceChanges[row.stockPriceChanges.length - 2]?.price)| number : '1.2-2'}} {{row.currency.symbol}}</span>
          </td>
          <td [style]="{textAlign:'right'}">{{row.variants[0].averageCost | number : '1.2-2' }} {{row.currency.symbol}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>
<p-dialog [(visible)]="staticsDisplay">
  <h4>{{ stockName }}</h4>
  <p-chart type="bar" [data]="data" [options]="chartOptions" width="700px" height="400px"></p-chart>
</p-dialog>
