import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ConfirmationService} from 'primeng/api';
import {UnitModel} from '../../../model/unit.model';
import { TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {CompanyModel} from '../../../model/company.model';
import {UnitService} from '../../../service/unit.service';
import {DialogService} from 'primeng/dynamicdialog';
import {CreateUnitComponent} from './create-unit/create-unit.component';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss'],
  providers: [DialogService]
})
export class UnitComponent implements OnInit, OnDestroy {
  items: UnitModel[] = [];
  activeItems: UnitModel[] = [];
  item: UnitModel = new UnitModel();
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  showAll: boolean;

  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private confirmationService: ConfirmationService, private translate: TranslateService,
              private validationService: ValidationService, private flashMessageService: FlashMessageService,
              private unitService: UnitService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.load();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });

    this.http.get(CompanyModel.IRI).subscribe((response: CompanyModel) => {
      this.company = response;
    });
  }

  load(): void {
    this.unitService.getUnitsAction();

    this.loading = true;

    this.unitService.getUnits().subscribe(response => {
      if (response !== null) {
        this.loading = false;
      }
      if (response) {
        this.activeItems = [];
        this.items = response;
        this.items.map(item => {
          if (item.isActive) {
            this.activeItems.push(item);
          }
        });
      }
    });
  }

  openForm(item: UnitModel): void {
    this.dialogService.open(CreateUnitComponent, {
      width: '50vw',
      header: this.translate.instant('UNIT_CREATE'),
      data: {item}
    });
  }

  edit(row): void {
    this.item = {...row};
    this.openForm(this.item);
  }

  changeStatus(row): void {
    this.http.put(`${UnitModel.IRI}/${row.id}`, {isActive: row.isActive}).then((response) => {
      row.isActive = response.isActive;
    });
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
    this.unitService.units.next([]);
  }

  setIsDefault(row): void {
    this.http.put(`${CompanyModel.IRI}/${this.company.id}`, {defaultUnit: row.value}).then();
  }
}
