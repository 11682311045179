import {ApiResourceModel} from './api-resource.model';

export class PrintTemplateModel extends ApiResourceModel {
  public static IRI = '/api/print_templates';
  public name: string;
  public language: string;
  public externalId: number;
  public type: string;
  public editor: string;
  public isDefault: boolean;
}

export enum PrintTemplateTypes  {
  Invoice = 'Invoice',
  WorkOrder = 'WorkOrder',
  Stock = 'Stock'
}
