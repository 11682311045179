import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  InvoiceDeliveryStatus,
  InvoiceProductionStatus,
  InvoiceStockStatus,
  InvoiceStatus
} from '../model/invoice.model';
import {TransferStatus} from '../model/stock-transfers.model';
import {RequestStatus} from '../model/stock-request.model';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string, ...args: unknown[]): string {

    if (value === '' && value === undefined) {
      return '';
    }

    if (value === InvoiceStockStatus.StatusExpected) {
      const date = args[0] && typeof args[0] === 'string' ? `(${args[0].substr(0, 10)})` : '';
      return `${this.translateService.instant(value)} ${date}`;
    } else {
      return this.translateService.instant(value);
    }
  }
}

@Pipe({
  name: 'statusLabel'
})
export class StatusLabelPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    switch (value) {
      case InvoiceStockStatus.StatusAvailable:
      case InvoiceProductionStatus.Status_Finished:
      case TransferStatus.Sent:
      case RequestStatus.Seen:
      case InvoiceStatus.Confirmed:
      case InvoiceStatus.Confirmed_Supplier:
        return 'success';
      case InvoiceStockStatus.StatusExpected:
      case InvoiceDeliveryStatus.DELIVERY_STATUS_PENDING:
      case InvoiceProductionStatus.Status_Not_Started:
      case TransferStatus.Preparing:
      case InvoiceStatus.Viewed_Supplier:
        return 'warning';
      case InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED:
      case InvoiceDeliveryStatus.DELIVERY_STATUS_READY:
      case InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED:
      case InvoiceProductionStatus.Status_Started:
      case TransferStatus.Request:
      case InvoiceStatus.Pending:
      case InvoiceStatus.Send:
        return 'primary';
      case InvoiceStockStatus.StatusNotRecipe:
        return 'info';
      case InvoiceStatus.Declined:
      case InvoiceStatus.Declined_Supplier:
        return 'danger';
      default:
        return 'danger';
    }
  }
}
