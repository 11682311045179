import {Component, OnInit, ElementRef} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AuthService} from './service/auth.service';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {PermissionModel} from './model/permission.model';
import {CompanyModel} from './model/company.model';
import {UserModel} from './model/user.model';
import {HttpService} from './service/http.service';
import {Router} from '@angular/router';
import 'primeflex/primeflex.css';
import {DialogModule} from 'primeng/dialog';
import {PrimeNGConfig} from 'primeng/api';
import {NotificationService} from './service/notification.service';
import {UserTypeService} from './service/user-type.service';
import {ProduceCompanyService} from './service/produce-company.service';
import {LangService} from './service/lang.service';
import {LocationModel} from './model/location.model';
import {any} from 'codelyzer/util/function';
import { IntegrationLog } from './model/integration-log.model';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'client';
  isLogged: boolean;
  hasSubscription: boolean;
  apiUrl: string;
  items: MenuItem[] = [];
  permissions: PermissionModel[] = [];
  company: CompanyModel = new CompanyModel();
  user: UserModel = new UserModel();
  loading: boolean;
  settingItems: MenuItem[] = [];
  mainMenu: MenuItem[] = [];
  userMenu: MenuItem[] = [];
  activePage: string;
  listTitles: any[];
  routeLocation: Location;
  mobileMenuVisible: any = 0;
  toggleButton: any;
  sidebarVisible: boolean;
  isCollapsed = true;
  $layer: any;
  contact: any;
  sidebarShort: boolean;
  sidebarWide: boolean;
  labelShow: boolean;
  mainMenuIcon: MenuItem[] = [];
  dayCount: number;
  paymentDialog = false;
  rightSidebarDisplay = false;
  leftSidebarDisplay = false;
  notification: any[];
  actions: any[];
  href: string;
  hasInitialSetupPage: boolean;
  hasPaymentPage: boolean;
  paymentCount: 0;
  private placeOrdersValue: boolean;
  isProducer: boolean;
  displayMatchError: boolean;

  constructor(private translate: TranslateService, private authService: AuthService, private http: HttpService,
              private router: Router, private element: ElementRef, private config: PrimeNGConfig,
              private notificationService: NotificationService, private userTypeService: UserTypeService,
              private produceCompanyService: ProduceCompanyService, private langService: LangService) {
    const language = localStorage.getItem('language');

    if (language) {
      this.translate.setDefaultLang(localStorage.getItem('language'));
      this.translate.use(localStorage.getItem('language'));
    }
    this.routeLocation = location;
    this.sidebarVisible = false;
  }

  ngOnInit(): void {
    this.notification = [];
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe( location => {
      if ( location.length === 0 && !this.href.includes('payment')){
        window.location.href = '/payment';
      }});
    this.apiUrl = environment.apiUrl;
    this.href = window.location.href;
    this.hasInitialSetupPage = this.href.includes('initial-setup');
    this.hasPaymentPage = this.href.includes('payment');
    this.company = JSON.parse(localStorage.getItem('company'));
    this.isProducer = this.company?.producer;
    this.actions = JSON.parse(localStorage.getItem('actions'));
    this.langService.setLanguage(localStorage.getItem('language'));
    this.contact = localStorage.getItem('contact');
    this.produceCompanyService.getProduceContact();
    this.produceCompanyService.getProduceLocation();
    const userJson = localStorage.getItem('user');
    if (userJson) {
      this.user = JSON.parse(userJson);
    }
    if (this.actions){
      let item = '';
      item = this.actions[0].setting?.find(order => order.name === 'GUEST');
      // @ts-ignore
      if (item){
        // @ts-ignore
        if ( item.value === true){
          this.userTypeService.sendData('GUEST');
        }
      }
    }
    this.authService.checkLogin().then(response => {
      this.isLogged = response.isValid;
      this.hasSubscription = response.hasSubscription;
      if (!this.hasSubscription) {
        this.items = [];
      }
    });
    this.activePage = sessionStorage.getItem('activePage');
    this.permissions = JSON.parse(localStorage.getItem('permissions'));

    if (this.permissions) {
      this.permissions.map(item => {
        this.items.push({
          label: this.translate.instant(item.name),
          icon: item.icon,
          routerLink: item.link
        });
      });
    }
    this.http.get(CompanyModel.IRI).subscribe((response: CompanyModel) => {
      const today = new Date();
      // @ts-ignore
      const futureDate = new Date(response.subscriptions[0]?.endDate);
      const diffInTime = futureDate.getTime() - today.getTime();
      this.dayCount = Math.ceil(diffInTime / (1000 * 3600 * 24));
    });
    setTimeout(() => {
      if (this.dayCount <= 0) {
        this.paymentDialog = true;
        window.location.href = '/payment';
      }
    }, 1000);
    this.userMenu = [
      {
        label: 'LogOut', icon: 'pi pi-times', command: () => {
          this.logout();
        }
      }
    ];

    this.menuLoad();
    this.mainMenuIconLoad();
    this.listTitles = this.mainMenu.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      const $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobileMenuVisible = 0;
      }
    });
    this.sidebarShort = false;
    this.sidebarWide = true;
    this.notificationService.getIntegrationLogs();
    setTimeout(()=> {
      this.getNotification();
    }, 3000);
  }
  getNotification(): void{
      this.notificationService.sharedData.subscribe(response => {
        this.notification = response;
        this.displayMatchError = this.notification.some(item => item.body.error === 'PRODUCT_MATCH_ERROR');

      });
    }
  removeNotification(id): void{
    console.log(id);
    this.http.put(`${IntegrationLog.IRI}/${id}`, {isShow: true}).then(()=>{
      this.notificationService.getIntegrationLogs();
      this.getNotification();
    }
  );
  }
  logout(): void {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('permissions');
    window.localStorage.removeItem('currency');
    // window.localStorage.removeItem('language');
    window.localStorage.removeItem('role');
    window.localStorage.removeItem('contact');
    window.localStorage.removeItem('locations');
    window.localStorage.removeItem('actions');
    window.localStorage.removeItem('invoices');
    window.localStorage.removeItem('company');
    window.location.href = '/login';
  }

  async deleteDemoData(): Promise<void> {
    this.loading = true;
    await this.http.delete('/api/company/truncate');
    this.loading = false;

    this.company.isDemoDataDeleted = true;
    window.localStorage.setItem('company', JSON.stringify(this.company));

    this.router.navigate(['/']);
  }

  activePageTitle(title): void {
    this.activePage = title;
    sessionStorage.setItem('activePage', title);
  }

  collapse(): void {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  sidebarOpen(): void {
    const toggleButton = this.toggleButton;
    const mainPanel = document.getElementsByClassName('q-main')[0] as HTMLElement;
    const html = document.getElementsByTagName('html')[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose(): void {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = document.getElementsByClassName('q-main')[0] as HTMLElement;

    if (window.innerWidth < 991) {
      setTimeout(() => {
        mainPanel.style.position = '';
      }, 500);
    }
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }

  sidebarToggle(): void {
    // const toggleButton = this.toggleButton;
    // const html = document.getElementsByTagName('html')[0];
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const html = document.getElementsByTagName('html')[0];

    if (this.mobileMenuVisible === 1) {
      // $('html').removeClass('nav-open');
      html.classList.remove('nav-open');
      if (this.$layer) {
        this.$layer.remove();
      }
      setTimeout(() => {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobileMenuVisible = 0;
    } else {
      setTimeout(() => {
        $toggle.classList.add('toggled');
      }, 430);

      const layer = document.createElement('div');
      layer.setAttribute('class', 'close-layer');


      if (html.querySelectorAll('.q-main')) {
        document.getElementsByClassName('q-main')[0].appendChild(layer);
      } else if (html.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild(layer);
      }

      setTimeout(() => {
        layer.classList.add('visible');
      }, 100);

      // tslint:disable-next-line:typedef
      layer.onclick = function() { // asign a function
        html.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        layer.classList.remove('visible');
        setTimeout(() => {
          layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      html.classList.add('nav-open');
      this.mobileMenuVisible = 1;

    }
  }

  sidebarIcon(): void {
    if (this.sidebarShort === false) {
      this.sidebarShort = true;
      this.sidebarWide = false;
      this.sidebarShortOpen();
    } else {
      this.sidebarShort = false;
      this.sidebarWide = true;
      this.sidebarWideOpen();
    }
  }

  menuLoad(): void {
    setTimeout(() => {

      if (window.localStorage.getItem('role') === '"ROLE_SUPPLIER"') {
        this.mainMenu = [
          {
            label: this.labelShow === true ? '' : this.translate.instant('DASHBOARD'),
            icon: 'pi pi-home',
            title: 'dashboard',
            id: 'dashboard',
            routerLink: '/',
            command: () => {
              this.activePageTitle('Dashboard');
            }
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('ORDERS'),
            icon: 'pi pi-shopping-cart',
            title: 'orders',
            id: 'orders',
            items: [
              {
                label: this.translate.instant('ONAY BEKLEYEN SİPARİŞLER'),
                routerLink: 'orders/new-order',
                command: () => {
                  this.activePageTitle(this.translate.instant('PLACE_ORDERS'));
                }
              },
              {
                label: this.translate.instant('ORDER_HISTORY'),
                routerLink: 'orders/order-history',
                command: () => {
                  this.activePageTitle(this.translate.instant('ORDER_HISTORY'));
                }
              }
            ]
          }

        ];
      } else {
        this.mainMenu = [
          {
            label: this.labelShow === true ? '' : this.translate.instant('DASHBOARD'),
            icon: 'pi pi-home',
            title: 'dashboard',
            id: 'dashboard',
            routerLink: '/',
            command: () => {
              this.activePageTitle('Dashboard');
            },
            visible: true
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('ORDERS'),
            icon: 'pi pi-shopping-cart',
            title: 'orders',
            id: 'orders',
            visible: this.findInPermissions('ORDERS'),
            items: [
              {
                label: this.translate.instant('PLACE_ORDERS'),
                routerLink: 'orders/new-order',
                command: () => {
                  this.activePageTitle(this.translate.instant('PLACE_ORDERS'));
                },
                visible: this.checkActionMenu('PLACE_ORDERS', 'ORDERS')
              },
              /*
              {
                label: this.translate.instant('ORDER_HISTORY'),
                routerLink: 'orders/order-history',
                command: () => {
                  this.activePageTitle(this.translate.instant('ORDER_HISTORY'));
                },
                visible: this.checkActionMenu('ORDER_HISTORY', 'ORDERS')
              },
              */
              {
                label: this.translate.instant('RECEIVE_ORDERS'),
                routerLink: 'orders/receive-order',
                command: () => {
                  this.activePageTitle(this.translate.instant('RECEIVE_ORDERS'));
                },
                visible: this.checkActionMenu('RECEIVE_ORDERS', 'ORDERS')
              },
              {
                label: this.translate.instant('INCOMING_INVOICE_RECEIVE'),
                routerLink: 'orders/incoming-invoice-list',
                command: () => {
                  this.activePageTitle(this.translate.instant('INCOMING_INVOICE_RECEIVE'));
                },
                visible: this.checkActionMenu('INCOMING_INVOICE_RECEIVE', 'ORDERS')
              },
              {
                label: this.translate.instant('WEEKLY_SCHEDULES'),
                routerLink: 'orders/weekly-schedules',
                command: () => {
                  this.activePageTitle(this.translate.instant('WEEKLY_SCHEDULES'));
                },
                visible: this.checkActionMenu('WEEKLY_SCHEDULES', 'ORDERS')
              }
            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('INVENTORY'),
            icon: 'pi pi-list',
            title: 'inventory',
            id: 'inventory',
            visible: this.findInPermissions('INVENTORY'),
            items: [
              {
                label: this.translate.instant('INVENTORY_ITEMS'),
                routerLink: 'inventory/inventory-items',
                command: () => {
                  this.activePageTitle(this.translate.instant('INVENTORY_ITEMS'));
                },
                visible: this.checkActionMenu('INVENTORY_ITEMS', 'INVENTORY')
              },
              {
                label: this.translate.instant('STOCK_TRANSFERS'),
                routerLink: 'stock-transfers',
                command: () => {
                  this.activePageTitle(this.translate.instant('STOCK_TRANSFERS'));
                },
                visible: this.checkActionMenu('STOCK_TRANSFERS', 'INVENTORY')
              },
              {
                label: this.translate.instant('INVENTORY'),
                routerLink: '/inventory',
                command: () => {
                  this.activePageTitle(this.translate.instant('INVENTORY'));
                },
                visible: this.checkActionMenu('INVENTORY', 'INVENTORY')
              },
              {
                label: this.translate.instant('ALLERGENS'),
                routerLink: '/inventory/allergens',
                command: () => {
                  this.activePageTitle(this.translate.instant('ALLERGENS'));
                },
                visible: this.checkActionMenu('ALLERGENS', 'INVENTORY')
              },
              {
                label: this.translate.instant('SUB_RECIPES'),
                routerLink: '/inventory/recipe-preparation-items',
                command: () => {
                  this.activePageTitle(this.translate.instant('SUB_RECIPES'));
                },
                visible: this.checkActionMenu('SUB_RECIPES', 'INVENTORY')
              },
              {
                label: this.translate.instant('MENU_ITEMS'),
                routerLink: '/inventory/menu-items',
                command: () => {
                  this.activePageTitle(this.translate.instant('MENU_ITEMS'));
                },
                visible: this.checkActionMenu('MENU_ITEMS', 'INVENTORY')
              },
              {
                label: this.translate.instant('COOKBOOK'),
                routerLink: '/inventory/cook-book',
                command: () => {
                  this.activePageTitle(this.translate.instant('COOKBOOK'));
                },
                visible: this.checkActionMenu('COOKBOOK', 'INVENTORY')
              },
              {
                label: this.translate.instant('WASTE_EVENTS'),
                routerLink: '/inventory/waste-events',
                command: () => {
                  this.activePageTitle(this.translate.instant('WASTE_EVENTS'));
                },
                visible: this.checkActionMenu('WASTE_EVENTS', 'INVENTORY')
              }
              /*
              ,{
                label: this.translate.instant('PRODUCTIONS_EVENTS'),
                routerLink: '/inventory/work-orders',
                command: () => {
                  this.activePageTitle(this.translate.instant('PRODUCTIONS_EVENTS'));
                },
                visible: this.checkActionMenu('PRODUCTIONS_EVENTS', 'INVENTORY')
              },
              {
                label: this.translate.instant('SALES_SUMMARY'),
                routerLink: '/inventory/sales-summary',
                command: () => {
                  this.activePageTitle(this.translate.instant('SALES_SUMMARY'));
                },
                visible: this.checkActionMenu('SALES_SUMMARY', 'INVENTORY')
              }*/
            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('PRODUCTION'),
            icon: 'pi pi-cog',
            title: 'suppliers',
            id: 'suppliers',
            visible: this.findInPermissions('PRODUCTION'),
            items: [
              {
                label: this.translate.instant('DASHBOARD'),
                routerLink: '/work-orders/dashboard',
                command: () => {
                  this.activePageTitle(this.translate.instant('PRODUCE_DASHBOARD'));
                },
                visible: this.checkActionMenu('PRODUCE_DASHBOARD', 'PRODUCTION')
              },
              {
                label: this.translate.instant('SALES'),
                routerLink: '/in-company-orders',
                command: () => {
                  this.activePageTitle(this.translate.instant('SALES'));
                },
                visible: this.checkActionMenu('SALES', 'PRODUCTION')
              },
              {
                label: this.translate.instant('PLAN'),
                routerLink: 'planning',
                command: () => {
                  this.activePageTitle(this.translate.instant('PLAN'));
                },
                visible: this.checkActionMenu('PLAN', 'PRODUCTION')
              },
              {
                label: this.translate.instant('EXPIRATION_DATE_STOCKS'),
                routerLink: 'planning/expiration_date_stocks',
                command: () => {
                  this.activePageTitle(this.translate.instant('EXPIRATION_DATE_STOCKS'));
                },
                visible: this.checkActionMenu('EXPIRATION_DATE_STOCKS', 'PRODUCTION')
              },
              {
                label: this.translate.instant('WORK_ORDERS'),
                routerLink: 'work-orders',
                command: () => {
                  this.activePageTitle(this.translate.instant('WORK_ORDERS'));
                },
                visible: this.checkActionMenu('WORK_ORDERS', 'PRODUCTION')
              },
              {
                label: this.translate.instant('LOCATION_EXPENSES'),
                routerLink: 'location-expenses',
                command: () => {
                  this.activePageTitle(this.translate.instant('LOCATION_EXPENSES'));
                },
                visible: this.checkActionMenu('WORK_ORDERS', 'PRODUCTION')
              },
              {
                label: this.labelShow === true ? '' : this.translate.instant('PRODUCTION_REPORTS'),
                title: 'production reports',
                id: 'production reports',
                visible: this.checkActionMenu('PRODUCTION_REPORTS', 'PRODUCTION'),
                items: [
                  {
                    label: this.translate.instant('DAILY_PRODUCTION_REPORT'),
                    routerLink: 'work-order/work-order-reports/daily-production-report',
                  },
                  {
                    label: this.translate.instant('PRODUCT_PRODUCTION_REPORT'),
                    routerLink: 'work-order/work-order-reports/product-production-report',
                  },
                  {
                    label: this.translate.instant('ORDER_REPORT'),
                    routerLink: 'work-order/work-order-reports/order-report',
                  },
                  {
                    label: this.translate.instant('DEPARTMENT_PRODUCTION_REPORT'),
                    routerLink: 'work-order/work-order-reports/department-production-report',
                  },
                  {
                    label: this.translate.instant('INCOME_EXPENSE_ANALYSIS'),
                    routerLink: '',
                  },
                  {
                    label: this.translate.instant('RETURN_REPORT'),
                    routerLink: 'work-order/work-order-reports/unpaid-production-report',
                  }
                ]
              },
            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('SUPPLIERS'),
            icon: 'pi pi-users',
            title: 'suppliers',
            id: 'suppliers',
            visible: this.findInPermissions('SUPPLIERS'),
            items: [
              {
                label: this.translate.instant('SUPPLIERS'),
                routerLink: 'contacts',
                command: () => {
                  this.activePageTitle(this.translate.instant('SUPPLIERS'));
                },
                visible: this.checkActionMenu('SUPPLIERS', 'SUPPLIERS')
              },
              {
                label: this.translate.instant('PRICES'),
                routerLink: 'contacts',
                command: () => {
                  this.activePageTitle(this.translate.instant('PRICES'));
                },
                visible: this.checkActionMenu('PRICES', 'SUPPLIERS')
              }
            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('ACCOUNTING'),
            icon: 'pi pi-user-edit',
            title: 'accounting',
            id: 'accounting',
            visible: this.findInPermissions('ACCOUNTING'),
            items: [
              {
                label: this.translate.instant('INVOICE_DELIVERY_NOTES'),
                routerLink: 'accounting/invoice-delivery-notes',
                command: () => {
                  this.activePageTitle(this.translate.instant('INVOICE_DELIVERY_NOTES'));
                },
                visible: this.checkActionMenu('INVOICE_DELIVERY_NOTES', 'ACCOUNTING')
              },
              {
                label: this.translate.instant('RETURN_INVOICES'),
                routerLink: 'accounting/return-invoices',
                command: () => {
                  this.activePageTitle(this.translate.instant('RETURN_INVOICES'));
                },
                visible: this.checkActionMenu('RETURN_INVOICES', 'ACCOUNTING')
              },
              {
                label: this.translate.instant('SCANNED_INVOICES'),
                routerLink: 'accounting/scanned-invoices',
                command: () => {
                  this.activePageTitle(this.translate.instant('SCANNED_INVOICES'));
                },
                visible: this.checkActionMenu('SCANNED_INVOICES', 'ACCOUNTING')
              },
              {
                label: this.translate.instant('CREDIT_REMINDER'),
                routerLink: 'accounting/credit-reminders',
                command: () => {
                  this.activePageTitle(this.translate.instant('CREDIT_REMINDER'));
                },
                visible: this.checkActionMenu('CREDIT_REMINDER', 'ACCOUNTING')
              },
              {
                label: this.translate.instant('IRREGULAR_PRICES'),
                routerLink: 'accounting/irregular-prices',
                command: () => {
                  this.activePageTitle(this.translate.instant('IRREGULAR_PRICES'));
                },
                visible: this.checkActionMenu('IRREGULAR_PRICES', 'ACCOUNTING')
              }
            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('REPORTS'),
            icon: 'pi pi-chart-line',
            title: 'reports',
            id: 'reports',
            visible: this.findInPermissions('REPORTS'),
            items: [
              {
                label: this.labelShow === true ? '' : this.translate.instant('INCOME_REPORTS'),
                title: 'daily reports',
                id: 'daily reports',
                items: [
                  {
                    label: this.translate.instant('DAILY_INCOME_REPORT'),
                    routerLink: 'reports/sale-reports/daily-income',
                  },
                  {
                    label: this.translate.instant('PRODUCT_GROUP_REPORT'),
                    routerLink: 'reports/sale-reports/product-group',
                  },
                  {
                    label: this.translate.instant('MENU_ITEM_REPORT'),
                    routerLink: 'reports/sale-reports/menu-item-group',
                  },
                  {
                    label: this.translate.instant('MENU_GROUP_COMPARISON_REPORT'),
                    routerLink: 'reports/sale-reports/menu-group-comparison',
                  },
                  {
                    label: this.translate.instant('UNPAID_REPORT'),
                    routerLink: 'reports/sale-reports/unpaid-report/by-daily',
                  }
                ]
              },
              {
                label: this.translate.instant('LAST_PURCHASE_REPORTS'),
                routerLink: 'reports/last-purchase-reports',
                command: () => {
                  this.activePageTitle(this.translate.instant('LAST_PURCHASE_REPORTS'));
                },
                visible: this.checkActionMenu('LAST_PURCHASE_REPORTS', 'REPORTS')
              },
              {
                label: this.translate.instant('INVENTORY_REPORTS'),
                routerLink: 'reports/inventory-reports',
                command: () => {
                  this.activePageTitle(this.translate.instant('INVENTORY_REPORTS'));
                },
                visible: this.checkActionMenu('INVENTORY_REPORTS', 'REPORTS')
              },
              {
                label: this.translate.instant('COGS_AND_GROSS_PROFITS'),
                routerLink: 'reports/cogs-and-gross-profits',
                command: () => {
                  this.activePageTitle(this.translate.instant('IRREGULAR_PRICES'));
                },
                visible: this.checkActionMenu('IRREGULAR_PRICES', 'REPORTS')
              },
              {
                label: this.translate.instant('LAST_PURCHASE_PRICE_REPORTS'),
                routerLink: 'reports/purchase-report',
                command: () => {
                  this.activePageTitle(this.translate.instant('LAST_PURCHASE_PRICE_REPORTS'));
                },
                visible: this.checkActionMenu('PURCHASE_REPORTS', 'REPORTS')
              },
              {
                label: this.translate.instant('PRICE_REPORTS'),
                routerLink: 'reports/price-reports',
                command: () => {
                  this.activePageTitle(this.translate.instant('IRREGULAR_PRICES'));
                },
                visible: this.checkActionMenu('IRREGULAR_PRICES', 'REPORTS')
              },
              {
                label: this.translate.instant('PRICE_CHANGE_REPORTS'),
                routerLink: 'reports/price-change-reports',
                command: () => {
                  this.activePageTitle(this.translate.instant('PRICE_CHANGE_REPORTS'));
                },
                visible: this.checkActionMenu('PRICE_CHANGE_REPORTS', 'REPORTS')
              },
              {
                label: this.translate.instant('ORDERS_BY_SUPPLIER'),
                routerLink: 'reports/orders-by-suppliers',
                command: () => {
                  this.activePageTitle(this.translate.instant('ORDERS_BY_SUPPLIER'));
                },
                visible: this.checkActionMenu('ORDERS_BY_SUPPLIER', 'REPORTS')
              },
              {
                label: this.translate.instant('WASTE_REPORTS'),
                routerLink: 'reports/waste-reports',
                command: () => {
                  this.activePageTitle(this.translate.instant('WASTE_REPORTS'));
                },
                visible: this.checkActionMenu('WASTE_REPORTS', 'REPORTS')
              },
              {
                label: this.translate.instant('STOCK_MOVEMENTS'),
                routerLink: 'stock-movements',
                command: () => {
                  this.activePageTitle(this.translate.instant('STOCK_MOVEMENTS'));
                },
                visible: this.checkActionMenu('STOCK_MOVEMENTS', 'REPORTS')
              }

            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('SETTINGS'),
            icon: 'pi pi-sliders-v',
            title: 'settings',
            id: 'settings',
            visible: this.findInPermissions('SETTINGS'),
            items: [
              {
                label: this.translate.instant('GENERAL'),
                routerLink: 'settings/general',
                command: () => {
                  this.activePageTitle(this.translate.instant('GENERAL'));
                },
                visible: this.checkActionMenu('GENERAL', 'SETTINGS')
              },
              {
                label: this.translate.instant('USERS'),
                routerLink: 'settings/users',
                command: () => {
                  this.activePageTitle(this.translate.instant('USERS'));
                },
                visible: this.checkActionMenu('USERS', 'SETTINGS')
              },
              {
                label: this.translate.instant('LOCATIONS'),
                routerLink: 'settings/locations',
                command: () => {
                  this.activePageTitle(this.translate.instant('LOCATIONS'));
                },
                visible: this.checkActionMenu('LOCATIONS', 'SETTINGS')
              },
              {
                label: this.translate.instant('SUBSCRIPTION'),
                routerLink: 'settings/subscriptions',
                command: () => {
                  this.activePageTitle(this.translate.instant('SUBSCRIPTION'));
                },
                visible: this.checkActionMenu('LOCATIONS', 'SETTINGS')
              },
              {
                label: this.translate.instant('UNITS'),
                routerLink: 'settings/units',
                command: () => {
                  this.activePageTitle(this.translate.instant('UNITS'));
                },
                visible: this.checkActionMenu('UNITS', 'SETTINGS')
              },
              {
                label: this.translate.instant('CATEGORIES'),
                routerLink: 'settings/categories',
                command: () => {
                  this.activePageTitle(this.translate.instant('CATEGORIES'));
                },
                visible: this.checkActionMenu('CATEGORIES', 'SETTINGS')
              },
              {
                label: this.translate.instant('TAX_RATES'),
                routerLink: 'settings/tax-rates',
                command: () => {
                  this.activePageTitle(this.translate.instant('TAX_RATES'));
                },
                visible: this.checkActionMenu('TAX_RATES', 'SETTINGS')
              },
              {
                label: this.translate.instant('ROLES'),
                routerLink: 'settings/roles',
                command: () => {
                  this.activePageTitle(this.translate.instant('ROLES'));
                },
                visible: this.checkActionMenu('ROLES', 'SETTINGS')
              },
              {
                label: this.translate.instant('INTEGRATIONS'),
                routerLink: 'settings/integrations',
                command: () => {
                  this.activePageTitle(this.translate.instant('INTEGRATIONS'));
                },
                visible: this.checkActionMenu('INTEGRATIONS', 'SETTINGS')
              },
              {
                label: this.translate.instant('DATA_IMPORT'),
                routerLink: 'settings/import',
                command: () => {
                  this.activePageTitle(this.translate.instant('DATA_IMPORT'));
                },
                visible: this.checkActionMenu('DATA_IMPORT', 'SETTINGS')
              },
              /*
              {
                label: this.translate.instant('FLUSH_ALL_DATA'),
                routerLink: null,
                command: () => {
                  this.activePageTitle(this.translate.instant('FLUSH_ALL_DATA'));
                  this.deleteDemoData().then();
                }
              },*/
            ]
          },
          {
            label: this.labelShow === true ? '' : this.translate.instant('LOGOUT'),
            icon: 'pi pi-sign-out',
            title: 'logouts',
            command: () => {
              this.logout();
            }
          },

        ];
      }
      this.listTitles = this.mainMenu.filter(listTitle => listTitle);
    }, 500);
  }

  checkActionMenu(text, type): boolean {
    let item;

    if (this.actions){
      if (type === 'ORDERS') {
        item = this.actions[0].orders?.find(order => order.name === text);
      } else if (type === 'INVENTORY') {
        item = this.actions[0].inventory?.find(order => order.name === text);
      } else if (type === 'SUPPLIERS') {
        item = this.actions[0].suppliers?.find(order => order.name === text);
      } else if (type === 'ACCOUNTING') {
        item = this.actions[0].accounting?.find(order => order.name === text);
      } else if (type === 'REPORTS') {
        item = this.actions[0].reports?.find(order => order.name === text);
      } else if (type === 'SETTINGS') {
        item = this.actions[0].settings?.find(order => order.name === text);
      } else if (type === 'PRODUCTION') {
      item = this.actions[0].production?.find(order => order.name === text);
    }
    }

    return item ? item.value : false;
  }

  mainMenuIconLoad(): void {
    setTimeout(() => {

      if (window.localStorage.getItem('role') === '"ROLE_SUPPLIER"') {
        this.mainMenuIcon = [
          {
            icon: 'pi pi-home',
          },
          {
            icon: 'pi pi-shopping-cart',
          }

        ];
      } else {
        this.mainMenuIcon = [
          {
            icon: 'pi pi-home',
          },
          {
            icon: 'pi pi-shopping-cart'
          },
          {
            icon: 'pi pi-list'
          },
          {
            icon: 'pi pi-users'
          },
          {
            icon: 'pi pi-user-edit'
          },
          {
            icon: 'pi pi-chart-line'
          },
          {
            icon: 'pi pi-sliders-v'
          }

        ];
      }

    }, 500);
  }

  sidebarWideOpen(): void {
    const mainPanel = document.getElementsByClassName('q-main')[0] as HTMLElement;
    const sidebar = document.getElementsByClassName('q-sidebar')[0] as HTMLElement;
    const avatar = document.getElementsByClassName('avatar')[0] as HTMLElement;
    // this.menuLoad();
    mainPanel.style.width = 'calc(100% - 248px)';
    sidebar.style.width = '238px';
    this.labelShow = false;
    avatar.style.flexDirection = 'row';
  }

  sidebarShortOpen(): void {
    const mainPanel = document.getElementsByClassName('q-main')[0] as HTMLElement;
    const sidebar = document.getElementsByClassName('q-sidebar')[0] as HTMLElement;
    const avatar = document.getElementsByClassName('avatar')[0] as HTMLElement;
    // this.menuLoad();
    this.labelShow = true;
    mainPanel.style.width = 'calc(100% - 95px)';
    sidebar.style.width = '85px';
    avatar.style.flexDirection = 'column';
  }

  findInPermissions(title): boolean {

    let visible = false;
    const array = JSON.parse(window.localStorage.getItem('actions'));
    let searchItem = any;
    if (title === 'SETTINGS'){ searchItem = array[0].settings; }
    if (title === 'INVENTORY'){ searchItem = array[0].inventory; }
    if (title === 'SUPPLIERS'){ searchItem = array[0].suppliers; }
    if (title === 'ACCOUNTING'){ searchItem = array[0].accounting; }
    if (title === 'PRODUCTION' && this.isProducer === true){ searchItem = array[0].production; }
    if (title === 'REPORTS'){ searchItem = array[0].reports; }
    if (title === 'ORDERS'){ searchItem = array[0].orders; }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < searchItem.length; i++) {
      if (searchItem[i].value === true) {
        visible = true;
        break;
      }
    }
    return visible;
  }

  ontranslate(lang: string): void {
    this.translate.use(lang);
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  onRightSidebarDisplay(): void {
    this.rightSidebarDisplay = true;
  }
  onLeftSidebarDisplay(): void {
    this.leftSidebarDisplay = true;
  }

}
