import {Component, OnInit} from '@angular/core';
import {StockMediaObjectModel} from '../../../model/stock-media-object.model';
import {HttpService} from '../../../service/http.service';
import {FileUpload} from 'primeng/fileupload';
import {environment} from '../../../../environments/environment';
import {QueueModel} from '../../../model/queue.model';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent implements OnInit {

  response: any;
  loading: boolean;
  entity: string;
  queueDisplay = false;

  constructor(private http: HttpService) {
  }

  ngOnInit(): void {
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
    });
    this.controlImportStatus();
  }

  async upload(event, entity: string, fileUpload: FileUpload): Promise<void> {
    this.response = {};

    this.entity = entity;
    this.loading = true;

    for (const file of event.files) {
      const fd = new FormData();
      fd.append('file', file);

      await this.http.post(`/api/data/import/${entity}`, fd).then((response: StockMediaObjectModel) => {
        this.response = response;
      }).catch(error => {
      });
    }

    fileUpload.clear();

    this.loading = false;
    this.queueDisplay = true;
  }

  download(entity: string): void {
    window.open(`${environment.apiUrl}/examples/${entity}.xlsx`, '_blank').focus();
  }

  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 5000);
    }
  }
}
