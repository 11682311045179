import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {LazyLoadEvent} from 'primeng/api';
import {UserModel} from '../../../model/user.model';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {ActivatedRoute, Router, Route} from '@angular/router';
import {ContactModel} from '../../../model/contact.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CategoryModel} from '../../../model/category.model';
import {UnitModel} from '../../../model/unit.model';
import {CurrencyModel} from '../../../model/currency.model';
import {LocationModel} from '../../../model/location.model';
import {CurrencyService} from '../../../service/currency.service';
import {CategoryService} from '../../../service/category.service';
import {ContactService} from '../../../service/contact.service';
import {StockModel} from '../../../model/stock.model';
import {DialogService} from 'primeng/dynamicdialog';
import {LocationService} from '../../../service/location.service';
import {UtilityService} from '../../../service/utility.service';
import {PrintTemplateModel, PrintTemplateTypes} from '../../../model/print-template.model';
import {environment} from '../../../../environments/environment';
import {QueueModel} from '../../../model/queue.model';
import {NotificationService} from '../../../service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductRecipeModel} from '../../../model/product-recipe.model';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: [DialogService]
})
export class ProductListComponent implements OnInit {

  products: StockVariantModel[] = [];
  loading: boolean;
  tableLoading: boolean;
  total: number;
  user: UserModel = new UserModel();
  settings = [];
  taxes: TaxRateModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  locations: LocationModel[] = [];
  categories: CategoryModel[] = [];
  suppliers: ContactModel[] = [];
  searchCategories = [];
  searchSuppliers = [];
  searchLocations = [];
  parameters = {
    id: '',
    name: '',
    code: '',
    'category.name': '',
    'currency.code': '',
    type: '',
    'category.id': '',
    'supplier.id': '',
    'stockQuantities.location': '',
    locations: '',
    page: 0,
    itemsPerPage: 20
  };
  display: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  type: string;
  moreLoading: boolean;
  showQuantitiesDialog: boolean;
  quantities: any [];
  printing: boolean;
  printTemplate: PrintTemplateModel = new PrintTemplateModel();
  apiUrl: string;
  queueDisplay = false;
  recipe: ProductRecipeModel[] = [];
  recipeDialog: boolean;
  recipeId: number;

  constructor(private http: HttpService, private dataTableSettingService: DataTableSettingService, private dialog: DialogService,
              private router: Router, private activatedRoute: ActivatedRoute, private currencyService: CurrencyService,
              private utilityService: UtilityService, private sharedDataService: NotificationService,
              private categoryService: CategoryService, private contactService: ContactService,
              private locationService: LocationService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.apiUrl = environment.apiUrl;
    this.parameters.type = this.activatedRoute.snapshot.data.type;
    this.type = this.activatedRoute.snapshot.data.type;
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.loadSearchData().then(e => {
      // this.loading = false;
    });
    this.sharedDataService.setData([{
      name: 'STOCK_REMAINING',
      title: this.translate.instant('STOCK_IMPORT_REMAINING'),
      url: 'aaaaaaa'
    }]);
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
      this.controlImportStatus();
    });
  }

  loadStocksData(event: LazyLoadEvent = null, type = null): void {
    if (type !== 'search') {
      this.parameters.page = this.parameters.page + 1;
    } else {
      this.products = [];
      this.parameters.page = 1;
    }
    if (event?.sortField) {
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    }

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.tableLoading = true;
    this.http.get(StockModel.IRI, this.parameters).subscribe(response => {
      response['hydra:member'].map(res => {
        this.products.push(res);
      });
      this.total = response['hydra:totalItems'];
      this.tableLoading = false;
      if (this.total >= this.parameters.page * this.parameters.itemsPerPage) {
        this.moreLoading = true;
      } else {
        this.moreLoading = false;
      }
      this.loading = false;
    });
  }

  cost(item: StockModel): number {
    if (item.type === 'product') {
      return item.variants[0].totalCost;
    } else {
      if (item.useAveragePrice) {
        return item.variants[0].averageCost;
      } else {
        return item.variants[0].price;
      }
    }
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      'products',
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  async loadSearchData(): Promise<void> {
    this.loading = true;
    this.searchCategories = this.categoryService.getSearchCategories();
    this.searchSuppliers = this.contactService.getSearchContacts({pagination: false, type: 'supplier'});
    this.locations = this.searchLocations = this.locationService.getSearchLocation();

  }

  changeParameters(event, type): void {
    if (type === 'category') {
      this.parameters['category.id'] = event.value;
    }
    if (type === 'supplier') {
      this.parameters['supplier.id'] = event.value;
    }
    if (type === 'locations') {
      const parts = event.value.split('/');
      const lastPart = parts[parts.length - 1];
      this.parameters.locations = lastPart;
      this.parameters['stockQuantities.location'] = lastPart;
    }
    this.loadStocksData(null, 'search');
  }

  reload(): void {
    window.location.reload();
  }

  async edit(event): Promise<void> {
    this.loading = true;
    await this.http.get(`${StockModel.IRI}/${event.id}`).subscribe((response) => {
      this.item = response;
      this.item.category = this.item.category ? this.item.category['@id'] : null;
      this.item.locations = this.item.locations ? this.item.locations : null;
      this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
      this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
      this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
      // @ts-ignore
      this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
      this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
      this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
      this.item.purchaseOptions.map((data, i) => {
        this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
          this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
          this.item.purchaseOptions[i].supplier = data.supplier['@id'];
          this.item.purchaseOptions[i].unit = data.unit['@id'];
          if (data.packName === '' && data.packPerCase === null) {
            this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
          } else {
            this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
          }

        });
      });
      this.item.stockQuantities.map((stockQuantity, i) => {
        this.item.stockQuantities[i] = stockQuantity['@id'];
      });
      this.displayCreate = true;
      this.display = true;
      this.loading = false;
    });

  }

  openForm(): void {
    // this.item = null;
    this.display = true;
    this.displayCreate = true;
    this.displayUpdate = false;
  }

  getStockQuantitiesAmount(row): number {
    const findIndex = row.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters.locations);

    if (findIndex !== -1) {
      return row.stockQuantities[findIndex].amount;
    } else {
      return 0;
    }
  }
  getStockQuantitiesLastCount(row): number {
    const findIndex = row.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters.locations);

    if (findIndex !== -1) {
      return row.stockQuantities[findIndex].lastQuantity;
    } else {
      return 0;
    }
  }

  getAveragePrice(row): number {
    const findIndex = row.stockQuantities.findIndex(obj => obj.location === '/api/locations/' + this.parameters.locations);

    if (findIndex !== -1) {
      return row.stockQuantities[findIndex].averagePrice;
    } else {
      return 0;
    }
  }

  showLocationQuantity(row): void {
    this.quantities = row;
    this.showQuantitiesDialog = true;
  }

  getLocationName(id): string {
    const findIndex = this.locations.findIndex(obj => obj.id === id);
    if (findIndex === -1){
      return '';
    }else{
      return this.locations[findIndex].name;
    }
  }

  createExcel(): void {
    const parameters = {...this.parameters};
    delete parameters.page;
    delete parameters.id;

    this.http.download('/api/data/exports/stocks')
      .subscribe(r => {
        this.utilityService.downloadFile(r, this.type + '.xlsx');
      });
  }

  createPdf(id: number, templateId: number): void {
    this.printing = true;
    this.http.get(`${StockVariantModel.IRI}/pdf`).subscribe(response => {
      this.printing = false;
      window.open(response.response, '_blank');
    });
  }

  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 30000);
    }
  }

  onLoadRecipes(id): void {
    this.recipeId = id;
    this.http.get(ProductRecipeModel.IRI, {'items.stock.id': id}).subscribe(res => {
      this.recipe = res['hydra:member'];
      this.loading = false;
      this.recipeDialog = true;

    });
  }
  getRecipeTotal(recipe): number {
    if (recipe){
      let grandTotal = 0;
      recipe.map( item => {
        grandTotal = grandTotal + item.cost;
      });
      return grandTotal;
    }
    return 0;
  }
}
