
<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'INTEGRATIONS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'INTEGRATIONS'"></app-page-info>
    </div>
  </div>
      <div *ngIf="!loading">
        <div class="p-grid">
          <div class="p-col-6">
            <h3>POS</h3>
            <div *ngFor="let item of integrations">
              <div *ngIf="item.isActive && item.type ==='pos'"  class="p-col-12">
                <img [src]="item.logo " width="150" alt="">
                <!---
                <p-button *ngIf="item.name === 'Luca'" class="p-button-secondary" (click)="openModal(item.id)" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="activeIntegrations[item['@id']]" (click)="disconnect(activeIntegrations[item['@id']])" class="p-button-secondary"  [icon]="'pi pi-trash'" styleClass="p-button-secondary p-ml-2" [style]="{float: 'right'}" [label]="'DISCONNECT'|translate"></p-button>
                <p-button *ngIf="item.name === 'KolayBi'" class="p-button-secondary" (click)="connectKolayBi()" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Paraşüt'" class="p-button-secondary" (click)="connectParasut(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Efatura'" class="p-button-secondary" (click)="connectEfatura(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Logo'" class="p-button-secondary" (click)="connectLogo(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Linga'" class="p-button-secondary" (click)="connectLinga(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Poscana'" class="p-button-secondary" (click)="connectPoscana(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Square'" class="p-button-secondary" (click)="connectSquare(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Adisyo'" class="p-button-secondary" (click)="connectAdisyo(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Paycell'" class="p-button-secondary" (click)="connectPaycell(activeIntegrations[item['@id']], '603')" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-divider></p-divider>
              </div>
            </div>
          </div>
          <div class="p-col-6">
            <h3>{{'ACCUONTING' | translate}}</h3>
            <div *ngFor="let item of integrations">
              <div *ngIf="item.isActive && item.type ==='accounting'"  class="p-col-12">
                <img [src]="item.logo " width="150" alt="">
                <p-button *ngIf="item.name === 'Luca'" class="p-button-secondary" (click)="openModal(item.id)" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="activeIntegrations[item['@id']]" (click)="disconnect(activeIntegrations[item['@id']])" class="p-button-secondary"  [icon]="'pi pi-trash'" styleClass="p-button-secondary p-ml-2" [style]="{float: 'right'}" [label]="'DISCONNECT'|translate"></p-button>
                <p-button *ngIf="item.name === 'KolayBi'" class="p-button-secondary" (click)="connectKolayBi()" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Paraşüt'" class="p-button-secondary" (click)="connectParasut(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Efatura'" class="p-button-secondary" (click)="connectEfatura(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'Logo'" class="p-button-secondary" (click)="connectLogo(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'TikoHesap'" class="p-button-secondary" (click)="connectTikoHesap(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                <p-button *ngIf="item.name === 'BizimHesap'" class="p-button-secondary" (click)="connectBizimHesap(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                --->
                <p-divider></p-divider>
              </div>
            </div>
          </div>

          <div class="p-col-6">
            <h3>{{'INTEGRATOR'}}</h3>
            <div *ngFor="let item of integrations">
              <div *ngIf="item.isActive && item.type ==='integrator'"  class="p-col-12">
                <img [src]="item.logo " width="150" alt="">
                <!---
                <p-button *ngIf="item.name === 'Logo'" class="p-button-secondary" (click)="connectLogo(activeIntegrations[item['@id']])" [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary" [style]="{float: 'right'}" [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
                --->
                <p-divider></p-divider>
              </div>
            </div>
          </div>
        </div>
        </div>
</p-card>
