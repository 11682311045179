<div class="p-grid">
    <div class="p-col-12">
    <h3>{{'ACCURATE_INVENTORY_ENTRY' | translate}}</h3>
     <p>{{'ACCURATE_INVENTORY_ENTRY_DESCRIPTION' | translate}}</p>
   </div>
    <div class="p-col-12">
      1- <b>{{'COST_CONTROL' | translate}} </b>
      {{'COST_CONTROL_DESCRIPTION' | translate}}
    </div>
    <div class="p-col-12">
      2- <b>{{'QUALITY_CONTROL' | translate}} </b>
      {{'QUALITY_CONTROL_DESCRIPTION' | translate}}
    </div>
    <div class="p-col-12">
      3- <b>{{'SUPPLIER_MANAGEMENT' | translate}} </b>
      {{'SUPPLIER_MANAGEMENT_DESCRIPTION' | translate}}
    </div>
    <div class="p-col-12">
      4- <b>{{'INVENTORY_UPDATES' | translate}} </b>
      {{'INVENTORY_UPDATES_DESCRIPTION' | translate}}
    </div>
    <div class="p-col-12">
      5- <b>{{'REPORTING_AND_ANALYSIS' | translate}} </b>
      {{'REPORTING_AND_ANALYSIS_DESCRIPTION' | translate}}
    </div>
    <div class="p-col-12">
      6- <b>{{'OPERATIONAL_EFFICIENCY' | translate}} </b>
      {{'OPERATIONAL_EFFICIENCY_DESCRIPTION' | translate}}
    </div>
  </div>
  