import {Component, OnDestroy, OnInit} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CurrencyModel} from '../../../model/currency.model';
import {HttpService} from '../../../service/http.service';
import {CategoryModel} from '../../../model/category.model';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {StockService} from '../../../service/stock.service';
import {CurrencyService} from '../../../service/currency.service';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';

@Component({
  selector: 'app-quick-stock-create',
  templateUrl: './quick-stock-create.component.html',
  styleUrls: ['./quick-stock-create.component.scss']
})
export class QuickStockCreateComponent implements OnInit, OnDestroy {

  stock: StockModel = new StockModel();
  taxes: TaxRateModel[] = [];
  currencies: CurrencyModel[] = [];
  categories: CategoryModel[] = [];
  currency: string;

  loading = false;
  submitted = false;
  close = false;

  errors = [];

  validationSubscriber: Subscription;

  constructor(private http: HttpService, private ref: DynamicDialogRef, private stockService: StockService,
              private currencyService: CurrencyService, private validationService: ValidationService) { }

  ngOnInit(): void {
    this.loadStockNumber();
    this.loadFormOptions();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  loadFormOptions(): void {
    this.http.get(TaxRateModel.IRI).subscribe((response => {
      this.taxes = response['hydra:member'];
      if (this.taxes.length > 0) {
        this.stock.tax = this.taxes[0]['@id'];
      }
    }));
    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel) => {
      this.currencies = response['hydra:member'];
      if (this.currencies.length > 0 && this.stock.currency === null) {
        this.stock.currency = this.currencies[0]['@id'];
        this.currency = this.currencies[0].code;
      }
    });
    this.http.get(CategoryModel.IRI).subscribe((response => {
      this.categories = response['hydra:member'];
      if (this.categories.length > 0) {
        this.stock.category = this.categories[0]['@id'];
      }
    }));
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;
    this.stock.variants[0].name = this.stock.name;

    await this.http.post(StockModel.IRI, this.stock).then((response: StockModel) => {

      if (response) {
        this.stockService.updateStocks(response);

        if (this.close) {
          this.ref.close({saved: true, stock: response});
        } else {
          this.loadStockNumber();
        }
      }
    }).catch(error => {
    });

    this.submitted = false;
  }

  onChangeCurrency(currency): void {
    this.currencies.map(item => {
      if (item['@id'] === currency.value) {
        this.currency = item.code;
      }
    });
  }

  loadStockNumber(): void {
    this.loading = true;
    this.http.get(`${StockModel.IRI}/stock_number`).toPromise().then((response: StockModel) => {
      this.stock = response;
      delete this.stock.id;
      delete this.stock['@id'];
      delete this.stock['@context'];
      delete this.stock['@type'];
      delete this.stock.variants[0]['@id'];
      delete this.stock.variants[0].stock;
      delete this.stock.variants[0].id;

      this.stock.isBuying = false;
      this.stock.isMaking = true;
      this.stock.type = 'product';
      this.currency = this.currencyService.getCurrency().code;
      this.stock.currency = this.currencyService.getCurrency()['@id'];

      if (this.taxes.length > 0) {
        this.stock.tax = this.taxes[0]['@id'];
      }
      if (this.currencies.length > 0 && this.stock.currency == null) {
        this.stock.currency = this.currencies[0]['@id'];
      }
      if (this.categories.length > 0) {
        this.stock.category = this.categories[0]['@id'];
      }

      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }
}
