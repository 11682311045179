<div class="p-grid" style="margin-top: 10%;">
  <div class="p-col-8 p-offset-2 p-o">
      <p-card class="p-mt-6" >
        <div class="p-p-3 p-text-center">
          <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
        </div>
        <h1 class="p-text-center">{{ 'YOUR_TRIAL_HAS_EXPIRED'|translate }}</h1>
        <p class="p-text-center">{{ 'YOUR_TRIAL_HAS_EXPIRED_DESCRIPTION'|translate }}</p>
        <p style="height: 25px;">
          <p-button label="{{'LETS_GO' | translate}}" [style]="{float: 'right'}" (onClick)="nextPage()"
                    icon="pi pi-angle-right" iconPos="right"></p-button>
        </p>
      </p-card>
  </div>
</div>
<router-outlet></router-outlet>
