import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {QueueModel} from '../../../../model/queue.model';
import {HttpService} from '../../../../service/http.service';
import {FileUpload} from 'primeng/fileupload';
import {StockMediaObjectModel} from '../../../../model/stock-media-object.model';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-stock-import',
  templateUrl: './stock-import.component.html',
  styleUrls: ['./stock-import.component.scss']
})
export class StockImportComponent implements OnInit {

  items: MenuItem[];
  response: any;
  loading: boolean;
  entity: string;
  queueDisplay = false;
  constructor(private translate: TranslateService, private router: Router, private http: HttpService) { }

  ngOnInit(): void {
    this.items = [
      {label: this.translate.instant('POS_INTEGRATION_STEP'), routerLink: 'POS_INTEGRATION'},
      {label: this.translate.instant('ACCOUNT_INTEGRATION_STEP'), routerLink: 'ACCOUNT_INTEGRATION'},
      {label: this.translate.instant('E_INVOICE_INTEGRATION_STEP'), routerLink: 'E_INVOICE_INTEGRATION_STEP'},
      {label: this.translate.instant('STOCK_IMPORT_STEP'), routerLink: 'STOCK_IMPORT'},
    ];
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
      this.queueDisplay = response['hydra:totalItems'] > 0;
    });
    this.controlImportStatus();
  }
  nextPage(): void {

    this.router.navigate(['initial-setup/select-pos']).then(r => {
    });

  }
  async upload(event, entity: string, fileUpload: FileUpload): Promise<void> {
    this.response = {};

    this.entity = entity;
    this.loading = true;

    for (const file of event.files) {
      const fd = new FormData();
      fd.append('file', file);

      await this.http.post(`/api/data/import/${entity}`, fd).then((response: StockMediaObjectModel) => {
        this.response = response;
      }).catch(error => {
      });
    }

    fileUpload.clear();

    this.loading = false;
    this.queueDisplay = true;
  }
  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'STOCK_IMPORT'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 5000);
    }
  }

  download(entity: string): void {
    window.open(`${environment.apiUrl}/examples/${entity}.xlsx`, '_blank').focus();
  }

}
