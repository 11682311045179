import {Component, OnInit, ViewChild} from '@angular/core';
import {
  InvoiceDeliveryStatus,
  InvoiceModel,
  InvoiceProductionStatus,
  InvoiceStatus,
  InvoiceStockStatus,
  InvoiceType
} from '../../../model/invoice.model';
import {HttpService} from '../../../service/http.service';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {InvoiceReceiveComponent} from '../../invoice/invoice-receive/invoice-receive.component';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../service/utility.service';
import {WorkOrderModel} from '../../../model/work-order.model';
import {CurrencyService} from '../../../service/currency.service';
import {DeliveryActions, InvoiceItemModel} from '../../../model/invoice-item.model';
import {UserModel} from '../../../model/user.model';
import {DataTableSettingService} from '../../../service/data-table-setting.service';
import {ContactModel} from '../../../model/contact.model';
import {LocationModel} from '../../../model/location.model';
import {InventoryModel} from '../../../model/inventory.model';
import {environment} from '../../../../environments/environment';
import { UserPageInformationModel } from 'src/app/model/user-page-information.model';
import { UserPageInformationService } from 'src/app/service/user-page-information.service';

@Component({
  selector: 'app-receive-orders',
  templateUrl: './receive-orders.component.html',
  styleUrls: ['./receive-orders.component.scss'],
  providers: [DialogService, DynamicDialogConfig]
})
export class ReceiveOrdersComponent implements OnInit {


  @ViewChild('createdAt') createdAt;
  @ViewChild('expectedDate') expectedDate;

  loading: boolean;
  workOrdersLoading: boolean;
  total: number;
  items: InvoiceModel[] = [];
  workOrders: WorkOrderModel[] = [];
  currency: string;
  changeStatusItems: MenuItem[] = [];
  detailed: boolean;
  entities = [];
  totalPrice = 0;

  user: UserModel = new UserModel();
  settings = [];

  invoiceType = InvoiceType;
  invoiceStatus = [];
  status = [];
  invoiceDeliveryStatus = InvoiceDeliveryStatus;
  deliveryActions = DeliveryActions;
  suppliers: any[];
  grandTotal: number;
  totals = {
    total: 0,
    tax: 0,
    subtotal: 0
  };

  parameters = {
    code: '',
    'contact.name': '',
    tags: '',
    total: '',
    isReceived: false,
    expectedDate: [],
    createdAt: [],
    status: 'CONFIRMED,VIEWED_SUPPLIER,CONFIRMED_SUPPLIER,WAITING_FOR_APPROVAL',
    contact: '',
    productionStatus: '',
    type: InvoiceType.Purchase,
    location: '',
    page: 0,
    itemsPerPage: 20,
    'currency.code': ''
  };
  confirmedStatus: any [];
  isReceivedStatus: any [];
  stockStatuses = [];
  productionStatuses = [];
  deliveryStatuses = [];
  moreLoading: boolean;
  tableLoading: boolean;
  locations: LocationModel [] = [];
  displayAction: boolean;
  actions: any;
  displayLocations: boolean;
  activeInventory: boolean;
  activeInventoryDisplay: boolean;
  sumTotal = 0;
  userId: number;
  infoTextShowDisplay = false;
  infoTextData: UserPageInformationModel[] = [];

  constructor(private http: HttpService, private dialogService: DialogService, private route: ActivatedRoute,
              private translate: TranslateService, private utilityService: UtilityService, private currencyService: CurrencyService,
              private dataTableSettingService: DataTableSettingService, private userPageInformationService: UserPageInformationService) {
  }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }
    this.entities.push('invoice');
    this.currency = this.currencyService.getCurrency().code;
    setTimeout(() => {
      this.suppliers = [
        {name: this.translate.instant('ALL_SUPPLIERS'), value: ''}
      ];
    }, 500);
    this.loadLocations();
    this.loadSuppliers();
    this.getActiveInventory().then();
    this.confirmedStatus = [
      {name: this.translate.instant('ALL'), value: 'CONFIRMED,VIEWED_SUPPLIER,CONFIRMED_SUPPLIER,WAITING_FOR_APPROVAL,DECLINED'},
      {name: this.translate.instant('WAITING_FOR_APPROVAL'), value: 'WAITING_FOR_APPROVAL'},
      {name: this.translate.instant('CONFIRMED'), value: 'CONFIRMED'},
      {name: this.translate.instant('DECLINED'), value: 'DECLINED'},
      {name: this.translate.instant('VIEWED_SUPPLIER'), value: 'VIEWED_SUPPLIER'},
      {name: this.translate.instant('CONFIRMED_SUPPLIER'), value: 'CONFIRMED_SUPPLIER'}
    ];
    this.isReceivedStatus = [
      {name: this.translate.instant('ALL'), value: ''},
      {name: this.translate.instant('RECEIVE'), value: '0'},
      {name: this.translate.instant('RECEIVED'), value: '1'},
    ];
  }

  load(event: LazyLoadEvent = null, type = null): void {
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    this.route.data.subscribe(data => {
      this.parameters.type = data.type;
    });
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    let parameters = {...this.parameters};

    if (this.parameters.expectedDate) {
      const start = new Date(this.parameters.expectedDate[0]);
      const end = new Date(this.parameters.expectedDate[1]);

      parameters = {
        ...parameters, ...{
          'expectedDate[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'expectedDate[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parameters.expectedDate;
    }
    if (this.parameters.createdAt) {
      const start = new Date(this.parameters.createdAt[0]);
      const end = new Date(this.parameters.createdAt[1]);

      parameters = {
        ...parameters, ...{
          'createdAt[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'createdAt[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };

      delete parameters.expectedDate;
    }

    this.tableLoading = true;
    this.items = [];
    /*
          this.http.get(InvoiceModel.IRI, parameters).subscribe((response => {
            this.grandTotal = 0;
            this.total = response['hydra:totalItems'];
            response['hydra:member'].map(res => {
              this.grandTotal += res.total;
              this.items.push(res);
            });
            this.items.map(((item: InvoiceModel) => {
              this.totalPrice += item.normalizedTotal;
           ));

            this.tableLoading = false;
          }));

          */
    this.http.get(`${InvoiceModel.IRI}/purchase_invoices/list`, parameters).toPromise().then((response) => {
      this.items = response.data;
      this.total = response.total;
      this.sumTotal = response.sum;
      this.tableLoading = false;
    });

  }

  async getActiveInventory(): Promise<any> {
    this.activeInventory = true;
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      if (response === null) {
        this.activeInventory = false;
      }
    });
  }

  loadSuppliers(): void {
    this.http.get(ContactModel.IRI, {type: 'supplier'}).subscribe((response: ContactModel) => {
      response['hydra:member'].map((items: any) => {
        this.suppliers.push(items);
      });
    });
  }

  loadLocations(): void {
    let locationTotal = 0;
    this.http.get(`${LocationModel.IRI}/subscriptionControl`).subscribe(response => {
      response.map((item, i) => {
        locationTotal = locationTotal + 1;
        if (i === 0) {
          this.parameters.location = item.id;
          // this.load();
        }
        this.locations.push(item);

      });
      if (locationTotal > 1) {
        this.displayLocations = true;
      }
    });
  }

  receive(id: number): void {
    const ref = this.dialogService.open(InvoiceReceiveComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('PURCHASE_RECEIVE'),
    });

    ref.onClose.subscribe(() => {
      this.load();
    });
  }

  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;

    this.http.download('/api/data/exports/invoices', parameters)
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'invoices.xlsx');
      });
  }

  loadWorkOrders(id: number): void {
    this.workOrdersLoading = true;
    this.workOrders = [];
    this.http.get(WorkOrderModel.IRI, {'invoice.id': id}).subscribe((response: WorkOrderModel[]) => {
      this.workOrders = response['hydra:member'];
      this.workOrdersLoading = false;
    });
  }

  openChangeStatusPanel(panel, event, id: number): void {
    this.changeStatusItems = [
      // tslint:disable-next-line:max-line-length
      {
        label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED),
        state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PACKAGED}
      },
      // tslint:disable-next-line:max-line-length
      {
        label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED),
        state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_PARTIAL_DELIVERED}
      },
      // tslint:disable-next-line:max-line-length
      {
        label: this.translate.instant(InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED),
        state: {id, status: InvoiceDeliveryStatus.DELIVERY_STATUS_DELIVERED}
      },
    ];

    panel.toggle(event);
  }

  changeStatus(id: number, status: string): any {
    this.loading = true;
    this.http.put(`${InvoiceModel.IRI}/${id}`, {deliveryStatus: status}).then(response => {
      this.load();
    });
  }

  closeCalendar(field: string): void {
    let isClose = true;

    if (field === 'expectedDate') {
      this.parameters.expectedDate.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    } else {
      this.parameters.createdAt.map(item => {
        if (!item) {
          isClose = false;
        }
      });
    }

    if (isClose) {
      this.createdAt.overlayVisible = false;
      this.createdAt.datepickerClick = true;
      this.expectedDate.overlayVisible = false;
      this.expectedDate.datepickerClick = true;
    }
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      this.parameters.type,
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }

  onReorder(event): void {
    this.http.post('/api/reorder', {drag: this.items[event.dragIndex].id, drop: this.items[event.dropIndex].id});
  }

  onSelectContact(event): void {
    this.parameters.contact = event.value;
  }

  goToPage(link): void {

    if (this.checkActionPermission('RECEIVE_ORDERS_AVAILABLE')) {
     window.location.href = link;
    } else {
      this.displayAction = true;
    }
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].orders.findIndex(obj => obj.name === text);
      return this.actions[0].orders[findIndex].value ? this.actions[0].orders[findIndex].value : false;
    }
  }

  async changeLocation(id): Promise<void> {
    this.parameters.location = id;

    this.parameters.page = 0;

    this.load();
    this.displayLocations = false;
  }

  inventoryAlert(): void {
    this.activeInventoryDisplay = true;
  }
  getUserInformation(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryComponent'});
    this.userPageInformationService.getItems().subscribe(response => {
      if (response !== null) {
        this.infoTextData = response;
        this.infoTextShowDisplay = this.infoTextData[0].isActive;
      } else {
        this.infoTextShowDisplay = true;
      }
    });
  }
  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'InventoryComponent';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryComponent'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }
}

