import { Component, OnInit } from '@angular/core';
import {InvoiceModel, InvoiceType} from '../../../model/invoice.model';
import {InvoiceItemModel} from '../../../model/invoice-item.model';
import {HttpService} from '../../../service/http.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {LocationModel} from '../../../model/location.model';

@Component({
  selector: 'app-quick-invoice-create',
  templateUrl: './quick-invoice-create.component.html',
  styleUrls: ['./quick-invoice-create.component.scss']
})
export class QuickInvoiceCreateComponent implements OnInit {

  invoice: InvoiceModel = new InvoiceModel();
  stock: StockVariantModel = new StockVariantModel();
  locations: LocationModel[] = [];
  loading: boolean;

  submitted = false;

  constructor(private http: HttpService, private config: DynamicDialogConfig, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(`${InvoiceModel.IRI}/invoice-number`).subscribe((response: InvoiceModel) => {
      this.invoice.series = response.series;
      this.invoice.number = response.number;
      this.invoice.code = response.code;
      this.invoice.currency = response.currency['@id'];
      this.invoice.expectedDate = new Date(response.expectedDate);
      this.invoice.type = InvoiceType.Purchase;
      this.loading = false;
    });
    this.invoice.type = 'purchase';
    const item = new InvoiceItemModel();

    this.loadLocations();

    this.http.get(`${StockVariantModel.IRI}/${this.config.data.id}`).subscribe((response: StockVariantModel) => {
      this.stock = response;
      this.invoice.contact = response.stock.supplier ? response.stock.supplier['@id'] : null;
      item.variant = response['@id'];
      item.stock = response.stock['@id'];
      item.unitPrice = response.price;

      this.invoice.items.push(item);
    });
  }

  loadLocations(): void {
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response : LocationModel[]) => {
      this.locations = response;
      if (this.locations.length > 0) {
        this.invoice.location = this.locations[0]['@id'];
      }
    });
  }

  async save(): Promise<void> {
    this.submitted = true;
    await this.http.post(InvoiceModel.IRI, this.invoice).then((response: InvoiceModel) => {
      this.ref.close({saved: true});
    }).catch(error => {
    });

    this.submitted = false;
  }
}
