<form (ngSubmit)="save()">
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
    <div class="p-fluid">
      <div class="p-field">
        <label for="code">{{'CODE' | translate}}</label>
        <input id="code" type="text" pInputText [(ngModel)]="invoice.code" name="code">
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="expectedDate">{{'EXPECTED_DATE' | translate}}</label>
        <p-calendar appendTo="body" id="expectedDate" [(ngModel)]="invoice.expectedDate" name="expectedDate"></p-calendar>
      </div>
      <div class="p-field p-col">
        <label for="location">{{'LOCATION' | translate}}</label>
        <p-dropdown appendTo="body" id="location" [options]="locations" [(ngModel)]="invoice.location" optionLabel="name"  optionValue="@id" name="location"></p-dropdown>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid" *ngIf="invoice.items.length > 0">
      <div class="p-field p-col">
        <label for="quantity">{{'QUANTITY' | translate}}</label>
        <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" id="quantity" [(ngModel)]="invoice.items[0].quantity" name="quantity" autofocus></p-inputNumber>
      </div>
      <div class="p-field p-col">
        <label for="missingStock">{{'MISSING_STOCK' | translate}}</label>
        <input id="missingStock" type="text" pInputText [(ngModel)]="stock.missingAmount" name="missingStock" disabled>
      </div>
    </div>
    <button pButton label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"></button>
  </div>
</form>


