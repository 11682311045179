<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading" id="pdf-content">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PRODUCTION_REPORTS' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'daily-production'"></app-page-info>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid" id="button">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="categories" appendTo="body" (onChange)="onSelectCategory($event)"
        [ngModel]="parameters.category"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_CATEGORY'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>

  <app-production-report-tab pageName="department-production-report"></app-production-report-tab>
  <div class="p-grid">
    <div class="p-col-12 div-sums" style="padding: 0px !important">
      <div>
        <div class="inside-div-no-background" style="overflow: auto;max-height: 600px; padding: 3px !important">
          <p-table [value]="saleDates" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th [style]="{width: '6%'}" pSortableColumn="location">
                  <p-sortIcon field="location"></p-sortIcon>{{'LOCATION' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="name">
                  <p-sortIcon field="name"></p-sortIcon>{{'PRODUCT_NAME' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="quantity">
                  <p-sortIcon field="quantity"></p-sortIcon>{{'PRODUCTION_QUANTITY' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="unitCost">
                  <p-sortIcon field="unitCost"></p-sortIcon>{{'UNIT_COST' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="cost">
                  <p-sortIcon field="cost"></p-sortIcon>{{'TOTAL_COST' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="unitPrice">
                  <p-sortIcon field="unitPrice"></p-sortIcon>{{'UNIT_SALE_PRICE' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="salePrice">
                  <p-sortIcon field="salePrice"></p-sortIcon>{{'TOTAL_SALE' | translate}}</th>
                <th [style]="{width: '5%'}" pSortableColumn="costRate">
                  <p-sortIcon field="costRate"></p-sortIcon>{{'COST_PERCENTAGE' | translate}}</th>
                <th [style]="{width: '7%'}" pSortableColumn="extraCost">
                  <p-sortIcon field="extraCost"></p-sortIcon>{{'ADDITIONAL_PRODUCTION_COST' | translate}}</th>
                <th [style]="{width: '8%'}" pSortableColumn="totalCost">
                  <p-sortIcon field="totalCost"></p-sortIcon>{{'DRESSED_COST' | translate}}</th>
                <th [style]="{width: '6%'}" pSortableColumn="totalCostRate">
                  <p-sortIcon field="totalCostRate"></p-sortIcon>{{'COST_PERCENTAGE' | translate}}</th>
                <th [style]="{width: '7%'}" pSortableColumn="profit">
                  <p-sortIcon field="profit"></p-sortIcon>{{'PROFIT_IN_LOCAL_CURRENCY' | translate}}</th>
                <th [style]="{width: '7%'}" pSortableColumn="profitRate">
                  <p-sortIcon field="profitRate"></p-sortIcon>{{'PROFIT_MARGIN' | translate}}</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dates>
              <tr>
                <td>{{dates.location}}</td>
                <td>{{dates.name}}</td>
                <td [style]="{textAlign: 'right'}">{{dates.quantity | number:'1.2-2' }}</td>
                <td [style]="{textAlign: 'right'}">{{dates.unitCost| number:'1.2-2' }}{{info.currency}}</td>
                <td
                  [style]="{textAlign: 'right'}">{{dates.cost| number:'1.2-2' }}{{info.currency}}</td>
                <td
                  [style]="{textAlign: 'right'}">{{dates.unitPrice| number:'1.2-2' }}{{info.currency}}</td>
                <td
                  [style]="{textAlign: 'right'}">{{dates.salePrice| number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}"
                    [ngStyle]="{'color': dates.costRate > 100 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{dates.costRate | number:'1.2-2' }}
                  %
                </td>
                <td
                  [style]="{textAlign: 'right'}">{{dates.extraCost| number:'1.2-2' }}{{info.currency}}</td>
                <td
                  [style]="{textAlign: 'right'}">{{dates.totalCost| number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}"
                    [ngStyle]="{'color': dates.totalCostRate > 100 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{dates.totalCostRate | number:'1.2-2'}}
                  %
                </td>
                <td
                  [ngStyle]="{'color': dates.profit < 0 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{dates.profit | number:'1.2-2' }}{{info.currency}}</td>
                <td
                  [ngStyle]="{'color': dates.profitRate < 0 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{dates.profitRate | number:'1.2-2'}}
                  %
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td colspan="2">Total:</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('quantity') | number:'1.2-2' }}</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('cost') / getTotal('quantity') | number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('cost') | number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('unitPrice') | number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('salePrice') | number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{ (getTotal('cost') / getTotal('salePrice'))*100 | number:'1.2-2' }}%</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('extraCost') | number:'1.2-2' }}{{info.currency}}</td>
                <td [style]="{textAlign: 'right'}">{{ getTotal('totalCost') | number:'1.2-2' }}{{info.currency}}</td>
                <td [ngStyle]="{'color': (getTotal('totalCost') / getTotal('salePrice')) *100 > 100 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{ (getTotal('totalCost') / getTotal('salePrice')) *100 | number:'1.2-2' }}%</td>
                <td [ngStyle]="{'color': getTotal('profit') < 0 ? '#ff0300' : 'black', 'textAlign': 'right'}">{{ getTotal('profit') | number:'1.2-2' }}{{info.currency}}</td>
                <td [ngStyle]="{'color': ((getTotal('profit') / getTotal('salePrice')) *100 < 0) ? '#ff0300' : 'black', 'textAlign': 'right'}">{{ (getTotal('profit') / getTotal('salePrice')) * 100 | number:'1.2-2' }}%</td>
              </tr>
            </ng-template>

          </p-table>
        </div>
      </div>
    </div>
  </div>
</p-card>

