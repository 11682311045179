import { Component, OnInit } from '@angular/core';
import {LocationModel} from "../../../../model/location.model";
import {HttpService} from "../../../../service/http.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {LocationService} from "../../../../service/location.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

@Component({
  selector: 'app-unpaid-production-report',
  templateUrl: './unpaid-production-report.component.html',
  styleUrls: ['./unpaid-production-report.component.scss']
})
export class UnpaidProductionReportComponent implements OnInit {
  events: any[] = [];
  options: any;
  dataIncoming: any;
  info: any;
  sale: any;
  saleDates: any;
  categories: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  basicData: any;
  basicOptions: any;
  totalProdCost = 0;
  totalProdInc = 0;
  totalCost = 0;
  location: LocationModel[] = [];
  dateRange: Date[] = [];
  parameters = {
    date: [],
    startDate: '',
    lastDate: '',
    location: ''
  };

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.saleDates = [{
      date: '08/04/2023',
      invoice_number: 'PO-345',
      customer: 'Sütiş Emirgan',
      name: 'Sütlaç',
      quantity: 500,
      location: 'Sütiş Emirgan',
      invoice_date: '04.08.2023 - 14:32',
      confirming: 'Ramazan',
      price: 75000,
      detail: 'Bayat Ürün'
    }, {
      date: '08/04/2023',
      invoice_number: 'PO-346',
      customer: 'Sütiş Beşiktaş',
      name: 'Kazandibi',
      quantity: 500,
      location: 'Sütiş Beşiktaş',
      invoice_date: '04.08.2023 - 15:42',
      confirming: 'Nurullah',
      price: 75000,
      detail: 'Hatalı Gönderim'
    }, {
      date: '08/04/2023',
      invoice_number: 'PO-347',
      customer: 'Sütiş Akaretler',
      name: 'Kadayıf',
      quantity: 500,
      location: 'Sütiş Akaretler',
      invoice_date: '04.08.2023 - 16:12',
      confirming: 'Çağlar',
      price: 75000,
      detail: 'Bayat Ürün'
    }, {
      date: '08/04/2023',
      invoice_number: 'PO-348',
      customer: 'Sütiş İzmir',
      name: 'Mercimek Çorba',
      quantity: 500,
      location: 'Sütiş İzmir',
      invoice_date: '04.08.2023 - 17:15',
      confirming: 'Efe',
      price: 75000,
      detail: 'Bayat Ürün'
    }];
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });
    this.loading = false;
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    this.parameters.startDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    this.parameters.lastDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
    this.parameters = {...this.parameters};
  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`daily_report.pdf`); // PDF'i indir
    });
  }
  getTotalPrice(): number {
    return this.saleDates.reduce((acc, item) => acc + item.price, 0);
  }
}
