import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../service/http.service';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {StockModel} from '../../model/stock.model';
import {StockVariantOptionModel} from '../../model/stock-variant-option.model';
import {StockVariantModel} from '../../model/stock-variant.model';
import {DialogService} from 'primeng/dynamicdialog';
import {QuickInvoiceCreateComponent} from '../invoice/quick-invoice-create/quick-invoice-create.component';
import {StockTransactionsComponent} from './stock-transactions/stock-transactions.component';
import { TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../service/utility.service';
import {QuickWorkOrderCreateComponent} from '../work-order/quick-work-order-create/quick-work-order-create.component';
import {CurrencyModel} from '../../model/currency.model';
import {CurrencyService} from '../../service/currency.service';
import {CompanyIntegrationModel} from '../../model/company-integration.model';
import {UserModel} from '../../model/user.model';
import {DataTableSettingService} from '../../service/data-table-setting.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
  providers: [DialogService]
})
export class StockComponent implements OnInit {
  items: StockVariantModel[] = [];
  item: StockModel = new StockModel();
  variantOption: StockVariantOptionModel = new StockVariantOptionModel();
  loading: boolean;
  total: number;
  currency: CurrencyModel = new CurrencyModel();
  companyIntegration: CompanyIntegrationModel[] = [];
  entities = [];
  user: UserModel = new UserModel();
  settings = [];

  parameters = {
    id: '',
    name: '',
    code: '',
    'stock.category.name': '',
    'stock.tax.name': '',
    'stock.supplier.name': '',
    averageCost: '',
    valueInStock: '',
    expectedAmount: '',
    committedAmount: '',
    missingAmount: '',
    alertLevel: '',
    minOrder: '',
    comment: '',
    'stock.type': 'product',
    page: 1,
    itemsPerPage: 20,

  };

  constructor(private http: HttpService, private confirmationService: ConfirmationService, private dialog: DialogService,
              private translate: TranslateService, private utilityService: UtilityService, private currencyService: CurrencyService,
              private dataTableSettingService: DataTableSettingService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.settings = this.user.dataTableSettings;
    }

    this.entities.push('stock');
    this.currency = this.currencyService.getCurrency();

    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.companyIntegration = response['hydra:member'];
    });
  }

  load(event: LazyLoadEvent = null): void{
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.parameters['stock.isArchive'] = false;
    this.loading = true;
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe((response: StockVariantModel[]) => {
      this.items = response['hydra:member'];
      // @ts-ignore
      this.total = response['hydra:totalItems'];
      this.loading = false;
    });
  }

  delete(event, row): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translate.instant('SUCCESS_DELETED'),
      header: this.translate.instant('ALERT'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translate.instant('YES'),
      rejectLabel: this.translate.instant('NO'),
      accept: () => {
        this.http.delete(`${StockModel.IRI}/${row.id}`).then(() => {
          this.items = this.items.filter(val => val.id !== row.id);
        });
      }
    });
  }

  buy(id: number): void {
    const ref = this.dialog.open(QuickInvoiceCreateComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('CREATE_PURCHASE'),
    });

    ref.onClose.subscribe(data => {
      if (data !== undefined && data.saved !== undefined && data.saved) {
        this.load();
      }
    });
  }

  make(id: number, quantity: number): void {
    const ref = this.dialog.open(QuickWorkOrderCreateComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('WORK_ORDER_CREATE'),
    });

    ref.onClose.subscribe(data => {
      if (data !== undefined && data.saved !== undefined && data.saved) {
        this.load();
      }
    });
  }

  showTransactions(id: number, status: string): void {
    this.dialog.open(StockTransactionsComponent, {
      width: '50vw',
      header: this.translate.instant('STOCK_TRANSACTIONS'),
      data: {variantId: id, status, isActive: 1}
    });
  }

  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;
    delete parameters.id;

    this.http.download('/api/data/exports/stocks', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'stocks.xlsx'); });
  }

  onResize(event): void {
    this.dataTableSettingService.save(
      'stocks',
      event.element.cellIndex,
      event.element.clientWidth,
      `${UserModel.IRI}/${this.user.id}`
    );
  }
}
