import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {InvoiceModel, InvoiceType} from '../../../model/invoice.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ShipmentModel} from '../../../model/shipment.model';
import {ShipmentItemModel} from '../../../model/shipment-item.model';
import {StockQuantityBatchModel} from "../../../model/stock-quantity-batch.model";
import {randomInt} from 'crypto';

@Component({
  selector: 'app-invoice-receive',
  templateUrl: './invoice-receive.component.html',
  styleUrls: ['./invoice-receive.component.scss']
})
export class InvoiceReceiveComponent implements OnInit {

  invoice: InvoiceModel = new InvoiceModel();
  shipment: ShipmentModel = new ShipmentModel();
  submitted: boolean;
  loading: boolean;
  trackByLots: boolean[] = [];
  trackByLot: boolean;
  invoiceType = InvoiceType;
  batches: any[] = [];

  constructor(private http: HttpService, private config: DynamicDialogConfig, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.loadInvoice();
  }

  loadInvoice(): void {
    this.loading = true;
    this.http.get(`${InvoiceModel.IRI}/${this.config.data.id}`).subscribe((response: InvoiceModel) => {
      this.invoice = response;

      if (this.invoice.isReceived) {
          this.ref.close();
      }

      this.shipment.invoice = this.invoice['@id'];
      this.invoice.items.map(item => {
        const shipmentItem = new ShipmentItemModel();
        shipmentItem.invoiceItem = item['@id'];
        shipmentItem.quantity = 0;

        this.shipment.items.push(shipmentItem);
        // @ts-ignore
        if (item.stock.trackType === 'LOT') {
          // @ts-ignore
          this.http.get(StockQuantityBatchModel.IRI, {'variant.id': item.variant.id, isActive: true}).subscribe(batches => {
            this.batches[item.variant['@id']] = batches['hydra:member'];
          });
          this.trackByLots.push(true);
          this.trackByLot = true;
        } else {
          this.trackByLots.push(false);
        }
      });

      this.loading = false;
    });
  }

  async save(): Promise<void> {
    this.submitted = true;
    this.shipment.documentType = 'DELIVERY_NOTE';
    this.shipment.documentDate = new Date();
    this.shipment.documentNumber = 'AAA' + Math.floor(Math.random() * 99999) + 1;

    await this.http.post(ShipmentModel.IRI, this.shipment).then((response: ShipmentModel) => {
      this.ref.close();
    }).catch(error => {
    });
    this.submitted = false;
  }

  onBatchSelect(event, variant, i): void {
    this.batches[variant].map(item => {
      if (item['@id'] === event.value) {
        if (this.invoice.items[i].remainingQuantity > item.amount) {
          this.shipment.items[i].quantity = item.amount;
        } else {
          this.shipment.items[i].quantity = this.invoice.items[i].remainingQuantity;
        }
      }
    });
  }
}
