import {Component, OnInit} from '@angular/core';
import {InventoryModel} from '../../../model/inventory.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InventoryItemModel, inventoryMethod} from '../../../model/inventory-item.model';
import {LazyLoadEvent} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {UserModel} from '../../../model/user.model';
import {TranslateService} from '@ngx-translate/core';
import {QueueModel} from '../../../model/queue.model';

@Component({
  selector: 'app-inventory-location',
  templateUrl: './inventory-location.component.html',
  styleUrls: ['./inventory-location.component.scss']
})
export class InventoryLocationComponent implements OnInit {
  loading: boolean;
  inventory: InventoryModel = new InventoryModel();
  errors = [];
  id: any;
  total: number;
  items: InventoryItemModel[] = [];
  selectedItems: InventoryItemModel[] = [];
  selectedLocation = [];
  info: string;
  user: UserModel[] = [];
  inventoryMethod: inventoryMethod;
  inventoryMethods: [{ name: any; value: inventoryMethod }, { name: any; value: inventoryMethod }];
  parameters = {
    inventoryMethod: [
      inventoryMethod.SD,
      inventoryMethod.STA
    ].join(','),
    page: 1,
    itemsPerPage: 20,
  };

  display = false;
  unlockDisplay = false;
  selectStatus = true;
  canUnlock = false;
  queueDisplay = false;
  displayAction: boolean;
  actions: any;

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    const userJson = localStorage.getItem('user');
    this.user = JSON.parse(userJson);
    this.id = this.route.snapshot.paramMap.get('id');
    // this.load();
    this.setInventoryMethods();
    this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'CLOSE_INVENTORY'}).subscribe(response => {

      this.queueDisplay = response['hydra:totalItems'] > 0;
      this.controlImportStatus();
    });
  }

  async load(event: LazyLoadEvent = null): Promise<void> {
    this.selectedItems = [];
    this.selectedLocation = [];
    this.display = false;
    await this.http.get(`${InventoryModel.IRI}/${this.id}`).subscribe((response: InventoryModel) => {
      this.inventory = response;
      this.loading = false;
    });

  }

  selectAll(event): void {

  }

  onRowSelect(event): void {
    this.selectedLocation.push(event.data.location.name);
    // this.selectedItems.push(event.data.location.id);
    const index = this.selectedItems.indexOf(event.data.id);
    const value = this.selectedItems.indexOf(event.data);
    if (index !== -1) {
      this.selectedItems.splice(value, 1);
    }
  }

  onRowUnselect(event): void {
    const index = this.selectedItems.indexOf(event.data.location.id);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
    const location = this.selectedLocation.indexOf(event.data.location.name);
    if (location !== -1) {
      this.selectedLocation.splice(location, 1);
    }
  }

  confirm(type): void {
    if ( this.checkActionPermission('CAN_CLOSE_INVENTORY')) {
    if (this.selectedItems.length === 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('YOU_HAVE_NOT_SELECTED_ANY_LOCATION'),
        acceptVisible: false,
        rejectVisible: false,
        accept: () => {
        }
      });
    } else {
      if (type === 'DELETE_INVENTORY') {
        this.confirmationService.confirm({
          message: this.translate.instant('THIS_OPERATION_CANNOT_BE_REVERSED_ARE_YOU_SURE'),
          accept: () => {
            this.selectedItems.map((item) => {
              this.http.delete(`${InventoryItemModel.IRI}/` + item).then((response: InventoryItemModel) => {
                this.load();
              });
            });
          }
        });
      }
      if (type === 'LOCKED_INVENTORY') {
        this.confirmationService.confirm({
          message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_LOCK_THE_LOCATION'),
          accept: () => {
            this.loading = true;
            this.http.put(`${InventoryItemModel.IRI}/change_status`,
              {
                inventoryId: this.inventory.id,
                data: this.selectedItems,
                status: 'LOCKED_INVENTORY'
              }
            ).then((response) => {
              this.load();
            });
          }
        });
      }
      if (type === 'CLOSED_INVENTORY') {
        this.confirmationService.confirm({
          message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THE_LOCATION'),
          accept: () => {
            this.loading = true;
            const items = [];
            this.selectedItems.map(item => {
              items.push(item.id);
            });
            this.http.put(`${InventoryItemModel.IRI}/change_status`, {
                inventoryId: this.inventory.id,
                data: items,
                status: 'CLOSED_INVENTORY'
              }
            ).then((response) => {
              if (response) {
                this.load();
              }
            });
          }
        });
      }
      if (type === 'UPDATE_INVENTORY') {
        this.confirmationService.confirm({
          message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_UPDATE_THE_LOCATION'),
          accept: () => {
          }
        });
      }
      if (type === 'UNLOCK_INVENTORY') {
        this.confirmationService.confirm({
          message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_THE_LOCATION'),
          accept: () => {
            this.loading = true;
            this.http.put(`${InventoryItemModel.IRI}/change_status`,
              {
                inventoryId: this.inventory.id,
                data: this.selectedItems,
                status: 'UNLOCK_INVENTORY'
              }
            ).then((response) => {
              if (response) {
                this.load();
              }
            });
          }
        });
      }
    }
    }else {
      this.displayAction = true;
    }
  }

  showDialog(): void {
    if ( this.checkActionPermission('CAN_CLOSE_INVENTORY')) {
    this.display = true;
    }else {
      this.displayAction = true;
    }
  }

  showLockedBy(event): void {
    if (this.user['id'] === event.id) {
      this.canUnlock = true;
    }
    this.info = 'Bu Depo ' + event.firstName + '' + event.lastName + ' Tarafından Kilitlenmiştir?';
    this.unlockDisplay = true;
  }

  setInventoryMethods(): void {
    // @ts-ignore
    this.inventoryMethods = [
      {name: this.translate.instant(inventoryMethod.SD), value: inventoryMethod.SD},
      {name: this.translate.instant(inventoryMethod.STA), value: inventoryMethod.STA}
    ];
  }

  changeMethod(event, id): void {
    this.http.put(`${InventoryItemModel.IRI}/change_status`,
      {id: +id, method: event.value}
    ).then((response) => {
    });
  }
  controlImportStatus(): void {
    if (this.queueDisplay === true) {
      setInterval(() => {
        this.http.get(QueueModel.IRI, {status: 'STARTED', name: 'CLOSE_INVENTORY'}).subscribe(response => {
          this.queueDisplay = response['hydra:totalItems'] > 0;
        });
      }, 30000);
    }
  }
  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].inventory.findIndex(obj => obj.name === text);
      return this.actions[0].inventory[findIndex].value ? this.actions[0].inventory[findIndex].value : false;
    }
  }

}
