<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'WASTE_EVENTS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'WASTE_EVENTS'"></app-page-info>
      <p-button icon="pi pi-file-excel" (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" (onClick)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button (onClick)="onShowDialog()" label="{{'NEW_WASTE_EVENTS'| translate}}"
                [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="locations" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="descriptionTypes" [(ngModel)]="wasteEvent.description" (onChange)="onSelectWaste($event)"
        placeholder="{{ 'WASTE_EVENT_STOCK_TYPE' | translate}}" appendTo="body"
        optionLabel="name" optionValue="value">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-1">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <div class="p-grid p-mt-4" *ngIf="!isCategory" id="pdf-content">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="wasteEvents" [tableStyle]="{'min-width': '50rem'}" [rowsPerPageOptions]="[25,50]"
               [rows]="25" [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
               styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '40%'}" pSortableColumn="number">
              <p-sortIcon field="number"></p-sortIcon>
              {{'CODE' |translate}}</th>
            <th scope="col" [style]="{width: '10%'}" pSortableColumn="date">
              <p-sortIcon field="date"></p-sortIcon>
              {{'DATE' |translate}}</th>

            <th scope="col" pSortableColumn="location.name" [style]="{width: '10%'}">
              <p-sortIcon field="location.name"></p-sortIcon>
              {{'LOCATION' |translate}}</th>
            <th scope="col" pSortableColumn="description" [style]="{width: '20%'}">
              <p-sortIcon field="description"></p-sortIcon>
              {{'DESCRIPTION' |translate}}</th>
            <th scope="col" pSortableColumn="createdBy.firstName" [style]="{width: '10%'}">
              <p-sortIcon field="createdBy.firstName"></p-sortIcon>
              {{'CREATED_USER' |translate}}</th>
            <th scope="col" pSortableColumn="total" [style]="{width: '10%'}">
              <p-sortIcon field="total"></p-sortIcon>
              {{'TOTAL' |translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr >
            <td><b (click)="edit(item.id)" style="color: #00b3d1">{{item.series}} - {{item.number}} </b></td>
            <td>{{item.date | date: 'dd-MM-YYYY'}}</td>
            <td>{{item.location.name}}</td>
            <td>{{item.description | translate}}</td>
            <td>{{item.createdBy.firstName}} {{item.createdBy.lastName}}</td>
            <td><span [style]="{float: 'right'}">{{item.total | currency : item.location?.currency.symbol}}</span></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="3" style="text-align: right; font-weight: bold;">{{ 'TOTAL' | translate }}</td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"></td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"></td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"><span
              *ngIf="wasteEvents.length>0">{{wasteEvents[0].location?.currency.symbol}}</span>{{ totalValue | number: '1.1-2'}}
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
  <div class="p-grid" *ngIf="isCategory" id="pdf-content-item">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="wasteEventsByCategory" [tableStyle]="{'min-width': '50rem'}" [rowsPerPageOptions]="[25,50]"
               [rows]="25" [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
               styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '9%'}" pSortableColumn="date">
              <p-sortIcon field="date"></p-sortIcon>
              {{'DATE' |translate}}</th>
            <th scope="col" [style]="{width: '9%'}" pSortableColumn="number">
              <p-sortIcon field="number"></p-sortIcon>
              {{'CODE' |translate}}</th>
            <th scope="col" [style]="{width: '37%'}" pSortableColumn="stock.name">
              <p-sortIcon field="stock.name"></p-sortIcon>
              {{'STOCK' |translate}}</th>
            <th scope="col" [style]="{width: '9%'}" pSortableColumn="location.name">
              <p-sortIcon field="location.name"></p-sortIcon>
              {{'LOCATION' |translate}}</th>
            <th scope="col" [style]="{width: '18%'}" pSortableColumn="description">
              <p-sortIcon field="description"></p-sortIcon>
              {{'DESCRIPTION' |translate}}</th>
            <th scope="col" [style]="{width: '9%'}" pSortableColumn="createdBy.firstName">
              <p-sortIcon field="createdBy.firstName"></p-sortIcon>
              {{'CREATED_USER' |translate}}</th>
            <th scope="col" [style]="{width: '9%'}" pSortableColumn="value">
              <p-sortIcon field="value"></p-sortIcon>
              {{'TOTAL' |translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td
              *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''">{{item.date | date: 'dd-MM-YYYY'}}</td>
            <td
              *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''">{{item.series}}
              - {{item.number}}</td>
            <td
              *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''">{{item.stock.name}}</td>
            <td
              *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''">{{item.location.name}}</td>
            <td
              *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''">{{item.description | translate}}</td>
            <td
              *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''">{{item.createdBy.firstName}} {{item.createdBy.lastName}}</td>
            <td *ngIf="item.stock.category.parent.id == parameters.category || parameters.category == ''"><span
              [style]="{float: 'right'}">{{item.value | currency : item.location?.currency.symbol}}</span></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="3" style="text-align: right; font-weight: bold;">{{ 'TOTAL' | translate }}</td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"></td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"></td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"></td>
            <td [style]="{textAlign: 'center', fontSize: '14px'}"><span
              *ngIf="wasteEventsByCategory.length>0">{{wasteEventsByCategory[0].location?.currency.symbol}}</span>{{ totalValue | number: '1.1-2'}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-sidebar position="right" [(visible)]="display" [style]="{width: '70vw'}" (close)="onHideDialog()">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{'NEW_WASTE_EVENTS'| translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'NEW_WASTE_EVENTS'"></app-page-info>
      <p-button *ngIf="!draftDisplay" [disabled]="!isCanBeSend"
                [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"
                label="{{'SAVE' | translate}}" (onClick)="save()"
                [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>

  <div class="p-grid p-mb-4"
       [style]="{background:'#f6f6f6',marginLeft:'-23px',marginRight:'-9px',padding:'1px 0 1px 20px'}">
    <div class="p-col-6">
      <div class="p-grid">
        <div class="p-col-12">
          <h4><b>{{'WASTE_ITEM_DEFINITION'| translate}}</b></h4>
        </div>
        <div class="p-col-12">
          <label for="transferDate">{{ 'LOCATION'|translate}}</label>
          <p-dropdown appendTo="body"
                      [options]="locations" placeholder="{{'SELECT_LOCATION'| translate}}"
                      [(ngModel)]="wasteEvent.location"
                      optionLabel="name" optionValue="@id" (onChange)="onChangeLocation($event)"
                      [disabled]="wasteEvent.id"
                      [filter]="true" filterBy="name">
          </p-dropdown>
          <small class="p-error">{{ errors['location'] }}</small>
        </div>
        <div class="p-col-12">
          <label for="transferDate">{{ 'WASTE_EVENT_DESCRIPTION'|translate}}</label>
          <p-dropdown appendTo="body"
                      [options]="descriptionTypes" [(ngModel)]="wasteEvent.description"
                      placeholder="{{ 'DESCRIPTION' | translate}}"
                      optionLabel="name" optionValue="value">
          </p-dropdown>
          <small class="p-error">{{ errors['description'] }}</small>
        </div>
        <div class="p-col-12">
          <label for="transferDate">{{ 'WASTE_EVENT_DATE'|translate}}</label>
          <p-calendar [minDate]="minDate" [maxDate]="maxDate" (onSelect)="wasteEventDateControl($event)" appendTo="body"
                      [(ngModel)]="wasteEvent.date">
          </p-calendar>
          <small class="p-error"></small>
        </div>
      </div>
    </div>
    <div class="p-col-6">
      <div class="p-grid">
        <div class="p-col-12">
          <h4><b>{{'WASTE_ITEM_TOTAL'| translate}}</b></h4>
        </div>
        <div class="p-col-12 div-sums" style="width: 98%;margin-top: 25px;">
          <span class="title">Ürün Sayısı</span>
          <span class="total-price">{{ wasteEvent.WasteEventItem?.length }} <b
            class="currency">Adet</b></span>
        </div>
        <div class="p-col-12 div-sums" style="width: 98%;">
          <span class="title">Toplam</span>
          <span class="total-price">{{ wasteEvent?.total | currency : wasteEvent.symbol}} <b
            class="currency"></b>
      </span>
        </div>
      </div>
    </div>
  </div>
  <p-message *ngIf="!isCanBeSend" [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info"
             text="Seçtiğiniz Tarih Açık Olan Envanter Başlangıç Tarihin {{ minDate | date : 'dd-mm-Y' }}'den Daha Eski Olamaz"
             styleClass="mr-2">
  </p-message>

  <p-message *ngIf="isAddStockAvailable" [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info"
             text="Eklediğiniz Ürünün Stok Miktarı ve Transfer Miktarını  Kontrol Ediniz"
             styleClass="mr-2">
  </p-message>

  <div class="p-grid p-mt-4">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table #table responsiveLayout="stack" [tableStyle]="{'min-width': '45rem'}"
               [loading]="tableLoading" [lazy]="true" [value]="wasteEvent.WasteEventItem"
               [rows]="parameters.itemsPerPage" [showCurrentPageReport]="true" [resizableColumns]="true" dataKey="id"
               styleClass="p-datatable-striped" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '2%'}"></th>
            <th scope="col" [style]="{width: '40%'}" pSortableColumn="name">{{ 'STOCK'| translate }}</th>
            <th scope="col" [style]="{width: '15%'}">{{ 'CURRENT_IN_STOCK_AT_ORIGIN'| translate }}</th>
            <th scope="col" [style]="{width: '15%'}">{{ 'PRICE'| translate }}</th>
            <th scope="col" [style]="{width: '12%'}">{{ 'QUANTITY'| translate }}</th>
            <th scope="col" [style]="{width: '12%'}">{{ 'TOTAL'| translate }}</th>
            <th scope="col" [style]="{width: '5%'}"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex" let-expanded="expanded">
          <tr [style]="{'background' : row.quantity > row.maxWaste ? '#f827275e' :  ''}">
            <td></td>
            <td pEditableColumn [style]="{textAlign:'left'}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete [id]="'autocomplete-' + i" appendTo="body" autofocus="true" [(ngModel)]="autoCompleteValues[i]" [suggestions]="variants"
                                  [style]="{width:'100%'}"
                                  name="stock" (completeMethod)="searchStock($event, i)"
                                  (onSelect)="onVariantSelect($event, i);"
                                  field="name"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ autoCompleteValues[i] ? autoCompleteValues[i].name : '' }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign:'right'}"> {{ row.maxWaste | number: '1.2-2' }} {{ row.unit }}</td>
            <td [style]="{textAlign:'right'}">{{row.price | currency: row.symbol }}</td>
            <td pEditableColumn [style]="{padding: '0px',textAlign:'right'}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input type="number" pInputText name="quantity" min="0" max="{{ row.maxWaste}}"
                         [style]="{ width: '100%'}" [(ngModel)]="row.quantity" (change)="onQuantityChange(row, i)">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ row.quantity | number: '1.2-2' }} {{ row.unit }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign:'right'}">{{  row.symbol ? (row.value | currency: row.symbol) : ''}}</td>
            <td><i class="pi pi-trash" *ngIf="!id" [style]="{color:'#ff2525'}"
                   (click)="removeItem(row.id,row.total, i)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>


        <button pButton *ngIf="wasteEvent.WasteEventItem.length < 20" label="{{'ADD_STOCK' | translate}}" class="p-mb-3 add-button p-mt-3" [icon]="'pi pi-plus'"
                (click)="addItem()" [disabled]="isAddStockAvailable"></button>
        <button pButton label="{{'ADD_FROM_RECIPE' | translate}}" class="p-mb-3 add-button p-mt-3" [icon]="'pi pi-plus'"
                (click)="addRecipeDisplay()" [disabled]="isAddStockAvailable || wasteEvent.location === null "></button>
      <button pButton *ngIf="wasteEvent.WasteEventItem.length < 20" label="{{'SAVE' | translate}}" class="p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              [disabled]="this.submitted || isAddStockAvailable" (click)="save()"></button>
    </div>
  </div>
</p-sidebar>
<p-sidebar position="right" [(visible)]="recipeDisplay" [style]="{width: '60vw'}" (close)="onHideDialog()">
  <div class="p-grid p-mt-3">
    <div class="p-col-6 p-md-6 p-sm-6">
      <label>{{ 'SELECT_RECIPE' | translate}}</label>
      <p-autoComplete appendTo="body" [suggestions]="variants"
                      [style]="{width:'100%'}"
                      name="stock" (completeMethod)="searchRecipe($event, 1)" (onSelect)="onRecipeSelect($event, 1);"
                      field="name"></p-autoComplete>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6 p-flex-column">
      <label for="minmax-buttons">{{ 'QUANTITY' | translate}}</label>
      <br>
      <p-inputNumber [(ngModel)]="addRecipeValue" mode="decimal" (ngModelChange)="willAddRecipeControl()"
                     [showButtons]="true" inputId="minmax-buttons" [min]="1">
      </p-inputNumber>
    </div>
  </div>

  <div class="p-grid" *ngIf="willAddRecipeItems">
    <div class="p-col-12 p-md-12 p-sm-12">
      <p-table [value]="willAddRecipeItems" dataKey="id" styleClass="p-datatable-sm" [loading]="submitted">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '40%'}">{{ 'STOCK'| translate }}</th>
            <th scope="col" [style]="{width: '15%'}">{{ 'CURRENT_IN_STOCK_AT_ORIGIN'| translate }}</th>
            <th scope="col" [style]="{width: '15%'}">{{ 'PRICE'| translate }}</th>
            <th scope="col" [style]="{width: '12%'}">{{ 'QUANTITY'| translate }}</th>
            <th scope="col" [style]="{width: '12%'}">{{ 'TOTAL'| translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr [style]="item.amount - item.quantity < 0 ? 'background : #ff9393' : '' ">
            <td>{{ item.stock.name}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.amount | number: '1.2-3'}} {{ item.stock.unit.name}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.price | number: '1.2-3'}} {{ item.stock.currency.symbol}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.quantity | number: '1.2-3'}} {{ item.stock.unit.name}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.total | number: '1.2-3'}} {{ item.stock.currency.symbol}}</td>
          </tr>
        </ng-template>
      </p-table>
      <button pButton label="{{'ADD' | translate}}" class="p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              [disabled]="this.submitted || isAddStockAvailable" (click)="addWasteEventtem()"></button>
    </div>
  </div>
</p-sidebar>
