import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';

export class StockRequestItemsModel extends ApiResourceModel{
  stock: StockModel|string;
  stockVariant: StockVariantModel;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  description: string;

}
