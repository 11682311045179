<div class="div-sums p-flex-row mb-3">
  <div class="p-col inside-div-no-background {{ page == 'daily' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/unpaid-report/by-daily'" [style]="{cursor:'pointer'}">
    {{'DAILY' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ page == 'group' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/unpaid-report/by-group'" [style]="{cursor:'pointer'}">
    {{'BY_GROUP' | translate}}
  </div>
  <div class="p-col inside-div-no-background {{ page == 'item' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/unpaid-report/by-item'" [style]="{cursor:'pointer'}">
    {{'BY_PRODUCT' | translate}}
  </div>
  <!--
  <div class="p-col inside-div-no-background {{ page == 'detail' ? 'active-div' :  ''}}"
       [routerLink]="'/reports/sale-reports/unpaid-report/by-detail'" [style]="{cursor:'pointer'}">
    {{'DETAILED' | translate}}
  </div>
  -->
</div>
