import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ContactModel} from '../model/contact.model';
import {HttpService} from './http.service';
import {TranslateService} from '@ngx-translate/core';
import {any} from 'codelyzer/util/function';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  contacts = new BehaviorSubject<ContactModel[]>(null);
  loading = false;
  total = new BehaviorSubject<number>(0);

  constructor(private http: HttpService, private translate: TranslateService) { }

  getContacts(): Observable<ContactModel[]> {
    return this.contacts.asObservable();
  }

  getContactsAction(params = {}): any[] {
   return this.http.get(ContactModel.IRI, params).subscribe(response => {
      this.contacts.next(response['hydra:member']);
      this.total.next(response['hydra:totalItems']);
    });
  }
  getSearchContacts(params = {}): any[] {
    const searchContacts = [];
    this.http.get(ContactModel.IRI, params).subscribe(response => {
      response['hydra:member'].map((item) => {
        searchContacts.push({name: item.name, id: item.id, hideInvoice: item.hideInvoice, email: item.email});
      });
      searchContacts.unshift({ name: this.translate.instant('ALL_SUPPLIERS'), id: null});
    });
    return searchContacts;
  }
  getSuppliers(params = {}): any[] {
    this.loading = true;
    this.getLoading();
    const suppliers = [];
    this.http.get(ContactModel.IRI, params).subscribe(response => {
      response['hydra:member'].map((item) => {
        suppliers.push({name: item.name, id: item['@id']});
      });
      this.loading = false;
      this.getLoading();
    });
    return suppliers;
  }
  getTotal(): any {
    return this.total.asObservable();
  }

  async save(contact: ContactModel): Promise<void> {
    return await this.http.post(ContactModel.IRI, contact).then(response => {
      if (response !== undefined) {
        const contacts = this.contacts.getValue();
        if (contacts && contacts['hydra:member']) {
          contacts['hydra:member'].push(response);
          this.contacts.next(contacts);
        }
      }
      return response;
    });
  }

  async update(contact: ContactModel): Promise<any> {
    return await this.http.put(`${ContactModel.IRI}/${contact.id}`, contact).then(response => {
      return response;
    });
  }
  getLoading(): boolean{
    return this.loading;
  }

  unsubscribe(): void {
    this.contacts.next(null);
    this.contacts.unsubscribe();
  }
}
