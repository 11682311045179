import {Component, Input, OnInit} from '@angular/core';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {HttpService} from '../../../service/http.service';

@Component({
  selector: 'app-planning-list',
  templateUrl: './planning-list.component.html',
  styleUrls: ['./planning-list.component.scss']
})
export class PlanningListComponent implements OnInit {

  items: StockVariantModel[] = [];
  loading: boolean;
  ids: StockVariantModel[] = [];
  quantities: number[] = [];
  list: number[] = [];

  parameters = {
    'missingAmount[lt]': 0,
    'stock.type': 'product',
    itemsPerPage: 100,
  };

  constructor(private http: HttpService) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.loading = true;
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe((response) => {
      this.items = response['hydra:member'];
      this.loading = false;
    });
  }

  add(variant: StockVariantModel, totalQuantity, i: number): void {
    this.list.push(i);

    let isExists = false;

    this.ids.map((stockVariant: StockVariantModel, key: number) => {
      if (stockVariant.id === variant.id) {
        this.quantities[key] += totalQuantity;
        isExists = true;
      }
    });

    if (!isExists) {
      this.quantities.push(totalQuantity);
      this.ids.push(variant);
    }
  }

  remove(i): void {
    this.list.splice(i, 1);
  }

  showTransactions(id: number, type: string): void {

  }
}
