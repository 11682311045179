import { Component, OnInit } from '@angular/core';
import {ContactModel} from '../../../model/contact.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {StockModel} from '../../../model/stock.model';
import {CategoryModel} from '../../../model/category.model';
import {CategoryService} from '../../../service/category.service';
import {LocationModel} from '../../../model/location.model';
import { DatePipe} from '@angular/common';
import {InvoiceModel} from '../../../model/invoice.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {ContactCreateDialogComponent} from '../../contact/contact-create-dialog/contact-create-dialog.component';
import {DialogService} from 'primeng/dynamicdialog';
import { CreateCategoryComponent} from '../../settings/category/create-category/create-category.component';
import {ContactService} from '../../../service/contact.service';
import {StockUpdateComponent} from '../../stock/stock-update/stock-update.component';
import {UnitModel} from '../../../model/unit.model';
import { ConfirmationService} from 'primeng/api';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-invoice-create-basket',
  templateUrl: './invoice-create-basket.component.html',
  styleUrls: ['./invoice-create-basket.component.scss'],
  providers: [DialogService],
})
export class InvoiceCreateBasketComponent implements OnInit {
  id: any;
  stocks: StockModel[] = [];
  noData: boolean;
  loading: boolean;
  submitted: boolean;
  parameters = {
    id: '',
    type: 'material',
    'variants.code': '',
    name: '',
    'supplier.name': '',
    'category.name': '',
    'variants.price': '',
    'supplier.id': null,
    'category.id': null,
    'location.id': null,
    page: 1,
    itemsPerPage: 20,
  };
  invoices = [
    {
      key: 0,
      supplierName: '',
      data: {
        code: '',
        contact: '',
        currency: '',
        discountRate: null,
        expectedDate: null,
        location: '',
        number: 0,
        series: '',
        status: 'CONFIRMED',
        subtotal: 0,
        tax: 0,
        total: 0,
        type: 'PURCHASE',
        description: '',
        items: []
      }
    }
  ];
  invoicesItems = [];
  selectInvoicesItems = [];
  totalRecords: number;
  contacts: ContactModel[] = [];
  searchContacts: ContactModel[] = [];
  categories: CategoryModel[] = [];
  locations: LocationModel[] = [];
  searchLocations: LocationModel[] = [];
  supplierCount: number;
  display: boolean;
  activeInvoice: number;
  quantityValues: [{ key: number, quantity: number}];
  firstValue: any;
  addDialog: boolean;
  stockDialog: boolean;
  displayInvoiceStatus: boolean;
  displaySideBar: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  order: any;
  stockOrderQuantityValues = [{
    key: 0,
    data: {
      stock: '',
      orderQuantity: 0
    }
  }];
  invoiceInfo: boolean;
  invoiceInfoComment: any;
  invoiceRouteId: any;
  actionItems: MenuItem[] = [];

  constructor(private http: HttpService, private route: ActivatedRoute,
              private translate: TranslateService,
              private categoryService: CategoryService,
              private contactService: ContactService,
              private router: Router,
              private datePipe: DatePipe,
              private dialog: DialogService,
              private confirmationService: ConfirmationService,
              private flashMessageService: FlashMessageService) { }

  ngOnInit(): void {
    this.onLoad().then();
    this.activeInvoice = 1;
    if (this.parameters['supplier.id'] === null){
      this.display = true;
    }
    this.firstValue = '';
    if (window.localStorage.getItem('invoices') !== undefined && window.localStorage.getItem('invoices').length > 1){
      this.invoices = JSON.parse(window.localStorage.getItem('invoices'));
      this.invoices.map((invoices, i) => {
        this.invoices[i].data.expectedDate = new Date(invoices.data.expectedDate);
      });
      this.activeInvoice = this.invoices.length - 1;
      this.supplierCount = this.invoices.length - 1;
    }
    this.actionItems = [
      {label: this.translate.instant('ADD_NEW_PRODUCT')},
      {label: this.translate.instant('IMPORT_INVENTORY_ITEMS')},
      {label: this.translate.instant('WEEKLY_ORDERS_SCHEDULES')},
    ];
  }
  async onLoadStock(): Promise<void> {
    this.noData = false;
    this.loading = true;
    // @ts-ignore
    await this.http.get(StockModel.IRI, this.parameters).subscribe((response: StockModel[]) => {
      // @ts-ignore
      if (response['hydra:totalItems'] === 0) {
        this.noData = true;
        this.loading = false;
        this.stocks = [];
      }else{
        this.noData = false;
        this.loading = false;
        this.stocks = response['hydra:member'];
        // @ts-ignore
        this.totalRecords = response['hydra:totalItems'];
        this.setStockValues();
      }
    });

  }
  async onLoad(): Promise<void> {
    this.noData = false;
    this.loading = true;
    await this.contactService.getContactsAction( { type: 'supplier'});
    await this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.contacts = response['hydra:member'];
        this.searchContacts = response['hydra:member'];
        // @ts-ignore
        this.searchContacts.unshift({ name: this.translate.instant('ALL_SUPPLIERS'), id: null});
      }
    });
    await this.categoryService.getCategoriesAction();
    await this.categoryService.getCategories().subscribe(response => {
      if (response !== null) {
      }
      if (response) {
        this.categories = response;
        // @ts-ignore
        this.categories.unshift({ name: this.translate.instant('ALL_CATEGORIES'), id: null});
        this.noData = false;
        this.loading = false;
      }
    });
    await this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination : false}).subscribe(response => {
      if (response !== undefined) {
        this.locations = response;
        this.searchLocations = response;
        // @ts-ignore
        this.searchLocations.unshift({ name: this.translate.instant('ALL_LOCATIONS'), id: null});
      }
    });

  }
  async changeContact(event): Promise<void> {
    const objIndex = this.invoices.findIndex((obj => obj.key === event.value));
    if (objIndex !== -1 ){
      this.activeInvoice = objIndex;
    }
    this.parameters = {...this.parameters, 'supplier.id' : event.value, 'category.id' : '' };
    this.onLoadStock().then();
  }
  async changeCategory(event): Promise<void> {
    this.parameters = {...this.parameters, 'category.id' : event.value , 'supplier.id' : '' };
    this.onLoadStock().then();
  }
  async onChangeQuantity(event: any, row , i , option = null): Promise<void> {
    const status = 'WAITING_FOR_APPROVAL';
    if (event.value === 0){
    }
    this.invoicesItems = [{
      currency: row.currency['@id'] ? row.currency['@id'] : null,
      nonDiscountedUnitPrice: 0,
      quantity: event.value,
      stock: row['@id'],
      name: option === null ? row.variants[0].name : option.name,
      code: option === null ? row.variants[0].code : option.code,
      subtotal: option === null ? row.variants[0].price * event.value * row.variants[0].minOrder : option.price * event.value,
      tax: option === null ?
        (row.variants[0].price * event.value * row.variants[0].minOrder) * ( row.tax.rate / 100)
        : (option.price * event.value) * ( row.tax.rate / 100),
      taxRate: row.tax['@id'],
      unitPrice: option === null ? row.variants[0].price : option.price,
      variant: row.variants[0]['@id'],
      // tslint:disable-next-line:max-line-length
      totalOrder: option === null ? event.value * row.variants[0].minOrder : option.baseUnitQuantity === null ? event.value * option.packQuantity : event.value * option.baseUnitQuantity,
      unit: row.unit.name,
      orderType : option === null ? null : option.orderType,
      purchaseOption : option === null ?  null : option['@id']
    }];
    const data = [{
      key: row['@id'], value: event.value
    }];
    const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
    this.activeInvoice = objInvoiceIndex;
    if (this.invoices.length === 1 || objInvoiceIndex === -1 ) {
      this.loading = true;
      this.invoices.push({
          key: option === null ? row.supplier.id : option.supplier.id,
          supplierName: option === null ? row.supplier.name : option.supplier.name,
          data : {
            contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
            currency: row.currency['@id'],
            discountRate: null,
            expectedDate: new Date(),
            location: this.locations[0]['@id'],
            code: '',
            number: 0,
            series: '',
            status,
            subtotal: 0,
            tax: 0,
            total: 0,
            type: 'PURCHASE',
            description: '',
            items: this.invoicesItems
          }
        });
      this.supplierCount = this.invoices.length - 1;
      this.activeInvoice = this.invoices.length - 1;
      this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
        // SubTotal Calculation
        // tslint:disable-next-line:no-shadowed-variable
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
          sum +=  item.subtotal;
        });
      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * ( row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * ( row.tax.rate / 100));
      this.loading = false;

   }else{
     const objIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
      // tslint:disable-next-line:max-line-length
     const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
     if (objIndexStock !== -1 ){
       this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
     }else{
       this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
     }
     this.supplierCount = this.invoices.length - 1;
     this.activeInvoice = objIndex;
     this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
    // SubTotal Calculation
    // tslint:disable-next-line:no-shadowed-variable
     let sum = 0;
     this.invoices[this.activeInvoice].data.items.map(item => {
      sum +=  item.subtotal;
    });
     this.invoices[this.activeInvoice].data.subtotal = sum;
     this.invoices[this.activeInvoice].data.tax = sum * ( row.tax.rate / 100);
     this.invoices[this.activeInvoice].data.total = sum + (sum * ( row.tax.rate / 100));
     }
    this.setInvoicesLocalStorage();
  }
  async onChangeQuantityTotal(quantity, row, option = null): Promise<void> {
    if (option === null){
      row.variants[0].orderQuantity = quantity;
    }else{
      option.orderQuantity = quantity;
    }
    const status = 'WAITING_FOR_APPROVAL';
    if (quantity === 0){

    }
    this.invoicesItems = [{
      currency: row.currency['@id'] ? row.currency['@id'] : null,
      nonDiscountedUnitPrice: 0,
      quantity,
      stock: row['@id'],
      name: option === null ? row.variants[0].name : option.name,
      code: option === null ? row.variants[0].code : option.code,
      subtotal: option === null ? row.variants[0].price * quantity * row.variants[0].minOrder : option.price * quantity,
      tax: option === null ?
        (row.variants[0].price * quantity * row.variants[0].minOrder) * ( row.tax.rate / 100)
        : (option.price * quantity) * ( row.tax.rate / 100),
      taxRate: row.tax['@id'],
      unitPrice: option === null ? row.variants[0].price : option.price,
      variant: row.variants[0]['@id'],
      // tslint:disable-next-line:max-line-length
      totalOrder: option === null ? quantity * row.variants[0].minOrder : option.baseUnitQuantity === null ? quantity * option.packQuantity : quantity * option.baseUnitQuantity,
      unit: row.unit.name,
      orderType : option === null ? null : option.orderType,
      purchaseOption : option === null ?  null : option['@id']
    }];

    const data = [{
      key: row['@id'], value: quantity
    }];
    const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));

    if (this.invoices.length === 1 || objInvoiceIndex === -1 ) {

      this.loading = true;

      this.invoices.push({
          key: option === null ? row.supplier.id : option.supplier.id,
          supplierName: option === null ? row.supplier.name : option.supplier.name,
          data : {
            code: '',
            contact: option === null ? row.supplier['@id'] : option.supplier['@id'],
            currency: row.currency['@id'],
            discountRate: null,
            expectedDate: new Date(),
            location: this.locations[0]['@id'],
            number: 0,
            series: '',
            status,
            subtotal: 0,
            tax: 0,
            total: 0,
            type: 'PURCHASE',
            description: '',
            items: this.invoicesItems
          }
        });

      this.supplierCount = this.invoices.length - 1;
      this.activeInvoice = this.invoices.length - 1;
        // SubTotal Calculation
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
          sum +=  item.subtotal;
        });

      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * ( row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * ( row.tax.rate / 100));
      this.loading = false;

    }else{
      const objIndex = this.invoices.findIndex((obj => obj.key === (option === null ? row.supplier.id : option.supplier.id)));
      // tslint:disable-next-line:max-line-length
      const objIndexStock = this.invoices[objIndex].data.items.findIndex((obj => obj.stock === (option === null ? row['@id'] : option.stock)));
      if (objIndexStock !== -1 ){
        this.invoices[objIndex].data.items[objIndexStock] = this.invoicesItems[0];
      }else{
        this.invoices[objIndex].data.items.push(this.invoicesItems[0]);
      }
      this.selectInvoicesItems = this.invoices[this.activeInvoice].data.items;
      let sum = 0;
      this.invoices[this.activeInvoice].data.items.map(item => {
        sum +=  item.subtotal;
      });
      this.invoices[this.activeInvoice].data.subtotal = sum;
      this.invoices[this.activeInvoice].data.tax = sum * ( row.tax.rate / 100);
      this.invoices[this.activeInvoice].data.total = sum + (sum * ( row.tax.rate / 100));
    }
    this.setInvoicesLocalStorage();
  }
  selectContact(contact): void {
    this.parameters['supplier.id'] = contact;
    this.onLoadStock().then();
    this.selectInvoicesItems = [];
    this.display = false;
}
  changeActiveInvoice(i): void {
    this.activeInvoice = i;
    this.parameters['supplier.id'] = this.invoices[i].key;
    this.selectInvoicesItems = this.invoices[i].data.items;
    this.selectInvoicesItems.map((item) => {
      const objIndex = this.stocks.findIndex((obj => obj['@id'] === item.stock));
    });
  }
  setDate(date): void {

  }
  setDescription(event): void {
    this.invoices[this.activeInvoice].data.description = event.value;
  }
  setLocation(event): void {
    this.invoices[this.activeInvoice].data.location = event.value;
  }
  changeAlertLevel(alertLevel, row, i , option = null, optionAlertLevel = null): void {
    if (option === null) {
      this.http.put(`${StockModel.IRI}/${row.id}`, { variants : row.variants}).then(res => {

        this.onLoadStock();
      });
    }else{
      row.variants[0].alertLevel = optionAlertLevel * option;
      this.http.put(`${StockModel.IRI}/${row.id}`, { variants : row.variants }).then(res => {

        this.onLoadStock();
      });
    }

  }
  addContact(item: ContactModel = null): void {
    this.dialog.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
    });
  }
  addCategory(): void {
    this.dialog.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),

    });
  }
  stockEdit(row): void {
    // this.stockDialog = true;
    this.dialog.open(StockUpdateComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: {
        id: row.id
      },

    });
  }
  onDisplayInvoiceStatus(): void {
    this.displayInvoiceStatus = true;
  }
  edit(event): void {
    this.loading = true;
    this.displayCreate = false;
    this.displayUpdate = true;
    this.http.get(`${StockModel.IRI}/${event.id}`).subscribe((response) => {
      this.item = response;
      this.item.category = this.item.category ? this.item.category['@id'] : null;
      this.item.locations = this.item.locations ? this.item.locations : null;
      this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
      this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
      this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
      // @ts-ignore
      this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
      this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
      this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
      this.item.purchaseOptions.map((data, i) => {
        this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
          this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
          this.item.purchaseOptions[i].supplier = data.supplier['@id'];
          this.item.purchaseOptions[i].unit = data.unit['@id'];
          if (data.packName === '' && data.packPerCase === null ){
            this.item.purchaseOptions[i].orderingUnit = data.packQuantity  + ' ' + unit['hydra:member'][0].name;
          }else{
            this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity  + ' ' + unit['hydra:member'][0].name +  ')';
          }
        });
      });
      this.loading = false;
    });
    this.display = true;
    this.displaySideBar = true;
  }
  reload(): void {
    this.displayCreate = false;
    this.displayUpdate = false;
    this.displaySideBar = false;
    this.onLoadStock().then();

  }
  openForm(): void {
    this.displaySideBar = true;
    this.displayCreate = true;
    this.displayUpdate = false;
  }
  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }
  async onChangeQuantityPurchaseOption(event, order, i, row): Promise<void> {

  }
  async  save(key): Promise<void> {
    this.submitted = true;
    const dataCount = this.invoices.length;
    const invoiceIndex = this.invoices.findIndex((obj => obj.key === key ));
    await this.http.get(`${InvoiceModel.IRI}/invoice-number?type=PURCHASE`).subscribe((res: InvoiceModel) => {
      this.invoices[invoiceIndex].data.number = res.number;
      this.invoices[invoiceIndex].data.code = res.code;
      this.invoices[invoiceIndex].data.series = res.series ;
      this.http.post(InvoiceModel.IRI, this.invoices[invoiceIndex].data).then((response: InvoiceModel) => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
          this.invoices.splice(objInvoiceIndex, 1);
          this.activeInvoice = this.invoices.length - 1;
          this.supplierCount = this.invoices.length - 1;
          this.submitted = false;
          this.invoiceInfoComment = 'Invoice Number : ' + response.code;
          this.invoiceRouteId = response.id;
          this.invoiceInfo = true;
          this.setInvoicesLocalStorage();
        }else{
          this.submitted = false;
        }
      }).catch(err => {
      });
    });

  }
  removeInvoiceItem(i, key): void {
    this.invoices[this.activeInvoice].data.items.splice(i, 1);
    if (this.invoices[this.activeInvoice].data.items.length === 0) {
      const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
      this.invoices.splice(objInvoiceIndex, 1);
      this.activeInvoice = this.invoices.length - 1;
      this.supplierCount = this.invoices.length - 1;
    }
    this.setInvoicesLocalStorage();
    this.setStockValues();
  }
  removeInvoice(key): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        const objInvoiceIndex = this.invoices.findIndex((obj => obj.key === key));
        this.invoices.splice(objInvoiceIndex, 1);
        this.activeInvoice = this.invoices.length - 1;
        this.supplierCount = this.invoices.length - 1;
        this.onLoadStock().then();
        this.setInvoicesLocalStorage();
      }
    });
  }
  setInvoicesLocalStorage(): void {
    window.localStorage.setItem('invoices',  JSON.stringify(this.invoices));
  }
  setStockValues(): void {
    this.stocks.map((stock) => {
      stock.purchaseOptions.map((data , i) => {
        // tslint:disable-next-line:max-line-length
        data.alertLevel = data.baseUnitQuantity === null ? Math.ceil(stock.variants[0].alertLevel / data.packQuantity) : Math.ceil(stock.variants[0].alertLevel / data.baseUnitQuantity);
        // tslint:disable-next-line:max-line-length
        data.onHand = data.baseUnitQuantity === null ? Math.floor(stock.variants[0].amount / data.packQuantity) : Math.floor(stock.variants[0].amount / data.baseUnitQuantity);
        // tslint:disable-next-line:max-line-length
        if (data.unit instanceof UnitModel) {
          data.orderType = data.packName === null || data.packPerCase === null ? data.packQuantity + ' ' + data.unit.name : data.packName + '(' + data.packPerCase + 'x' + data.packQuantity + ' ' + data.unit.name + ')';
        }
      });
    });
    if (this.invoices.length > 1 ) {
      this.stockOrderQuantityValues = [];
      this.invoices.map((invoice, i) => {
        invoice.data.items.forEach((item) => {
          this.stocks.map(stock => {
            if ( stock.purchaseOptions) {
              stock.purchaseOptions.forEach((option) => {
                if  (!(option.supplier instanceof ContactModel) || option.supplier.id === invoice.key && stock['@id'] === option.stock){
                  option.orderQuantity = item.quantity;
                }
              });
            }
            if (!(stock.supplier instanceof ContactModel) || stock.supplier.id === invoice.key && stock['@id'] === item.stock){
              stock.variants[0].orderQuantity = item.quantity;
            }
          });
          this.stockOrderQuantityValues.push({
            key : invoice.key,
            data : {
              stock: item.stock ,
              orderQuantity: item.quantity
            }
          });
        });
      });
    }
  }
}

