import { Component, OnInit } from '@angular/core';
import {StockVariantOptionModel} from '../../model/stock-variant-option.model';
import {StockVariantOptionValueModel} from '../../model/stock-variant-option-value.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {HttpService} from '../../service/http.service';
import {FlashMessageService} from '../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../model/flash-message.model';
import {StockModel} from '../../model/stock.model';

@Component({
  selector: 'app-stock-variant',
  templateUrl: './stock-variant.component.html',
  styleUrls: ['./stock-variant.component.scss']
})
export class StockVariantComponent implements OnInit {

  submitted: boolean;
  loading: boolean;
  items: StockVariantOptionModel[] = [];
  autoCompleteValues: StockModel[] = [];
  copy: boolean;

  constructor(private config: DynamicDialogConfig, private http: HttpService, private ref: DynamicDialogRef,
              private flashMessageService: FlashMessageService) { }

  async ngOnInit(): Promise<void> {
    await this.load(this.config.data.id);
    if (this.items.length < 1) {
      this.add();
    }
  }

  async load(id: string): Promise<void> {
    this.loading = true;
    await this.http.get(StockVariantOptionModel.IRI, {'stock.id': id}).toPromise().then((response) => {
      if (response && response['hydra:totalItems'] > 0) {
        this.items = response['hydra:member'];
      }
    });

    this.loading = false;
  }

  async save(): Promise<void> {
    this.submitted = true;
    await Promise.all(this.items.map(async (item) => {
      if (item.id) {
        // tslint:disable-next-line:max-line-length
        await this.http.put(`${StockVariantOptionModel.IRI}/${item.id}`, item).then(async (response: StockVariantOptionModel) => {});
      } else {
        await this.http.post(StockVariantOptionModel.IRI, item).then(async (response: StockVariantOptionModel) => {       });
      }
    }));

    this.submitted = false;
    this.ref.close({load: true});
  }

  add(): void {
    const option = new StockVariantOptionModel();
    option.stock = this.config.data.id;
    this.items.push(option);
  }

  remove(i: number, id: number): void {
    if (id) {
      this.http.delete(`${StockVariantOptionModel.IRI}/${id}`).then(() => {
        this.items.splice(i, 1);
      }).catch(error => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, error.error['hydra:title']));
      });
    } else {
      this.items.splice(i, 1);
    }
  }

  addOption(i: number, event): void {
    const option = new StockVariantOptionValueModel();
    option.name = event.value;

    this.items[i].optionValues.map((item, key) => {
      if (!(item instanceof StockVariantOptionValueModel) && item['@id'] === undefined) {
        this.items[i].optionValues.splice(key, 1);
      }
    });
    this.items[i].optionValues.push(option);
  }

  isValid(): boolean {
    let isValid = true;

    this.items.map((option: StockVariantOptionModel) => {
      if (option.name === '' || option.name === undefined || option.optionValues.length < 1) {
        isValid = false;
      }
    });

    return isValid;
  }

  loadOptions(event): void {
    this.http.get(StockVariantOptionModel.IRI, {'stock.id': event['@id']}).toPromise().then((response) => {
      if (response && response['hydra:totalItems'] > 0) {
        this.items.map(item => {
          if (item.optionValues.length < 1) {
            this.items = [];
          }
        });

        response['hydra:member'].map((item) => {
          const variantOption = new StockVariantOptionModel();
          variantOption.stock = this.config.data.id;
          variantOption.name = item.name;

          item.optionValues.map(value => {
            const optionValue = new StockVariantOptionValueModel();
            optionValue.name = value.name;

            variantOption.optionValues.push(optionValue);
          });

          this.items.push(variantOption);
        });
      }
    });
  }

  searchStock = event => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel[]) => {
      this.autoCompleteValues = response['hydra:member'];
    });
  }
}
