<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'RECIPE_PREPARATION_ITEMS' | translate }}</h3>

    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'MENU_ITEMS'"></app-page-info>
      <p-button icon="pi pi-arrow-down"  (onClick)="createExcel()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <a target="_blank" href="{{apiUrl + '/api/stocks/pdf'}}"> <p-button icon="pi pi-file-pdf"[style]="{marginLeft: '5px', float:'right'}"></p-button></a>
      <button pButton pRipple type="button" label="{{ 'NEW' | translate}}"
              (click)="openForm()" [style]="{marginLeft: '5px', float:'right'}">
      </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                   [(ngModel)]="parameters['stock.category.id']" [filter]="true"
                   placeholder="{{'SELECT_CATEGORY' | translate}}" (onChange)="changeParameters($event,'category')"
                   [options]="searchCategories"  optionLabel="name" optionValue="id" [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                   [(ngModel)]="parameters['stock.locations.id']"
                   (onChange)="changeParameters($event,'locations')"  [options]="locations"
                   optionLabel="name" optionValue="id" [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <input [style]="{width: '100%',  border:'none', borderBottom: '2px solid #47bfd4'}"
             type="text" pInputText [(ngModel)]="parameters['stock.name']" name="name"
             (change)="changeParameters($event,'name')"
             placeholder="{{ 'SEARCH_EG_NAME_CODE' | translate}}"/>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3" *ngIf="nonRecipesCount !== undefined && nonRecipesCount > 0">
    <div class="p-col div-sums alert-div">
      <b> {{ 'ALERT' | translate}}!</b> {{'PRODUCT_RECIPE_REMAINING'| translate }}
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-message severity="warn" *ngIf="queueDisplay" class="p-d-block" [style]="{padding: '10px', marginBottom:'20px'}" text="{{'PRODUCT_IMPORT_REMAINING'| translate }}">
      </p-message>
      <p-table [loading]="tableLoading" [paginator]="true" [resizableColumns]="true" [rowsPerPageOptions]="[10,25,50]"
               [rows]="20" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
               [totalRecords]="total" [lazy]="true" (onLazyLoad)="load($event)"
               [value]="products"
               styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '8%'}"  pSortableColumn="code"><p-sortIcon field="code"></p-sortIcon>{{'SKU' | translate}} <app-tooltip description="PRODUCTS_SKU_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '30%'}" pSortableColumn="stock.name"><p-sortIcon field="stock.name"></p-sortIcon>{{'STOCK' | translate}} <app-tooltip description="PRODUCTS_STOCK_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '20%'}" pSortableColumn="stock.category.id" ><p-sortIcon field="stock.category.id"></p-sortIcon>{{'CATEGORY' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '10%'}" pSortableColumn="salePrice"><p-sortIcon field="salePrice"></p-sortIcon>{{ 'SALE_PRICE'|translate }} <app-tooltip description="PRODUCTS_SALES_PRICE_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '10%'}" pSortableColumn="totalCost"><p-sortIcon field="totalcost"></p-sortIcon>{{ 'COST'|translate }} <app-tooltip description="PRODUCTS_COST_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '10%'}" pSortableColumn="profit"><p-sortIcon field="profit"></p-sortIcon>{{ 'PROFIT'|translate }} <app-tooltip description="PRODUCTS_PROFIT_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '10%'}" pSortableColumn="margin"><p-sortIcon field="margin"></p-sortIcon>{{ 'MARGIN'|translate }} <app-tooltip description="PRODUCTS_MARGIN_TOOLTIP"></app-tooltip></th>
            <th [style]="{width: '10%'}" >{{'RECIPES' | translate}} <app-tooltip description="LAST_COUNT_TOOLTIP"></app-tooltip></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr [style]="row.totalCost === 0 ? 'background:#ffc4c4': ''">
            <td>{{ row.code }} {{ totalCostControl(row.totalCost)}}</td>
            <td><a href="javascript:void(0)"  [style]="{color:'#10bee5'}"(click)="edit(row.stock.id)"><i class="pi pi-pencil" [style]="{fontSize: '10px',marginRight: '10px'}"></i> </a>{{ row.stock.name }} / {{ row.name }}</td>
            <td>{{ row.stock.category?.parent?.name }} {{ row.stock.category?.parent ? '/' : '' }} {{ row.stock.category?.name }}</td>
            <td [style]="{textAlign: 'right'}">{{ row.salePrice|currency: row.stock.currency.symbol}}</td>
            <td [style]="{textAlign: 'right'}">{{ row.totalCost|currency: row.stock.currency.symbol }}</td>
            <td [style]="{textAlign: 'right'}">{{ row.profit |currency: row.stock.currency.symbol}}</td>
            <td [style]="{textAlign: 'right'}">{{ row.margin | number: '1.1-2' }} %</td>
            <td [style]="{textAlign: 'right'}">
              <i class="pi pi-chart-line" [style]="{fontSize: '1.2rem', color:'#55c416',marginRight:'20px'}" style="font-size: 2rem" ></i>
              <i class="pi pi-book" [style]="{fontSize: '1.2rem', color:'#10bee5'}" style="font-size: 2rem" (click)="onLoadRecipes(row.id)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-confirmPopup></p-confirmPopup>
<p-sidebar [(visible)]="display" [style]="{width:'80em'}" position="right" (onHide)="reload()">
  <app-new-material-create *ngIf="display" [type]="type" [item]="item" ></app-new-material-create>
</p-sidebar>

<p-sidebar [(visible)]="showQuantitiesDialog" position="right"  [style]="{width:'60em'}">
  <h3>Depolar ve Stock Miktarları</h3>
  <hr>
  <p-table *ngIf="showQuantitiesDialog" [value]="quantities['stockQuantities']"
           styleClass="p-datatable-striped"  columnResizeMode="expand">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width:'40%'}"></th>
        <th [style]="{textAlign:'right',width:'20%'}">{{ 'IN_STOCK' | translate}}</th>
        <th [style]="{textAlign:'right',width:'20%'}">{{ 'PRICE' | translate}}</th>
        <th [style]="{textAlign:'right',width:'20%'}">{{ 'VALUE' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>

    </ng-template>
  </p-table>

</p-sidebar>
<p-sidebar  [(visible)]="recipeDialog" [style]="{width: '70vw'}" position="right" (onHide)="load(null)">
  <app-variant-recipe *ngIf="recipeDialog"  variantId="{{ recipeId }}" [location]="parameters['stock.locations.id']"></app-variant-recipe>
</p-sidebar>

<p-dialog [(visible)]="selectLocationShow" [style]="{width:'50vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff;border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444" (click)="parameters['stock.locations.id'] =location.id; load(null);selectLocationShow = false">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
