<form (ngSubmit)="save()">
  <div class="p-fluid">
    <div class="p-field">
      <label for="street">{{'STREET' | translate}}</label>
      <input id="street" type="text" pInputText [(ngModel)]="address.street" name="street">
      <small class="p-error">{{ errors['street'] }}</small>
    </div>
    <div class="p-field">
      <label for="line1">{{'LINE1' | translate}}</label>
      <input id="line1" type="text" pInputText [(ngModel)]="address.line1" name="line1">
    </div>
    <div class="p-field">
      <label for="line2">{{'LINE2' | translate}}</label>
      <input id="line2" type="text" pInputText [(ngModel)]="address.line2" name="line2">
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
      <label for="city">{{'CITY' | translate}}</label>
      <input id="city" type="text" pInputText [(ngModel)]="address.city" name="city">
    </div>
    <div class="p-field p-col">
      <label for="state">{{'STATE' | translate}}</label>
      <input id="state" type="text" pInputText [(ngModel)]="address.state" name="state">
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
      <label for="zipCode">{{'ZIP_CODE' | translate}}</label>
      <input id="zipCode" type="text" pInputText [(ngModel)]="address.zipCode" name="zipCode">
    </div>
    <div class="p-field p-col">
      <label for="state">{{'COUNTRY' | translate}}</label>
      <p-dropdown id="location" [options]="countries" appendTo="body" [(ngModel)]="address.country" optionLabel="name"  optionValue="@id" name="location"></p-dropdown>
    </div>
  </div>
 <div class="p-grid">
   <div class="p-col-4 p-offset-4">
     <button pButton [style]="{width: '100%'}" label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"></button>
   </div>
 </div>
  </form>
