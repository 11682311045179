<div class="p-fluid">
<span class="p-buttonset p-mb-3">
    <button (click)="changeStatus('in_stock')" pButton type="button" class="p-button-secondary" label="{{'IN_STOCK' | translate}}"></button>
    <button (click)="changeStatus('expected')" pButton type="button" class="p-button-secondary" label="{{'EXPECTED_AMOUNT' | translate}}"></button>
    <button (click)="changeStatus('committed')" pButton type="button" class="p-button-secondary" label="{{'COMMITTED_AMOUNT' | translate}}"></button>
    <button (click)="changeStatus('missing')" pButton type="button" class="p-button-secondary" label="{{'MISSING_STOCK' | translate}}"></button>
</span>
  <p-table [value]="transactions" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'DATE' | translate}}</th>
        <th>{{'RELATED_BY' | translate}}</th>
        <th>{{'QUANTITY' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{product.date.substr(0, 10)}}</td>
        <td>{{product.code}}</td>
        <td>{{product.quantity}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
