import { Component, OnInit } from '@angular/core';
import {IntegrationModel} from '../../../model/integration.model';
import {HttpService} from '../../../service/http.service';
import {DialogService} from 'primeng/dynamicdialog';
import {LucaComponent} from './luca/luca.component';
import {TranslateService} from '@ngx-translate/core';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {ActivatedRoute} from '@angular/router';
import {KolaybiComponent} from './kolaybi/kolaybi.component';
import { environment } from '../../../../environments/environment';
import {ParasutComponent} from './parasut/parasut.component';
import {LingaComponent} from './linga/linga.component';
import {LogoComponent} from './logo/logo.component';
import {EfaturaComponent} from './efatura/efatura.component';
import {TikoHesapComponent} from './tiko-hesap/tiko-hesap.component';
import {PoscanaComponent} from './poscana/poscana.component';
import {BizimHesapComponent} from './bizim-hesap/bizim-hesap.component';
import {SquareComponent} from './square/square.component';
import {AdisyoComponent} from './adisyo/adisyo.component';
import {PaycellComponent} from "./paycell/paycell.component";
import { SimpraComponent } from './simpra/simpra.component';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  providers: [DialogService]
})
export class IntegrationsComponent implements OnInit {

  loading: boolean;
  integrations: IntegrationModel[] = [];
  activeIntegrations = [];
  partner: string = null;
  code: string = null;
  kolayBiConfiguration = {
    clientId: null,
    clientSecret: null,
    redirectUri: null,
    apiUrl: null,
    channel: null
  };

  constructor(private http: HttpService, private dialogService: DialogService, private translate: TranslateService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.kolayBiConfiguration = environment.kolayBi;
    this.partner = this.activatedRoute.snapshot.queryParamMap.get('partner');
    this.code = this.activatedRoute.snapshot.queryParamMap.get('code');

    if (this.partner && this.partner === 'kolaybi' && this.code) {
      this.http.get(`${CompanyIntegrationModel.IRI}/kolaybi-connect`, {code: this.code}).subscribe(response => {
        if (response) {
          this.activeIntegrations[response.integration['@id']] = response.id;

          this.dialogService.open(KolaybiComponent, {
            width: '50vw',
            header: '',
          });
        }
      });
    }

    if (this.partner && this.partner === 'isBasi' && this.code) {
      this.http.get(`${CompanyIntegrationModel.IRI}/kolaybi-connect`, {code: this.code}).subscribe(response => {
        if (response) {
          this.activeIntegrations[response.integration['@id']] = response.id;

          this.dialogService.open(KolaybiComponent, {
            width: '50vw',
            header: '',
          });
        }
      });
    }

    this.load();
  }

  load(): void {
    this.loading = true;
    this.http.get(IntegrationModel.IRI, {pagination: false, itemsPerPage: 100, page:1}).subscribe((response: IntegrationModel[]) => {
      this.integrations = response['hydra:member'];
      this.loading = false;
    });

    this.loadIntegrations();
  }

  loadIntegrations(): void {
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.activeIntegrations = [];
      response['hydra:member'].map((item: CompanyIntegrationModel) => {
        if (item.isActive) {
          // @ts-ignore
          this.activeIntegrations[item.integration['@id']] = item.id;
        }
      });
    });
  }

  openModal(id: number): void {
    const ref = this.dialogService.open(LucaComponent, {
      width: '50vw',
      data: {id},
      header: 'LUCA',
    });

    ref.onClose.subscribe((data) => {
      if (data && data.integration !== undefined && data.integration) {
        this.loadIntegrations();
      }
    });
  }

  connectKolayBi(): void {
    window.location.href = `${this.kolayBiConfiguration.apiUrl}/oauth/authorize?client_id=${this.kolayBiConfiguration.clientId}&redirect_uri=${this.kolayBiConfiguration.redirectUri}&client_secret=${this.kolayBiConfiguration.clientSecret}&response_type=code`;
  }

  connectParasut(isConnected: boolean): void
  {
    const ref = this.dialogService.open(ParasutComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Paraşüt',
    });
  }

  connectLinga(isConnected: boolean): void
  {
    const ref = this.dialogService.open(LingaComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Linga',
    });
  }

  connectLogo(isConnected: boolean): void
  {
    const ref = this.dialogService.open(LogoComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Logo',
    });
  }

  connectEfatura(isConnected: boolean): void
  {
    const ref = this.dialogService.open(EfaturaComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'E-Fatura',
    });
  }

  connectTikoHesap(isConnected: boolean): void
  {
    const ref = this.dialogService.open(TikoHesapComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Tiko Hesap',
    });
  }
  connectBizimHesap(isConnected: boolean): void
  {
    const ref = this.dialogService.open(BizimHesapComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Bizim Hesap',
    });
  }

  connectPoscana(isConnected: boolean): void
  {
    const ref = this.dialogService.open(PoscanaComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Poscana',
    });
  }
  connectSquare(isConnected: boolean): void
  {
    const ref = this.dialogService.open(SquareComponent, {
      width: '50vw',
      data: {isConnected},
      header: 'Square',
    });
  }
  connectAdisyo(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(AdisyoComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Adisyo',
    });
  }
  connectPaycell(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(PaycellComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Paycell',
    });
  }
  connectSimpra(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(SimpraComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Simpra',
    });
  }
  connectMenulux(isConnected: boolean, location: string): void
  {
    const ref = this.dialogService.open(SimpraComponent, {
      width: '50vw',
      data: {isConnected, location},
      header: 'Menulux',
    });
  }
  async disconnect(id: number): Promise<void> {
    await this.http.put(`${CompanyIntegrationModel.IRI}/${id}`, {isActive: false}).then(response => {
      if (response) {
        this.loadIntegrations();
      }
    });
  }
}
