import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ActivatedRoute} from '@angular/router';
import {StockModel} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact-stocks',
  templateUrl: './contact-stocks.component.html',
  styleUrls: ['./contact-stocks.component.scss']
})
export class ContactStocksComponent implements OnInit {

  stocks: [];
  noData: boolean;
  loading: boolean;
  parameters = {
    id: '',
    'variants.code': '',
    'variants.name': '',
    'supplier.name': '',
    'category.name': '',
    'variants.price': '',
    'supplier.id': '',
    page: 1,
    itemsPerPage: 20,
  };
  totalRecords: number;
  contacts: ContactModel[] = [];
  moreLoading: boolean;

  constructor(private http: HttpService, private route: ActivatedRoute,
              private translate: TranslateService,
              private router: Router,
              private flashMessageService: FlashMessageService) { }

  ngOnInit(): void {
    this.parameters['supplier.id'] = this.route.snapshot.paramMap.get('id');
    this.onLoadStock().then();
  }

  async onLoadStock(): Promise<void> {
    this.noData = false;
    this.loading = true;
    // @ts-ignore
    await this.http.get(ContactModel.IRI, {type: 'supplier'}).subscribe(response => {
      if (response !== undefined) {
        this.contacts = response['hydra:member'];
      }
    });
    this.parameters = {...this.parameters, 'supplier.id' : this.parameters['supplier.id'] };
    await this.http.get(StockModel.IRI, this.parameters).subscribe((response) => {
      if (response['hydra:totalItems'] === 0) {
        this.noData = true;
        this.loading = false;
      }else{
        this.noData = false;
        this.loading = false;
        this.stocks = response['hydra:member'];
        this.totalRecords = response['hydra:totalItems'];
      }
    });
  }
  async changeContact(event): Promise<void> {
    this.parameters['supplier.id'] = event.value;
    this.parameters = {...this.parameters};
    await this.router.navigate(['contact/stock-prices/', event.value ]).then();
    this.onLoadStock().then();
  }

}
