import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-item-create-page-info',
  templateUrl: './menu-item-create-page-info.component.html',
  styleUrls: ['./menu-item-create-page-info.component.scss']
})
export class MenuItemCreatePageInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
