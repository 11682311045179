import { Component, OnInit } from '@angular/core';
import {CompanyModel} from '../../../model/company.model';
import {LocationModel} from '../../../model/location.model';
import {HttpService} from '../../../service/http.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-payment-packets',
  templateUrl: './payment-packets.component.html',
  styleUrls: ['./payment-packets.component.scss']
})
export class PaymentPacketsComponent implements OnInit {

  company: CompanyModel = new CompanyModel();
  loading: boolean;
  submitted: boolean;
  apiUrl: string;
  plan = 'c2b9d646-87f5-4eb2-b41d-046eeeb17a8d-4';
  monthlyPlans = [
    {
      name: 'Temel',
      plain: 'A1',
      description: 'Envanter, sipariş ve alımlarınızı kontrol altına alır',
      code: '4fde7d4c-b0b8-4627-865a-a21cf53080c2',
      price: 39,
      priceSub: 'Konum başına / Aylık',
      specification: [
        { name : 'POS & muhasebe yazılımı Entegrasyonları'},
        { name : 'Satın alma siparişlerini verme ve alma'},
        { name : 'Envanter sayımı ve transferler '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'SMM ve satın alma raporlaması '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Profesyonel',
      plain: 'A2',
      description: 'Satılan malın maliyetini azaltmak için imkân ve araçlar sunar',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 69,
      priceSub: 'Konum başına / Aylık',
      specification: [
        { name : 'POS & muhasebe yazılımı Entegrasyonları'},
        { name : 'Satın alma siparişlerini verme ve alma'},
        { name : 'Envanter sayımı ve transferler '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'SMM ve satın alma raporlaması '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Girişim',
      plain: 'A3',
      description: 'Çok şubeli işletmeler ve pazaryerleri üzerinde hâkimiyet sağlar',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 89,
      priceSub: 'Konum başına / Aylık',
      specification: [
        { name : 'POS & muhasebe yazılımı Entegrasyonları'},
        { name : 'Satın alma siparişlerini verme ve alma'},
        { name : 'Envanter sayımı ve transferler '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'SMM ve satın alma raporlaması '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'Ayda 50 fatura taraması '},
      ]
    },
  ];
  annualPlans = [
    {
      name: 'Temel',
      plain: 'Y1',
      description: 'Envanter, sipariş ve alımlarınızı kontrol altına alır',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 35,
      priceSub: 'Konum başına / Aylık',
      specification: [
        { name : 'POS & muhasebe yazılımı Entegrasyonları'},
        { name : 'Satın alma siparişlerini verme ve alma'},
        { name : 'Envanter sayımı ve transferler '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'SMM ve satın alma raporlaması '},
        { name : 'Borç hesapları yönetimi '},
        { name : 'Ayda 50 fatura taraması '},
      ]
    },
    {
      name: 'Profesyonel',
      plain: 'Y2',
      description: 'Satılan malın maliyetini azaltmak için imkân ve araçlar sunar',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 65,
      priceSub: 'Konum başına / Aylık',
      specification: [
        { name : 'Tedarikçi sipariş otomasyonu ve onayları'},
        { name : 'Tarif maliyeti ve dijital yemek kitabı'},
        { name : 'Envanter atık takibi'},
        { name : 'Gelişmiş karlılık raporlaması'},
        { name : 'Gerçek ve teorik raporlama'},
        { name : 'API erişimi'},
        { name : 'Ayda 200 fatura taraması'},
      ]
    },
    {
      name: 'Girişim',
      plain: 'Y3',
      description: 'Çok şubeli işletmeler ve pazaryerleri üzerinde hâkimiyet sağlar',
      code: '5dc16660-a45f-4dd4-be28-5f7f64e62253-1',
      price: 95,
      priceSub: 'Konum başına / Aylık',
      specification: [
        { name : 'Tedarikçi sipariş otomasyonu ve onayları'},
        { name : 'Tarif maliyeti ve dijital yemek kitabı'},
        { name : 'Envanter atık takibi'},
        { name : 'Gelişmiş karlılık raporlaması'},
        { name : 'Fiili ve teorik raporlama'},
        { name : 'API erişimi'},
        { name : 'Ayda 300 fatura taraması'},
      ]
    },
  ];

  paymentForm: string = null;
  card = {
    cardHolderName: '',
    cardNumber: '',
    expireMonth: null,
    expireYear: null,
    cvc: '',
  };
  selectedPlain: '';
  selectedPrice: number;
  index: number;
  locations: LocationModel[] = [];
  location: LocationModel = new LocationModel();
  locationCount: number;
  locationLength: number;
  nameValues = [];
  constructor(private http: HttpService, private flashMessageService: FlashMessageService , private router: Router) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.loading = true;
    this.index = 0;
    this.selectedPrice = 0;

    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
    });

    this.http.get(CompanyModel.IRI).subscribe(response => {
      this.company = response;
      this.locationCount = this.company.locationCount;

      this.loading = false;
    });
  }
  onSelectPlain(periot, plain , price): void {
    this.selectedPrice = 0;
    this.selectedPlain = plain;
    this.selectedPrice = periot * price;
    localStorage.setItem('packet', this.selectedPlain);
    this.router.navigate(['payment/basket']).then(r => {});
  }
}
