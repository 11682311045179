<p-card>
  <app-loading-indicator [loading]="false"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'ALLERGENS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'ALLERGENS'"></app-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
      <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTabMenuItem"></p-tabMenu>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 20px">
    <div class="p-col-12 p-md-6 p-lg-2">
      <input type="text" class="boxShadow" pInputText [(ngModel)]="parameters.name"
             [style]="{width: '100%',height:'45px',border:'none', borderBottom: '2px solid #47bfd4'}"
             name="name" placeholder="{{'SEARCH_EG_NAME_CODE' | translate}}">
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                   [(ngModel)]="parameters['category.id']"
                   placeholder="{{'SELECT_CATEGORY' | translate}}" (onChange)="changeParameters($event,'category')"
                   [options]="searchCategories"  optionLabel="name" optionValue="id" >
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="parameters['stock.type'] === 'material'">

      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  appendTo="body" [(ngModel)]="parameters['stock.supplier.id']"
                  placeholder="{{'SELECT_SUPPLIER' | translate}}" (onChange)="changeParameters($event,'supplier')"
                  [options]="searchSuppliers" optionLabel="name" optionValue="id" >
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                   [(ngModel)]="parameters['stock.locations.id']" placeholder="{{'SELECT_LOCATION' | translate}}"
                   (onChange)="changeParameters($event,'locations')"  [options]="locations"
                   optionLabel="name" optionValue="@id" [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top: 30px">
      <form action="" (ngSubmit)="load()" (submit)="load()">
        <p-table styleClass="p-datatable-striped" [loading]="loading" [lazy]="true" [value]="products" [totalRecords]="total" (onLazyLoad)="load($event)"
                 [showCurrentPageReport]="true" [resizableColumns]="true"
                 styleClass="p-datatable-striped p-datatable-lg"  columnResizeMode="expand">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'12%'}">{{'STOCK' | translate}} <app-tooltip description="PRODUCTS_STOCK_TOOLTIP"></app-tooltip></th>
              <th [style]="{width:'6%'}">{{'SKU' | translate}} <app-tooltip description="PRODUCTS_SKU_TOOLTIP"></app-tooltip></th>
              <th [style]="{width:'15%'}" *ngIf="parameters['stock.type'] === 'material'" pResizableColumn>{{'SUPPLIER' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
              <th [style]="{width:'10%'}">{{'CATEGORY' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
              <th [style]="{width:'20%'}">{{'ALLERGENS' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
              <th [style]="{width:'20%'}">{{'DOES_NOT_CONTAIN' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
              <th [style]="{width:'10%'}">{{'EDIT' | translate}} <app-tooltip description="PRODUCTS_CATEGORY_TOOLTIP"></app-tooltip></th>
            </tr>
            <tr style="display: none">
              <td><input type="text" pInputText [(ngModel)]="parameters.name" name="name"></td>
              <td><input type="text" pInputText [(ngModel)]="parameters.code" name="code"></td>
              <td><input type="text" pInputText [(ngModel)]="parameters['stock.category.name']" name="category"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th>
                <button type="submit" class="p-d-none" pButton label="{{'stock.search' | translate}}" (click)="load()"></button>
              </th>
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-row let-i="rowIndex">
            <tr>
              <td>{{ row.name }}</td>
              <td>{{ row.code }}</td>
              <td *ngIf="parameters['stock.type'] === 'material'">{{ row.purchaseOptions?.length > 0 ? 'Multi' : row.stock.supplier?.name  }}</td>
              <td>{{ row.stock.category?.parent?.name }} {{ row.stock.category?.parent ? '/' : '' }} {{ row.stock.category?.name }}</td>
              <td><b [style]="{float:'left'}" *ngIf="row.stock.allergen.length > 0">{{ 'CONTAINS'| translate}} : </b>
                <div [style]="{float:'left'}" *ngFor="let item of row.stock.allergen">
                  <span [style]="{float:'left'}" *ngIf="item.contains === true">{{ item.name }},</span>
                </div>
                <br>
                <b [style]="{float:'left'}"*ngIf="row.stock.allergen.length > 0">{{ 'MAY_CONTAIN'| translate}} : </b>
                <div [style]="{float:'left'}" *ngFor="let item of row.stock.allergen">
                  <span [style]="{float: 'left'}" *ngIf="item.mayContain === true">{{ item.name }},</span>
                </div>
              </td>
              <td><b [style]="{float:'left'}" *ngIf="row.stock.allergen.length > 0">{{ 'DOES_NOT_CONTAIN'| translate}} : </b>
                <div [style]="{float:'left'}" *ngFor="let item of row.stock.allergen">
                  <span [style]="{float:'left'}" *ngIf="item.doesNotContain === true">{{ item.name }},</span>
                </div>
              </td>
              <td><p-button icon="pi pi-edit" (click)="showAllergen(row.stock, i)" [label]="'EDIT' | translate"></p-button></td>
            </tr>
          </ng-template>
        </p-table>
      </form>
      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="load()"></button>
        </div>
      </div>

    </div>
  </div>

</p-card>
<p-dialog [(visible)]="allergenDialog" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h3>{{ item.name}}</h3>
  </ng-template>
  <p-table styleClass="p-datatable-striped" [value]="item.allergen" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width: '40%'}">{{'Name' |translate}}</th>
        <th>{{'NO_INFO' | translate}}</th>
        <th>{{'CONTAINS' | translate}}</th>
        <th>{{'MAY_CONTAIN' | translate}}</th>
        <th>{{'DOES_NOT_CONTAIN' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td>{{ row.name}} </td>
        <td><p-checkbox [(ngModel)]="row.noInfo" (onChange)="change($event, i, noInfo = true,contains= false,mayContain=false,doesNotContain= false)" binary="true"></p-checkbox></td>
        <td><p-checkbox [(ngModel)]="row.contains" (onChange)="change($event, i, noInfo = false,contains= true,mayContain=false,doesNotContain= false)"  binary="true"></p-checkbox></td>
        <td><p-checkbox [(ngModel)]="row.mayContain" (onChange)="change($event, i, noInfo = false,contains= false,mayContain=true,doesNotContain= false)"  binary="true"></p-checkbox></td>
        <td><p-checkbox [(ngModel)]="row.doesNotContain" (onChange)="change($event, i, noInfo = false,contains= false,mayContain=false,doesNotContain= true)"  binary="true"></p-checkbox></td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <div class="p-grid">
      <div class="p-col-4 p-offset-4">
        <p-button [style]="{width: '100%'}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (onClick)="saveAllergens()" label="{{ 'SAVE' | translate}}"></p-button>
      </div>
    </div>

  </ng-template>
</p-dialog>

