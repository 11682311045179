import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductRecipeModel} from '../../../../model/product-recipe.model';
import {LocationModel} from '../../../../model/location.model';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../service/location.service';
import {CategoryService} from '../../../../service/category.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {UtilityService} from '../../../../service/utility.service';
import {CategoryModel} from '../../../../model/category.model';
import {environment} from '../../../../../environments/environment';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {DailyReportModel} from '../../../../model/daily-report.model';

@Component({
  selector: 'app-daily-income',
  templateUrl: './daily-income.component.html',
  styleUrls: ['./daily-income.component.scss']
})
export class DailyIncomeComponent implements OnInit {
  recalculatingItem: number;
  events: any[] = [];
  options: any;
  dataIncoming: any;
  info: any;
  sale = {
    subTotal: 0,
    total: 0,
    discount: 0,
    tax: 0,
    netTotal: 0,
    cuver: 0,
    saleCount: 0,
    averageCuver: 0,
    averageSale: 0
  };
  saleDates: any;
  categories: any;
  loading: boolean;
  chartCategory: any[] = [];
  chartCategoryValue: any[] = [];
  data: any;
  basicData: any;
  basicOptions: any;
  chartOptions: any;
  chartOpen = false;
  dataDays: any[] = [];
  dataDaysSaleTotal: any[] = [];
  dataDaysSaleGrandTotal: any[] = [];
  location: LocationModel[] = [];
  category: CategoryModel[] = [];
  dateRange: Date[] = [];
  parameters = {
    date: [],
    startDate: '',
    lastDate: '',
    location: '',
    saleDate: ''
  };
  isRefresh: boolean;

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService,
              private categoryService: CategoryService, private utilityService: UtilityService,
              private flashMessageService: FlashMessageService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.http.get(CategoryModel.IRI + '/main-categories', {categoryType: 'product'}).subscribe(response => {
      this.category = response['hydra:member'];
    });
    console.log(this.category);
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.startDate;
        // @ts-ignore
        this.parameters.lastDate = params.lastDate;
        this.dateRange = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];

        const dateRange = this.getDateRange(new Date(this.parameters.startDate), new Date(this.parameters.lastDate));
        console.log(dateRange);

        const selectedDate = dateRange.map(date => this.getFormattedDate(date));

        this.parameters.saleDate = selectedDate.join(',');

        this.parameters.location = params.location ? params.location : '';
      }
      else {
        this.getThisWeekDates();
      }
    });
    this.options = {
      initialDate: new Date(),
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      eventColor: '#378006'
    };
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    this.sale = {
      subTotal: 0,
      total: 0,
      discount: 0,
      tax: 0,
      netTotal: 0,
      cuver: 0,
      saleCount: 0,
      averageCuver: 0,
      averageSale: 0
    };
    this.chartCategory = [];
    this.chartCategoryValue = [];
    this.dataDays = [];
    this.dataDaysSaleTotal = [];
    this.dataDaysSaleGrandTotal = [];
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
      this.dateRange = [new Date(this.info.startDate), new Date(this.info.lastDate)];
    });
    await this.http.get('/api/reports/categories_reports', this.parameters).subscribe(response => {
      this.categories = response;
      this.categories.categories.map(item => {
        this.chartCategory.push(item.name);
        this.chartCategoryValue.push(parseFloat(item.sumSales?.total).toFixed(2));
      });
    });

    await this.http.get('/api/reports/daily_income_reports', this.parameters).subscribe(response => {
      console.log(response);
      this.saleDates = response;
      this.saleDates.map(item => {
        console.log(item.netTotal);
        console.log(item.total - item.tax);
        this.sale.subTotal += parseFloat(item.subTotal);
        this.sale.total += parseFloat(item.total);
        this.sale.netTotal += parseFloat(item.netTotal);
        this.sale.tax += parseFloat(item.tax);
        this.sale.discount += parseFloat(item.discount);
        this.sale.cuver += parseFloat(item.cuver);
        this.sale.saleCount += parseFloat(item.saleCount);
        this.dataDays.push(item.saleDate);
        this.dataDaysSaleTotal.push(Math.ceil(item.netTotal));
        this.dataDaysSaleGrandTotal.push(Math.ceil(item.subTotal).toString());
      });
      this.sale.averageCuver = this.sale.netTotal / this.sale.cuver;
      this.sale.averageSale = this.sale.netTotal / this.sale.saleCount;
      this.chartOpen = true;
      this.loading = false;

    });


    setTimeout(() => {
      this.data = {
        labels: this.dataDays,
        datasets: [{
          type: 'line',
          label: 'Net Gelir',
          borderColor: '#42A5F5',
          data: this.dataDaysSaleTotal,
          borderWidth: 2,
          fill: false
        }, {
          type: 'bar',
          label: 'Toplam Gelir',
          backgroundColor: '#f88612',
          data: this.dataDaysSaleGrandTotal,
          borderColor: 'white',
          borderWidth: 2
        }]
      };
      this.chartOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      this.dataIncoming = {
        labels: this.chartCategory,
        datasets: [
          {
            data: this.chartCategoryValue,
            backgroundColor: [
              '#42A5F5',
              '#f88612',
              '#FFA726',
              '#10eeee'
            ],
            hoverBackgroundColor: [
              '#64B5F6',
              '#81C784',
              '#FFB74D',
              '#128181'
            ]
          }
        ]
      };
    }, 3000);
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    console.log('eee', event[1]);
    if (event[1] === null){
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    }else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }
    // @ts-ignore
    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    this.parameters.saleDate = selectedDate.join(',');

    this.parameters = {...this.parameters};


  }

  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }
  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'flex';
      }
      // @ts-ignore
      pdf.save(`daily_income_report.pdf`); // PDF'i indir
    });
  }

  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/dailyIncomeReport', parameters)
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'daily_income_report.xlsx');
      });
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getThisWeekDates(): void {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Haftanın ilk gününü al
    const thisWeekDates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      thisWeekDates.push(this.getFormattedDate(date));
    }
    this.parameters.startDate = this.getFormattedDate(firstDayOfWeek);
    this.parameters.lastDate = thisWeekDates[thisWeekDates.length - 1];

    this.parameters.saleDate = thisWeekDates.join(',');

  }

  refreshDailyReport(saleDate): void {
    this.isRefresh = true;
    const company = JSON.parse(localStorage.getItem('company'));
    this.http.get(DailyReportModel.IRI, {
      company: company.id , date: saleDate
    }).subscribe(response => {
      this.isRefresh = false;
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      this.load().then();
    });
}
}
