import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationModel} from '../../../../../model/location.model';
import {ActivatedRoute} from '@angular/router';
import {LocationService} from '../../../../../service/location.service';
@Component({
  selector: 'app-by-detail',
  templateUrl: './by-detail.component.html',
  styleUrls: ['./by-detail.component.scss']
})
export class ByDetailComponent implements OnInit {
  events: any[] = [];
  options: any;
  info: any;
  sale: any;
  saleDates: any;
  categories: any;
  loading: boolean;
  data: any;
  chartOptions: any;
  location: LocationModel[] = [];
  date: Date[] = [];
  parameters = {
    date: [],
    startDate: '',
    lastDate: '',
    location: ''
  };

  constructor(private http: HttpService, private translate: TranslateService,
              private route: ActivatedRoute, private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.location = this.locationService.getSearchLocation({type: 'supplier'});
    this.route.queryParams.subscribe(params => {
      if (params.startDate || params.location) {
        // @ts-ignore
        this.parameters.startDate = params.startDate;
        // @ts-ignore
        this.parameters.lastDate = params.lastDate;
        this.date = [new Date(this.parameters.startDate), new Date(this.parameters.lastDate)];
        this.parameters.location = params.location ? params.location : '';
      }
    });
    this.load().then();

  }

  async load(): Promise<void> {
    this.loading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.loading = false;
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
      this.date = [new Date(this.info.startDate), new Date(this.info.lastDate)];
    });
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    this.parameters.startDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    this.parameters.lastDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
    this.parameters = {...this.parameters};
  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

}
