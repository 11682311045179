<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card id="pdf-content">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'SUPPLIERS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6" id="button">
      <app-page-info [pageName]="'SUPPLIERS'" ></app-page-info>
      <p-button icon="pi pi-file-excel" [style]="{marginLeft: '5px', float:'right'}" (onClick)="export()"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button (click)="addContact(null)" [style]="{marginLeft: '5px', float:'right'}" icon="pi pi-external-link"
                label="{{ 'NEW'| translate}}"></p-button>
    </div>
  </div>
  <div class="p-grid" >
    <div class="p-col-12" style="overflow-x: auto;">
      <form (ngSubmit)="search()">
        <button pButton type="submit" icon="pi pi-search" label="{{'search' | translate}}"
                class="p-d-none p-button-success p-mr-2 p-mb-4"></button>

        <p-table #table styleClass="p-datatable-striped" [loading]="loading" [lazy]="true" [value]="items"
                 [totalRecords]="total" (onLazyLoad)="load($event)" [rowsPerPageOptions]="[20,50,100]"
                 [paginator]="true" [rows]="20" [first]="first" >
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn [style]="{width:'15%'}">{{'CODE' | translate}}
                <app-tooltip description="CONTACTS_CODE_TOOLTIP"></app-tooltip>
              </th>
              <th pResizableColumn [style]="{width:'40%'}">{{'NAME' | translate}}
                <app-tooltip description="CONTACTS_NAME_TOOLTIP"></app-tooltip>
              </th>
              <th pResizableColumn [style]="{width:'20%'}">{{'TAX_AGENCY' | translate}}
                <app-tooltip description="CONTACTS_NAME_TOOLTIP"></app-tooltip>
              </th>
              <th pResizableColumn [style]="{width:'20%'}">{{'TAX_AGENCY_NUMBER' | translate}}
                <app-tooltip description="CONTACTS_NAME_TOOLTIP"></app-tooltip>
              </th>
              <th pResizableColumn [style]="{width:'20%'}">{{'EMAIL' | translate}}
                <app-tooltip description="CONTACTS_NAME_TOOLTIP"></app-tooltip>
              </th>
              <th pResizableColumn [style]="{width:'20%'}">{{'DELIVERY_ADDRESSES' | translate}}</th>
              <th pResizableColumn [style]="{width:'10%'}">{{'ADDRESS' | translate}}</th>
              <th pResizableColumn [style]="{width:'5%'}"></th>
            </tr>
            <tr>
              <td>
                <input type="text" pInputText
                       [(ngModel)]="parameters['contact.name']" name="contactName" placeholder="{{'CODE' | translate}}">
              </td>
              <td><input type="text"
                         pInputText [(ngModel)]="parameters.name" name="name" placeholder="{{'NAME' | translate}}"></td>
              <td><input type="text"
                         pInputText [(ngModel)]="parameters.agency" name="agency" placeholder="{{'TAX_AGENCY' | translate}}"></td>
              <td><input type="text"
                         pInputText [(ngModel)]="parameters.agencyNumber" name="agencyNumber" placeholder="{{'TAX_AGENCY_NUMBER' | translate}}"></td>
              <td><input type="text"
                         pInputText [(ngModel)]="parameters.email" name="email" placeholder="{{'EMAIL' | translate}}"></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td style="padding: 0 0 0 0.5rem">{{ row.code }}</td>
              <td [style]="{color: '#47bfd4', textAlign:'left', padding: '0 0 0 0.5rem'}"><a routerLink="/contact/stock-prices/{{row.id}}" [style]="{color: '#47bfd4'}">
                <app-tooltip description="{{ row.name }}"></app-tooltip> <b> {{ row.name | slice:0:30 }}
              {{row.name.length > 30 ? '...': ''}}
              </b></a>
              </td>
              <td style="padding: 0 0 0 0.5rem">{{ row.taxAgency }}</td>
              <td style="padding: 0 0 0 0.5rem">{{ row.taxAgencyNumber }}</td>
              <td style="padding: 0 0 0 0.5rem">{{ row.email }}</td>
              <td style="padding: 0 0 0 0.5rem">
                <button pButton type="button" (click)="openDeliverAddressDialog(row)"
                        class="p-button-secondary p-button-sm p-d-block" [style]="{width: '100%'}"
                        [label]="'DELIVERY_ADDRESSES'|translate"></button>
              </td>
              <td style="padding: 0 0 0 0.5rem">
                <button pButton type="button" (click)="openAddressDialog(row.id, row.address)"
                        class="p-button-secondary p-button-sm p-d-block" [style]="{width: '100%'}"
                        [label]="'ADDRESS'|translate"></button>
              </td>
              <td style="padding: 0 0 0 0.5rem" class="p-text-center">
                <button style="margin-right: 0.5rem" pButton type="button" icon="pi pi-pencil" class="p-button-sm" (click)="edit(row)"></button>
                <!--
                <button pButton type="button" class="p-button-danger p-button-sm" icon="pi pi-trash"  (click)="delete($event, row)" ></button> --->
              </td>
            </tr>
          </ng-template>
        </p-table>
      </form>
      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="load()"></button>
        </div>
      </div>

    </div>
  </div>

</p-card>

<p-confirmPopup></p-confirmPopup>
<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>


<p-dialog [(visible)]="deliverAddressDisplay" [style]="{width: '60vw'}" (onHide)="reload()">
  <div class="p-grid">
    <div class="col-md-12"></div>
    <div class="col-md-12">
      <h3>{{item.name}} {{'DELIVERY_ADDRESSES' | translate}}</h3>
      <p-table [value]="item.deliveryAddresses" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:20%">{{'NAME' | translate}}</th>
            <th style="width:70%">{{'ADDRESS' | translate}}</th>
            <th style="width:10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="product">
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="product.name" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{product.name}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="product.address">
                </ng-template>
                <ng-template pTemplate="output">
                  {{product.address }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <div class="flex align-items-center justify-content-center gap-2">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <button  pButton pRipple type="button" icon="pi pi-plus"
               (click)="addItem()" class="p-button-rounded p-button-text">
      </button>
    </div>
  </div>
</p-dialog>
