import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WorkOrderModel} from '../../../model/work-order.model';
import {HttpService} from '../../../service/http.service';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {WorkOrderMaterialModel} from '../../../model/work-order-material.model';
import {WorkOrderOperationModel} from '../../../model/work-order-operation.model';
import {ResourceModel} from '../../../model/resource.model';
import {OperationModel} from '../../../model/operation.model';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {DialogService} from 'primeng/dynamicdialog';
import {QuickInvoiceCreateComponent} from '../../invoice/quick-invoice-create/quick-invoice-create.component';
import {TranslateService} from '@ngx-translate/core';
import {QuickWorkOrderCreateComponent} from '../quick-work-order-create/quick-work-order-create.component';
import {CurrencyService} from '../../../service/currency.service';
import {LocationModel} from '../../../model/location.model';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SidebarService} from '../../../service/sidebar.service';
import {StockQuantityBatchModel} from '../../../model/stock-quantity-batch.model';
import {UserPageInformationModel} from '../../../model/user-page-information.model';
import {UserModel} from '../../../model/user.model';
import {UserPageInformationService} from '../../../service/user-page-information.service';

@Component({
  selector: 'app-work-order-create',
  templateUrl: './work-order-create.component.html',
  styleUrls: ['./work-order-create.component.scss'],
  providers: [DialogService, {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: WorkOrderCreateComponent
  }]
})
export class WorkOrderCreateComponent implements OnInit, OnDestroy {
  @Input() data: any[];
  @Input() id: number;
  loading = false;
  deleting: boolean;
  loadingRecipeItems = false;
  recipeItemsNotAvailable = false;
  loadingOperationItems = false;
  operationItemsNotAvailable = false;
  submitted = false;
  workOrder: WorkOrderModel = new WorkOrderModel();
  variants: StockVariantModel[] = [];
  resources: ResourceModel[] = [];
  operations: OperationModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  currencies: string[] = [];
  variant: StockVariantModel = new StockVariantModel();
  apiUrl: string;
  currency: string;
  locations: LocationModel[] = [];
  targetId: number;
  printing: boolean;
  invoiceCode: string = null;
  invoiceId: number = null;
  isTrackByLot = false;
  errors = [];
  validationSubscriber: Subscription;
  unitName: string;
  isSaved: boolean;
  lotTrackingView: boolean;
  lotTrackingItemView: boolean;
  canSave: boolean;

  infoTextShowDisplay: boolean;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;

  constructor(private http: HttpService, private route: ActivatedRoute, private flashMessageService: FlashMessageService,
              private router: Router, private dialog: DialogService, private translate: TranslateService,
              private currencyService: CurrencyService, private validationService: ValidationService,
              private sidebarService: SidebarService, private userPageInformationService: UserPageInformationService) {
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.apiUrl = environment.apiUrl;
    // tslint:disable-next-line:radix
    // this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currency = this.currencyService.getCurrency().code;
    this.load();
    this.loadFormOptions();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  recipeItemLocationQuantity(event): void {
    // @ts-ignore
    const numericValue = this.workOrder.variant.replace('/api/stock_variants/', '');
    this.http.get(StockVariantModel.IRI, {id: numericValue}).subscribe((response: StockVariantModel[]) => {
      this.onVariantSelect(response['hydra:member'][0]).then();
    });
  }

  saveAndCloseSidebar(): void {
    this.sidebarService.toggle();
  }

  load(): void {
    this.loading = true;
    if (this.id) {
      this.http.get(`${WorkOrderModel.IRI}/${this.id}`).subscribe((response: WorkOrderModel) => {
        this.loading = false;
        this.workOrder = response;
        this.workOrder.deadline = new Date(this.workOrder.deadline);
        // @ts-ignore
        this.isTrackByLot = this.workOrder.stock.trackType === 'LOT';
        if (this.isTrackByLot && this.workOrder.expirationDate) {
          this.workOrder.expirationDate = new Date(this.workOrder.expirationDate);
        }

        this.workOrder.stock = response.stock;
        this.variants.push(this.workOrder.variant);
        this.variant = this.workOrder.variant;
        this.workOrder.variant = this.workOrder.variant['@id'];

        // @ts-ignore
        if (this.workOrder.invoice) {
          // @ts-ignore
          this.invoiceCode = this.workOrder.invoice.code;
          // @ts-ignore
          this.invoiceId = this.workOrder.invoice.id;
          // @ts-ignore
          this.workOrder.invoice = this.workOrder.invoice['@id'];
        }
        /*
        // @ts-ignore
        this.workOrder.materials.map((item, i) => {
          this.autoCompleteValues.push(item.variant);
          // @ts-ignore
          this.currencies.push(item.variant.stock.currency?.code);
          // @ts-ignore
          this.workOrder.materials[i].variant = item.variant;
        });
        // @ts-ignore
        this.workOrder.operations.map((item, i) => {
          // @ts-ignore
          this.workOrder.operations[i].resource = item.resource['@id'];
          // @ts-ignore
          this.workOrder.operations[i].operation = item.operation['@id'];
          // @ts-ignore
          this.workOrder.operations[i].totalCost = item.cost * item.plannedTime;

          const dependencies = [];

          item.dependencies.map(dependency => {
            dependencies.push(dependency['@id']);
          });

          // @ts-ignore
          this.workOrder.operations[i].dependencies = dependencies;

        });
        */
        this.loading = false;
      });
    } else {
      this.http.get(`${WorkOrderModel.IRI}/work_order_number`).subscribe((response: WorkOrderModel) => {
        this.workOrder = {
          ...this.workOrder,
          ...{code: response.code, series: response.series, number: response.number, deadline: new Date(response.deadline)},
        };

        if (this.data) {
          this.workOrder.deadline = new Date(this.data[0].expectedDate);
        }
        this.workOrder.status = 'NOT_STARTED';

        if (history.state.invoice !== undefined) {
          // @ts-ignore
          this.workOrder.invoice = history.state.invoice;
        }

        if (history.state.quantity !== undefined) {
          this.workOrder.plannedQuantity = history.state.quantity;
        }

        if (history.state.invoiceItem !== undefined) {
          // @ts-ignore
          this.workOrder.invoiceItem = history.state.invoiceItem;
        }

        if (history.state.description !== undefined) {
          this.workOrder.description = history.state.description;
        }

        if (history.state.variant !== undefined) {
          this.http.get(`${history.state.variant['@id']}`).subscribe((res) => {
            this.variant = res;
            this.onVariantSelect(res).then(r => {
            });
          });
          // tslint:disable-next-line:no-shadowed-variable
          /*
          this.http.get(history.state.variant['@id']).subscribe((response: StockVariantModel) => {
            this.onVariantSelect(response);
            this.variant = response;

            if (history.state.invoiceItem !== undefined) {
              this.getStockStatus();
            }
          });
          */
        }

        this.loading = false;
      });
    }
  }

  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {name: event.query,  'stock.isActive': 1}).subscribe((response: StockVariantModel[]) => {
      this.variants = response['hydra:member'];
    });
  }

  async onVariantSelect(event): Promise<void> {
    this.lotTrackingView = false;
    this.recipeItemsNotAvailable = false;
    this.operationItemsNotAvailable = false;
    this.loadingRecipeItems = true;
    this.loadingOperationItems = true;
    this.workOrder.materials = [];
    this.workOrder.operations = [];
    this.workOrder.subtotal = 0;
    this.unitName = event.stock.unit.name;
    // @ts-ignore
    if (event.stock.isTrackByLot === true) {
      this.lotTrackingView = true;
    }

    this.workOrder.barcode = event.barcode ? event.barcode : '0000' + '' + this.workOrder.number;
    this.workOrder.stock = event.stock['@id'];
    this.workOrder.variant = event['@id'];
    this.workOrder.planning = false;
    this.workOrder.plannedQuantity = 1;
    this.workOrder.materialStatus = 'AVAILABLE';
    this.workOrder.itemStatus = 'AVAILABLE';
    this.lotTrackingItemView = false;
    event.variantRecipes[0].variantRecipeItems.map((item, i) => {
      const material = new WorkOrderMaterialModel();
      material.batchesData = [];
      if (item.stock.isTrackByLot === true) {
        this.lotTrackingItemView = true;
        this.http.get(StockQuantityBatchModel.IRI, {
          'variant.id': item.variant.id
        }).subscribe(response => {

          // @ts-ignore
          response['hydra:member'].map((batch) => {
            const newBatch = {
              batch: batch['@id'],
              barcode: batch.barcode,
              amount: batch.amount
            };
            material.batchesData.push(newBatch);
          });
          /*
          material.batchesData = response['hydra:member'];
           */
        });
      }else {
        delete  material.batchesData;
        delete material.batches;
      }
      material.plannedQuantity = this.workOrder.plannedQuantity;
      material.totalQuantity = material.plannedQuantity * item.amountWithWastage;
      material.wastage = item.amountWithWastage;

      const findIndex = item.stock.stockQuantities.findIndex((obj) => obj.location === '/api/locations/' + this.getLocationId());
      material.totalQuantity = material.totalQuantity + (material.totalQuantity / 100 * material.wastage);
      material.cost = item.cost;
      material.notes = item.note;
      material.variant = item.variant;
      material.status = '';
      material.itemStatus = 'AVAILABLE';
      material.expectedDate = this.workOrder.deadline;
      material.createWorkOrder = true;
      material.unitCost = material.totalQuantity * item.cost;
      material.stockQuantity = item.stock.stockQuantities[findIndex].amount;
      material.needStockQuantity = material.totalQuantity;
      // @ts-ignore
      this.workOrder.materials.push(material);
      this.autoCompleteValues[i] = item.stock;
      this.currencies.push(item.currency.code);
      this.workOrder.subtotal += material.unitCost;
    });
    this.recalculateMaterials(this.workOrder.plannedQuantity);
    this.loadingRecipeItems = false;
    /*
   await this.http.get(`${event['@id']}/recipes`).toPromise().then((response) => {
     if (response['hydra:totalItems'] < 1) {
       this.recipeItemsNotAvailable = true;
       this.loadingRecipeItems = false;
     }

     response['hydra:member'].map((item, i) => {
     });
   }).catch(() => {
     this.recipeItemsNotAvailable = true;
     this.loadingRecipeItems = false;
   });

   this.http.get(`${event['@id']}/operations`).toPromise().then((response) => {
     if (response['hydra:totalItems'] < 1) {
       this.operationItemsNotAvailable = true;
       this.loadingRecipeItems = false;
     }

     response['hydra:member'].map((item, i) => {
       const operation = new WorkOrderOperationModel();
       operation.plannedTime = item.time;
       operation.cost = item.cost;
       operation.resource = item.resource['@id'];
       operation.operation = item.operation['@id'];
       operation.totalCost = item.totalCost * operation.plannedTime;
       operation.isActive = item.isActive;
       operation.notes = item.notes;

       item.dependencies.map(dependency => {
         operation.dependencies.push(`/api/operations/${dependency.id}`);
       });

       // @ts-ignore
       this.workOrder.operations.push(operation);
       this.workOrder.subtotal += item.totalCost;
     });

     this.loadingOperationItems = false;
   }).catch(() => {
     this.operationItemsNotAvailable = true;
     this.loadingOperationItems = false;
   });
    */
  }

  recalculateMaterials(event): void {
    this.isSaved = false;
    this.workOrder.plannedQuantity = parseFloat(event);
    this.workOrder.materials.map((item, i) => {
      this.workOrder.materials[i].itemStatus = 'AVAILABLE';
      this.workOrder.materialStatus = 'AVAILABLE';
      this.workOrder.materials[i].totalQuantity = this.workOrder.materials[i].wastage * this.workOrder.plannedQuantity;
      if (this.workOrder.materials[i].totalQuantity > this.workOrder.materials[i].stockQuantity) {
        // @ts-ignore
        this.workOrder.materials[i].itemStatus = 'NOT_AVAILABLE';
        this.workOrder.materialStatus = 'NOT_AVAILABLE';
        this.isSaved = true;
      }
    });
  }

  loadFormOptions(): void {
    /*
    this.http.get(ResourceModel.IRI, {isActive: true}).subscribe((response: ResourceModel[]) => {
      this.resources = response['hydra:member'];
    });

    this.http.get(OperationModel.IRI, {isActive: true}).subscribe((response: OperationModel[]) => {
      this.operations = response['hydra:member'];
    });
*/
    this.http.get(LocationModel.IRI + '/subscriptionControl', {isActive: true}).subscribe(response => {
      this.locations = response;
      if (this.locations.length > 0) {
        this.workOrder.source = this.locations[0]['@id'];
        this.workOrder.target = this.locations[0]['@id'];
        this.targetId = this.locations[0].id;
      }
    });
  }

  async save(): Promise<void> {
    this.errors = [];

    if (this.workOrder.id === 0) {
      delete this.workOrder.id;
      delete this.workOrder['@id'];
    }
    this.canSave = false;
    this.workOrder.materials.map((item, i) => {
      this.workOrder.materials[i].variant = item.variant['@id'];
      if (typeof item.batchIndex !== 'undefined' && item.batchIndex >= 0 && item.batchesData) {
        // item.batches özelliğini her zaman bir dizi olarak ayarla
        item.batches = Array.isArray(item.batches) ? item.batches : [];

        // Eğer item.batchesData dizisinin boyutu item.batchIndex'ten büyükse, ilgili öğeyi ekleyin
        if (item.batchIndex < item.batchesData.length) {
          item.batches.push(item.batchesData[item.batchIndex]);
        }
      }
    });
    setTimeout(() => {
      if (this.canSave === false){
        this.submitted = true;
        if (this.id) {
          this.workOrder.stock = this.workOrder.stock['@id'];
          this.workOrder.source = this.workOrder.source['@id'];
          this.workOrder.target = this.workOrder.target['@id'];
          this.http.put(`${WorkOrderModel.IRI}/${this.id}`, this.workOrder).then((response: WorkOrderModel) => {
            if (response) {
              this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
            }
          }).catch(error => {
          });
        }
        else {
          this.http.post(WorkOrderModel.IRI, this.workOrder).then((response: WorkOrderModel) => {
            if (response) {
              this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
              // this.router.navigate([`/work-orders/${response.id}/edit`]);
            }
          }).catch(error => {
          });
        }
        this.sidebarService.toggle();
        this.submitted = false;
      }
    }, 1000);
  }

  removeMaterial(i: number): void {
    this.workOrder.materials.splice(i, 1);
    this.calculateTotalCost();
  }

  removeOperation(i: number): void {
    this.workOrder.operations.splice(i, 1);
    this.calculateTotalCost();
  }

  calculateTotalCost(): void {
    let materialCost = 0;
    let operationCost = 0;
    let qty = 1;

    // @ts-ignore
    this.workOrder.materials.map((item: WorkOrderMaterialModel) => {
      materialCost += item.cost;
    });

    // @ts-ignore
    this.workOrder.operations.map((item: WorkOrderOperationModel) => {
      operationCost += item.totalCost;
    });

    if (this.workOrder.plannedQuantity !== undefined || this.workOrder.plannedQuantity !== 0) {
      qty = this.workOrder.plannedQuantity;
    }

    this.workOrder.subtotal = (materialCost + operationCost) * qty;
  }

  getStockStatusFirst(): void {
    if (this.workOrder.plannedQuantity !== undefined || this.workOrder.plannedQuantity !== 0) {
      // tslint:disable-next-line:max-line-length
      this.http.get(`${this.workOrder.variant}/recipe/status?qty=${this.workOrder.plannedQuantity}&location=${this.targetId}`).subscribe((response) => {
        // @ts-ignore
        this.workOrder.materials.map((item: WorkOrderMaterialModel, i) => {
          // @ts-ignore
          this.workOrder.materials[i].status = response['hydra:member'][i].stock.status;
          // @ts-ignore
          this.workOrder.materials[i].expectedDate = response['hydra:member'][i].stock.expectedDate;
        });
      });
    }
  }

  getStockStatus(): void {
    if (this.workOrder.plannedQuantity !== undefined || this.workOrder.plannedQuantity !== 0) {
      this.workOrder.materials.map((item: WorkOrderMaterialModel, i) => {
        // @ts-ignore
        if (item.stockQuantity >= item.needStockQuantity) {
          this.workOrder.materials[i].status = 'AVAILABLE';
        } else {
          this.workOrder.materials[i].status = 'NOT_AVAILABLE';
        }
      });
    }
  }

  buy(id: number): void {
    const ref = this.dialog.open(QuickInvoiceCreateComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('PURCHASE'),
    });

    ref.onClose.subscribe(data => {
      if (data !== undefined && data.saved !== undefined && data.saved) {
      }
    });
  }

  make(id: number): void {
    const ref = this.dialog.open(QuickWorkOrderCreateComponent, {
      data: {id},
      width: '50vw',
      header: this.translate.instant('WORK_ORDER_CREATE'),
    });

    ref.onClose.subscribe(data => {
      if (data !== undefined && data.saved !== undefined && data.saved) {
      }
    });
  }

  calculateItemAmount(): void {
    // @ts-ignore
    this.workOrder.materials.map((material: WorkOrderMaterialModel, key) => {
      // @ts-ignore
      this.workOrder.materials[key].totalQuantity = material.plannedQuantity * this.workOrder.plannedQuantity;
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      this.workOrder.materials[key].totalQuantity = this.workOrder.materials[key].totalQuantity + (this.workOrder.materials[key].totalQuantity / 100 * this.workOrder.materials[key].wastage);
    });
  }

  addOperation(): void {
    const operation = new WorkOrderOperationModel();
    operation.resource = this.resources[0]['@id'];
    operation.operation = this.operations[0]['@id'];
    operation.cost = this.resources[0].price;

    // @ts-ignore
    this.workOrder.operations.push(operation);

    this.operationItemsNotAvailable = false;
  }

  addMaterial(): void {
    const material = new WorkOrderMaterialModel();
    material.status = '';

    // @ts-ignore
    this.workOrder.materials.push(material);

    this.recipeItemsNotAvailable = false;
  }

  onMaterialSelect(event: StockVariantModel, key: number): void {

    const material = this.workOrder.materials[key];

    if (material instanceof WorkOrderMaterialModel) {
      material.plannedQuantity = 1;
      material.totalQuantity = 1;
      material.wastage = 0;
      material.unitCost = event.averageCost;
      material.cost = event.averageCost;
      material.status = '';
      material.createWorkOrder = event.stock.isMaking;

      this.autoCompleteValues[key].price = event.averageCost;

      this.workOrder.materials[key] = material;
      // @ts-ignore
      this.currencies.push(event.stock.currency.code);

      this.setMaterialStatus(event['@id'], material.plannedQuantity, key);
    }
  }

  setMaterialPrice(key: number): void {
    const material = this.workOrder.materials[key];

    if (material instanceof WorkOrderMaterialModel) {
      material.cost = material.unitCost * material.totalQuantity;

      this.workOrder.materials[key] = material;

      this.setMaterialStatus(material.variant.toString(), material.totalQuantity, key);

    }
  }

  getLocationId(): number {
    let id = 0;

    this.locations.map(item => {
      if (item['@id'] === this.workOrder.source) {
        id = item.id;
      }
    });

    return id;
  }

  setMaterialStatus(variant: string, qty: number, i: number): void {
    if (variant !== undefined && qty !== undefined) {
      this.http.get(`${variant}/status?qty=${qty}&location=${this.getLocationId()}`).subscribe(response => {
        // @ts-ignore
        this.workOrder.materials[i].status = response.status;
        // @ts-ignore
        this.workOrder.materials[i].expectedDate = response.stockExpectedDate;
      });
    }
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

  print(): void {
    this.printing = true;
    this.http.get(`${WorkOrderModel.IRI}/${this.workOrder.id}/pdf`).subscribe(response => {
      this.printing = false;
      window.open(response.response, '_blank');
    });
  }

  async deleteWorkOrder(): Promise<void> {
    this.deleting = true;

    await this.http.delete(`${WorkOrderModel.IRI}/${this.workOrder.id}`).then(() => {
      this.router.navigate(['work-orders']);
    });

    this.deleting = false;
  }

  createBatch(event, i, row): void {
    console.log(row.batches);
    const findIndex = row.batchesData.findIndex(obj => obj.batch === event.value.batch);
    row.batchIndex = findIndex;
  }

  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'WorkOrderCreate';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'WorkOrderCreate'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }
}
