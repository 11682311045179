import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {LazyLoadEvent} from 'primeng/api';
import {RequestStatus, StockRequestModel} from '../../../model/stock-request.model';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {LocationModel} from '../../../model/location.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-stock-request',
  templateUrl: './stock-request.component.html',
  styleUrls: ['./stock-request.component.scss']
})
export class StockRequestComponent implements OnInit {
  request: StockRequestComponent[] = [];
  locations: StockRequestModel[] = [];
  destinations: StockRequestModel[] = [];
  loading: boolean;
  total: number;
  requestStatus: RequestStatus;
  parameters = {
    code: '',
    requestDate: '',
    location: '',
    destination: '',
    requestStatus: [
      RequestStatus.Seen,
      RequestStatus.Registered,
      RequestStatus.Rejected
    ].join(','),
    page: 1,
    itemsPerPage: 20,
    status: ''
  };
  requestStatuses: [
    { name: any; value: RequestStatus},
    { name: any; value: RequestStatus},
    { name: any; value: RequestStatus}
  ];
  constructor(private http: HttpService,
              private translate: TranslateService,
              private flashMessageService: FlashMessageService,
              private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(StockRequestModel.IRI).subscribe((response: StockRequestModel[]) => {
      this.request = response['hydra:member'];
    });
    this.loadLocation();
  }
  loadLocation(): void{
    this.http.get(LocationModel.IRI + '/subscriptionControl', { pagination: false}).subscribe(response => {
      this.locations = response;
    });
  }
  changeStatus = (event, id) => {
    this.loading = true;
    this.http.put(`${StockRequestModel.IRI}/${id}`, { status: event.value}).then((response: StockRequestModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      this.loading = false;
    });
  }
  load(event: LazyLoadEvent = null ): void {
    this.loading = true;
    this.requestStatuses = [
      {name: this.translate.instant(RequestStatus.Seen), value: RequestStatus.Seen},
      {name: this.translate.instant(RequestStatus.Registered), value: RequestStatus.Registered},
      {name: this.translate.instant(RequestStatus.Rejected), value: RequestStatus.Rejected},

    ];
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1 ;
    }
    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    let parameters = {...this.parameters};
    if (this.parameters.requestDate) {
      const start = new Date(this.parameters.requestDate[0]);
      const end = new Date(this.parameters.requestDate[1]);
      parameters = {...parameters, ...{
          'createdAt[strictly_after]': `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
          'createdAt[strictly_before]': `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        }
      };
    }
    this.parameters = parameters;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v != ''));
    this.http.get(StockRequestModel.IRI, this.parameters).subscribe(response => {
      this.request = response['hydra:member'];
      this.total = response['hydra:totalItems'];
      this.loading = false;
    });
  }

    reset(): void {
    this.router.navigate([`/stock-requests`]);
  }

}
