import {ApiResourceModel} from './api-resource.model';
import {StockVariantModel} from './stock-variant.model';
import {VariantRecipeItemModel} from './variant-recipe-item.model';

export class VariantRecipeModel extends ApiResourceModel{
  public static IRI = '/api/variant_recipes';
  public variant: StockVariantModel|string;
  public totalWeight: number;
  public servingSize: number;
  public variantRecipeItems: VariantRecipeItemModel [] = [];
}
