import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';

export class IntegrationModel extends ApiResourceModel {
  public static IRI = '/api/integrations';
  name: string;
  logo: string;
  location: LocationModel;
  isActive: boolean;
  type: string;
}
