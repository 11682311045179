<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div style="
width: 60%;position: absolute;top:0px;bottom: 0px;
background-image: url('assets/currency.jpeg');
background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: -1;
    opacity: 0.1;
"></div>
<div class="p-grid" style="width: 100%">
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-6 p-lg-offset-0 p-sm-offset-0 p-xl-offset-3 p-md-offset-0">
    <div class="left-area">
      <div class="p-p-3 p-text-center">
        <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
      </div>
      <div class="p-p-3 p-text-left">
        <h1 style="font-size: 45px;font-weight: 600;">
          {{ 'COMPANY_INFORMATION_PAGE_TITLE' | translate}}
          <p [style]="{fontSize: '16px',textAlign: 'justify',fontWeight: '400'}">
            {{ 'COMPANY_INFORMATION_PAGE_DESCRIPTION' | translate}}
          </p>
        </h1>
      </div>
      <div class="p-col-12" style="background: #ffffff9e;
    padding: 40px 8px;
    border-radius: 10px;">
        <div class="p-col-12">
          <label for="currency">{{'CURRENCY' | translate}}</label>
          <p-dropdown [style]="{marginTop:'10px'}" [options]="currencies" [(ngModel)]="company.currency" optionLabel="code"
                      id="currency" name="currency" optionValue="@id"></p-dropdown>
          <small class="p-error">{{ errors['currency'] }}</small>
        </div>
        <div class="p-col-12">
          <label for="currency">{{'COUNTRY' | translate}}</label>
          <p-dropdown [style]="{marginTop:'10px'}" [filter]="true" [options]="countries" [(ngModel)]="company.country"
                      optionLabel="name" id="country" name="country" optionValue="@id"></p-dropdown>
          <small class="p-error">{{ errors['currency'] }}</small>
        </div>
        <div class="p-p-3 p-text-left" [style]="{marginTop: '5%'}">
          <p-button label="İleri" (onClick)="nextPage()" [style]="{float: 'right', marginTop:'100px'}"
                    icon="pi pi-angle-right" iconPos="right">

          </p-button>
          <p-button label="Geri" (onClick)="previousPage()" [style]="{float: 'right', marginTop:'100px',marginRight:'10px'}"
                    icon="pi pi-angle-left" iconPos="left"></p-button>

        </div>

      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
