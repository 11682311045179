import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryCurrency'
})
export class CountryCurrencyPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) {
      return 0;
    }
  }

}
