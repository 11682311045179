import {Component, OnInit} from '@angular/core';
import {ContactModel} from '../../../model/contact.model';
import {LocationModel} from '../../../model/location.model';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../../service/http.service';
import {ContactService} from '../../../service/contact.service';
import {LocationService} from '../../../service/location.service';
import {UtilityService} from '../../../service/utility.service';
import {TabMenuModule} from 'primeng/tabmenu';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {StockModel} from '../../../model/stock.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-last-purchase-reports',
  templateUrl: './last-purchase-reports.component.html',
  styleUrls: ['./last-purchase-reports.component.scss']
})
export class LastPurchaseReportsComponent implements OnInit {
  apiUrl: string;
  loading: boolean;
  data = [];
  parameters = {
    id: '',
    invoiceId: '',
    contact: '',
    location: '',
    name: '',
    startDate: '',
    endDate: '',
    itemsPerPage: 20
  };
  searchContacts: ContactModel[] = [];
  locations: LocationModel[] = [];
  tableLoading: boolean;
  items: MenuItem[];
  activeItem: MenuItem;
  displayPopupDetails = false; // Pop-up görünürlüğünü kontrol etmek için
  popupDetails: {
    tax: 0,
    total: 0,
    currency: '',
    discount: 0,
    subTotal: 0,
    phone: '',
    documentDate: '',
    documentNumber: '',
    code: '',
    location: '',
    name: '',
    email: '',
    invoiceDetails: []
  }; // Pop-up tablosunun içeriği
  contactName: any;
  dateRange: string;
  stocks: StockVariantModel[] = [];
  searched: boolean;
  first = 0;
  select: boolean;
  detail: boolean;
  subCategoryName = [];
  mCategoryName = [];
  name = [];
  fContactName = [];
  locationName = [];
  date = [];
  noSelected: boolean;


  constructor(private translate: TranslateService, private http: HttpService,
              private contactService: ContactService, private locationService: LocationService,
              private utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.searched = false;
    this.noSelected = false;
    this.items = [
      {
        label: this.translate.instant('PURCHASING_REPORT_BY_SUPPLIER'),
        title: 'PURCHASING_REPORT_BY_SUPPLIER',
        command: (event) => {
          this.activeItem = this.items[0];
          this.tabMenuSelect(0);
        },
      },
      {
        label: this.translate.instant('PURCHASING_REPORT_BY_ITEM'),
        title: 'PURCHASING_REPORT_BY_ITEM',
        command: (event) => {
          this.activeItem = this.items[1];
          this.tabMenuSelect(1);
        },
      },
      {
        label: this.translate.instant('PURCHASING_REPORT_BY_INVOICE'),
        title: 'PURCHASING_REPORT_BY_INVOICE',
        command: (event) => {
          this.activeItem = this.items[2];
          this.tabMenuSelect(2);
        },
      },
      {
        label: this.translate.instant('INFLATION_ANALYSIS_REPORT'),
        title: 'INFLATION_ANALYSIS_REPORT',
        command: (event) => {
          this.activeItem = this.items[3];
          this.tabMenuSelect(3);
        },
      },
      {
        label: this.translate.instant('RETURN_INVOICE_REPORT'),
        title: 'RETURN_INVOICE_REPORT',
        command: (event) => {
          this.activeItem = this.items[4];
          this.tabMenuSelect(4);
        },
      },
      {
        label: this.translate.instant('ABC_ANALYSIS'),
        title: 'ABC_ANALYSIS',
        command: (event) => {
          this.activeItem = this.items[5];
          this.tabMenuSelect(5);
        },
      }
    ];
    this.activeItem = this.items[0];
    this.searchContacts = this.contactService.getSearchContacts({pagination: false});
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
      this.loading = false;
    });
  }

  load(event: LazyLoadEvent = null): void {
    if (this.select) {
      this.noSelected = false;
      this.tableLoading = true;
      if (this.activeItem === this.items[5]) {
        this.parameters[`order[documentDate]`] = 'DESC';
      }
      // @ts-ignore
      if (event?.sortField) {
        this.parameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
      }
      // @ts-ignore
      this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
      this.http.get('/api/reports/purchase-report', this.parameters).subscribe(response => {
        this.data = response;
        this.extractCategoryNames();
        this.tableLoading = false;
      });
      this.parameters[`order[${event.sortField}]`] = '';
    } else {
      this.noSelected = true;
    }
  }

  search(): void {
    if (this.select) {
      this.searched = true;
    } else {
      this.searched = false;
    }
  }

  tabMenuSelect(id): void {

    this.activeItem = this.items[id];
    if (id === 0) {
      this.searched = false;
      this.select = false;
      this.data = [];
      this.parameters = {
        id: '',
        invoiceId: '',
        contact: '',
        location: '',
        name: '',
        startDate: '',
        endDate: '',
        itemsPerPage: 20
      };
      this.dateRange = '';

    }
    if (id === 1) {
      this.searched = false;
      this.select = false;
      this.first = 0;
      this.data = [];
      this.detail = false;
      this.parameters = {
        id: '',
        invoiceId: '',
        contact: '',
        location: '',
        name: '',
        startDate: '',
        endDate: '',
        itemsPerPage: 20
      };
      this.dateRange = '';
    }
    if (id === 2) {
      this.searched = false;
      this.select = false;
      this.first = 0;
      this.data = [];
      this.parameters = {
        id: '',
        invoiceId: '',
        contact: '',
        location: '',
        name: '',
        startDate: '',
        endDate: '',
        itemsPerPage: 20
      };
      this.dateRange = '';
    }
    if (id === 3) {
      //  this.loading = true;
    }
  }

  changeInvoiceInput(event): void {
    this.parameters.invoiceId = event.target.value;
  }

  changeContact(event): void {
    this.parameters.contact = event.value;
  }

  changeLocation(event): void {
    this.parameters.location = event.value;
    if ((this.activeItem === this.items[1] || this.activeItem === this.items[5]) && !(this.detail)) {
      if ((this.parameters.startDate || this.parameters.endDate) && this.parameters.location) {
        this.select = true;
      }
    } else {
      this.select = true;
    }
  }

  onStartChange(event): void {
    const start = event[0];
    const end = event[1];
    this.parameters.startDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    this.parameters.endDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
    if (this.activeItem === this.items[1] && !(this.detail)) {
      if ((this.parameters.startDate || this.parameters.endDate) && this.parameters.location) {
        this.select = true;
      }
    } else {
      this.select = true;
    }
  }

  showPopupDetails(rowData): void {
    this.popupDetails = rowData; // Popup tablosunun içeriğini ayarla
    this.displayPopupDetails = true; // Popup'ı görünür yap
  }
  searchStock = (event) => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel) => {
      this.stocks = response['hydra:member'];
    });
  }

  async onVariantSelect(event: StockModel): Promise<void> {
    this.parameters.id = event.id;
    this.first = 0;
  }

  handlePageChange(event): void {
    this.first = event.first;
  }

  extractCategoryNames(): void {
    // Tüm categoryName değerlerini çıkar
    // this.subCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.categoryName);
    // this.mCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.mCatName);
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedCat: any[] = []; // Seçilen öğeleri saklamak için bir dizi
    const selectedMCat: any[] = []; // Seçilen öğeleri saklamak için bir dizi
    const selectedName: any[] = []; // Seçilen öğeleri saklamak için bir dizi
    const selectedContactName: any[] = []; // Seçilen öğeleri saklamak için bir dizi
    const selectedLocation: any[] = []; // Seçilen öğeleri saklamak için bir dizi
    const selectedDate: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if (!uniqueCategoryNames.has(item.categoryName)) {
        // Bu kategori daha önce eklenmediyse, ekleyin
        uniqueCategoryNames.add(item.categoryName);
        selectedCat.push(item.categoryName);
      }
      this.subCategoryName = selectedCat.sort();
    }
    selectedCat.unshift(this.translate.instant('ALL_CATEGORIES'));

    for (const item of this.data[0].stocksInvoiceDetail) {
      if (!uniqueCategoryNames.has(item.mCatName)) {
        // Bu kategori daha önce eklenmediyse, ekleyin
        uniqueCategoryNames.add(item.mCatName);
        selectedMCat.push(item.mCatName);
      }
      this.mCategoryName = selectedMCat.sort();
    }
    selectedMCat.unshift(this.translate.instant('ALL_CATEGORIES'));

    for (const item of this.data[0].stocksInvoiceDetail) {
      if (!uniqueCategoryNames.has(item.name)) {
        // Bu kategori daha önce eklenmediyse, ekleyin
        uniqueCategoryNames.add(item.name);
        selectedName.push(item.name);
      }
      this.name = selectedName.sort();
    }
    selectedName.unshift(this.translate.instant('ALL_ITEMS'));
    for (const item of this.data[0].stocksInvoiceDetail) {
      if (!uniqueCategoryNames.has(item.contactName)) {
        // Bu kategori daha önce eklenmediyse, ekleyin
        uniqueCategoryNames.add(item.contactName);
        selectedContactName.push(item.contactName);
      }
      this.fContactName = selectedContactName.sort();
    }
    selectedContactName.unshift(this.translate.instant('ALL_CONTACT'));
    for (const item of this.data[0].stocksInvoiceDetail) {
      if (!uniqueCategoryNames.has(item.location)) {
        // Bu kategori daha önce eklenmediyse, ekleyin
        uniqueCategoryNames.add(item.location);
        selectedLocation.push(item.location);
      }
      this.locationName = selectedLocation.sort();
    }
    for (const item of this.data[0].stocksInvoiceDetail) {
      if (!uniqueCategoryNames.has(item.documentDate)) {
        // Bu kategori daha önce eklenmediyse, ekleyin
        uniqueCategoryNames.add(item.documentDate);
        selectedDate.push(item.documentDate);
      }
      this.date = selectedDate.sort();
    }
    selectedDate.unshift(this.translate.instant('ALL_DATES'));
  }

  extractCat($event): void {
    // Tüm categoryName değerlerini çıkar
    // this.subCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.categoryName);
    // this.mCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.mCatName);
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedCat: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if ($event === this.translate.instant('ALL_CATEGORIES')) {
        this.extractCategoryNames();
      } else {
        if (item.mCatName === $event) {
          if (!uniqueCategoryNames.has(item.categoryName)) {
            // Bu kategori daha önce eklenmediyse, ekleyin
            uniqueCategoryNames.add(item.categoryName);
            selectedCat.push(item.categoryName);
          }
        }
        this.subCategoryName = selectedCat.sort();
      }
    }
    selectedCat.unshift(this.translate.instant('ALL_CATEGORIES'));
  }

  extractmCat($event): void {
    // Tüm categoryName değerlerini çıkar
    // this.subCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.categoryName);
    // this.mCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.mCatName);
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedMCat: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if ($event === this.translate.instant('ALL_CATEGORIES')) {
        this.extractCategoryNames();
      } else {
        if (item.categoryName === $event) {
          if (!uniqueCategoryNames.has(item.mCatName)) {
            // Bu kategori daha önce eklenmediyse, ekleyin
            uniqueCategoryNames.add(item.mCatName);
            selectedMCat.push(item.mCatName);
          }
        }
        this.mCategoryName = selectedMCat.sort();
      }
    }
    selectedMCat.unshift(this.translate.instant('ALL_CATEGORIES'));
  }

  extractName($event): void {
    // Tüm categoryName değerlerini çıkar
    // this.subCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.categoryName);
    // this.mCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.mCatName);
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedName: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if ($event === this.translate.instant('ALL_CONTACT')) {
        this.extractCategoryNames();
      } else {
        if (item.contactName === $event) {
          if (!uniqueCategoryNames.has(item.name)) {
            // Bu kategori daha önce eklenmediyse, ekleyin
            uniqueCategoryNames.add(item.name);
            selectedName.push(item.name);
          }
          this.name = selectedName.sort();
        }
      }
    }
    selectedName.unshift(this.translate.instant('ALL_ITEMS'));
  }
  extractNameDate($event): void {
    // Tüm categoryName değerlerini çıkar
    // this.subCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.categoryName);
    // this.mCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.mCatName);
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedName: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if ($event === this.translate.instant('ALL_DATES')) {
        this.extractCategoryNames();
      } else {
        if (item.documentDate === $event) {
          if (!uniqueCategoryNames.has(item.name)) {
            // Bu kategori daha önce eklenmediyse, ekleyin
            uniqueCategoryNames.add(item.name);
            selectedName.push(item.name);
          }
          this.name = selectedName.sort();
        }
      }
    }
    selectedName.unshift(this.translate.instant('ALL_ITEMS'));
  }

  extractContact($event): void {
    // Tüm categoryName değerlerini çıkar
    // this.subCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.categoryName);
    // this.mCategoryName = this.data[0].stocksInvoiceDetail.map(item => item.mCatName);
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedContactName: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if ($event === this.translate.instant('ALL_ITEMS')) {
        this.extractCategoryNames();
      } else {
        if (item.name === $event) {
          if (!uniqueCategoryNames.has(item.contactName)) {
            // Bu kategori daha önce eklenmediyse, ekleyin
            uniqueCategoryNames.add(item.contactName);
            selectedContactName.push(item.contactName);
          }
          this.fContactName = selectedContactName.sort();
        }
      }
    }
    selectedContactName.unshift(this.translate.instant('ALL_CONTACT'));
  }
  extractDate($event): void{
    const uniqueCategoryNames = new Set<string>(); // Tekrarlanmayan categoryName'leri saklamak için bir Set kullanın
    const selectedDate: any[] = []; // Seçilen öğeleri saklamak için bir dizi

    for (const item of this.data[0].stocksInvoiceDetail) {
      if ($event === this.translate.instant('ALL_DATES')) {
        this.extractCategoryNames();
      } else {
        if (item.name === $event) {
          if (!uniqueCategoryNames.has(item.documentDate)) {
            // Bu kategori daha önce eklenmediyse, ekleyin
            uniqueCategoryNames.add(item.documentDate);
            selectedDate.push(item.documentDate);
          }
          this.date = selectedDate.sort();
        }
      }
    }
    selectedDate.unshift(this.translate.instant('ALL_DATES'));
  }

  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/purchaseReport', parameters)
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'last_purchase_prices_report.xlsx');
      });
  }
  // PDF oluşturma fonksiyonu
  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = content.querySelector('.pdf-button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin


    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'block';
      }
      // @ts-ignore
      pdf.save(`${this.popupDetails.name} ${this.popupDetails.documentNumber}.pdf`); // PDF'i indir
    });
  }

  getRateSum(rows: any[], index: number): number {
    console.log("getRateSum called with rows:", rows, "and index:", index);
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.slice(0, index + 1).reduce((sum, row) => sum + (row.rate || 0), 0);
  }
  getTotalSum(rows: any[], index: number): number {
    if (!rows || rows.length === 0) {
      return 0;
    }
    return rows.slice(0, index + 1).reduce((sum, row) => sum + (row.total || 0), 0);
  }
}

