import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from './http.service';
import {SectorModel} from '../model/sector.model';

@Injectable({
  providedIn: 'root'
})
export class SectorService {
  sectors = new BehaviorSubject<SectorModel[]>(null);

  constructor(private http: HttpService) { }

  getSectors(): Observable<SectorModel[]> {
    return this.sectors.asObservable();
  }

  getSectorsAction(params = {}): void {
    this.http.get(SectorModel.IRI, params).subscribe(response => {
      this.sectors.next(response['hydra:member']);
    });
  }

  async save(sector: SectorModel): Promise<any> {
    return await this.http.post(SectorModel.IRI, sector).then(response => {
      if (response !== undefined) {
        const sectors = this.sectors.getValue();
        sectors.push(response);
        this.sectors.next(sectors);
      }

      return response;
    });
  }

  async update(sector: SectorModel): Promise<any> {
    return await this.http.put(`${SectorModel.IRI}/${sector.id}`, sector).then(response => {
      return response;
    });
  }

  unsubscribe(): void {
    this.sectors.next(null);
    this.sectors.unsubscribe();
  }
}
