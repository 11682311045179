import {Component, OnInit} from '@angular/core';
import {InvoiceModel} from '../../../model/invoice.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ShipmentItemModel} from '../../../model/shipment-item.model';
import {ValidationService} from '../../../service/validation.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CreditModel} from '../../../model/credit.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {StockPriceChanges} from '../../../model/stock-price-changes.model';
import {InvoiceItemModel} from '../../../model/invoice-item.model';
import {ConfirmationService} from 'primeng/api';
import {InventoryModel} from '../../../model/inventory.model';
import {UserPageInformationModel} from '../../../model/user-page-information.model';
import {UserModel} from '../../../model/user.model';
import {UserPageInformationService} from '../../../service/user-page-information.service';


@Component({
  selector: 'app-invoice-receive-manuel',
  templateUrl: './invoice-receive-manuel.component.html',
  styleUrls: ['./invoice-receive-manuel.component.scss']
})
export class InvoiceReceiveManuelComponent implements OnInit {
  showInvoiceDialog: boolean;
  showWaybillDialog: boolean;
  invoice: InvoiceModel = new InvoiceModel();
  invoiceItem: InvoiceModel = new InvoiceModel();
  shipment: ShipmentModel = new ShipmentModel();
  credit: CreditModel = new CreditModel();
  submitted: boolean;
  loading: boolean;
  id: any;
  uploadedFiles: any[] = [];
  documentType: any[] = [];
  currencyLabel: string;
  shipments: ShipmentModel [] = [];
  errors = [];
  totalError = [];
  validationSubscriber: Subscription;
  creditRemember: boolean;
  editAction: boolean;
  discountRateChecked = false;
  discountValueChecked = false;
  priceChange: boolean;
  creditValues: string[] = [];
  priceCreditValues: string[] = [];
  priceCreditValuesOrder: number[] = [];
  priceChangeValues: string[] = [];
  disabledValues: string[] = [];
  changePriceVariants: StockPriceChanges [] = [];
  tableLoading: boolean;
  discountRate: number;
  firstDiscountValues: number[] = [];
  firstDiscountRateValues: number[] = [];
  secondDiscountValues: number[] = [];
  total: number;
  subTotal: number;
  tax: number;
  itemUnitPriceValues: number[] = [];
  itemSubTotalValues: number[] = [];
  itemTaxValues: number[] = [];
  discountValue: number;
  discountRateValue: number;
  variants: StockVariantModel[] = [];
  firstSubTotal: number;
  isDiscount: boolean;
  discountRateValueTotal: number;
  today: Date = new Date();
  minDate: Date;
  activeInventory: InventoryModel;
  isCanBeSend = true;
  isCanBeSelectDate: Date | string;
  agencyNumber = false;
  agencyNumberNotNull = false;
  infoTextShowDisplay = false;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private validationService: ValidationService,
              private flashMessageService: FlashMessageService,
              private translate: TranslateService,
              private confirmationService: ConfirmationService,
              private userPageInformationService: UserPageInformationService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.isDiscount = false;
    this.loading = true;
    this.shipment.documentType = this.route.snapshot.paramMap.get('type');
    this.creditRemember = false;
    this.editAction = false;
    this.documentType = [
      {name: this.translate.instant('INVOICE'), value: 'Invoice'},
      {name: this.translate.instant('DELIVERY_NOTE'), value: 'DeliveryNote'},
    ];
    this.loadInvoice().then();
    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });
    this.getActiveInventory();
  }

  async loadInvoice(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');
    await this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
      this.invoice = response;
      this.total = this.invoice.total;
      this.subTotal = this.invoice.subtotal;
      this.firstSubTotal = this.invoice.subtotal;
      this.tax = this.invoice.tax;
      this.shipment.invoice = this.invoice;
      this.shipment.documentDate = new Date();
      this.shipment.documentNumber = this.invoice.incomingInvoices[0]?.invoiceNumber;
      this.currencyLabel = this.invoice.currency?.symbol;
      this.credit.invoice = this.invoice['@id'];
      this.credit.location = this.invoice.location['@id'];
      this.credit.currency = this.invoice.currency['@id'];
      this.credit.contact = this.invoice.contact['@id'];
      this.invoice.items.map(item => {
        this.itemUnitPriceValues.push(item.unitPrice);
        this.itemSubTotalValues.push(item.subtotal);
        this.itemTaxValues.push(item.tax);
        item.documentQuantity = item.quantity;
        if (item.incomingQuantity === 0) {
          item.incomingQuantity = item.remainingQuantity;
        } else {
          item.incomingQuantity = item.incomingQuantity;
        }
        const shipmentItem = new ShipmentItemModel();
        shipmentItem.invoiceItem = item['@id'];
        shipmentItem.quantity = item.remainingQuantity;
        this.shipment.items.push(shipmentItem);
        this.creditValues.push(item.stock['@id']);
        this.priceCreditValues.push(item.stock['@id']);
      });
      this.http.get(CreditModel.IRI, {invoice: this.id}).subscribe(res => {
        this.loading = false;
        if (res['hydra:totalItems'] > 0) {
          this.priceCreditValues = [];
          this.credit = res['hydra:member'][0];
          this.creditRemember = true;
          this.credit.items.map(item => {
            if (item.type === 'CHANGE_QUANTITY') {
              // @ts-ignore
              this.creditValues.push(item.stock['@id']);
            } else if (item.type === 'CHANGE_PRICE') {
              // @ts-ignore
              this.priceCreditValues.push(item.stock['@id']);
              const findObject = this.invoice.items.findIndex(obj => obj.stock['@id'] === item.stock['@id']);
              if (findObject !== -1) {
                this.priceCreditValuesOrder.push(findObject);
              }
              this.priceChange = true;
            }
          });
        }
      });

    });
    await this.http.get(ShipmentModel.IRI, {invoice: this.id}).subscribe(data => {
      if (data['hydra:totalItems'] > 0) {
        this.shipments = data['hydra:member'];
        this.shipments.map(item => {
          this.shipment.documentType = item.documentType;
          this.shipment.documentDate = new Date(item.documentDate);
          this.shipment.documentNumber = item.documentNumber;
          this.shipment.total = item.total;
          this.shipment.taxTotal = item.taxTotal;
          if (this.shipments[0].paymentDate) {
            this.shipment.paymentDate = new Date(item.paymentDate);
          }
        });
      } else {
        this.editAction = true;
      }
      this.tableLoading = false;

    });
  }

  async save(): Promise<void> {
    // @ts-ignore
    this.invoice.total = parseFloat(this.invoice.total.toFixed(2));
    this.submitted = true;
    this.errors = [];
    if (this.shipment.documentNumber === undefined) {
      this.totalError = [];
      this.totalError.push({documentNumber: 'Döküman Numarası Giriniz'});
      this.submitted = false;
      // tslint:disable-next-line:max-line-length
    } else if (this.shipment.total === undefined || this.invoice.total !== this.shipment.total || this.shipment.documentType === undefined) {
      this.totalError = [];
      this.totalError.push({shipmentTotal: 'Fatura Tutarını Giriniz'});
      this.submitted = false;
    } else {
      this.totalError = [];
      this.invoiceSave().then(async () => {
        this.shipment.invoice = this.invoice['@id'];
        await this.http.post(ShipmentModel.IRI, this.shipment).then((response: ShipmentModel) => {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }).catch(error => {
          this.router.navigate([`/orders/receive-order`]);
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        });
        // tslint:disable-next-line:no-unused-expression
        this.changePriceVariants.map(async changePrice => {
          await this.http.post(StockPriceChanges.IRI, changePrice).subscribe(res => {
          }, 2000);
        });
        /*
         this.credit.items.map(async (item, i) => {
          this.credit.items[i].stock = item.stock['@id'];
          this.credit.items[i].variant = item.variant['@id'];
        });
        if (this.credit.id) {
          await this.http.put(`${CreditModel.IRI}/${this.credit.id}`, this.credit).subscribe(res => {
          });
        } else {
          await this.http.post(CreditModel.IRI, this.credit).subscribe(res => {
          });
        }
         */
        this.submitted = false;
      });
    }
  }

  onshowWaybillDialog(): void {
    this.showWaybillDialog = true;
    this.showInvoiceDialog = false;
  }

  onshowInvoiceDialog(): void {
    this.showInvoiceDialog = true;
    this.showWaybillDialog = false;
  }

  onUpload(event): void {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  rowMarked(row, i): void {
    this.invoice.items[i].marked = true;
  }

  async invoiceSave(): Promise<void> {
    this.loading = true;
    this.invoice.contact = this.invoice.contact['@id'];
    this.invoice.currency = this.invoice.currency['@id'];
    this.invoice.location = this.invoice.location['@id'];
    this.invoice.credits = this.invoice.credits['@id'];
    this.invoice.discountRate = parseFloat(String(this.invoice.discountRate));
    this.invoice.items.map((item, key) => {
      this.invoice.items[key].currency = this.invoice.currency;
      this.invoice.items[key].stock = this.invoice.items[key].stock['@id'];
      this.invoice.items[key].variant = this.invoice.items[key].variant['@id'];
      this.invoice.items[key].taxRate = this.invoice.items[key].taxRate['@id'];
      if (this.invoice.items[key].purchaseOption){
        this.invoice.items[key].purchaseOption = this.invoice.items[key].purchaseOption['@id'];
      }
       });
    this.invoice.incomingInvoices.map((inv, key) => {
      this.invoice.incomingInvoices[key] = this.invoice.incomingInvoices[key]['@id'];
    });
    this.invoice.shipments.map((shipmentItem, key) => {
      this.invoice.shipments[key] = this.invoice.shipments[key]['@id'];
    });
    await this.http.put(`${InvoiceModel.IRI}/${this.invoice.id}`, this.invoice).then();
  }

  deleteItem(i): void {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_YOU_SURE_THAT_YOU_WANT_TO_PERFORM_THIS_ACTION'),
      accept: () => {
        this.invoice.items.splice(i, 1);
        this.invoicePriceControl();
        this.invoiceSave().then();
        this.loadInvoice().then();
      }
    });

  }

  changeInInvoiceItemQuantity(row, i, event = null): void {
    this.invoice.items[i].quantity = parseFloat(event.target.value);
    this.invoice.items[i].incomingQuantity = parseFloat(event.target.value);
    this.invoicePriceControl();
  }

  changeInInvoiceItemPrice(row, i, event = null): void {
    const findValuesIndex = this.credit.items.findIndex(obj => obj.stock['@id'] === row.stock['@id'] && obj.type === 'CHANGE_PRICE');
    const findIndex = this.priceCreditValuesOrder.indexOf(i);
    if (row.unitPrice !== this.itemUnitPriceValues[i]) {
      this.creditRemember = true;
      // Add in Credit
      if (findValuesIndex !== -1) {
        if (this.itemUnitPriceValues[i] === parseFloat(event)) {
          this.credit.items.splice(findValuesIndex, 1);
        } else {
          this.credit.items[findValuesIndex].price = parseFloat(event);
          this.credit.items[findValuesIndex].newPrice = this.itemUnitPriceValues[i] - parseFloat(event);
        }
      } else {
        const newItem = {
          quantity: row.documentQuantity,
          variant: row.variant,
          stock: row.stock,
          newPrice: this.itemUnitPriceValues[i] - parseFloat(event),
          price: parseFloat(event),
          reason: '',
          type: 'CHANGE_PRICE'
        };
        this.priceChange = true;
        // @ts-ignore
        this.credit.items.push(newItem);
        if (findIndex === -1) {
          this.priceCreditValuesOrder.push(i);
        }
      }
    } else if (row.unitPrice === this.itemUnitPriceValues[i]) {
      this.priceCreditValuesOrder.splice(i, 1);
      this.credit.items.splice(findValuesIndex, 1);
    }
    this.invoicePriceControl();
    this.creditRememberControl();
  }

  changeInInvoiceItemSubTotal(row, i, event = null): void {
    this.invoice.items[i].unitPrice = event / row.quantity;
    this.invoicePriceControl();
    // Add in Credit
  }

  changeInInvoiceItemIncomingQuantity(row, i, event = null): void {
    this.creditRemember = true;
    // Add in Credit
    const findValuesIndex = this.credit.items.findIndex(obj => obj.stock['@id'] === row.stock['@id'] && obj.type === 'CHANGE_QUANTITY');
    if (row.remainingQuantity !== row.incomingQuantity) {
      if (findValuesIndex === -1) {
        const item = {
          quantity: this.invoice.items[i].remainingQuantity - this.invoice.items[i].incomingQuantity,
          variant: this.invoice.items[i].variant,
          stock : this.invoice.items[i].stock,
          reason: '',
          type: 'CHANGE_QUANTITY',
          price: parseFloat(String(this.invoice.items[i].unitPrice)),
          subtotal: this.invoice.items[i].unitPrice * (this.invoice.items[i].remainingQuantity - this.invoice.items[i].incomingQuantity) ,
          tax: 0,
          total: this.invoice.items[i].unitPrice * (this.invoice.items[i].remainingQuantity - this.invoice.items[i].incomingQuantity)
        };
        // @ts-ignore
        this.credit.items.push(item);
        this.creditRemember = true;
      }else {

      }
    }
    if (row.documentQuantity === row.incomingQuantity) {

      this.credit.items.splice(findValuesIndex, 1);
    }
    this.invoicePriceControl();
    this.creditRememberControl();
    if (this.shipments.length > 0 ){
      this.shipment.items[i].quantity = row.remainingQuantity;
    }else{
      this.shipment.items[i].quantity = row.incomingQuantity;
    }
  }

  onVariantPriceUpdate(item, row, i): void {
    // remove form credits items
    const findInIndexCredits = this.credit.items.findIndex(obj => obj.stock['@id'] === item && obj.type === 'CHANGE_PRICE');
    if (findInIndexCredits !== -1) {
      this.credit.items.splice(findInIndexCredits, 1);
    }
    // @ts-ignore
    this.changePriceVariants.push({
      stock: item,
      price: row.unitPrice,
      variant: row.variant['@id']
    });
  }

  //
  onChange(row, i, event = null): void {

    if (row.type === 'newAdded') {
      this.invoice.items[i].quantity = parseFloat(event.target.value);
      this.invoice.items[i].documentQuantity = parseFloat(event.target.value);
      this.invoice.items[i].remainingQuantity = parseFloat(event.target.value);
      this.invoice.items[i].incomingQuantity = parseFloat(event.target.value);
      this.invoice.items[i].subtotal = parseFloat(event.target.value) * row.unitPrice;
      this.invoice.items[i].tax = (parseFloat(event.target.value) * row.unitPrice) * (row.taxRate.rate / 100);
      this.invoicePriceControl();
      this.invoiceSave().then();
    } else {
      this.invoice.items[i].quantity = event.target.value;
      this.invoice.items[i].documentQuantity = event.target.value;
      this.invoice.items[i].remainingQuantity = row.documentQuantity - event.target.value;
      this.shipment.items[i].quantity = row.incomingQuantity;
      this.invoice.items[i].subtotal = row.unitPrice * event.target.value;
      this.invoice.items[i].tax = (row.unitPrice * event.target.value) * (row.variant.stock.tax.rate / 100);
      this.credit.invoice = this.invoice['@id'];
      this.credit.contact = this.invoice.contact['@id'];
      this.credit.location = this.invoice.location['@id'];
      this.credit.total = this.invoice.total;
      this.credit.subtotal = this.invoice.subtotal;
      this.credit.tax = this.invoice.tax;
      this.credit.totalUnits = this.invoice.totalUnits;
      const item = {
        quantity: this.invoice.items[i].remainingQuantity,
        variant: this.invoice.items[i].variant,
        stock: this.invoice.items[i].stock,
        reason: '',
        type: 'CHANGE_QUANTITY',
        price: parseFloat(String(this.invoice.items[i].unitPrice)),
        subtotal: this.invoice.items[i].unitPrice * this.invoice.items[i].remainingQuantity,
        tax: 0,
        total: this.invoice.items[i].unitPrice * this.invoice.items[i].remainingQuantity
      };
      const findObjIndex = this.credit.items.findIndex((obj => obj.variant['@id'] === this.invoice.items[i].variant['@id'] && obj.type === 'CHANGE_QUANTITY'));
      if (findObjIndex === -1) {
        // @ts-ignore
        this.credit.items.push(item);
      } else {
        if (this.invoice.items[i].remainingQuantity === 0) {
          this.credit.items.splice(findObjIndex, 1);
        } else {
          this.credit.items[findObjIndex].quantity = this.invoice.items[i].remainingQuantity;
        }
      }
    }
    this.creditRemember = true;
    this.creditRememberControl();
    this.invoicePriceControl();
  }

  creditRememberControl(): void {
    const findValuesIndex = this.credit.items.findIndex(obj => obj.type === 'CHANGE_PRICE');
    if (this.credit.items.length === 0) {
      this.creditRemember = false;
      this.priceChange = false;
    } else {
      this.creditRemember = true;
    }
  }

  invoicePriceControl(): void {
    let newSubtotal = 0;
    let newTotal = 0;
    let newTaxTotal = 0;
    this.invoice.items.map((item, i) => {
      this.invoice.items[i].subtotal = item.unitPrice * item.quantity;
      // @ts-ignore
      this.invoice.items[i].tax = (item.unitPrice * item.quantity) * (item.taxRate?.rate / 100);
      newSubtotal += item.unitPrice * item.quantity;
      newTotal += item.unitPrice * item.quantity;
      // @ts-ignore
      newTaxTotal += (item.unitPrice * item.quantity) * (item.taxRate?.rate / 100);
    });
    this.invoice.total = newTotal + newTaxTotal;
    this.invoice.tax = newTaxTotal;
    this.invoice.subtotal = newSubtotal;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }

  edit(): void {
    this.editAction = true;
    this.shipment.total = 0;
  }

  onChangePrice(event, row, i): void {
    if (this.itemUnitPriceValues[i] !== parseFloat(event)) {
      this.priceChange = true;
      if (this.credit.invoice === undefined) {
        this.creditRemember = true;
        this.invoice.items[i].unitPrice = parseFloat(event);
        this.credit.invoice = this.invoice['@id'];
        this.credit.contact = this.invoice.contact['@id'];
        this.credit.location = this.invoice.location['@id'];
        this.credit.total = this.invoice.total;
        this.credit.subtotal = this.invoice.subtotal;
        this.credit.tax = this.invoice.tax;
        this.credit.totalUnits = this.invoice.totalUnits;
      }
      const newItem = {
        quantity: row.documentQuantity,
        variant: row.variant,
        stock: row.stock,
        newPrice: row.variant.price - event,
        type: 'CHANGE_PRICE',
        reason: '',
        price: parseFloat(row.unitPrice),
        subtotal: row.documentQuantity * (row.unitPrice - event),
      };
      const findChangePriceItem = this.credit.items.findIndex(obj => obj.stock['@id'] === row.stock['@id'] && obj.type === 'CHANGE_PRICE');
      if (findChangePriceItem !== -1) {
        this.credit.items[findChangePriceItem].quantity = row.documentQuantity;
        this.credit.items[findChangePriceItem].newPrice = row.unitPrice - event;
        this.credit.items[findChangePriceItem].price = row.unitPrice;
        this.credit.items[findChangePriceItem].subtotal = row.documentQuantity * (row.unitPrice - event);
      } else {
        // @ts-ignore
        this.credit.items.push(newItem);
        this.priceCreditValuesOrder.push(i);
      }
    } else {
      this.priceChange = false;
    }
    this.creditRememberControl();
  }

  itemCreditSelect(item, row): void {
    const findValuesIndex = this.credit.items.findIndex(obj => obj.stock['@id'] === item && obj.type === 'CHANGE_QUANTITY');
    if (findValuesIndex !== -1) {
      this.credit.items.splice(findValuesIndex, 1);
    } else {
      const newItem = {
        quantity: row.remainingQuantity,
        variant: row.variant,
        stock: row.stock,
        reason: '',
        type: 'CHANGE_QUANTITY'
      };
      // @ts-ignore
      this.credit.items.push(newItem);
    }
    this.creditRememberControl();
  }

  onPriceCreditChange(item, row, i): void {
    const valuesOld = this.priceChangeValues;
    this.priceChangeValues = [];
    const findValuesIndexPrice = valuesOld.findIndex(obj => obj === item);
    if (findValuesIndexPrice !== -1) {
      valuesOld.splice(findValuesIndexPrice, 1);
    }
    setTimeout(() => {
      this.priceChangeValues = valuesOld;
    }, 100);
    // add
    const findValuesIndex = this.credit.items.findIndex(obj => obj.stock['@id'] === item && obj.type === 'CHANGE_PRICE');
    if (findValuesIndex !== -1) {
      this.credit.items.splice(findValuesIndex, 1);
    } else {
      const newItem = {
        quantity: row.documentQuantity,
        variant: row.variant,
        stock: row.stock,
        newPrice: this.itemUnitPriceValues[i] - row.unitPrice,
        reason: '',
        type: 'CHANGE_PRICE'
      };
      // @ts-ignore
      this.credit.items.push(newItem);
    }
    this.creditRememberControl();
  }

  onPriceUpdate(item, row, i): void {
    const valuesOld = this.priceCreditValues;
    this.priceCreditValues = [];
    const findValuesIndex = valuesOld.findIndex(obj => obj === item);
    if (findValuesIndex !== -1) {
      valuesOld.splice(findValuesIndex, 1);
    }
    setTimeout(() => {
      this.priceCreditValues = valuesOld;
      this.disabledValues = this.priceChangeValues;
    }, 100);
    // remove form credits items
    const findValuesIndexCredits = this.credit.items.findIndex(obj => obj.stock['@id'] === item && obj.type === 'CHANGE_PRICE');
    if (findValuesIndexCredits !== -1) {
      this.credit.items.splice(findValuesIndexCredits, 1);
    }
    // credit items lengt control
    if (this.credit.items.length === 0) {
      this.creditRemember = false;
    } else {
      this.creditRemember = true;
    }
    // ekle
  }

  onCreditItemTax(i, value): void {
    this.credit.items[i].tax = value;
  }

  reCalculateInvoiceByRate(event, type = null): void {
    this.isDiscount = true;
    if (event.target.value > 0 && event.target.value !== undefined) {
      if (this.discountValue === undefined) {
        this.firstDiscountValues = [];
        this.firstDiscountRateValues = [];
        // @ts-ignore
        this.discountRate = 1 - (event.target.value / 100).toFixed(2);
        // @ts-ignore
        this.discountRateValueTotal = this.firstSubTotal * (event.target.value / 100).toFixed(2);
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].subtotal = this.itemSubTotalValues[i] * this.discountRate;
          this.firstDiscountValues.push(this.itemUnitPriceValues[i] * (1 - this.discountRate) * this.invoice.items[i].quantity);
          this.firstDiscountRateValues.push(event.target.value);
          if (type === 'secondDiscount') {
            this.secondDiscountValues.push(this.itemUnitPriceValues[i] * (1 - this.discountRate));
          }
          this.invoice.items[i].unitPrice = ((this.itemSubTotalValues[i] * this.discountRate) / this.invoice.items[i].quantity);
          this.invoice.items[i].tax = this.itemTaxValues[i] * this.discountRate;
          this.priceChangeValues.push(this.invoice.items[i].stock['@id']);
          const findObj = this.priceCreditValuesOrder.findIndex(obj => obj === i);
          if (findObj === -1) {
            this.priceCreditValuesOrder.push(i);
          }
          this.priceCreditValues = [];
          // @ts-ignore
          const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
          if (findObjVariant === -1) {
            // @ts-ignore
            this.changePriceVariants.push({
              variant: this.invoice.items[i].variant['@id'],
              stock: this.invoice.items[i].stock['@id'],
              price: this.invoice.items[i].unitPrice
            });
          } else {
            this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
          }
          this.priceChange = true;
        });
        this.invoice.subtotal = this.subTotal * this.discountRate;
        this.invoice.total = this.total * this.discountRate;
        this.invoice.tax = this.tax * this.discountRate;
        this.invoice.discount = this.firstSubTotal - this.invoice.subtotal;
      } else {
        this.firstDiscountRateValues = [];
        // @ts-ignore
        this.discountRate = 1 - (event.target.value / 100).toFixed(2);
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].subtotal = this.invoice.items[i].subtotal * this.discountRate;
          this.firstDiscountValues.push(this.itemUnitPriceValues[i] * (1 - this.discountRate));
          this.invoice.items[i].unitPrice = (this.invoice.items[i].subtotal / this.invoice.items[i].quantity);
          this.invoice.items[i].tax = this.invoice.items[i].tax * this.discountRate;
          this.priceChangeValues.push(this.invoice.items[i].stock['@id']);
          this.firstDiscountRateValues.push(event.target.value);
          this.firstDiscountValues.push(this.itemUnitPriceValues[i] * (1 - this.discountRate) * this.invoice.items[i].quantity);
          this.firstDiscountRateValues.push(event.target.value);

          const findObj = this.priceCreditValuesOrder.findIndex(obj => obj === i);
          if (findObj === -1) {
            this.priceCreditValuesOrder.push(i);
          }
          this.priceCreditValues = [];
          // @ts-ignore
          const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
          if (findObjVariant === -1) {
            // @ts-ignore
            this.changePriceVariants.push({
              variant: this.invoice.items[i].variant['@id'],
              stock: this.invoice.items[i].stock['@id'],
              price: this.invoice.items[i].unitPrice
            });
          } else {
            this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
          }
          this.priceChange = true;
        });
        this.invoice.subtotal = this.invoice.subtotal * this.discountRate;
        this.invoice.total = this.invoice.total * this.discountRate;
        this.invoice.tax = this.invoice.tax * this.discountRate;
        this.invoice.discount = this.firstSubTotal - this.invoice.subtotal;
      }
    } else {
      this.discountRateValue = null;
      this.discountValue = null;
      this.discountRate = null;
      this.priceChange = false;
      this.priceCreditValues = [];
      this.changePriceVariants = [];
      this.firstDiscountValues = [];
      this.firstDiscountRateValues = [];
      this.loadInvoice().then();
      if (this.discountValue > 0) {
        this.reCalculateInvoiceByDiscount(null, 1000);
      }
    }
  }

  reCalculateInvoiceByDiscount(event = null, value = null): void {
    this.isDiscount = true;
    if (event.target.value > 0) {
      if (this.discountRateValue === undefined) {
        // @ts-ignore
        if (value) {
          this.discountRate = 1 - ((value / this.subTotal) * 100) / 100;
        } else {
          this.discountRate = 1 - ((event.target.value / this.subTotal) * 100) / 100;
        }
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].subtotal = this.itemSubTotalValues[i] * this.discountRate;
          this.secondDiscountValues.push(this.itemUnitPriceValues[i] * (1 - this.discountRate));
          this.invoice.items[i].unitPrice = ((this.itemSubTotalValues[i] * this.discountRate) / this.invoice.items[i].quantity);
          this.invoice.items[i].tax = this.itemTaxValues[i] * this.discountRate;
          this.priceChangeValues.push(this.invoice.items[i].stock['@id']);
          const findObj = this.priceCreditValuesOrder.findIndex(obj => obj === i);
          if (findObj === -1) {
            this.priceCreditValuesOrder.push(i);
          }
          this.priceCreditValues = [];
          // @ts-ignore
          const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
          if (findObjVariant === -1) {
            // @ts-ignore
            this.changePriceVariants.push({
              variant: this.invoice.items[i].variant['@id'],
              stock: this.invoice.items[i].stock['@id'],
              price: this.invoice.items[i].unitPrice
            });
          } else {
            this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
          }
          this.priceChange = true;
        });
        this.invoice.subtotal = this.subTotal * this.discountRate;
        this.invoice.total = this.total * this.discountRate;
        this.invoice.tax = this.tax * this.discountRate;
        this.invoice.discount = this.firstSubTotal - this.invoice.subtotal;
      } else {
        // @ts-ignore
        if (value) {
          this.discountRate = 1 - ((value / this.subTotal) * 100) / 100;
        } else {
          this.discountRate = 1 - ((event.target.value / this.subTotal) * 100) / 100;
        }
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].subtotal = this.invoice.items[i].subtotal * this.discountRate;
          this.secondDiscountValues.push(this.itemUnitPriceValues[i] * (1 - this.discountRate));
          this.invoice.items[i].unitPrice = (this.invoice.items[i].subtotal / this.invoice.items[i].quantity);
          this.invoice.items[i].tax = this.invoice.items[i].tax * this.discountRate;
          this.priceChangeValues.push(this.invoice.items[i].stock['@id']);
          const findObj = this.priceCreditValuesOrder.findIndex(obj => obj === i);
          if (findObj === -1) {
            this.priceCreditValuesOrder.push(i);
          }
          this.priceCreditValues = [];
          // @ts-ignore
          const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
          if (findObjVariant === -1) {
            // @ts-ignore
            this.changePriceVariants.push({
              variant: this.invoice.items[i].variant['@id'],
              stock: this.invoice.items[i].stock['@id'],
              price: this.invoice.items[i].unitPrice
            });
          } else {
            this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
          }
          this.priceChange = true;
        });
        this.invoice.subtotal = this.invoice.subtotal * this.discountRate;
        this.invoice.total = this.invoice.total * this.discountRate;
        this.invoice.tax = this.invoice.tax * this.discountRate;
        this.invoice.discount = this.firstSubTotal - this.invoice.subtotal;
      }
    }
    if (event.target.value === '') {
      this.discountRateValue = null;
      this.discountValue = null;
      this.discountRate = null;
      this.priceChange = false;
      this.priceCreditValues = [];
      this.changePriceVariants = [];
      this.secondDiscountValues = [];
      this.loadInvoice().then();
      this.loadInvoice();
    }
  }

  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  onVariantSelect(event: StockVariantModel): void {
    const invoiceItem = new InvoiceItemModel();
    invoiceItem.type = 'newAdded';
    invoiceItem.variant = event;
    invoiceItem.stock = event.stock;
    invoiceItem.taxRate = event.stock.tax;
    invoiceItem.purchaseOption = null;
    invoiceItem.unitPrice = event.price;
    invoiceItem.documentQuantity = 0;
    invoiceItem.quantity = 0;
    invoiceItem.subtotal = 0;
    invoiceItem.incomingQuantity = 1;
    invoiceItem.nonDiscountedUnitPrice = event.price;
    this.invoice.items.push(invoiceItem);
  }

  onFirstDiscountRateValues(event, i): void {
    this.isDiscount = true;
    if (this.secondDiscountValues[i]) {
      if (this.firstDiscountRateValues[i]) {
        if (this.firstDiscountRateValues[i] > event) {
          const price = this.firstDiscountRateValues[i] - event;
          this.firstDiscountRateValues[i] = parseFloat(event);
          this.invoice.items[i].unitPrice = (1 - (price / 100)) * this.invoice.items[i].unitPrice;
          this.firstDiscountValues[i] = this.itemSubTotalValues[i] - (this.invoice.items[i].unitPrice * this.invoice.items[i].quantity);

        } else {
          const price = event - this.firstDiscountRateValues[i];
          this.firstDiscountRateValues[i] = parseFloat(event);
          this.invoice.items[i].unitPrice = (1 - (price / 100)) * this.invoice.items[i].unitPrice;
          this.firstDiscountValues[i] = this.itemSubTotalValues[i] - (this.invoice.items[i].unitPrice * this.invoice.items[i].quantity);

        }
      } else {
        this.firstDiscountRateValues[i] = parseFloat(event);
        this.invoice.items[i].unitPrice = (1 - (event / 100)) * this.invoice.items[i].unitPrice;
        this.firstDiscountValues[i] = this.itemSubTotalValues[i] - (this.invoice.items[i].unitPrice * this.invoice.items[i].quantity);

      }
    } else {
      this.firstDiscountRateValues[i] = parseFloat(event);
      this.invoice.items[i].unitPrice = (1 - (event / 100)) * this.itemUnitPriceValues[i];
      this.firstDiscountValues[i] = this.itemSubTotalValues[i] - (this.invoice.items[i].unitPrice * this.invoice.items[i].quantity);

    }
    // @ts-ignore
    const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
    if (findObjVariant === -1) {
      // @ts-ignore
      this.changePriceVariants.push({
        variant: this.invoice.items[i].variant['@id'],
        stock: this.invoice.items[i].stock['@id'],
        price: this.invoice.items[i].unitPrice
      });
    } else {
      this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
    }
    this.onControlPriceChangeValues(i, this.invoice.items[i].stock['@id']);
    this.priceChange = true;
    this.invoicePriceControl();
    setTimeout(() => {
      let sum = 0;
      this.firstDiscountRateValues.forEach(num => {
        sum += num;
      });
      this.invoice.discountRate = Number(sum / this.firstDiscountRateValues.length);
      this.averageRateCalculate();
    }, 100);
  }

  onSecondDiscountRateValues(event, i): void {
    this.isDiscount = true;
    if (this.firstDiscountRateValues[i]) {
      if (this.secondDiscountValues[i]) {
        if (this.secondDiscountValues[i] > event) {
          const price = this.secondDiscountValues[i] - event;
          this.secondDiscountValues[i] = event;
          this.invoice.items[i].unitPrice = this.invoice.items[i].unitPrice - price;
        } else {
          const price = event - this.secondDiscountValues[i];
          this.secondDiscountValues[i] = event;
          this.invoice.items[i].unitPrice = this.invoice.items[i].unitPrice + price;
        }
      } else {
        this.secondDiscountValues[i] = event;
        this.invoice.items[i].unitPrice = this.invoice.items[i].unitPrice - event;
      }
    } else {
      this.secondDiscountValues[i] = event;
      this.invoice.items[i].unitPrice = this.itemUnitPriceValues[i] - event;
    }

    // @ts-ignore
    const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
    if (findObjVariant === -1) {
      // @ts-ignore
      this.changePriceVariants.push({
        variant: this.invoice.items[i].variant['@id'],
        stock: this.invoice.items[i].stock['@id'],
        price: this.invoice.items[i].unitPrice
      });
    } else {
      this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
    }
    this.onControlPriceChangeValues(i, this.invoice.items[i].stock['@id']);
    this.priceChange = true;
    this.invoicePriceControl();
  }

  onChangeItemSubTotal(event, i): void {
    this.invoice.items[i].unitPrice = event / this.invoice.items[i].incomingQuantity;
    this.invoice.items[i].tax = event * this.invoice.items[i].tax;
    this.invoice.items[i].subtotal = event;
    this.firstDiscountRateValues[i] = 100 - ((event / this.itemSubTotalValues[i]) * 100);
    this.invoicePriceControl();
  }

  onControlPriceChangeValues(i, stock): void {
    this.priceChangeValues.push(stock);
    const findObj = this.priceCreditValuesOrder.findIndex(obj => obj === i);
    if (findObj === -1) {
      this.priceCreditValuesOrder.push(i);
    }
    this.priceCreditValues = [];
    // @ts-ignore
    const findObjVariant = this.changePriceVariants.findIndex(obj => obj.id === this.invoice.items[i].stock.id);
    if (findObjVariant === -1) {
      // @ts-ignore
      this.changePriceVariants.push({
        variant: this.invoice.items[i].variant['@id'],
        stock: this.invoice.items[i].stock['@id'],
        price: this.invoice.items[i].unitPrice
      });
    } else {
      this.changePriceVariants[findObjVariant].price = this.invoice.items[i].unitPrice;
    }
  }

  averageRateCalculate(): void {
    const averageRate = (this.firstSubTotal - this.invoice.subtotal) * 100 / this.firstSubTotal;
    this.invoice.discountRate = Number(averageRate.toFixed(2));
    this.invoice.discount = this.firstSubTotal - this.invoice.subtotal;
  }

  async getActiveInventory(): Promise<any> {
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      this.activeInventory = response;
    });
  }

  shipmentDateControl(event): void {
    if (this.activeInventory.startDate) {
      const selectedDate = new Date(event);
      const InventoryStartDate = new Date(this.activeInventory.startDate);
      if (selectedDate.getTime() < InventoryStartDate.getTime()) {
        this.shipment.documentDate = this.today;
        this.isCanBeSend = false;
        this.isCanBeSelectDate = this.activeInventory.startDate;
      } else {
        this.minDate = InventoryStartDate;
        this.isCanBeSend = true;
      }
    } else {
      this.minDate = this.today;
    }
  }
  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'InventoryComponent';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryComponent'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }
}
