

  <!---
  <aside>
    <ul>
      <li *ngFor="let item of items" [class]="activeMenu === item.routerLink ? 'active': ''">
        <a *ngIf="item.routerLink" (click)="setActiveMenu(item.routerLink)" [routerLink]="item.routerLink">{{ item.label }}</a>
        <a *ngIf="!item.routerLink" class="cursor-pointer" (click)="setActiveMenu(item.routerLink); flushAllData()">{{ item.label }}</a>
      </li>
    </ul>
  </aside>---->
  <div class="content">
    <router-outlet></router-outlet>
  </div>

<p-confirmDialog [header]="'CONFIRMATION'|translate" icon="pi pi-exclamation-triangle"></p-confirmDialog>

