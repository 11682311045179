import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../service/auth.service';
import {HttpService} from '../../../service/http.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  token: string;
  submitted: boolean;
  password: string;
  passwordRepeat: string;
  needLogin: boolean;
  apiUrl: string;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private http: HttpService,
              private flashMessageService: FlashMessageService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    if (!this.token) {

    }

    this.apiUrl = environment.apiUrl;
    await this.authService.checkLogin().then(response => {
      if (response.isValid) {
        window.location.href = '/';
      }
    });
    this.needLogin = true;
  }

  async send(): Promise<boolean> {
    if (this.password !== this.passwordRepeat || this.password.length < 6) {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'flashMessages.passwordMismatch'));
      return false;
    }

    this.submitted = true;
    await this.http.post('/api/security/reset-password', {password: this.password, token: this.token}).then(response => {
      // @ts-ignore
      if (response.success) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.passwordChanged'));
        this.router.navigate(['/login']);
      }
    }).catch(error => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'flashMessages.error'));
    });
  }
}
