<p-card>
  <app-loading-indicator [loading]=loading></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'COOK_BOOK_STOCKS' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'COOKBOOK'"></app-page-info>
      <p-fileUpload name="file" class="p-d-inline-block" styleClass="p-button-text" chooseIcon="pi pi-upload"
                    mode="basic" [chooseLabel]="'IMPORT'| translate" #fileUpload auto="true" customUpload="true"
                    multiple="multiple" (uploadHandler)="upload($event, 'cookBook', fileUpload)">

      </p-fileUpload>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <div class="main-content-div">
        <div>
          <div class="main-content-div-row3">
            <div class="category-div">
              <div class="p-grid">
                <p-table [value]="cookBookStocks" [tableStyle]="{'min-width': '50rem'}"
                         [paginator]="true" [rows]="20" [totalRecords]="total" [lazy]="true" (onLazyLoad)="load($event)"
                         [rowsPerPageOptions]="[10,25,50]">
                  <ng-template pTemplate="header">
                    <tr>
                      <th [style]="{width:'10%'}" pSortableColumn="code">
                        <p-sortIcon field="code"></p-sortIcon>{{ 'CODE' | translate}}</th>
                      <th [style]="{width:'50%'}" pSortableColumn="name">
                        <p-sortIcon field="name"></p-sortIcon>{{ 'NAME' | translate}}</th>
                      <th [style]="{width:'20%'}" pSortableColumn="category.name">
                        <p-sortIcon field="category.name"></p-sortIcon>{{ 'CATEGORY' | translate}}</th>
                      <th [style]="{width:'14%'}" pSortableColumn="totalCost">
                        <p-sortIcon field="totalCost"></p-sortIcon>{{ 'PRICE' | translate}}</th>
                      <th [style]="{width:'8%'}">{{ 'UNIT' | translate}}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr>
                      <td>{{product.code}}</td>
                      <td>{{product.name}}</td>
                      <td>{{product.category?.name}}</td>
                      <td pEditableColumn [style]="{textAlign:'right'}">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="3" [(ngModel)]="product.price"
                                           (ngModelChange)="changePrice(product, $event)">
                            </p-inputNumber>
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.price | currency: '₺'}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td [style]="{textAlign:'right'}">{{product.unit}}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</p-card>
