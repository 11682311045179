import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../model/user.model';
import {HttpService} from '../../service/http.service';
import {FlashMessageService} from '../../service/flash-message.service';
import {ValidationService} from '../../service/validation.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  users: UserModel[] = [];
  loading = false;
  baseUrl: string;
  constructor(private http: HttpService,
              private flashMessageService: FlashMessageService,
              private validationService: ValidationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.baseUrl = environment.apiUrl;
    this.loadUsers();
  }
  loadUsers(): void {
    this.loading = true;
    this.http.get(UserModel.IRI).subscribe((response: UserModel[]) => {
      this.users = response['hydra:member'];
      this.loading = false;
    });
  }

}
