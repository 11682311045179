<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'WEEKLY_SCHEDULES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'WEEKLY_SCHEDULES'"></app-page-info>
      <button [style]="{marginLeft: '5px', float:'right'}" pButton pRipple type="button" label="{{ 'NEW_SCHEDULE' | translate}}"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  inputId="dropdown"  (onChange)="parameters.status = $event.value; load()" baseZIndex="9999" [options]="status" name="materialStatus" [(ngModel)]="parameters.status" optionLabel="name" optionValue="value" ></p-dropdown>

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="suppliers" [(ngModel)]="parameters.contactId" name="supplier"  (onChange)="load()"  placeholder="{{'SELECT_SUPPLIER' | translate}}" optionValue="@id" optionLabel="name" ></p-dropdown>

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <app-flash-messages></app-flash-messages>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">

      <p-table  responsiveLayout="scroll" styleClass="p-datatable-striped" [style]="{width:'100%'}">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'SUPPLIER'| translate}}</th>
            <th>{{ 'TOTAL_VALUE'| translate}}</th>
            <th>{{ 'START_DATE'| translate}}</th>
            <th>{{ 'END_DATE'| translate}}</th>
            <th>{{ 'STATUS'| translate}}</th>
            <th>{{ 'REPEATING'| translate}}</th>
          </tr>
          <tr>
            <td> <p-autoComplete  [showEmptyMessage]="true"  field="name" [minLength]="1"></p-autoComplete></td>
            <td><p-inputNumber inputId="integeronly"></p-inputNumber></td>
            <td><p-inputNumber inputId="integeronly"></p-inputNumber></td>
            <td><p-inputNumber inputId="integeronly"></p-inputNumber></td>
            <td><p-inputNumber inputId="integeronly"></p-inputNumber></td>
            <td><p-inputNumber inputId="integeronly"></p-inputNumber></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="6"> {{ 'NO_RECORDS_FOUND'| translate}}</td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</p-card>
