<div class="p-grid">
  <div class="p-col-12">
  <h3>{{'WHY_OPEN_INVENTORY' | translate}}</h3>
   <p>{{'WHY_OPEN_INVENTORY_DESCRIPTION' | translate}}</p>
 </div>
  <div class="p-col-12">
    1- <b>{{'WHY_OPEN_TITLE_1' | translate}} </b>
    {{'WHY_OPEN_TITLE_1_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    2- <b>{{'WHY_OPEN_TITLE_2' | translate}} </b>
    {{'WHY_OPEN_TITLE_2_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    3- <b>{{'WHY_OPEN_TITLE_3' | translate}} </b>
    {{'WHY_OPEN_TITLE_3_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    4- <b>{{'WHY_OPEN_TITLE_4' | translate}} </b>
    {{'WHY_OPEN_TITLE_4_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    5- <b>{{'WHY_OPEN_TITLE_5' | translate}} </b>
    {{'WHY_OPEN_TITLE_5_DESCRIPTION' | translate}}
  </div>
  <div class="p-col-12">
    6- <b>{{'WHY_OPEN_TITLE_6' | translate}} </b>
    {{'WHY_OPEN_TITLE_6_DESCRIPTION' | translate}}
  </div>
</div>
