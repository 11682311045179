// openai.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OpenaiService {
  private apiKey = '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'; // OpenAI API anahtarınızı buraya ekleyin
  private apiUrl = 'https://api.openai.com/v1/'; // OpenAI API URL'si

  constructor(private http: HttpClient) {}

  generateText(prompt: string): any {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.apiKey}`);
    const body = {
      prompt: prompt,
      max_tokens: 50, // İstenilen maksimum token sayısı
      model: 'davinci'
    };

    // @ts-ignore
    return this.http.post(`${this.apiUrl}completions`, body, { headers });
  }
}
