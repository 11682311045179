import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ContactModel} from '../../../model/contact.model';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-weekley-schedules',
  templateUrl: './weekley-schedules.component.html',
  styleUrls: ['./weekley-schedules.component.scss'],
  providers: [DialogService, DynamicDialogConfig]
})
export class WeekleySchedulesComponent implements OnInit {
  loading: boolean;
  status: any[];
  suppliers: any[];
  parameters = {
    status: '',
    contactId: '',
    page: 1,
    itemsPerPage: 10
  };
  constructor(private http: HttpService, private dialogService: DialogService, private route: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.suppliers = [
        { name: this.translate.instant('ALL'), value: ''}
      ];
      this.status = [
        {name: this.translate.instant('ALL_STATUS'), value: ''},
        {name: this.translate.instant('PAST'), value: 'PAST'},
        {name: this.translate.instant('CURRENT'), value: 'CURRENT'},
        {name: this.translate.instant('CURRENT'), value: 'CURRENT'}
        ];
    }, 500);
    this.loadSuppliers().then();
  }
  load(): void {
}
  async loadSuppliers(): Promise<void> {
    await this.http.get(ContactModel.IRI, {type: 'supplier'}).subscribe((response: ContactModel) => {
      response['hydra:member'].map((items: any) => {
        this.suppliers.push({ name: items.name, value: items['@id'] });
      });
    });
  }
}
