// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:8000',
  apiUrl: 'https://api.qapera.com',

  kolayBi: {
    clientId: 37,
    clientSecret: 'Y4mg26Hzqks9PGoLGcwZcbiCLcuMhLZomCozEdOY',
    redirectUri: 'http://app.qapera.com/settings/integrations?partner=kolaybi',
    apiUrl: 'http://ofis-sandbox-api.kolaybi.com',
    channel: 'qapera'
  },
  isBasi: {
    ApiKey: 'DBA2EEE5776444FB82D46FA57DEF5F82',
    returnUrl: 'https://app.qapera.com/settings/locations?partner=logoisbasi',
    apiUrl: 'https://isbasimw.isbasi.com',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
