import {Component, Input, OnInit} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {WorkOrderModel} from '../../../model/work-order.model';
import {StockVariantModel} from '../../../model/stock-variant.model';

@Component({
  selector: 'app-pick-list-table',
  templateUrl: './pick-list-table.component.html',
  styleUrls: ['./pick-list-table.component.scss']
})
export class PickListTableComponent implements OnInit {

  @Input() workOrder: WorkOrderModel = new WorkOrderModel();
  @Input() ids: StockVariantModel[] = [];
  @Input() items: number[] = [];
  @Input() quantities: number[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  add(variant: StockVariantModel, totalQuantity, i: number): void {
    this.items.push(i);

    let isExists = false;

    this.ids.map((stockVariant: StockVariantModel, key: number) => {
      if (stockVariant.id === variant.id) {
        this.quantities[key] += this.needAmount(variant.amount, totalQuantity);
        isExists = true;
      }
    });

    if (!isExists) {
      this.quantities.push(this.needAmount(variant.amount, totalQuantity));
      this.ids.push(variant);
    }
  }

  remove(i): void {
    this.items.splice(i, 1);
  }

  requiredAmount(workOrderPlanned, materialPlannedQuantity, quantity): number {
    if (workOrderPlanned !== undefined && materialPlannedQuantity !== undefined && quantity !== undefined) {
      return workOrderPlanned * (materialPlannedQuantity * quantity);
    }

    return 1;
  }

  needAmount(usableAmount, requiredAmount): number {
    let needAmount =  usableAmount - requiredAmount;

    if (needAmount < 0) {
      needAmount *= -1;
    } else {
      needAmount = 0;
    }

    return needAmount;
  }
}
