import {ApiResourceModel} from './api-resource.model';
import {InvoiceModel} from './invoice.model';
import {ShipmentItemModel} from './shipment-item.model';

export class ShipmentModel extends ApiResourceModel{
  public static IRI = '/api/shipments';
  public invoice: InvoiceModel|string;
  public items: ShipmentItemModel[] = [];
  public total: number;
  public taxTotal: number;
  public documentType: string;
  public documentNumber: any;
  public documentDate: Date|string;
  public taxType: string;
  public paymentDate: Date|string;
  public id: number;
}
