import {ApiResourceModel} from './api-resource.model';
import {PurchaseOptionModel} from './purchase-option.model';
import {StockVariantModel} from './stock-variant.model';
import {StockModel} from './stock.model';

export class StockMatchModel  extends ApiResourceModel{
  public static IRI = '/api/stock_matches';

  public variant: StockVariantModel| string;
  public stock: StockModel| string;
  public purchaseOption: PurchaseOptionModel | string;
  public name: string;
}
