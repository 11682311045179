import {Component, Input, OnInit} from '@angular/core';
import {StockModel} from '../../../model/stock.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductRecipeModel} from '../../../model/product-recipe.model';
import {ProductRecipeItemModel} from '../../../model/product-recipe-item.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {ProductRecipeItemVariantOptionModel} from '../../../model/product-recipe-item-variant-option.model';
import {CurrencyService} from '../../../service/currency.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';

@Component({
  selector: 'app-product-recipe',
  templateUrl: './product-recipe.component.html',
  styleUrls: ['./product-recipe.component.scss']
})
export class ProductRecipeComponent implements OnInit {
  @Input() stockId: number;
  stock: StockModel = new StockModel();
  recipe: ProductRecipeModel = new ProductRecipeModel();
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  currencies: string[] = [];
  currency: string;
  rates: string[] = [];

  stocks: StockModel[] = [];

  loading: boolean;
  submitted: boolean;
  recalculating: boolean;
  id: number;

  errors = [];

  validationSubscriber: Subscription;
  total: number;

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private currencyService: CurrencyService, private flashMessageService: FlashMessageService,
              private validationService: ValidationService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    // tslint:disable-next-line:radix
    if (this.stockId) {
      this.id = this.stockId;
    }else{
      // tslint:disable-next-line:radix
      this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    }
    this.currency = this.currencyService.getCurrency().code;

    await this.load();

    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
  }

  async load(): Promise<void> {
    await this.http.get(`${StockModel.IRI}/${this.id}`).toPromise().then((response: StockModel) => {
      this.stock = response;
      this.recipe.product = this.stock['@id'];
    });

    await this.http.get(`${ProductRecipeModel.IRI}?product.id=${this.id}`).toPromise().then((response: ProductRecipeModel[]) => {

      if (response['hydra:member'].length > 0) {
        let subtotal= 0;
        this.recipe = response['hydra:member'][0];
        this.recipe.product = this.recipe.product['@id'];
        this.recipe.items.map((item, i) => {
          subtotal += item.cost;
          this.recipe.items[i].currency = item.currency['@id'];
          this.recipe.items[i].recipe = this.recipe['@id'];
          if (item.stock !== undefined) {
            // @ts-ignore
            this.variants.push(item.stock);
            // @ts-ignore
            this.autoCompleteValues.push(item.stock);

            // @ts-ignore
            this.currencies.push(item.stock.stock.currency.code);

            this.recipe.items[i].stock = item.stock['@id'];

            if (item.rate) {
              this.rates[i] = item.rate.toString();
            }

          }
        });
        this.total = subtotal;
      }

      const options = [];
      if (this.recipe.items.length > 0) {
        this.recipe.items[0].productRecipeItemVariantOptions.map(option => {
          options.push(option.variantOption);
        });
      }

      this.stock.stockVariantOptions.map(option => {
        if (options.indexOf(option['@id']) < 0) {
          this.recipe.items.map((item, key) => {
            const recipeItemVariantOption = new ProductRecipeItemVariantOptionModel();
            recipeItemVariantOption.variantOptionValues = [];
            recipeItemVariantOption.variantOption = option['@id'];
            this.recipe.items[key].productRecipeItemVariantOptions.push(recipeItemVariantOption);
          });
        }
      });

      this.loading = false;
    });
    // tslint:disable-next-line:max-line-length
    await this.http.get(`${ProductRecipeModel.IRI}/${this.recipe.id}/recalculate`).toPromise().then((response: ProductRecipeModel[]) => {
    });
  }

  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  searchStock = event => {
    this.http.get(StockModel.IRI, {name: event.query}).subscribe((response: StockModel[]) => {
      this.stocks = response['hydra:member'];
    });
  }

  loadRecipe(stock: StockModel): void {
    this.loading = true;
    this.http.get(`${ProductRecipeModel.IRI}?product.id=${stock.id}`).subscribe((response: ProductRecipeModel[]) => {
      if (response['hydra:member'].length > 0) {

        if (this.recipe) {
          this.recipe = {...response['hydra:member'][0], ...{id: this.recipe.id, '@id': this.recipe['@id']}};
        } else {
          this.recipe = response['hydra:member'][0];
        }
        this.recipe.product = this.stock['@id'];

        this.variants = [];
        this.autoCompleteValues = [];
        this.recipe.items.map((item, i) => {
          if (item.stock !== undefined) {
            // @ts-ignore
            this.variants.push(item.stock);
            // @ts-ignore
            this.autoCompleteValues.push(item.stock);

            this.recipe.items[i].stock = item.stock['@id'];

            // @ts-ignore
            this.currencies.push(item.stock.stock.currency.code);

            // @ts-ignore
            delete this.recipe.items[i].recipe;
            delete this.recipe.items[i].id;
            delete this.recipe.items[i]['@id'];
          }
        });
      }

      this.loading = false;
    });
  }

  addItem = () => {
    const item = new ProductRecipeItemModel();
    item.cost = 0;
    item.wastage = 0;

    this.stock.stockVariantOptions.map((variantOption, i) => {
      const productRecipeItemVariantOption = new ProductRecipeItemVariantOptionModel();
      productRecipeItemVariantOption.variantOption = variantOption['@id'];
      productRecipeItemVariantOption.variantOptionValues = [];

      item.productRecipeItemVariantOptions.push(productRecipeItemVariantOption);
    });

    this.recipe.items.push(item);
  }

  async removeItem(i): Promise<void> {
    if (this.recipe.items[i].id) {
      await this.http.delete(`${ProductRecipeItemModel.IRI}/${this.recipe.items[i].id}`).then(() => {
        this.autoCompleteValues.splice(i, 1);
        this.rates.splice(i, 1);
      });
    }

    this.recipe.items.splice(i, 1);
  }

  calculateCost = (unitPrice, amount, totalCost: number, isMaking = false) => {
    let cost = 0;

    if (isMaking) {
      if (amount !== undefined && totalCost !== undefined) {
        cost = totalCost * amount;
      }
    } else {

      if (amount !== undefined && unitPrice !== undefined) {
        cost = unitPrice * amount;
      }
    }

    return cost;
  }

  async save(): Promise<void> {
    this.submitted = true;

    for (let i = this.recipe.items.length - 1; i >= 0; i--) {
      if (this.recipe.items[i].stock === undefined) {
        this.recipe.items.splice(i, 1);
      }
    }

    this.recipe.items.map((item, key) => {
      if (item.currency['@id'] !== undefined) {
        this.recipe.items[key].currency = item.currency['@id'];
      }
    });

    if (this.recipe.id) {
      await this.http.put(`${ProductRecipeModel.IRI}/${this.recipe.id}`, this.recipe).then((response: ProductRecipeModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(err => {
      });
    } else {
      await this.http.post(ProductRecipeModel.IRI, this.recipe).then((response: ProductRecipeModel) => {
        if (response) {
          this.recipe.id = response.id;
          this.recipe.items = response.items;

          this.recipe.items.map((item, key) => {
            this.recipe.items[key].stock = item.stock['@id'];
          });

          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      }).catch(err => {
      });
    }

    this.submitted = false;
  }

  onVariantSelect(variant: StockVariantModel, i: number): void {
    // @ts-ignore
    this.currencies.push(variant.stock.currency.code);
    this.recipe.items[i].stock = variant['@id'];
    this.recipe.items[i].cost = this.calculateCost(variant.price, this.recipe.items[i].amount, variant.totalCost, variant.stock.isMaking);
    this.recipe.items[i].currency = variant.stock.currency['@id'];

    // @ts-ignore
    const currencyId = variant.stock.currency.id;
    this.http.get('/api/currency_rates/today', {id: currencyId}).subscribe(response => {
      if (response) {
        this.rates[i] = response.rate;
      }
    });
  }

  calculateAmountWithWastage(amount: number, wastage: number): number {
    if (amount !== undefined && wastage !== undefined) {
      return amount + (amount / 100 * wastage);
    }

    return 0;
  }

  async recalculate(): Promise<void> {
    this.recalculating = true;

    await this.http.get(`${ProductRecipeModel.IRI}/${this.recipe.id}/recalculate`).toPromise().then((response: ProductRecipeModel) => {
      this.load();
    });

    this.recalculating = false;
  }

  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  onCurrencyChange(rate: string, i: number): void {
    this.recipe.items[i].rate = parseFloat(rate);
  }
}
