<app-flash-messages></app-flash-messages>
<div class="login-area" *ngIf="needLogin">
  <div class="row1" style="background-image: url('assets/img/login-background.svg');background-repeat: no-repeat;background-size: cover;">

      <form action="" (ngSubmit)="login()">
        <div class="form-div">
          <div class="p-p-3 p-text-center">
            <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
          </div>
          <div class="p-fluid p-pl-3 p-pt-3 p-pr-3">
            <div class="p-field">
              <label for="email">{{'EMAIL' | translate}}</label>
              <input id="email" type="text" pInputText [(ngModel)]="email" name="email">
            </div>
            <div class="p-field">
              <label for="password">{{'PASSWORD' | translate}}</label>
              <input id="password" type="password" pInputText [(ngModel)]="password" name="password">
            </div>
          </div>
          <div class="p-pl-3 p-pb-3">
            <a routerLink="/forgot-password" style="color:gray">{{'FORGOT_PASSWORD'| translate}}</a>
          </div>
          <div class="p-pl-3 p-pb-3">
            <p-button [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'" type="submit" label="{{'LOGIN' | translate}}"></p-button>
          </div>
        </div>
      </form>

  </div>
</div>


