import {ApiResourceModel} from './api-resource.model';
import {WasteEventItems} from './waste-event-items.model';
import {LocationModel} from './location.model';
import {UserModel} from "./user.model";

export class WasteEvents extends ApiResourceModel{
  public static IRI = '/api/waste_events';
  public WasteEventItem: WasteEventItems[] = [];
  public location: LocationModel[] = [];
  public date: Date;
  public code: string;
  public series: string;
  public number: number;
  public total: number;
  public description: string;
  public id: any;
  public symbol: string;
  public value: number;
  public createdBy: UserModel;
}
