<app-loading-indicator [loading]="loading"></app-loading-indicator>
<form action="" *ngIf="!loading">
  <div class="p-fluid">
    <div class="p-field" *ngFor="let attribute of attributes">
      <label for="code">{{ attribute.attrKey|translate }}</label>
      <input id="code" type="text" [name]="attribute.attrKey" pInputText [(ngModel)]="attribute.attrValue" autofocus>
    </div>
  </div>
  <button pButton label="{{ 'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
</form>
