import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../../service/http.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-okay-and-continue',
  templateUrl: './okay-and-continue.component.html',
  styleUrls: ['./okay-and-continue.component.scss']
})
export class OkayAndContinueComponent implements OnInit {

  constructor(private http: HttpService, private router: Router) { }

  ngOnInit(): void {
  }
  nextPage(): void {

    this.router.navigate(['initial-setup/select-pos']).then(r => {
    });

  }
  installDemoData(): void {
    this.http.get('/api/registration/import-sample-data').subscribe(response => {
      if (response.status === true){
        window.location.href = '/';
      }
    });
  }

}
