import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {StockAdjustmentModel} from '../../../model/stock-adjustment.model';
import {StockAdjustmentItemModel} from '../../../model/stock-adjustment-item.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {HttpService} from '../../../service/http.service';
import {LocationModel} from '../../../model/location.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {StockTrackType} from '../../../model/stock.model';

@Component({
  selector: 'app-stock-adjustment',
  templateUrl: './stock-adjustment.component.html',
  styleUrls: ['./stock-adjustment.component.scss']
})
export class StockAdjustmentComponent implements OnInit, OnDestroy {
  @Input() item: StockAdjustmentModel = new StockAdjustmentModel();
  adjustment: StockAdjustmentModel = new StockAdjustmentModel();
  loading: boolean;
  submitted: boolean;
  errors = [];
  currencyLabel: string;
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  locations: LocationModel[] = [];
  id: any;
  validationSubscriber: Subscription;
  moreLoading: boolean;

  constructor(private http: HttpService, private activatedRoute: ActivatedRoute,
              private flashMessageService: FlashMessageService, private router: Router, private validationService: ValidationService) { }

  ngOnInit(): void {
    if (this.item.id){
      this.id = this.item.id;
    }
    this.load();
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
      if (this.locations.length > 0) {
        this.adjustment.location = this.locations[0]['@id'];
      }
    });

    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });
  }
  async load(): Promise<void>{
    this.loading = true;
    if (this.id) {
      this.http.get(`${StockAdjustmentModel.IRI}/${this.id}`).subscribe((response: StockAdjustmentModel) => {
        this.adjustment = response;
        this.adjustment.adjustmentDate = new Date(this.adjustment.adjustmentDate);
        this.loading = false;
        this.adjustment.location = this.adjustment.location['@id'];

        this.adjustment.items.map((item, key) => {
          this.autoCompleteValues.push(item.stockVariant);
          this.adjustment.items[key].stockVariant = this.adjustment.items[key].stockVariant['@id'];
        });

      });
    } else {
      this.http.get(`${StockAdjustmentModel.IRI}/adjustment-number`).subscribe((response: StockAdjustmentModel) => {
        this.adjustment = response;
        this.adjustment.id = null;
        this.adjustment.adjustmentDate = new Date(this.adjustment.adjustmentDate);
        this.loading = false;

        if (this.locations.length > 0) {
          this.adjustment.location = this.locations[0]['@id'];
        }
      });
    }

  }

  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  onVariantSelect(event: StockVariantModel, i: number): void {
    this.adjustment.items[i].stockVariant = event['@id'];
    this.adjustment.items[i].stock = event.stock['@id'];
    this.adjustment.items[i].unitPrice = event.price;
    this.adjustment.items[i].inStock = event.amount;
    this.adjustment.items[i].trackType = event.stock.trackType;
  }

  searchVariant(event): void {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  addItem(): void {
    const item = new StockAdjustmentItemModel();
    item.totalPrice = 0;

    this.adjustment.items.push(item);
    this.autoCompleteValues.push(new StockVariantModel());
  }

  removeItem(i: number): void {
    this.adjustment.items.splice(i, 1);
    this.autoCompleteValues.splice(i, 1);
  }

  calculateTotalPrice(i: number): void {
    this.adjustment.items[i].totalPrice = this.adjustment.items[i].quantity * this.adjustment.items[i].unitPrice;
  }

  async save(): Promise<void> {
    this.submitted = true;

    this.adjustment.items.map((item, key) => {
      if (item.stockVariant['@id'] !== undefined) {
        this.adjustment.items[key].stockVariant = item.stockVariant['@id'];
      }
    });

    if (this.adjustment.location['@id'] !== undefined) {
      this.adjustment.location = this.adjustment.location['@id'];
    }

    if (this.id) {
      await this.http.put(`${StockAdjustmentModel.IRI}/${this.id}`, this.adjustment).then((response: StockAdjustmentModel) => {
        if (response) {
          this.adjustment = response;
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(error => {
      });
    } else {
      await this.http.post(StockAdjustmentModel.IRI, this.adjustment).then((response: StockAdjustmentModel) => {
        if (response) {
          this.adjustment = response;
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          // this.router.navigate([`/stock-adjustments/${response.id}/edit`]);
        }
      }).catch(error => {
      });
    }

    this.submitted = false;
  }

  ngOnDestroy(): void{
    this.validationService.updateErrors(null);
    this.validationSubscriber.unsubscribe();
  }
}
