<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'STOCKS' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'STOCKS_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <span class="p-buttonset p-mb-5 p-d-inline-block">
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['stock.type'] == 'product'"
            (click)="parameters['stock.type'] = 'product'; load()" label="{{'PRODUCT' | translate}}"></button>
        <button pButton type="button" class="p-button-secondary" [disabled]="parameters['stock.type'] == 'semi_product'"
                (click)="parameters['stock.type'] = 'semi_product'; load()" label="{{'SEMI_PRODUCT' | translate}}"></button>
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters['stock.type'] == 'material'"
            (click)="parameters['stock.type'] = 'material'; load()" label="{{'MATERIAL' | translate}}"></button>
</span>
  <p-table [loading]="loading" [lazy]="true" [value]="items"
           [paginator]="false"   [resizableColumns]="true"
           styleClass="p-datatable-gridlines p-datatable-striped p-datatable-lg"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width: '30% !important'}"  pResizableColumn>{{'STOCK' | translate}} <app-tooltip description="STOCK_TOOLTIP"></app-tooltip></th>
        <th pResizableColumn>{{'SKU' | translate}} <app-tooltip description="SKU_TOOLTIP"></app-tooltip></th>
        <th pResizableColumn>{{'IN_STOCK' | translate}} <app-tooltip description="IN_STOCK_TOOLTIP"></app-tooltip></th>
        <th pResizableColumn>{{'EXPECTED_AMOUNT' | translate}} <app-tooltip description="EXPECTED_AMOUNT_TOOLTIP"></app-tooltip></th>
        <th pResizableColumn>{{'COMMITTED_AMOUNT' | translate}} <app-tooltip description="RESERVED_AMOUNT_TOOLTIP"></app-tooltip></th>
        <th pResizableColumn>{{'ALERT_LEVEL' | translate}} <app-tooltip description="ALERT_LEVEL_TOOLTIP"></app-tooltip></th>
        <th pResizableColumn>{{'MISSING_STOCK' | translate}} <app-tooltip description="MISSING_AMOUNT_TOOLTIP"></app-tooltip></th>
        <th [style]="{width: '7% !important'}"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td><a routerLink="/stocks/{{row.stock.id}}/edit">{{ row.name }}</a></td>
        <td>{{ row.code }}</td>
        <td><a type="button" href="javascript:void(0)" class="p-button-text" (click)="showTransactions(row.id, 'in_stock')">{{ row.amount }}</a></td>
        <td><a type="button" href="javascript:void(0)" class="p-button-text"  (click)="showTransactions(row.id, 'expected')">{{row.expectedAmount}}</a></td>
        <td><a type="button" href="javascript:void(0)" class="p-button-text"  (click)="showTransactions(row.id, 'committed')">{{ row.committedAmount }}</a></td>
        <td>{{ row.alertLevel }}</td>
        <td><a type="button" href="javascript:void(0)" class="p-button-text"  (click)="showTransactions(row.id, 'missing')">{{ row.missingAmount }}</a></td>
        <td>
          <button pButton label="" *ngIf="row.stock.isMaking"  routerLink="/work-orders/create" [state]="{quantity: -1 * row.missingAmount, variant: row}" icon="pi pi-cog" class="p-button-outlined p-mr-2" iconPos="left"></button>
          <p-button *ngIf="row.stock.isBuying" [icon]="ids.indexOf(row) > -1 ? 'pi pi-trash' : 'pi pi-plus'" [label]="(list.indexOf(i) > -1 ? 'REMOVE' : 'ADD')|translate" (click)="ids.indexOf(row) > -1 ? remove(i) : add(row, -1 * row.missingAmount, i)" styleClass="p-button-text"></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<div class="p-text-right p-mt-2" *ngIf="parameters['stock.type'] == 'material'">
  <p-button [disabled]="ids.length < 1" routerLink="/purchase/create" [state]="{ids: this.ids, quantities: this.quantities}" [label]="'CREATE_PURCHASE_REQUEST'|translate" ></p-button>
</div>
