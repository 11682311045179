import {Component, OnInit} from '@angular/core';
import {InvoiceModel, InvoiceStatus, InvoiceStockStatus, InvoiceType} from '../../../model/invoice.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {InvoiceItemModel} from '../../../model/invoice-item.model';
import {LocationModel} from '../../../model/location.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CurrencyModel} from '../../../model/currency.model';
import {ContactModel} from '../../../model/contact.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {Subscription} from 'rxjs';
import {CompanyModel} from '../../../model/company.model';
import {StockService} from '../../../service/stock.service';
import {CurrencyService} from '../../../service/currency.service';
import {ValidationService} from '../../../service/validation.service';
import {ContactService} from '../../../service/contact.service';
import {environment} from '../../../../environments/environment';
import {StockModel} from '../../../model/stock.model';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {TranslateService} from '@ngx-translate/core';
import {UnitModel} from '../../../model/unit.model';
import {DialogService} from 'primeng/dynamicdialog';
import {IncomingInvoiceModel} from '../../../model/incoming-invoice.model';
import {LocationService} from '../../../service/location.service';


@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  providers: [DialogService]
})
export class OrderDetailsComponent implements OnInit {

  showInvoiceDialog: boolean;
  showWaybillDialog: boolean;
  invoice: InvoiceModel = new InvoiceModel();
  shipment: ShipmentModel = new ShipmentModel();
  submitted: boolean;
  loading: boolean;
  id: any;
  uploadedFiles: any[] = [];
  contacts: ContactModel[] = [];
  locations: LocationModel[] = [];
  taxRates: TaxRateModel[] = [];
  currencies: CurrencyModel[] = [];
  discountLoading = false;
  confirmLoading = true;
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  apiUrl: string;
  printItems: MenuItem[];
  taxes: TaxRateModel[] = [];
  status: string;
  currencyLabel: string;
  locationId: number;
  manualCustomer: boolean;
  shipmentText: string;
  billingAddressSameAsShippingAddress = true;
  shippingAddressString: string;
  billingAddressString: string;
  invoiceTypes = InvoiceType;
  invoiceStatus = InvoiceStatus;
  stockSubscriber: Subscription;
  validationSubscriber: Subscription;
  errors = [];
  company: CompanyModel = new CompanyModel();
  billable: boolean;
  sendingBill: boolean;
  createdUserName: string;
  createdUserEmail: string;
  orderSendOn: any;
  locationName: '';
  supplierName: '';
  supplierEmail: '';
  supplierTCKN: any;
  supplierId: number;
  deliveryTime: '';
  contactName: ContactModel | string;
  display: boolean;
  displayCreate: boolean;
  displayUpdate: boolean;
  item: StockModel = new StockModel();
  type: string;
  receiveDialog: boolean;
  invoiceLoading: boolean;
  inComingInvoices: IncomingInvoiceModel[] = [];
  parameters = {
    startDate: '',
    endDate: '',
    CurrentPage: 1,
    MaxResultCount: 10,
    SkipCount: 0,
    TCKN_VN_Sender: null,
    ettn: 0,
    invoiceId: 0,
    contact: ''
  };
  invoiceItemsShow: boolean;
  invoiceItems: any[] = [];
  selectedRow: number;
  agencyNumber = false;
  agencyNumberNotNull = false;
  agencyEmailNotNull = false;
  inCompanyOrder: boolean;
  actions: any;
  displayAction: boolean;

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private flashMessageService: FlashMessageService,
              private locationService: LocationService,
              private confirmationService: ConfirmationService,
              private stockService: StockService, private currencyService: CurrencyService,
              private validationService: ValidationService, private contactService: ContactService,
              private translate: TranslateService,
              private router: Router, private dialog: DialogService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
     if ( params.type) {
       this.inCompanyOrder = true;
     }
    });
    this.invoice.currency = this.currencyService.getCurrency()['@id'];
    this.currencyLabel = this.currencyService.getCurrency().code;

    if (history.state.status !== undefined) {
      this.invoice.status = history.state.status;
    } else {
      this.invoice.status = InvoiceStatus.Confirmed;
    }

    this.route.data.subscribe(data => {
      this.invoice.type = data.type;
    });
    // tslint:disable-next-line:radix
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loading = true;

    this.loadInvoice();
    this.loadFormData();

    this.apiUrl = environment.apiUrl;

    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });

    this.stockSubscriber = this.stockService.stocksData.subscribe((data: StockModel) => {
      if (data) {

        const invoiceItem = new InvoiceItemModel();
        invoiceItem.variant = data.variants[0]['@id'];
        invoiceItem.unitPrice = data.variants[0].salePrice;
        invoiceItem.quantity = 1;
        invoiceItem.subtotal = invoiceItem.quantity * invoiceItem.unitPrice;
        // @ts-ignore
        invoiceItem.tax = (invoiceItem.subtotal / 100) * data.tax.rate;
        invoiceItem.stock = data['@id'];
        invoiceItem.status = InvoiceStockStatus.StatusNotAvailable;
        invoiceItem.materialStatus = InvoiceStockStatus.StatusNotRecipe;
        // @ts-ignore
        invoiceItem.taxRate = data.tax['@id'];

        this.autoCompleteValues.push(data.variants[0]);
        this.invoice.items[this.invoice.items.length - 1] = invoiceItem;
        this.calculateTotalPrice(this.invoice.items.length - 1);
      }
    });

    setTimeout(() => {
      this.printItems = [
        {label: this.translate.instant('PRINT_QUOTE'), command: () => this.print('quote')},
        {label: this.translate.instant('PRINT_WITHOUT_PRICE'), command: () => this.print('withoutPrice')},
        {label: this.translate.instant('PRINT_PICK_LIST'), command: () => this.print('picklist')},
      ];
    }, 500);

    this.company = JSON.parse(localStorage.getItem('company'));

    if (this.company) {
      this.http.get(CompanyIntegrationModel.IRI, {'company.id': this.company.id}).subscribe(response => {
        if (response['hydra:totalItems'] > 0) {
          this.billable = true;
        }
      });
    }
  }

  loadFormData = () => {
    this.http.get(ContactModel.IRI, {order: '[name]=asc', pagination: false, type: 'supplier'}).subscribe((contact: ContactModel[]) => {
      if (contact) {
        this.contacts = contact['hydra:member'];
      }
    });
    this.locationService.getLocationAction();
    this.locationService.getLocations().subscribe(response => {
      this.locations = response;
    });
    /*
    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
      if (this.locations.length > 0 && this.invoice.location === undefined) {
        this.invoice.location = this.locations[0]['@id'];
        this.locationId = this.locations[0].id;
      }
    });
*/
    this.http.get(TaxRateModel.IRI).subscribe((response: TaxRateModel[]) => {
      this.taxRates = response['hydra:member'];
      this.invoice.items.map((item, i) => {
        if (item.taxRate === undefined) {
          this.taxes.push(this.taxRates[0]);
          this.invoice.items[i].taxRate = this.taxRates[0]['@id'];
          this.invoice.items[i].tax = this.taxRates[0].rate;
        }
      });
    });

    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];

      if (this.currencies.length > 0 && !this.invoice.currency) {
        this.invoice.currency = this.currencies[0]['@id'];
      }
    });
  };

  loadInvoice(): void {
    if (this.id) {

      this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
        this.invoice = response;
        this.invoice.expectedDate = new Date(this.invoice.expectedDate);
        // @ts-ignore
        this.parameters.contact = this.invoice.contact?.id;
        // @ts-ignore
        this.supplierName = this.invoice.contact.name;
        // @ts-ignore
        this.supplierEmail = this.invoice.contact.email;
        // @ts-ignore
        this.supplierTCKN = this.invoice.contact?.taxAgencyNumber;
        if (this.supplierTCKN === null) {
          this.agencyNumber = true;
        }
        // @ts-ignore
        this.supplierId = this.invoice.contact?.id;
        this.currencyLabel = this.invoice.currency?.code;
        this.invoice.contact = this.invoice.contact ? this.invoice.contact['@id'] : null;
        // @ts-ignore
        this.createdUserName = this.invoice.createdBy.firstName + ' ' + this.invoice.createdBy.lastName;
        // @ts-ignore
        this.createdUserEmail = this.invoice.createdBy.email;
        this.orderSendOn = new Date(response.createdAt);
        this.invoice.currency = this.invoice.currency ? this.invoice.currency['@id'] : null;
        this.invoice.location = this.invoice.location ? this.invoice.location['@id'] : null;
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].taxRate = item.taxRate['@id'] ? item.taxRate['@id'] : null;
          // @ts-ignore
          this.autoCompleteValues.push(item.variant);
          // @ts-ignore
          this.autoCompleteValues[i].stock = item.stock;

          this.invoice.items[i].variant = item.variant['@id'];
          this.invoice.items[i].purchaseOption = item.purchaseOption;
          if (item.stock) {
            // @ts-ignore
            this.taxes.push(item.stock?.tax);
            this.invoice.items[i].stock = item?.stock['@id'];
          }
        });

        if (!this.invoice.contact && this.invoice.customerName) {
          this.manualCustomer = true;
        }

        if (this.invoice.shipmentCode) {
          this.shipmentText = this.invoice.shipmentCode;
        }

        if (this.invoice.shipmentLink) {
          this.shipmentText = `${this.invoice.shipmentCode} - ${this.invoice.shipmentLink}`;
        }

        this.billingAddressSameAsShippingAddress = false;

        if ((this.invoice.billingAddress &&
            this.invoice.shippingAddress &&
            this.invoice.billingAddress['@id'] === this.invoice.shippingAddress['@id']) ||
          this.invoice.billingAddress === null
        ) {
          this.billingAddressSameAsShippingAddress = true;
        }

        if (this.invoice.shippingAddress) {
          this.shippingAddressString = this.invoice.shippingAddress.body;
        }

        if (this.invoice.billingAddress) {
          this.billingAddressString = this.invoice.billingAddress.body;
        }

        this.loading = false;
        this.submitted = false;
      });
    } else {
      this.http.get(`${InvoiceModel.IRI}/invoice-number?type=${this.invoice.type}`).subscribe((response: InvoiceModel) => {
        this.invoice.series = response.series;
        this.invoice.number = response.number;
        this.invoice.code = response.code;
        this.invoice.expectedDate = new Date(response.expectedDate);
        this.invoice.total = 0;
        this.invoice.subtotal = 0;
        this.invoice.tax = 0;

        if (history.state.workOrderId !== undefined) {
          this.invoice.workOrder = history.state.workOrderId;
        }

        if (history.state.ids !== undefined && history.state.ids.length > 0) {
          history.state.ids.map((item: StockVariantModel, key: number) => {
            this.autoCompleteValues.push(item);
            this.onVariantSelect(item, key);
            this.invoice.items[key].quantity = history.state.quantities[key];
            this.calculateTotalPrice(key);
          });
        }
        this.loading = false;
      });
    }
  }

  async save(): Promise<void> {
    if ( this.checkActionPermission('ORDER_SAVE_AVAILABLE')){
    this.errors = [];
    this.invoice.items.map((item, key) => {
      this.invoice.items[key].currency = this.invoice.currency;
      if (this.invoice.items[key].purchaseOption) {
        this.invoice.items[key].purchaseOption = this.invoice.items[key].purchaseOption['@id'];
      }
    });
    this.submitted = true;
    if (this.id) {
      await this.http.put(`${InvoiceModel.IRI}/${this.id}`, this.invoice).then((response: InvoiceModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          this.loadInvoice();
        }
      }).catch(err => {
      });
    } else {
      await this.http.post(InvoiceModel.IRI, this.invoice).then((response: InvoiceModel) => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          this.router.navigate([`orders/order-history`]);
        }
      }).catch(err => {
      });
    }
    }else {
      this.displayAction = true;
    }
  }

  onshowWaybillDialog(): void {
    this.showWaybillDialog = true;
    this.showInvoiceDialog = false;
  }

  onshowInvoiceDialog(): void {
    this.showInvoiceDialog = true;
    this.showWaybillDialog = false;
  }

  onUpload(event): void {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  async approveOrder(): Promise<void> {
    if ( this.checkActionPermission('ORDER_CONFIRM_AVAILABLE')){
    this.submitted = true;
    await this.http.put(`${InvoiceModel.IRI}/${this.id}`, {
      status: 'CONFIRMED'
    }).then((response: InvoiceModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        setTimeout(() => {
          this.invoice.status = response.status;
          this.submitted = false;
          window.location.href = '/orders/receive-order';
        }, 300);
      }
    }).catch(err => {
    });
    await this.http.get(`${InvoiceModel.IRI}/${this.id}/send_mail`).subscribe((response: InvoiceModel) => {
      window.location.href = '/orders/receive-order';
    });
    }else {
      this.displayAction = true;
    }
  }

  async cancelOrder(): Promise<void> {
    await this.http.put(`${InvoiceModel.IRI}/${this.id}`, {
      status: 'DECLINED'
    }).then((response: InvoiceModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.router.navigate([`/orders/receive-order`]);
        this.submitted = false;
      }
    }).catch(err => {
    });
  }

  confirm(): void {
   if ( this.checkActionPermission('ORDER_CANCEL_AVAILABLE')){
     this.confirmationService.confirm({
       message: 'Bu İşlem Geri Alınamaz .Silmek İstediğinize Eminmisiniz?',
       accept: () => {
         this.cancelOrder().then();
       }
     });
   }else {
     this.displayAction = true;
   }
  }

  onChangeQuantity(event, row, i): void {
    this.invoice.items[i].quantity = parseInt(event.target.value, 10);
    this.invoice.items[i].subtotal = row.unitPrice * parseInt(event.target.value, 10);
    let sum = 0;
    this.invoice.items.map((item, b) => {
      sum += item.subtotal;
    });
  }

  async invoiceSave(event): Promise<void> {
    // tslint:disable-next-line:radix variable-name
    const number = parseInt(event.value.match(/\d+/)[0]);
    await this.http.put(`${InvoiceModel.IRI}/${this.id}`, {contact: event.value}).then((response: InvoiceModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  calculateTotalPrice(i: number): void {
    let totalPrice = 0;
    let tax = 0;
    let discount = 0;

    if (this.invoice.items[i].quantity !== undefined && this.invoice.items[i].unitPrice !== undefined) {
      this.invoice.items[i].subtotal = this.invoice.items[i].quantity * this.invoice.items[i].unitPrice;
      this.invoice.items[i].discount = this.invoice.items[i].quantity * this.invoice.items[i].unitPrice *
        (this.invoice.items[i].discountRate / 100);
      // tslint:disable-next-line:max-line-length
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      this.taxRates.map(taxItem => {
        if (this.invoice.items[i].taxRate === taxItem['@id']) {
          this.invoice.items[i].tax = ((this.invoice.items[i].subtotal - this.invoice.items[i].discount) / 100) * taxItem.rate;
        }
      });
    }

    this.invoice.items.map(item => {
      if (item.subtotal !== undefined && item.tax !== undefined) {
        totalPrice += item.subtotal;
        tax += item.tax;
        discount += item.discount;
      }
    });

    this.invoice.subtotal = totalPrice;
    this.invoice.discount = discount;
    this.invoice.tax = tax;
    this.invoice.total = this.invoice.subtotal + this.invoice.tax - this.invoice.discount;
  }

  onCurrencyChange(): void {
    this.currencies.map(item => {
      if (item['@id'] === this.invoice.currency) {
        this.currencyLabel = item.code;
      }
    });
  }

  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  setTax(i, event): void {
    this.taxRates.map(item => {
      if (item['@id'] === event.value) {
        this.taxes[i] = item;
      }
    });
  }

  setStatus(variant: string, qty: number, i: number): void {
    if (variant !== undefined && qty !== undefined && this.invoice.type === InvoiceType.Sales) {
      this.http.get(`${variant}/status?qty=${qty}&location=${this.locationId}`).subscribe(response => {
        // @ts-ignore
        this.invoice.items[i].status = response.status;
        // @ts-ignore
        this.invoice.items[i].materialStatus = response.materialStatus;
        // @ts-ignore
        this.invoice.items[i].stockExpectedDate = response.stockExpectedDate;
      });
    }
  }

  onVariantSelect(event: StockVariantModel, i: number): void {
    this.invoice.items[i].variant = event['@id'];
    this.invoice.items[i].stock = event.stock['@id'];
    this.invoice.items[i].taxRate = event.stock['@id'];
    this.invoice.items[i].purchaseOption = null;
    if (event.stock.tax) {
      this.invoice.items[i].taxRate = event.stock.tax['@id'];
    }
    this.invoice.items[i].unitPrice = event.price;
    this.invoice.items[i].nonDiscountedUnitPrice = event.price;
    // @ts-ignore
    this.taxes.push(event.stock.tax);
  }

  print(type = null): void {
    let url = `${this.apiUrl}/invoices/${this.id}/pdf`;

    if (type) {
      url = `${url}?type=${type}`;
    }
    window.open(url, '_blank');
  }

  removeItem = (i: number) => {
    this.invoice.items.splice(i, 1);
  }
  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {name: event.query, 'stock.isActive': 1}).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }
  addItem = () => {
    if ( this.checkActionPermission('ORDER_ADD_ITEM_AVAILABLE')){
    const invoiceItem = new InvoiceItemModel();

    if (this.taxRates.length > 0) {
      this.taxes.push(this.taxRates[0]);
      invoiceItem.taxRate = this.taxRates[0]['@id'];
      invoiceItem.tax = invoiceItem.tax = this.taxRates[0].rate;
    }

    this.invoice.items.push(invoiceItem);
    }else {
      this.displayAction = true;
    }
  }


  reload(): void {
    this.displayCreate = false;
    this.displayUpdate = false;
    this.display = false;
    this.item = new StockModel();
    window.location.reload();
  }

  async edit(event): Promise<void> {
    this.loading = true;
    this.type = 'material';
    await this.http.get(`${StockModel.IRI}/${event.stock.id}`).subscribe((response) => {
      this.item = response;
      this.item.category = this.item.category ? this.item.category['@id'] : null;
      this.item.locations = this.item.locations ? this.item.locations : null;
      this.item.tax = this.item.tax ? this.item.tax['@id'] : null;
      this.item.supplier = this.item.supplier ? this.item.supplier['@id'] : null;
      this.item.unit = this.item.unit ? this.item.unit['@id'] : null;
      // @ts-ignore
      this.currency = this.item.currency ? this.item.currency.code : this.currencyService.getCurrency().code;
      this.item.currency = this.item.currency ? this.item.currency['@id'] : null;
      this.item.purchaseOptions = this.item.purchaseOptions ? this.item.purchaseOptions : null;
      this.item.purchaseOptions.map((data, i) => {
        this.http.get(UnitModel.IRI, {id: data.unit['@id']}).subscribe(unit => {
          this.item.purchaseOptions[i].unitName = unit['hydra:member'][0].name;
          this.item.purchaseOptions[i].supplier = data.supplier['@id'];
          this.item.purchaseOptions[i].unit = data.unit['@id'];
          if (data.packName === '' && data.packPerCase === null) {
            this.item.purchaseOptions[i].orderingUnit = data.packQuantity + ' ' + unit['hydra:member'][0].name;
          } else {
            this.item.purchaseOptions[i].orderingUnit = data.packName + '(' + data.packPerCase + '*' + data.packQuantity + ' ' + unit['hydra:member'][0].name + ')';
          }

        });
      });
      this.item.stockQuantities.map((stockQuantity, i) => {
        this.item.stockQuantities[i] = stockQuantity;
      });
      this.displayCreate = true;
      this.display = true;
      this.loading = false;
    });

  }

  openForm(): void {
    // this.item = null;
    this.display = true;
    this.displayCreate = true;
    this.displayUpdate = false;
  }

  openReceiveDialog(): void {
    this.receiveDialog = true;
  }

  getIncomingEinvoices(TcKnNbr): void {
    this.invoiceLoading = true;
    this.http.get(IncomingInvoiceModel.IRI, {
      contact: TcKnNbr
    }).subscribe(response => {
      this.inComingInvoices = response['hydra:member'];
      this.invoiceLoading = false;
    });
  }

  viewInvoicesPdf(ettn): void {
    this.parameters.ettn = ettn;
    this.http.get('/api/company_integrations/logo/get-invoice-inbox-pdf', this.parameters).subscribe(response => {
    });
  }

  viewInvoiceItems(id, i, currencyCode): void {
    /*
    this.parameters.invoiceId = id;
    this.http.get('/api/company_integrations/logo/invoiceItems', this.parameters).subscribe(response => {
      this.inComingInvoices[i].items = (response.items);
      this.invoiceItems = this.inComingInvoices[i];
      this.invoiceItemsShow = true;
    });
*/
    this.http.get(IncomingInvoiceModel.IRI, this.parameters).subscribe(response => {
      this.inComingInvoices = response['hydra:member'];
      this.loading = false;
    });
  }

  async setIncomingInvoice(invoiceId, relatedId, i): Promise<void> {
    this.loading = true;
    this.selectedRow = i;
    await this.http.get(IncomingInvoiceModel.IRI + '/remove-incoming-invoice/' + invoiceId).subscribe( response => {
      this.http.put(`${IncomingInvoiceModel.IRI}/${relatedId}`, {invoice: this.invoice['@id']})
        .then((res: InvoiceModel) => {
          this.loading = false;
        }).catch(err => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        });
    });
  }

  addAgencyNUmber(event, supplierId): void {
    this.loading = true;
    if (event === null) {
      this.agencyNumberNotNull = true;
      this.loading = false;
    } else if (this.supplierEmail === null) {
      this.agencyEmailNotNull = true;
      this.loading = false;
    } else {
      this.agencyNumberNotNull = false;
      this.http.put(`${ContactModel.IRI}/${supplierId}`, {
        taxAgencyNumber: event,
        email: this.supplierEmail
      }).then(response => {
        window.location.reload();
      });
    }
  }

  againSendMail(invoiceId): void {
    this.http.get(InvoiceModel.IRI + '/' + invoiceId + '/send_mail').subscribe(response => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      }
    });
  }

  createSaleInvoice(): void {
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].orders.findIndex(obj => obj.name === text);
      return this.actions[0].orders[findIndex].value ? this.actions[0].orders[findIndex].value : false;
    }
  }
}

