import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../../service/http.service';
import {environment} from '../../../../environments/environment';
import {LazyLoadEvent} from 'primeng/api';
import {StockModel} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import {ContactService} from '../../../service/contact.service';
import {UtilityService} from '../../../service/utility.service';
import {LocationModel} from '../../../model/location.model';
import {LocationService} from '../../../service/location.service';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.scss']
})
export class PurchaseReportComponent implements OnInit {
  apiUrl: string;
  loading: boolean;
  report: any [];
  parameters = {
    contact: '',
    location: '',
    name: '',
  };
  searchContacts: ContactModel[] = [];
  locations: LocationModel[] = [];
  tableLoading: boolean;
  constructor(private translate: TranslateService, private http: HttpService,
              private contactService: ContactService, private locationService: LocationService,
              private utilityService: UtilityService ) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(`${ContactModel.IRI}/get-contact-by-type/supplier`).subscribe(category => {
      this.searchContacts = category['hydra:member'];
    });
    this.http.get(LocationModel.IRI + '/subscriptionControl', {pagination: false}).subscribe(response => {
      this.locations = response;
      this.loading = false;
    });
  }
  load(): void{
    this.tableLoading = true;
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    this.http.get('/api/reports/purchase-report', this.parameters).subscribe(response => {
      this.report = response[0].result;
      this.tableLoading = false;
    });
  }

  changeNameInput(event): void {
    this.parameters.name = event.target.value;
  }
  changeContact(event): void {
    // const choseId = event.value.split('/');
    this.parameters.contact = event.value;
  }
  changeLocation(event): void {
    this.parameters.location = event.value;
  }

  export(): void {
    const parameters = {...this.parameters};

    this.http.download('/api/data/exports/purchaseReport', parameters)
      .subscribe(r => {this.utilityService.downloadFile(r, 'last_purchase_prices_report.xlsx'); });
  }

}
