<div class="p-grid" *ngIf="needLogin">
  <div class="p-col-4 p-offset-4">
    <form action="" (ngSubmit)="send()">
      <p-card class="p-mt-6">
        <div class="p-p-3 p-text-center">
          <img [src]="apiUrl + '/images/logo.png'" class="logo" alt="">
        </div>
        <div class="p-fluid p-pl-3 p-pt-3 p-pr-3">
          <div class="p-field">
            <label for="email">{{'EMAIL' | translate}}</label>
            <input id="email" type="text" pInputText [(ngModel)]="email" name="email">
          </div>
        </div>
        <ng-template pTemplate="footer">
          <div class="p-pl-3 p-pb-3">
            <a routerLink="/login">{{'LOGIN'|translate}}</a>
          </div>
          <div class="p-pl-3 p-pb-3">
            <p-button [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'" type="submit" label="{{'CONTINUE' | translate}}"></p-button>
          </div>
        </ng-template>
      </p-card>
    </form>
  </div>
</div>
