import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {UserModel} from '../../../model/user.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {PermissionModel} from '../../../model/permission.model';
import {Subscription} from 'rxjs';
import {ValidationService} from '../../../service/validation.service';
import {OperationModel} from '../../../model/operation.model';
import { LocationModel } from 'src/app/model/location.model';
import {RoleModel} from '../../../model/role.model';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceModel} from '../../../model/invoice.model';
import {environment} from '../../../../environments/environment';
import {CurrencyModel} from '../../../model/currency.model';


@Component({
  selector: 'app-user-list',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: UserModel[] = [];
  operations: OperationModel[] = [];
  user: UserModel = new UserModel();
  loading = false;
  showForm = false;
  showPasswordForm = false;
  submitted = false;
  id: number;
  permissions: PermissionModel[] = [];
  roles: RoleModel[] = [];

  languages = [
    {
      name: 'Türkçe',
      value: 'tr',
    },
    {
      name: 'English',
      value: 'en',
    },
    {
      name: 'Deutsch',
      value: 'de',
    },
    {
      name: 'Français',
      value: 'fr',
    },
    {
      name: 'Русский',
      value: 'ru',
    },
    {
      name: 'Español',
      value: 'es',
    },
    {
      name: 'Nederlands',
      value: 'nl',
    },
    {
      name: 'عربي',
      value: 'ar',
    },
  ];
  errors = [];
  validationSubscriber: Subscription;
  locations: LocationModel[] = [];
  apiUrl: string;
  parameters = {
    page: 1,
    itemsPerPage: 100,
    pagination: false,
  };

  constructor(private http: HttpService, private flashMessageService: FlashMessageService, private validationService: ValidationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.loadUsers();
    this.loadPermissions();
    this.loadOperations();
    this.loadLocations();
    this.loadRoles();
  }

  loadUsers(): void {
    this.loading = true;
    this.http.get(UserModel.IRI, this.parameters).subscribe((response: UserModel[]) => {
      this.users = response['hydra:member'];
      this.loading = false;
    });
  }

  loadPermissions(): void {
    this.http.get(PermissionModel.IRI).subscribe((response: PermissionModel[]) => {
      this.permissions = response['hydra:member'];
    });
  }

  loadRoles(): void {
    this.http.get(RoleModel.IRI).subscribe((response: RoleModel[]) => {
      this.roles = response['hydra:member'];
      // @ts-ignore
      this.roles.unshift({ name: this.translate.instant('SELECT_ROLES'), value: ''});
    });
  }

  loadOperations(): void {
    this.http.get(OperationModel.IRI).subscribe((response: OperationModel[]) => {
      this.operations = response['hydra:member'];
    });
  }
  loadLocations(): void {
    this.http.get(LocationModel.IRI, this.parameters).subscribe(response => {
      this.locations = response['hydra:member'];
    });
  }
  openForm(): void {
    this.showForm = true;
    this.user = new UserModel();
  }

  hideForm(): void {
    this.errors = [];
    this.showForm = false;
    this.showPasswordForm = false;
    this.id = null;
  }

  async save(): Promise<void> {
    this.submitted = true;
    this.errors = [];
    if (this.id) {
      await this.http.put(`${UserModel.IRI}/${this.user.id}`, this.user).then((response: UserModel) => {
        this.loadUsers();
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      });
    } else {
      this.user.roles = ['ROLE_USER'];
      await this.http.post(UserModel.IRI, this.user).then((response: UserModel) => {
        if (response !== undefined) {
          this.loadUsers();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.successCreated'));
        }
      });
    }

    this.submitted = false;

    if (this.errors.length < 1) {
      this.hideForm();
    }
    window.localStorage.removeItem('language');
    window.localStorage.setItem('language', this.user.language);
  }

  async edit(user: UserModel): Promise<void> {
    this.openForm();
    this.user = {...user};
    console.log(this.user.permissions);
    this.user.permissions.map((permission, i: number) => {
      this.user.permissions[i] = permission['@id'];
    });
    this.id = user.id;
    this.user.locations.map((locations, i: number) => {
      this.user.locations[i] = locations['@id'];
    });
    this.user.roleType = this.user.roleType['@id'];
  }

  openChangePassword(user: UserModel): void {
    this.user = {...user};
    this.id = user.id;
    this.showPasswordForm = true;
  }

  async changePassword(): Promise<void> {
    this.submitted = true;
    this.user.permissions.map((per, i) => {
      this.user.permissions[i] = per['@id'];
    });
    this.user.locations.map((loc, i) => {
      this.user.locations[i] = loc['@id'];
    });
    this.user.roleType = this.user.roleType['@id'];
    await this.http.put(`${UserModel.IRI}/${this.user.id}/change-password`, this.user).then((response: UserModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      this.submitted = false;
      this.loadUsers();
    });

    this.hideForm();
  }

  async delete(user: UserModel): Promise<void> {
    await this.http.delete(`${UserModel.IRI}/${user.id}`).then(() => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'flashMessages.successDeleted'));
    });
    this.loadUsers();
  }
  onChangeRole(event): void{
    const choseId = event.value.split('/');
    this.http.get(`${RoleModel.IRI}/${choseId[3]}`).subscribe((response: RoleModel) => {
      this.user.permissions = [];
      response.permissions.map((item, i) => {
        this.user.permissions.push(item['@id']);
      });
    });
  }
}
