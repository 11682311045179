import { Component, OnInit } from '@angular/core';
import {CookBookCategoryModel} from '../../../../model/cook-book-category.model';
import {CookBookCategoryService} from '../../../../service/cook-book-category.service';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';

@Component({
  selector: 'app-create-cook-book-category',
  templateUrl: './create-cook-book-category.component.html',
  styleUrls: ['./create-cook-book-category.component.scss']
})
export class CreateCookBookCategoryComponent implements OnInit {

  item: CookBookCategoryModel = new CookBookCategoryModel();
  submitted: boolean;
  errors = [];

  constructor(private categoryService: CookBookCategoryService,
              private flashMessageService: FlashMessageService,
              private ref: DynamicDialogRef,
              private validationService: ValidationService,
              private config: DynamicDialogConfig,
              private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.config.data?.item) {
      this.item = this.config.data.item;
    }

    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
  }

  async save(): Promise<void> {
    this.errors = [];
    this.submitted = true;

    if (this.item.id) {
      await this.categoryService.update(this.item).then(response => {
        if (response !== undefined) {
          this.categoryService.getItemsAction({isActive: true});
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    } else {
      this.item.type = this.config.data;
      this.item.parent = this.config.data;
      await this.categoryService.save(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }

    this.submitted = false;
  }

  close(): void {
    this.ref.close();
  }

}
