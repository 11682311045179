import {Component, OnInit} from '@angular/core';
import {InvoiceModel, InvoiceStatus, InvoiceStockStatus, InvoiceType} from '../../../model/invoice.model';
import {ShipmentModel} from '../../../model/shipment.model';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {InvoiceItemModel} from '../../../model/invoice-item.model';
import {LocationModel} from '../../../model/location.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {CurrencyModel} from '../../../model/currency.model';
import {ContactModel} from '../../../model/contact.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {Subscription} from 'rxjs';
import {CompanyModel} from '../../../model/company.model';
import {StockService} from '../../../service/stock.service';
import {CurrencyService} from '../../../service/currency.service';
import {ValidationService} from '../../../service/validation.service';
import {ContactService} from '../../../service/contact.service';
import {environment} from '../../../../environments/environment';
import {StockModel} from '../../../model/stock.model';
import {CompanyIntegrationModel} from '../../../model/company-integration.model';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-invoice-receive-list',
  templateUrl: './invoice-receive-list.component.html',
  styleUrls: ['./invoice-receive-list.component.scss']
})
export class InvoiceReceiveListComponent implements OnInit {

  showInvoiceDialog: boolean;
  showWaybillDialog: boolean;
  invoice: InvoiceModel = new InvoiceModel();
  shipment: ShipmentModel = new ShipmentModel();
  submitted: boolean;
  loading: boolean;
  id: any;
  uploadedFiles: any[] = [];
  contacts: ContactModel[] = [];
  locations: LocationModel[] = [];
  taxRates: TaxRateModel[] = [];
  currencies: CurrencyModel[] = [];
  discountLoading = false;
  confirmLoading = true;
  variants: StockVariantModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  apiUrl: string;
  printItems: MenuItem[];
  taxes: TaxRateModel[] = [];
  status: string;
  currencyLabel: string;
  locationId: number;
  manualCustomer: boolean;
  shipmentText: string;
  billingAddressSameAsShippingAddress = true;
  shippingAddressString: string;
  billingAddressString: string;
  invoiceTypes = InvoiceType;
  invoiceStatus = InvoiceStatus;
  stockSubscriber: Subscription;
  validationSubscriber: Subscription;
  errors = [];
  company: CompanyModel = new CompanyModel();
  billable: boolean;
  sendingBill: boolean;
  createdUserName: string;
  createdUserEmail: string;
  orderSendOn: any;
  locationName: '';
  supplierName: '';
  deliveryTime: '';

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private flashMessageService: FlashMessageService,
              private confirmationService: ConfirmationService,
              private stockService: StockService, private currencyService: CurrencyService,
              private validationService: ValidationService, private contactService: ContactService,
              private translate: TranslateService,
              private router: Router) { }

  ngOnInit(): void {

    this.invoice.currency = this.currencyService.getCurrency()['@id'];
    this.currencyLabel = this.currencyService.getCurrency().code;

    if (history.state.status !== undefined) {
      this.invoice.status = history.state.status;
    } else {
      this.invoice.status = InvoiceStatus.Confirmed;
    }

    this.route.data.subscribe(data => {this.invoice.type =  data.type; });
    // tslint:disable-next-line:radix
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loading = true;
    if (this.id) {

      this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
        this.invoice = response;
        this.invoice.expectedDate = new Date(this.invoice.expectedDate);
        this.currencyLabel = this.invoice.currency?.code;
        this.invoice.contact = this.invoice.contact ? this.invoice.contact['@id'] : null;
        // this.createdUserName = this.invoice.createdBy[0].firstName + ' ' + this.invoice.createdBy[0].lastName;
        // this.createdUserEmail = this.invoice.createdBy[0].email;
        this.orderSendOn = new Date(response.createdAt);
        this.invoice.currency = this.invoice.currency ? this.invoice.currency['@id'] : null;
        this.invoice.location = this.invoice.location ? this.invoice.location['@id'] : null;
        this.invoice.items.map((item, i) => {
          this.invoice.items[i].taxRate = item.taxRate['@id'];
          // @ts-ignore
          this.autoCompleteValues.push(item.variant);
          // @ts-ignore
          this.autoCompleteValues[i].stock = item.stock;

          this.invoice.items[i].variant = item.variant['@id'];
          this.invoice.items[i].purchaseOption = item.purchaseOption;
          if (item.stock) {
            // @ts-ignore
            this.taxes.push(item.stock?.tax);
            this.invoice.items[i].stock = item?.stock['@id'];
          }
        });

        if (!this.invoice.contact && this.invoice.customerName) {
          this.manualCustomer = true;
        }

        if (this.invoice.shipmentCode) {
          this.shipmentText = this.invoice.shipmentCode;
        }

        if (this.invoice.shipmentLink) {
          this.shipmentText = `${this.invoice.shipmentCode} - ${this.invoice.shipmentLink}`;
        }

        this.billingAddressSameAsShippingAddress = false;

        if ((this.invoice.billingAddress &&
          this.invoice.shippingAddress &&
          this.invoice.billingAddress['@id'] === this.invoice.shippingAddress['@id']) ||
          this.invoice.billingAddress === null
        ) {
          this.billingAddressSameAsShippingAddress = true;
        }

        if (this.invoice.shippingAddress) {
          this.shippingAddressString = this.invoice.shippingAddress.body;
        }

        if (this.invoice.billingAddress) {
          this.billingAddressString = this.invoice.billingAddress.body;
        }

        this.loading = false;
      });
    } else {
      this.http.get(`${InvoiceModel.IRI}/invoice-number?type=${this.invoice.type}`).subscribe((response: InvoiceModel) => {
        this.invoice.series = response.series;
        this.invoice.number = response.number;
        this.invoice.code = response.code;
        this.invoice.expectedDate = new Date(response.expectedDate);
        this.invoice.total = 0;
        this.invoice.subtotal = 0;
        this.invoice.tax = 0;

        if (history.state.workOrderId !== undefined) {
          this.invoice.workOrder = history.state.workOrderId;
        }

        if (history.state.ids !== undefined && history.state.ids.length > 0) {
          history.state.ids.map((item: StockVariantModel, key: number) => {
            this.autoCompleteValues.push(item);
            this.onVariantSelect(item, key);
            this.invoice.items[key].quantity = history.state.quantities[key];
            this.calculateTotalPrice(key);
          });
        }
        this.loading = false;
      });
    }

    this.loadFormData();

    this.apiUrl = environment.apiUrl;

    this.validationSubscriber = this.validationService.errors.subscribe((data: any) => {
      if (data) {
        this.errors = data;
      }
    });

    this.stockSubscriber = this.stockService.stocksData.subscribe((data: StockModel) => {
      if (data) {

        const invoiceItem = new InvoiceItemModel();
        invoiceItem.variant = data.variants[0]['@id'];
        invoiceItem.unitPrice = data.variants[0].salePrice;
        invoiceItem.quantity = 1;
        invoiceItem.subtotal = invoiceItem.quantity * invoiceItem.unitPrice;
        // @ts-ignore
        invoiceItem.tax = (invoiceItem.subtotal / 100) * data.tax.rate;
        invoiceItem.stock = data['@id'];
        invoiceItem.status = InvoiceStockStatus.StatusNotAvailable;
        invoiceItem.materialStatus = InvoiceStockStatus.StatusNotRecipe;
        // @ts-ignore
        invoiceItem.taxRate = data.tax['@id'];

        this.autoCompleteValues.push(data.variants[0]);
        this.invoice.items[this.invoice.items.length - 1] = invoiceItem;
        this.calculateTotalPrice(this.invoice.items.length - 1);
      }
    });

    setTimeout(() => {
      this.printItems = [
        {label: this.translate.instant('PRINT_QUOTE'), command: () => this.print('quote')},
        {label: this.translate.instant('PRINT_WITHOUT_PRICE'), command: () => this.print('withoutPrice')},
        {label: this.translate.instant('PRINT_PICK_LIST'), command: () => this.print('picklist')},
      ];
    }, 500);

    this.company = JSON.parse(localStorage.getItem('company'));

    if (this.company) {
      this.http.get(CompanyIntegrationModel.IRI, {'company.id': this.company.id}).subscribe(response => {
        if (response['hydra:totalItems'] > 0) {
          this.billable = true;
        }
      });
    }
  }
  loadFormData = () => {

    this.contactService.getContactsAction({order: '[name]=asc', pagination: false});
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.contacts = response['hydra:member'];
      }
    });

    this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response: LocationModel[]) => {
      this.locations = response;
      if (this.locations.length > 0 && this.invoice.location === undefined) {
        this.invoice.location = this.locations[0]['@id'];
        this.locationId = this.locations[0].id;
      }
    });

    this.http.get(TaxRateModel.IRI).subscribe((response: TaxRateModel[]) => {
      this.taxRates = response['hydra:member'];
      this.invoice.items.map((item, i) => {
        if (item.taxRate === undefined) {
          this.taxes.push(this.taxRates[0]);
          this.invoice.items[i].taxRate = this.taxRates[0]['@id'];
          this.invoice.items[i].tax = this.taxRates[0].rate;
        }
      });
    });

    this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];

      if (this.currencies.length > 0 && !this.invoice.currency) {
        this.invoice.currency = this.currencies[0]['@id'];
      }
    });
  }
  loadInvoice(): void {
    this.loading = true;
    this.id = this.route.snapshot.paramMap.get('id');
    this.http.get(`${InvoiceModel.IRI}/${this.id}`).subscribe((response: InvoiceModel) => {
      this.invoice = response;
      this.invoice.expectedDate = new Date(this.invoice.expectedDate);
      this.invoice.contact = this.invoice.contact ? this.invoice.contact['@id'] : null;
      this.currencyLabel = this.invoice.currency?.code;
      this.invoice.currency = this.invoice.currency ? this.invoice.currency['@id'] : null;
      this.invoice.location = this.invoice.location ? this.invoice.location['@id'] : null;
      this.invoice.items.map((item, i) => {
        this.invoice.items[i].taxRate = item.taxRate['@id'];
        this.invoice.items[i].currency = item.currency['@id'];
      });
      this.loading = false;
    });
  }
  async save(): Promise<void> {
    this.errors = [];

    this.invoice.items.map((item, key) => {
      this.invoice.items[key].currency = this.invoice.currency;
    });

    this.submitted = true;
    if (this.id) {
      await this.http.put(`${InvoiceModel.IRI}/${this.id}`, this.invoice).then((response: InvoiceModel) => {
        if (response) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      }).catch(err => {
      });
    } else {
      await this.http.post(InvoiceModel.IRI, this.invoice).then((response: InvoiceModel) => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          this.router.navigate([`/purchase/${response.id}/edit`]);
        }
      }).catch(err => {
      });
    }

    this.submitted = false;
  }
  onshowWaybillDialog(): void {
    this.showWaybillDialog = true;
    this.showInvoiceDialog = false;
  }
  onshowInvoiceDialog(): void {
    this.showInvoiceDialog = true;
    this.showWaybillDialog = false;
  }
  onUpload(event): void {
    for ( const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }
  async approveOrder(): Promise<void> {
    this.submitted = true;
    await this.http.put(`${InvoiceModel.IRI}/${this.id}`, {
      status: 'CONFIRMED'
    }).then((response: InvoiceModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.invoice.status = response.status;
        this.submitted = false;
      }
    }).catch(err => {
    });
    this.http.get(`${InvoiceModel.IRI}/${this.id}/send_mail`).subscribe((response: InvoiceModel) => {
    });
  }
  async cancelOrder(): Promise<void> {
    await this.http.put(`${InvoiceModel.IRI}/${this.id}`, {
      status: 'DECLINED'
    }).then((response: InvoiceModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.router.navigate([`/purchases`]);
        this.submitted = false;
      }
    }).catch(err => {
    });
  }
  confirm(): void {
    this.confirmationService.confirm({
      message: 'Bu İşlem Geri Alınamaz .Silmek İstediğinize Eminmisiniz?',
      accept: () => {
        this.cancelOrder().then();
      }
    });
  }
  onChangeQuantity(event, row, i): void {
    this.invoice.items[i].quantity = parseInt(event.target.value, 10);
    this.invoice.items[i].subtotal = row.unitPrice * parseInt(event.target.value, 10);
    let sum = 0;
    this.invoice.items.map((item, b) => {
      sum +=  item.subtotal;
    });
  }
  async invoiceSave(): Promise<void> {
    await this.http.put(`${InvoiceModel.IRI}/${this.id}`, this.invoice).then((response: InvoiceModel) => {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
    });
  }
  calculateTotalPrice(i: number): void {
    let totalPrice = 0;
    let tax = 0;

    if (this.invoice.items[i].quantity !== undefined && this.invoice.items[i].unitPrice !== undefined) {
      this.invoice.items[i].subtotal = this.invoice.items[i].quantity * this.invoice.items[i].unitPrice;
      // tslint:disable-next-line:max-line-length
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      this.taxRates.map(taxItem => {
        if (this.invoice.items[i].taxRate === taxItem['@id']) {
          this.invoice.items[i].tax = (this.invoice.items[i].subtotal / 100) * taxItem.rate;
        }
      });
    }

    this.invoice.items.map(item => {
      if (item.subtotal !== undefined && item.tax !== undefined) {
        totalPrice += item.subtotal;
        tax += item.tax;
      }
    });

    this.invoice.subtotal = totalPrice;
    this.invoice.tax = tax;
    this.invoice.total = this.invoice.subtotal + this.invoice.tax;
  }
  onCurrencyChange(): void {
    this.currencies.map(item => {
      if (item['@id'] === this.invoice.currency) {
        this.currencyLabel = item.code;
      }
    });
  }
  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }
  setTax(i, event): void {
    this.taxRates.map(item => {
      if (item['@id'] === event.value) {
        this.taxes[i] = item;
      }
    });
  }
  setStatus(variant: string, qty: number, i: number): void {
    if (variant !== undefined && qty !== undefined && this.invoice.type === InvoiceType.Sales) {
      this.http.get(`${variant}/status?qty=${qty}&location=${this.locationId}`).subscribe(response => {
        // @ts-ignore
        this.invoice.items[i].status = response.status;
        // @ts-ignore
        this.invoice.items[i].materialStatus = response.materialStatus;
        // @ts-ignore
        this.invoice.items[i].stockExpectedDate = response.stockExpectedDate;
      });
    }
  }
  onVariantSelect(event: StockVariantModel, i: number): void {
    this.invoice.items[i].variant = event['@id'];
    this.invoice.items[i].stock = event.stock['@id'];
    if (this.invoice.type === InvoiceType.Sales) {
      this.invoice.items[i].unitPrice = event.salePrice;
      this.invoice.items[i].nonDiscountedUnitPrice = event.salePrice;
    } else {
      this.invoice.items[i].unitPrice = event.defaultPrice;
      this.invoice.items[i].nonDiscountedUnitPrice = event.defaultPrice;
    }

    if (event.stock.tax) {
      this.invoice.items[i].taxRate = event.stock.tax['@id'];
    }

    // @ts-ignore
    this.taxes.push(event.stock.tax);

    if (this.invoice.discountRate !== undefined) {
      this.invoice.items[i].unitPrice = event.salePrice / 100 * (100 - this.invoice.discountRate);
    } else {
      this.invoice.items[i].unitPrice = this.invoice.items[i].nonDiscountedUnitPrice;
    }
  }
  print(type = null): void {
    let url = `${this.apiUrl}/invoices/${this.id}/pdf`;

    if (type) {
      url = `${url}?type=${type}`;
    }
    window.open(url, '_blank');
  }
  removeItem = (i: number) => {
    this.invoice.items.splice(i, 1);
  }
}
