import {ApiResourceModel} from './api-resource.model';
import {LocationFunctionModel} from './location-function.model';
import {CurrencyModel} from './currency.model';
import {ContactModel} from './contact.model';
import {LocationExpenseModel} from './location-expense.model';

export class LocationModel extends ApiResourceModel{
  public static IRI = '/api/locations';
  public name: string;
  public functions: LocationFunctionModel[] = [];
  public parent: string;
  public isActive = true;
  public code: string;
  public number: number;
  public parentId: string;
  public id: number| null;
  public isDefault: boolean;
  public inventoryCountType: string;
  public storeId: string;
  public currency: CurrencyModel|string;
  public contact: ContactModel|string;
  public accountingCode: any;
  public locationSubscriptions: any;
  public locationExpenses: LocationExpenseModel| any;
  public companyName: any;
  public companyAddress: any;
  public taxOffice: any;
  public taxNumber: any;
  public data: any;
  public city: string;
  public zipCode: string;
}

export interface TreeNode {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}
