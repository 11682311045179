<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>


  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'RECEIVE_ORDERS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'RECEIVE_ORDERS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <app-flash-messages></app-flash-messages>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">


    </div>
  </div>
  <ng-template pTemplate="header">
    <span class="p-buttonset">
      <button pButton pRipple [routerLink]="['/purchases']" label="Geri" icon="pi pi-arrow-left" class="p-button-secondary"></button>
      <button *ngIf="invoice.status == 'CONFIRMED' && invoice.isReceived === false" pButton pRipple label="İrsaliye İle Teslim Alın" (click)="onshowWaybillDialog()" icon="pi pi-check" class="p-button-primary"></button>
      <button *ngIf="invoice.status == 'CONFIRMED' && invoice.isReceived === false" pButton pRipple label="Fatura  İle Teslim Alın" (click)="onshowInvoiceDialog()" icon="pi pi-check" class="p-button-primary"></button>
      <button *ngIf="invoice.status == 'WAITING_FOR_APPROVAL'"  pButton pRipple label="Siparişi Onayla" icon="{{ submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'}}"  (click)="approveOrder()" class="p-button-success"></button>
      <button *ngIf="invoice.isReceived"  pButton pRipple label="View Invoice" icon="{{ 'pi pi-eye'}}" [routerLink]="['/purchases/receive-manuel/'+ invoice.id]" class="p-button-success"></button>
      <button pButton pRipple label="Siparişi İptal Et" icon="pi pi-times" (click)="confirm()"  [disabled]="invoice.isReceived" class="p-button-danger"></button>
    </span>
  </ng-template>

  <div *ngIf="!loading">
    <div class="p-fluid p-formgrid p-grid paddingBottom">
      <div class="p-field p-col-2 paddingBottom">
        <b>{{ (invoice.status === invoiceStatus.Pending ?  'QUOTE_NUMBER': 'ORDER_NUMBER')|translate}}</b>
        <input id="code" [class.border-0]="true"  type="text" pInputText [(ngModel)]="invoice.code" [disabled]="invoice.isReceived" >
        <small class="p-error">{{ errors['code'] }}</small>
      </div>
      <div class="p-field p-col-2 paddingBottom">
        <b>{{ 'SEND_BY_EMAIL' | translate}} </b><br>
        {{ 'SEND_TO' | translate}}<br>
        {{ 'ORDER_SEND_ON' | translate}}
      </div>
      <div class="p-field p-col-2 paddingBottom">
        {{ createdUserName }}<br>
        ramazan.demir@fgaa.com<br>
        <input id="createdAt" type="text" pInputText title="Created At" [value]="invoice.createdAt|date: 'M/d/yyyy'" [disabled]="true"/>
      </div>
      <div class="p-field p-col-6 paddingBottom">
        {{ invoice.description}}
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid paddingBottom">
      <div class="p-field p-col-2 paddingBottom">
        <b>{{'ITEMS'|translate}}</b>
        <br>
        <b>{{ invoice.items.length }}</b>
      </div>
      <div class="p-field p-col-2 paddingBottom">
        <b>{{'SUPPLIER'|translate}}</b><br>
        {{ 'DELIVERY_TIME' | translate}}
      </div>
      <div class="p-field p-col-2 paddingBottom">
        <p-dropdown [options]="contacts" *ngIf="!manualCustomer" filter="true" [placeholder]="'CONTACT_PLACEHOLDER'|translate" [required]="false" [(ngModel)]="invoice.contact" optionLabel="name" id="supplier" (onChange)="onContactsSelect($event)" [disabled]="invoice.isReceived" optionValue="@id"></p-dropdown>
        <p-calendar [(ngModel)]="invoice.expectedDate" [disabled]="invoice.isReceived"></p-calendar>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid paddingBottom">
      <div class="p-field p-col-2 paddingBottom">
        <b>{{'TOTAL'|translate}}</b>
        <br>
        {{ invoice.total|currency: currencyLabel }}
      </div>
      <div class="p-field p-col-2 paddingBottom">
        <b>{{'LOCATION'|translate}}</b>
      </div>
      <div class="p-field p-col-2 paddingBottom">

        <p-dropdown id="location" [options]="locations" [(ngModel)]="invoice.location"  [disabled]="invoice.isReceived" optionLabel="name"  optionValue="@id"></p-dropdown>
      </div>
    </div>
    <div [style]="{display: 'none'}" class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="tags">{{ 'TAGS'|translate}}</label>
        <input id="tags"  type="text" pInputText [(ngModel)]="invoice.tags" [disabled]="invoice.isReceived">
      </div>
      <div class="p-field p-col-4">
        <label for="location">{{ 'LOCATION' | translate}}</label>
        <small class="p-error">{{ errors['location'] }}</small>
      </div>
    </div>
    <div [style]="{display: 'none'}" class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="supplier" class="p-pb-2">{{ (invoice.type == invoiceTypes.Sales ? 'CUSTOMER' : 'SUPPLIER')|translate }}</label>
        <div class="p-grid">
          <div class="p-col-11">
            <input id="customerName" *ngIf="manualCustomer" type="text" pInputText [(ngModel)]="invoice.customerName" [disabled]="invoice.isReceived" autofocus>
          </div>
        </div>
      </div>
      <div class="p-field p-col-4">
        <label for="currency">{{ 'CURRENCY' | translate}}</label>
        <p-dropdown id="currency" [options]="currencies" [(ngModel)]="invoice.currency" (onChange)="onCurrencyChange()"  [disabled]="invoice.isReceived" optionLabel="code"  optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['currency'] }}</small>
      </div>
    </div>
    <div [style]="{display: 'none'}" class="p-fluid p-formgrid p-grid" *ngIf="invoice.type === invoiceTypes.Sales">
      <div class="p-field p-col-8"></div>
      <div class="p-field p-col-4">
        <label for="currency">{{ 'TRACKING_INFO' | translate}}</label>
        <br>
        <p-overlayPanel #op [showCloseIcon]="true" (onHide)="shipmentText = (invoice.shipmentCode ? invoice.shipmentCode : '') + (invoice.shipmentLink ? ' - ' + invoice.shipmentLink : '' )">
          <ng-template pTemplate>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="shipmentCode">{{ 'SHIPMENT_CODE' | translate}}</label>
                <input id="shipmentCode" type="text" pInputText [(ngModel)]="invoice.shipmentCode" />
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="shipmentLink">{{ 'SHIPMENT_LINK' | translate}}</label>
                <input id="shipmentLink" type="text" pInputText [(ngModel)]="invoice.shipmentLink" />
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
        <p-button [label]="shipmentText" class="p-d-inline-block" icon="pi pi-pencil" styleClass="p-button-text p-button-secondary" (click)="op.toggle($event)"></p-button>
      </div>
    </div>
    <p-table [value]="invoice.items" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'CODE' | translate}} <app-tooltip [description]="'TOOLTIP_CODE'"></app-tooltip></th>
          <th>{{ 'STOCK' | translate}} <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip></th>
          <th>{{ 'NOTES' | translate}} <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip></th>
          <th>{{ 'QUANTITY' | translate}} <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip></th>
          <th>{{ 'UNIT_PRICE' | translate}} <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip></th>
          <th>{{ 'TOTAL_PRICE' | translate}} <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip></th>
          <th>{{ 'TAX' | translate}} <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip></th>
          <th *ngIf="invoice.type == invoiceTypes.Sales">{{ 'STOCK_STATUS' | translate}} <app-tooltip [description]="'TOOLTIP_STOCK_STATUS'"></app-tooltip></th>
          <th *ngIf="invoice.type == invoiceTypes.Sales">{{ 'MATERIAL_STATUS' | translate}} <app-tooltip [description]="'TOOLTIP_MATERIAL_STATUS'"></app-tooltip></th>
          <th width="10%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td>
            {{ item.purchaseOption === null ? autoCompleteValues[i] ? autoCompleteValues[i].code : '' : item.purchaseOption.code }}
          </td>
          <td>
            {{ item.purchaseOption === null ? autoCompleteValues[i] ? autoCompleteValues[i].name : '' : item.purchaseOption.name }}
            <br>{{ item.purchaseOption !== null ? item.orderType : '' }}
          </td>
          <td pEditableColumn class="{{ hasErrors('notes', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="item.notes" name="notes" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.notes}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity" [min]="item.incomingQuantity" [disabled]="item.isReceived" (ngModelChange)=" setStatus(item.variant, item.quantity, i); calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.quantity}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.unitPrice" mode="decimal" minFractionDigits="2" maxFractionDigits="2" [disabled]="item.isReceived || discountLoading" (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{item.unitPrice|currency: currencyLabel: ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{item.subtotal|currency: currencyLabel: ''}}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [options]="taxRates" [(ngModel)]="item.taxRate" (onChange)="calculateTotalPrice(i); setTax(i, $event)" optionLabel="name" id="tax" optionValue="@id"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{taxes.length > i ? taxes[i]?.name : ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td *ngIf="invoice.type == invoiceTypes.Sales">
            <p-tag [severity]="item.status|statusLabel" *ngIf="item.status !== undefined" class="status">{{ item.status|status:item.stockExpectedDate }}</p-tag>
          </td>
          <td *ngIf="invoice.type == invoiceTypes.Sales">
            <p-tag [severity]="item.materialStatus|statusLabel" *ngIf="item.materialStatus !== undefined" severity="success" class="status">{{ item.materialStatus|status: item.stockExpectedDate }}</p-tag>
          </td>
          <td align="right" [style]="{textAlign: 'right'}">
            <button pButton label="" *ngIf="invoice.type == invoiceTypes.Sales && invoice.id !== undefined" routerLink="/work-orders/create" [state]="{description: item.notes, quantity: item.quantity - item.workOrderCreatedQuantity, invoice: invoice['@id'], invoiceItem: item['@id'], variant: autoCompleteValues[i]}" icon="pi pi-cog" class="p-button-outlined p-mr-2" iconPos="left"></button>
            <button  [disabled]="item.isReceived || item.isWorkOrderCreated" pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="comment"></label>
        <p-editor id="comment" [(ngModel)]="invoice.description" [style]="{'height':'120px'}"></p-editor>
      </div>
    </div>
    <table class="total-box p-p-3">
      <tr>
        <th>{{ 'SUBTOTAL' | translate}}</th>
        <td>{{ invoice.subtotal|currency: currencyLabel }}</td>
      </tr>
      <tr>
        <th>{{ 'TAX' | translate}}</th>
        <td>{{ invoice.tax|currency: currencyLabel }}</td>
      </tr>
      <tr>
        <th>{{ 'TOTAL' | translate}}</th>
        <td>{{ invoice.total|currency: currencyLabel }}</td>
      </tr>
    </table>
    <div class="clearfix"></div>
    <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted || invoice.isReceived" (click)="save()"></button>

  </div>

</p-card>
<p-dialog [(visible)]="showWaybillDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  <div class="p-fluid">
    <div class="p-field col12">
      <p>Supplier: <b></b></p>
      <p>Code: <b>{{ invoice.code}}</b></p>
    </div>
  </div>
  <div class="card">
    <h5 class="h5-title">Dosya Yükleme </h5>
    <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)"
                  multiple="multiple" accept="image/*" maxFileSize="1000000">
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="p-fluid">
    <div class="p-field col6">
      <button pButton  label="İRSALİYE TARAMA İLE AL"></button>
    </div>
    <div class="p-field col6-right">
      <button pButton [routerLink]="['/order/receive-manuel/'+ invoice.id]" label="MANUEL KONTROL İLE AL"></button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="showInvoiceDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  <div class="p-fluid">
    <div class="p-field col12">
      <p>Supplier: <b></b></p>
      <p>Code: <b>{{ invoice.code}}</b></p>
    </div>
  </div>
  <div class="card">
    <h5 class="h5-title">Dosya Yükleme</h5>
    <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)"
                  multiple="multiple" accept="image/*" maxFileSize="1000000">
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
      </ng-template>
    </p-fileUpload>
    </div>
  <div class="p-fluid">
    <div class="p-field col6">
      <button pButton  label="FATURA TARAMA İLE AL"></button>
    </div>
    <div class="p-field col6-right">
      <button pButton [routerLink]="['/order/receive-manuel/'+ invoice.id]" label="MANUEL KONTROL İLE AL"></button>
    </div>
  </div>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog header="{{ 'ARE_YOU_SURE' | translate }}" icon="pi pi-exclamation-triangle"></p-confirmDialog>

