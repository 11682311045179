import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';

export class StockPriceChanges extends ApiResourceModel{
  public static IRI = '/api/stock_price_changes';
  public stock: StockModel[] = [];
  public variant: StockVariantModel[] = [];
  public price: number;
}
