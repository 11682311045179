<p-card>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'WORK_ORDERS' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'WORK_ORDERS_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <span class="p-buttonset p-mb-5 p-d-inline-block">
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters.status == started" (click)="parameters.status = started; load()" label="{{ 'ACTIVE' | translate}}"></button>
    <button pButton type="button" class="p-button-secondary" [disabled]="parameters.status == invoiceProductionStatus.Status_Finished"  (click)="parameters.status =invoiceProductionStatus.Status_Finished; load()" label="{{ 'COMPLETED' | translate}}"></button>
</span>
  <form action="" (submit)="load()">
    <p-table styleClass="p-datatable-striped"  [loading]="loading" [lazy]="true" [value]="filteredData" [totalRecords]="total" (onLazyLoad)="load($event)"
             [paginator]="parameters.status == 'finished'" [rows]="parameters.status == 'finished' ? 10 : 100" [showCurrentPageReport]="true"
             [resizableColumns]="true" styleClass="p-datatable-striped" (onColResize)="onResize($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: settings|columnWidth: 'productions': '0'}" pResizableColumn>{{ 'WORK_ORDER_NUMBER' | translate}} <app-tooltip description="PRODUCTIONS_WORK_ORDER_NUMBER_TOOLTIP"></app-tooltip> </th>
          <th [style]="{width: settings|columnWidth: 'productions': '1'}" pResizableColumn>{{ 'CUSTOMER' | translate}} <app-tooltip description="PRODUCTIONS_CUSTOMER_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '2'}" pResizableColumn>{{ 'PRODUCT' | translate}} <app-tooltip description="PRODUCTIONS_PRODUCT_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '3'}" pResizableColumn>{{ 'DESCRIPTION' | translate}} <app-tooltip></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '4'}" pResizableColumn>{{ 'OPERATION' | translate}} <app-tooltip description="PRODUCTIONS_OPERATION_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '5'}" pResizableColumn>{{ 'RESOURCE' | translate}} <app-tooltip description="PRODUCTIONS_RESOURCE_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '6'}" pResizableColumn>{{ 'PLANNED_QUANTITY' | translate}} <app-tooltip description="WORK_ORDER_PLANNED_QUANTITY_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '7'}" pResizableColumn>{{ 'PLANNED_TIME' | translate}} <app-tooltip description="WORK_ORDER_PLANNED_TIME_TOOLTIP"></app-tooltip></th>
          <th [style]="{width: settings|columnWidth: 'productions': '8'}" pResizableColumn>{{ 'ACTIONS' | translate}}</th>
        </tr>
        <tr>
          <td><input type="text" pInputText [(ngModel)]="parameters['workOrder.code']"  name="code"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['workOrder.invoice.contact.name']"  name="customer"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['workOrder.variant.name']"  name="product"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['workOrder.description']"  name="description"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['operation.name']" name="operation"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters['resource.name']" name="resource"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.plannedQuantity" name="plannedQuantity"></td>
          <td><input type="text" pInputText [(ngModel)]="parameters.plannedTime" name="plannedTime"></td>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td>{{ row.workOrder.code }} {{ row.workOrder?.invoice ? '/' : '' }} {{ row.workOrder?.invoice?.code }}</td>
          <td>{{ row.workOrder?.invoice?.contact?.name }}</td>
          <td>{{ row.workOrder.variant.name }}</td>
          <td>{{ row.workOrder.description }}</td>
          <td>{{ row.operation?.name }}</td>
          <td>{{ row.resource?.name }}</td>
          <td>{{ row.workOrder.plannedQuantity }}</td>
          <td>{{ row.plannedTime }}</td>
          <td>
            <button pButton type="button" label="" icon="pi pi-play" *ngIf="row.status == invoiceProductionStatus.Status_Not_Started || row.status == invoiceProductionStatus.Status_Paused" (click)="changeStatus($event, row.id, invoiceProductionStatus.Status_Started)"></button>
            <button pButton type="button" label="" icon="pi pi-pause" *ngIf="row.status == invoiceProductionStatus.Status_Started" class="p-button-warning" (click)="changeStatus($event, row.id, invoiceProductionStatus.Status_Paused)"></button>
            <button pButton type="button" label="" icon="pi pi-check" *ngIf="row.status != invoiceProductionStatus.Status_Not_Started" class="p-button-success" (click)="changeStatus($event, row.id, invoiceProductionStatus.Status_Finished, row)"></button>
            <button type="button" *ngIf="!loading" style="margin: 0 15px" pButton icon="pi pi-eye" (click)="showProductions(row)" class="p-button-secondary"></button>
            <a *ngIf="!loading" pButton [icon]="this.printing === row.id ? 'pi pi-spin pi-spinner' : 'pi pi-print'" (click)="print(row.workOrder.id, row.id)" class="p-button-secondary"></a>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button type="submit" style="display: none" pButton label="{{'workOrderList.search' | translate}}"></button>
  </form>
</p-card>

<p-confirmDialog [header]="'ALERT'|translate" [style]="{width: '50vw'}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
