import {ApiResourceModel} from './api-resource.model';
import {StockAdjustmentItemModel} from './stock-adjustment-item.model';
import {LocationModel} from './location.model';

export class StockAdjustmentModel extends ApiResourceModel {
  public static IRI = '/api/stock_adjustments';
  code: string;
  series: string;
  number: number;
  reason: string;
  adjustmentDate: Date|string;
  description: string;
  items: StockAdjustmentItemModel[] = [];
  location: LocationModel|string;
}
