import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  errors = new BehaviorSubject<any>(null);

  errorsData = this.errors.asObservable();

  constructor() { }

  updateErrors(errors): void {
    this.errors.next(errors);
  }
}
