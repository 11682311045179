import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IntegrationLog} from '../../../model/integration-log.model';
import {HttpService} from '../../../service/http.service';

@Component({
  selector: 'app-integration-logs',
  templateUrl: './integration-logs.component.html',
  styleUrls: ['./integration-logs.component.scss']
})
export class IntegrationLogsComponent implements OnInit {
  loading: boolean;
  logs: IntegrationLog[] = [];
  display: boolean;
  response: string;
  constructor(private translate: TranslateService, private http: HttpService) { }

  ngOnInit(): void {
    this.loading = true;
    this.http.get(IntegrationLog.IRI).subscribe(response => {
      this.logs =  response['hydra:member'];
      this.loading = false;
    });
  }

  onClick(item): void {
    this.response = item.response;
    this.display = true;
  }

}
