import {Component, OnInit} from '@angular/core';
import {UserPageInformationModel} from '../../../model/user-page-information.model';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../../service/http.service';
import {ValidationService} from '../../../service/validation.service';
import {ProduceCompanyService} from '../../../service/produce-company.service';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {FlashMessageService} from '../../../service/flash-message.service';
import {SidebarService} from '../../../service/sidebar.service';
import {UserPageInformationService} from '../../../service/user-page-information.service';
import {UserModel} from '../../../model/user.model';
import {StockQuantityBatchModel} from '../../../model/stock-quantity-batch.model';
import {VariantRecipeModel} from '../../../model/variant-recipe.model';
import {ProductRecipeModel} from '../../../model/product-recipe.model';

@Component({
  selector: 'app-expiration-date-stocks',
  templateUrl: './expiration-date-stocks.component.html',
  styleUrls: ['./expiration-date-stocks.component.scss']
})
export class ExpirationDateStocksComponent implements OnInit {
  batchParameters = {
    page: 1,
    'variant.name': '',
    itemsPerPage: 20,
    isActive: 1,
    'order[variant.name]': 'ASC',
  };
  infoTextShowDisplay = false;
  display: boolean;
  userId: number;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  batches: StockQuantityBatchModel[] = [];
  loading: boolean;
  loadRecipesData: ProductRecipeModel[] = [];
  loadRecipeDisplay: boolean;
  batchesDisplay: boolean;

  constructor(private translate: TranslateService,
              private route: ActivatedRoute,
              private http: HttpService,
              private flashMessageService: FlashMessageService,
              private userPageInformationService: UserPageInformationService) {
  }

  ngOnInit(): void {
  }

  getStockBatches(event: LazyLoadEvent = null): void {
    if (event) {
      this.batchParameters.page = event.first / this.batchParameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.batchParameters.itemsPerPage = event.rows;
    }

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.batchParameters = Object.fromEntries(Object.entries(this.batchParameters).filter(([_, v]) => v != ''));

    this.loading = true;
    this.http.get(StockQuantityBatchModel.IRI, this.batchParameters).subscribe(response => {
      this.batches = response['hydra:member'];
      this.loading = false;
    });
  }

  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'STOCK_BATCHES';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'STOCK_BATCHES'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }

  calculateDay(date): number {

    const today = new Date();
    // @ts-ignore
    const futureDate = new Date(date);
    const diffInTime = futureDate.getTime() - today.getTime();
    const remainingDay = Math.ceil(diffInTime / (1000 * 3600 * 24));
    if (remainingDay < 0) {
      return 0;
    } else {
      return remainingDay;
    }
  }
  loadRecipes(id): void {
    this.loading = true;
    this.http.get(`${VariantRecipeModel.IRI}/stockInRecipes/${id}`).subscribe((response) => {
      this.loadRecipesData = response;
      this.loadRecipeDisplay = true;
      this.loading = false;
    });
  }

}
